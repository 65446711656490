// Action Types

export const Types = {
  OPEN_MENU: 'open_menu',
  SELECT_UNIDADE: 'select_unidade',
  SELECT_ESCOLARIDADE: 'select_escolaridade',
  SELECT_TURNO: 'select_turno',
  SELECT_TURMA: 'select_turma',
};

// Reducer

const initialState = {
  isMenuOpen: false,
  unidade: null,
  escolaridade: null,
  turno: null,
  turma: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.OPEN_MENU:
      return { ...state, isMenuOpen: action.payload.isMenuOpen };
    case Types.SELECT_UNIDADE:
      return { ...state, unidade: action.payload.unidade };
    case Types.SELECT_ESCOLARIDADE:
      return { ...state, escolaridade: action.payload.escolaridade };
    case Types.SELECT_TURNO:
      return { ...state, turno: action.payload.turno };
    case Types.SELECT_TURMA:
      return { ...state, turma: action.payload.turma };
    default:
      return state;
  }
}

// Action Creators
import InputMask from "react-input-mask";
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  justify-content: center;
  align-items: center;

  padding: 25px 30px;
  box-sizing: border-box;

  border-radius: 10px;
  border: 1px solid #ccd6ed;

  box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.05);

  background-color: white;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  font-weight: bold;

  gap: 12px;

  > div {
    width: 140px;
    height: 30px;

    color: #7b8c9d;
    font-weight: bold;
  }
`;

export const Schedule = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 100%;

`;

export const Row = styled.section`
  display: flex;
  justify-content: space-between;

  height: 60px;

  align-items: center;

  background: #f4f7fa;
  :nth-child(odd) { 
    background: transparent; 
    }

  > div {
    flex: 1;
    margin: 0 10px;
    border-right: 1px solid #ccd6ed;
    color: #212529;
  }
`;

export const CustomTextInput = styled(InputMask)`


  display: block;
  border-radius: 8px;
  flex: 1;
  margin: 0 10px;
  width: 40px;
  height: 35px;
  font-size: 12px;
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;

  :disabled {
    background-color: #f4f7fa;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

export const CustomRadioInput = styled.input`


  display: block;
  border-radius: 8px;
  flex: 1;
  margin: 0 10px;
  width: 30px;
  height: 15px;
  font-size: 12px;
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;

  :checked {
    background: rgba(34, 105, 245, .25);
  }
`;

export const CustomInput = styled.select`
  display: block;
  border-radius: 8px;
  flex: 1;
  margin: 0 10px;
  width: 40px;
  height: 35px;
  font-size: 12px;
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;

  :disabled {
    background-color: #f4f7fa;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: flex-start;

  margin-left: 12px;

  border-bottom: 1px solid #ccd6ed;

  h2 {
    color: #24292e;
    font-size: 14px;
  }

  h3 {
    color: #7b8c9d;
    font-size: 13px;
  }
`;

export const Footer = styled.footer`
  display: grid;

  justify-content: space-between;
  grid-auto-flow: column;

  width: 100%;


  margin-top: 12px;

`;
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import BaseGestao from "../../gestao/baseGestao";
import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from "../../../components/breadcrumb";
import urlsGestao from "../../gestao/urlsGestao";
import urlsGestaoUnidades from "../../gestao-unidades/urlsGestaoUnidades";
import { postEvadirAluno } from '../../../services//alunos';
import { useMutation } from 'react-query';
import FormInput from '../../../components/formInput'
import api from "../../../services";
import { useQuery } from 'react-query'
import upperCaseSimple from "../../../utils/upperCaseSimple";
import removeEmpty from "../../../utils/removeEmpty";
import { store } from '../../../store/index'

import Select from 'react-select';
import urlsConfiguracoes from "../../configuracoes/urlsConfiguracoes";



const AlunosEvadirPage = (gestao) => {
  const navigate = useNavigate();

  // const { id } = useParams();
  const { state, pathname } = useLocation();
  const [responsavel, setResponsavel] = useState(null);
  const onMenuOpen = () => setIsOpen(true);
  const onMenuClose = () => setIsOpen(false);
  const [isOpen, setIsOpen] = useState(false);

  const currentUser = store.getState()['user']['user']

  const unidadeID = localStorage.getItem('unidadeID');
  const [newData, setNewData] = useState('');
  const [shouldApperUnidadePicker, setShouldApperUnidadePicker] = useState(false);
  const [isFirstRenderPick, setIsFirstRenderPick] = useState(true);

  const [unidades, setUnidades] = useState([])
  const [unidade, setUnidade] = useState(null)
  const unidadeId = localStorage.getItem('unidadeID');
  const [escolaridadeId, setEscolaridadeId] = useState(0)

  const BaseComponent = gestao.gestao ? BaseGestao : BaseGestaoUnidades;
  const BasePath = gestao.gestao ? 'Gestão' : 'Gestão Unidades';
  const alunos = gestao.gestao ? urlsGestao.alunos : urlsGestaoUnidades.alunos;

  console.log(state)

  const { mutate, isLoading } = useMutation(postEvadirAluno, {
    onSuccess: (message) => {
      toast.success(message);
      
      if (sessionStorage.getItem('duplicados')) {
        navigate(urlsConfiguracoes.alunosDuplicado)
      } else {
        navigate(alunos);
      }
    },
    onError: (error) => {
      if (error.response.status == 400) {
        toast.error("Informe todos os campos obrigatórios.");
      } else if (error.request) {
        toast.error('Erro ao tentar enturmar, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  const { register, handleSubmit, setValue, formState: { isValid, errors }, watch } = useForm({ mode: "onSubmit" });

  // PARA TESTAR A INTEGRAÇÃO, SÓ DESCOMENTAR A LINHA "MUTATE" ABAIXO
  const onSubmit = data => {
    data.unidadeId = unidade
    data.usuarioId = currentUser?.servidorId
    // data.motivoId = parseInt(data.motivo)

    if (data.motivoId == 15 && unidade == unidadeID) {
      return toast.error('Aluno não pode ser transferido para mesma unidade.')
    }

    if (data.motivoId == 15 && (unidade === undefined || unidade == null || unidade == 0)) {
      return toast.error('Selecione uma unidade para transferência.')
    }
    mutate(upperCaseSimple(removeEmpty(data)));
  }

  useEffect(() => {
    console.log(state)
    setValue('alunoId', state.id)
    setValue('nome', state.aluno.nome)
    setValue('escolaridade', state.aluno.escolaridade)
    setEscolaridadeId(state.aluno.escolaridadeId)
  }, [state, pathname, setValue])

  useEffect(() => {
    setShouldApperUnidadePicker(watch('motivoId') === 15)
  }, [watch('motivoId')])

  const getUnitName = async () => {
    try {
      const res = await api.get(`unidade/2024/escolaridade/${state.aluno.escolaridadeId}`)
      console.log(res)

      const unidadesResponse = res?.data?.data

      var dados = [];
      unidadesResponse.forEach(item => {
        dados.push({ value: item.id, label: item.nome })
      });

      setUnidades(dados);
      // setUnidades(res?.data?.data)
      // setValue('unidadeID', res.data.data.nome)
    } catch (err) {
      console.log({ err })
    }
  }


  useEffect(() => {
    if (shouldApperUnidadePicker) {
      getUnitName()
      // setValue('unidadeID', newData)
    }
  }, [shouldApperUnidadePicker])


  console.log({ isFirstRenderPick })


  // PARA HABILITAR O PICKER DE UNIDADE NO FORM BASTA COMENTAR AS LINHAS 135 ~ 147 E DESCOMENTAR AS LINHAS 148~165
  return (
    <BaseComponent>
      <Breadcrumb title={'Exclusão'} itens={[BasePath, 'Alunos', 'Evadir']} />
      <form id='form' onSubmit={handleSubmit(onSubmit)}>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className="card-body">
                <>
                  <div className='row'>
                    <div className='col-1'>
                      <FormInput
                        register={register}
                        errors={errors}
                        group=""
                        atribute="alunoId"
                        label="Id"
                        readOnly
                        required={false}
                      />
                    </div>
                    <div className='col-7'>
                      <FormInput
                        register={register}
                        errors={errors}
                        group=""
                        atribute="nome"
                        label="Nome"
                        readOnly
                        required={false}
                      />
                    </div>
                    <div className='col-4'>
                      <FormInput
                        register={register}
                        errors={errors}
                        group=""
                        atribute="escolaridade"
                        label="Escolaridade"
                        readOnly
                        required={false}
                      />
                    </div>
                  </div>

                  <br />

                  <div className='row'>
                    <div className={shouldApperUnidadePicker ? "col-md-6" : "col-md-12"}>
                      <div className="form-group">
                        <label htmlFor="motivoId">Motivos da Exclusão<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                        <select
                          className="form-select"
                          id="motivoId"
                          onClick={() => setIsFirstRenderPick(false)}
                          {...register('motivoId', { required: true, valueAsNumber: true })}
                        >
                          {isFirstRenderPick && <option></option>}
                          <option value={11}>CANCELAMENTO</option>
                          <option value={12}>DESISTÊNCIA</option>
                          <option value={13}>EVADIDO</option>
                          <option value={14}>TRANSFERÊNCIA EXTERNA</option>
                          <option value={15}>TRANSFERÊNCIA MUNICIPAL</option>
                          <option value={16}>OUTROS</option>
                        </select>
                        {errors.dadosPessoais?.sexo?.type === 'required' && (
                          <span className="text-danger">Campo obrigatório</span>
                        )}
                      </div>
                    </div>
                    {
                      shouldApperUnidadePicker && (
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label htmlFor="unidadeId" className="form-label">
                              Unidades
                            </label>
                            <Select
                              aria-labelledby="aria-label"
                              inputId="aria-example-input"
                              name="aria-live-color"
                              onMenuOpen={onMenuOpen}
                              onMenuClose={onMenuClose}
                              options={unidades}
                              value={unidades.find(x => x.value == unidade)}
                              placeholder='SELECIONE'
                              onChange={async e => {
                                setUnidade(e.value);
                                // await setEscolaridade(0)
                                // await setTurno(0)
                                // await setTurma(0)
                                // refetch()
                                // refetchEscolaridades()
                              }
                              }
                            />
                          </div>
                        </div>
                      )
                    }
                  </div>

                  <br />



                  <div className='row'>
                    <div className='col-12'>
                      <FormInput
                        register={register}
                        errors={errors}
                        group=""
                        atribute="observacao"
                        label="Observação"
                        required={false}
                      />
                    </div>
                  </div>

                </>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginRight: 30,
                  marginBottom: 20
                }}>
                <button
                  style={{ marginRight: 20 }}
                  type='button'
                  className='btn btn-warning'
                  onClick={() => {
                    if (sessionStorage.getItem('duplicados')) {
                      navigate(urlsConfiguracoes.alunosDuplicado)
                    } else {
                      navigate(-1)
                    }
                  }}>
                  Voltar
                </button>
                <button
                  type='submit'
                  className='btn btn-danger'
                  disabled={!isValid}>
                  Enviar
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </BaseComponent>
  )

};

export default AlunosEvadirPage;
import { useEffect, useMemo, useState } from "react";
import Breadcrumb from "../../components/breadcrumb";
import Table from "../../components/table";
import BaseGestao from "../gestao/baseGestao";
import BaseGestaoUnidades from "../gestao-unidades/baseGestaoUnidades";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getServidores, deleteServidor } from "../../services/servidores";
import Spinner from "../../components/spinner";
import { getUnidades } from "../../services/unidades";
import { toast } from "react-toastify";
import urlsGestao from "../gestao/urlsGestao";
import urlsGestaoUnidades from "../gestao-unidades/urlsGestaoUnidades";
import Select from "react-select";
import FormInput from "../../components/formInput";
import { Dropdown } from "react-bootstrap";
import BaseReconhecimentoFacial from "../reconhecimento-facil/baseReconhecimentoFacial";
import urlsReconhecimentoFacil from "../reconhecimento-facil/urlsReconhecimentoFacil";
import { getServidoresCargos } from "../../services/cargos";

const ServidoresPage = (gestao) => {
  const unidadeID = localStorage.getItem("unidadeID");

  const navigate = useNavigate();
  const [newData, setNewData] = useState("");
  const [search, setSearch] = useState(localStorage.getItem("servidorPesquisar") ? localStorage.getItem("servidorPesquisar") : "");
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [warningControl, setWarningControl] = useState(false);
  const [cargos, setCargos] = useState([]);
  const [regimes, setRegimes] = useState([]);
  const [unidade, setUnidade] = useState(gestao.gestao ? 0 : unidadeID);
  const [unidades, setUnidades] = useState([]);

  const [limitCargos, setLimitCargos] = useState(2000);
  const [skipCargos, setSkipCargo] = useState(0);
  const [descricao, setDescricao] = useState("");
  const [ativo, setAtivo] = useState(true);

  const onMenuOpen = () => setIsOpen(true);
  const onMenuClose = () => setIsOpen(false);
  const [isOpen, setIsOpen] = useState(false);

  const { isLoading: loadingUnidades, refetch: refetchUnidades } = useQuery(
    "getUnidades",
    () => getUnidades(),
    {
      enabled: true,
      retry: true,
      onSuccess: (data) => {
        var dados = [{ value: 0, label: "TODOS" }];
        data.forEach((item) => {
          dados.push({ value: item.id, label: item.nome });
        });

        setUnidades(dados);
      },
    }
  );

  let editarUrl = gestao.gestao
    ? urlsGestao.servidoresEditar
    : urlsGestaoUnidades.servidoresEditar;
  let adicionarUrl = gestao.gestao ? urlsGestao.servidoresAdicionar : "";
  let BaseComponent = gestao.gestao ? BaseGestao : BaseGestaoUnidades;
  let BasePath = gestao.gestao ? "Gestão" : "Gestão Unidades";
  let cadastrarFotoUrl = gestao.gestao
    ? urlsGestao.servidorCadastrarFoto
    : urlsGestaoUnidades.servidorCadastrarFoto;

  if (gestao.reconhecimentoFacial) {
    cadastrarFotoUrl = urlsReconhecimentoFacil.servidorCadastrarFoto;
    BaseComponent = BaseReconhecimentoFacial;
    BasePath = "Reconhecimento Facial";
    editarUrl = "";
  }
  useEffect(() => {
    if (process.env.REACT_APP_SECRETARIA_CIDADE === "ARARUAMA") {
      import("./pickersAraruama").then((mod) => {
        setCargos(mod.servidoresCargos);
        // setRegimes(mod.regimesServidores);
        var dados = [{ value: 0, label: "TODOS" }];
        mod.regimesServidores.forEach((item) => {
          dados.push({ value: item.id, label: item.description });
        });

        setRegimes(dados);
      });
    } else {
      import("./pickersSaoPedro").then((mod) => {
        setCargos(mod.servidoresCargos);
        var dados = [{ value: 0, label: "TODOS" }];
        mod.regimesServidores.forEach((item) => {
          dados.push({ value: item.id, label: item.description });
        });

        setRegimes(dados);
      });
    }
  }, []);

  const [unidadeSelected, setUnidadeSelected] = useState(
    localStorage.getItem("servidorUnidadeId") ? localStorage.getItem("servidorUnidadeId") : gestao.gestao ? 0 : unidadeID
  );
  const [regimeSelected, setRegimeSelected] = useState(localStorage.getItem("servidorRegimeId") ? localStorage.getItem("servidorRegimeId") : 0);

  const [cargosData, setCargosData] = useState([]);

  function handleOnChangeUnidade(e) {
    setUnidadeSelected(e.value);
    localStorage.setItem("servidorUnidadeId", e.value);
  }

  function handleOnChangeRegime(e) {
    setRegimeSelected(e.value);
    localStorage.setItem("servidorRegimeId", e.value);
  }

  function handleOnChangePesquisar(e) {
    setSearch(e.target.value);
    localStorage.setItem("servidorPesquisar", e.target.value);
  }


  const { data, refetch, isLoading } = useQuery(
    "getServidores",
    () => getServidores(unidadeSelected, regimeSelected, search, limit, skip),
    {
      onSuccess: (data) => {
        setNewData(data);
      },
      enabled: true,
      retry: false,
    }
  );

  const { refetch: refetchCargos } = useQuery(
    "getServidoresCargos",
    () => getServidoresCargos(ativo, skipCargos, limitCargos, descricao),
    {
      onSuccess: (data) => {
        setCargosData(data?.data);
      },
      enabled: true,
      retry: true,
    }
  );

  const findCargo = (id) => {
    if (id) {
      const cargoName = cargosData.find((cargo) => cargo.id === id);
      return cargoName?.descricao;
    }
  };

  const findRegime = (id) => {
    if (id) {
      const regimeName = regimes.find((regime) => regime.value === id);
      return regimeName.label;
    }
  };

  const deletarServidor = (id) => {
    deleteServidor(id);
    toast.success("Servidor deletado com sucesso.");
    refetch();
  };

  useEffect(() => {
    refetch();
  }, [search, limit, skip, newData, unidadeSelected, regimeSelected]);

  const ActionsMenu = ({ row }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          Ações
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ marginTop: 0 }}>
          {editarUrl !== "" && (
            <Dropdown.Item
              className="d-flex dropdown-item-secondary"
              onClick={() => navigate(editarUrl + row.original.id)}
            >
              <i className="material-icons md-edit_note pe-2"></i> Editar
            </Dropdown.Item>
          )}
          {gestao.gestao && (
            <Dropdown.Item
              className="d-flex dropdown-item-danger"
              onClick={() => deletarServidor(row.original.id)}
            >
              <i className="material-icons md-delete pe-2"></i> Deletar
            </Dropdown.Item>
          )}

          <Dropdown.Item
            className="d-flex dropdown-item-primary"
            style={{ color: "#9c00ab"}}
            onClick={() =>
              navigate(cadastrarFotoUrl, {
                state: {
                  servidorId: row.original.id,
                },
              })
            }
          >
            <i className="material-icons md-camera_alt pe-2"></i> Foto
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const columns = useMemo(
    () => [
      { Header: "#", accessor: "id" },
      { Header: "Nome Servidor", accessor: "nomeCompleto" },
      { Header: "CPF", accessor: "cpf" },
      { Header: "Número Matrícula", accessor: "numeroContrato" },
      {
        Header: "Cargo",
        accessor: "cargo",
        Cell: ({ row }) => findCargo(row.original.cargo),
      },
      {
        Header: "Regime",
        accessor: "regimeID",
        Cell: ({ row }) => findRegime(row.original.regime),
      },
      {
        Header: "Gestão",
        accessor: "moduloGestao",
        Cell: ({ row }) => (
          <span
            className={`badge bg-${
              row.original.moduloGestao ? "success" : "danger"
            }`}
          >
            {row.original.moduloGestao ? "SIM" : "NÃO"}
          </span>
        ),
      },
      {
        Header: "Gestão Unidades",
        accessor: "moduloGestaoUnidades",
        Cell: ({ row }) => (
          <span
            className={`badge bg-${
              row.original.moduloGestaoUnidades ? "success" : "danger"
            }`}
          >
            {row.original.moduloGestaoUnidades ? "SIM" : "NÃO"}
          </span>
        ),
      },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => <ActionsMenu row={row} />,
      },
    ],
    [navigate, isLoading, newData]
  );

  const handleSearchButton = () => {
    if (search.length >= 3) {
      setSearch(search);
      refetch();
    }
  };

  console.log(gestao.gestao);

  return (
    <BaseComponent>
      <Breadcrumb
        title={"Servidores"}
        itens={[BasePath, "Servidores", "Lista Geral"]}
        actions={{ link: adicionarUrl, text: "Novo Servidor" }}
      />

      <div style={{ marginTop: 10, marginBottom: 20 }}>
        <div className="row align-items-center">
          <div className="col-12 col-md-5">
            <div className="form-group">
              <label htmlFor="unidadeId" className="form-label">
                Unidades
              </label>
              <Select
                aria-labelledby="aria-label"
                inputId="aria-example-input"
                name="aria-live-color"
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                options={unidades}
                isDisabled={gestao.gestao ? false : true}
                value={unidades.find((x) => x.value == unidadeSelected)}
                placeholder=""
                onChange={async (e) => {
                  handleOnChangeUnidade(e);
                }}
              />
            </div>
          </div>

          <div className="col-12 col-md-2">
            <div className="form-group">
              <label htmlFor="regimeId" className="form-label">
                Regimes
              </label>
              <Select
                aria-labelledby="aria-label"
                inputId="aria-example-input"
                name="aria-live-color"
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                options={regimes}
                // isDisabled={gestao.gestao ? false : true}
                value={regimes.find((x) => x.value == regimeSelected)}
                placeholder=""
                onChange={async (e) => {
                  handleOnChangeRegime(e);
                }}
              />
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="form-group">
              <label htmlFor="regimeId" className="form-label">
                Pesquisar
              </label>
              <input
                type="text"
                className="form-control"
                // placeholder='Pesquisar'
                value={search}
                onChange={(e) => handleOnChangePesquisar(e)}
                onMouseEnter={() => setWarningControl(true)}
                onMouseOut={() => setWarningControl(false)}
              />
            </div>
          </div>

          <div className="col-12 col-md-1 d-flex align-items-center justify-content-center">
            <div className="form-group" style={{ marginTop: "20px" }}>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => handleSearchButton()}
              >
                <i className="material-icons md-search"></i> Buscar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            {isLoading ? (
              <Spinner />
            ) : (
              <Table
                columns={columns}
                data={newData?.data ? newData?.data : []}
                hasPagination
                limit={limit}
                setLimit={setLimit}
                skip={skip}
                setSkip={setSkip}
                totalItems={newData?.total}
                overflowUnset={true}
              />
            )}
          </div>
        </div>
      </div>
    </BaseComponent>
  );
};

export default ServidoresPage;


import { useMemo, useEffect, useState } from 'react'
import Breadcrumb from '../../../../components/breadcrumb';
import BaseAdministrativo from '../../baseAdministrativo';
import Spinner from '../../../../components/spinner';
import Table from '../../../../components/table';
import { getAlunos, getUnidades } from '../../../../services/administrativo/transporte';
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from 'react-query';

const TransporteAlunos = () => {
  const [data, setData] = useState([]);
  const [listaUnidades, setListaUnidades] = useState([]);
  const [skip, setSkip] = useState(0);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(20);
  const [idUndiade, setIdUnidade] = useState(0);
  const [nome, setNome] = useState('');
  const [status, setStatus] = useState(-1);
  const [pcdPne, setPcdPne] = useState("");

  const { isLoading: loading, refetch } = useQuery('getAlunos', () => getAlunos(nome, idUndiade, status, pcdPne, skip, limit), {
    onSuccess: (data) => {
      setData(data.data);
      setTotal(data.total);
      setLimit(data.limit);
      setSkip(data.skip);
    },
    enabled: false,
    retry: true
  });

  const { refetch: refetchUnidades } = useQuery(
    'getUnidades',
    () =>
      getUnidades(),
    {
      retry: 0,
      enabled: false,
      onSuccess: data => {
        setListaUnidades(data.data)
      }
    }
  )


  useEffect(() => {
    refetch();
    if (listaUnidades.length === 0)
      refetchUnidades();
  }, [data, skip, limit]);


  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Nome', accessor: 'nome' },
      { Header: 'Cpf', accessor: 'cpf' },
      { Header: 'Pcd/Pne', accessor: 'pcdPne' },
      { Header: 'Unidade', accessor: 'nomeUnidade' },
      { Header: 'Escolaridade', accessor: 'nomeEscolaridade' },
      { Header: 'Turno', accessor: 'nomeTurno' },
      { Header: 'Turma', accessor: 'nomeTurma' },
    ],
    [data]
  )

  return (
    <BaseAdministrativo>
      <Breadcrumb title={'Alunos'} itens={['Administrativo', 'Transportes', 'Alunos', 'Lista Geral']} />

      <div className='row' style={{ marginBottom: '20px', marginRight: '0', marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
        <div className="col-md-3">
          <select className="form-select" value={idUndiade} onChange={(event) => setIdUnidade(event.target.value)}>
            <option value={0}>SELECIONE A UNIDADE</option>
            {listaUnidades && listaUnidades.map(item => (<option key={item.id} value={item.id}>{item.descricao}</option>))}
          </select>
        </div>
        <div className="col-md-2">
          <select className="form-select" value={status} onChange={(event) => setStatus(event.target.value)} placeholder="Nome">
            <option value={-1}>SELECIONE O STATUS</option>
            <option value={1}>ATIVO</option>
            <option value={0}>INATIVO</option>
          </select>
        </div>
        <div className="col-md-2">
          <select className="form-select" value={pcdPne} onChange={(event) => setPcdPne(event.target.value)} placeholder="Nome">
            <option value={'1'}>SIM</option>
            <option selected value={''}>NÃO</option>
          </select>
        </div>
        <div className="col-md-2">
          <input type="text" className="form-control" value={nome} onChange={(event) => setNome(event.target.value)} placeholder="Nome" />
        </div>
        <button className="btn btn-sm btn-primary" style={{ width: '100px', height: '30px', marginTop: '7px' }} onClick={() => refetch()}>
          <i className="material-icons md-search"></i> Buscar
        </button>
      </div>

      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            {loading ? (
              <Spinner />
            ) : (
              <Table
                columns={columns}
                data={data}
                hasPagination
                limit={limit}
                setLimit={setLimit}
                skip={skip}
                setSkip={setSkip}
                totalItems={total}
              />
            )}
          </div>
        </div>
      </div>
    </BaseAdministrativo>
  )
}

export default TransporteAlunos;
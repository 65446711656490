import { useMemo, useState } from 'react';
import Breadcrumb from "../../../../components/breadcrumb";
import Table from "../../../../components/novaTabela";
import BaseAdministrativo from '../../baseAdministrativo';
import { useQuery, useMutation } from 'react-query';
import { getMemorandos, deleteMemorando } from '../../../../services/administrativo/financeiro-memorandos';
import urls from "../../../../utils/urls";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const dataTable = [ //mock de memorandos
  {
    id: 1,
    descricao: 'Memorando',
    ativo: 'Ativo',
    dataEnvio: '19-05-2023',
    usuarioCriacaoId: 1,
    nomeUsuario: 'ES1U02',
    anexo: 1
  },
  {
    id: 2,
    descricao: 'Memorando',
    ativo: 'Ativo',
    dataEnvio: '19-05-2023',
    usuarioCriacaoId: 1,
    nomeUsuario: 'ES1U02',
    anexo: null,
  },
  {
    id: 3,
    descricao: 'Memorando',
    ativo: 'Inativo',
    dataEnvio: '19-05-2023',
    usuarioCriacaoId: 1,
    nomeUsuario: 'ES1U02',
    anexo: 1
  },
  {
    id: 4,
    descricao: 'Memorando',
    ativo: 'Ativo',
    dataEnvio: '19-05-2023',
    usuarioCriacaoId: 1,
    nomeUsuario: 'ES1U02',
    anexo: null,
  },
  {
    id: 5,
    descricao: 'Memorando',
    ativo: 'Inativo',
    dataEnvio: '19-05-2023',
    usuarioCriacaoId: 1,
    nomeUsuario: 'ES1U02',
    anexo: 1
  },

]

const MemorandosTable = () => {
  const [newData, setNewData] = useState([])
  const navigate = useNavigate();

  const { data: memorandosData, refetch: refetchData } = useQuery('getMemorandos', getMemorandos, {
    retry: false,
    enabled: true,
    onSuccess: (data) => {
      // setNewData(data.data)
    },
  });

  const { mutate: deleteMutate } = useMutation(deleteMemorando, {
    onSuccess: () => {
      toast.success('Memorando excluído com sucesso.');
      refetchData();
    },
    onError: () => {
      toast.error('Algo de errado aconteceu. Tente novamente mais tarde.')
    }
  });


  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Descrição', accessor: 'descricao' },
      {
        Header: 'Status', accessor: 'ativo',
        Cell: ({ row }) => (
          row.original.ativo === true ? 'Ativo' : 'Inativo'
        )
      },
      {
        Header: 'Data de envio', accessor: 'dataEnvio',
        Cell: ({ row }) => (
          row.original.dataEnvio.split('T')[0].split('-').reverse().join('/')
        )
      },
      { Header: 'Usuário', accessor: 'nomeUsuario' },
      {
        Header: 'Anexo', accessor: 'anexo',
        Cell: ({ row }) => (
          <button
            className='btn btn-sm'
            style={{ padding: '8px' }}
            onClick={() => console.log('Anexo')}
            disabled={row.original.anexo === '' || row.original.anexo === null || !row.original.anexo}
          >
            Baixar
          </button>
        )
      },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <button onClick={() => navigate(urls.administrativoFinanceiroMemorandosCadastrar + row.original.id, {
              state: {
                id: row.original.id,
                descricao: row.original.descricao,
                ativo: row.original.ativo,
                anexo: row.original.anexo
              }
            })} className='btn btn-sm btn-primary'>
              <i className="material-icons md-edit_note"></i> visualizar
            </button>
            <button
              className='btn btn-sm btn-danger'
              style={{ marginLeft: '8px' }}
              onClick={() => {
                const confirm = window.confirm('Tem certeza que deseja excluir?')
                if (confirm) {
                  deleteMutate(row.original.id)
                }
              }}
            >
              <i className="material-icons md-group_remove"></i> excluir
            </button>
          </>
        )
      },
    ],
    [navigate, newData]
  )

  return (
    <BaseAdministrativo>
      <Breadcrumb title={'Memorandos'} itens={['Administrativo', 'Financeiro', 'Memorandos']} actions={{ link: urls.administrativoFinanceiroMemorandosCadastrar, text: "Novo Memorando" }} />
      <div className='row'>
        <div className="mailbox-container">
          <div className="card">
            <div className="card-body">
              <Table columns={columns} data={dataTable ? dataTable : []} />
            </div>
          </div>
        </div>
      </div>
    </BaseAdministrativo >
  )
};
export default MemorandosTable;

import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { salvarResultadoFicha } from "../../../services/ficha-individual";

const SalvarFichaForm = ({ id, ficha }) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      resultado: ficha?.resultadoFinal?.resultado ?? "",
      observacoes: ficha?.resultadoFinal?.observacao ?? "",
    },
  });

  const resultados = [
    { label: "Transferido", value: 1 },
    { label: "Aprovado", value: 2 },
    { label: "Aprovado pelo Conselho de Classe", value: 3 },
    { label: "Reprovado", value: 4 },
    { label: "Evadido", value: 5 },
    { label: "Matrícula Indeferida", value: 6 },
    { label: "Temporalidade flexível", value: 7 },
    { label: "Promovido", value: 8 },
    { label: "Retido", value: 9 },
  ];

  const onSubmit = async (data) => {
    let body = {
      alunoId: id,
      turmaId: ficha.aluno.turmaId,
      resultado: data.resultado,
      observacao: data.observacoes,
      servidorId: 1, // This is a placeholder; adjust as needed
      dataLancamento: new Date(),
      diasLetivos: 200,
    };

    let response = await salvarResultadoFicha(body);
    if (response) {
      toast.success("Resultado salvo com sucesso!");
    } else {
      toast.error("Erro ao salvar resultado");
    }
  };

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="d-flex justify-content-center">
          <div className="d-flex flex-column align-items-center">
            <h5>Resultado da Avaliação</h5>
            <div className="d-flex gap-2">
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Resultado
                    <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    {...register("resultado", { required: true })}
                  >
                    {resultados.map((resultado) => (
                      <option key={resultado.label} value={resultado.label}>
                        {resultado.label}
                      </option>
                    ))}
                  </select>
                  {errors.resultado && <p className="text-danger">Campo obrigatório</p>}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Observações
                    <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    {...register("observacoes", {
                      required: true,
                      maxLength: 200,
                    })}
                  ></input>
                  {errors.observacoes && <p className="text-danger">Campo obrigatório</p>}
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-success mt-2">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SalvarFichaForm;

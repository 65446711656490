import React from 'react';
import InputMask from 'react-input-mask';
// import { Tooltip } from 'react-tooltip';

export default function FormInput({
  register,
  errors,
  group,
  atribute,
  label,
  mask,
  type = 'text',
  required = true,
  placeholder,
  readOnly,
  onChange,
  onChangeCapture,
  onKeyDownCapture,
  style,
  asNumber = false,
  defaultValue = '',
  tooltipText = ''
}) {

  const tooltipId = `${group ? `${group}.${atribute}` : `${atribute}`}-tooltip`;
  
  return (
    <div className="form-group">
      <label htmlFor={group ? `${group}.${atribute}` : `${atribute}`}>
        {label}
        {required && <span style={{ color: 'red', marginLeft: '4px' }}>*</span>}
        {tooltipText && (
          <span
            className="tooltip-icon"
            data-tip={tooltipText}
            data-for={tooltipId}
          >
            ?
          </span>
        )}
        {/* {tooltipText && (
          <Tooltip id={tooltipId} place="top" effect="solid">
            {tooltipText}
          </Tooltip>
        )} */}
      </label>
      {mask ? (
        <InputMask
          type="tel"
          inputMode="numeric"
          className="form-control"
          style={style}
          name={group ? `${group}.${atribute}` : `${atribute}`}
          id={group ? `${group}.${atribute}` : `${atribute}`}
          mask={mask}
          onChange={(e) => onChange(e.target.value)}
          // onChangeCapture={(e) => onChangeCapture(e.target.value)}
          placeholder={placeholder}
          readOnly={readOnly}
          defaultValue={defaultValue} // Adicione o defaultValue aqui
          {...register(group ? `${group}.${atribute}` : `${atribute}`, {
            required: { required },
          })}
        />
      ) : (
        <input
          type={asNumber ? 'number' : type}
          style={style}
          className="form-control"
          id={group ? `${group}.${atribute}` : `${atribute}`}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          // onChangeCapture={(e) => onChangeCapture(e.target.value)}
          // onBlurCapture={(e) => onBlurCapture(e.target.value)}
          // onKeyDownCapture={(e) => onKeyDownCapture(e.key)}
          readOnly={readOnly}
          defaultValue={defaultValue} // Adicione o defaultValue aqui
          {...register(group ? `${group}.${atribute}` : `${atribute}`, {
            required: { required },
          })}
        />
      )}
    </div>
  );
}

import {React, useState} from 'react';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import AtaResultadoFinalAnosIniciais from './anos-iniciais';
import AtaResultadoFinalEJAFase3A5 from './eja-fase-3-a-5';
import AtaResultadoFinalInfantil from './infantil';
import AtaResultadoFinalPrimeiroAno from './primeiro-ano';
import AtaResultadoFinalEJAFase1E2 from './eja-fase-1-e-2';
import AtaResultadoFinalAnosFinais from './anos-finais';



export const AtaResultadoFinalPage = () => {
  const { turmaId } = useParams();

  const { state } = useLocation();

  // Helper function to remove accents and compare case-insensitively
  const normalizeString = (str) => {
    return str ? str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() : '';
  };

  const isEjaFundamental = () => {
    const normalizedDescricao = normalizeString(state?.escolaridadeDescricao);
    return [
      'fase iii', 'fase iv', 'fase v', 
      'fase vi', 'fase vii', 'fase viii', 'fase ix'
    ].some(fase => normalizedDescricao.includes(fase));
  };

  const isEjaInicial = () => {
    const normalizedDescricao = normalizeString(state?.escolaridadeDescricao);
    return normalizedDescricao === 'fase i' || normalizedDescricao === 'fase ii';
  }

  const isPrimeiroAno = () => {
    const normalizedDescricao = normalizeString(state?.escolaridadeDescricao);
    return normalizedDescricao.includes('1º ano') || normalizedDescricao.includes('primeiro ano');
  };

  const isAnosInicias = () => {
    const normalizedDescricao = normalizeString(state?.escolaridadeDescricao);
    return normalizedDescricao.includes('2º ano') ||  normalizedDescricao.includes('3º ano')  || normalizedDescricao.includes('4º ano') || normalizedDescricao.includes('5º ano');
  };

  const isAnosFinais = () => {
    const normalizedDescricao = normalizeString(state?.escolaridadeDescricao);
    return normalizedDescricao.includes('6º ano') || normalizedDescricao.includes('7º ano') || normalizedDescricao.includes('8º ano') || normalizedDescricao.includes('9º ano');
  };

  const isInfantil = () => {
    const normalizedDescricao = normalizeString(state?.escolaridadeDescricao);
    return normalizedDescricao.includes('creche') || normalizedDescricao.includes('pré') || normalizedDescricao.includes('pre');
  };


  return (
    <div>
      {isPrimeiroAno() ? (
        <AtaResultadoFinalPrimeiroAno turmaId={state?.id} escolaridadeDescricao={state?.escolaridadeDescricao} turnoDescricao={state?.turnoDescricao} turmaDescricao={state?.turmaDescricao} gestao={state?.gestao} unidadeDescricao={state?.unidadeDescricao} />
      ) : isAnosInicias() ? (
        <AtaResultadoFinalAnosIniciais turmaId={state?.id} escolaridadeDescricao={state?.escolaridadeDescricao} turnoDescricao={state?.turnoDescricao} turmaDescricao={state?.turmaDescricao} gestao={state?.gestao} unidadeDescricao={state?.unidadeDescricao} />
      ) : isInfantil() ? (
        <AtaResultadoFinalInfantil turmaId={state?.id} escolaridadeDescricao={state?.escolaridadeDescricao} turnoDescricao={state?.turnoDescricao} turmaDescricao={state?.turmaDescricao} gestao={state?.gestao} unidadeDescricao={state?.unidadeDescricao} />
      ) : isEjaInicial() ? (
        <AtaResultadoFinalEJAFase1E2 turmaId={state?.id} escolaridadeDescricao={state?.escolaridadeDescricao} turnoDescricao={state?.turnoDescricao} turmaDescricao={state?.turmaDescricao} gestao={state?.gestao} unidadeDescricao={state?.unidadeDescricao} />
      ) : isEjaFundamental() ? (
        <AtaResultadoFinalEJAFase3A5 turmaId={state?.id} escolaridadeDescricao={state?.escolaridadeDescricao} turnoDescricao={state?.turnoDescricao} turmaDescricao={state?.turmaDescricao} gestao={state?.gestao} unidadeDescricao={state?.unidadeDescricao} />
      ) :  isAnosFinais() ? (
        <AtaResultadoFinalAnosFinais turmaId={state?.id} escolaridadeDescricao={state?.escolaridadeDescricao} turnoDescricao={state?.turnoDescricao} turmaDescricao={state?.turmaDescricao} gestao={state?.gestao} unidadeDescricao={state?.unidadeDescricao} />
      ) : null}
    </div>
  );
};

export default AtaResultadoFinalPage;

import React from "react";

class DataImpressao extends React.Component {
    render() {

    let studentsMap = {};

    const { data } = this.props;

    const calcularSoma = (trimestre) => {
        let soma = 0;
        if (trimestre.r1 !== -1) soma += trimestre.r1;
        if (trimestre.r2 !== -1) soma += trimestre.r2;
        if (trimestre.tracos !== -1) soma += trimestre.tracos;
        if (trimestre.corpo !== -1) soma += trimestre.corpo;
        return soma;
      };

      

    if (data !== undefined) {
        data.forEach(trimestre => {
            trimestre.linhas.forEach(student => {
              const { nomeAluno, numeroMatricula, r1, r2, corpo, tracos, total } = student;
              if (!studentsMap[numeroMatricula]) {
                studentsMap[numeroMatricula] = { nomeAluno, numeroMatricula, trimesters: [] };
              }
              studentsMap[numeroMatricula].trimesters.push({ r1, r2, corpo, tracos, total});
            });
          });
  }

  const students = Object.values(studentsMap);

    return (
        <table border="1" className="striped-table">
          {/* <caption>HA - Hora Aula | DL - Dias Letivos
          </caption> */}
          <thead>
          <tr>
          <th rowSpan="2">NOME</th>
            <th rowSpan="2">MATRÍCULA</th>
            <th colSpan="5">1º TRIMESTRE</th>
            <th colSpan="5">2º TRIMESTRE</th>
            <th colSpan="5">3º TRIMESTRE</th>
            <th colSpan={4}>TOTAL ANUAL</th>
          </tr>
        
          <tr>
            <th>R1</th>
            <th>R2</th>
            <th>Traços</th>
            <th>Corpo</th>
            <th>Total</th>
            <th>R1</th>
            <th>R2</th>
            <th>Traços</th>
            <th>Corpo</th>
            <th>Total</th>
            <th>R1</th>
            <th>R2</th>
            <th>Traços</th>
            <th>Corpo</th>
            <th>Total</th>
            <th>TOTAL FALTAS/ HA</th>
            <th>TOTAL FALTAS / DIA</th>
            <th>%FR.:</th>
            <th>Decisão</th>
          </tr>
          </thead>
          <tbody>
          {students.map((student, index) => (
            <tr key={index}>
              <td>{student.nomeAluno}</td>
              <td>{student.numeroMatricula}</td>
              {student.trimesters.map((trimester, idx) => (
                <React.Fragment key={idx}>
                 <td>{trimester.r1 !== -1 ? trimester.r1 : ''}</td>
                <td>{trimester.r2 !== -1 ? trimester.r2 : ''}</td>
                <td>{trimester.tracos !== -1 ? trimester.tracos : ''}</td>
                <td>{trimester.corpo !== -1 ? trimester.corpo : ''}</td>
                <td>{trimester.total !== 0 ? trimester.total : ''}</td>
                </React.Fragment>
              ))}
               {/* <td>{calcularSomaTrimestres(data)}</td> */}
               <td>{student.trimesters.reduce((acc, cur) => acc + cur.total, 0)}</td>
               <td>{student.trimesters.reduce((acc, cur) => acc + cur.total, 0) / 5}</td>
               <td>{(200 - student.trimesters.reduce((acc, cur) => acc + cur.total, 0) / 5) * 100 / 200}</td>
               <td>
  <span className={`badge bg-${((200 - student.trimesters.reduce((acc, cur) => acc + cur.total, 0) / 5) * 100 / 200) >= 75 ? 'success' : 'danger'}`}>
    {((200 - student.trimesters.reduce((acc, cur) => acc + cur.total, 0) / 5) * 100 / 200) >= 75 ? 'APROVADO' : 'REPROVADO'}
  </span>
</td>
            </tr>
          ))}
        </tbody>
        </table>
    )
}
}

export default DataImpressao;

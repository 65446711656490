import { useMemo } from 'react';
import Breadcrumb from '../../../../components/breadcrumb';
import Table from '../../../../components/table';
import BaseAdministrativo from '../../baseAdministrativo';
import Select from 'react-select'
import { useQuery } from 'react-query';
import { getMovimentacoes } from '../../../../services/administrativo/patrimonio-estoque'
import urlsAdministrativo from '../../urlsAdministrativo';

const ControleEstoque = () => {

  const { data } = useQuery('getMovimentacoes', getMovimentacoes, {
    enabled: true,
    retry: true,
  })

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Movimentação', accessor: 'tipoMovimentacao'}, 
      { Header: 'Produto', accessor: 'nomeProduto' }, 
      { Header: 'Fornecedor', accessor: 'nomeFantasia' }, 
      { Header: 'Unidade', accessor: 'nome' },
      { Header: 'Data', accessor: 'dataMovimentacao',
        Cell: ({ row }) => (
          row.original?.dataMovimentacao?.split("T")[0]?.split("-")?.reverse()?.join("/")
        )
      },
      { Header: 'Quantidade', accessor: 'quantidade' },
      { Header: 'Servidor', accessor: 'usuarioLogin' },
    ],
    []
  )

  return (
    <BaseAdministrativo>
      <Breadcrumb title={'Controle de Estoque'} itens={['Administrativo', 'Patrimônio', 'Controle de Estoque']} actions={{ link: urlsAdministrativo.patrimonioControleEstoqueCadastrar, text: "Nova Movimentação" }} />
      <div className='row'>
        <div className="mailbox-container">
          <div className="card">
            <div className="card-body">
              <div className="row" style={{ marginBottom: 0 }}>
                
              </div>
              <Table hasPagination={true} totalItems={data?.data?.length ?? 0} columns={columns} data={data?.data ? data?.data : []} />
            </div>
          </div>
        </div>
      </div>
    </BaseAdministrativo >
  )
};
export default ControleEstoque;

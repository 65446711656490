import { useEffect, useMemo, useState } from 'react';
import Breadcrumb from "../../../../components/breadcrumb";
import Table from "../../../../components/novaTabela";
import BaseAdministrativo from '../../baseAdministrativo';
import { getFornecedores, deleteFornecedor } from '../../../../services/administrativo/patrimonio-fornecedores';
import { useQuery, useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom';
import urls from "../../../../utils/urls";
import { toast } from 'react-toastify';

const NutricaoFornecedores = () => {
  const navigate = useNavigate()
  const [newData, setNewData] = useState([]);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);


  const { refetch: refetchData, isLoading } = useQuery('getFornecedores', () => getFornecedores(), {
    enabled: true,
    retry: true,
    onSuccess: (data) => {
      setNewData(data);
    }
  })

  // const { refetch: refetchDeleteFornecedor, isLoading: loadingDelete } = useQuery('deleteFornecedor', deleteFornecedor(rowToDelete), {
  //   enabled: false,
  //   retry: false,
  //   onSuccess: (data) => {
  //     toast.success('Fornecedor excluído com sucesso.');
  //     refetchData();
  //   }
  // })

  const { mutate, isLoading: loadingDelete } = useMutation(id => deleteFornecedor(id), {
    onSuccess: data => {
      toast.success('Fornecedor excluído com sucesso.');
      refetchData();
    },
    onError: data => {
      toast.error('Erro ao excluir fornecedor. Tente novamente mais tarde.')
    }
  })

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Nome', accessor: 'nomeFantasia' },
      { Header: 'CNPJ', accessor: 'cnpj' },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <button onClick={() => navigate(urls.administrativoNutricaoFornecedorCadastrar + row.original.id, {
              state: {
                id: row.original.id,
                nomeFantasia: row.original.nomeFantasia,
                cnpj: row.original.cnpj,
              },

            })} className='btn btn-sm btn-primary'>
              <i className="material-icons md-edit_note"></i> visualizar
            </button>
            <button
              className='btn btn-sm btn-danger'
              style={{ marginLeft: '8px' }}
              onClick={() => {
                const confirm = window.confirm('Tem certeza que deseja excluir?')
                if (confirm) {
                  mutate(row.original.id);
                }
              }}
            >
              <i className="material-icons md-group_remove"></i> excluir
            </button>
          </>
        )
      },
    ],
    [navigate, isLoading, newData, loadingDelete]
  )

  return (
    <BaseAdministrativo>
      <Breadcrumb title={'Nutrição Fornecedores'} itens={['Administrativo', 'Nutrição', 'Fornecedores']} actions={{ link: urls.administrativoNutricaoFornecedorCadastrar, text: "Novo Fornecedor" }} />
      <div className='row'>
        <div className="mailbox-container">
          <div className="card">
            <div className="card-body">
              {/* <div className="row" style={{ marginBottom: 10 }}> -> Função de pesquisar desabilitada no momento

                <div className="col-xs-12 col-sm-5" style={{ marginBottom: 10 }}>
                  <div className="form-group">
                    <label htmlFor="componente-curricular">Nome</label>
                    <input type="text" className="form-control" id="componente-curricular" placeholder="Nome" />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-5" style={{ marginBottom: 10 }}>
                  <div className="form-group">
                    <label htmlFor="componente-curricular">CNPJ</label>
                    <input type="text" className="form-control" id="componente-curricular" placeholder="CNPJ" />
                  </div>
                </div>


                <div className="col-xs-12 col-sm-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 10 }}>
                  <div className="form-group">
                    <button className="btn btn-primary btn-sm" onClick={() => console.log('teste')}>
                      <i class="material-icons">search</i> Buscar
                    </button>
                  </div>
                </div>
              </div> */}
              <Table 
              columns={columns}
              data={newData.data ? newData.data : []}
              hasPagination
              limit={limit}
              setLimit={setLimit}
              skip={skip}
              setSkip={setSkip}
              totalItems={newData.total}
              />
            </div>
          </div>
        </div>
      </div>
    </BaseAdministrativo >
  )
};
export default NutricaoFornecedores;

import { useMemo, useState, useEffect } from "react";
import { useQuery } from 'react-query';
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from "../../../../../components/breadcrumb";
import BaseAdministrativo from "../../../baseAdministrativo";
import urlsAdministrativo from "../../../urlsAdministrativo";
import { createVeiculoGasto, getTiposGastos } from "../../../../../services/administrativo/transporte";

const LancarGastosForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { state } = useLocation();
    const [valor, setValor] = useState(0);
    const [idTipoGastos, setIdTipoGastos] = useState(0);    
    const [tipoGastoValido, setTipoGastoValido] = useState("");
    const [valorValido, setValorValido] = useState("");
    const [listaGastos, setListaGastos] = useState();

    const formataMoeda = (number) => {
        if (number === "") {
            setValorValido("d-block");
            setValor(0);
            return;
        }
        setValorValido("");
        var valorFormatado = number;
        valorFormatado = parseInt(valorFormatado.replace(/[\D]+/g, ''));
        valorFormatado = valorFormatado + '';
        valorFormatado = valorFormatado.replace(/([0-9]{2})$/g, ",$1");

        if (valorFormatado.length > 6) {
            valorFormatado = valorFormatado.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
        }
        setValor(valorFormatado);
    }

    const validaTipoGasto = (id) => {
        setIdTipoGastos(id);
        setTipoGastoValido("");
    }

    useEffect(() => {
        refetchTiposGastos();
    }, []);

    const { refetch: refetchTiposGastos } = useQuery(
        'getTiposGastos',
        () =>
            getTiposGastos(),
        {
            retry: 0,
            enabled: false,
            onSuccess: data => {
                setListaGastos(data.data)
            }
        }
    )

    const { register, handleSubmit, setValue, formState: { isValid, errors } } = useForm({
        mode: "onChange", defaultValues: {
            idTipoGasto: '',
            valor: 0,
            dataLancto: new Date()
        }
    });

    const onSubmit = async (data) => {
        try {
            if (idTipoGastos === 0) {
                setTipoGastoValido("d-block");
                return;
            }

            if (valor === 0) {
                setValorValido("d-block");
                return;
            }
            const valueUnformatted = Number(valor.replace('.', '').replace(',', '.'));
            const dataObject = {
                idVeiculo: id,
                idTipoGasto: idTipoGastos,
                data: data.dataLancto,
                valor: valueUnformatted
            }
            await createVeiculoGasto(dataObject);
            toast.success("Lançamento de gasto realizada com sucesso!");
            navigate(urlsAdministrativo.transporteVeiculos)
        } catch (err) {
            toast.error('Erro no envio da requisição. Verifique sua internet e tente novamente mais tarde.')
        }
    };

    return (
        <BaseAdministrativo>
            <Breadcrumb title={'Lançamento de gastos'} itens={['Administrativo', 'Transportes', 'Veículos', 'Formulário']} />
            <form id='form' onSubmit={handleSubmit(onSubmit)}>
                <div className='row'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className="card-body">
                                <>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <label htmlFor="idTipoGasto" className="form-label">Tipo Gasto</label>
                                            <select className="form-select" value={idTipoGastos} onChange={(event) => validaTipoGasto(event.currentTarget.value)} >
                                                <option value={0}>SELECIONE O TIPO DE GASTO</option>
                                                {listaGastos && listaGastos.map(item => (<option key={item.id} value={item.id}>{item.descricao}</option>))}
                                            </select>
                                            <div role='alert' className={"invalid-feedback " + tipoGastoValido}>Campo obrigatório!</div>
                                        </div>
                                        <div className='col-3'>
                                            <label htmlFor="dataLancto" className="form-label">Data</label>
                                            <input type="date" className="form-control" id="dataLancto"
                                                {...register("dataLancto", { required: true })} />
                                            {errors.dataLancto?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                                        </div>
                                        <div className='col-3'>
                                            <label htmlFor="valor" className="form-label">Valor</label>
                                            <input type="text" value={valor} className="form-control" id="valor" onChange={(event) => formataMoeda(event.target.value)} />
                                            <div role='alert' className={"invalid-feedback " + valorValido}>Campo obrigatório!</div>
                                        </div>
                                    </div>
                                </>
                            </div>
                            <div className='card-footer text-end'>
                                <button type='submit' className='btn btn-success'>
                                    {'Cadastrar'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </BaseAdministrativo>
    )

};

export default LancarGastosForm;
import React, { useState, useEffect, useRef } from 'react';
import BaseConfiguracoes from '../configuracoes/baseConfiguracoes';
import Breadcrumb from '../../components/breadcrumb';
import html2canvas from 'html2canvas';

const ChatInterativo = () => {
  const [messages, setMessages] = useState([]);
  const [userMessage, setUserMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [botTyping, setBotTyping] = useState(false);
  const [currentText, setCurrentText] = useState('');
  const messageContainerRef = useRef(null);

  // Função para converter texto em áudio
  const speak = (text) => {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(text);
    synth.speak(utterance);
  };

  // Função para gerar texto sendo escrito gradualmente
  const typeText = (text, callback) => {
    let index = 0;
    const interval = setInterval(() => {
      if (index < text.length) {
        callback((prevText) => prevText + text[index]);
        index++;
      } else {
        clearInterval(interval);
      }
    }, 10); // Velocidade da digitação (100ms por caractere)
  };

  const sendMessage = async () => {
    if (userMessage.trim() === '') return;

    // Adiciona a mensagem do usuário na lista de mensagens
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: 'user', text: userMessage },
    ]);

    // Limpa o campo de entrada
    setUserMessage('');
    setLoading(true);
    setBotTyping(true);

    try {
      // Chamada à API da OpenAI
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer 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`,  // Insira sua chave de API
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo',  // ou outro modelo de sua escolha
          messages: [{ role: 'user', content: userMessage + 'em forma de conto de até 10 linhas' }],
        }),
      });

      const data = await response.json();
      const botResponse = data.choices[0].message.content;

      let typedText = '';
      typeText(botResponse, (typedText) => {
        setCurrentText(typedText); // Atualiza o texto sendo digitado
      });

      // Converte a resposta em áudio
      speak(botResponse);

      // Adiciona a resposta do bot após o texto completo ser exibido
      setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'bot', text: botResponse },
        ]);
        setCurrentText(''); // Limpa o texto temporário
      }, botResponse.length * 100); // Tempo para terminar de digitar o texto

    } catch (error) {
      console.error('Erro ao chamar a API do ChatGPT:', error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'bot', text: 'Erro ao processar sua mensagem. Tente novamente.' },
      ]);
    }

    setLoading(false);
    setBotTyping(false);
  };

  useEffect(() => {
    // Rolar para o final da lista de mensagens sempre que novas mensagens forem adicionadas
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [messages]);

  // Função para capturar o chat como imagem
  const generateImage = async () => {
    const chatElement = document.getElementById('messages');
    const canvas = await html2canvas(chatElement);
    const image = canvas.toDataURL('image/png');

    // Baixar a imagem
    const link = document.createElement('a');
    link.href = image;
    link.download = 'chat.png';
    link.click();
  };

  return (
    <BaseConfiguracoes>
      <Breadcrumb title={'Chat Interativo'} itens={['Configurações', 'Chat Interatvo']} />
      <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
            height: '60vh', // Diminuímos a altura do chat
            maxWidth: '500px', // Ajuste para telas menores
            border: '2px solid #ccc',
            borderRadius: '10px',
            overflow: 'hidden',
          }}
        >
          <div
            id="messages"
            ref={messageContainerRef}
            style={{
              padding: '20px',
              backgroundColor: '#f1f1f1',
              overflowY: 'auto',
              flexGrow: 1,
            }}
          >
            {messages.map((msg, index) => (
              <p key={index} style={{ textAlign: msg.sender === 'user' ? 'right' : 'left' }}>
                <strong>{msg.sender === 'user' ? 'Você' : 'ChatGPT'}:</strong> {msg.text}
              </p>
            ))}
            {botTyping && <p><strong>ChatGPT:</strong> Digitando...</p>}
            {currentText && <p><strong>ChatGPT:</strong> {currentText}</p>}
          </div>
          <div style={{ padding: '10px', backgroundColor: '#fff' }}>
            <textarea
              rows="2"
              style={{ width: '100%', padding: '10px' }}
              placeholder="Digite sua mensagem..."
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
            />
            <button
              style={{
                width: '100%',
                padding: '10px',
                backgroundColor: '#007BFF',
                color: 'white',
                border: 'none',
                cursor: 'pointer',
                marginTop: '10px',
              }}
              onClick={sendMessage}
              disabled={loading}
            >
              {loading ? 'Carregando...' : 'Enviar'}
            </button>
            <button
              onClick={generateImage}
              style={{
                marginTop: '10px',
                padding: '10px',
                backgroundColor: '#28a745',
                color: 'white',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              Gerar Imagem
            </button>
          </div>
        </div>
      </div>
    </BaseConfiguracoes>
  );
};

export default ChatInterativo;

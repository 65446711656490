import Breadcrumb from '../../../../../components/breadcrumb'
import BaseChamadaPublica from '../../baseChamadaPublica';
import { store } from '../../../../../store/index'
import Table from '../../../../../components/table';
import { getHistorico } from '../../../../../services/processos/chamadaPublica';
import { useMutation, useQuery } from 'react-query';
import { useMemo, useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormInput from '../../../../../components/formInput';

const ChamadaPublicaInscricoesHistoricoPage = () => {

const { id } = useParams();

  const { state } = useLocation();
  const navigate = useNavigate();

const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [data, setData] = useState([]);

  const renderStatus = (status) => {
    switch (status) {
        case 1:
            return 'LISTA DE ESPERA';
        case 2:
            return 'CONVOCADO';
        case 3:
            return 'MATRICULADO';
        case 4:
            return 'NÃO COMPARECIDO';
        case 5:
            return 'INDEFERIDO';
        case 6:
            return 'CANCELADO';
        case 7:
            return 'DESISTÊNCIA';
        case 8:
            return 'MATRICULADO POR OUTRA OPÇÃO';
        case 9:
            return 'CONVOCADO POR OUTRA OPÇÃO';
        case 10:
          return 'MATRICULADO EM OUTRA OPÇÃO';
        default:
            return '';
    }
};
  console.log(state)
  const { isError, refetch } = useQuery(
  'getHistorico', () => getHistorico(id, state.unidade),
  {
    retry: 0,
    enabled: true,
    onSuccess: (data) => {
      setData(data);
    }
  });

  const columns = useMemo(
    () => [
      {
        Header: 'Data', accessor: 'criacao',
        Cell: ({ row }) => (
          row.original.criacao.split('T')[0].split('-').reverse().join('/') + ' às ' + row.original.criacao.split('T')[1].split('.')[0]
        )
      },
      {
        Header: 'Status', accessor: 'status',
        Cell: ({ row }) => (
          renderStatus(row.original.status)
        )
      },
      { Header: 'Por', accessor: 'usuario' },
      { Header: 'Observação', accessor: 'observacao' },
    ],
    []
  )
  


  const currentUser = store.getState()['user']['user']


  const { register, handleSubmit, setValue, formState: { isValid, errors }, watch } = useForm({ mode: "onSubmit" });

  // PARA TESTAR A INTEGRAÇÃO, SÓ DESCOMENTAR A LINHA "MUTATE" ABAIXO

  useEffect(() => {
    debugger
    setValue('id', id)
    setValue('nome', state.nome)
    setValue('cpf', state.cpf)
    setValue('unidade', state.unidade)
    setValue('unidadeNome', state.unidadeNome)
    setValue('usuarioId', currentUser?.servidorId)
  }, [setValue])


return (
    <BaseChamadaPublica>
      <Breadcrumb
        title={'Inscrições'}
      itens={['CHAMADA PÚBLICA', 'INSCRIÇOES', 'HISTÓRICO']}
      />

<div className="row" style={{marginTop: 20}}>
        {/* <div className="col-12"> */}
          <div className='col-2'>
                <FormInput
                  register={register}
                  errors={errors}
                  group=""
                  atribute="id"
                  label="ID"
                  readOnly
                  required={false}

                />
              </div>
              <div className='col-4'>
                <FormInput
                  register={register}
                  errors={errors}
                  group=""
                  atribute="nome"
                  label="Nome"
                  readOnly
                  required={false}
                  
                />
              </div>
              <div className='col-6'>
                <FormInput
                  register={register}
                  errors={errors}
                  group=""
                  atribute="unidadeNome"
                  label="Unidade"
                  readOnly
                  required={false}
                />
              </div>

        {/* </div> */}
      </div>
            

    <div className="row" style={{marginTop: 20}}>
        <div className="col-12">
          <div className="card">
            <Table
              columns={columns}
              data={data.data ? data.data : []}
              hasPagination
              limit={limit}
              setLimit={setLimit}
              skip={skip}
              setSkip={setSkip}
              totalItems={data?.total}
              hiddenColluns={["id", "passo2CpfAluno"]}
            />
          </div>
        </div>
      </div>
      </BaseChamadaPublica>


)
}

export default ChamadaPublicaInscricoesHistoricoPage
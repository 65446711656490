import React from "react";

class HeaderImpressao extends React.Component {
  render() {
    const { data } = this.props;

    let aulasDadas = [];
    let totalAulas = 0;
    

if (data !== undefined) {
  data.forEach(trimestre => {
    let totalAulas = 0;
    let { aulasDadasLinguaPortugues, aulasDadasMatematica, aulasDadasCiencias, aulasDadasHistoria, aulasDadasGeografia, aulasDadasArtes, aulasDadasEducacaoFisica } = trimestre;
    totalAulas += aulasDadasLinguaPortugues + aulasDadasMatematica + aulasDadasCiencias + aulasDadasHistoria + aulasDadasGeografia + aulasDadasArtes + aulasDadasEducacaoFisica;  
    aulasDadas.push({ aulasDadasLinguaPortugues, aulasDadasMatematica, aulasDadasCiencias, aulasDadasHistoria, aulasDadasGeografia, aulasDadasArtes, aulasDadasEducacaoFisica, totalAulas });
    
  });
}

let totalDiaLetivos = totalAulas % 1 === 0 ? (totalAulas / 5).toFixed(0) : (totalAulas / 5).toFixed(2);


    return (
      <table border="1" className="striped-table">
        <thead>
          <tr>
            <th colSpan="8">1º TRIMESTRE</th>
            <th colSpan="8">2º TRIMESTRE</th>
            <th colSpan="8">3º TRIMESTRE</th>
            <th rowSpan="1" colSpan={2}>TOTAL ANUAL</th>
          </tr>
          {/* <tr>
            <th colSpan="5">HORAS-AULAS</th>
            <th colSpan="5">HORAS-AULAS</th>
            <th colSpan="5">HORAS-AULAS</th>
          </tr> */}
          <tr>
            <th>LP</th>
            <th>MAT</th>
            <th>CIÊN</th>
            <th>HIST</th>
            <th>GEO</th>
            <th>ARTE</th>
            <th>ED FÍS</th>
            <th>TOTAL</th>
            <th>LP</th>
            <th>MAT</th>
            <th>CIÊN</th>
            <th>HIST</th>
            <th>GEO</th>
            <th>ARTE</th>
            <th>ED FÍS</th>
            <th>TOTAL</th>
            <th>LP</th>
            <th>MAT</th>
            <th>CIÊN</th>
            <th>HIST</th>
            <th>GEO</th>
            <th>ARTE</th>
            <th>ED FÍS</th>
            <th>TOTAL</th>
            <th>HA</th>
            <th>DL</th>
          </tr>
        </thead>
        <tbody>
  <tr>
    {/* Render columns for 1st trimester */}
    <td>{aulasDadas[0].aulasDadasLinguaPortuguesa !== -1 ? aulasDadas[0].aulasDadasLinguaPortuguesa : ''}</td>
    <td>{aulasDadas[0].aulasDadasMatematica !== -1 ? aulasDadas[0].aulasDadasMatematica : ''}</td>
    <td>{aulasDadas[0].aulasDadasCiencias !== -1 ? aulasDadas[0].aulasDadasCiencias : ''}</td>
    <td>{aulasDadas[0].aulasDadasHistoria !== -1 ? aulasDadas[0].aulasDadasHistoria : ''}</td>
    <td>{aulasDadas[0].aulasDadasGeografia !== -1 ? aulasDadas[0].aulasDadasGeografia : ''}</td>
    <td>{aulasDadas[0].aulasDadasArtes !== -1 ? aulasDadas[0].aulasDadasArtes : ''}</td>
    <td>{aulasDadas[0].aulasDadasEducacaoFisica !== -1 ? aulasDadas[0].aulasDadasEducacaoFisica : ''}</td>
    <td>{aulasDadas[0].totalAulas !== -1 ? aulasDadas[0].totalAulas : ''}</td>

    {/* Render columns for 2nd trimester */}
    <td>{aulasDadas[1].aulasDadasLinguaPortuguesa !== -1 ? aulasDadas[1].aulasDadasLinguaPortuguesa : ''}</td>
    <td>{aulasDadas[1].aulasDadasMatematica !== -1 ? aulasDadas[1].aulasDadasMatematica : ''}</td>
    <td>{aulasDadas[1].aulasDadasCiencias !== -1 ? aulasDadas[1].aulasDadasCiencias : ''}</td>
    <td>{aulasDadas[1].aulasDadasHistoria !== -1 ? aulasDadas[1].aulasDadasHistoria : ''}</td>
    <td>{aulasDadas[1].aulasDadasGeografia !== -1 ? aulasDadas[1].aulasDadasGeografia : ''}</td>
    <td>{aulasDadas[1].aulasDadasArtes !== -1 ? aulasDadas[1].aulasDadasArtes : ''}</td>
    <td>{aulasDadas[1].aulasDadasEducacaoFisica !== -1 ? aulasDadas[1].aulasDadasEducacaoFisica : ''}</td>
    <td>{aulasDadas[1].totalAulas !== -1 ? aulasDadas[1].totalAulas : ''}</td>

    {/* Render columns for 3rd trimester */}
    <td>{aulasDadas[2].aulasDadasLinguaPortuguesa !== -1 ? aulasDadas[2].aulasDadasLinguaPortuguesa : ''}</td>
    <td>{aulasDadas[2].aulasDadasMatematica !== -1 ? aulasDadas[2].aulasDadasMatematica : ''}</td>
    <td>{aulasDadas[2].aulasDadasCiencias !== -1 ? aulasDadas[2].aulasDadasCiencias : ''}</td>
    <td>{aulasDadas[2].aulasDadasHistoria !== -1 ? aulasDadas[2].aulasDadasHistoria : ''}</td>
    <td>{aulasDadas[2].aulasDadasGeografia !== -1 ? aulasDadas[2].aulasDadasGeografia : ''}</td>
    <td>{aulasDadas[2].aulasDadasArtes !== -1 ? aulasDadas[2].aulasDadasArtes : ''}</td>
    <td>{aulasDadas[2].aulasDadasEducacaoFisica !== -1 ? aulasDadas[2].aulasDadasEducacaoFisica : ''}</td>
    <td>{aulasDadas[2].totalAulas !== -1 ? aulasDadas[2].totalAulas : ''}</td>
      <td>{totalAulas}</td>
      <td>{totalDiaLetivos}</td>
    </tr>
</tbody>


      </table>
    );
  }
}

export default HeaderImpressao;

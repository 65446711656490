import api from "..";

async function getEstados() {
    return await api.get('endereco/estados').then(async res => {
      return res.data.data
    });
  
  };

  async function getCidades(estadoId) {
    return await api.get(`endereco/cidades/${estadoId}`).then(async res => {
      return res.data.data
    })
  }

  async function getLocation(cep) {
    return await api.get(`endereco/cep/${cep}`).then(async res => {
      return res.data
    })
  }
  
  

export {
    getEstados,
    getCidades,
    getLocation
}
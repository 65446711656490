import React, { useEffect } from 'react'
import FormInput from '../../../../../../../components/formInput'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import urlsAdministrativo from '../../../../../urlsAdministrativo'
import { useQuery, useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { postFornecedorCadastro, putFornecedorCadastro, getFornecedor } from '../../../../../../../services/administrativo/patrimonio-fornecedores'

export default function Cadastro() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { id } = useParams()

  const { data: fornecedorData, isLoading, isFetched } = useQuery('getFornecedor', () => getFornecedor(id), {
    enabled: id ? true : false,
    retry: false,
  })

  useEffect(() => {
    if (id) {
      console.log('State: ', state)
      setValue('cnpj', state.cnpj.replaceAll('.', '').replace('/', '').replace('-', ''));
      setValue('nomeFantasia', state.nomeFantasia)
      setValue('id', id)
    }
  }, [id, navigate, state])

  const { mutate } = useMutation(id ? putFornecedorCadastro : postFornecedorCadastro, {
    onSuccess: () => {
      if (id) {
        toast.success('Fornecedor atualizado com sucesso.')
      } else {
        toast.success('Fornecedor criado com sucesso.')
        navigate(urlsAdministrativo.patrimonioControleFornecedores)
      }
    },
    onError: error => {
      toast.error('Algo deu errado. Tente novamente mais tarde');
    }
  })

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { isValid, errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      nomeFantasia: '',
      cnpj: ''
    }
  })

  const onSubmit = (data) => {
    if (id) {
      mutate({ id, nomeFantasia: data.nomeFantasia.toUpperCase(), cnpj: data.cnpj.toUpperCase() })
    } else {
      mutate({ nomeFantasia: data.nomeFantasia.toUpperCase(), cnpj: data.cnpj.toUpperCase() })
    }
  }


  return (
    <div
      className="tab-pane fade show active"
      id="cadastro"
      role="tabpanel"
      aria-labelledby="cadastro-tab"
    >
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="card-body">

          <div className="row">
            {id && (
              <div className="col-md-1">
                <FormInput
                  register={register}
                  errors={errors}
                  atribute="id"
                  label="ID"
                  readOnly={true}
                  required={id ? true : false}
                />
              </div>
            )}

            <div className={id ? "col-md-8" : "col-md-9"}>
              <FormInput
                register={register}
                errors={errors}
                atribute="nomeFantasia"
                label="Nome fantasia"
              />
            </div>

            <div className="col-md-3">
              <FormInput
                register={register}
                errors={errors}
                mask="99.999.999/9999-99"
                atribute="cnpj"
                label="CNPJ"
              />
            </div>

          </div>

        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end'
          }}
        >
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => navigate(urlsAdministrativo.patrimonioControleFornecedores)}
            style={{ marginRight: '8px' }}
          >
            Voltar
          </button>

          <button
            type="submit"
            className="btn btn-success"
            disabled={!isValid}
          >
            {id ? 'Atualizar' : 'Cadastrar'}
          </button>
        </div>
      </form>
    </div>
  )
}

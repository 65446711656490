import Breadcrumb from "../../components/breadcrumb";
import BaseConfiguracoes from "./baseConfiguracoes";
import { useQuery } from 'react-query';

const HomeConfiguracoes = () => {
 

  return (
    <BaseConfiguracoes>
      <Breadcrumb title={'Configurações'} itens={['Adminitração']} />
    </BaseConfiguracoes>
  )
};

export default HomeConfiguracoes;
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { criarGrupoProdutos, atualizaGrupoProdutos } from '../../../../../services/administrativo/patrimonio'
import { useMutation } from 'react-query'
import BaseAdministrativo from '../../../baseAdministrativo'
import Categoria from './tab/categoria'
import urlsAdministrativo from '../../../urlsAdministrativo'
import { toast } from 'react-toastify'


const CategoriaProdutosForm = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { state } = useLocation()

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid, errors }
  } = useForm({
    mode: 'all'
  })

  const { mutate, isLoading } = useMutation(id ? atualizaGrupoProdutos : criarGrupoProdutos, {
    onSuccess: message => {
      toast.success(message);
      navigate(urlsAdministrativo.patrimonioCategoriaProdutos)
    },
    onError: error => {
      toast.error('Algo deu errado. Tente novamente mais tarde');
    }
  })

  useMemo(() => {
    (async () => {
      if (state) {
        const { teste } = state
        if (id && teste) {
          const { descricao, status } = teste
          setValue('id', id)
          setValue('descricao', descricao)
          setValue('status', status)
        }
      }
    })()
  }, [id, state, setValue])

  const onSubmit = (data) => {

    if (data.descricao !== '') {
      if (id) {
        mutate({ id, descricao: data.descricao.toUpperCase(), status: data?.status === "true" ? true : false })
      } else {
        mutate({ descricao: data.descricao.toUpperCase(), status: data?.status === "true" ? true : false })
      }
    } else {
      toast.error('Confira os campos obrigatórios e tente novamente.')
    }
  }


  return (
    <BaseAdministrativo>
      <div className="row">
        <div className="mailbox-container">
          <div className="card">
            <div className="container-fluid">
              <div className="row">
                <div className="mailbox-open-content col-md-12 m-0 p-3">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="categoria-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#categoria"
                        type="button"
                        role="tab"
                        aria-controls="categoria"
                        aria-selected="true"
                      >
                        Categoria
                      </button>
                    </li>
                  </ul>
                  <form id="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="tab-content" id="myTabContent">
                      <Categoria
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        watch={watch}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => navigate(urlsAdministrativo.patrimonioCategoriaProdutos)}
                        style={{ marginRight: '8px' }}
                      >
                        Voltar
                      </button>

                      <button
                        type="submit"
                        className="btn btn-success"
                        disabled={!isValid}
                      >
                        {id ? 'Atualizar' : 'Cadastrar'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseAdministrativo>
  )

};

export default CategoriaProdutosForm;
import api from "..";

export class LocationApi {
  async searchByCep(cep, signal) {
    cep = cep.replace(/\D/g, '');
    const url = `${process.env.REACT_APP_API_URL}/endereco/cep`;

    return api.get(`${url}/${cep}`, {
      signal,
    });
  }
}

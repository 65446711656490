import api from "..";

async function getQuadros(unidadeId, escolaridadeId, turnoId, turmaId, skip, limit) { // busca quadros disponíveis
  return await api
  .get(`quadrohorario?unidadeId=${unidadeId}&escolaridadeId=${escolaridadeId}&turnoId=${turnoId}&turmaId=${turmaId}&skip=${skip}&limit=${limit}`)
    .then(async (res) => {
      return res.data;
    });
};

async function getQuadrosTurma(turmaId) { // busca quadros disponíveis por turma
  return await api
    .get(`quadrohorario/turma/${turmaId}`)
    .then(async (res) => {
      return res.data.data;
    });
};

async function getQuadro(id) { // buscar quadro especifico
  return await api
    .get(`disciplinaquadrohorario/modelo/${id}`)
    .then(async (res) => {
      console.log('res: ', res)
      const { data } = res.data;
      return data;
    });
}

async function getQuadroDocente(servidorId, unidadeId) { // buscar quadro especifico
  return await api
    .get(`disciplinaquadrohorario/docente?servidorId=${servidorId}&unidadeId=${unidadeId}`)
    .then(async (res) => {
      console.log('res: ', res)
      const { data } = res.data;
      return data;
    });
}

async function deleteQuadro(id) { // deletar quadro especifico
  return await api
    .delete(`quadrohorario/${id}`)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function createQuadro(data) { // criar novo quadro
  return await api
    .post('quadrohorario', data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function updateQuadro(id, data) { // atualizar quadro especifico
  return await api
    .put(`quadrohorario/${id}`, data)
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
}

async function quadroHorarioServidorUnidades(servidorId) { // buscar quadro especifico
  return await api
    .get(`unidade/quadroHorario/${servidorId}`)
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
}

async function quadroHorarioServidorEscolaridade(servidorId, unidadeId) { // buscar quadro especifico
  return await api
    .get(`Escolaridade/quadroHorario/${servidorId}?unidadeId=${unidadeId}`)
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
}
async function quadroHorarioServidorTurno(servidorId, unidadeId, escolaridadeId) { // buscar quadro especifico
  return await api
    .get(`Turno/quadroHorario/${servidorId}?unidadeId=${unidadeId}&escolaridadeId=${escolaridadeId}`)
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
}
async function quadroHorarioServidorTurma(servidorId, unidadeId, escolaridadeId, turnoId) { // buscar quadro especifico
  return await api
    .get(`Turma/quadroHorario/${servidorId}?unidadeId=${unidadeId}&escolaridadeId=${escolaridadeId}&turnoId=${turnoId}`)
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
}

async function quadroHorarioAulas(turmaId, servidorId, disciplinaId) { 
  return await api
    .get(`QuadroHorario/quadroHorarioAulas/${turmaId}/${servidorId}/${disciplinaId}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
}



export {
  getQuadros,
  getQuadro,
  deleteQuadro,
  createQuadro,
  updateQuadro,
  getQuadrosTurma,
  quadroHorarioServidorEscolaridade,
  quadroHorarioServidorTurno,
  quadroHorarioServidorTurma,
  quadroHorarioServidorUnidades,
  quadroHorarioAulas,
  getQuadroDocente
}
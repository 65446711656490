import React, { useEffect, useRef, useState } from "react";
import { listarFichaIndividualInfantil, salvarResultadoFicha } from "../../../services/ficha-individual";
import BaseGestao from "../../gestao/baseGestao";
import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import Breadcrumb from "../../../components/breadcrumb";
import { useForm } from "react-hook-form";
import { useReactToPrint } from "react-to-print";
import "./style.css";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import SalvarFichaForm from "../salvarResultado/SalvarFichaForm";

const FichaIndividualPrimeiroAno = (gestao) => {
  const [ficha, setFicha] = useState(null); // Estado para armazenar a ficha individual
  const [loading, setLoading] = useState(true); // Estado para controlar o loading
  const BaseComponent = gestao.gestao ? BaseGestao : BaseGestaoUnidades;
  const BasePath = gestao.gestao ? "Gestão" : "Gestão Unidades";
  const printRef = useRef(null);
  const { id } = useParams(); 

  const resultados = [
    { label: "Transferido", value: 1 },
    { label: "Aprovado", value: 2 },
    { label: "Aprovado pelo Conselho de Classe", value: 3 },
    { label: "Reprovado", value: 4 },
    { label: "Evadido", value: 5 },
    { label: "Matrícula Indeferida", value: 6 },
    { label: "Temporalidade flexível", value: 7 },
    { label: "Promovido", value: 8 },
    { label: "Retido", value: 9 },
  ];

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    getValues,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      resultado: "",
      observacoes: "",
    },
  });

  const resultado = watch("resultado");
  const observacoes = watch("observacoes");

  const onSubmit = async () => {
    let body = {
      alunoId: id,
      turmaId: ficha.aluno.turmaId,
      resultado: resultado,
      observacao: observacoes,
      servidorId: 1,
      dataLancamento: new Date(),
      diasLetivos: 200,
    };

    let response = await salvarResultadoFicha(body);
    if (response) {
      toast.success("Resultado salvo com sucesso!");
    }
    else{
      toast.error("Erro ao salvar resultado");
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "FICHA_INDIVIDUAL_ALUNO",
  });

  useEffect(() => {
    // Função que busca os dados da API
    const fetchFicha = async () => {
      try {
        const response = await listarFichaIndividualInfantil(id);
        setFicha(response); // Armazena os dados no estado
        setLoading(false); // Desabilita o loading
        setValue("resultado", response?.resultadoFinal?.resultado ?? "");
        setValue("observacoes", response?.resultadoFinal?.observacao ?? "");
        // setValue("diasLetivos", response?.resultadoFinal?.diasLetivos ?? 0);
      } catch (error) {
        console.error("Erro ao buscar a ficha individual:", error);
        setLoading(false); // Desabilita o loading, mesmo em caso de erro
      }
    };

    fetchFicha(); // Chama a função assim que o componente é montado
  }, []);

  if (loading) {
    return <p>Carregando...</p>; // Exibe mensagem de carregamento
  }

  if (!ficha) {
    return <p>Erro ao carregar a ficha.</p>; // Exibe mensagem de erro, caso não tenha ficha
  }

  return (
    <BaseComponent>
      <Breadcrumb
        title={"Ficha Individual"}
        itens={[BasePath, "Alunos", "Documentos"]}
        onClickImprimir={handlePrint}
        imprimir={{ link: "fda", text: "Imprimir" }}
      />
      <style>
        {`
           @media print {
            .ficha-container {
              transform: scale(0.9);
              font-size: 0.85em;
            }
            .ficha-table td,
            .experiencia-table th,
            .experiencia-table td,
            .total-geral-table td,
            .resultado-final-table td,
            .assinaturas-table td {
              padding: 3px;
              margin: 0;
            }
            .signature-section {
              margin-top: 20px;
            }
            @page {
              size: landscape;
            }
          }
        `}
      </style>
       <SalvarFichaForm id={id} ficha={ficha} />
      <div className="ficha-container" ref={printRef}>
        <div className="d-flex justify-content-between pt-5">
          <div style={{ width: "250px" }}>
            <img
              src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`}
              width="100"
              height="100"
              alt=""
            ></img>
          </div>
          <div className="text-center">
            <h5>ESTADO DO RIO DE JANEIRO</h5>
            <h5>{process.env.REACT_APP_PREFEITURA}</h5>
            <h5>SECRETARIA MUNICIPAL DE EDUCAÇÃO</h5>
            <h5>{ficha.aluno.unidade}</h5>
          </div>
          <div>
            <img
              src={`/assets/images/prefeituras/logo-pmspa-azul.png`}
              width="250"
              height="80"
              alt=""
            ></img>
          </div>
        </div>
        <h2 className="mt-3">FICHA INDIVIDUAL DO {ficha?.aluno?.escolaridade} DO ENSINO FUNDAMENTAL</h2>
        <table className="ficha-table">
          <tbody>
            <tr>
              <td>ALUNO: {ficha.aluno.nome}</td>
              <td style={{textAlign: 'left'}}>SEXO: {ficha.aluno.sexo}</td>
            </tr>
          </tbody>
        </table>
        <table className="ficha-table">
          <tbody>
            <tr>
              <td>DATA DE NASCIMENTO: {ficha.aluno.dataNascimento}</td>
              <td style={{textAlign: 'left'}}>CIDADE NASCIMENTO: {ficha.aluno.naturalidadeCidade}</td>
              <td style={{textAlign: 'left'}}>ESTADO NASCIMENTO: {ficha.aluno.naturalidadeEstado}</td>
            </tr>
          </tbody>
        </table>
        <table className="ficha-table mb-2">
          <tbody>
            <tr>
              <td>FILIAÇÃO 1: {ficha.aluno.filiacao1}</td>
              <td style={{textAlign: 'left'}}>FILIAÇÃO 2: {ficha.aluno.filiacao2}</td>
            </tr>
          </tbody>
        </table>

        <table className="experiencia-table">
          <thead>
            <tr>
              <th>TURMA: {ficha.aluno.turma}</th>
              <th>TURNO: {ficha.aluno.turno}</th>
              <th>MATRÍCULA: {ficha.aluno.numero}</th>
              <th>ANO LETIVO: {ficha.aluno.anoLetivo}</th>
            </tr>
          </thead>
        </table>
        <table className="experiencia-table mt-2">
          <thead>
            <tr>
              <th rowSpan="2">Componentes Curriculares</th>
              <th colSpan="3">FALTAS</th>
              <th colSpan="3">HORAS-AULAS/AULAS</th>
            </tr>
            <tr>
            <th>1º TRI</th>
              <th>2º TRI</th>
              <th>3º TRI</th>
              <th>1º TRI</th>
              <th>2º TRI</th>
              <th>3º TRI</th>
            </tr>
          </thead>
          <tbody>
            {ficha.camposDeExperiencias.map((campo, index) => (
              <tr key={index}>
               <td>{campo.campo}</td>
                <td>{campo.faltas.primeiroTrimestre}</td>
                <td>{campo.faltas.segundoTrimestre}</td>
                <td>{campo.faltas.terceiroTrimestre}</td>
                <td>{campo.horasAulas.primeiroTrimestre}</td>
                <td>{campo.horasAulas.segundoTrimestre}</td>
                <td>{campo.horasAulas.terceiroTrimestre}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <table className="ficha-table mt-2">
          <thead>
            <tr>
              <td>TOTAL DE FALTAS: {ficha.totalGeral.faltas}</td>
              <td>TOTAL DE HORAS: {ficha.totalGeral.horasAulas}</td>
              <td>
                PERCENTUAL DE FREQUÊNCIA:{" "}
                {ficha.totalGeral.percentualFrequencia}%
              </td>
              <td>
                CARGA HORÁRIA:{" "}
                {ficha.totalGeral.cargaHoraria}h
              </td>
            </tr>
          </thead>
        </table>

        <table className="resultado-final-table mt-2">
          <tbody>
            <tr>
              <td style={{ maxWidth: "120px" }}>
                RESULTADO:{" "}
                {ficha?.resultadoFinal?.resultado || 'N/A'}
              </td>
              <td>
  DATA: {ficha?.resultadoFinal?.dataLancamento ? new Date(ficha.resultadoFinal.dataLancamento).toLocaleDateString() : ''}
</td>

              <td style={{ maxWidth: "60px" }}>DIAS LETIVOS: {200}</td>
            </tr>
          </tbody>
        </table>

        <div className="d-flex mt-2" style={{ height: 80 }}>
          <div className="border-black w-25 d-flex justify-content-center align-items-center">
            OBSERVAÇÕES
          </div>
          <div className="d-flex align-items-center ps-2 border-black w-75">
            {observacoes}
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "end" }}>
          <span>
            RD - Relatório Descritivo | T - Trimestre | M.F - Média Final | T.F
            - Frequência | * - Componente curricular de matrícula facultativa
          </span>
        </div>

        <div className="signature-section">
          <div className="signature-field">
            <div className="signature-line"></div>
            <span>SECRETÁRIO(A) ESCOLAR</span>
          </div>
          <div className="signature-field">
            <div className="signature-line"></div>
            <span>DIREÇÃO</span>
          </div>
          <div className="signature-field">
            <div className="signature-line"></div>
            <span>INSPETOR(A) ESCOLAR</span>
          </div>
        </div>
      </div>
    </BaseComponent>
  );
};

export default FichaIndividualPrimeiroAno;

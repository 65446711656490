import React from 'react';
import { Routes, Route } from 'react-router-dom';
import RelatorioAlunosTransporte from '../../pages/relatorios/alunos/alunosTransporte';
import ReportCard from '../../pages/desenvolvimento';
import FichaIndividualInfantil from '../../pages/ficha-individual/infantil';
import EduAmigoFale from '../../pages/eduAmigo/fale';
import EduAmigoExplica from '../../pages/eduAmigo/explica';
import EduAmigoHome from '../../pages/eduAmigo/home';
import FichaIndividualFundamental from '../../pages/ficha-individual/fundamental';
import AtaResultadoFinalInfantil from '../../pages/ata-resultado-final/infantil';
import AtaResultadoFinalAnosIniciais from '../../pages/ata-resultado-final/anos-iniciais';
import AtaResultadoFinalAnosFinais from '../../pages/ata-resultado-final/anos-finais';
import AtaResultadoFinalPrimeiroAno from '../../pages/ata-resultado-final/primeiro-ano';
import AtaResultadoFinalEJAFase1E2 from '../../pages/ata-resultado-final/eja-fase-1-e-2';
import AtaResultadoFinalEJAFase3A5 from '../../pages/ata-resultado-final/eja-fase-3-a-5';

const defaultPath = '/desenvolvimento';

const DesenvolvimentoRoutes = () => (
    <React.Fragment>
        <Route exact path={`${defaultPath}/1`} element={ <ReportCard />}/>
        <Route exact path={`${defaultPath}/2`} element={ <FichaIndividualInfantil />}/>
        <Route exact path={`${defaultPath}/eduAmigo/fale`} element={ <EduAmigoFale />}/>
        <Route exact path={`${defaultPath}/eduAmigo/explica`} element={ <EduAmigoExplica />}/>
        <Route exact path={`${defaultPath}/eduAmigo`} element={ <EduAmigoHome />}/>
        <Route exact path={`${defaultPath}/3`} element={ <FichaIndividualFundamental />}/>
        <Route exact path={`${defaultPath}/ata-resultado-final-infantil`} element={ <AtaResultadoFinalInfantil /> } />
        <Route exact path={`${defaultPath}/ata-resultado-final-iniciais`} element={ <AtaResultadoFinalAnosIniciais /> } />
        <Route exact path={`${defaultPath}/ata-resultado-final-finais`} element={ <AtaResultadoFinalAnosFinais /> } />
        <Route exact path={`${defaultPath}/ata-resultado-final-primeiro-ano`} element={ <AtaResultadoFinalPrimeiroAno /> } />
        <Route exact path={`${defaultPath}/ata-resultado-final-eja-1-e-2`} element={ <AtaResultadoFinalEJAFase1E2 /> } />
        <Route exact path={`${defaultPath}/ata-resultado-final-eja-3-a-5`} element={ <AtaResultadoFinalEJAFase3A5 /> } />
       
        
    </React.Fragment>
);

export default DesenvolvimentoRoutes;

import api from "../..";

async function postFornecedorCadastro({ nomeFantasia, cnpj }) {
  return await api
    .post('fornecedor', { nomeFantasia, cnpj })
    .then(async (res) => {
      const data = res.data;
      return data;
    });
};

async function postFornecedorContato({ id, data }) {
  return await api
    .post(`fornecedor/contato/${id}`, data)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
};

async function postFornecedorContrato({ id, data }) {
  return await api
    .post(`fornecedor/contrato/${id}`, data)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
};

async function putFornecedorCadastro({ id, nomeFantasia, cnpj }) {
  return await api
    .put(`fornecedor/${id}`, { nomeFantasia, cnpj })
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function putFornecedorContato({ id, data }) {
  return await api
    .put(`fornecedor/contato/${id}`, data)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function putFornecedorContrato({ id, data }) {
  return await api
    .put(`fornecedor/contrato/${id}`, data)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}


async function deleteFornecedor(id) {
  return await api
    .delete(`fornecedor/${id}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function getFornecedor(id) {
  return await api
    .get(`fornecedor/${id}`)
    .then(async (res) => {
      const data = res.data.data;
      return data;
    })
}

async function getFornecedores(skip = 0, limit = 20) {
  return await api
    .get(`fornecedor/skip=${skip}&limit=${limit}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

export {
  postFornecedorCadastro,
  postFornecedorContato,
  postFornecedorContrato,
  putFornecedorContato,
  putFornecedorCadastro,
  putFornecedorContrato,
  deleteFornecedor,
  getFornecedores,
  getFornecedor
}
const baseUrl = '/biblioteca';

const urlsBiblioteca = {
    dashboard: baseUrl,
    livros: `${baseUrl}/livros`,
    editar: `${baseUrl}/livros/editar/`,
    editarId: `${baseUrl}/livros/editar/:id`,
    adicionar: `${baseUrl}/livros/adicionar`,
    emprestimo: `${baseUrl}/livros/emprestimo`,
   


}

export default urlsBiblioteca;
import { useEffect, useMemo, useState } from 'react';
import { Breadcrumbs, Card, CardContent, Grid, Typography, FormControl, Autocomplete, TextField, IconButton, makeStyles, Alert } from '@mui/material';
import Table from '../../../../../components/table';
import BaseAdministrativo from '../../../baseAdministrativo';
import BaseGestaoUnidades from '../../../../gestao-unidades/baseGestaoUnidades';
import { useLocation, useNavigate } from 'react-router-dom';
import urlsAdministrativo from '../../../urlsAdministrativo';
import { useQuery, useMutation } from 'react-query';
import { impressoraChamadoMensagem, getChamadosImpressorasMensagens, impressoraChamadoStatus } from '../../../../../services/administrativo/patrimonio';
import { toast } from 'react-toastify';
import { getUnidades } from '../../../../../services/unidades';
import Breadcrumb from '../../../../../components/breadcrumb';
import { PlayCircleOutline, SupportAgent, Visibility } from '@mui/icons-material';
import { store } from '../../../../../store';
import './style.css';
import DialogChamadoMensagem from './components/modalMensagem';

const AcompanhamentoChamadosPage = (gestao, administrativo) => {
    const unidadeId = JSON.parse(localStorage.getItem('unidadeID'));
    const navigate = useNavigate();
    const [mensagem, setMensagem] = useState('');
    const [newData, setNewData] = useState([]);
    const [unidadeSelect, setUnidadeSelect] = useState(gestao.administrativo ? 0 : unidadeId);
    const [openModal, setOpenModal] = useState(false);
    const currentUser = store.getState()["user"]["user"];
    const { state } = useLocation();
    const [status, setStatus] = useState(state.status);
    const [statusInformado, setStatusInformado] = useState(0);


    const { refetch: refetchData, isLoading } = useQuery(
        'getChamadosImpressorasMensagens',
        () => getChamadosImpressorasMensagens(state.id),
        {
            enabled: true,
            retry: true,
            onSuccess: (data) => {
                console.log(data);
                setNewData(data);
            },
        }
    );

    useEffect(() => {
        refetchData();
    }, []);

    let Base = gestao.administrativo ? BaseAdministrativo : BaseGestaoUnidades;
    let title = gestao.administrativo ? 'Administrativo' : 'Gestão de Unidades';
    let linkCadastrar = gestao.administrativo ? urlsAdministrativo.patrimonioControleImpressorasCadastrar : '';

    const enviarMensagem = async () => {
        var response = await impressoraChamadoMensagem(
            {
                id: 0,
                chamadoId: state.id,
                servidorId: currentUser?.servidorId,
                mensagem: mensagem,
            }
        );

        setOpenModal(false);
        if (response) {
            toast.success("Mensagem salva com sucesso!");
            refetchData();
            setMensagem('');
        } else {
            toast.error('Houve um problema ao tentar salvar mensagem!');
            setMensagem('');
        }
    }

    const enviarMensagemStatus = async (statusParam = null) => {
        if (statusParam != 2 && !openModal) {
            setStatusInformado(statusParam);
            setOpenModal(true);
        } else {
            setStatus(statusInformado == 0 ? statusParam : statusInformado);
            var response = await impressoraChamadoStatus(
                {
                    status: statusInformado == 0 ? statusParam : statusInformado,
                    id: 0,
                    chamadoId: state.id,
                    servidorId: currentUser?.servidorId,
                    mensagem: state.status == 1 ? 'Iniciando Atendimento' : mensagem,
                }
            );

            setOpenModal(false);
            if (response) {
                toast.success(
                    statusInformado == 2 || statusParam == 2 ? 'Chamado iniciado' :
                        statusInformado == 3 || statusParam == 3 ? 'Mensagem enviada com sucesso' :
                            statusInformado == 4 || statusParam == 4 ? 'Chamado concluido com sucesso' :
                                statusInformado == 5 || statusParam == 5 ? 'Chamado cancelado com sucesso' :
                                    "Mensagem salva com sucesso!");
                refetchData();
                setMensagem('');
                setStatusInformado(0);
            } else {
                toast.error('Houve um problema ao tentar executar ação!');
                setMensagem('');
            }
        }


    }

    return (
        <Base>
            <DialogChamadoMensagem
                isOpen={openModal}
                setOpenModal={setOpenModal}
                mensagem={mensagem}
                setMensagem={setMensagem}
                enviarMensagem={statusInformado == 0 || !gestao.administrativo ? enviarMensagem : enviarMensagemStatus}
                status={statusInformado}
            ></DialogChamadoMensagem>

            <Breadcrumb title={'Acompanhamento Chamados'} itens={[title, 'Patrimônio', 'Impressoras', 'Chamados', 'Acompanhamento']} />
            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", marginTop: 10, marginBottom: 20, }}>
                <div style={{ marginTop: 6, marginLeft: 10, display: "flex", alignItems: "flex-start" }}>
                    {
                        !gestao.administrativo && (
                            <button className="btn btn-sm btn-primary" style={{ marginRight: 10 }}
                                onClick={() => setOpenModal(true)}
                            > Enviar mensagem
                            </button>
                        )
                    }

                    {gestao.administrativo && (
                        <>
                            {
                                (status == 1 || status == 2 || status == 3) && (
                                    <>
                                        <button className="btn btn-sm btn-primary" style={{ marginRight: 10 }}
                                            onClick={() => {
                                                if (status != 1) {
                                                    setStatusInformado(status == 2 || status == 3 ? 4 : 0)
                                                    setOpenModal(true)
                                                } else {
                                                    enviarMensagemStatus(2)
                                                }
                                            }}
                                        > {status == 1 ? 'Iniciar Atendimento' : 'Concluir Atendimento'}
                                        </button>
                                    </>
                                )
                            }


                            {(status == 2 || status == 3) && (
                                <button className="btn btn-sm btn-success" style={{ marginRight: 10 }}
                                    onClick={() => enviarMensagemStatus(3)}
                                > {'Enviar mensagem para solicitante'}
                                </button>
                            )}

                            {
                                (status == 1 || status == 2 || status == 3) && (
                                    <>
                                        <button className="btn btn-sm btn-danger"
                                            onClick={() => enviarMensagemStatus(5)}
                                        > Cancelar
                                        </button>
                                    </>
                                )
                            }
                        </>
                    )}

                </div>
            </div>
            <Card>
                <CardContent style={{ backgroundColor: 'transparent' }}>
                    <div className='row'>
                        <div className='col-8'>
                            <div className='bg-header'>
                                <h5>Mensagens</h5>
                            </div>
                            <div className='content-chamado'>
                                <div className='row' style={{ backgroundColor: '#F3F3F3' }}>
                                    <div className='col-12'>
                                        <p style={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center' }}>RESUMO DO CHAMADO</p>
                                    </div>
                                </div>
                                <div className='row' style={{ backgroundColor: '#F3F3F3' }}>
                                    <p><strong>Título: </strong>{state.titulo}</p>
                                    <p><strong>Descrição: </strong>{state.descricao}</p>
                                </div>
                                <hr />
                                <div className='lista-mensagens'>
                                    {
                                        newData?.data?.map((row) => {
                                            return (<>
                                                <div className='row'>
                                                    <div className='col-9'>
                                                        <p style={{ fontSize: 13, fontWeight: 'bold' }}>{row.unidade} - <span style={{ fontWeight: 'normal' }}>{row.servidor}</span></p>
                                                    </div>
                                                    <div className='col-3'>
                                                        <p style={{ textAlign: 'right', marginRight: 10 }}>{row.dataMensagem.split("T")[0].split("-").reverse().join("/")} {row.dataMensagem.split("T")[1].substring(0, 5)}</p>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <p>{row.mensagem}</p>
                                                </div>
                                                <hr />
                                            </>)
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='bg-header'>
                                <h5>Informações do Chamado</h5>
                            </div>
                            <div className='content-chamado'>
                                <div className='row'>
                                    <dt class="col-5">
                                        Responsável:
                                    </dt>
                                    <dd class="col-5">
                                        {state.nomeServidor}
                                    </dd>
                                </div>
                                <div className='row'>
                                    <dt class="col-5">
                                        Criado em:
                                    </dt>
                                    <dd class="col-5">
                                        {state.dataEvento}
                                    </dd>
                                </div>
                                <div className='row'>
                                    <dt class="col-5">
                                        Impressora Marca:
                                    </dt>
                                    <dd class="col-5">
                                        {state.marca}
                                    </dd>
                                </div>
                                <div className='row'>
                                    <dt class="col-5">
                                        Impressora Modelo:
                                    </dt>
                                    <dd class="col-5">
                                        {state.modelo}
                                    </dd>
                                </div>
                                <div className='row'>
                                    <dt class="col-5">
                                        Impressora S/N:
                                    </dt>
                                    <dd class="col-5">
                                        {state.numeroSerie}
                                    </dd>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </Base >
    );
};

export default AcompanhamentoChamadosPage;

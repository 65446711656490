import Breadcrumb from "../../components/breadcrumb";
import BaseDocente from "./baseDocente";
import Spinner from '../../components/spinner';
import { useEffect, useState } from "react";
import api from '../../services'
import { store } from "../../store";

const HomeDocente = () => {
  const unidadeID = localStorage.getItem('unidadeID');
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false)
  const currentUser = store.getState()['user']['user']

  

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      const response = await api.get(`/estatistica/docente/${currentUser?.servidorId}`);
      setData(response.data.data)
      setIsLoading(false)
    }
    fetchData()
  }, [])

  return (
    <BaseDocente>
      <Breadcrumb title={'Informações Gerais'} itens={['Docente', 'Dashboard']} />
      {
        isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="row">
              <div className="col-xl-4">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-gray">
                        <i className="material-icons-outline md-assignment_ind"></i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                      <span className="widget-stats-title">Alunos</span>
                        <span className="widget-stats-amount">{data?.totalAlunos}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-success">
                        <i className="material-icons-outline md-assignment_ind"></i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Turmas</span>
                        <span className="widget-stats-amount">{data?.totalTurmas}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-danger">
                        <i className="material-icons-outline md-school"></i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Escolas</span>
                        <span className="widget-stats-amount">{data?.totalUnidades}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

             
            </div>
          </>
        )}
    </BaseDocente>
  )
};

export default HomeDocente;
import React, { useState, useEffect } from "react";
import BaseGestao from "../../gestao/baseGestao";
import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import { useNavigate, useLocation } from "react-router-dom";
import Breadcrumb from "../../../components/breadcrumb";
import { getAluno } from "../../../services/alunos";
import { useQuery } from "react-query";
import urlsGestao from "../../gestao/urlsGestao";
import urlsGestaoUnidades from "../../gestao-unidades/urlsGestaoUnidades";
import { Web } from "@mui/icons-material";
import WebcamComponent from "../../../utils/webcamComponent";

const AdicionarFotoAluno = (gestao) => {

    const navigate = useNavigate();
  const { state } = useLocation();
  const BaseComponent = gestao.gestao ? BaseGestao : BaseGestaoUnidades;
  const BasePath = gestao.gestao ? "Gestão" : "Gestão Unidades";
  const [aluno, setAluno] = useState({});
  const [showFoto, setShowFoto] = useState(false);

  const { data, loadingAluno } = useQuery(
    "getAluno",
    () => state.alunoId !== undefined && getAluno(state.alunoId),
    {
      retry: false,
      enabled: true,
      onSuccess: (response) => {
        setAluno({
          ...response,
        //   unidade: state.aluno.unidade,
        //   escolaridade: state.aluno.escolaridade,
        //   turno: state.aluno.turno,
        //   turma: state.aluno.turma,
        });
        if (response.fotoPerfil) {
          setShowFoto(true);
        }
      },
    }
  );

  useEffect(() => {
    if (state.id !== undefined && data === undefined) {
      getAluno(state.id);
    }
  }, []);

  function cadastrarFoto() {
    setShowFoto(false);
    }

    return (
        <BaseComponent>
            <Breadcrumb title={"Foto"} itens={[BasePath, "Alunos", "Foto"]} />

            {data?.fotoPerfil && showFoto && (
                <img
                    src={data.fotoPerfil}
                    alt="Foto do estudante"
                    style={{
                        width: "480px",
                        height: "400px", // Fixed height to ensure the image is square
                        borderRadius: "12px",
                        objectFit: "cover", // Ensures the image covers the entire element while maintaining aspect ratio
                        objectPosition: "center", // Centers the image within the element
                    }}
                />
            )}

            {showFoto === false &&(
            <WebcamComponent alunoId={state?.alunoId || 0}  showCaptureButton={true} showCompareButton={false} />

            )}
                        {showFoto &&(
                            <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              marginRight: 0,
                              marginTop: 20,
                            }}
                          >
                            <button
                              style={{
                                marginRight: 20,
                                marginTop: 20,
                                height: 36,
                              }}
                              type="button"
                              className="btn btn-warning"
                              onClick={() => navigate(-1)}
                            >
                              Voltar
                            </button>
                            <button
                            //   type="submit"
                              className="btn btn-success"
                              style={{
                                marginTop: "20px",
                              }}
                              onClick={() => cadastrarFoto()}
                            >
                              Cadastrar Nova Foto
                            </button>
                          </div>


                        )}
                        

        </BaseComponent>
    );
    };

export default AdicionarFotoAluno;
import api from "../..";

async function postMovimentacao(data) {
  return await api
    .post('patrimonio/controleestoque', data)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
};

async function getMovimentacoes() {
    return await api
      .get(`patrimonio/controleestoque/skip=${0}&limit=${20}`)
      .then(async (res) => {
        const data = res.data;
        return data;
      });
  };

export {
    postMovimentacao,
    getMovimentacoes
}
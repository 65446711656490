import React from "react";

const Verso = ({ data }) => {
  return (
    <div
      className="id-card"
      style={{
        width: "100%",
        height: "100%",
        borderRadius: "10px",
        border: "1px solid #000",
        padding: "0px",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div
        className="header"
        style={{
          backgroundColor: "#003366",
          color: "#FFCC00",
          padding: "10px",
          borderRadius: "8px 8px 0 0",
          textAlign: "center",
        }}
      >
        <h1 style={{ display: "flex", fontSize: "18px", height: "30px", justifyContent: "center", alignItems: "center" }}>
          VERSO DA CARTEIRINHA
        </h1>
      </div>
      <div
        className="content"
        style={{
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div className="section" style={{ marginBottom: "10px" }}>
          <p style={{ margin: "2px 0", fontSize: "11px" }}>
            <strong>Quem pode buscar:</strong>
          </p>
          <p style={{ margin: "2px 0", fontSize: "11px" }}>
            I. {data?.transporte?.desembarqueNome},{" "}
            {data?.transporte?.desembarqueParentesco}
          </p>
          <p style={{ margin: "2px 0", fontSize: "11px" }}>
            II. {data?.transporte?.desembarqueNome2},{" "}
            {data?.transporte?.desembarqueParentesco2}
          </p>
        </div>
        <div className="section" style={{ marginBottom: "10px" }}>
          <p style={{ margin: "2px 0", fontSize: "11px" }}>
            <strong>Contato de Emergência:</strong>
          </p>
          <p style={{ margin: "2px 0", fontSize: "11px" }}>
            {data?.transporte?.emergenciaNome},{" "}
            {data?.transporte?.emergenciaTelefone},{" "}
            {data?.transporte?.emergenciaParentesco}
          </p>
        </div>
        <div className="section" style={{ marginBottom: "10px" }}>
          <p style={{ margin: "2px 0", fontSize: "11px" }}>
            <strong>Tipo Sanguíneo:</strong> {data?.transporte?.tipoSanguineo}
          </p>
          <p style={{ margin: "2px 0", fontSize: "11px" }}>
            <strong>Alergia a Medicamentos:</strong>{" "}
            {data?.transporte?.possuiAlergiaMedicamentos === 0
              ? "NÃO"
              : "SIM - "}{" "}
            {data?.transporte?.possuiAlergiaMedicamentos === 1
              ? data?.transporte?.medicamentos
              : ""}
          </p>
          <p style={{ margin: "2px 0", fontSize: "11px" }}>
          <strong>Validade:</strong> 31/12/{new Date().getFullYear()}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Verso;

const baseUrl = '/processo-seletivo-servidores';

const UrlsProcessoSeletivoServidores = {
    dashboard: baseUrl,
    inscricoes: `${baseUrl}/inscricoes`,
    inscricoesAtualizar: `${baseUrl}/inscricoes/atualizar`,
    formulario: `${baseUrl}/formulario`,
    formularioPreenchimento: `${baseUrl}/formulario/preenchimento`,
    formularioVisualizacao: `${baseUrl}/formulario/visualizacao/`,
    formularioVisualizacaoId: `${baseUrl}/formulario/visualizacao/:id`,

}

export default UrlsProcessoSeletivoServidores;
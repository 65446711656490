import React, { useEffect, useState } from 'react';
import { TextField, Autocomplete, FormControl, Grid, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { getAlunoUniforme, postAlunoUniforme } from '../../../../services/alunos';
import { store } from '../../../../store';
import { toast } from 'react-toastify';

export default function AlunoUniforme({ register, alunoId }) {
    const camisaBermudaOptions = ["1", "2", "3", "4", "6", "8", "10", "12", "14", "16", "P", "M", "G", "GG", "XG"];
    const calcadoOptions = ["17/18", "19/20", "21/22", "23/24", "25/26", "27/28", "29/30", "31/32", "33/34", "35/36", "37/38", "39/40", "41/42", "43/44"];
    const currentUser = store.getState()["user"]["user"];
    const servidorId = currentUser?.servidorId;

    const [data, setData] = useState({
        tamanhoCamiseta: "",
        tamanhoBermudaCalca: "",
        tamanhoCalcado: "",
        camisetaEntregue: false,
        bermudaCalcaEntregue: false,
        calcadoEntregue: false,
        dataEntregaCamiseta: "",
        dataEntregaCalcado: "",
        dataEntregaBermudaCalca: "",
        servidorEntregaCamiseta: "",
        servidorEntregaBermudaCalca: "",
        servidorEntregaCalcado: "",
        servidorEntregaCamisetaNome: "",
        servidorEntregaBermudaCalcaNome: "",
        servidorEntregaCalcadoNome: "",
    });

    // Define individual fetch status flags for each item
    const [fetchStatus, setFetchStatus] = useState({
        camisetaEntregueFetch: false,
        bermudaCalcaEntregueFetch: false,
        calcadoEntregueFetch: false,
    });

    const fetchData = async () => {
        try {
            const response = await getAlunoUniforme(alunoId);
            if (response.statusCode === 404) {
                console.warn("No uniform data found for alunoId:", alunoId);
            } else {
                setData(response?.data);

                // Set individual fetch flags based on whether each 'entregue' field has data
                setFetchStatus({
                    camisetaEntregueFetch: !!response?.data?.camisetaEntregue,
                    bermudaCalcaEntregueFetch: !!response?.data?.bermudaCalcaEntregue,
                    calcadoEntregueFetch: !!response?.data?.calcadoEntregue,
                });
            }
        } catch (error) {
            console.error("Error fetching uniform data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [alunoId]);

    function onChangeEntregue(entregueKey, newValue) {
        setData((prev) => ({ ...prev, [entregueKey]: newValue }));
    }

    const renderRow = (label, tamanhoOptions, tamanhoKey, entregueKey, dataKey, servidorKey, fetchKey) => (
        <Grid container spacing={2} alignItems="center" style={{ marginBottom: '16px' }}>
           <Grid item xs={2}>
    <TextField
        value={label.toUpperCase()}
        variant="outlined"
        InputProps={{
            readOnly: true,
            sx: {
                backgroundColor: '#1976D2',
                color: 'white',
            }
        }}
        fullWidth
        sx={{
            '& .MuiInputBase-root': {
                backgroundColor: '#1976D2',
                color: 'white',
            },
            '& .MuiInputBase-input': {
                color: 'white',
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#1976D2',
            }
        }}
    />
</Grid>


            <Grid item xs={2}>
                <Autocomplete
                    options={tamanhoOptions}
                    value={data[tamanhoKey] || ""}
                    onChange={(event, newValue) => setData((prev) => ({ ...prev, [tamanhoKey]: newValue }))}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={`Tamanho ${label}`}
                            {...register(tamanhoKey)}
                        />
                    )}
                    disableClearable
                    fullWidth
                />
            </Grid>

            <Grid item xs={2}>
                <Autocomplete
                    options={[
                        { value: true, label: "SIM" },
                        { value: false, label: "NÃO" }
                    ]}
                    value={data[entregueKey] === true ? { value: true, label: "SIM" } : { value: false, label: "NÃO" }}
                    onChange={(event, newValue) => setData((prev) => ({ ...prev, [entregueKey]: newValue?.value }))}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={`Entregue?`}
                            {...register(entregueKey)}
                        />
                    )}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    disableClearable
                    fullWidth
                />
            </Grid>

            {/* Show this section only if the specific 'entregue' fetch status is true */}
            {data[entregueKey] && fetchStatus[fetchKey] && (
                <>
                    <Grid item xs={3}>
    <TextField
        label={`Data e Hora de Entrega ${label}`}
        type="datetime-local"
        value={data[dataKey] ? data[dataKey].split(".")[0] : ""}  // Format to "YYYY-MM-DDTHH:mm"
        onChange={(e) => setData((prev) => ({ ...prev, [dataKey]: e.target.value }))}
        InputLabelProps={{ shrink: true }}
        fullWidth
        {...register(dataKey)}
    />
</Grid>

                    <Grid item xs={3}>
                        <TextField
                            label={`Servidor Entrega ${label}`}
                            value={data[servidorKey] || ""}
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            {...register(servidorKey)}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );

    const handleSave = async () => {
        try {
            const { dataEntregaCamiseta, dataEntregaCalcado, dataEntregaBermudaCalca, servidorEntregaCamiseta, servidorEntregaBermudaCalca, servidorEntregaCalcado, servidorEntregaCamisetaNome, servidorEntregaBermudaCalcaNome, servidorEntregaCalcadoNome, ...filteredData } = data;
            const postData = {
                alunoId,
                ...filteredData,
                servidorId,
            };
            const response = await postAlunoUniforme(postData);

            if (response.statusCode === 200 || response.statusCode === 201) {
                toast.success("Uniforme salvo com sucesso!");
            }
            console.log("Save response:", response);

            // Refetch data after saving
            await fetchData();
        } catch (error) {
            console.error("Error saving uniform data:", error);
            toast.error("Erro ao salvar uniforme!");
        }
    };

    return (
        <div className="card-body">
            {renderRow("Camisa", camisaBermudaOptions, "tamanhoCamiseta", "camisetaEntregue", "dataEntregaCamiseta", "servidorEntregaCamisetaNome", "camisetaEntregueFetch")}
            {renderRow("Bermuda", camisaBermudaOptions, "tamanhoBermudaCalca", "bermudaCalcaEntregue", "dataEntregaBermudaCalca", "servidorEntregaBermudaCalcaNome", "bermudaCalcaEntregueFetch")}
            {renderRow("Calçado", calcadoOptions, "tamanhoCalcado", "calcadoEntregue", "dataEntregaCalcado", "servidorEntregaCalcadoNome", "calcadoEntregueFetch")}
            
            <Grid container justifyContent="flex-end" style={{ marginTop: '16px' }}>
                <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={handleSave}>
                    Salvar
                </Button>
            </Grid>
        </div>
    );
}

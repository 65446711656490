import React from 'react';
import BaseReconhecimentoFacial from "../baseReconhecimentoFacial";
import Breadcrumb from "../../../components/breadcrumb"; // Adjust the path as necessary
import WebcamComponent from '../../../utils/webcamComponent';
import BaseGestao from '../../gestao/baseGestao';
import BaseGestaoUnidades from '../../gestao-unidades/baseGestaoUnidades';
import BaseConfiguracoes from '../../configuracoes/baseConfiguracoes';
import { store } from '../../../store';
import { useLocation } from 'react-router-dom';

const WebCamPage = (gestao) => {

  const currentUser = store.getState()['user']['user']
  const { state } = useLocation();

  let BaseComponent;
  let BasePath;
  let showCaptureButton = false;
  let showCompareButton = false;
  let usuarioId = currentUser.servidorId;
  const unidadeID = localStorage.getItem('unidadeID');

  if (gestao.gestao) {
    BaseComponent = BaseGestao;
    BasePath = 'Gestão';
    showCaptureButton = true;
  }
  if (!gestao.gestao && !gestao.docente) {
    BaseComponent = BaseGestaoUnidades;
    BasePath = 'Gestão Unidades';
    showCaptureButton = true;
  }
  if (gestao.configuracao) {
    BaseComponent = BaseConfiguracoes;
    BasePath = 'Configurações';
    showCaptureButton = true;
    showCompareButton = true;
  }
  if (gestao.reconhecimentoFacial) {
    BaseComponent = BaseReconhecimentoFacial;
    BasePath = 'Reconhecimento Facial';
    showCaptureButton = true;
    showCompareButton = true;
  }

  return (
    <BaseComponent>
      <Breadcrumb 
        title={BasePath} 
        itens={[BasePath, 'Foto Reconhecimento Facial']} 
      />
     <div>
      <WebcamComponent alunoId={state?.alunoId || 0} servidorId={state?.servidorId || 0} usuarioId={usuarioId} unidadeId={unidadeID} showCaptureButton={showCaptureButton} showCompareButton={showCompareButton} />
    </div>
    </BaseComponent>
  );
};

// captureButton, compareButton, alunoId, servidorId, usuarioId

export default WebCamPage;

import React, { useState, useEffect } from 'react'
import FormInput from '../../../../../../../components/formInput'
import { useQuery, useMutation } from 'react-query'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import { putFornecedorContrato, getFornecedor } from '../../../../../../../services/administrativo/patrimonio-fornecedores'
import urlsAdministrativo from '../../../../../urlsAdministrativo'
import { toast } from 'react-toastify'
import upperCaseSimple from '../../../../../../../utils/upperCaseSimple'
import removeEmpty from '../../../../../../../utils/removeEmpty'

export default function Contrato() {
  const navigate = useNavigate();
  const { id } = useParams()
  const [data, setData] = useState(null)

  const { data: dataFornecedor, isFetched } = useQuery('getFornecedor', (id) => getFornecedor(id), {
    enabled: id ? true : false,
    onSuccess: data => {
      setData(data);
      console.log('Data: ', data)
    }
  })

  const { mutate } = useMutation(putFornecedorContrato, {
    onSuccess: data => {
      toast.success('Fornecedor atualizado com sucesso.')
    },
    onError: data => {
      toast.error('Algo de errado aconteceu. Tente novamente mais tarde.')
    }
  })

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { isValid, errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      areaAtuacao: '',
      produto: '',
      referenciaComercial: '',
      clausulasEspeciais: ''
    }
  })

  useEffect(() => {
    if (data !== null) {
      setValue('areaAtuacao', data?.contrato?.areaAtuacao);
      setValue('produto', data?.contrato?.produto);
      setValue('referenciaComercial', data?.contrato?.referenciaComercial);
      setValue('clausulasEspeciais', data?.contrato?.clausulasEspeciais);
    }
  }, [data, isFetched])

  const onSubmit = (data) => {
    mutate({ id: id, data: upperCaseSimple(removeEmpty(data)) })
  }

  return (
    <div
      className="tab-pane fade"
      id="contrato"
      role="tabpanel"
      aria-labelledby="contrato-tab"
    >
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <FormInput
                register={register}
                errors={errors}
                atribute="areaAtuacao"
                label="Área de atuação"
                required={false}
              />
            </div>

            <div className="col-md-6">
              <FormInput
                register={register}
                errors={errors}
                atribute="produto"
                label="Produtos ou serviços oferecidos"
                required={false}
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: 30 }}>
            <div className="col-md-6">
              <FormInput
                register={register}
                errors={errors}
                atribute="referenciaComercial"
                label="Referências comerciais ou indicações de clientes atendidos"
                required={false}
              />
            </div>

            <div className="col-md-6">
              <FormInput
                register={register}
                errors={errors}
                atribute="clausulasEspeciais"
                label="Cláusulas especiais"
                required={false}
              />
            </div>
          </div>

        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end'
          }}
        >
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => navigate(urlsAdministrativo.patrimonioControleFornecedores)}
            style={{ marginRight: '8px' }}
          >
            Voltar
          </button>

          <button
            type="submit"
            className="btn btn-success"
            disabled={!isValid}
          >
            Atualizar
          </button>
        </div>
      </form>
    </div>
  )
}

import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from "../../../components/breadcrumb";
import BaseConfiguracoes from "../../configuracoes/baseConfiguracoes";
import urlsConfiguracoes from "../../configuracoes/urlsConfiguracoes";
import { useMutation } from 'react-query';
import { createEscolaridade, updateEscolaridade } from "../../../services/escolaridades";
import upperCaseSimple from "../../../utils/upperCaseSimple";
import removeEmpty from "../../../utils/removeEmpty";

const EscolaridadesAdicionarPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();

  const { mutate, isLoading } = useMutation(id ? updateEscolaridade : createEscolaridade, {
    onSuccess: (message) => {
      toast.success(message);
      navigate(urlsConfiguracoes.escolaridades);
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar cadastrar um escolaridade, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  const { register, handleSubmit, setValue, formState: { isValid, errors } } = useForm({
    mode: "onChange", defaultValues: {
      descricao: '',
      sigla: '',
      status: "1"
    }
  });

  const onSubmit = (data) => {
    if (id) {
      mutate({ id, data: upperCaseSimple(removeEmpty(data)) });
    } else {
      mutate(upperCaseSimple(removeEmpty(data)));
    }
  };

  useMemo(() => {
    (async () => {
      if (state) {
        const { escolaridade } = state;
        if (id && escolaridade) {
          const { descricao, sigla, status } = escolaridade;
          setValue('descricao', descricao);
          setValue('sigla', sigla);
          setValue('status', status);
        }
      }
    })();
  }, [id, state, setValue]);

  return (
    <BaseConfiguracoes>
      <Breadcrumb title={'Formulário Escolaridades'} itens={['Gestão', 'Secretaria', 'Escolaridades', 'Formulário']} />
      <form id='form' onSubmit={handleSubmit(onSubmit)}>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className="card-body">
                <>
                  <div className='row'>
                    <div className='col-12'>
                      <label htmlFor="descricao" className="form-label">Descrição</label>
                      <input type="text" className="form-control" id="descricao"
                        {...register("descricao", { required: true })} />
                      {errors.descricao?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>
                  </div>

                  <br />

                  <div className='row'>
                    <div className='col-12 '>
                      <label htmlFor="sigla" className="form-label">Sigla</label>
                      <input type="text" className="form-control" id="sigla"
                        {...register("sigla", { required: true })} />
                      {errors.sigla?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campoobrigatório!</div>}
                    </div>
                  </div>

                  <br />

                  <div className='row'>
                    <div className='col-12'>
                      <label htmlFor="status" className="form-label">Status</label>
                      <select className="form-select" id="status" {...register("status", { required: true })}>
                        <option value="1">Ativo</option>
                        <option value="0">Inativo</option>
                      </select>
                      {errors.status?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>
                  </div>
                </>
              </div>
              <div className='card-footer text-end'>
                <button type='submit' className='btn btn-success' disabled={!isValid || isLoading}>
                  {isLoading ? 'enviando...' : id ? 'Atualizar' : 'Cadastrar'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </BaseConfiguracoes>
  )

};

export default EscolaridadesAdicionarPage;
import React from "react";

const Frente = ({ data }) => {

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };



  return (
    <div
      className="id-card"
      style={{
        width: "100%",
        height: "100%",
        borderRadius: "10px",
        border: "1px solid #000",
        padding: "0px",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div
        className="header"
        style={{
          backgroundColor: "#003366",
          color: "#FFCC00",
          padding: "10px",
          borderRadius: "8px 8px 0 0",
          position: "relative",
        }}
      >
        <img
          src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`}
          alt="Logo"
          style={{
            position: "absolute",
            top: "3px",
            right: "5px",
            width: "50px",
            height: "auto",
          }}
        />
        <h1 style={{ margin: "0", fontSize: "18px", textAlign: "left" }}>
          IDENTIDADE ESTUDANTIL
        </h1>
        <h2 style={{ margin: "0", fontSize: "14px", textAlign: "left" }}>
          {process.env.REACT_APP_PREFEITURA}
        </h2>
      </div>
      <div className="content" style={{ display: "flex", padding: "10px", alignItems: "center" }}>
        <div className="photo" style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          {data?.fotoPerfil ? (
            <img
            src={data?.fotoPerfil}
            alt="Foto do estudante"
            style={{
              width: "120px",
              height: "100px", // Fixed height to ensure the image is square
              borderRadius: "12px",
              objectFit: "cover", // Ensures the image covers the entire element while maintaining aspect ratio
              objectPosition: "center", // Centers the image within the element
            }}
          />
          ) : (
            <img
              src="/assets/images/avatars/avatar-male.svg"
              alt="Foto do estudante"
              style={{
                width: "100px",
                height: "auto",
                borderRadius: "8px",
              }}
            />
          )}
        </div>
        <div className="details" style={{ flex: 2, paddingLeft: "10px" }}>
          <p style={{ margin: "2px 0", fontSize: "11px" }}>
            <strong>Nome:</strong> {data?.nome}
          </p>
          <p style={{ margin: "2px 0", fontSize: "11px" }}>
            <strong>CPF:</strong> {data?.cpf}
          </p>
          <p style={{ margin: "2px 0", fontSize: "11px" }}>
            <strong>RG:</strong> {data?.rg}
          </p>
          <p style={{ margin: "2px 0", fontSize: "11px" }}>
            <strong>Data Nascimento:</strong>{" "}
            {formatDate(data?.numeroCarteiraNascimento)}
          </p>
          <p style={{ margin: "2px 0", fontSize: "11px" }}>
            <strong>Escolaridade:</strong> {data?.escolaridade}
          </p>
          <p style={{ margin: "2px 0", fontSize: "11px" }}>
            <strong>Turma:</strong> {data?.turma}
          </p>
          <p style={{ margin: "2px 0", fontSize: "11px" }}>
            <strong>Unidade:</strong> {data?.unidade}
          </p>
        </div>
      
      </div>
      <div
        className="footer"
        style={{
          textAlign: "right",
          padding: "2px",
          fontSize: "16px",
          fontWeight: "bold",
        }}
      >
        {process.env.REACT_APP_SECRETARIA_MUNICIPAL}{" "}
        <span style={{ color: "#FFCC00" }}>{new Date().getFullYear()}</span>
      </div>
    </div>
  );
};

export default Frente;

import { useMemo, useEffect, useState } from 'react'
import Breadcrumb from '../../../../components/breadcrumb';
import Table from '../../../../components/table';
import BaseAdministrativo from '../../baseAdministrativo';
import urlsAdministrativo from '../../urlsAdministrativo';
import { useQuery, useMutation } from 'react-query';
import { useNavigate } from "react-router-dom";
import Spinner from '../../../../components/spinner';
import { getCardapios, getCardapioImprimir } from '../../../../services/nutricao';

const NutricaoCardapio = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [skip, setSkip] = useState(0);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(20);
  const [ativo, setAtivo] = useState(true);
  const [descricao, setDescricao] = useState('');
  const [warningControl, setWarningControl] = useState(false)

  const { isLoading: loading, refetch } = useQuery('getCardapios', () => getCardapios(skip, limit), {
    onSuccess: (data) => {
        for (let i = 0; i < data.data.length; i++) {
            data.data[i].dataInicio = `${data.data[i].dataInicio.toString().split('T')[0].split('-')[2]}-${data.data[i].dataInicio.toString().split('T')[0].split('-')[1]}-${data.data[i].dataInicio.toString().split('T')[0].split('-')[0]}`;    
            data.data[i].dataFim = `${data.data[i].dataFim.toString().split('T')[0].split('-')[2]}-${data.data[i].dataFim.toString().split('T')[0].split('-')[1]}-${data.data[i].dataFim.toString().split('T')[0].split('-')[0]}`;    
        }
      setData(data.data);
      setTotal(data.total);
      setLimit(data.limit);
      setSkip(data.skip);
    },
    enabled: false,
    retry: true
  })

  const imprimir = async (id) =>{
    var itens = await getCardapioImprimir(id);
    localStorage.setItem('imprimirCardapio', JSON.stringify(itens.data));
    window.open(urlsAdministrativo.nutricaoCardapioImpressao, '_blank');
  }

//   const { data: dataReport } = useQuery('relatorioNacionalidades', () => relatorioNacionalidades(), {
//     onSuccess: (data) => {
//       debugger;
//     },
//     enabled: false,
//     retry: true
//   });

  useEffect(() => {
    refetch();
  }, [limit, skip,]);

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Escolaridade', accessor: 'nomeEscolaridade' },
      { Header: 'Unidade', accessor: 'nomeUnidade' },
      { Header: 'Data Inicio', accessor: 'dataInicio' },
      { Header: 'Data Fim', accessor: 'dataFim' },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <button type='button' onClick={(event) => imprimir(row.original.id)} className='btn btn-sm btn-success'><i className="material-icons"></i> Imprimir</button>
          </>
        )
      },
    ],
    [navigate, data]
  )

  const handleSearch = () => {
    refetch();
  }

  const consultaCaracter = () => {
    if (descricao.length >= 3) {
      refetch();
    }
  }

  const handleDownloadExcel = async () => {
    // var response = relatorioNacionalidades().then(response => {
    //   let fileName = `nacionalidades.xlsx`;
    //   if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //     // IE variant
    //     window.navigator.msSaveOrOpenBlob(
    //       new Blob([response.data], {
    //         type: 'application/pdf',
    //         encoding: 'UTF-8'
    //       }),
    //       fileName
    //     );
    //   } else {
    //     const url = window.URL.createObjectURL(
    //       new Blob([response], {
    //         type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    //         encoding: 'UTF-8'
    //       })
    //     );
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', fileName);
    //     document.body.appendChild(link);
    //     link.click();
    //     link.remove();
    //   }
    // });

  }

  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  return (
    <BaseAdministrativo>
      <Breadcrumb title={'Cardápio'} itens={['Administrativo', 'Nutrição', 'Cardápio']} actions={{ link: urlsAdministrativo.nutricaoCardapioCadastrar, text: "Novo Cardápio" }} />
      
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            {loading ? (
              <Spinner />
            ) : (
              <Table
                columns={columns}
                data={data}
                hasPagination
                limit={limit}
                setLimit={setLimit}
                skip={skip}
                setSkip={setSkip}
                totalItems={total}
              />
            )}
          </div>
        </div>
      </div>
    </BaseAdministrativo>
  )

};

export default NutricaoCardapio;

export const ocorrenciasOptions = [
    "ADIAMENTO DA ENTRADA EM EXERCÍCIO",
    "ADIAMENTO DE POSSE",
    "AFASTAMENTO PELO INSS",
    "APOSENTADORIA",
    "ATESTADO MÉDICO",
    "CESSÃO",
    "DEMISSÃO",
    "EXONERAÇÃO",
    "FALECIMENTO",
    "FALTA",
    "FÉRIAS",
    "FOLGA DO TRE",
    "INTEGRAL",
    "LICENÇA DE CASAMENTO",
    "LICENÇA MATERNIDADE",
    "LICENÇA PARA ADOTANTE",
    "LICENÇA PARA ALISTAR COMO ELEITOR",
    "LICENÇA PARA ATIVIDADE POLÍTICA",
    "LICENÇA PARA CAPACITAÇÃO",
    "LICENÇA PARA DOAÇÃO DE SANGUE",
    "LICENÇA PARA ESTUDO",
    "LICENÇA PARA MANDATO CLASSISTA",
    "LICENÇA PARA MANDATO ELETIVO",
    "LICENÇA PARA SERVIÇO MILITAR",
    "LICENÇA PARA TRATAMENTO DE SAÚDE",
    "LICENÇA PARA TRATAMENTO DE SAÚDE FAMILIAR",
    "LICENÇA PRÊMIO",
    "LICENÇA SEM VENCIMENTO",
    "OUTROS",
    "PERMUTA",
    "RESCISÃO",
    "SEM FREQUÊNCIA",
    "VACÂNCIA",
].sort();
import { useMemo, useState, useEffect } from 'react';
import BaseGestao from '../../gestao/baseGestao';
import BaseGestaoUnidades from '../../gestao-unidades/baseGestaoUnidades';
import Breadcrumb from "../../../components/breadcrumb";
import { useQuery } from 'react-query';
import Spinner from '../../../components/spinner';
import { useLocation, useNavigate } from "react-router-dom";
import Table from "../../../components/table";
import urlsGestao from '../../gestao/urlsGestao';
import urls from './../../../utils/urls';
import urlsGestaoUnidades from '../../gestao-unidades/urlsGestaoUnidades';
import api from '../../../services';
import { getAlunosByTurma } from '../../../services/alunos'
import createStorageReport from './../../../utils/reportStorage'

const TurmaAlunosPage = (gestao) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [data, setData] = useState([]);

  const [idTurma, setIdTurma] = useState(state?.id);
  const [skip, setSkip] = useState(0);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(50);
  const [renovacao, setRenovacao] = useState(localStorage.getItem("filtroTurmaRenovacao") || 0);

  const BaseComponent = gestao.gestao ? BaseGestao : BaseGestaoUnidades;
  const BasePath = gestao.gestao ? 'Gestão' : 'Gestão Unidades';
  const editarUrl = gestao.gestao ? urlsGestao.alunosEditar : urlsGestaoUnidades.alunosEditar;
  const evadirUrl = gestao.gestao ? urlsGestao.alunosEvadir : urlsGestaoUnidades.alunosEvadir;
  const turmasUrl = gestao.gestao ? urlsGestao.turmas : urlsGestaoUnidades.turmas;

  const { isError, isLoading, refetch } = useQuery(
    'getAlunosByTurma', () => getAlunosByTurma(idTurma, skip, limit, renovacao),
    {
      retry: 0,
      enabled: false,
      onSuccess: (data) => {
        setData(data.data);
        setTotal(data.total);
        setLimit(data.limit);
        setSkip(data.skip);
      }
    });

  useEffect(() => {
    refetch();
  }, [])

  // Tabela
  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Nome', accessor: 'nome' },
      { Header: 'Matricula', accessor: 'numeroMatricula' },
      {
        Header: 'PcD', accessor: 'pne',
        Cell: ({ row }) => (
          <span className={`badge bg-${row.original.pne ? 'success' : 'danger'}`}>
            {row.original.pne ? 'SIM' : 'NÃO'}
          </span>
        )
      },
      {
        Header: 'Nascimento', accessor: 'nascimento',
        Cell: ({ row }) => (
          row.original.nascimento.split('T')[0].split('-').reverse().join('/')
        )
      },
      { Header: 'CPF', accessor: 'cpf' },
      { Header: 'Unidade', accessor: 'unidade' },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) => (
          <>
            {row.original.turmaID !== 0 && (
              <button onClick={() => navigate(evadirUrl, {
                state: {
                  id: row.original.id,
                  aluno: {
                    nome: row.original.nome,
                    escolaridadeId: row.original.escolaridadeId,
                    escolaridade: row.original.escolaridade,
                  }
                }
              })} className='btn btn-sm btn-danger' style={{ marginRight: '13px' }}>
                <i className="material-icons md-remove"></i> excluir
              </button>
            )}
            <button
              onClick={() =>
                navigate(editarUrl + row.original.id, {
                  state: {
                    id: row.original.pessoaID,
                    aluno: {
                      nome: row.original.nome,
                      cpf: row.original.cpf,
                      responsavel: row.original.responsavel.nome
                    }
                  }
                })
              }
              className="btn btn-sm btn-primary"
            >
              <i className="material-icons md-edit_note"></i> editar
            </button>

          </>
        )
      }
    ],
    [navigate]
  )

  const clickImprimir = async () => {
    var data = await getAlunosByTurma(idTurma, 0, 99999, renovacao);
    var listaDados = [];
    data.data.map(x => {
      listaDados.push({ id: x.id, matricula :x.numeroMatricula, nome: x.nome, pne: x.pne ? 'SIM' : 'NÃO', nascimento: new Date(x.nascimento).toLocaleDateString('pt-BR') })
    });

    await createStorageReport({dados: listaDados, nomeTurma: state.turma.descricao, turnoId: state.turma.turnoID, escolaridadeId: state.turma.escolaridadeID});

    window.open(urls.relatorio, '_blank');

  }

  return (
    <BaseComponent>
      <Breadcrumb
        title={'Alunos Turma: ' + state.turma.descricao}
        itens={[BasePath, 'Turmas', , 'Alunos', 'Lista Geral']}
        voltar={{ link: turmasUrl, text: "Voltar" }}
        imprimir={{ link: urls.relatorio, text: "Imprimir" }}
        onClickImprimir={clickImprimir}

      />

<div className="form-group">
                    <label htmlFor="renovacao" className="form-label">
                      Renovação?
                    </label>
                    <select
                      className="form-select"
                      id="renovacao"
                      value={renovacao}
                      onChange={async (e) => {
                        await setRenovacao(e.target.value);
                        localStorage.setItem(
                          "filtroTurmaRenovacao",
                          e.target.value
                        );
                        refetch();
                      }}
                    >
                      <>
                        <option value={0}></option>
                        <option value={1}>SIM</option>
                        <option value={2}>NÃO</option>
                      </>
                    </select>
                  </div>
      <div className="row" style={{ marginBottom: 20 }}>


      </div>
      {
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <Table
                columns={columns}
                data={data ? data : []}
                hasPagination
                limit={limit}
                setLimit={setLimit}
                skip={skip}
                setSkip={setSkip}
                totalItems={total}
              />
            </div>
          </div>
        </div>
      }

    </BaseComponent >
  )
};
export default TurmaAlunosPage;

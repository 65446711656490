import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import FichaAvaliacaoTable from '../visualizacao/table';
import Signature from '../../../utils/signature';

const ImpressaoFichaAvaliacao = forwardRef((props, ref) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Ficha de Avaliação',
        // onAfterPrint: () => alert('Print dialog closed'),
    });

    useImperativeHandle(ref, () => ({
        print: handlePrint,
    }));

    const data = JSON.parse(localStorage.getItem('impressaoFichaAvaliacao'));

    if (!data || data.length == 0) return null;

    return (
        <div ref={componentRef}>
            <img src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`} height={90} className='center' style={{ marginBottom: '20px', marginTop: '0' }} alt="Logo" />
            <h4 style={{ textAlign: 'center' }}>TURMA {localStorage.getItem('impressaoFichaAvaliacaoTurma')} | COMPONENTE CURRICULAR: {localStorage.getItem('impressaoFichaAvaliacaoComponenteCurricular')}</h4>
            <div className="col-12">
                <FichaAvaliacaoTable data={data} />
                <p style={{ marginTop: 20 }}>Total Aulas Dadas:</p>
                <p style={{ marginTop: 20 }}>Encerrado Em:</p>
                <p style={{ marginTop: 20, marginBottom: 100 }}>Observações:</p>
                <Signature />
            </div>
        </div>
    );
});

export default ImpressaoFichaAvaliacao;

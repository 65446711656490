import React, { useEffect, useState } from 'react';
import { postNotaLinha } from '../../../services/ficha-avaliacao';
import { toast } from 'react-toastify';
import { Alert } from '@mui/material';

const TabelaFundamental = React.memo(({ alunos, disciplina, ciclo, turma, podeEditarNotas = true }) => {
    const [salvando, setSalvando] = useState(false);
    const [inputValues, setInputValues] = useState({});
    const [editaNotas, setEditaNotas] = useState(false);

    useEffect(() => {
        if (!alunos || alunos.length === 0) {
            return;
        }
        if (parseInt(ciclo) === 1 && podeEditarNotas === false) {
            setEditaNotas(true);
        }
        setInputValues(
            alunos.reduce((acc, row) => {
                acc[row.id] = {
                    ae: row.registro === true ? row.ae : '',
                    ad: row.registro === true ? row.ad : '',
                    recuperacao: row.registro === true ? row.recuperacao : '',
                    faltas: row.registro === true ? row.faltas : ''
                };
                return acc;
            }, {})
        );
    }, [ciclo, disciplina, alunos, podeEditarNotas]);

    function handleChange(row, field, value) {
        setInputValues({
            ...inputValues,
            [row.id]: {
                ...inputValues[row.id],
                [field]: value
            }
        });
    }

    async function handleSalvar(row) {
        handleBlur(row, { target: { value: inputValues[row.id].ae } }, 1);
        handleBlur(row, { target: { value: inputValues[row.id].ad } }, 2);
        handleBlur(row, { target: { value: inputValues[row.id].recuperacao } }, 3);
        handleBlur(row, { target: { value: inputValues[row.id].faltas } }, 4);

        if (inputValues[row.id].ae > 7 || inputValues[row.id].ad > 3 || inputValues[row.id].recuperacao > 7) {
            return;
        }

        setSalvando(true);
        try {
            let data = {
                alunoId: row.id,
                disciplinaId: disciplina,
                trimestreId: ciclo,
                turmaId: turma,
                ae: inputValues[row.id].ae === '' ? -1 : inputValues[row.id].ae,
                ad: inputValues[row.id].ad === '' ? -1 : inputValues[row.id].ad,
                recuperacao: inputValues[row.id].recuperacao === '' ? -1 : inputValues[row.id].recuperacao,
                faltas: inputValues[row.id].faltas 
            }
    
            const response = await postNotaLinha(data);
    
            if (response?.statusCode === undefined) {
                return;
            }
            if (response.statusCode === 200) {
                toast.success('Lançamento efetuado com sucesso!');
            } else {
                toast.error('Erro ao efetuar lançamento!');
            }
        } catch (error) {
            console.error(error);
            toast.error('Erro ao salvar notas!');
        } finally {
            setSalvando(false);
        }
    }

    async function handleBlur(row, e, tipoLancamento) {
        if (tipoLancamento === 1 && e.target.value > 7) {
            toast.warn('O valor máximo para AE é 7!');
            return;
        }

        if (tipoLancamento === 2 && e.target.value > 3) {
            toast.warn('O valor máximo para AD é 3!');
            return;
        }

        if (tipoLancamento === 3 && e.target.value > 7 && e.target.value !== '') {
            toast.warn('O valor máximo para recuperação é 7!');
            return;
        }
    }

    if (!alunos || alunos.length === 0) {
        return null; // Se 'alunos' for falsy ou vazio, não renderiza nada
    }

    return (
        <div>
            {
                editaNotas === true && (
                    <Alert severity="info" style={{ marginTop: 10, marginBottom: 20 }}>
                    Para lançamentos do primeiro ano, quanto os lançamentos são para 1 trimestre, não será lançado as notas, apenas as faltas.
                </Alert>
                )
            }
          
            <div className="table-responsive" key={ciclo}>
                <table className="table table-striped table-hover w-100" key={disciplina}>
                    <thead>
                        <tr className="text-center">
                            <th style={{ display: 'none' }} className="text-center">ID</th>
                            <th className="text-center">MATRÍCULA</th>
                            <th className="text-center">NOME</th>
                            <th className="text-center">AE</th>
                            <th className="text-center">AD</th>
                            <th className="text-center">RECUPERAÇÃO</th>
                            <th className="text-center">FALTAS</th>
                            <th className="text-center">AÇÕES</th>
                        </tr>
                    </thead>
                    <tbody>
                        {alunos.map((row) => (
                            <tr key={row.id} className="text-center">
                                <td style={{ display: 'none' }}>{row.id}</td>
                                <td>{row.matricula}</td>
                                <td>{row.nome}</td>
                                <td>
                                    <input
                                        className="form-control"
                                        type="number"
                                        aria-labelledby="aria-label"
                                        id={'ae' + row.id}
                                        name={'ae' + row.id}
                                        placeholder=""
                                        disabled={editaNotas}
                                        defaultValue={row.registro === true && row.ae > 0 ? row.ae : null}
                                        onChange={(e) => handleChange(row, 'ae', e.target.value)}
                                        onBlur={(e) => { handleBlur(row, e, 1) }}
                                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                    />
                                </td>
                                <td>
                                    <input
                                        className="form-control"
                                        type="number"
                                        aria-labelledby="aria-label"
                                        id={'ad' + row.id}
                                        name={'ad' + row.id}
                                        disabled={editaNotas}
                                        placeholder=""
                                        defaultValue={row.registro === true && row.ad > 0  ? row.ad : null}
                                        onChange={(e) => handleChange(row, 'ad', e.target.value)}
                                        onBlur={(e) => { handleBlur(row, e, 2) }}
                                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                    />
                                </td>
                                <td>
                                    <input
                                        className="form-control"
                                        type="number"
                                        aria-labelledby="aria-label"
                                        id={'recuperacao' + row.id}
                                        name={'recuperacao' + row.id}
                                        disabled={editaNotas}
                                        placeholder=""
                                        defaultValue={row.registro === true && row.recuperacao > 0 ? row.recuperacao : null}
                                        onBlur={(e) => { handleBlur(row, e, 3) }}
                                        onChange={(e) => handleChange(row, 'recuperacao', e.target.value)}
                                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                    />
                                </td>
                                <td>
                                    <input
                                        className="form-control"
                                        type="number"
                                        aria-labelledby="aria-label"
                                        id={'faltas' + row.id}
                                        name={'faltas' + row.id}
                                        placeholder=""
                                        defaultValue={row.registro === true ? row.faltas : null}
                                        onBlur={(e) => { handleBlur(row, e, 4) }}
                                        onChange={(e) => handleChange(row, 'faltas', e.target.value)}
                                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                    />
                                </td>
                                <td>
                                    <button
                                        style={{ marginTop: 15 }}
                                        type="button"
                                        className="btn"
                                        onClick={() => handleSalvar(row)}
                                        disabled={salvando}
                                    >
                                        Salvar
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
});

export default TabelaFundamental;

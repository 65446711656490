import { useMemo, useEffect, useState } from 'react'
import Breadcrumb from "../../../components/breadcrumb";
import Table from "../../../components/table";
import BaseConfiguracoes from '../../configuracoes/baseConfiguracoes';
import urlsConfiguracoes from '../../configuracoes/urlsConfiguracoes';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from 'react-query';
import { getAlunosDuplicado } from '../../../services/alunos';
import { useNavigate } from "react-router-dom";
import Spinner from '../../../components/spinner';
import urlsGestaoUnidades from "../../gestao-unidades/urlsGestaoUnidades";

const AlunosDuplicadoPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [unidadeId, setUnidadeId] = useState(
    JSON.parse(localStorage.getItem("unidadeID"))
  );
  const [skip, setSkip] = useState(sessionStorage.getItem('duplicados') ? JSON.parse(sessionStorage.getItem('duplicados')).skip : 0);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(sessionStorage.getItem('duplicados') ? JSON.parse(sessionStorage.getItem('duplicados')).limit : 20);
  const [ativo, setAtivo] = useState(true);
  const [descricao, setDescricao] = useState('');
  const [warningControl, setWarningControl] = useState(false);
  const [pesquisarAluno, setPesquisaAluno] = useState(sessionStorage.getItem('duplicados') ? JSON.parse(sessionStorage.getItem('duplicados')).aluno : "");
  const [pesquisaCPF, setpesquisaCPF] = useState(sessionStorage.getItem('duplicados') ? JSON.parse(sessionStorage.getItem('duplicados')).cpf : "");

  const { isLoading: loading, refetch } = useQuery('getAlunosDuplicado', () => getAlunosDuplicado(pesquisarAluno, pesquisaCPF, unidadeId, skip, limit, 0), {
    onSuccess: (data) => {
      setData(data?.data);
      setTotal(data?.total);
      setLimit(data?.limit);
      setSkip(data?.skip);
    },
    enabled: false,
    retry: false
  });


  useEffect(() => {
    refetch();
    recarregarFiltros();
  }, [limit, skip]);

  const recarregarFiltros = () => {
    if(sessionStorage.getItem('duplicados')){
      setPesquisaAluno(JSON.parse(sessionStorage.getItem('duplicados')).aluno);
      setpesquisaCPF(JSON.parse(sessionStorage.getItem('duplicados')).cpf);
      setSkip(JSON.parse(sessionStorage.getItem('duplicados')).skip);
      setLimit(JSON.parse(sessionStorage.getItem('duplicados')).limit);
      sessionStorage.removeItem('duplicados');
    }
  }

  function handleExportar() {
    getAlunosDuplicado(
      pesquisarAluno,
      pesquisaCPF,
      unidadeId,
      skip,
      limit,
      1
    ).then((response) => {
      if (response?.byteLength > 0) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'alunos-duplicados.xlsx');
        document.body.appendChild(link);
        link.click();
      } else {
        toast.error('Erro ao exportar os dados');
      }
    });
  }

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Nome', accessor: 'nome' },
      { Header: 'Cpf', accessor: 'cpf' },
      {
        Header: 'Nascimento', accessor: 'carteiraNascimento',
        Cell: ({ row }) =>
          row.original.carteiraNascimento.split("T")[0].split("-").reverse().join("/"),
      },
      { Header: 'Unidade', accessor: 'unidade' },
      { Header: 'Escolaridade', accessor: 'escolaridade' },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <button onClick={() => {
                  sessionStorage.setItem('duplicados',JSON.stringify(
                    {
                      aluno: pesquisarAluno,
                      cpf: pesquisaCPF,
                      skip: skip,
                      limit: limit
                    }
                  ));
                navigate(urlsGestaoUnidades.alunosEditar + row.original.id, {
                  state: {
                    id: row.original.pessoaID,
                    aluno: {
                      nome: row.original.nome,
                      cpf: row.original.cpf,
                      responsavel: row.original.responsavelNome,
                      status: row.original.status,
                    },
                  },
                });
            }} className='btn btn-sm btn-primary'>
              <i className="material-icons md-edit_note"></i> Editar
            </button>
            <button className='btn btn-sm btn-danger' onClick={() => {
              sessionStorage.setItem('duplicados',JSON.stringify(
                {
                  aluno: pesquisarAluno,
                  cpf: pesquisaCPF,
                  skip: skip,
                  limit: limit
                }
              ));

                navigate(urlsGestaoUnidades.alunosEvadir, {
                  state: {
                    id: row.original.id,
                    aluno: {
                      nome: row.original.nome,
                      responsavel: row.original.responsavelNome,
                      escolaridade: row.original.escolaridade,
                      escolaridadeId: row.original.escolaridadeId,
                    },
                  },
                });
            }}>
              <i className="material-icons md-delete"></i> excluir
            </button>
          </>
        )
      },
    ],
    [navigate, data]
  )

  return (
    <BaseConfiguracoes>
      <Breadcrumb title={'Alunos Duplicado'} itens={['Configurações', 'Parâmetros', 'Alunos Duplicado', 'Lista Geral']} hasExcelButton={true} onClickDownload={() => handleExportar()} />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginTop: 10,
          marginBottom: 20,
        }}
      >
        <div
          className="form-group"
          style={{ width: "100%", maxWidth: 390, marginRight: 10 }}
        >
          <input
            type="text"
            className="form-control"
            placeholder="CPF"
            value={pesquisaCPF}
            onChange={(e) => setpesquisaCPF(e.target.value)}
          />
        </div>

        <div
          className="form-group"
          style={{ width: "100%", maxWidth: 395, marginRight: 10 }}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Nome do Aluno"
            value={pesquisarAluno}
            onChange={(e) => setPesquisaAluno(e.target.value)}
          />
        </div>
        <div
          className="form-group"
          style={{
            marginTop: 6,
            marginLeft: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <button
            className="btn btn-sm btn-primary"
            onClick={() => refetch()}
          >
            <i className="material-icons md-search"></i> Buscar
          </button>
        </div>
      </div>
      
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            {loading ? (
              <Spinner />
            ) : (
              <Table
                columns={columns}
                data={data === undefined ? [] : data}
                hasPagination
                limit={limit}
                setLimit={setLimit}
                skip={skip}
                setSkip={setSkip}
                totalItems={total}
                validatePage={true}
              />
            )}
          </div>
        </div>
      </div>
    </BaseConfiguracoes>
  )

};

export default AlunosDuplicadoPage;

import styled, { keyframes, css } from 'styled-components'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: ${props => (props.open ? 'flex' : 'none')};
  flex-direction: ${props => (props.open ? 'column' : null)};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
  animation: ${fadeIn} 0.3s ease-in-out;
`;

export const Section = styled.section`
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 20px;
  width: 720px;
`;

export const Header = styled.header`
  display: flex;

  justify-content: center;

  width: 720px;

  background-color: white;

  border-top-right-radius: 5px;
  border-top-left-radius: 5px;

  padding: 8px 8px 0 0;

  border-bottom: 1px solid gray;
`;

export const Title = styled.h1`
  font-size: 24px;
  margin-left: 16px;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  color: red;
`;
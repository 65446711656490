import React from 'react';
import { Routes, Route } from 'react-router-dom';
import urlsReconhecimentoFacil from '../../pages/reconhecimento-facil/urlsReconhecimentoFacil';
import WebCamPage from '../../pages/reconhecimento-facil/webcam';
import HomeReconhecimentoFacial from '../../pages/reconhecimento-facil/homeReconhecimentoFacial';
import AlunosPage from '../../pages/alunos';
import ServidoresPage from '../../pages/servidores';
import ListagamReconhecimentoFacial from '../../pages/reconhecimento-facil/listagem';

const defaultPermisson = false;

const ReconhecimentoFacialRoutes = () => (
    <React.Fragment>
        <Route exact path={urlsReconhecimentoFacil.webcam} element={ <WebCamPage reconhecimentoFacial={true} />}/>
        <Route exact path={urlsReconhecimentoFacil.dashboard} element={ <HomeReconhecimentoFacial />}/>
        <Route exact path={urlsReconhecimentoFacil.alunos} element={ <AlunosPage reconhecimentoFacial={true} />}/>
        <Route exact path={urlsReconhecimentoFacil.servidores} element={ <ServidoresPage reconhecimentoFacial={true} />}/>
        <Route path={urlsReconhecimentoFacil.alunoCadastrarFoto} element={<WebCamPage reconhecimentoFacial={true} />} />
        <Route path={urlsReconhecimentoFacil.servidorCadastrarFoto} element={<WebCamPage reconhecimentoFacial={true} />} />
        <Route path={urlsReconhecimentoFacil.listagem} element={<ListagamReconhecimentoFacial reconhecimentoFacial={true} />} />
    </React.Fragment>
);

export default ReconhecimentoFacialRoutes;

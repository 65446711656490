import api from "..";

async function getBnccByDisciplinaAnoFaixa(disciplina, anoFaixa) {
  return await api
    .get(`gestao/BnccFundamental/getBnccByDisciplinaAnoFaixa?disciplina=${disciplina}&anoFaixa=${anoFaixa}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

export {
    getBnccByDisciplinaAnoFaixa,
}
import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography, TextField, Autocomplete } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getServidores } from '../../../services/servidores';
import './style.css'

// Estilos customizados para um layout mais moderno e minimalista
const useStyles = makeStyles(() => ({
    dialogTitle: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '1.5rem',
        color: '#333',
        marginBottom: '1rem',
    },
    dialogContent: {
        textAlign: 'center',
        fontSize: '1.1rem',
        color: '#666',
        padding: '20px',
    },
    dialogActions: {
        justifyContent: 'center',
        padding: '16px',
    },
    closeButton: {
        backgroundColor: '#C01515',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#B31515',
        },
        padding: '8px 24px',
        fontSize: '1rem',
        textTransform: 'none',
        borderRadius: '20px',
    },
    saveButton: {
        backgroundColor: '#1976d2',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#1565c0',
        },
        padding: '8px 24px',
        fontSize: '1rem',
        textTransform: 'none',
        borderRadius: '20px',
    },
    previewButton: {
        backgroundColor: '#23AE71',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#1F9E67',
        },
        padding: '8px 24px',
        fontSize: '1rem',
        textTransform: 'none',
        borderRadius: '20px',
    },
}));

const DialogFotoPreviewChat = ({ isOpen, setOpenModal, foto }) => {
    const classes = useStyles();

    const handleClose = () => {
        setOpenModal(false);
    };

    const retornaFoto = () => {
        if (foto != null)
            return 'data:'+foto.extensao+';base64,' + foto.arquivo;
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            PaperProps={{
                style: {
                    borderRadius: '12px',
                    padding: '10px',
                    width: '90vh',  // 90% of viewport width
                    height: 'auto' // Optional: 90% of viewport height
                }
            }}
        >
            <DialogTitle className={classes.dialogTitle}>Preview Imagem</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <div className="row">
                    <div className="col-12">
                        <img src={retornaFoto()} alt="Preview" style={{ maxWidth: '800px', width: '100%', height: 'auto' }} />
                    </div>
                </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={() => {
                  
                  const novaAba = window.open();
                  novaAba.document.write(`<img src="data:${foto.extensao};base64,${foto.arquivo}" alt="Imagem">`);
                  novaAba.document.close();
                }} className={classes.previewButton} >
                    Visualizar imagem
                </Button>
                <Button onClick={handleClose} className={classes.closeButton}>Fechar</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogFotoPreviewChat;

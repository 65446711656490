import { useMemo, useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify'
import Breadcrumb from "./../../../components/breadcrumb";
import urlsGestaoUnidades from '../../gestao-unidades/urlsGestaoUnidades';
import BaseGestaoUnidades from './../../gestao-unidades/baseGestaoUnidades';
import urls from "./../../../utils/urls";
import { createDiarioClasseAvaliacao, deleteDiarioClasseAvaliacao, listaDiarioClasseAvaliacao } from './../../../services/diario-classe';
import { useQuery } from 'react-query'
import Table from '../../../components/table'
import BaseGestao from '../../gestao/baseGestao';
import BaseDocente from '../../docente/baseDocente';
import urlsDocente from '../../docente/urlsDocente';
import urlsGestao from '../../gestao/urlsGestao';

const DiarioClasseAvaliacoesPage = (gestao) => {
  const [selectedRow, setSelectedRow] = useState({})
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const [listaAvaliacoes, setListaAvaliacoes] = useState([]);

  console.log(state)

  const [ciclo, setCiclo] = useState(0);
  const [descricao, setDescricao] = useState("");
  const [abreviacao, setAbreviacao] = useState("");
  const [pontuacaoMinima, setPontuacaoMinima] = useState(0);
  const [pontuacaoMaxima, setPontuacaoMaxima] = useState(0);
  const [media, setMedia] = useState(0);
  const [editandoAvaliacao, setEditandoAvaliacao] = useState(false);

  let BaseComponent;
  let BasePath;
  let notasAvaliacoesAlunosUrl;

  // Primeira condição para escolher entre BaseGestao e BaseGestaoUnidades
  if (gestao.gestao) {
    BaseComponent = BaseGestao;
    BasePath = 'Gestão'
    notasAvaliacoesAlunosUrl = urlsGestao.diarioClasseAvaliacoesAlunos
  } 
  if (!gestao.gestao && !gestao.docente) {
    BaseComponent = BaseGestaoUnidades;
    BasePath = 'Gestão Unidades'
    notasAvaliacoesAlunosUrl  = urlsGestaoUnidades.diarioClasseAvaliacoesAlunos
  }

  // Segunda condição para substituir BaseComponent por BaseDocente, se docente.docente for verdadeiro
  if (gestao.docente) {
    BaseComponent = BaseDocente;
    BasePath = 'Docente'
    notasAvaliacoesAlunosUrl = urlsDocente.notasAvaliacoesAlunos
  }

  useEffect(() => {
    getAvaliacoes();
  }, [])

  const getAvaliacoes = async () => {
    var response = await listaDiarioClasseAvaliacao(id, ciclo);

    setListaAvaliacoes([...response.data]);
  }

  const addAvaliacao = async () => {
    // console.log(pontuacaoMinima)
    // console.log(pontuacaoMaxima)
    // console.log(descricao)
    // console.log(abreviacao)
    // console.log(media)
    // if(media < pontuacaoMinima || media > pontuacaoMaxima){
    //   toast.error("A média deve ser maior que a pontuação mínima, e maior que a pontuação máxima.");
    //   return;
    // }

    if (parseInt(pontuacaoMaxima) > 10) {
      toast.error("A nota máximo permitida para avaliação é 10.");
      return;
    }

    if (parseInt(pontuacaoMaxima) < parseInt(pontuacaoMinima)) {
      toast.error("Pontuação máxima não pode ser menor que pontuação mínima!");
      return;
    }

    if (descricao == "" || abreviacao == "") {
      toast.error("Informe todos os campos para adicionar uma avaliação");
      return;
    }

    var request = {
      id: 0,
      idDiarioClasse: id,
      ciclo: ciclo,
      descricao: descricao.toLocaleUpperCase(),
      abreviacao: abreviacao.toLocaleUpperCase(),
    };

    var response = await createDiarioClasseAvaliacao(request);

    var avaliacao = {
      id: response.data.id,
      descricao: descricao.toLocaleUpperCase(),
      abreviacao: abreviacao.toLocaleUpperCase(),
    };

    setListaAvaliacoes([...listaAvaliacoes, avaliacao]);

    toast.success("Avaliação adicionada com sucesso!");

    setDescricao("");
    setAbreviacao("");
    setPontuacaoMaxima("");
    setPontuacaoMinima("");
    setMedia("");
  }

  const removeAvaliacao = async (id) => {
    await deleteDiarioClasseAvaliacao(id);
    var lista = listaAvaliacoes;
    for (let i = 0; i < lista.length; i++) {
      if (lista[i].id == id) {
        lista.splice(i, 1);
        break;
      }
    }
    toast.success("Avaliação removida com sucesso!");

    setListaAvaliacoes([...lista]);
  }

  useMemo(() => {
    (async () => {
      if (state) {
        if (id && state) {
         setCiclo(parseInt(state.ciclo));
        }
      }
    })();
  }, [id, state]);

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Descrição', accessor: 'descricao' },
      { Header: 'Abreviação', accessor: 'abreviacao' },
      // { Header: 'Pontuação Mínima', accessor: 'pontuacaoMinima' },
      // { Header: 'Pontuação Maxima', accessor: 'pontuacaoMaxima' },
      // { Header: 'Média', accessor: 'media' },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {
              <>
                {/* <button
                  className='btn btn-sm btn-danger'
                  onClick={() => {
                    const confirm = window.confirm('Tem certeza que deseja excluir essa avaliação?');
                    if (confirm) {
                      removeAvaliacao(row.original.id);
                    }
                  }}
                >
                  <i className="material-icons"></i> excluir
                </button> */}

                <button className='btn btn-sm btn-success'
                  onClick={() => navigate(notasAvaliacoesAlunosUrl + id, {
                    state: state.state
                  })}
                > <i className="material-icons"></i> lançamento </button>
              </>

            }
          </div>
        )
      },
    ],
    [navigate, listaAvaliacoes]
  );

  return (
    <BaseComponent>
      <Breadcrumb title={'Diário de Classe Avaliações'} itens={[BasePath, 'Diário de classe', 'Avaliações']} />
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className="card-body">
              <div className='row'>
                <div className='col-1'>
                  <label htmlFor="ciclo" className="form-label">Ciclo</label>
                  <input type='text' readOnly className='form-control' value={ciclo} />
                </div>
                <div className='col-5'>
                  <label htmlFor="descricao" className="form-label">Descrição</label>
                  <input type='text' className='form-control' value={descricao} onChange={(event) => setDescricao(event.target.value)} />
                </div>
                <div className='col-4'>
                  <label htmlFor="abreviacao" className="form-label">Abreviação</label>
                  <input type='text' className='form-control' value={abreviacao} onChange={(event) => setAbreviacao(event.target.value)} />
                </div>

                <div className="col-2" style={{ float: 'right' }}>
                  <button type="button" onClick={addAvaliacao} style={{ marginTop: 35, marginRight: 5 }} className='btn btn-sm btn-success'><i className="material-icons"></i>Adicionar</button>
                </div>
              </div>
              {/* <div className='row' style={{ marginTop: 30 }}>
                <div className='col-4'>
                  <label htmlFor="ciclo" className="form-label">Pontuação Mínima</label>
                  <input type='text' className='form-control' value={pontuacaoMinima} onChange={(event) => setPontuacaoMinima(event.target.value)} />
                </div>
                <div className='col-4'>
                  <label htmlFor="ciclo" className="form-label">Pontuação Máxima</label>
                  <input type='text' className='form-control' value={pontuacaoMaxima} onChange={(event) => setPontuacaoMaxima(event.target.value)} />
                </div>
                <div className='col-4'>
                  <label htmlFor="media" className="form-label">Média</label>
                  <input type='text' className='form-control' value={media} onChange={(event) => setMedia(event.target.value)} />
                </div>
              </div> */}
            </div>

            <hr />

            <div className="row">
              <Table columns={columns} data={listaAvaliacoes} selected={selectedRow} />
            </div>

            <div className='card-footer text-end'>
              <button type='button' className='btn btn-warning' onClick={() => navigate(-1, {

              })}>
                {'Voltar'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </BaseComponent >
  )

};

export default DiarioClasseAvaliacoesPage;
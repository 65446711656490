import React, { useState, useEffect } from 'react'
import urlsGestaoUnidades from '../../../gestao-unidades/urlsGestaoUnidades';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';
import { postRenovacaoAluno } from '../../../../services/alunos';
import { toast } from 'react-toastify'
import { useMutation } from 'react-query'
import upperCaseSimple from '../../../../utils/upperCaseSimple';
import removeEmpty from '../../../../utils/removeEmpty';
import { store } from '../../../../store';


export default function AbaMatriculas({ register, errors, alunoId, renovacaoOut}) {
  
  const navigate = useNavigate();
  const currentUser = store.getState()['user']['user']
  const [renovacao, setRenovacao] = useState(0)

  const { mutate, isLoading } = useMutation(postRenovacaoAluno, {
    onSuccess: data => {
      if (data == undefined || data == false) {
         toast.error('Ocorreu um erro no envio, favor tentar novamente mais tarde.')
      }
      debugger
      if (data != false && data != undefined) {
        toast.success('Protocolo gerado com sucesso.');
        if (renovacao == true){
          window.open(urlsGestaoUnidades.alunoRenovacaoVisualizacao + data.alunoId, '_blank');
        }
       
        // navigate(urls.relatoriosAlunosRenovacaoVisualizacao + data.alunoId)
      }


    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar gerar renovação de mátricula, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  if(renovacaoOut == true){
    setRenovacao(1)
  }

  const onSubmit = data => {

    let postData = {
      'alunoId' : parseInt(alunoId),
      'usuarioId': currentUser?.servidorId,
      'statusRenovacao' : parseInt(renovacao)
    }

    mutate(upperCaseSimple(removeEmpty(postData)));
    
  }

  return (

    // <div className="tab-pane fade" id="matriculas" role="tabpanel" aria-labelledby="matriculas-tab">
    <div className="card-body">
        <div className='row'>
        <div className="col-md-12">
            <div className="form-group">
              <label>Renovação de Matrícula?</label>
              <select
                className="form-select"
                id="renovacao" 
                {...register("renovacao", { required: true })}
                onChange={(e) => setRenovacao(e.target.value)}
              >
                {/* <option value="0"></option> */}
                <option value="0">NÃO</option>
                <option value="1">SIM</option>
              </select>
            </div>
          </div>
        </div>
        <button 
                    style={{marginRight: 20, marginTop: 20}} 
                    type='button' 
                    className='btn btn-info' 
                    onClick={() => onSubmit()}>
                        Gerar Protocolo
                      </button>
    </div>
    // </div>
  )
}

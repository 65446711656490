import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { criarGrupoProdutos, atualizaGrupoProdutos } from '../../../../../services/administrativo/patrimonio';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
  Box,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';

const StyledDialogTitle = styled(DialogTitle)({
  backgroundColor: '#1976d2', // Blue background
  color: '#fff', // White text color
  textAlign: 'center',
  padding: '16px 24px',
});

const CategoriaProdutosModal = ({ open, onClose, initialData }) => {
  const { register, handleSubmit, setValue, formState: { isValid, errors } } = useForm({ mode: 'all' });

  const isEditMode = Boolean(initialData?.id); // Determine mode based on initialData presence

  const { mutate, isLoading } = useMutation(isEditMode ? atualizaGrupoProdutos : criarGrupoProdutos, {
    onSuccess: (message) => {
      toast.success(message);
      onClose(); // Close modal on success
    },
    onError: () => {
      toast.error('Algo deu errado. Tente novamente mais tarde');
    },
  });

  useEffect(() => {
    if (initialData) {
      setValue('id', initialData.id);
      setValue('descricao', initialData.descricao);
      setValue('status', initialData.status ? "true" : "false");
    } else {
      setValue('descricao', '');
      setValue('status', "true"); // Default to 'Ativo' for add mode
    }
  }, [initialData, setValue]);

  const onSubmit = (data) => {
    const payload = {
      descricao: data.descricao.toUpperCase(),
      status: isEditMode ? data.status === 'true' : true, // Always send 'Ativo' in add mode
    };
    if (isEditMode) payload.id = initialData.id;
    mutate(payload);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <StyledDialogTitle>{isEditMode ? 'Atualizar Categoria de Produto' : 'Cadastrar Categoria de Produto'}</StyledDialogTitle>
      <DialogContent>
        <form id="form" onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="column" gap={2} mt={2}>
            {/* ID Field - Read-only if updating */}
            {isEditMode && (
              <TextField
                label="ID"
                fullWidth
                size="small"
                margin="dense"
                InputProps={{ readOnly: true }}
                {...register("id")}
              />
            )}

            {/* Descrição Field */}
            <TextField
              label="Descrição"
              fullWidth
              size="small"
              margin="dense"
              error={!!errors.descricao}
              helperText={errors.descricao ? 'Descrição é obrigatória' : ''}
              {...register("descricao", { required: true })}
            />

            {/* Status Field - Only show in edit mode */}
            {isEditMode && (
              <FormControl fullWidth margin="dense" size="small" error={!!errors.status}>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  {...register("status", { required: true })}
                >
                  <MenuItem value="true">Ativo</MenuItem>
                  <MenuItem value="false">Inativo</MenuItem>
                </Select>
                {errors.status && (
                  <Typography variant="body2" color="error" sx={{ mt: 0.5 }}>
                    Status é obrigatório
                  </Typography>
                )}
              </FormControl>
            )}
          </Box>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onClose} color="secondary" variant="outlined" startIcon={<CloseIcon />}>
          Voltar
        </Button>
        <Button form="form" type="submit" color="primary" variant="contained" startIcon={<SaveIcon />} disabled={!isValid || isLoading}>
          {isEditMode ? 'Atualizar' : 'Cadastrar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CategoriaProdutosModal;

import { useEffect, useState } from 'react';
import './style.css';



const ImpressaoCardapio = () => {
    const [dias, setDias] = useState('');
    const [resultados, setResultados] = useState([]);

    useEffect(() => {
        if (localStorage.getItem('imprimirCardapio') != null) {
            var alimentos = JSON.parse(localStorage.getItem('imprimirCardapio'));
            retornaDadosCardapio(alimentos);            
        }
       
        setTimeout(() => {
            window.print();
        }, 2000);
    }, []);


    const retornaDadosCardapio = (alimentos) => {
        var diasSemana = ['SEGUNDA-FEIRA', 'TERÇA-FEIRA', 'QUARTA-FEIRA', 'QUINTA-FEIRA', 'SEXTA-FEIRA'];
        var refeicoes = ['DESJEJUM/ LANCHE DA TARDE', 'ALMOÇO', 'SOBREMESA'];
        setDias(`${alimentos[0].dataInicio.split('T')[0].split('-')[2]} à ${alimentos[0].dataFim.split('T')[0].split('-')[2]}/${alimentos[0].dataFim.split('T')[0].split('-')[1]}`);

        for (let j = 0; j < diasSemana.length; j++) {
            var alimentosDiaSemana = alimentos.filter(x => x.diaSemana == diasSemana[j]);
            for (let k = 0; k < refeicoes.length; k++) {
                var html = "";
                var refeicao = alimentosDiaSemana.filter(x => x.refeicao == refeicoes[k]);
                for (let i = 0; i < refeicao.length; i++) {
                    html += `<h5 class='text-center produto mt-2'>${refeicao[i].produto}</h5>`;
                    if (i == refeicao.length - 1) {
                        if (refeicao[i].refeicao == 'DESJEJUM/ LANCHE DA TARDE' && refeicao[i].diaSemana == 'SEGUNDA-FEIRA')
                            document.getElementById('linhaDesjejumSegunda').innerHTML = html;
                        else if (refeicao[i].refeicao == 'ALMOÇO' && refeicao[i].diaSemana == 'SEGUNDA-FEIRA')
                            document.getElementById('linhaAlmocoSegunda').innerHTML = html;
                        else if (refeicao[i].refeicao == 'SOBREMESA' && refeicao[i].diaSemana == 'SEGUNDA-FEIRA')
                            document.getElementById('linhaSobremesaSegunda').innerHTML = html;

                        if (refeicao[i].refeicao == 'DESJEJUM/ LANCHE DA TARDE' && refeicao[i].diaSemana == 'TERÇA-FEIRA')
                            document.getElementById('linhaDesjejumTerca').innerHTML = html;
                        else if (refeicao[i].refeicao == 'ALMOÇO' && refeicao[i].diaSemana == 'TERÇA-FEIRA')
                            document.getElementById('linhaAlmocoTerca').innerHTML = html;
                        else if (refeicao[i].refeicao == 'SOBREMESA' && refeicao[i].diaSemana == 'TERÇA-FEIRA')
                            document.getElementById('linhaSobremesaTerca').innerHTML = html;

                        if (refeicao[i].refeicao == 'DESJEJUM/ LANCHE DA TARDE' && refeicao[i].diaSemana == 'QUARTA-FEIRA')
                            document.getElementById('linhaDesjejumQuarta').innerHTML = html;
                        else if (refeicao[i].refeicao == 'ALMOÇO' && refeicao[i].diaSemana == 'QUARTA-FEIRA')
                            document.getElementById('linhaAlmocoQuarta').innerHTML = html;
                        else if (refeicao[i].refeicao == 'SOBREMESA' && refeicao[i].diaSemana == 'QUARTA-FEIRA')
                            document.getElementById('linhaSobremesaQuarta').innerHTML = html;

                        if (refeicao[i].refeicao == 'DESJEJUM/ LANCHE DA TARDE' && refeicao[i].diaSemana == 'QUINTA-FEIRA')
                            document.getElementById('linhaDesjejumQuinta').innerHTML = html;
                        else if (refeicao[i].refeicao == 'ALMOÇO' && refeicao[i].diaSemana == 'QUINTA-FEIRA')
                            document.getElementById('linhaAlmocoQuinta').innerHTML = html;
                        else if (refeicao[i].refeicao == 'SOBREMESA' && refeicao[i].diaSemana == 'QUINTA-FEIRA')
                            document.getElementById('linhaSobremesaQuinta').innerHTML = html;

                        if (refeicao[i].refeicao == 'DESJEJUM/ LANCHE DA TARDE' && refeicao[i].diaSemana == 'SEXTA-FEIRA')
                            document.getElementById('linhaDesjejumSexta').innerHTML = html;
                        else if (refeicao[i].refeicao == 'ALMOÇO' && refeicao[i].diaSemana == 'SEXTA-FEIRA')
                            document.getElementById('linhaAlmocoSexta').innerHTML = html;
                        else if (refeicao[i].refeicao == 'SOBREMESA' && refeicao[i].diaSemana == 'SEXTA-FEIRA')
                            document.getElementById('linhaSobremesaSexta').innerHTML = html;
                    }
                }
            }
        }

        // localStorage.removeItem('imprimirCardapio');
    }


    return (
        <div className='class-print' style={{ marginLeft: '10px', marginRight: '13px' }}>
            <div className='row border-black remove-border-bottom'>
                            <div className='col-md-1'>
                                <img className='p-3 logo-impressao'  src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`} alt='Logo' />
                            </div>
                            <div className='col-md-9 text-center' style={{ paddingTop: 25 }}>
                                ESTADO DO RIO DE JANEIRO <br></br> {process.env.REACT_APP_PREFEITURA}
                            </div>
                            {/* <div className='col-md-2'>
                                <img className='p-4 logo-sao-pedro' src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`} alt='Logo' />
                            </div> */}
                            {/* <div className='col-md-2'>
                                <img className='p-4 logo-sao-pedro' src='/logo-sao-pedro-da-aldeia.png' alt='Logo' />
                            </div> */}
                        </div>
                        <div className='row border-black'>
                            <h5 className='text-center mt-2'>SECRETARIA MUNICIPAL DE EDUCAÇÃO - SETOR DE NUTRIÇÃO (ALIMENTAÇÃO ESCOLAR)</h5>
                        </div>
                        <div className='row border-right border-bottom border-left bg-bege'>
                            <h5 className='text-center mt-2'>CARDÁPIO EDUCACIONAL INFANTIL, FUNDAMENTAL I / II E EJA</h5>
                        </div>
                        <div className='row border-black remove-border-top'>
                            <div className='col-md-2 bg-bege border-right'>
                                <h5 className='text-center mt-2' style={{ paddingTop: '10px', paddingBottom: '5px' }}>{dias}</h5>
                            </div>
                            <div className='col-md-10'>
                                <h5 className='text-center mt-2' style={{ paddingTop: 10 }}>ALIMENTAÇÃO ESCOLAR</h5>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-2 bg-bege border-black remove-border-top remove-border-right'>
                                <h5 className='text-center mt-2'>DIAS</h5>
                            </div>
                            <div className='col-md-2 bg-aliceblue border-black remove-border-top remove-border-right'>
                                <h5 className='text-center mt-2'>SEGUNDA-FEIRA</h5>
                            </div>
                            <div className='col-md-2 bg-antiquewhite border-black remove-border-top remove-border-right'>
                                <h5 className='text-center mt-2'>TERÇA-FEIRA</h5>
                            </div>
                            <div className='col-md-2 bg-aqua border-black remove-border-top remove-border-right'>
                                <h5 className='text-center mt-2'>QUARTA-FEIRA</h5>
                            </div>
                            <div className='col-md-2 bg-bege border-black remove-border-top remove-border-right'>
                                <h5 className='text-center mt-2'>QUINTA-FEIRA</h5>
                            </div>
                            <div className='col-md-2 bg-azure border-black remove-border-top'>
                                <h5 className='text-center mt-2'>SEXTA-FEIRA</h5>
                            </div>
                        </div>

                        <div className='row' id='linhaDesjejum'>
                            <div className='col-md-2 bg-bege border-black remove-border-top remove-border-right'>
                                <h5 className='text-center mt-2'>DESJEJUM/LANCHE DA TARDE</h5>
                            </div>
                            <div className='col-md-2 border-black remove-border-top remove-border-right' id='linhaDesjejumSegunda'>
                            </div>
                            <div className='col-md-2 border-black remove-border-top remove-border-right' id='linhaDesjejumTerca'>
                            </div>
                            <div className='col-md-2 border-black remove-border-top remove-border-right' id='linhaDesjejumQuarta'>
                            </div>
                            <div className='col-md-2 border-black remove-border-top remove-border-right' id='linhaDesjejumQuinta'>
                            </div>
                            <div className='col-md-2 border-black remove-border-top' id='linhaDesjejumSexta'>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-2 bg-bege border-black remove-border-top remove-border-right'>
                                <h5 className='text-center'>ALMOÇO</h5>
                            </div>
                            <div className='col-md-2 border-black remove-border-top remove-border-right' id='linhaAlmocoSegunda'>
                            </div>
                            <div className='col-md-2 border-black remove-border-top remove-border-right' id='linhaAlmocoTerca'>
                            </div>
                            <div className='col-md-2 border-black remove-border-top remove-border-right' id='linhaAlmocoQuarta'>
                            </div>
                            <div className='col-md-2 border-black remove-border-top remove-border-right' id='linhaAlmocoQuinta'>
                            </div>
                            <div className='col-md-2 border-black remove-border-top' id='linhaAlmocoSexta'>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-2 bg-bege border-black remove-border-top remove-border-right'>
                                <h5 className='text-center mt-2'>SOBREMESA</h5>
                            </div>
                            <div className='col-md-2 border-black remove-border-top remove-border-right' id='linhaSobremesaSegunda'>
                            </div>
                            <div className='col-md-2 border-black remove-border-top remove-border-right' id='linhaSobremesaTerca'>
                            </div>
                            <div className='col-md-2 border-black remove-border-top remove-border-right' id='linhaSobremesaQuarta'>
                            </div>
                            <div className='col-md-2 border-black remove-border-top remove-border-right' id='linhaSobremesaQuinta'>
                            </div>
                            <div className='col-md-2 border-black remove-border-top' id='linhaSobremesaSexta'>
                            </div>
                        </div>

                        <div className='row mt-2'>
                            <div className='col-md-1'></div>
                            <div className='col-md-2 bg-bege border-black remove-border-right'>
                                <h5 className='text-center mt-2' style={{ fontSize: 13, paddingTop: 25 }}>COMPOSIÇÃO NUTRICIONAL<br></br>MÉDIA SEMANAL</h5>
                            </div>
                            <div className='col-md-8 border-black'>
                                <div className='row border-bottom'>
                                    <h5 className='text-center produto mt-2'>Macronutrientes</h5>
                                </div>
                                <div className='row border-bottom'>
                                    <div className='col-md-3 border-right'>
                                        <h5 className='text-center produto mt-2'>Energia (Kcal)</h5>
                                    </div>
                                    <div className='col-md-3 border-right'>
                                        <h5 className='text-center produto mt-2'>carboidratos (g)</h5>
                                    </div>
                                    <div className='col-md-3 border-right'>
                                        <h5 className='text-center produto mt-2'>Proteínas (g)</h5>
                                    </div>
                                    <div className='col-md-3'>
                                        <h5 className='text-center produto mt-2'>Lipídios (g)</h5>
                                    </div>
                                </div>
                                <div className='row' style={{ height: 35 }}>
                                    <div className='col-md-3 border-right'>
                                        <h5 className='text-center produto mt-2'></h5>
                                    </div>
                                    <div className='col-md-3 border-right'>
                                        <h5 className='text-center produto mt-2'></h5>
                                    </div>
                                    <div className='col-md-3 border-right'>
                                        <h5 className='text-center produto mt-2'></h5>
                                    </div>
                                    <div className='col-md-3'>
                                        <h5 className='text-center produto mt-2'></h5>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-1'></div>
                        </div>

                        <div className='row mt-2'>
                            <div className='col-md-1'></div>
                            <div className='col-md-10'>
                                <div className='row'>
                                    <p className='margin-bottom-zero' style={{ fontSize: 10 }}><strong>Nome:</strong> A frunta indicada poderá ser substituida, caso não esteja madura, mas deverá ser servida durante a semana.
                                        <br></br>
                                        O estoque disponível(excedente) de legumes ou verduras poderá ser acrescentado em outras preparações para enriquecer o cardápio.
                                        Mudanças poderão ocorrer, devido a sazonalidade de hortifrutis em geral durande o ano.
                                        <br></br>
                                        Solicitamos atenção redobrada com espinhas e sementes. Servir as proteínas desfiadas para a Educação Infantil.
                                    </p>
                                    <span className='margin-top-zero' style={{ fontSize: 10, textAlign: 'center' }}>A margarina não deve ser utilizada no prepado dos bolos, utilizar óleo de soja.</span>
                                </div>
                                <div className='col-md-1'></div>
                            </div>
                        </div>

                        <div className='row mt-2'>
                            <div className='col-md-1'></div>
                            <div className='col-md-10 bg-bege border-black'>
                                <h5 className='text-center mt-2' style={{ fontSize: 13 }}>Atenção: Proíbida a alteraçõ do cardápio sem a autorização dos nutricionistas.</h5>
                            </div>
                            <div className='col-md-1'></div>
                        </div>
        </div>
    );
};

export default ImpressaoCardapio;

import React from 'react';

function Signature() {
    return (
        <div className="signature-row" style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: '100%' }}>
            <SignatureField name="Professor" />
            <SignatureField name="Secretaria Escolar" />
            <SignatureField name="Inspeção Escolar" />
        </div>
    );
}

function SignatureField({ name }) {
    return (
        <div className="signature-field" style={{ width: '30%', textAlign: 'center', margin: '0 10px' }}>
            <div style={{ borderTop: '2px solid black', padding: '2px', width: '100%' }}></div>
            <p style={{ marginTop: '5px' }}>{name}</p>
        </div>
    );
}

export default Signature;

import React, { useState } from 'react';

import * as S from './styles';

export default function InputWithSelect({
  register,
  errors,
  group,
  atribute,
  label,
  required = true,
  placeholder,
  readOnly,
  onChange,
  style,
  options,
  name,
  selected,
  isSelected,
  attribute1 = 'cadastro',
  attribute2 = 'nomeFantasia'
}) {
  const [shouldOpenDropdown, setShouldOpenDropdown] = useState(false);
  const [selectedData, setSelectedData] = useState('');

  const handleKeyDown = event => {
    if (event.key === 'Backspace') {
      selected()
    }
  };

  return (
    <div className="form-group">
      <S.Container>
        <label htmlFor={group ? `${group}.${atribute}` : `${atribute}`}>
          {label}
          {
            required && <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
          }
        </label>
        <input
          type="text"
          style={style}
          className="form-control"
          id={group ? `${group}.${atribute}` : `${atribute}`}
          placeholder={placeholder}
          readOnly={readOnly}
          onChangeCapture={(e) => setSelectedData(e.target.value)}
          onKeyDownCapture={handleKeyDown}
          onFocus={() => setShouldOpenDropdown(true)}
          {...register(group ? `${group}.${atribute}` : `${atribute}`, { required: { required } })}
        />
        {
          shouldOpenDropdown && selectedData !== '' && !isSelected && (
            <S.DropdownContainer>
              {
                options === undefined && name?.length < 3 && (
                  <span>Digite pelo menos 3 caracteres para iniciar a filtragem</span>
                )
              }
              {
                options?.length === 0 && name?.length >= 3 && (
                  <span>Nenhum fornecedor encontrado com esse nome</span>
                )
              }
              {options?.map((item) => (
                <S.DropdownOption
                  onClick={() => { selected(item); setShouldOpenDropdown(false) }}
                >
                  <span>{attribute2 === 'null' ? item[attribute1] : item[attribute1][attribute2]}</span>
                </S.DropdownOption>
              )
              )}
            </S.DropdownContainer>
          )
        }

      </S.Container>
    </div>
  )
}
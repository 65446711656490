import api from "../..";

async function postContrato(data) {
  return await api
    .post('patrimonio/contrato', data)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
};

async function putContrato({ id, data }) {
  return await api
    .put(`patrimonio/contrato/${id}`, data)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function deleteContrato(id) {
  return await api
    .delete(`patrimonio/contrato/${id}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function getContratos() {
  return await api
    .get('patrimonio/contrato')
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function getContrato(id) {
  return await api
    .get(`patrimonio/contrato/${id}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

export {
  postContrato,
  putContrato,
  deleteContrato,
  getContratos,
  getContrato
}
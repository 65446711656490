export const cid10 = [
  { cod: "A00",	text: "A00 - Cólera"},
  { cod: "A01",	text: "A01 - Febres tifóide e paratifóide"},
  { cod: "A02",	text: "A02 - Outras infecções por Salmonella"},
  { cod: "A03",	text: "A03 - Shiguelose"},
  { cod: "A04",	text: "A04 - Outras infecções intestinais bacterianas"},
  { cod: "A05",	text: "A05 - Outras intoxicações alimentares bacterianas"},
  { cod: "A06",	text: "A06 - Amebíase"},
  { cod: "A07",	text: "A07 - Outras doenças intestinais por protozoários"},
  { cod: "A08",	text: "A08 - Infecções intestinais virais, outras e as não especificadas"},
  { cod: "A09",	text: "A09 - Diarréia e gastroenterite de origem infecciosa presumível"},
  { cod: "A15",	text: "A15 - Tuberculose respiratória, com confirmação bacteriológica e histológica"},
  { cod: "A16",	text: "A16 - Tuberculose das vias respiratórias, sem confirmação bacteriológica ou histológica"},
  { cod: "A17",	text: "A17 - Tuberculose do sistema nervoso"},
  { cod: "A18",	text: "A18 - Tuberculose de outros órgãos"},
  { cod: "A19",	text: "A19 - Tuberculose miliar"},
  { cod: "A20",	text: "A20 - Peste"},
  { cod: "A22",	text: "A22 - Carbúnculo"},
  { cod: "A23",	text: "A23 - Brucelose"},
  { cod: "A24",	text: "A24 - Mormo e melioidose"},
  { cod: "A25",	text: "A25 - Febres transmitidas por mordedura de rato"},
  { cod: "A26",	text: "A26 - Erisipelóide"},
  { cod: "A27",	text: "A27 - Leptospirose"},
  { cod: "A28",	text: "A28 - Outras doenças bacterianas zoonóticas não classificadas em outra parte"},
  { cod: "A30",	text: "A30 - Hanseníase [doença de Hansen] [lepra]"},
  { cod: "A31",	text: "A31 - Infecções devidas a outras micobactérias"},
  { cod: "A32",	text: "A32 - Listeriose [listeríase]"},
  { cod: "A33",	text: "A33 - Tétano do recém-nascido [neonatal]"},
  { cod: "A34",	text: "A34 - Tétano obstétrico"},
  { cod: "A35",	text: "A35 - Outros tipos de tétano"},
  { cod: "A37",	text: "A37 - Coqueluche"},
  { cod: "A39",	text: "A39 - Infecção meningogócica"},
  { cod: "A40",	text: "A40 - Septicemia estreptocócica"},
  { cod: "A41",	text: "A41 - Outras septicemias"},
  { cod: "A42",	text: "A42 - Actinomicose"},
  { cod: "A43",	text: "A43 - Nocardiose"},
  { cod: "A44",	text: "A44 - Bartonelose"},
  { cod: "A46",	text: "A46 - Erisipela"},
  { cod: "A48",	text: "A48 - Outras doenças bacterianas não classificadas em outra parte"},
  { cod: "A49",	text: "A49 - Infecção bacteriana de local não especificado"},
  { cod: "A50",	text: "A50 - Sífilis congênita"},
  { cod: "A51",	text: "A51 - Sífilis precoce"},
  { cod: "A52",	text: "A52 - Sífilis tardia"},
  { cod: "A53",	text: "A53 - Outras formas e as não especificadas da sífilis"},
  { cod: "A54",	text: "A54 - Infecção gonocócica"},
  { cod: "A55",	text: "A55 - Linfogranuloma (venéreo) por clamídia"},
  { cod: "A56",	text: "A56 - Outras infecções causadas por clamídias transmitidas por via sexual"},
  { cod: "A58",	text: "A58 - Granuloma inguinal"},
  { cod: "A60",	text: "A60 - Infecções anogenitais pelo vírus do herpes [herpes simples]"},
  { cod: "A63",	text: "A63 - Outras doenças de transmissão predominantemente sexual, não classificadas em outra parte"},
  { cod: "A64",	text: "A64 - Doenças sexualmente transmitidas, não especificadas"},
  { cod: "A65",	text: "A65 - Sífilis não-venérea"},
  { cod: "A66",	text: "A66 - Bouba"},
  { cod: "A67",	text: "A67 - Pinta [carate]"},
  { cod: "A68",	text: "A68 - Febres recorrentes [Borrelioses]"},
  { cod: "A69",	text: "A69 - Outras infecções por espiroquetas"},
  { cod: "A70",	text: "A70 - Infecções causadas por Clamídia psittaci"},
  { cod: "A74",	text: "A74 - Outras doenças causadas por Clamídias"},
  { cod: "A77",	text: "A77 - Febre maculosa [rickettsioses transmitidas por carrapatos]"},
  { cod: "A78",	text: "A78 - Febre Q"},
  { cod: "A80",	text: "A80 - Poliomielite aguda"},
  { cod: "A81",	text: "A81 - Infecções por vírus atípico do sistema nervoso central"},
  { cod: "A82",	text: "A82 - Raiva"},
  { cod: "A83",	text: "A83 - Encefalite por vírus transmitidos por mosquitos"},
  { cod: "A85",	text: "A85 - Outras encefalites virais, não classificadas em outra parte"},
  { cod: "A86",	text: "A86 - Encefalite viral, não especificada"},
  { cod: "A87",	text: "A87 - Meningite viral"},
  { cod: "A88",	text: "A88 - Outras infecções virais do sistema nervoso central não classificadas em outra parte"},
  { cod: "A89",	text: "A89 - Infecções virais não especificadas do sistema nervoso central"},
  { cod: "A90",	text: "A90 - Dengue [dengue clássico]"},
  { cod: "A91",	text: "A91 - Febre hemorrágica devida ao vírus do dengue"},
  { cod: "A92",	text: "A92 - Outras febres virais transmitidas por mosquitos"},
  { cod: "A93",	text: "A93 - Outras febres por vírus transmitidas por artrópodes não classificadas em outra parte"},
  { cod: "A94",	text: "A94 - Febre viral transmitida por artrópodes, não especificada"},
  { cod: "A95",	text: "A95 - Febre amarela"},
  { cod: "A96",	text: "A96 - Febre hemorrágica por arenavírus"},
  { cod: "A98",	text: "A98 - Outras febres hemorrágicas por vírus, não classificadas em outra parte"},
  { cod: "A99",	text: "A99 - Febres hemorrágicas virais não especificadas"},
  { cod: "B00",	text: "B00 - Infecções pelo vírus do herpes [herpes simples]"},
  { cod: "B01",	text: "B01 - Varicela"},
  { cod: "B02",	text: "B02 - Herpes zoster [Zona]"},
  { cod: "B03",	text: "B03 - Varíola"},
  { cod: "B04",	text: "B04 - Varíola dos macacos [Monkeypox]"},
  { cod: "B05",	text: "B05 - Sarampo"},
  { cod: "B06",	text: "B06 - Rubéola"},
  { cod: "B07",	text: "B07 - Verrugas de origem viral"},
  { cod: "B08",	text: "B08 - Outras infecções virais caracterizadas por lesões da pele e das membranas mucosas, não classificadas em outra parte"},
  { cod: "B09",	text: "B09 - Infecção viral não especificada caracterizada por lesões da pele e membranas mucosas"},
  { cod: "B15",	text: "B15 - Hepatite aguda A"},
  { cod: "B16",	text: "B16 - Hepatite aguda B"},
  { cod: "B17",	text: "B17 - Outras hepatites virais agudas"},
  { cod: "B18",	text: "B18 - Hepatite viral crônica"},
  { cod: "B19",	text: "B19 - Hepatite viral não especificada"},
  { cod: "B20",	text: "B20 - Doença pelo vírus da imunodeficiência humana [HIV], resultando em doenças infecciosas e parasitárias"},
  { cod: "B21",	text: "B21 - Doença pelo vírus da imunodeficiência humana [HIV], resultando em neoplasias malignas"},
  { cod: "B22",	text: "B22 - Doença pelo vírus da imunodeficiência humana [HIV] resultando em outras doenças especificadas"},
  { cod: "B23",	text: "B23 - Doença pelo vírus da imunodeficiência humana [HIV] resultando em outras doenças"},
  { cod: "B24",	text: "B24 - Doença pelo vírus da imunodeficiência humana [HIV] não especificada"},
  { cod: "B25",	text: "B25 - Doença por citomegalovírus"},
  { cod: "B26",	text: "B26 - Caxumba [Parotidite epidêmica]"},
  { cod: "B27",	text: "B27 - Mononucleose infecciosa"},
  { cod: "B30",	text: "B30 - Conjuntivite viral"},
  { cod: "B33",	text: "B33 - Outras doenças por vírus não classificada em outra parte"},
  { cod: "B34",	text: "B34 - Doenças por vírus, de localização não especificada"},
  { cod: "B35",	text: "B35 - Dermatofitose"},
  { cod: "B36",	text: "B36 - Outras micoses superficiais"},
  { cod: "B37",	text: "B37 - Candidíase"},
  { cod: "B38",	text: "B38 - Coccidioidomicose"},
  { cod: "B39",	text: "B39 - Histoplasmose"},
  { cod: "B40",	text: "B40 - Blastomicose"},
  { cod: "B41",	text: "B41 - Paracoccidioidomicose"},
  { cod: "B42",	text: "B42 - Esporotricose"},
  { cod: "B43",	text: "B43 - Cromomicose e abscesso feomicótico"},
  { cod: "B44",	text: "B44 - Aspergilose"},
  { cod: "B45",	text: "B45 - Criptococose"},
  { cod: "B46",	text: "B46 - Zigomicose"},
  { cod: "B47",	text: "B47 - Micetoma"},
  { cod: "B48",	text: "B48 - Outras micoses, não classificadas em outra parte"},
  { cod: "B49",	text: "B49 - Micose não especificada"},
  { cod: "B51",	text: "B51 - Malária por Plasmodium vivax"},
  { cod: "B54",	text: "B54 - Malária não especificada"},
  { cod: "B55",	text: "B55 - Leishmaniose"},
  { cod: "B56",	text: "B56 - Tripanossomíase africana"},
  { cod: "B57",	text: "B57 - Doença de Chagas"},
  { cod: "B58",	text: "B58 - Toxoplasmose"},
  { cod: "B59",	text: "B59 - Pneumocistose"},
  { cod: "B60",	text: "B60 - Outras doenças devidas a protozoários, não classificadas em outra parte"},
  { cod: "B65",	text: "B65 - Esquistossomose [bilharziose] [Schistosomíase]"},
  { cod: "B67",	text: "B67 - Equinococose"},
  { cod: "B69",	text: "B69 - Cisticercose"},
  { cod: "B74",	text: "B74 - Filariose"},
  { cod: "B76",	text: "B76 - Ancilostomíase"},
  { cod: "B77",	text: "B77 - Ascaridíase"},
  { cod: "B78",	text: "B78 - Estrongiloidíase"},
  { cod: "B81",	text: "B81 - Outras helmintíases intestinais, não classificadas em outra parte"},
  { cod: "B82",	text: "B82 - Parasitose intestinal não especificada"},
  { cod: "B83",	text: "B83 - Outras helmintíases"},
  { cod: "B85",	text: "B85 - Pediculose e ftiríase"},
  { cod: "B86",	text: "B86 - Escabiose [sarna]"},
  { cod: "B87",	text: "B87 - Miíase"},
  { cod: "B88",	text: "B88 - Outras infestações"},
  { cod: "B89",	text: "B89 - Doença parasitária não especificada"},
  { cod: "B90",	text: "B90 - Seqüelas de tuberculose"},
  { cod: "B91",	text: "B91 - Seqüelas de poliomielite"},
  { cod: "B92",	text: "B92 - Seqüelas de hanseníase [lepra]"},
  { cod: "B94",	text: "B94 - Seqüelas de outras doenças infecciosas e parasitárias e das não especificadas"},
  { cod: "B95",	text: "B95 - Estreptococos e estafilococos como causa de doenças classificadas em outros capítulos"},
  { cod: "B96",	text: "B96 - Outros agentes bacterianos, como causa de doenças classificadas em outros capítulos"},
  { cod: "B97",	text: "B97 - Vírus como causa de doenças classificadas em outros capítulos"},
  { cod: "B99",	text: "B99 - Doenças infecciosas, outras e as não especificadas"},
  { cod: "C00",	text: "C00 - Neoplasia maligna do lábio"},
  { cod: "C01",	text: "C01 - Neoplasia maligna da base da língua"},
  { cod: "C02",	text: "C02 - Neoplasia maligna de outras partes e de partes não especificadas da língua"},
  { cod: "C03",	text: "C03 - Neoplasia maligna da gengiva"},
  { cod: "C04",	text: "C04 - Neoplasia maligna do assoalho da boca"},
  { cod: "C05",	text: "C05 - Neoplasia maligna do pálato"},
  { cod: "C06",	text: "C06 - Neoplasia maligna de outras partes e de partes não especificadas da boca"},
  { cod: "C07",	text: "C07 - Neoplasia maligna da glândula parótida"},
  { cod: "C08",	text: "C08 - Neoplasia maligna de outras glândulas salivares maiores e as não especificadas"},
  { cod: "C09",	text: "C09 - Neoplasia maligna da amígdala"},
  { cod: "C10",	text: "C10 - Neoplasia maligna da orofaringe"},
  { cod: "C11",	text: "C11 - Neoplasia maligna da nasofaringe"},
  { cod: "C12",	text: "C12 - Neoplasia maligna do seio piriforme"},
  { cod: "C13",	text: "C13 - Neoplasia maligna da hipofaringe"},
  { cod: "C14",	text: "C14 - Neoplasia maligna de outras localizações e de localizações mal definida, do lábio, cavidade oral e faringe"},
  { cod: "C15",	text: "C15 - Neoplasia maligna do esôfago"},
  { cod: "C16",	text: "C16 - Neoplasia maligna do estômago"},
  { cod: "C17",	text: "C17 - Neoplasia maligna do intestino delgado"},
  { cod: "C18",	text: "C18 - Neoplasia maligna do cólon"},
  { cod: "C19",	text: "C19 - Neoplasia maligna da junção retossigmóide"},
  { cod: "C20",	text: "C20 - Neoplasia maligna do reto"},
  { cod: "C21",	text: "C21 - Neoplasia maligna do ânus e do canal anal"},
  { cod: "C22",	text: "C22 - Neoplasia maligna do fígado e das vias biliares intra-hepáticas"},
  { cod: "C23",	text: "C23 - Neoplasia maligna da vesícula biliar"},
  { cod: "C24",	text: "C24 - Neoplasia maligna de outras partes, e de partes não especificadas das vias biliares"},
  { cod: "C25",	text: "C25 - Neoplasia maligna do pâncreas"},
  { cod: "C26",	text: "C26 - Neoplasia maligna de outros órgãos digestivos e de localizações mal definidas no aparelho digestivo"},
  { cod: "C30",	text: "C30 - Neoplasia maligna da cavidade nasal e do ouvido médio"},
  { cod: "C31",	text: "C31 - Neoplasia maligna dos seios da face"},
  { cod: "C32",	text: "C32 - Neoplasia maligna da laringe"},
  { cod: "C33",	text: "C33 - Neoplasia maligna da traquéia"},
  { cod: "C34",	text: "C34 - Neoplasia maligna dos brônquios e dos pulmões"},
  { cod: "C37",	text: "C37 - Neoplasia maligna do timo"},
  { cod: "C38",	text: "C38 - Neoplasia maligna do coração, mediastino e pleura"},
  { cod: "C39",	text: "C39 - Neoplasia maligna de outras localizações e de localizações mal definidas do aparelho respiratório e dos órgãos intratorácicos"},
  { cod: "C40",	text: "C40 - Neoplasia maligna dos ossos e cartilagens articulares dos membros"},
  { cod: "C41",	text: "C41 - Neoplasia maligna dos ossos e das cartilagens articulares de outras localizações e de localizações não especificadas"},
  { cod: "C43",	text: "C43 - Melanoma maligno da pele"},
  { cod: "C44",	text: "C44 - Outras neoplasias malignas da pele"},
  { cod: "C45",	text: "C45 - Mesotelioma"},
  { cod: "C46",	text: "C46 - Sarcoma de Kaposi"},
  { cod: "C47",	text: "C47 - Neoplasia maligna dos nervos periféricos e do sistema nervoso autônomo"},
  { cod: "C48",	text: "C48 - Neoplasia maligna dos tecidos moles do retroperitônio e do peritônio"},
  { cod: "C49",	text: "C49 - Neoplasia maligna do tecido conjuntivo e de outros tecidos moles"},
  { cod: "C50",	text: "C50 - Neoplasia maligna da mama"},
  { cod: "C51",	text: "C51 - Neoplasia maligna da vulva"},
  { cod: "C52",	text: "C52 - Neoplasia maligna da vagina"},
  { cod: "C53",	text: "C53 - Neoplasia maligna do colo do útero"},
  { cod: "C54",	text: "C54 - Neoplasia maligna do corpo do útero"},
  { cod: "C55",	text: "C55 - Neoplasia maligna do útero, porção não especificada"},
  { cod: "C56",	text: "C56 - Neoplasia maligna do ovário"},
  { cod: "C57",	text: "C57 - Neoplasia maligna de outros órgãos genitais femininos e dos não especificados"},
  { cod: "C58",	text: "C58 - Neoplasia maligna da placenta"},
  { cod: "C60",	text: "C60 - Neoplasia maligna do pênis"},
  { cod: "C61",	text: "C61 - Neoplasia maligna da próstata"},
  { cod: "C62",	text: "C62 - Neoplasia maligna dos testículos"},
  { cod: "C63",	text: "C63 - Neoplasia maligna de outros órgãos genitais masculinos e dos não especificados"},
  { cod: "C64",	text: "C64 - Neoplasia maligna do rim, exceto pelve renal"},
  { cod: "C65",	text: "C65 - Neoplasia maligna da pelve renal"},
  { cod: "C66",	text: "C66 - Neoplasia maligna dos ureteres"},
  { cod: "C67",	text: "C67 - Neoplasia maligna da bexiga"},
  { cod: "C68",	text: "C68 - Neoplasia maligna de outros órgãos urinários e dos não especificados"},
  { cod: "C69",	text: "C69 - Neoplasia maligna do olho e anexos"},
  { cod: "C70",	text: "C70 - Neoplasia maligna das meninges"},
  { cod: "C71",	text: "C71 - Neoplasia maligna do encéfalo"},
  { cod: "C72",	text: "C72 - Neoplasia maligna da medula espinhal, dos nervos cranianos e de outras partes do sistema nervoso central"},
  { cod: "C73",	text: "C73 - Neoplasia maligna da glândula tireóide"},
  { cod: "C74",	text: "C74 - Neoplasia maligna da glândula supra-renal"},
  { cod: "C75",	text: "C75 - Neoplasia maligna de outras glândulas endócrinas e de estruturas relacionadas"},
  { cod: "C76",	text: "C76 - Neoplasia maligna de outras localizações e de localizações mal definidas"},
  { cod: "C77",	text: "C77 - Neoplasia maligna secundária e não especificada dos gânglios linfáticos"},
  { cod: "C78",	text: "C78 - Neoplasia maligna secundária dos órgãos respiratórios e digestivos"},
  { cod: "C79",	text: "C79 - Neoplasia maligna secundária de outras localizações"},
  { cod: "C80",	text: "C80 - Neoplasia maligna, sem especificação de localização"},
  { cod: "C81",	text: "C81 - Doença de Hodgkin"},
  { cod: "C82",	text: "C82 - Linfoma não-Hodgkin, folicular (nodular)"},
  { cod: "C83",	text: "C83 - Linfoma não-Hodgkin difuso"},
  { cod: "C84",	text: "C84 - Linfomas de células T cutâneas e periféricas"},
  { cod: "C85",	text: "C85 - Linfoma não-Hodgkin de outros tipos e de tipo não especificado"},
  { cod: "C88",	text: "C88 - Doenças imunoproliferativas malignas"},
  { cod: "C90",	text: "C90 - Mieloma múltiplo e neoplasias malignas de plasmócitos"},
  { cod: "C91",	text: "C91 - Leucemia linfóide"},
  { cod: "C92",	text: "C92 - Leucemia mielóide"},
  { cod: "C93",	text: "C93 - Leucemia monocítica"},
  { cod: "C94",	text: "C94 - Outras leucemias de células de tipo especificado"},
  { cod: "C95",	text: "C95 - Leucemia de tipo celular não especificado"},
  { cod: "C96",	text: "C96 - Outras neoplasias malignas e as não especificadas dos tecidos linfático, hematopoético e tecidos correlatos"},
  { cod: "C97",	text: "C97 - Neoplasias malignas de localizações múltiplas independentes (primárias)"},
  { cod: "D00",	text: "D00 - Carcinoma in situ da cavidade oral, do esôfago e do estômago"},
  { cod: "D01",	text: "D01 - Carcinoma in situ de outros órgãos digestivos"},
  { cod: "D02",	text: "D02 - Carcinoma in situ do ouvido médio e do aparelho respiratório"},
  { cod: "D03",	text: "D03 - Melanoma in situ"},
  { cod: "D04",	text: "D04 - Carcinoma in situ da pele"},
  { cod: "D05",	text: "D05 - Carcinoma in situ da mama"},
  { cod: "D06",	text: "D06 - Carcinoma in situ do colo do útero (cérvix)"},
  { cod: "D07",	text: "D07 - Carcinoma in situ de outros órgãos genitais e dos não especificados"},
  { cod: "D09",	text: "D09 - Carcinoma in situ de outras localizações e das não especificadas"},
  { cod: "D10",	text: "D10 - Neoplasia benigna da boca e da faringe"},
  { cod: "D11",	text: "D11 - Neoplasia benigna de glândulas salivares maiores"},
  { cod: "D12",	text: "D12 - Neoplasia benigna do cólon, reto, canal anal e ânus"},
  { cod: "D13",	text: "D13 - Neoplasia benigna de outras partes e de partes mal definidas do aparelho digestivo"},
  { cod: "D14",	text: "D14 - Neoplasia benigna do ouvido médio e do aparelho respiratório"},
  { cod: "D15",	text: "D15 - Neoplasia benigna de outros órgãos intratorácicos e dos não especificados"},
  { cod: "D16",	text: "D16 - Neoplasia benigna de osso e de cartilagem articular"},
  { cod: "D17",	text: "D17 - Neoplasia lipomatosa benigna"},
  { cod: "D18",	text: "D18 - Hemangioma e linfangioma de qualquer localização"},
  { cod: "D19",	text: "D19 - Neoplasia benigna de tecido mesotelial"},
  { cod: "D20",	text: "D20 - Neoplasia benigna de tecido mole do retroperitônio e do peritônio"},
  { cod: "D21",	text: "D21 - Outras neoplasias benignas do tecido conjuntivo e de outros tecidos moles"},
  { cod: "D22",	text: "D22 - Nevos melanocíticos"},
  { cod: "D23",	text: "D23 - Outras neoplasias benignas da pele"},
  { cod: "D24",	text: "D24 - Neoplasia benigna da mama"},
  { cod: "D25",	text: "D25 - Leiomioma do útero"},
  { cod: "D26",	text: "D26 - Outras neoplasias benignas do útero"},
  { cod: "D27",	text: "D27 - Neoplasia benigna do ovário"},
  { cod: "D28",	text: "D28 - Neoplasia benigna de outros órgãos genitais femininos e de órgãos não especificados"},
  { cod: "D29",	text: "D29 - Neoplasia benigna dos órgãos genitais masculinos"},
  { cod: "D30",	text: "D30 - Neoplasia benigna dos órgãos urinários"},
  { cod: "D31",	text: "D31 - Neoplasia benigna do olho e anexos"},
  { cod: "D32",	text: "D32 - Neoplasia benigna das meninges"},
  { cod: "D33",	text: "D33 - Neoplasia benigna do encéfalo e de outras partes do sistema nervoso central"},
  { cod: "D34",	text: "D34 - Neoplasia benigna da glândula tireóide"},
  { cod: "D35",	text: "D35 - Neoplasia benigna de outras glândulas endócrinas e das não especificadas"},
  { cod: "D36",	text: "D36 - Neoplasia benigna de outras localizações e de localizações não especificadas"},
  { cod: "D37",	text: "D37 - Neoplasia de comportamento incerto ou desconhecido da cavidade oral e dos órgãos digestivos"},
  { cod: "D38",	text: "D38 - Neoplasia de comportamento incerto ou desconhecido do ouvido médio e dos órgãos respiratórios e intratorácicos"},
  { cod: "D39",	text: "D39 - Neoplasia de comportamento incerto ou desconhecido dos órgãos genitais femininos"},
  { cod: "D40",	text: "D40 - Neoplasia de comportamento incerto ou desconhecido dos órgãos genitais masculinos"},
  { cod: "D41",	text: "D41 - Neoplasia de comportamento incerto ou desconhecido dos órgãos urinários"},
  { cod: "D42",	text: "D42 - Neoplasia de comportamento incerto ou desconhecido das meninges"},
  { cod: "D43",	text: "D43 - Neoplasia de comportamento incerto ou desconhecido do encéfalo e do sistema nervoso central"},
  { cod: "D44",	text: "D44 - Neoplasia de comportamento incerto ou desconhecido das glândulas endócrinas"},
  { cod: "D45",	text: "D45 - Policitemia vera"},
  { cod: "D46",	text: "D46 - Síndromes mielodisplásicas"},
  { cod: "D47",	text: "D47 - Outras neoplasias de comportamento incerto ou desconhecido dos tecidos linfático, hematopoético e tecidos correlatos"},
  { cod: "D48",	text: "D48 - Neoplasia de comportamento incerto ou desconhecido de outras localizações e de localizações não especificadas"},
  { cod: "D50",	text: "D50 - Anemia por deficiência de ferro"},
  { cod: "D51",	text: "D51 - Anemia por deficiência de vitamina B12"},
  { cod: "D52",	text: "D52 - Anemia por deficiência de folato"},
  { cod: "D53",	text: "D53 - Outras anemias nutricionais"},
  { cod: "D55",	text: "D55 - Anemia devida a transtornos enzimáticos"},
  { cod: "D56",	text: "D56 - Talassemia"},
  { cod: "D57",	text: "D57 - Transtornos falciformes"},
  { cod: "D58",	text: "D58 - Outras anemias hemolíticas hereditárias"},
  { cod: "D59",	text: "D59 - Anemia hemolítica adquirida"},
  { cod: "D60",	text: "D60 - Aplasia pura da série vermelha, adquirida [eritroblastopenia]"},
  { cod: "D61",	text: "D61 - Outras anemias aplásticas"},
  { cod: "D62",	text: "D62 - Anemia aguda pós-hemorrágica"},
  { cod: "D63",	text: "D63 - Anemia em doenças crônicas classificadas em outra parte"},
  { cod: "D64",	text: "D64 - Outras anemias"},
  { cod: "D65",	text: "D65 - Coagulação intravascular disseminada [síndrome de desfibrinação]"},
  { cod: "D66",	text: "D66 - Deficiência hereditária do fator VIII"},
  { cod: "D67",	text: "D67 - Deficiência hereditária do fator IX"},
  { cod: "D68",	text: "D68 - Outros defeitos da coagulação"},
  { cod: "D69",	text: "D69 - Púrpura e outras afecções hemorrágicas"},
  { cod: "D70",	text: "D70 - Agranulocitose"},
  { cod: "D71",	text: "D71 - Transtornos funcionais dos neutrófilos polimorfonucleares"},
  { cod: "D72",	text: "D72 - Outros transtornos dos glóbulos brancos"},
  { cod: "D73",	text: "D73 - Doenças do baço"},
  { cod: "D74",	text: "D74 - Metemoglobinemia"},
  { cod: "D75",	text: "D75 - Outras doenças do sangue e dos órgãos hematopoéticos"},
  { cod: "D76",	text: "D76 - Algumas doenças que envolvem o tecido linforreticular e o sistema reticulohistiocítico"},
  { cod: "D77",	text: "D77 - Outros transtornos do sangue e dos órgãos hematopoéticos em doenças classificadas em outra parte"},
  { cod: "D80",	text: "D80 - Imunodeficiência com predominância de defeitos de anticorpos"},
  { cod: "D81",	text: "D81 - Deficiências imunitárias combinadas"},
  { cod: "D82",	text: "D82 - Imunodeficiência associada com outros defeitos 'major'"},
  { cod: "D83",	text: "D83 - Imunodeficiência comum variável"},
  { cod: "D84",	text: "D84 - Outras imunodeficiências"},
  { cod: "D86",	text: "D86 - Sarcoidose"},
  { cod: "D89",	text: "D89 - Outros transtornos que comprometem o mecanismo imunitário não classificados em outra parte"},
  { cod: "E01",	text: "E01 - Transtornos tireoidianos e afecções associadas, relacionados à deficiência de iodo"},
  { cod: "E02",	text: "E02 - Hipotireoidismo subclínico por deficiência de iodo"},
  { cod: "E03",	text: "E03 - Outros hipotireoidismos"},
  { cod: "E04",	text: "E04 - Outros bócios não-tóxicos"},
  { cod: "E05",	text: "E05 - Tireotoxicose [hipertireoidismo]"},
  { cod: "E06",	text: "E06 - Tireoidite"},
  { cod: "E07",	text: "E07 - Outros transtornos da tireóide"},
  { cod: "E10",	text: "E10 - Diabetes mellitus insulino-dependente"},
  { cod: "E11",	text: "E11 - Diabetes mellitus não-insulino-dependente"},
  { cod: "E12",	text: "E12 - Diabetes mellitus relacionado com a desnutrição"},
  { cod: "E13",	text: "E13 - Outros tipos especificados de diabetes mellitus"},
  { cod: "E14",	text: "E14 - Diabetes mellitus não especificado"},
  { cod: "E15",	text: "E15 - Coma hipoglicêmico não-diabético"},
  { cod: "E16",	text: "E16 - Outros transtornos da secreção pancreática interna"},
  { cod: "E20",	text: "E20 - Hipoparatireoidismo"},
  { cod: "E21",	text: "E21 - Hiperparatireoidismo e outros transtornos da glândula paratireóide"},
  { cod: "E22",	text: "E22 - Hiperfunção da hipófise"},
  { cod: "E23",	text: "E23 - Hipofunção e outros transtornos da hipófise"},
  { cod: "E24",	text: "E24 - Síndrome de Cushing"},
  { cod: "E25",	text: "E25 - Transtornos adrenogenitais"},
  { cod: "E26",	text: "E26 - Hiperaldosteronismo"},
  { cod: "E27",	text: "E27 - Outros transtornos da glândula supra-renal"},
  { cod: "E28",	text: "E28 - Disfunção ovariana"},
  { cod: "E29",	text: "E29 - Disfunção testicular"},
  { cod: "E30",	text: "E30 - Transtornos da puberdade não classificados em outra parte"},
  { cod: "E31",	text: "E31 - Disfunção poliglandular"},
  { cod: "E32",	text: "E32 - Doenças do timo"},
  { cod: "E34",	text: "E34 - Outros transtornos endócrinos"},
  { cod: "E35",	text: "E35 - Transtornos das glândulas endócrinas em doenças classificadas em outra parte"},
  { cod: "E41",	text: "E41 - Marasmo nutricional"},
  { cod: "E42",	text: "E42 - Kwashiorkor marasmático"},
  { cod: "E43",	text: "E43 - Desnutrição protéico-calórica grave não especificada"},
  { cod: "E44",	text: "E44 - Desnutrição protéico-calórica de graus moderado e leve"},
  { cod: "E46",	text: "E46 - Desnutrição protéico-calórica não especificada"},
  { cod: "E51",	text: "E51 - Deficiência de tiamina"},
  { cod: "E52",	text: "E52 - Deficiência de niacina [pelagra]"},
  { cod: "E53",	text: "E53 - Deficiência de outras vitaminas do grupo B"},
  { cod: "E54",	text: "E54 - Deficiência de ácido ascórbico"},
  { cod: "E55",	text: "E55 - Deficiência de vitamina D"},
  { cod: "E56",	text: "E56 - Outras deficiências vitamínicas"},
  { cod: "E60",	text: "E60 - Deficiência de zinco da dieta"},
  { cod: "E61",	text: "E61 - Deficiência de outros elementos nutrientes"},
  { cod: "E63",	text: "E63 - Outras deficiências nutricionais"},
  { cod: "E64",	text: "E64 - Seqüelas de desnutrição e de outras deficiências nutricionais"},
  { cod: "E65",	text: "E65 - Adiposidade localizada"},
  { cod: "E66",	text: "E66 - Obesidade"},
  { cod: "E67",	text: "E67 - Outras formas de hiperalimentação"},
  { cod: "E68",	text: "E68 - Seqüelas de hiperalimentação"},
  { cod: "E70",	text: "E70 - Distúrbios do metabolismo de aminoácidos aromáticos"},
  { cod: "E71",	text: "E71 - Distúrbios do metabolismo de aminoácidos de cadeia ramificada e do metabolismo dos ácidos graxos"},
  { cod: "E72",	text: "E72 - Outros distúrbios do metabolismo de aminoácidos"},
  { cod: "E73",	text: "E73 - Intolerância à lactose"},
  { cod: "E74",	text: "E74 - Outros distúrbios do metabolismo de carboidratos"},
  { cod: "E75",	text: "E75 - Distúrbios do metabolismo de esfingolípides e outros distúrbios de depósito de lípides"},
  { cod: "E76",	text: "E76 - Distúrbios do metabolismo do glicosaminoglicano"},
  { cod: "E78",	text: "E78 - Distúrbios do metabolismo de lipoproteínas e outras lipidemias"},
  { cod: "E79",	text: "E79 - Distúrbios do metabolismo de purina e pirimidina"},
  { cod: "E80",	text: "E80 - Distúrbios do metabolismo da porfirina e da bilirrubina"},
  { cod: "E83",	text: "E83 - Distúrbios do metabolismo de minerais"},
  { cod: "E84",	text: "E84 - Fibrose cística"},
  { cod: "E85",	text: "E85 - Amiloidose"},
  { cod: "E86",	text: "E86 - Depleção de volume"},
  { cod: "E87",	text: "E87 - Outros transtornos do equilíbrio hidroeletrolítico e ácido-básico"},
  { cod: "E88",	text: "E88 - Outros distúrbios metabólicos"},
  { cod: "E89",	text: "E89 - Transtornos endócrinos e metabólicos pós-procedimentos, não classificados em outra parte"},
  { cod: "E90",	text: "E90 - Transtornos nutricionais e metabólicos em doenças classificadas em outra parte"},
  { cod: "F00",	text: "F00 - Demência na doença de Alzheimer (G30.-+)"},
  { cod: "F01",	text: "F01 - Demência vascular"},
  { cod: "F02",	text: "F02 - Demência em outras doenças classificadas em outra parte"},
  { cod: "F03",	text: "F03 - Demência não especificada"},
  { cod: "F04",	text: "F04 - Síndrome amnésica orgânica não induzida pelo álcool ou por outras substâncias psicoativas"},
  { cod: "F05",	text: "F05 - Delírium não induzido pelo álcool ou por outras substâncias psicoativas"},
  { cod: "F06",	text: "F06 - Outros transtornos mentais devidos a lesão e disfunção cerebral e a doença física"},
  { cod: "F07",	text: "F07 - Transtornos de personalidade e do comportamento devidos a doença, a lesão e a disfunção cerebral"},
  { cod: "F09",	text: "F09 - Transtorno mental orgânico ou sintomático não especificado"},
  { cod: "F10",	text: "F10 - Transtornos mentais e comportamentais devidos ao uso de álcool"},
  { cod: "F11",	text: "F11 - Transtornos mentais e comportamentais devidos ao uso de opiáceos"},
  { cod: "F12",	text: "F12 - Transtornos mentais e comportamentais devidos ao uso de canabinóides"},
  { cod: "F13",	text: "F13 - Transtornos mentais e comportamentais devidos ao uso de sedativos e hipnóticos"},
  { cod: "F14",	text: "F14 - Transtornos mentais e comportamentais devidos ao uso da cocaína"},
  { cod: "F15",	text: "F15 - Transtornos mentais e comportamentais devidos ao uso de outros estimulantes, inclusive a cafeína"},
  { cod: "F16",	text: "F16 - Transtornos mentais e comportamentais devidos ao uso de alucinógenos"},
  { cod: "F17",	text: "F17 - Transtornos mentais e comportamentais devidos ao uso de fumo"},
  { cod: "F18",	text: "F18 - Transtornos mentais e comportamentais devidos ao uso de solventes voláteis"},
  { cod: "F19",	text: "F19 - Transtornos mentais e comportamentais devidos ao uso de múltiplas drogas e ao uso de outras substâncias psicoativas"},
  { cod: "F20",	text: "F20 - Esquizofrenia"},
  { cod: "F21",	text: "F21 - Transtorno esquizotípico"},
  { cod: "F22",	text: "F22 - Transtornos delirantes persistentes"},
  { cod: "F23",	text: "F23 - Transtornos psicóticos agudos e transitórios"},
  { cod: "F24",	text: "F24 - Transtorno delirante induzido"},
  { cod: "F25",	text: "F25 - Transtornos esquizoafetivos"},
  { cod: "F28",	text: "F28 - Outros transtornos psicóticos não-orgânicos"},
  { cod: "F29",	text: "F29 - Psicose não-orgânica não especificada"},
  { cod: "F30",	text: "F30 - Episódio maníaco"},
  { cod: "F31",	text: "F31 - Transtorno afetivo bipolar"},
  { cod: "F32",	text: "F32 - Episódios depressivos"},
  { cod: "F33",	text: "F33 - Transtorno depressivo recorrente"},
  { cod: "F34",	text: "F34 - Transtornos de humor [afetivos] persistentes"},
  { cod: "F38",	text: "F38 - Outros transtornos do humor [afetivos]"},
  { cod: "F39",	text: "F39 - Transtorno do humor [afetivo] não especificado"},
  { cod: "F40",	text: "F40 - Transtornos fóbico-ansiosos"},
  { cod: "F41",	text: "F41 - Outros transtornos ansiosos"},
  { cod: "F42",	text: "F42 - Transtorno obsessivo-compulsivo"},
  { cod: "F43",	text: "F43 - Reações ao 'stress' grave e transtornos de adaptação"},
  { cod: "F44",	text: "F44 - Transtornos dissociativos [de conversão]"},
  { cod: "F45",	text: "F45 - Transtornos somatoformes"},
  { cod: "F48",	text: "F48 - Outros transtornos neuróticos"},
  { cod: "F50",	text: "F50 - Transtornos da alimentação"},
  { cod: "F51",	text: "F51 - Transtornos não-orgânicos do sono devidos a fatores emocionais"},
  { cod: "F52",	text: "F52 - Disfunção sexual, não causada por transtorno ou doença orgânica"},
  { cod: "F53",	text: "F53 - Transtornos mentais e comportamentais associados ao puerpério, não classificados em outra parte"},
  { cod: "F54",	text: "F54 - Fatores psicológicos ou comportamentais associados a doença ou a transtornos classificados em outra parte"},
  { cod: "F55",	text: "F55 - Abuso de substâncias que não produzem dependência"},
  { cod: "F59",	text: "F59 - Síndromes comportamentais associados a transtornos das funções fisiológicas e a fatores físicos, não especificadas"},
  { cod: "F60",	text: "F60 - Transtornos específicos da personalidade"},
  { cod: "F61",	text: "F61 - Transtornos mistos da personalidade e outros transtornos da personalidade"},
  { cod: "F62",	text: "F62 - Modificações duradouras da personalidade não atribuíveis a lesão ou doença cerebral"},
  { cod: "F63",	text: "F63 - Transtornos dos hábitos e dos impulsos"},
  { cod: "F64",	text: "F64 - Transtornos da identidade sexual"},
  { cod: "F65",	text: "F65 - Transtornos da preferência sexual"},
  { cod: "F66",	text: "F66 - Transtornos psicológicos e comportamentais associados ao desenvolvimento sexual e à sua orientação"},
  { cod: "F68",	text: "F68 - Outros transtornos da personalidade e do comportamento do adulto"},
  { cod: "F69",	text: "F69 - Transtorno da personalidade e do comportamento do adulto, não especificado"},
  { cod: "F70",	text: "F70 - Retardo mental leve"},
  { cod: "F71",	text: "F71 - Retardo mental moderado"},
  { cod: "F72",	text: "F72 - Retardo mental grave"},
  { cod: "F73",	text: "F73 - Retardo mental profundo"},
  { cod: "F78",	text: "F78 - Outro retardo mental"},
  { cod: "F79",	text: "F79 - Retardo mental não especificado"},
  { cod: "F80",	text: "F80 - Transtornos específicos do desenvolvimento da fala e da linguagem"},
  { cod: "F81",	text: "F81 - Transtornos específicos do desenvolvimento das habilidades escolares"},
  { cod: "F82",	text: "F82 - Transtorno específico do desenvolvimento motor"},
  { cod: "F83",	text: "F83 - Transtornos específicos misto do desenvolvimento"},
  { cod: "F84",	text: "F84 - Transtornos globais do desenvolvimento"},
  { cod: "F88",	text: "F88 - Outros transtornos do desenvolvimento psicológico"},
  { cod: "F89",	text: "F89 - Transtorno do desenvolvimento psicológico não especificado"},
  { cod: "F90",	text: "F90 - Transtornos hipercinéticos"},
  { cod: "F91",	text: "F91 - Distúrbios de conduta"},
  { cod: "F92",	text: "F92 - Transtornos mistos de conduta e das emoções"},
  { cod: "F93",	text: "F93 - Transtornos emocionais com início especificamente na infância"},
  { cod: "F94",	text: "F94 - Transtornos do funcionamento social com início especificamente durante a infância ou a adolescência"},
  { cod: "F95",	text: "F95 - Tiques"},
  { cod: "F98",	text: "F98 - Outros transtornos comportamentais e emocionais com início habitualmente durante a infância ou a adolescência"},
  { cod: "F99",	text: "F99 - Transtorno mental não especificado em outra parte"},
  { cod: "G00",	text: "G00 - Meningite bacteriana não classificada em outra parte"},
  { cod: "G01",	text: "G01 - Meningite em doenças bacterianas classificadas em outra parte"},
  { cod: "G02",	text: "G02 - Meningite em outras doenças infecciosas e parasitárias classificadas em outra parte"},
  { cod: "G03",	text: "G03 - Meningite devida a outras causas e a causas não especificadas"},
  { cod: "G04",	text: "G04 - Encefalite, mielite e encefalomielite"},
  { cod: "G05",	text: "G05 - Encefalite, mielite e encefalomielite em doenças classificadas em outra parte"},
  { cod: "G06",	text: "G06 - Abscesso e granuloma intracranianos e intra-raquidianos"},
  { cod: "G07",	text: "G07 - Abscesso e granuloma intracranianos e intraspinais em doenças classificadas em outra parte"},
  { cod: "G08",	text: "G08 - Flebite e tromboflebite intracranianas e intra-raquidianas"},
  { cod: "G09",	text: "G09 - Seqüelas de doenças inflamatórias do sistema nervoso central"},
  { cod: "G10",	text: "G10 - Doença de Huntington"},
  { cod: "G11",	text: "G11 - Ataxia hereditária"},
  { cod: "G12",	text: "G12 - Atrofia muscular espinal e síndromes correlatas"},
  { cod: "G13",	text: "G13 - Atrofias sistêmicas que afetam principalmente o sistema nervoso central em doenças classificadas em outra parte"},
  { cod: "G20",	text: "G20 - Doença de Parkinson"},
  { cod: "G21",	text: "G21 - Parkinsonismo secundário"},
  { cod: "G22",	text: "G22 - Parkinsonismo em doenças classificadas em outra parte"},
  { cod: "G23",	text: "G23 - Outras doenças degenerativas dos gânglios da base"},
  { cod: "G24",	text: "G24 - Distonia"},
  { cod: "G25",	text: "G25 - Outras doenças extrapiramidais e transtornos dos movimentos"},
  { cod: "G26",	text: "G26 - Doenças extrapiramidais e transtornos dos movimentos em doenças classificadas em outra parte"},
  { cod: "G30",	text: "G30 - Doença de Alzheimer"},
  { cod: "G31",	text: "G31 - Outras doenças degenerativas do sistema nervoso não classificadas em outra parte"},
  { cod: "G32",	text: "G32 - Outros transtornos degenerativos do sistema nervoso em doenças classificadas em outra parte"},
  { cod: "G35",	text: "G35 - Esclerose múltipla"},
  { cod: "G36",	text: "G36 - Outras desmielinizações disseminadas agudas"},
  { cod: "G37",	text: "G37 - Outras doenças desmielinizantes do sistema nervoso central"},
  { cod: "G40",	text: "G40 - Epilepsia"},
  { cod: "G41",	text: "G41 - Estado de mal epiléptico"},
  { cod: "G43",	text: "G43 - Enxaqueca"},
  { cod: "G44",	text: "G44 - Outras síndromes de algias cefálicas"},
  { cod: "G45",	text: "G45 - Acidentes vasculares cerebrais isquêmicos transitórios e síndromes correlatas"},
  { cod: "G46",	text: "G46 - Síndromes vasculares cerebrais que ocorrem em doenças cerebrovasculares (I60-I67+)"},
  { cod: "G47",	text: "G47 - Distúrbios do sono"},
  { cod: "G50",	text: "G50 - Transtornos do nervo trigêmeo"},
  { cod: "G51",	text: "G51 - Transtornos do nervo facial"},
  { cod: "G52",	text: "G52 - Transtornos de outros nervos cranianos"},
  { cod: "G53",	text: "G53 - Transtornos dos nervos cranianos em doenças classificadas em outra parte"},
  { cod: "G54",	text: "G54 - Transtornos das raízes e dos plexos nervosos"},
  { cod: "G55",	text: "G55 - Compressões das raízes e dos plexos nervosos em doenças classificadas em outra parte"},
  { cod: "G56",	text: "G56 - Mononeuropatias dos membros superiores"},
  { cod: "G57",	text: "G57 - Mononeuropatias dos membros inferiores"},
  { cod: "G58",	text: "G58 - Outras mononeuropatias"},
  { cod: "G59",	text: "G59 - Mononeuropatias em doenças classificadas em outra parte"},
  { cod: "G60",	text: "G60 - Neuropatia hereditária e idiopática"},
  { cod: "G61",	text: "G61 - Polineuropatia inflamatória"},
  { cod: "G62",	text: "G62 - Outras polineuropatias"},
  { cod: "G63",	text: "G63 - Polineuropatia em doenças classificadas em outra parte"},
  { cod: "G64",	text: "G64 - Outros transtornos do sistema nervoso periférico"},
  { cod: "G70",	text: "G70 - Miastenia gravis e outros transtornos neuromusculares"},
  { cod: "G71",	text: "G71 - Transtornos primários dos músculos"},
  { cod: "G72",	text: "G72 - Outras miopatias"},
  { cod: "G73",	text: "G73 - Transtornos da junção mioneural e dos músculos em doenças classificadas em outra parte"},
  { cod: "G80",	text: "G80 - Paralisia cerebral infantil"},
  { cod: "G81",	text: "G81 - Hemiplegia"},
  { cod: "G82",	text: "G82 - Paraplegia e tetraplegia"},
  { cod: "G83",	text: "G83 - Outras síndromes paralíticas"},
  { cod: "G90",	text: "G90 - Transtornos do sistema nervoso autônomo"},
  { cod: "G91",	text: "G91 - Hidrocefalia"},
  { cod: "G92",	text: "G92 - Encefalopatia tóxica"},
  { cod: "G93",	text: "G93 - Outros transtornos do encéfalo"},
  { cod: "G94",	text: "G94 - Outros transtornos do encéfalo em doenças classificadas em outra parte"},
  { cod: "G95",	text: "G95 - Outras doenças da medula espinal"},
  { cod: "G96",	text: "G96 - Outros transtornos do sistema nervoso central"},
  { cod: "G97",	text: "G97 - Transtornos pós-procedimento do sistema nervoso não classificados em outra parte"},
  { cod: "G98",	text: "G98 - Outras transtornos do sistema nervoso não classificados em outra parte"},
  { cod: "G99",	text: "G99 - Outros transtornos do sistema nervoso em doenças classificadas em outra parte"},
  { cod: "H00",	text: "H00 - Hordéolo e calázio"},
  { cod: "H01",	text: "H01 - Outras inflamações da pálpebra"},
  { cod: "H02",	text: "H02 - Outros transtornos da pálpebra"},
  { cod: "H03",	text: "H03 - Transtornos da pálpebra em doenças classificadas em outras partes"},
  { cod: "H04",	text: "H04 - Transtornos do aparelho lacrimal"},
  { cod: "H05",	text: "H05 - Transtornos da órbita"},
  { cod: "H06",	text: "H06 - Transtornos do aparelho lacrimal e da órbita em doenças classificadas em outra parte"},
  { cod: "H10",	text: "H10 - Conjuntivite"},
  { cod: "H11",	text: "H11 - Outros transtornos da conjuntiva"},
  { cod: "H13",	text: "H13 - Transtornos da conjuntiva em doenças classificadas em outra parte"},
  { cod: "H15",	text: "H15 - Transtornos da esclerótica"},
  { cod: "H16",	text: "H16 - Ceratite"},
  { cod: "H17",	text: "H17 - Cicatrizes e opacidades da córnea"},
  { cod: "H18",	text: "H18 - Outros transtornos da córnea"},
  { cod: "H19",	text: "H19 - Transtorno da esclerótica e da córnea em doenças classificadas em outra parte"},
  { cod: "H20",	text: "H20 - Iridociclite"},
  { cod: "H21",	text: "H21 - Outros transtornos da íris e do corpo ciliar"},
  { cod: "H22",	text: "H22 - Transtornos da íris e do corpo ciliar em doenças classificadas em outra parte"},
  { cod: "H25",	text: "H25 - Catarata senil"},
  { cod: "H26",	text: "H26 - Outras cataratas"},
  { cod: "H27",	text: "H27 - Outros transtornos do cristalino"},
  { cod: "H28",	text: "H28 - Catarata e outros transtornos do cristalino em doenças classificadas em outra parte"},
  { cod: "H30",	text: "H30 - Inflamação coriorretiniana"},
  { cod: "H31",	text: "H31 - Outros transtornos da coróide"},
  { cod: "H32",	text: "H32 - Transtornos coriorretinianos em doenças classificadas em outra parte"},
  { cod: "H33",	text: "H33 - Descolamentos e defeitos da retina"},
  { cod: "H34",	text: "H34 - Oclusões vasculares da retina"},
  { cod: "H35",	text: "H35 - Outros transtornos da retina"},
  { cod: "H36",	text: "H36 - Transtornos da retina em doenças classificadas em outra parte"},
  { cod: "H40",	text: "H40 - Glaucoma"},
  { cod: "H42",	text: "H42 - Glaucoma em doenças classificadas em outra parte"},
  { cod: "H43",	text: "H43 - Transtornos do humor vítreo"},
  { cod: "H44",	text: "H44 - Transtornos do globo ocular"},
  { cod: "H45",	text: "H45 - Transtornos do humor vítreo e do globo ocular em doenças classificadas em outra parte"},
  { cod: "H46",	text: "H46 - Neurite óptica"},
  { cod: "H47",	text: "H47 - Outros transtornos do nervo óptico e das vias ópticas"},
  { cod: "H48",	text: "H48 - Transtornos do nervo óptico [segundo par] e das vias ópticas em doenças classificadas em outra parte"},
  { cod: "H49",	text: "H49 - Estrabismo paralítico"},
  { cod: "H50",	text: "H50 - Outros estrabismos"},
  { cod: "H51",	text: "H51 - Outros transtornos do movimento binocular"},
  { cod: "H52",	text: "H52 - Transtornos da refração e da acomodação"},
  { cod: "H53",	text: "H53 - Distúrbios visuais"},
  { cod: "H54",	text: "H54 - Cegueira e visão subnormal"},
  { cod: "H55",	text: "H55 - Nistagmo e outros movimentos irregulares do olho"},
  { cod: "H57",	text: "H57 - Outros transtornos do olho e anexos"},
  { cod: "H58",	text: "H58 - Outros transtornos do olho e anexos em doenças classificadas em outra parte"},
  { cod: "H59",	text: "H59 - Transtornos do olho e anexos pós-procedimento não classificados em outra parte"},
  { cod: "H60",	text: "H60 - Otite externa"},
  { cod: "H61",	text: "H61 - Outros transtornos do ouvido externo"},
  { cod: "H62",	text: "H62 - Transtornos do ouvido externo em doenças classificadas em outra parte"},
  { cod: "H65",	text: "H65 - Otite média não-supurativa"},
  { cod: "H66",	text: "H66 - Otite média supurativa e as não especificadas"},
  { cod: "H67",	text: "H67 - Otite média em doenças classificadas em outra parte"},
  { cod: "H68",	text: "H68 - Salpingite e obstrução da trompa de Eustáquio"},
  { cod: "H69",	text: "H69 - Outros transtornos da trompa de Eustáquio"},
  { cod: "H70",	text: "H70 - Mastoidite e afecções correlatas"},
  { cod: "H71",	text: "H71 - Colesteatoma do ouvido médio"},
  { cod: "H72",	text: "H72 - Perfuração da membrana do tímpano"},
  { cod: "H73",	text: "H73 - Outros transtornos da membrana do tímpano"},
  { cod: "H74",	text: "H74 - Outros transtornos do ouvido médio e da mastóide"},
  { cod: "H75",	text: "H75 - Outros transtornos do ouvido médio e da mastóide em doenças classificadas em outra parte"},
  { cod: "H80",	text: "H80 - Otosclerose"},
  { cod: "H81",	text: "H81 - Transtornos da função vestibular"},
  { cod: "H82",	text: "H82 - Síndromes vertiginosas em doenças classificadas em outra parte"},
  { cod: "H83",	text: "H83 - Outros transtornos do ouvido interno"},
  { cod: "H90",	text: "H90 - Perda de audição por transtorno de condução e/ou neuro-sensorial"},
  { cod: "H91",	text: "H91 - Outras perdas de audição"},
  { cod: "H92",	text: "H92 - Otalgia e secreção auditiva"},
  { cod: "H93",	text: "H93 - Outros transtornos do ouvido não classificados em outra parte"},
  { cod: "H94",	text: "H94 - Outros transtornos do ouvido em doenças classificadas em outra parte"},
  { cod: "H95",	text: "H95 - Transtornos do ouvido e da apófise mastóide pós-procedimentos, não classificados em outra parte"},
  { cod: "I00",	text: "I00 - Febre reumática sem menção de comprometimento do coração"},
  { cod: "I01",	text: "I01 - Febre reumática com comprometimento do coração"},
  { cod: "I02",	text: "I02 - Coréia reumática"},
  { cod: "I05",	text: "I05 - Doenças reumáticas da valva mitral"},
  { cod: "I06",	text: "I06 - Doenças reumáticas da valva aórtica"},
  { cod: "I07",	text: "I07 - Doenças reumáticas da valva tricúspide"},
  { cod: "I08",	text: "I08 - Doenças de múltiplas valvas"},
  { cod: "I09",	text: "I09 - Outras doenças reumáticas do coração"},
  { cod: "I10",	text: "I10 - Hipertensão essencial (primária)"},
  { cod: "I11",	text: "I11 - Doença cardíaca hipertensiva"},
  { cod: "I12",	text: "I12 - Doença renal hipertensiva"},
  { cod: "I13",	text: "I13 - Doença cardíaca e renal hipertensiva"},
  { cod: "I15",	text: "I15 - Hipertensão secundária"},
  { cod: "I20",	text: "I20 - Angina pectoris"},
  { cod: "I21",	text: "I21 - Infarto agudo do miocárdio"},
  { cod: "I22",	text: "I22 - Infarto do miocárdio recorrente"},
  { cod: "I23",	text: "I23 - Algumas complicações atuais subseqüentes ao infarto agudo do miocárdio"},
  { cod: "I24",	text: "I24 - Outras doenças isquêmicas agudas do coração"},
  { cod: "I25",	text: "I25 - Doença isquêmica crônica do coração"},
  { cod: "I26",	text: "I26 - Embolia pulmonar"},
  { cod: "I27",	text: "I27 - Outras formas de doença cardíaca pulmonar"},
  { cod: "I28",	text: "I28 - Outras doenças dos vasos pulmonares"},
  { cod: "I30",	text: "I30 - Pericardite aguda"},
  { cod: "I31",	text: "I31 - Outras doenças do pericárdio"},
  { cod: "I32",	text: "I32 - Pericardite em doenças classificadas em outra parte"},
  { cod: "I33",	text: "I33 - Endocardite aguda e subaguda"},
  { cod: "I34",	text: "I34 - Transtornos não-reumáticos da valva mitral"},
  { cod: "I35",	text: "I35 - Transtornos não-reumáticos da valva aórtica"},
  { cod: "I36",	text: "I36 - Transtornos não-reumáticos da valva tricúspide"},
  { cod: "I37",	text: "I37 - Transtornos da valva pulmonar"},
  { cod: "I38",	text: "I38 - Endocardite de valva não especificada"},
  { cod: "I39",	text: "I39 - Endocardite e transtornos valvulares cardíacos em doenças classificadas em outra parte"},
  { cod: "I40",	text: "I40 - Miocardite aguda"},
  { cod: "I41",	text: "I41 - Miocardite em doenças classificadas em outra parte"},
  { cod: "I42",	text: "I42 - Cardiomiopatias"},
  { cod: "I43",	text: "I43 - Cardiomiopatia em doenças classificadas em outra parte"},
  { cod: "I44",	text: "I44 - Bloqueio atrioventricular e do ramo esquerdo"},
  { cod: "I45",	text: "I45 - Outros transtornos de condução"},
  { cod: "I46",	text: "I46 - Parada cardíaca"},
  { cod: "I47",	text: "I47 - Taquicardia paroxística"},
  { cod: "I48",	text: "I48 - 'Flutter' e fibrilação atrial"},
  { cod: "I49",	text: "I49 - Outras arritmias cardíacas"},
  { cod: "I50",	text: "I50 - Insuficiência cardíaca"},
  { cod: "I51",	text: "I51 - Complicações de cardiopatias e doenças cardíacas mal definidas"},
  { cod: "I52",	text: "I52 - Outras afecções cardíacas em doenças classificadas em outra parte"},
  { cod: "I60",	text: "I60 - Hemorragia subaracnóide"},
  { cod: "I61",	text: "I61 - Hemorragia intracerebral"},
  { cod: "I62",	text: "I62 - Outras hemorragias intracranianas não-traumáticas"},
  { cod: "I63",	text: "I63 - Infarto cerebral"},
  { cod: "I64",	text: "I64 - Acidente vascular cerebral, não especificado como hemorrágico ou isquêmico"},
  { cod: "I65",	text: "I65 - Oclusão e estenose de artérias pré-cerebrais que não resultam em infarto cerebral"},
  { cod: "I66",	text: "I66 - Oclusão e estenose de artérias cerebrais que não resultam em infarto cerebral"},
  { cod: "I67",	text: "I67 - Outras doenças cerebrovasculares"},
  { cod: "I68",	text: "I68 - Transtornos cerebrovasculares em doenças classificadas em outra parte"},
  { cod: "I69",	text: "I69 - Seqüelas de doenças cerebrovasculares"},
  { cod: "I70",	text: "I70 - Aterosclerose"},
  { cod: "I71",	text: "I71 - Aneurisma e dissecção da aorta"},
  { cod: "I72",	text: "I72 - Outros aneurismas"},
  { cod: "I73",	text: "I73 - Outras doenças vasculares periféricas"},
  { cod: "I74",	text: "I74 - Embolia e trombose arteriais"},
  { cod: "I77",	text: "I77 - Outras afecções das artérias e arteríolas"},
  { cod: "I78",	text: "I78 - Doenças dos capilares"},
  { cod: "I79",	text: "I79 - Transtornos das artérias, das arteríolas e dos capilares em doenças classificadas em outra parte"},
  { cod: "I80",	text: "I80 - Flebite e tromboflebite"},
  { cod: "I81",	text: "I81 - Trombose da veia porta"},
  { cod: "I82",	text: "I82 - Outra embolia e trombose venosas"},
  { cod: "I83",	text: "I83 - Varizes dos membros inferiores"},
  { cod: "I84",	text: "I84 - Hemorróidas"},
  { cod: "I85",	text: "I85 - Varizes esofagianas"},
  { cod: "I86",	text: "I86 - Varizes de outras localizações"},
  { cod: "I87",	text: "I87 - Outras transtornos das veias"},
  { cod: "I88",	text: "I88 - Linfadenite inespecífica"},
  { cod: "I89",	text: "I89 - Outros transtornos não-infecciosos dos vasos linfáticos e dos gânglios linfáticos"},
  { cod: "I95",	text: "I95 - Hipotensão"},
  { cod: "I97",	text: "I97 - Transtornos do aparelho circulatório, subseqüentes a procedimentos não classificados em outra parte"},
  { cod: "I98",	text: "I98 - Outros transtornos do aparelho circulatório em doenças classificadas em outra parte"},
  { cod: "I99",	text: "I99 - Outros transtornos do aparelho circulatório e os não especificados"},
  { cod: "J00",	text: "J00 - Nasofaringite aguda [resfriado comum]"},
  { cod: "J01",	text: "J01 - Sinusite aguda"},
  { cod: "J02",	text: "J02 - Faringite aguda"},
  { cod: "J03",	text: "J03 - Amigdalite aguda"},
  { cod: "J04",	text: "J04 - Laringite e traqueíte agudas"},
  { cod: "J05",	text: "J05 - Laringite obstrutiva aguda [crupe] e epiglotite"},
  { cod: "J06",	text: "J06 - Infecções agudas das vias aéreas superiores de localizações múltiplas e não especificadas"},
  { cod: "J09",	text: "J09 - Influenza [gripe] devida a vírus identificado da gripe aviária"},
  { cod: "J10",	text: "J10 - Influenza devida a vírus da influenza [gripe] identificado"},
  { cod: "J11",	text: "J11 - Influenza [gripe] devida a vírus não identificado"},
  { cod: "J12",	text: "J12 - Pneumonia viral não classificada em outra parte"},
  { cod: "J13",	text: "J13 - Pneumonia devida a Streptococcus pneumoniae"},
  { cod: "J14",	text: "J14 - Pneumonia devida a Haemophilus infuenzae"},
  { cod: "J15",	text: "J15 - Pneumonia bacteriana não classificada em outra parte"},
  { cod: "J16",	text: "J16 - Pneumonia devida a outros microorganismos infecciosos especificados não classificados em outra parte"},
  { cod: "J17",	text: "J17 - Pneumonia em doenças classificadas em outra parte"},
  { cod: "J18",	text: "J18 - Pneumonia por microorganismo não especificada"},
  { cod: "J20",	text: "J20 - Bronquite aguda"},
  { cod: "J21",	text: "J21 - Bronquiolite aguda"},
  { cod: "J22",	text: "J22 - Infecções agudas não especificada das vias aéreas inferiores"},
  { cod: "J30",	text: "J30 - Rinite alérgica e vasomotora"},
  { cod: "J31",	text: "J31 - Rinite, nasofaringite e faringite crônicas"},
  { cod: "J32",	text: "J32 - Sinusite crônica"},
  { cod: "J33",	text: "J33 - Pólipo nasal"},
  { cod: "J34",	text: "J34 - Outros transtornos do nariz e dos seios paranasais"},
  { cod: "J35",	text: "J35 - Doenças crônicas das amígdalas e das adenóides"},
  { cod: "J36",	text: "J36 - Abscesso periamigdaliano"},
  { cod: "J37",	text: "J37 - Laringite e laringotraqueíte crônicas"},
  { cod: "J38",	text: "J38 - Doenças das cordas vocais e da laringe não classificadas em outra parte"},
  { cod: "J39",	text: "J39 - Outras doenças das vias aéreas superiores"},
  { cod: "J40",	text: "J40 - Bronquite não especificada como aguda ou crônica"},
  { cod: "J41",	text: "J41 - Bronquite crônica simples e a mucopurulenta"},
  { cod: "J42",	text: "J42 - Bronquite crônica não especificada"},
  { cod: "J43",	text: "J43 - Enfisema"},
  { cod: "J44",	text: "J44 - Outras doenças pulmonares obstrutivas crônicas"},
  { cod: "J45",	text: "J45 - Asma"},
  { cod: "J46",	text: "J46 - Estado de mal asmático"},
  { cod: "J47",	text: "J47 - Bronquectasia"},
  { cod: "J60",	text: "J60 - Pneumoconiose dos mineiros de carvão"},
  { cod: "J61",	text: "J61 - Pneumoconiose devida a amianto [asbesto] e outras fibras minerais"},
  { cod: "J62",	text: "J62 - Pneumoconiose devida a poeira que contenham sílica"},
  { cod: "J63",	text: "J63 - Pneumoconiose devida a outras poeiras inorgânicas"},
  { cod: "J64",	text: "J64 - Pneumoconiose não especificada"},
  { cod: "J65",	text: "J65 - Pneumoconiose associada com tuberculose"},
  { cod: "J66",	text: "J66 - Doenças das vias aéreas devida a poeiras orgânicas específicas"},
  { cod: "J67",	text: "J67 - Pneumonite de hipersensibilidade devida a poeiras orgânicas"},
  { cod: "J68",	text: "J68 - Afecções respiratórias devidas a inalação de produtos químicos, gases, fumaças e vapores"},
  { cod: "J69",	text: "J69 - Pneumonite devida a sólidos e líquidos"},
  { cod: "J70",	text: "J70 - Afecções respiratórias devida a outros agentes externos"},
  { cod: "J80",	text: "J80 - Síndrome do desconforto respiratório do adulto"},
  { cod: "J81",	text: "J81 - Edema pulmonar, não especificado de outra forma"},
  { cod: "J82",	text: "J82 - Eosinofilia pulmonar, não classificada em outra parte"},
  { cod: "J84",	text: "J84 - Outras doenças pulmonares intersticiais"},
  { cod: "J85",	text: "J85 - Abscesso do pulmão e do mediastino"},
  { cod: "J86",	text: "J86 - Piotórax"},
  { cod: "J90",	text: "J90 - Derrame pleural não classificado em outra parte"},
  { cod: "J91",	text: "J91 - Derrame pleural em afecções classificadas em outra parte"},
  { cod: "J92",	text: "J92 - Placas pleurais"},
  { cod: "J93",	text: "J93 - Pneumotórax"},
  { cod: "J94",	text: "J94 - Outras afecções pleurais"},
  { cod: "J95",	text: "J95 - Afecções respiratórias pós-procedimentos não classificadas em outra parte"},
  { cod: "J96",	text: "J96 - Insuficiência respiratória não classificada de outra parte"},
  { cod: "J98",	text: "J98 - Outros transtornos respiratórios"},
  { cod: "J99",	text: "J99 - Transtornos respiratórios em doenças classificadas em outra parte"},
  { cod: "K00",	text: "K00 - Distúrbios do desenvolvimento e da erupção dos dentes"},
  { cod: "K01",	text: "K01 - Dentes inclusos e impactados"},
  { cod: "K02",	text: "K02 - Cárie dentária"},
  { cod: "K03",	text: "K03 - Outras doenças dos tecidos dentários duros"},
  { cod: "K04",	text: "K04 - Doenças da polpa e dos tecidos periapicais"},
  { cod: "K05",	text: "K05 - Gengivite e doenças periodontais"},
  { cod: "K06",	text: "K06 - Outros transtornos da gengiva e do rebordo alveolar sem dentes"},
  { cod: "K07",	text: "K07 - Anomalias dentofaciais (inclusive a maloclusão)"},
  { cod: "K08",	text: "K08 - Outros transtornos dos dentes e de suas estruturas de sustentação"},
  { cod: "K09",	text: "K09 - Cistos da região bucal não classificados em outra parte"},
  { cod: "K10",	text: "K10 - Outras doenças dos maxilares"},
  { cod: "K11",	text: "K11 - Doenças das glândulas salivares"},
  { cod: "K12",	text: "K12 - Estomatite e lesões correlatas"},
  { cod: "K13",	text: "K13 - Outras doenças do lábio e da mucosa oral"},
  { cod: "K14",	text: "K14 - Doenças da língua"},
  { cod: "K20",	text: "K20 - Esofagite"},
  { cod: "K21",	text: "K21 - Doença de refluxo gastroesofágico"},
  { cod: "K22",	text: "K22 - Outras doenças do esôfago"},
  { cod: "K23",	text: "K23 - Transtornos do esôfago em doenças classificadas em outra parte"},
  { cod: "K25",	text: "K25 - Úlcera gástrica"},
  { cod: "K26",	text: "K26 - Úlcera duodenal"},
  { cod: "K27",	text: "K27 - Úlcera péptica de localização não especificada"},
  { cod: "K28",	text: "K28 - Úlcera gastrojejunal"},
  { cod: "K29",	text: "K29 - Gastrite e duodenite"},
  { cod: "K30",	text: "K30 - Dispepsia"},
  { cod: "K31",	text: "K31 - Outras doenças do estômago e do duodeno"},
  { cod: "K35",	text: "K35 - Apendicite aguda"},
  { cod: "K36",	text: "K36 - Outras formas de apendicite"},
  { cod: "K37",	text: "K37 - Apendicite, sem outras especificações"},
  { cod: "K38",	text: "K38 - Outras doenças do apêndice"},
  { cod: "K40",	text: "K40 - Hérnia inguinal"},
  { cod: "K41",	text: "K41 - Hérnia femoral"},
  { cod: "K42",	text: "K42 - Hérnia umbilical"},
  { cod: "K43",	text: "K43 - Hérnia ventral"},
  { cod: "K44",	text: "K44 - Hérnia diafragmática"},
  { cod: "K45",	text: "K45 - Outras hérnias abdominais"},
  { cod: "K46",	text: "K46 - Hérnia abdominal não especificada"},
  { cod: "K50",	text: "K50 - Doença de Crohn [enterite regional]"},
  { cod: "K51",	text: "K51 - Colite ulcerativa"},
  { cod: "K52",	text: "K52 - Outras gastroenterites e colites não-infecciosas"},
  { cod: "K55",	text: "K55 - Transtornos vasculares do intestino"},
  { cod: "K56",	text: "K56 - Íleo paralítico e obstrução intestinal sem hérnia"},
  { cod: "K57",	text: "K57 - Doença diverticular do intestino"},
  { cod: "K58",	text: "K58 - Síndrome do cólon irritável"},
  { cod: "K59",	text: "K59 - Outros transtornos funcionais do intestino"},
  { cod: "K60",	text: "K60 - Fissura e fístula das regiões anal e retal"},
  { cod: "K61",	text: "K61 - Abscesso das regiões anal e retal"},
  { cod: "K62",	text: "K62 - Outras doenças do reto e do ânus"},
  { cod: "K63",	text: "K63 - Outras doenças do intestino"},
  { cod: "K65",	text: "K65 - Peritonite"},
  { cod: "K66",	text: "K66 - Outros transtornos do peritônio"},
  { cod: "K67",	text: "K67 - Comprometimento do peritônio, em doenças infecciosas classificadas em outra parte"},
  { cod: "K70",	text: "K70 - Doença alcoólica do fígado"},
  { cod: "K71",	text: "K71 - Doença hepática tóxica"},
  { cod: "K72",	text: "K72 - Insuficiência hepática não classificada em outra parte"},
  { cod: "K73",	text: "K73 - Hepatite crônica não classificada em outra parte"},
  { cod: "K74",	text: "K74 - Fibrose e cirrose hepáticas"},
  { cod: "K75",	text: "K75 - Outras doenças inflamatórias do fígado"},
  { cod: "K76",	text: "K76 - Outras doenças do fígado"},
  { cod: "K77",	text: "K77 - Transtornos do fígado em doenças classificadas em outra parte"},
  { cod: "K80",	text: "K80 - Colelitíase"},
  { cod: "K81",	text: "K81 - Colecistite"},
  { cod: "K82",	text: "K82 - Outras doenças da vesícula biliar"},
  { cod: "K83",	text: "K83 - Outras doenças das vias biliares"},
  { cod: "K85",	text: "K85 - Pancreatite aguda"},
  { cod: "K86",	text: "K86 - Outras doenças do pâncreas"},
  { cod: "K87",	text: "K87 - Transtornos da vesícula biliar, das vias biliares e do pâncreas em doenças classificadas em outra parte"},
  { cod: "K90",	text: "K90 - Má-absorção intestinal"},
  { cod: "K91",	text: "K91 - Transtornos do aparelho digestivo pós-procedimentos, não classificados em outra parte"},
  { cod: "K92",	text: "K92 - Outras doenças do aparelho digestivo"},
  { cod: "K93",	text: "K93 - Transtornos de outros órgãos digestivos em doenças classificadas em outra parte"},
  { cod: "L00",	text: "L00 - Síndrome da pele escaldada estafilocócica do recém-nascido"},
  { cod: "L01",	text: "L01 - Impetigo"},
  { cod: "L02",	text: "L02 - Abscesso cutâneo, furúnculo e antraz"},
  { cod: "L03",	text: "L03 - Celulite (Flegmão)"},
  { cod: "L04",	text: "L04 - Linfadenite aguda"},
  { cod: "L05",	text: "L05 - Cisto pilonidal"},
  { cod: "L08",	text: "L08 - Outras infecções localizadas da pele e do tecido subcutâneo"},
  { cod: "L10",	text: "L10 - Pênfigo"},
  { cod: "L11",	text: "L11 - Outras afecções acantolíticas"},
  { cod: "L12",	text: "L12 - Penfigóide"},
  { cod: "L13",	text: "L13 - Outras afecções bolhosas"},
  { cod: "L14",	text: "L14 - Afecções bolhosas em doenças classificadas em outra parte"},
  { cod: "L20",	text: "L20 - Dermatite atópica"},
  { cod: "L21",	text: "L21 - Dermatite seborréica"},
  { cod: "L22",	text: "L22 - Dermatite das fraldas"},
  { cod: "L23",	text: "L23 - Dermatites alérgicas de contato"},
  { cod: "L24",	text: "L24 - Dermatites de contato por irritantes"},
  { cod: "L25",	text: "L25 - Dermatite de contato não especificada"},
  { cod: "L26",	text: "L26 - Dermatite esfoliativa"},
  { cod: "L27",	text: "L27 - Dermatite devida a substâncias de uso interno"},
  { cod: "L28",	text: "L28 - Líquen simples crônico e prurigo"},
  { cod: "L29",	text: "L29 - Prurido"},
  { cod: "L30",	text: "L30 - Outras dermatites"},
  { cod: "L40",	text: "L40 - Psoríase"},
  { cod: "L41",	text: "L41 - Parapsoríase"},
  { cod: "L42",	text: "L42 - Pitiríase rósea"},
  { cod: "L43",	text: "L43 - Líquen plano"},
  { cod: "L44",	text: "L44 - Outras afecções pápulo-descamativas"},
  { cod: "L45",	text: "L45 - Afecções pápulo-descamativas em doenças classificadas em outra parte"},
  { cod: "L50",	text: "L50 - Urticária"},
  { cod: "L51",	text: "L51 - Eritema polimorfo (eritema multiforme)"},
  { cod: "L52",	text: "L52 - Eritema nodoso"},
  { cod: "L53",	text: "L53 - Outras afecções eritematosas"},
  { cod: "L54",	text: "L54 - Eritema em doenças classificadas em outra parte"},
  { cod: "L55",	text: "L55 - Queimadura solar"},
  { cod: "L56",	text: "L56 - Outras alterações agudas da pele devidas a radiação ultravioleta"},
  { cod: "L57",	text: "L57 - Alterações da pele devidas à exposição crônica à radiação não ionizante"},
  { cod: "L58",	text: "L58 - Radiodermatite"},
  { cod: "L59",	text: "L59 - Outras afecções da pele e do tecido subcutâneo relacionadas com a radiação"},
  { cod: "L60",	text: "L60 - Afecções das unhas"},
  { cod: "L62",	text: "L62 - Afecções das unhas em doenças classificadas em outra parte"},
  { cod: "L63",	text: "L63 - Alopécia areata"},
  { cod: "L64",	text: "L64 - Alopécia androgênica"},
  { cod: "L65",	text: "L65 - Outras formas não cicatriciais da perda de cabelos ou pêlos"},
  { cod: "L66",	text: "L66 - Alopécia cicatricial [perda de cabelos ou pêlos, cicatricial]"},
  { cod: "L67",	text: "L67 - Anormalidades da cor e do pedículo dos cabelos e dos pêlos"},
  { cod: "L68",	text: "L68 - Hipertricose"},
  { cod: "L70",	text: "L70 - Acne"},
  { cod: "L71",	text: "L71 - Rosácea"},
  { cod: "L72",	text: "L72 - Cistos foliculares da pele e do tecido subcutâneo"},
  { cod: "L73",	text: "L73 - Outras afecções foliculares"},
  { cod: "L74",	text: "L74 - Afecções das glândulas sudoríparas écrinas"},
  { cod: "L75",	text: "L75 - Afecções das glândulas sudoríparas apócrinas"},
  { cod: "L80",	text: "L80 - Vitiligo"},
  { cod: "L81",	text: "L81 - Outros transtornos da pigmentação"},
  { cod: "L82",	text: "L82 - Ceratose seborréica"},
  { cod: "L83",	text: "L83 - Acantose nigricans"},
  { cod: "L84",	text: "L84 - Calos e calosidades"},
  { cod: "L85",	text: "L85 - Outras formas de espessamento epidérmico"},
  { cod: "L86",	text: "L86 - Ceratodermia em doenças classificadas em outra parte"},
  { cod: "L87",	text: "L87 - Transtornos da eliminação transepidérmica"},
  { cod: "L88",	text: "L88 - Piodermite gangrenosa"},
  { cod: "L89",	text: "L89 - Úlcera de decúbito"},
  { cod: "L90",	text: "L90 - Afecções atróficas da pele"},
  { cod: "L91",	text: "L91 - Afecções hipertróficas da pele"},
  { cod: "L92",	text: "L92 - Afecções granulomatosas da pele e do tecido subcutâneo"},
  { cod: "L93",	text: "L93 - Lúpus eritematoso"},
  { cod: "L94",	text: "L94 - Outras afecções localizadas do tecido conjuntivo"},
  { cod: "L95",	text: "L95 - Vasculite limitada a pele não classificadas em outra parte"},
  { cod: "L97",	text: "L97 - Úlcera dos membros inferiores não classificada em outra parte"},
  { cod: "L98",	text: "L98 - Outras afecções da pele e do tecido subcutâneo não classificadas em outra parte"},
  { cod: "L99",	text: "L99 - Outras afecções da pele e do tecido subcutâneo em doenças classificadas em outra parte"},
  { cod: "M00",	text: "M00 - Artrite piogênica"},
  { cod: "M01",	text: "M01 - Infecções diretas da articulação em doenças infecciosas e parasitárias classificadas em outra parte"},
  { cod: "M02",	text: "M02 - Artropatias reacionais"},
  { cod: "M03",	text: "M03 - Artropatias pós-infecciosas e reacionais em doenças infecciosas classificadas em outra parte"},
  { cod: "M05",	text: "M05 - Artrite reumatóide soro-positiva"},
  { cod: "M06",	text: "M06 - Outras artrites reumatóides"},
  { cod: "M07",	text: "M07 - Artropatias psoriásicas e enteropáticas"},
  { cod: "M08",	text: "M08 - Artrite juvenil"},
  { cod: "M09",	text: "M09 - Artrite juvenil em doenças classificadas em outra parte"},
  { cod: "M10",	text: "M10 - Gota"},
  { cod: "M11",	text: "M11 - Outras artropatias por deposição de cristais"},
  { cod: "M12",	text: "M12 - Outras artropatias especificadas"},
  { cod: "M13",	text: "M13 - Outras artrites"},
  { cod: "M14",	text: "M14 - Artropatias em outras doenças classificadas em outra parte"},
  { cod: "M15",	text: "M15 - Poliartrose"},
  { cod: "M16",	text: "M16 - Coxartrose [artrose do quadril]"},
  { cod: "M17",	text: "M17 - Gonartrose [artrose do joelho]"},
  { cod: "M18",	text: "M18 - Artrose da primeira articulação carpometacarpiana"},
  { cod: "M19",	text: "M19 - Outras artroses"},
  { cod: "M20",	text: "M20 - Deformidades adquiridas dos dedos das mãos e dos pés"},
  { cod: "M21",	text: "M21 - Outras deformidades adquiridas dos membros"},
  { cod: "M22",	text: "M22 - Transtornos da rótula [patela]"},
  { cod: "M23",	text: "M23 - Transtornos internos dos joelhos"},
  { cod: "M24",	text: "M24 - Outras transtornos articulares específicos"},
  { cod: "M25",	text: "M25 - Outros transtornos articulares não classificados em outra parte"},
  { cod: "M30",	text: "M30 - Poliarterite nodosa e afecções correlatas"},
  { cod: "M31",	text: "M31 - Outras vasculopatias necrotizantes"},
  { cod: "M32",	text: "M32 - Lúpus eritematoso disseminado [sistêmico]"},
  { cod: "M33",	text: "M33 - Dermatopoliomiosite"},
  { cod: "M34",	text: "M34 - Esclerose sistêmica"},
  { cod: "M35",	text: "M35 - Outras afecções sistêmicas do tecido conjuntivo"},
  { cod: "M36",	text: "M36 - Doenças sistêmicas do tecido conjuntivo em doenças classificadas em outra parte"},
  { cod: "M40",	text: "M40 - Cifose e lordose"},
  { cod: "M41",	text: "M41 - Escoliose"},
  { cod: "M42",	text: "M42 - Osteocondrose da coluna vertebral"},
  { cod: "M43",	text: "M43 - Outras dorsopatias deformantes"},
  { cod: "M45",	text: "M45 - Espondilite ancilosante"},
  { cod: "M46",	text: "M46 - Outras espondilopatias inflamatórias"},
  { cod: "M47",	text: "M47 - Espondilose"},
  { cod: "M48",	text: "M48 - Outras espondilopatias"},
  { cod: "M49",	text: "M49 - Espondilopatias em doenças classificadas em outra parte"},
  { cod: "M50",	text: "M50 - Transtornos dos discos cervicais"},
  { cod: "M51",	text: "M51 - Outros transtornos de discos intervertebrais"},
  { cod: "M53",	text: "M53 - Outras dorsopatias não classificadas em outra parte"},
  { cod: "M54",	text: "M54 - Dorsalgia"},
  { cod: "M60",	text: "M60 - Miosite"},
  { cod: "M61",	text: "M61 - Calcificação e ossificação do músculo"},
  { cod: "M62",	text: "M62 - Outros transtornos musculares"},
  { cod: "M63",	text: "M63 - Transtornos de músculo em doenças classificadas em outra parte"},
  { cod: "M65",	text: "M65 - Sinovite e tenossinovite"},
  { cod: "M66",	text: "M66 - Ruptura espontânea de sinóvia e de tendão"},
  { cod: "M67",	text: "M67 - Outros transtornos das sinóvias e dos tendões"},
  { cod: "M68",	text: "M68 - Transtorno de sinóvias e de tendões em doenças classificadas em outra parte"},
  { cod: "M70",	text: "M70 - Transtornos dos tecidos moles relacionados com o uso, uso excessivo e pressão"},
  { cod: "M71",	text: "M71 - Outras bursopatias"},
  { cod: "M72",	text: "M72 - Transtornos fibroblásticos"},
  { cod: "M73",	text: "M73 - Transtornos dos tecidos moles em doenças classificadas em outra parte"},
  { cod: "M75",	text: "M75 - Lesões do ombro"},
  { cod: "M76",	text: "M76 - Entesopatias dos membros inferiores, excluindo pé"},
  { cod: "M77",	text: "M77 - Outras entesopatias"},
  { cod: "M79",	text: "M79 - Outros transtornos dos tecidos moles, não classificados em outra parte"},
  { cod: "M80",	text: "M80 - Osteoporose com fratura patológica"},
  { cod: "M81",	text: "M81 - Osteoporose sem fratura patológica"},
  { cod: "M82",	text: "M82 - Osteoporose em doenças classificadas em outra parte"},
  { cod: "M83",	text: "M83 - Osteomalácia do adulto"},
  { cod: "M84",	text: "M84 - Transtornos da continuidade do osso"},
  { cod: "M85",	text: "M85 - Outros transtornos da densidade e da estrutura ósseas"},
  { cod: "M86",	text: "M86 - Osteomielite"},
  { cod: "M87",	text: "M87 - Osteonecrose"},
  { cod: "M88",	text: "M88 - Doença de Paget do osso (osteíte deformante)"},
  { cod: "M89",	text: "M89 - Outros transtornos ósseos"},
  { cod: "M90",	text: "M90 - Osteopatias em doenças classificadas em outra parte"},
  { cod: "M91",	text: "M91 - Osteocondrose juvenil do quadril e da pelve"},
  { cod: "M92",	text: "M92 - Outras osteocondroses juvenis"},
  { cod: "M93",	text: "M93 - Outras osteocondropatias"},
  { cod: "M94",	text: "M94 - Outros transtornos das cartilagens"},
  { cod: "M95",	text: "M95 - Outras deformidades adquiridas do sistema osteomuscular e do tecido conjuntivo"},
  { cod: "M96",	text: "M96 - Transtornos osteomusculares pós-procedimentos não classificados em outra parte"},
  { cod: "M99",	text: "M99 - Lesões biomecânicas não classificadas em outra parte"},
  { cod: "N00",	text: "N00 - Síndrome nefrítica aguda"},
  { cod: "N01",	text: "N01 - Síndrome nefrítica rapidamente progressiva"},
  { cod: "N02",	text: "N02 - Hematúria recidivante e persistente"},
  { cod: "N03",	text: "N03 - Síndrome nefrítica crônica"},
  { cod: "N04",	text: "N04 - Síndrome nefrótica"},
  { cod: "N05",	text: "N05 - Síndrome nefrítica não especificada"},
  { cod: "N06",	text: "N06 - Proteinúria isolada com lesão morfológica especificada"},
  { cod: "N07",	text: "N07 - Nefropatia hereditária não classificada em outra parte"},
  { cod: "N08",	text: "N08 - Transtornos glomerulares em doenças classificadas em outra parte"},
  { cod: "N10",	text: "N10 - Nefrite túbulo-intersticial aguda"},
  { cod: "N11",	text: "N11 - Nefrite túbulo-intersticial crônica"},
  { cod: "N12",	text: "N12 - Nefrite túbulo-intersticial não especificada se aguda ou crônica"},
  { cod: "N13",	text: "N13 - Uropatia obstrutiva e por refluxo"},
  { cod: "N14",	text: "N14 - Afecções tubulares e túbulo-intersticiais induzidas por drogas ou metais pesados"},
  { cod: "N15",	text: "N15 - Outras doenças renais túbulo-intersticiais"},
  { cod: "N16",	text: "N16 - Transtornos renais túbulo-intersticiais em doenças classificadas em outra parte"},
  { cod: "N17",	text: "N17 - Insuficiência renal aguda"},
  { cod: "N18",	text: "N18 - Insuficiência renal crônica"},
  { cod: "N19",	text: "N19 - Insuficiência renal não especificada"},
  { cod: "N20",	text: "N20 - Calculose do rim e do ureter"},
  { cod: "N21",	text: "N21 - Calculose do trato urinário inferior"},
  { cod: "N22",	text: "N22 - Calculose do trato urinário inferior em doenças classificadas em outra parte"},
  { cod: "N23",	text: "N23 - Cólica nefrética não especificada"},
  { cod: "N25",	text: "N25 - Transtornos resultantes de função renal tubular alterada"},
  { cod: "N26",	text: "N26 - Rim contraído, não especificado"},
  { cod: "N27",	text: "N27 - Hipoplasia renal de causa desconhecida"},
  { cod: "N28",	text: "N28 - Outros transtornos do rim e do ureter não classificado em outra parte"},
  { cod: "N29",	text: "N29 - Outros transtornos do rim e do ureter em doenças classificadas em outra parte"},
  { cod: "N30",	text: "N30 - Cistite"},
  { cod: "N31",	text: "N31 - Disfunções neuromusculares da bexiga não classificados em outra parte"},
  { cod: "N32",	text: "N32 - Outros transtornos da bexiga"},
  { cod: "N33",	text: "N33 - Transtornos da bexiga em doenças classificadas em outra parte"},
  { cod: "N34",	text: "N34 - Uretrite e síndrome uretral"},
  { cod: "N35",	text: "N35 - Estenose da uretra"},
  { cod: "N36",	text: "N36 - Outros transtornos da uretra"},
  { cod: "N37",	text: "N37 - Transtornos da uretra em doenças classificadas em outra parte"},
  { cod: "N39",	text: "N39 - Outros transtornos do trato urinário"},
  { cod: "N40",	text: "N40 - Hiperplasia da próstata"},
  { cod: "N41",	text: "N41 - Doenças inflamatórias da próstata"},
  { cod: "N42",	text: "N42 - Outras afecções da próstata"},
  { cod: "N43",	text: "N43 - Hidrocele e espermatocele"},
  { cod: "N44",	text: "N44 - Torção do testículo"},
  { cod: "N45",	text: "N45 - Orquite e epididimite"},
  { cod: "N46",	text: "N46 - Infertilidade masculina"},
  { cod: "N47",	text: "N47 - Hipertrofia do prepúcio, fimose e parafimose"},
  { cod: "N48",	text: "N48 - Outros transtornos do pênis"},
  { cod: "N49",	text: "N49 - Transtornos inflamatórios de órgãos genitais masculinos, não classificados em outra parte"},
  { cod: "N50",	text: "N50 - Outros transtornos dos órgãos genitais masculinos"},
  { cod: "N51",	text: "N51 - Transtornos dos órgãos genitais masculinos em doenças classificadas em outra parte"},
  { cod: "N60",	text: "N60 - Displasias mamárias benignas"},
  { cod: "N61",	text: "N61 - Transtornos inflamatórios da mama"},
  { cod: "N62",	text: "N62 - Hipertrofia da mama"},
  { cod: "N63",	text: "N63 - Nódulo mamário não especificado"},
  { cod: "N64",	text: "N64 - Outras doenças da mama"},
  { cod: "N70",	text: "N70 - Salpingite e ooforite"},
  { cod: "N71",	text: "N71 - Doença inflamatória do útero, exceto o colo"},
  { cod: "N72",	text: "N72 - Doença inflamatória do colo do útero"},
  { cod: "N73",	text: "N73 - Outras doenças inflamatórias pélvicas femininas"},
  { cod: "N74",	text: "N74 - Transtornos inflamatórios da pelve feminina em doenças classificadas em outra parte"},
  { cod: "N75",	text: "N75 - Doenças da glândula de Bartholin"},
  { cod: "N76",	text: "N76 - Outras afecções inflamatórias da vagina e da vulva"},
  { cod: "N77",	text: "N77 - Ulceração e inflamação vulvovaginais em doenças classificadas em outra parte"},
  { cod: "N80",	text: "N80 - Endometriose"},
  { cod: "N81",	text: "N81 - Prolapso genital feminino"},
  { cod: "N82",	text: "N82 - Fístulas do trato genital feminino"},
  { cod: "N83",	text: "N83 - Transtornos não-inflamatórios do ovário, da trompa de Falópio e do ligamento largo"},
  { cod: "N84",	text: "N84 - Pólipo do trato genital feminino"},
  { cod: "N85",	text: "N85 - Outros transtornos não-inflamatórios do útero, exceto do colo do útero"},
  { cod: "N86",	text: "N86 - Erosão e ectrópio do colo do útero"},
  { cod: "N87",	text: "N87 - Displasia do colo do útero"},
  { cod: "N88",	text: "N88 - Outros transtornos não-inflamatórios do colo do útero"},
  { cod: "N89",	text: "N89 - Outros transtornos não-inflamatórios da vagina"},
  { cod: "N90",	text: "N90 - Outros transtornos não-inflamatórios da vulva e do períneo"},
  { cod: "N91",	text: "N91 - Menstruação ausente, escassa e pouco freqüente"},
  { cod: "N92",	text: "N92 - Menstruação excessiva, freqüente e irregular"},
  { cod: "N93",	text: "N93 - Outros sangramentos anormais do útero e da vagina"},
  { cod: "N94",	text: "N94 - Dor e outras afecções associadas com os órgãos genitais femininos e com o ciclo menstrual"},
  { cod: "N95",	text: "N95 - Transtornos da menopausa e da perimenopausa"},
  { cod: "N96",	text: "N96 - Abortamento habitual"},
  { cod: "N97",	text: "N97 - Infertilidade feminina"},
  { cod: "N98",	text: "N98 - Complicações associadas à fecundação artificial"},
  { cod: "N99",	text: "N99 - Transtornos do trato geniturinário pós-procedimentos não classificados em outra parte"},
  { cod: "O00",	text: "O00 - Gravidez ectópica"},
  { cod: "O01",	text: "O01 - Mola hidatiforme"},
  { cod: "O02",	text: "O02 - Outros produtos anormais da concepção"},
  { cod: "O03",	text: "O03 - Aborto espontâneo"},
  { cod: "O04",	text: "O04 - Aborto por razões médicas e legais"},
  { cod: "O05",	text: "O05 - Outros tipos de aborto"},
  { cod: "O06",	text: "O06 - Aborto não especificado"},
  { cod: "O07",	text: "O07 - Falha de tentativa de aborto"},
  { cod: "O08",	text: "O08 - Complicações conseqüentes a aborto e gravidez ectópica ou molar"},
  { cod: "O10",	text: "O10 - Hipertensão pré-existente complicando a gravidez, o parto e o puerpério"},
  { cod: "O11",	text: "O11 - Distúrbio hipertensivo pré-existente com proteinúria superposta"},
  { cod: "O12",	text: "O12 - Edema e proteinúria gestacionais [induzidos pela gravidez], sem hipertensão"},
  { cod: "O13",	text: "O13 - Hipertensão gestacional [induzida pela gravidez] sem proteinúria significativa"},
  { cod: "O14",	text: "O14 - Hipertensão gestacional [induzida pela gravidez] com proteinúria significativa"},
  { cod: "O15",	text: "O15 - Eclâmpsia"},
  { cod: "O16",	text: "O16 - Hipertensão materna não especificada"},
  { cod: "O20",	text: "O20 - Hemorragia do início da gravidez"},
  { cod: "O21",	text: "O21 - Vômitos excessivos na gravidez"},
  { cod: "O22",	text: "O22 - Complicações venosas na gravidez"},
  { cod: "O23",	text: "O23 - Infecções do trato geniturinário na gravidez"},
  { cod: "O24",	text: "O24 - Diabetes mellitus na gravidez"},
  { cod: "O25",	text: "O25 - Desnutrição na gravidez"},
  { cod: "O26",	text: "O26 - Assistência materna por outras complicações ligadas predominantemente a gravidez"},
  { cod: "O28",	text: "O28 - Achados anormais do rastreamento ['screening'] antenatal da mãe"},
  { cod: "O29",	text: "O29 - Complicações de anestesia administrada durante a gravidez"},
  { cod: "O30",	text: "O30 - Gestação múltipla"},
  { cod: "O31",	text: "O31 - Complicações específicas de gestação múltipla"},
  { cod: "O32",	text: "O32 - Assistência prestada à mãe por motivo de apresentação anormal, conhecida ou suspeitada, do feto"},
  { cod: "O33",	text: "O33 - Assistência prestada à mãe por uma desproporção conhecida ou suspeita"},
  { cod: "O34",	text: "O34 - Assistência prestada à mãe por anormalidade, conhecida ou suspeita, dos órgãos pélvicos maternos"},
  { cod: "O35",	text: "O35 - Assistência prestada à mãe por anormalidade e lesão fetais, conhecidas ou suspeitadas"},
  { cod: "O36",	text: "O36 - Assistência prestada à mãe por outros problemas fetais conhecidos ou suspeitados"},
  { cod: "O40",	text: "O40 - Polihidrâmnio"},
  { cod: "O41",	text: "O41 - Outros transtornos das membranas e do líquido amniótico"},
  { cod: "O42",	text: "O42 - Ruptura prematura de membranas"},
  { cod: "O43",	text: "O43 - Transtornos da placenta"},
  { cod: "O44",	text: "O44 - Placenta prévia"},
  { cod: "O45",	text: "O45 - Descolamento prematuro da placenta [abruptio placentae]"},
  { cod: "O46",	text: "O46 - Hemorragia anteparto não classificada em outra parte"},
  { cod: "O47",	text: "O47 - Falso trabalho de parto"},
  { cod: "O48",	text: "O48 - Gravidez prolongada"},
  { cod: "O60",	text: "O60 - Parto pré-termo"},
  { cod: "O61",	text: "O61 - Falha na indução do trabalho de parto"},
  { cod: "O62",	text: "O62 - Anormalidades da contração uterina"},
  { cod: "O63",	text: "O63 - Trabalho de parto prolongado"},
  { cod: "O64",	text: "O64 - Obstrução do trabalho de parto devida à má-posição ou má-apresentação do feto"},
  { cod: "O65",	text: "O65 - Obstrução do trabalho de parto devida a anormalidade pélvica da mãe"},
  { cod: "O66",	text: "O66 - Outras formas de obstrução do trabalho de parto"},
  { cod: "O67",	text: "O67 - Trabalho de parto e parto complicados por hemorragia intraparto não classificados em outra parte"},
  { cod: "O68",	text: "O68 - Trabalho de parto e parto complicados por sofrimento fetal"},
  { cod: "O69",	text: "O69 - Trabalho de parto e parto complicados por anormalidade do cordão umbilical"},
  { cod: "O70",	text: "O70 - Laceração do períneo durante o parto"},
  { cod: "O71",	text: "O71 - Outros traumatismos obstétricos"},
  { cod: "O72",	text: "O72 - Hemorragia pós-parto"},
  { cod: "O73",	text: "O73 - Retenção da placenta e das membranas, sem hemorragias"},
  { cod: "O75",	text: "O75 - Outras complicações do trabalho de parto e do parto não classificadas em outra parte"},
  { cod: "O80",	text: "O80 - Parto único espontâneo"},
  { cod: "O82",	text: "O82 - Parto único por cesariana"},
  { cod: "O83",	text: "O83 - Outros tipos de parto único assistido"},
  { cod: "O84",	text: "O84 - Parto múltiplo"},
  { cod: "O85",	text: "O85 - Infecção puerperal"},
  { cod: "O86",	text: "O86 - Outras infecções puerperais"},
  { cod: "O87",	text: "O87 - Complicações venosas no puerpério"},
  { cod: "O88",	text: "O88 - Embolia de origem obstétrica"},
  { cod: "O89",	text: "O89 - Complicações da anestesia administrada durante o puerpério"},
  { cod: "O90",	text: "O90 - Complicações do puerpério não classificadas em outra parte"},
  { cod: "O91",	text: "O91 - Infecções mamárias associadas ao parto"},
  { cod: "O92",	text: "O92 - Outras afecções da mama e da lactação associadas ao parto"},
  { cod: "O94",	text: "O94 - Seqüelas de complicações da gravidez, parto e puerpério"},
  { cod: "O95",	text: "O95 - Morte obstétrica de causa não especificada"},
  { cod: "O98",	text: "O98 - Doenças infecciosas e parasitárias maternas classificáveis em outra parte mas que compliquem a gravidez, o parto e o puerpério"},
  { cod: "O99",	text: "O99 - Outras doenças da mãe, classificadas em outra parte, mas que complicam a gravidez o parto e o puerpério"},
  { cod: "P00",	text: "P00 - Feto e recém-nascido afetados por afecções maternas, não obrigatoriamente relacionadas com a gravidez atual"},
  { cod: "P01",	text: "P01 - Feto e recém-nascido afetados por complicações maternas da gravidez"},
  { cod: "P02",	text: "P02 - Feto e recém-nascido afetados por complicações da placenta, do cordão umbilical e das membranas"},
  { cod: "P03",	text: "P03 - Feto e recém-nascido afetados por outras complicações do trabalho de parto e do parto"},
  { cod: "P04",	text: "P04 - Feto e recém-nascido afetados por influências nocivas transmitidas ao feto via placenta ou leite materno"},
  { cod: "P05",	text: "P05 - Crescimento fetal retardado e desnutrição fetal"},
  { cod: "P07",	text: "P07 - Transtornos relacionados com a gestação de curta duração e peso baixo ao nascer não classificados em outra parte"},
  { cod: "P08",	text: "P08 - Transtornos relacionados com a gestação prolongada e peso elevado ao nascer"},
  { cod: "P10",	text: "P10 - Laceração intracraniana e hemorragia devidas a traumatismo de parto"},
  { cod: "P11",	text: "P11 - Outros traumatismos de parto do sistema nervoso central"},
  { cod: "P13",	text: "P13 - Lesões do esqueleto devidas a traumatismo de parto"},
  { cod: "P14",	text: "P14 - Lesões ao nascer do sistema nervoso periférico"},
  { cod: "P15",	text: "P15 - Outros traumatismos de parto"},
  { cod: "P23",	text: "P23 - Pneumonia congênita"},
  { cod: "P27",	text: "P27 - Doença respiratória crônica originada no período perinatal"},
  { cod: "P29",	text: "P29 - Transtornos cardiovasculares originados no período perinatal"},
  { cod: "P36",	text: "P36 - Septicemia bacteriana do recém-nascido"},
  { cod: "P37",	text: "P37 - Outras doenças infecciosas e parasitárias congênitas"},
  { cod: "P39",	text: "P39 - Outras infecções específicas do período perinatal"},
  { cod: "P50",	text: "P50 - Perda sangüínea fetal"},
  { cod: "P52",	text: "P52 - Hemorragia intracraniana não-traumática do feto e do recém-nascido"},
  { cod: "P54",	text: "P54 - Outras hemorragias neonatais"},
  { cod: "P55",	text: "P55 - Doença hemolítica do feto e do recém-nascido"},
  { cod: "P56",	text: "P56 - Hidropsia fetal devida a doença hemolítica"},
  { cod: "P57",	text: "P57 - Kernicterus"},
  { cod: "P61",	text: "P61 - Outros transtornos hematológicos perinatais"},
  { cod: "P70",	text: "P70 - Transtornos transitórios do metabolismo dos carboidratos específicos do feto e do recém-nascido"},
  { cod: "P72",	text: "P72 - Outros transtornos endócrinos transitórios do período neonatal"},
  { cod: "P78",	text: "P78 - Outros transtornos do aparelho digestivo do período perinatal"},
  { cod: "P83",	text: "P83 - Outras afecções comprometendo o tegumento específicas do feto e do recém-nascido"},
  { cod: "P91",	text: "P91 - Outros distúrbios da função cerebral do recém-nascido"},
  { cod: "P92",	text: "P92 - Problemas de alimentação do recém-nascido"},
  { cod: "P95",	text: "P95 - Morte fetal de causa não especificada"},
  { cod: "P96",	text: "P96 - Outras afecções originadas no período perinatal"},
  { cod: "Q00",	text: "Q00 - Anencefalia e malformações similares"},
  { cod: "Q01",	text: "Q01 - Encefalocele"},
  { cod: "Q02",	text: "Q02 - Microcefalia"},
  { cod: "Q03",	text: "Q03 - Hidrocefalia congênita"},
  { cod: "Q04",	text: "Q04 - Outras malformações congênitas do cérebro"},
  { cod: "Q05",	text: "Q05 - Espinha bífida"},
  { cod: "Q06",	text: "Q06 - Outras malformações congênitas da medula espinhal"},
  { cod: "Q07",	text: "Q07 - Outras malformações congênitas do sistema nervoso"},
  { cod: "Q10",	text: "Q10 - Malformações congênitas das pálpebras, do aparelho lacrimal e da órbita"},
  { cod: "Q11",	text: "Q11 - Anoftalmia, microftalmia e macroftalmia"},
  { cod: "Q12",	text: "Q12 - Malformações congênitas do cristalino"},
  { cod: "Q13",	text: "Q13 - Malformações congênitas da câmara anterior do olho"},
  { cod: "Q14",	text: "Q14 - Malformações congênitas da câmara posterior do olho"},
  { cod: "Q15",	text: "Q15 - Outras malformações congênitas do olho"},
  { cod: "Q16",	text: "Q16 - Malformações congênitas do ouvido causando comprometimento da audição"},
  { cod: "Q17",	text: "Q17 - Outras malformações congênitas da orelha"},
  { cod: "Q18",	text: "Q18 - Outras malformações congênitas da face e do pescoço"},
  { cod: "Q20",	text: "Q20 - Malformações congênitas das câmaras e das comunicações cardíacas"},
  { cod: "Q21",	text: "Q21 - Malformações congênitas dos septos cardíacos"},
  { cod: "Q22",	text: "Q22 - Malformações congênitas das valvas pulmonar e tricúspide"},
  { cod: "Q23",	text: "Q23 - Malformações congênitas das valvas aórtica e mitral"},
  { cod: "Q24",	text: "Q24 - Outras malformações congênitas do coração"},
  { cod: "Q25",	text: "Q25 - Malformações congênitas das grandes artérias"},
  { cod: "Q26",	text: "Q26 - Malformações congênitas das grandes veias"},
  { cod: "Q27",	text: "Q27 - Outras malformações congênitas do sistema vascular periférico"},
  { cod: "Q28",	text: "Q28 - Outras malformações congênitas do aparelho circulatório"},
  { cod: "Q30",	text: "Q30 - Malformação congênita do nariz"},
  { cod: "Q32",	text: "Q32 - Malformações congênitas da traquéia e dos brônquios"},
  { cod: "Q33",	text: "Q33 - Malformações congênitas do pulmão"},
  { cod: "Q34",	text: "Q34 - Outras malformações congênitas do aparelho respiratório"},
  { cod: "Q35",	text: "Q35 - Fenda palatina"},
  { cod: "Q36",	text: "Q36 - Fenda labial"},
  { cod: "Q37",	text: "Q37 - Fenda labial com fenda palatina"},
  { cod: "Q38",	text: "Q38 - Outras malformações congênitas da língua, da boca e da faringe"},
  { cod: "Q39",	text: "Q39 - Malformações congênitas do esôfago"},
  { cod: "Q40",	text: "Q40 - Outras malformações congênitas do trato digestivo superior"},
  { cod: "Q41",	text: "Q41 - Ausência, atresia e estenose congênita do intestino delgado"},
  { cod: "Q42",	text: "Q42 - Ausência, atresia e estenose congênita do cólon"},
  { cod: "Q43",	text: "Q43 - Outras malformações congênitas do intestino"},
  { cod: "Q44",	text: "Q44 - Malformações congênitas da vesícula biliar, das vias biliares e do fígado"},
  { cod: "Q45",	text: "Q45 - Outras malformações congênitas do aparelho digestivo"},
  { cod: "Q50",	text: "Q50 - Malformações congênitas dos ovários, das trompas de Falópio e dos ligamentos largos"},
  { cod: "Q51",	text: "Q51 - Malformações congênitas do útero e do colo do útero"},
  { cod: "Q52",	text: "Q52 - Outras malformações congênitas dos órgãos genitais femininos"},
  { cod: "Q53",	text: "Q53 - Testículo não-descido"},
  { cod: "Q54",	text: "Q54 - Hipospádias"},
  { cod: "Q55",	text: "Q55 - Outras malformações congênitas dos órgãos genitais masculinos"},
  { cod: "Q56",	text: "Q56 - Sexo indeterminado e pseudo-hermafroditismo"},
  { cod: "Q60",	text: "Q60 - Agenesia renal e outros defeitos de redução do rim"},
  { cod: "Q61",	text: "Q61 - Doenças císticas do rim"},
  { cod: "Q62",	text: "Q62 - Anomalias congênitas obstrutivas da pelve renal e malformações congênitas do ureter"},
  { cod: "Q63",	text: "Q63 - Outras malformações congênitas do rim"},
  { cod: "Q64",	text: "Q64 - Outras malformações congênitas do aparelho urinário"},
  { cod: "Q65",	text: "Q65 - Malformações congênitas do quadril"},
  { cod: "Q66",	text: "Q66 - Deformidades congênitas do pé"},
  { cod: "Q67",	text: "Q67 - Deformidades osteomusculares congênitas da cabeça, da face, da coluna e do tórax"},
  { cod: "Q68",	text: "Q68 - Outras deformidades osteomusculares congênitas"},
  { cod: "Q69",	text: "Q69 - Polidactilia"},
  { cod: "Q70",	text: "Q70 - Sindactilia"},
  { cod: "Q71",	text: "Q71 - Defeitos, por redução, do membro superior"},
  { cod: "Q72",	text: "Q72 - Defeitos, por redução, do membro inferior"},
  { cod: "Q73",	text: "Q73 - Defeitos por redução de membro não especificado"},
  { cod: "Q74",	text: "Q74 - Outras malformações congênitas dos membros"},
  { cod: "Q75",	text: "Q75 - Outras malformações congênitas dos ossos do crânio e da face"},
  { cod: "Q76",	text: "Q76 - Malformações congênitas da coluna vertebral e dos ossos do tórax"},
  { cod: "Q77",	text: "Q77 - Osteocondrodisplasia com anomalias de crescimento dos ossos longos e da coluna vertebral"},
  { cod: "Q78",	text: "Q78 - Outras osteocondrodisplasias"},
  { cod: "Q79",	text: "Q79 - Malformações congênitas do sistema osteomuscular não classificadas em outra parte"},
  { cod: "Q80",	text: "Q80 - Ictiose congênita"},
  { cod: "Q81",	text: "Q81 - Epidermólise bolhosa"},
  { cod: "Q82",	text: "Q82 - Outras malformações congênitas da pele"},
  { cod: "Q83",	text: "Q83 - Malformações congênitas da mama"},
  { cod: "Q84",	text: "Q84 - Outras malformações congênitas do tegumento"},
  { cod: "Q85",	text: "Q85 - Facomatoses não classificadas em outra parte"},
  { cod: "Q86",	text: "Q86 - Síndromes com malformações congênitas devidas a causas exógenas conhecidas, não classificadas em outra parte"},
  { cod: "Q87",	text: "Q87 - Outras síndromes com malformações congênitas que acometem múltiplos sistemas"},
  { cod: "Q89",	text: "Q89 - Outras malformações congênitas não classificadas em outra parte"},
  { cod: "Q90",	text: "Q90 - Síndrome de Down"},
  { cod: "Q91",	text: "Q91 - Síndrome de Edwards e síndrome de Patau"},
  { cod: "Q93",	text: "Q93 - Monossomias e deleções dos autossomos, não classificadas em outra parte"},
  { cod: "Q95",	text: "Q95 - Rearranjos equilibrados e marcadores estruturais, não classificados em outra parte"},
  { cod: "Q96",	text: "Q96 - Síndrome de Turner"},
  { cod: "Q99",	text: "Q99 - Outras anomalias dos cromossomos, não classificadas em outra parte"},
  { cod: "R00",	text: "R00 - Anormalidades do batimento cardíaco"},
  { cod: "R01",	text: "R01 - Sopros e outros ruídos cardíacos"},
  { cod: "R02",	text: "R02 - Gangrena não classificada em outra parte"},
  { cod: "R03",	text: "R03 - Valor anormal da pressão arterial sem diagnóstico"},
  { cod: "R04",	text: "R04 - Hemorragia das vias respiratórias"},
  { cod: "R05",	text: "R05 - Tosse"},
  { cod: "R06",	text: "R06 - Anormalidades da respiração"},
  { cod: "R07",	text: "R07 - Dor de garganta e no peito"},
  { cod: "R09",	text: "R09 - Outros sintomas e sinais relativos aos aparelhos circulatório e respiratório"},
  { cod: "R10",	text: "R10 - Dor abdominal e pélvica"},
  { cod: "R11",	text: "R11 - Náusea e vômitos"},
  { cod: "R12",	text: "R12 - Pirose"},
  { cod: "R13",	text: "R13 - Disfagia"},
  { cod: "R15",	text: "R15 - Incontinência fecal"},
  { cod: "R16",	text: "R16 - Hepatomegalia e esplenomegalia não classificadas em outra parte"},
  { cod: "R17",	text: "R17 - Icterícia não especificada"},
  { cod: "R18",	text: "R18 - Ascite"},
  { cod: "R19",	text: "R19 - Outros sintomas e sinais relativos ao aparelho digestivo e ao abdome"},
  { cod: "R20",	text: "R20 - Distúrbios da sensibilidade cutânea"},
  { cod: "R21",	text: "R21 - Eritema e outras erupções cutâneas não especificadas"},
  { cod: "R22",	text: "R22 - Tumefação, massa ou tumoração localizadas da pele e do tecido subcutâneo"},
  { cod: "R23",	text: "R23 - Outras alterações cutâneas"},
  { cod: "R25",	text: "R25 - Movimentos involuntários anormais"},
  { cod: "R26",	text: "R26 - Anormalidades da marcha e da mobilidade"},
  { cod: "R27",	text: "R27 - Outros distúrbios da coordenação"},
  { cod: "R29",	text: "R29 - Outros sintomas e sinais relativos aos sistemas nervoso e osteomuscular"},
  { cod: "R30",	text: "R30 - Dor associada à micção"},
  { cod: "R31",	text: "R31 - Hematúria não especificada"},
  { cod: "R32",	text: "R32 - Incontinência urinária não especificada"},
  { cod: "R33",	text: "R33 - Retenção urinária"},
  { cod: "R34",	text: "R34 - Anúria e oligúria"},
  { cod: "R35",	text: "R35 - Poliúria"},
  { cod: "R39",	text: "R39 - Outros sintomas e sinais relativos ao aparelho urinário"},
  { cod: "R40",	text: "R40 - Sonolência, estupor e coma"},
  { cod: "R41",	text: "R41 - Outros sintomas e sinais relativos à função cognitiva e à consciência"},
  { cod: "R42",	text: "R42 - Tontura e instabilidade"},
  { cod: "R43",	text: "R43 - Distúrbios do olfato e do paladar"},
  { cod: "R44",	text: "R44 - Outros sintomas e sinais relativos às sensações e às percepções gerais"},
  { cod: "R45",	text: "R45 - Sintomas e sinais relativos ao estado emocional"},
  { cod: "R46",	text: "R46 - Sintomas e sinais relativos à aparência e ao comportamento"},
  { cod: "R47",	text: "R47 - Distúrbios da fala não classificados em outra parte"},
  { cod: "R48",	text: "R48 - Dislexia e outras disfunções simbólicas, não classificadas em outra parte"},
  { cod: "R49",	text: "R49 - Distúrbios da voz"},
  { cod: "R50",	text: "R50 - Febre de origem desconhecida"},
  { cod: "R51",	text: "R51 - Cefaléia"},
  { cod: "R52",	text: "R52 - Dor não classificada em outra parte"},
  { cod: "R53",	text: "R53 - Mal estar, fadiga"},
  { cod: "R54",	text: "R54 - Senilidade"},
  { cod: "R55",	text: "R55 - Síncope e colapso"},
  { cod: "R56",	text: "R56 - Convulsões, não classificadas em outra parte"},
  { cod: "R57",	text: "R57 - Choque não classificado em outra parte"},
  { cod: "R58",	text: "R58 - Hemorragia não classificada em outra parte"},
  { cod: "R59",	text: "R59 - Aumento de volume dos gânglios linfáticos"},
  { cod: "R60",	text: "R60 - Edema não classificado em outra parte"},
  { cod: "R61",	text: "R61 - Hiperidrose"},
  { cod: "R62",	text: "R62 - Retardo do desenvolvimento fisiológico normal"},
  { cod: "R63",	text: "R63 - Sintomas e sinais relativos à ingestão de alimentos e líquidos"},
  { cod: "R64",	text: "R64 - Caquexia"},
  { cod: "R68",	text: "R68 - Outros sintomas e sinais gerais"},
  { cod: "R69",	text: "R69 - Causas desconhecidas e não especificadas de morbidade"},
  { cod: "R73",	text: "R73 - Aumento da glicemia"},
  { cod: "R74",	text: "R74 - Anormalidades dos níveis de enzimas séricas"},
  { cod: "R75",	text: "R75 - Evidência laboratorial do vírus da imunodeficiência humana [HIV]"},
  { cod: "R80",	text: "R80 - Proteinúria isolada"},
  { cod: "R81",	text: "R81 - Glicosúria"},
  { cod: "R84",	text: "R84 - Achados anormais de material proveniente dos órgãos respiratórios e do tórax"},
  { cod: "R90",	text: "R90 - Resultados anormais de exames para diagnóstico por imagem do sistema nervoso central"},
  { cod: "R91",	text: "R91 - Achados anormais, de exames para diagnóstico por imagem, do pulmão"},
  { cod: "R92",	text: "R92 - Achados anormais, de exames para diagnóstico por imagem, da mama"},
  { cod: "R93",	text: "R93 - Achados anormais de exames para diagnóstico por imagem de outras estruturas do corpo"},
  { cod: "S00",	text: "S00 - Traumatismo superficial da cabeça"},
  { cod: "S01",	text: "S01 - Ferimento da cabeça"},
  { cod: "S02",	text: "S02 - Fratura do crânio e dos ossos da face"},
  { cod: "S03",	text: "S03 - Luxação, entorse ou distensão das articulações e dos ligamentos da cabeça"},
  { cod: "S04",	text: "S04 - Traumatismo dos nervos cranianos"},
  { cod: "S05",	text: "S05 - Traumatismo do olho e da órbita ocular"},
  { cod: "S06",	text: "S06 - Traumatismo intracraniano"},
  { cod: "S07",	text: "S07 - Lesões por esmagamento da cabeça"},
  { cod: "S08",	text: "S08 - Amputação traumática de parte da cabeça"},
  { cod: "S09",	text: "S09 - Outros traumatismos da cabeça e os não especificados"},
  { cod: "S10",	text: "S10 - Traumatismo superficial do pescoço"},
  { cod: "S11",	text: "S11 - Ferimento do pescoço"},
  { cod: "S12",	text: "S12 - Fratura do pescoço"},
  { cod: "S13",	text: "S13 - Luxação, entorse ou distensão das articulações e dos ligamentos do pescoço"},
  { cod: "S14",	text: "S14 - Traumatismo de nervos e da medula espinhal ao nível cervical"},
  { cod: "S15",	text: "S15 - Traumatismo dos vasos sangüíneos ao nível do pescoço"},
  { cod: "S16",	text: "S16 - Traumatismo de tendões e de músculos do pescoço"},
  { cod: "S17",	text: "S17 - Lesões por esmagamento do pescoço"},
  { cod: "S18",	text: "S18 - Amputação traumática ao nível do pescoço"},
  { cod: "S19",	text: "S19 - Outros traumatismos do pescoço e os não especificados"},
  { cod: "S20",	text: "S20 - Traumatismo superficial do tórax"},
  { cod: "S21",	text: "S21 - Ferimento do tórax"},
  { cod: "S22",	text: "S22 - Fratura de costela(s), esterno e coluna torácica"},
  { cod: "S23",	text: "S23 - Luxação, entorse e distensão de articulações e dos ligamentos do tórax"},
  { cod: "S24",	text: "S24 - Traumatismos de nervos e da medula espinhal ao nível do tórax"},
  { cod: "S25",	text: "S25 - Traumatismo de vasos sangüíneos do tórax"},
  { cod: "S26",	text: "S26 - Traumatismo do coração"},
  { cod: "S27",	text: "S27 - Traumatismo de outros órgãos intratorácicos e dos não especificados"},
  { cod: "S28",	text: "S28 - Lesão por esmagamento do tórax e amputação traumática de parte do tórax"},
  { cod: "S29",	text: "S29 - Outros traumatismos do tórax e os não especificados"},
  { cod: "S30",	text: "S30 - Traumatismo superficial do abdome, do dorso e da pelve"},
  { cod: "S31",	text: "S31 - Ferimento do abdome, do dorso e da pelve"},
  { cod: "S32",	text: "S32 - Fratura da coluna lombar e da pelve"},
  { cod: "S33",	text: "S33 - Luxação, entorse ou distensão das articulações e dos ligamentos da coluna lombar e da pelve"},
  { cod: "S34",	text: "S34 - Traumatismo dos nervos e da medula lombar ao nível do abdome, do dorso e da pelve"},
  { cod: "S35",	text: "S35 - Traumatismo de vasos sangüíneos ao nível do abdome, do dorso e da pelve"},
  { cod: "S36",	text: "S36 - Traumatismo de órgãos intra-abdominais"},
  { cod: "S37",	text: "S37 - Traumatismo do aparelho urinário e de órgãos pélvicos"},
  { cod: "S38",	text: "S38 - Lesão por esmagamento e amputação traumática de parte do abdome, do dorso e da pelve"},
  { cod: "S39",	text: "S39 - Outros traumatismos e os não especificados do abdome, do dorso e da pelve"},
  { cod: "S40",	text: "S40 - Traumatismo superficial do ombro e do braço"},
  { cod: "S41",	text: "S41 - Ferimento do ombro e do braço"},
  { cod: "S42",	text: "S42 - Fratura do ombro e do braço"},
  { cod: "S43",	text: "S43 - Luxação, entorse e distensão das articulações e dos ligamentos da cintura escapular"},
  { cod: "S44",	text: "S44 - Traumatismo de nervos ao nível do ombro e do braço"},
  { cod: "S45",	text: "S45 - Traumatismo dos vasos sangüíneos ao nível do ombro e do braço"},
  { cod: "S46",	text: "S46 - Traumatismo de tendão e músculo ao nível do ombro e do braço"},
  { cod: "S47",	text: "S47 - Lesão por esmagamento do ombro e do braço"},
  { cod: "S48",	text: "S48 - Amputação traumática do ombro e do braço"},
  { cod: "S49",	text: "S49 - Outros traumatismos e os não especificados do ombro e do braço"},
  { cod: "S50",	text: "S50 - Traumatismo superficial do cotovelo e do antebraço"},
  { cod: "S51",	text: "S51 - Ferimento do antebraço"},
  { cod: "S52",	text: "S52 - Fratura do antebraço"},
  { cod: "S53",	text: "S53 - Luxação, entorse e distensão das articulações e dos ligamentos do cotovelo"},
  { cod: "S54",	text: "S54 - Traumatismo de nervos ao nível do antebraço"},
  { cod: "S55",	text: "S55 - Traumatismo de vasos sangüíneos ao nível do antebraço"},
  { cod: "S56",	text: "S56 - Traumatismo do músculo e tendão ao nível do antebraço"},
  { cod: "S57",	text: "S57 - Lesão por esmagamento do antebraço"},
  { cod: "S58",	text: "S58 - Amputação traumática do cotovelo e do antebraço"},
  { cod: "S59",	text: "S59 - Outros traumatismos do antebraço e os não especificados"},
  { cod: "S60",	text: "S60 - Traumatismo superficial do punho e da mão"},
  { cod: "S61",	text: "S61 - Ferimento do punho e da mão"},
  { cod: "S62",	text: "S62 - Fratura ao nível do punho e da mão"},
  { cod: "S63",	text: "S63 - Luxação, entorse e distensão das articulações e dos ligamentos ao nível do punho e da mão"},
  { cod: "S64",	text: "S64 - Traumatismo de nervos ao nível do punho e da mão"},
  { cod: "S65",	text: "S65 - Traumatismo de vasos sangüíneos ao nível do punho e da mão"},
  { cod: "S66",	text: "S66 - Traumatismo de músculo e tendão ao nível do punho e da mão"},
  { cod: "S67",	text: "S67 - Lesão por esmagamento do punho e da mão"},
  { cod: "S68",	text: "S68 - Amputação traumática ao nível do punho e da mão"},
  { cod: "S69",	text: "S69 - Outros traumatismos e os não especificados do punho e da mão"},
  { cod: "S70",	text: "S70 - Traumatismo superficial do quadril e da coxa"},
  { cod: "S71",	text: "S71 - Ferimento do quadril e da coxa"},
  { cod: "S72",	text: "S72 - Fratura do fêmur"},
  { cod: "S73",	text: "S73 - Luxação, entorse e distensão da articulação e dos ligamentos do quadril"},
  { cod: "S74",	text: "S74 - Traumatismo de nervos ao nível do quadril e da coxa"},
  { cod: "S75",	text: "S75 - Traumatismo de vasos sangüíneos ao nível do quadril e da coxa"},
  { cod: "S76",	text: "S76 - Traumatismo de músculo e de tendão ao nível do quadril e da coxa"},
  { cod: "S77",	text: "S77 - Lesão por esmagamento do quadril e da coxa"},
  { cod: "S78",	text: "S78 - Amputação traumática do quadril e da coxa"},
  { cod: "S79",	text: "S79 - Outros traumatismos e os não especificados do quadril e da coxa"},
  { cod: "S80",	text: "S80 - Traumatismo superficial da perna"},
  { cod: "S81",	text: "S81 - Ferimento da perna"},
  { cod: "S82",	text: "S82 - Fratura da perna, incluindo tornozelo"},
  { cod: "S83",	text: "S83 - Luxação, entorse e distensão das articulações e dos ligamentos do joelho"},
  { cod: "S84",	text: "S84 - Traumatismo de nervos periféricos da perna"},
  { cod: "S85",	text: "S85 - Traumatismo de vasos sangüíneos da perna"},
  { cod: "S86",	text: "S86 - Traumatismos de músculo e de tendão ao nível da perna"},
  { cod: "S87",	text: "S87 - Traumatismo por esmagamento da perna"},
  { cod: "S88",	text: "S88 - Amputação traumática da perna"},
  { cod: "S89",	text: "S89 - Outros traumatismos e os não especificados da perna"},
  { cod: "S90",	text: "S90 - Traumatismo superficial do tornozelo e do pé"},
  { cod: "S91",	text: "S91 - Ferimentos do tornozelo e do pé"},
  { cod: "S92",	text: "S92 - Fratura do pé (exceto do tornozelo)"},
  { cod: "S93",	text: "S93 - Luxação, entorse e distensão das articulações e dos ligamentos ao nível do tornozelo e do pé"},
  { cod: "S94",	text: "S94 - Traumatismo dos nervos ao nível do tornozelo e do pé"},
  { cod: "S95",	text: "S95 - Traumatismo de vasos sangüíneos ao nível do tornozelo e do pé"},
  { cod: "S96",	text: "S96 - Traumatismos do músculo e tendão ao nível do tornozelo e do pé"},
  { cod: "S97",	text: "S97 - Lesão por esmagamento do tornozelo e do pé"},
  { cod: "S98",	text: "S98 - Amputação traumática do tornozelo e do pé"},
  { cod: "S99",	text: "S99 - Outros traumatismos e os não especificados do tornozelo e do pé"},
  { cod: "T00",	text: "T00 - Traumatismos superficiais envolvendo múltiplas regiões do corpo"},
  { cod: "T01",	text: "T01 - Ferimentos envolvendo múltiplas regiões do corpo"},
  { cod: "T02",	text: "T02 - Fraturas envolvendo múltiplas regiões do corpo"},
  { cod: "T03",	text: "T03 - Luxações, entorses e distensões envolvendo regiões múltiplas do corpo"},
  { cod: "T04",	text: "T04 - Traumatismos por esmagamento envolvendo múltiplas regiões do corpo"},
  { cod: "T05",	text: "T05 - Amputações traumáticas envolvendo múltiplas regiões do corpo"},
  { cod: "T06",	text: "T06 - Outros traumatismos envolvendo regiões múltiplas do corpo, não classificados em outra parte"},
  { cod: "T07",	text: "T07 - Traumatismos múltiplos não especificados"},
  { cod: "T08",	text: "T08 - Fratura da coluna, nível não especificado"},
  { cod: "T09",	text: "T09 - Outros traumatismos de coluna e tronco, nível não especificado"},
  { cod: "T10",	text: "T10 - Fratura do membro superior, nível não especificado"},
  { cod: "T11",	text: "T11 - Outros traumatismos de membro superior, nível não especificado"},
  { cod: "T12",	text: "T12 - Fratura do membro inferior, nível não especificado"},
  { cod: "T13",	text: "T13 - Outros traumatismos de membro inferior, nível não especificado"},
  { cod: "T14",	text: "T14 - Traumatismo de região não especificada do corpo"},
  { cod: "T15",	text: "T15 - Corpo estranho na parte externa do olho"},
  { cod: "T16",	text: "T16 - Corpo estranho no ouvido"},
  { cod: "T17",	text: "T17 - Corpo estranho no trato respiratório"},
  { cod: "T18",	text: "T18 - Corpo estranho no aparelho digestivo"},
  { cod: "T19",	text: "T19 - Corpo estranho no trato geniturinário"},
  { cod: "T20",	text: "T20 - Queimadura e corrosão da cabeça e pescoço"},
  { cod: "T21",	text: "T21 - Queimadura e corrosão do tronco"},
  { cod: "T22",	text: "T22 - Queimadura e corrosão do ombro e membro superior, exceto punho e mão"},
  { cod: "T23",	text: "T23 - Queimadura e corrosão do punho e da mão"},
  { cod: "T24",	text: "T24 - Queimadura e corrosão do quadril e membro inferior, exceto tornozelo e do pé"},
  { cod: "T25",	text: "T25 - Queimadura e corrosão do tornozelo e do pé"},
  { cod: "T26",	text: "T26 - Queimadura e corrosão limitadas ao olho e seus anexos"},
  { cod: "T27",	text: "T27 - Queimadura e corrosão do trato respiratório"},
  { cod: "T28",	text: "T28 - Queimadura e corrosão de outros órgãos internos"},
  { cod: "T29",	text: "T29 - Queimaduras e corrosões de múltiplas regiões do corpo"},
  { cod: "T30",	text: "T30 - Queimadura e corrosão, parte não especificada do corpo"},
  { cod: "T31",	text: "T31 - Queimaduras classificadas segundo a extensão da superfície corporal atingida"},
  { cod: "T32",	text: "T32 - Corrosões classificadas segundo a extensão da superfície corporal atingida"},
  { cod: "T34",	text: "T34 - Geladura com necrose de tecidos"},
  { cod: "T38",	text: "T38 - Intoxicação por hormônios, seus substitutos sintéticos e seus antagonistas não classificados em outra parte"},
  { cod: "T40",	text: "T40 - Intoxicação por narcóticos e psicodislépticos [alucinógenos]"},
  { cod: "T41",	text: "T41 - Intoxicação por anestésicos e gases terapêuticos"},
  { cod: "T42",	text: "T42 - Intoxicação por antiepilépticos, sedativos-hipnóticos e antiparkinsonianos"},
  { cod: "T43",	text: "T43 - Intoxicação por drogas psicotrópicas não classificadas em outra parte"},
  { cod: "T44",	text: "T44 - Intoxicação por drogas que afetam principalmente o sistema nervoso autônomo"},
  { cod: "T45",	text: "T45 - Intoxicação por substâncias de ação essencialmente sistêmica e substâncias hematológicas, não classificadas em outra parte"},
  { cod: "T46",	text: "T46 - Intoxicação por substâncias que atuam primariamente sobre o aparelho circulatório"},
  { cod: "T47",	text: "T47 - Intoxicação por substâncias que atuam primariamente sobre o aparelho gastrointestinal"},
  { cod: "T48",	text: "T48 - Intoxicação por substâncias que atuam primariamente sobre os músculos lisos e esqueléticos e sobre o aparelho respiratório"},
  { cod: "T50",	text: "T50 - Intoxicação por diuréticos e outras drogas, medicamentos e substâncias biológicas e as não especificadas"},
  { cod: "T51",	text: "T51 - Efeito tóxico do álcool"},
  { cod: "T52",	text: "T52 - Efeito tóxico de solventes orgânicos"},
  { cod: "T53",	text: "T53 - Efeito tóxico de derivados halogênicos de hidrocarbonetos alifáticos e aromáticos"},
  { cod: "T54",	text: "T54 - Efeito tóxico de corrosivos"},
  { cod: "T56",	text: "T56 - Efeito tóxico de metais"},
  { cod: "T58",	text: "T58 - Efeito tóxico de monóxido de carbono"},
  { cod: "T59",	text: "T59 - Efeito tóxico de outros gases, fumaças e vapores"},
  { cod: "T60",	text: "T60 - Efeito tóxico de pesticidas"},
  { cod: "T62",	text: "T62 - Efeito tóxico de outras substâncias nocivas ingeridas como alimento"},
  { cod: "T63",	text: "T63 - Efeito tóxico de contato com animais venenosos"},
  { cod: "T64",	text: "T64 - Efeito tóxico da aflatoxina e de outras micotoxinas contaminantes de alimentos"},
  { cod: "T65",	text: "T65 - Efeito tóxico de outras substâncias e as não especificadas"},
  { cod: "T67",	text: "T67 - Efeitos do calor e da luz"},
  { cod: "T68",	text: "T68 - Hipotermia"},
  { cod: "T69",	text: "T69 - Outros efeitos da temperatura reduzida"},
  { cod: "T70",	text: "T70 - Efeitos da pressão atmosférica e da pressão da água"},
  { cod: "T71",	text: "T71 - Asfixia"},
  { cod: "T73",	text: "T73 - Efeitos de outras privações"},
  { cod: "T74",	text: "T74 - Síndromes de maus tratos"},
  { cod: "T75",	text: "T75 - Efeitos de outras causas externas"},
  { cod: "T78",	text: "T78 - Efeitos adversos não classificados em outra parte"},
  { cod: "T79",	text: "T79 - Algumas complicações precoces dos traumatismos não classificadas em outra parte"},
  { cod: "T80",	text: "T80 - Complicações conseqüentes à infusão, transfusão ou injeção terapêutica"},
  { cod: "T81",	text: "T81 - Complicações de procedimentos não classificadas em outra parte"},
  { cod: "T82",	text: "T82 - Complicações de dispositivos protéticos, implantes e enxertos cardíacos e vasculares"},
  { cod: "T83",	text: "T83 - Complicações de dispositivos protéticos, implantes e enxertos geniturinários internos"},
  { cod: "T84",	text: "T84 - Complicações de dispositivos protéticos, implantes e enxertos ortopédicos internos"},
  { cod: "T85",	text: "T85 - Complicações de outros dispositivos protéticos, implantes e enxertos internos"},
  { cod: "T86",	text: "T86 - Falha e rejeição de órgãos e tecidos transplantados"},
  { cod: "T87",	text: "T87 - Complicações próprias de reimplante e amputação"},
  { cod: "T88",	text: "T88 - Outras complicações de cuidados médicos e cirúrgicos não classificadas em outra parte"},
  { cod: "T90",	text: "T90 - Seqüelas de traumatismo da cabeça"},
  { cod: "T91",	text: "T91 - Seqüelas de traumatismos do pescoço e do tronco"},
  { cod: "T92",	text: "T92 - Seqüelas de traumatismos do membro superior"},
  { cod: "T93",	text: "T93 - Seqüelas de traumatismos do membro inferior"},
  { cod: "T94",	text: "T94 - Seqüelas de traumatismos envolvendo múltiplas regiões do corpo e as não especificadas"},
  { cod: "T95",	text: "T95 - Seqüelas de queimaduras, corrosões e geladuras"},
  { cod: "T96",	text: "T96 - Seqüelas de intoxicação por drogas, medicamentos e substâncias biológicas"},
  { cod: "T97",	text: "T97 - Seqüelas de efeitos tóxicos de substâncias de origem predominantemente não-medicinal"},
  { cod: "T98",	text: "T98 - Seqüelas de outros efeitos de causas externas e dos não especificados"},
  { cod: "V02",	text: "V02 - Pedestre traumatizado em colisão com um veículo a motor de duas ou três rodas"},
  { cod: "V03",	text: "V03 - Pedestre traumatizado em colisão com um automóvel [carro], 'pick up' ou caminhonete"},
  { cod: "V04",	text: "V04 - Pedestre traumatizado em colisão com um veículo de transporte pesado ou com um ônibus"},
  { cod: "V05",	text: "V05 - Pedestre traumatizado em colisão com trem [comboio] ou um veículo ferroviário"},
  { cod: "V06",	text: "V06 - Pedestre traumatizado em colisão com outro veículo não-motorizado"},
  { cod: "V09",	text: "V09 - Pedestre traumatizado em outros acidentes de transporte e em acidentes de transporte não especificados"},
  { cod: "V11",	text: "V11 - Ciclista traumatizado em colisão com outro veículo a pedal"},
  { cod: "V12",	text: "V12 - Ciclista traumatizado em colisão com um veículo a motor de duas ou três rodas"},
  { cod: "V13",	text: "V13 - Ciclista traumatizado em colisão com um automóvel, 'pick up' ou caminhonete"},
  { cod: "V14",	text: "V14 - Ciclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus"},
  { cod: "V16",	text: "V16 - Ciclista traumatizado em colisão com outro veículo não-motorizado"},
  { cod: "V17",	text: "V17 - Ciclista traumatizado em colisão com um objeto fixo ou parado"},
  { cod: "V18",	text: "V18 - Ciclista traumatizado em um acidente de transporte sem colisão"},
  { cod: "V19",	text: "V19 - Ciclista traumatizado em outros acidentes de transporte e em acidentes de transporte não especificados"},
  { cod: "V20",	text: "V20 - Motociclista traumatizado em colisão com um pedestre ou um animal"},
  { cod: "V21",	text: "V21 - Motociclista traumatizado em colisão com um veículo a pedal"},
  { cod: "V22",	text: "V22 - Motociclista traumatizado em colisão com um veículo a motor de duas ou três rodas"},
  { cod: "V23",	text: "V23 - Motociclista traumatizado em colisão com um automóvel [carro], 'pick up' ou caminhonete"},
  { cod: "V24",	text: "V24 - Motociclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus"},
  { cod: "V25",	text: "V25 - Motociclista traumatizado em colisão com um trem ou um veículo ferroviário"},
  { cod: "V26",	text: "V26 - Motociclista traumatizado em colisão com outro veículo não-motorizado"},
  { cod: "V27",	text: "V27 - Motociclista traumatizado em colisão com um objeto fixo ou parado"},
  { cod: "V28",	text: "V28 - Motociclista traumatizado em um acidente de transporte sem colisão"},
  { cod: "V29",	text: "V29 - Motociclista traumatizado em outros acidentes de transporte e em acidentes de transporte não especificados"},
  { cod: "V30",	text: "V30 - Ocupante de um triciclo motorizado traumatizado em colisão com um pedestre ou um animal"},
  { cod: "V39",	text: "V39 - Ocupante de um triciclo motorizado traumatizado em outros acidentes de transporte e em acidentes de transporte não especificados"},
  { cod: "V40",	text: "V40 - Ocupante de um automóvel [carro] traumatizado em colisão com um pedestre ou um animal"},
  { cod: "V42",	text: "V42 - Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo a motor de duas ou três rodas"},
  { cod: "V43",	text: "V43 - Ocupante de um automóvel [carro] traumatizado em colisão com um automóvel [carro], 'pick up' ou caminhonete"},
  { cod: "V44",	text: "V44 - Ocupante de um automóvel [carro] traumatizado em colisão com um veículo de transporte pesado ou um ônibus"},
  { cod: "V46",	text: "V46 - Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo não-motorizado"},
  { cod: "V47",	text: "V47 - Ocupante de um automóvel [carro] traumatizado em colisão com um objeto fixo ou parado"},
  { cod: "V48",	text: "V48 - Ocupante de um automóvel [carro] traumatizado em um acidente de transporte sem colisão"},
  { cod: "V49",	text: "V49 - Ocupante de um automóvel [carro] traumatizado em outro acidentes de transporte e em acidentes de transporte não especificados"},
  { cod: "V51",	text: "V51 - Ocupante de uma caminhonete traumatizado em colisão com um veículo a pedal"},
  { cod: "V56",	text: "V56 - Ocupante de uma caminhonete traumatizado em colisão com outro veículo não-motorizado"},
  { cod: "V60",	text: "V60 - Ocupante de um veículo de transporte pesado traumatizado em colisão com um pedestre ou um animal"},
  { cod: "V64",	text: "V64 - Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo de transporte pesado ou um ônibus"},
  { cod: "V68",	text: "V68 - Ocupante de um veículo de transporte pesado traumatizado em um acidente de transporte sem colisão"},
  { cod: "V69",	text: "V69 - Ocupante de um veículo de transporte pesado traumatizado em outros acidentes de transporte não especificados"},
  { cod: "V73",	text: "V73 - Ocupante de um ônibus traumatizado em colisão com um automóvel [carro] ou uma caminhonete"},
  { cod: "V80",	text: "V80 - Pessoa montada em animal ou ocupante de um veículo a tração animal traumatizado em um acidente de transporte"},
  { cod: "V82",	text: "V82 - Ocupante de um bonde [carro elétrico] traumatizado em um acidente de transporte"},
  { cod: "V84",	text: "V84 - Ocupante de um veículo especial a motor de uso essencialmente agrícola traumatizado em um acidente de transporte"},
  { cod: "V85",	text: "V85 - Ocupante de um veículo a motor especial de construções traumatizado em um acidente de tansporte"},
  { cod: "V86",	text: "V86 - Ocupante de um veículo especial para qualquer terreno ou de outro veículo a motor projetado essencialmente para uso não em via pública, traumatizado em um acidente de transporte"},
  { cod: "V87",	text: "V87 - Acidente de trânsito de tipo especificado, mas sendo desconhecido o modo de transporte da vítima"},
  { cod: "V88",	text: "V88 - Acidente não-de-trânsito de tipo especificado, mas sendo desconhecido o modo de transporte da vítima"},
  { cod: "V89",	text: "V89 - Acidente com um veículo a motor ou não-motorizado, tipo(s) de veículo(s) não especificado(s)"},
  { cod: "V91",	text: "V91 - Acidente com embarcação causando outro tipo de traumatismo"},
  { cod: "V92",	text: "V92 - Afogamento e submersão relacionados com transporte por água sem acidente com a embarcação"},
  { cod: "V93",	text: "V93 - Acidente a bordo de uma embarcação, sem acidente da embarcação e não causando afogamento ou submersão"},
  { cod: "V98",	text: "V98 - Outros acidentes de transporte especificados"},
  { cod: "V99",	text: "V99 - Acidente de transporte não especificado"},
  { cod: "W01",	text: "W01 - Queda no mesmo nível por escorregão, tropeção ou passos em falsos [traspés]"},
  { cod: "W02",	text: "W02 - Queda envolvendo patins de rodas ou para gelo, esqui ou pranchas de rodas"},
  { cod: "W03",	text: "W03 - Outras quedas no mesmo nível por colisão com ou empurrão por outra pessoa"},
  { cod: "W06",	text: "W06 - Queda de um leito"},
  { cod: "W08",	text: "W08 - Queda de outro tipo de mobília"},
  { cod: "W10",	text: "W10 - Queda em ou de escadas ou degraus"},
  { cod: "W11",	text: "W11 - Queda em ou de escadas de mão"},
  { cod: "W12",	text: "W12 - Queda em ou de um andaime"},
  { cod: "W13",	text: "W13 - Queda de ou para fora de edifícios ou outras estruturas"},
  { cod: "W14",	text: "W14 - Queda de árvore"},
  { cod: "W15",	text: "W15 - Queda de penhasco"},
  { cod: "W17",	text: "W17 - Outras quedas de um nível a outro"},
  { cod: "W18",	text: "W18 - Outras quedas no mesmo nível"},
  { cod: "W19",	text: "W19 - Queda sem especificação"},
  { cod: "W20",	text: "W20 - Impacto causado por objeto lançado, projetado ou em queda"},
  { cod: "W21",	text: "W21 - Impacto acidental ativo ou passivo causado por equipamento esportivo"},
  { cod: "W22",	text: "W22 - Impacto acidental ativo ou passivo causado por outros objetos"},
  { cod: "W23",	text: "W23 - Apertado, colhido, comprimido ou esmagado dentro de ou entre objetos"},
  { cod: "W25",	text: "W25 - Contato com vidro cortante"},
  { cod: "W26",	text: "W26 - Contato com faca, espada e punhal"},
  { cod: "W27",	text: "W27 - Contato com ferramentas manuais sem motor"},
  { cod: "W29",	text: "W29 - Contato com outros utensílios manuais e aparelhos domésticos equipados com motor"},
  { cod: "W30",	text: "W30 - Contato com maquinaria agrícola"},
  { cod: "W31",	text: "W31 - Contato com outras máquinas e com as não especificadas"},
  { cod: "W32",	text: "W32 - Projétil de revólver"},
  { cod: "W33",	text: "W33 - Rifle, espingarda e armas de fogo de maior tamanho"},
  { cod: "W34",	text: "W34 - Projéteis de outras armas de fogo e das não especificadas"},
  { cod: "W37",	text: "W37 - Explosão ou ruptura de pneumático, tubulação ou mangueira, pressurizados"},
  { cod: "W38",	text: "W38 - Explosão ou ruptura de outros aparelhos pressurizados especificados"},
  { cod: "W39",	text: "W39 - Queima de fogos de artifício"},
  { cod: "W40",	text: "W40 - Explosão de outros materiais"},
  { cod: "W44",	text: "W44 - Penetração de corpo estranho no ou através de olho ou orifício natural"},
  { cod: "W45",	text: "W45 - Penetração de corpo ou objeto estranho através da pele"},
  { cod: "W49",	text: "W49 - Exposição a outras forças mecânicas inanimadas e às não especificadas"},
  { cod: "W50",	text: "W50 - Golpe, pancada, pontapé, mordedura ou escoriação infligidos por outra pessoa"},
  { cod: "W51",	text: "W51 - Colisão entre duas pessoas"},
  { cod: "W54",	text: "W54 - Mordedura ou golpe provocado por cão"},
  { cod: "W55",	text: "W55 - Mordedura ou golpe provocado por outros animais mamíferos"},
  { cod: "W56",	text: "W56 - Contato com animais marinhos"},
  { cod: "W57",	text: "W57 - Mordeduras e picadas de inseto e de outros artrópodes, não-venenosos"},
  { cod: "W58",	text: "W58 - Mordedura ou golpe provocado por crocodilo ou aligator"},
  { cod: "W59",	text: "W59 - Mordedura ou esmagamento provocado por outros répteis"},
  { cod: "W60",	text: "W60 - Contato com espinhos de plantas ou com folhas aguçadas"},
  { cod: "W64",	text: "W64 - Exposição a outras forças mecânicas animadas e às não especificadas"},
  { cod: "W66",	text: "W66 - Afogamento e submersão consecutiva a queda dentro de uma banheira"},
  { cod: "W68",	text: "W68 - Afogamento e submersão conseqüente a queda dentro de uma piscina"},
  { cod: "W69",	text: "W69 - Afogamento e submersão em águas naturais"},
  { cod: "W70",	text: "W70 - Afogamento e submersão conseqüentes a queda dentro de águas naturais"},
  { cod: "W74",	text: "W74 - Afogamento e submersão não especificados"},
  { cod: "W78",	text: "W78 - Inalação do conteúdo gástrico"},
  { cod: "W79",	text: "W79 - Inalação e ingestão de alimentos causando obstrução do trato respiratório"},
  { cod: "W80",	text: "W80 - Inalação e ingestão de outros objetos causando obstrução do trato respiratório"},
  { cod: "W81",	text: "W81 - Confinado ou aprisionado em um ambiente pobre em oxigênio"},
  { cod: "W85",	text: "W85 - Exposição a linhas de transmissão de corrente elétrica"},
  { cod: "W86",	text: "W86 - Exposição a outra corrente elétrica especificada"},
  { cod: "W87",	text: "W87 - Exposição a corrente elétrica não especificada"},
  { cod: "W88",	text: "W88 - Exposição a radiação ionizante"},
  { cod: "X02",	text: "X02 - Exposição a fogo controlado em um edifício ou outro tipo de construção"},
  { cod: "X03",	text: "X03 - Exposição a fogo controlado fora de um edifício ou de outro tipo de construção"},
  { cod: "X04",	text: "X04 - Exposição a combustão de substância muito inflamável"},
  { cod: "X08",	text: "X08 - Exposição a outro tipo especificado de fumaças, fogo ou chamas"},
  { cod: "X09",	text: "X09 - Exposição a tipo não especificado de fumaças, fogo ou chamas"},
  { cod: "X10",	text: "X10 - Contato com bebidas, alimentos, gordura e óleo de cozinha quentes"},
  { cod: "X12",	text: "X12 - Contato com outros líquidos quentes"},
  { cod: "X17",	text: "X17 - Contato com motores, máquinas e ferramentos quentes"},
  { cod: "X19",	text: "X19 - Contato com outras fontes de calor ou com substâncias quentes não especificados"},
  { cod: "X20",	text: "X20 - Contato com serpentes e lagartos venenosos"},
  { cod: "X21",	text: "X21 - Contato com aranhas venenosas"},
  { cod: "X22",	text: "X22 - Contato com escorpiões"},
  { cod: "X23",	text: "X23 - Contato com abelhas, vespas e vespões"},
  { cod: "X25",	text: "X25 - Contato com outros artrópodes venenosos especificados"},
  { cod: "X26",	text: "X26 - Contato com animais e plantas marinhos venenosos"},
  { cod: "X29",	text: "X29 - Contato com animais ou plantas venenosos, sem especificação"},
  { cod: "X33",	text: "X33 - Vítima de raio"},
  { cod: "X36",	text: "X36 - Vítima de avalanche, desabamento de terra e outros movimentos da superfície terrestre"},
  { cod: "X39",	text: "X39 - Exposição a outras forças da natureza e às não especificadas"},
  { cod: "X40",	text: "X40 - Envenenamento [intoxicação] acidental por e exposição a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos"},
  { cod: "X44",	text: "X44 - Envenenamento [intoxicação] acidental por e exposição a outras drogas, medicamentos e substâncias biológicas não especificadas"},
  { cod: "X47",	text: "X47 - Intoxicação acidental por e exposição a outros gases e vapores"},
  { cod: "X48",	text: "X48 - Envenenamento [intoxicação] acidental por e exposição a pesticidas"},
  { cod: "X49",	text: "X49 - Envenenamento [intoxicação] acidental por e exposição a outras substâncias químicas nocivas e às não especificadas"},
  { cod: "X50",	text: "X50 - Excesso de exercícios e movimentos vigorosos ou repetitivos"},
  { cod: "X58",	text: "X58 - Exposição a outros fatores especificados"},
  { cod: "X59",	text: "X59 - Exposição a fatores não especificados"},
  { cod: "X60",	text: "X60 - Auto-intoxicação por e exposição, intencional, a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos"},
  { cod: "X61",	text: "X61 - Auto-intoxicação por e exposição, intencional, a drogas anticonvulsivantes [antiepilépticos] sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte"},
  { cod: "X62",	text: "X62 - Auto-intoxicação por e exposição, intencional, a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte"},
  { cod: "X63",	text: "X63 - Auto-intoxicação por e exposição, intencional, a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo"},
  { cod: "X64",	text: "X64 - Auto-intoxicação por e exposição, intencional, a outras drogas, medicamentos e substâncias biológicas e às não especificadas"},
  { cod: "X65",	text: "X65 - Auto-intoxicação voluntária por álcool"},
  { cod: "X66",	text: "X66 - Auto-intoxicação intencional por solventes orgânicos, hidrocarbonetos halogenados e seus vapores"},
  { cod: "X67",	text: "X67 - Auto-intoxicação intencional por outros gases e vapores"},
  { cod: "X69",	text: "X69 - Auto-intoxicação por e exposição, intencional, a outros produtos químicos e substâncias nocivas não especificadas"},
  { cod: "X70",	text: "X70 - Lesão autoprovocada intencionalmente por enforcamento, estrangulamento e sufocação"},
  { cod: "X74",	text: "X74 - Lesão autoprovocada intencionalmente por disparo de outra arma de fogo e de arma de fogo não especificada"},
  { cod: "X76",	text: "X76 - Lesão autoprovocada intencionalmente pela fumaça, pelo fogo e por chamas"},
  { cod: "X78",	text: "X78 - Lesão autoprovocada intencionalmente por objeto cortante ou penetrante"},
  { cod: "X79",	text: "X79 - Lesão autoprovocada intencionalmente por objeto contundente"},
  { cod: "X80",	text: "X80 - Lesão autoprovocada intencionalmente por precipitação de um lugar elevado"},
  { cod: "X81",	text: "X81 - Lesão autoprovocada intencionalmente por precipitação ou permanência diante de um objeto em movimento"},
  { cod: "X82",	text: "X82 - Lesão autoprovocada intencionalmente por impacto de um veículo a motor"},
  { cod: "X83",	text: "X83 - Lesão autoprovocada intencionalmente por outros meios especificados"},
  { cod: "X84",	text: "X84 - Lesão autoprovocada intencionalmente por meios não especificados"},
  { cod: "X90",	text: "X90 - Agressão por meio de produtos químicos e substâncias nocivas não especificados"},
  { cod: "X91",	text: "X91 - Agressão por meio de enforcamento, estrangulamento e sufocação"},
  { cod: "X93",	text: "X93 - Agressão por meio de disparo de arma de fogo de mão"},
  { cod: "X94",	text: "X94 - Agressão por meio de disparo de espingarda, carabina ou arma de fogo de maior calibre"},
  { cod: "X95",	text: "X95 - Agressão por meio de disparo de outra arma de fogo ou de arma não especificada"},
  { cod: "X96",	text: "X96 - Agressão por meio de material explosivo"},
  { cod: "X97",	text: "X97 - Agressão por meio de fumaça, fogo e chamas"},
  { cod: "X98",	text: "X98 - Agressão por meio de vapor de água, gases ou objetos quentes"},
  { cod: "X99",	text: "X99 - Agressão por meio de objeto cortante ou penetrante"},
  { cod: "Y00",	text: "Y00 - Agressão por meio de um objeto contundente"},
  { cod: "Y03",	text: "Y03 - Agressão por meio de impacto de um veículo a motor"},
  { cod: "Y04",	text: "Y04 - Agressão por meio de força corporal"},
  { cod: "Y05",	text: "Y05 - Agressão sexual por meio de força física"},
  { cod: "Y07",	text: "Y07 - Outras síndromes de maus tratos"},
  { cod: "Y08",	text: "Y08 - Agressão por outros meios especificados"},
  { cod: "Y09",	text: "Y09 - Agressão por meios não especificados"},
  { cod: "Y14",	text: "Y14 - Envenenamento [intoxicação] por e exposição a outras drogas, medicamentos e substâncias biológicas e as não especificadas, intenção não determinada"},
  { cod: "Y17",	text: "Y17 - Envenenamento [intoxicação] por e exposição a outros gases e vapores, intenção não determinada"},
  { cod: "Y19",	text: "Y19 - Envenenamento [intoxicação] por e exposição a outros produtos químicos e substâncias nocivas e aos não especificados, intenção não determinada"},
  { cod: "Y20",	text: "Y20 - Enforcamento, estrangulamento e sufocação, intenção não determinada"},
  { cod: "Y22",	text: "Y22 - Disparo de pistola, intenção não determinada"},
  { cod: "Y23",	text: "Y23 - Disparo de fuzil, carabina e arma de fogo de maior calibre, intenção não determinada"},
  { cod: "Y24",	text: "Y24 - Disparo de outra arma de fogo e de arma de fogo não especificada, intenção não determinada"},
  { cod: "Y26",	text: "Y26 - Exposição a fumaça, fogo e chamas, intenção não determinada"},
  { cod: "Y28",	text: "Y28 - Contato com objeto cortante ou penetrante, intenção não determinada"},
  { cod: "Y29",	text: "Y29 - Contato com objeto contundente, intenção não determinada"},
  { cod: "Y30",	text: "Y30 - Queda, salto ou empurrado de um lugar elevado, intenção não determinada"},
  { cod: "Y31",	text: "Y31 - Queda, permanência ou corrida diante de um objeto em movimento, intenção não determinada"},
  { cod: "Y32",	text: "Y32 - Impacto de um veículo a motor, intenção não determinada"},
  { cod: "Y42",	text: "Y42 - Hormônios e seus substitutos sintéticos e antagonistas, não classificados em outra parte"},
  { cod: "Y43",	text: "Y43 - Substâncias de ação primariamente sistêmica"},
  { cod: "Y44",	text: "Y44 - Substâncias farmacológicas que atuam primariamente sobre os constituintes do sangue"},
  { cod: "Y46",	text: "Y46 - Drogas anticonvulsivantes (anti-epilépticas) e antiparkinsonianas"},
  { cod: "Y47",	text: "Y47 - Sedativos, hipnóticos e tranquilizantes [ansiolíticos]"},
  { cod: "Y49",	text: "Y49 - Substâncias psicotrópicas, não classificadas em outra parte"},
  { cod: "Y51",	text: "Y51 - Drogas que atuam primariamente sobre o sistema nervoso autônomo"},
  { cod: "Y53",	text: "Y53 - Substâncias que atuam primariamente sobre o aparelho gastrointestinal"},
  { cod: "Y57",	text: "Y57 - Outras drogas e medicamentos e as não especificadas"},
  { cod: "Y59",	text: "Y59 - Outras vacinas e substâncias biológicas e as não especificadas"},
  { cod: "Y60",	text: "Y60 - Corte, punção, perfuração ou hemorragia acidentais durante a prestação de cuidados médicos ou cirúrgicos"},
  { cod: "Y61",	text: "Y61 - Objeto estranho deixado acidentalmente no corpo durante a prestação de cuidados cirúrgicos e médicos"},
  { cod: "Y62",	text: "Y62 - Assepsia insuficiente durante a prestação de cuidados cirúrgicos e médicos"},
  { cod: "Y65",	text: "Y65 - Outros acidentes durante a prestação de cuidados médicos e cirúrgicos"},
  { cod: "Y71",	text: "Y71 - Dispositivos (aparelhos) cardiovasculares, associados a incidentes adversos"},
  { cod: "Y73",	text: "Y73 - Dispositivos (aparelhos) usados em gastroenterologia e em urologia, associados a incidentes adversos"},
  { cod: "Y74",	text: "Y74 - Dispositivos (aparelhos) gerais de uso hospitalar ou pessoal, associados a incidentes adversos"},
  { cod: "Y77",	text: "Y77 - Dispositivos (aparelhos) utilizados em oftalmologia, associados a incidentes adversos"},
  { cod: "Y79",	text: "Y79 - Dispositivos (aparelhos) ortopédicos, associado a incidentes adversos"},
  { cod: "Y81",	text: "Y81 - Dispositivos (aparelhos) utilizados em cirurgia geral ou cirurgia plástica, associados a incidente adversos"},
  { cod: "Y83",	text: "Y83 - Reação anormal em paciente ou complicação tardia, causadas por intervenção cirúrgica e por outros atos cirúrgicos, sem menção de acidente durante a intervenção"},
  { cod: "Y84",	text: "Y84 - Reação anormal em paciente ou complicação tardia, causadas por outros procedimentos médicos, sem menção de acidente durante o procedimento"},
  { cod: "Y85",	text: "Y85 - Seqüelas de acidentes de transporte"},
  { cod: "Y86",	text: "Y86 - Seqüelas de outros acidentes"},
  { cod: "Y87",	text: "Y87 - Seqüelas de uma lesão autoprovocada intencionalmente, de agressão ou de um fato cuja intenção é indeterminada"},
  { cod: "Y88",	text: "Y88 - Seqüelas de cuidado médico ou cirúrgico considerados como uma causa externa"},
  { cod: "Y89",	text: "Y89 - Seqüelas de outras causas externas"},
  { cod: "Y90",	text: "Y90 - Evidência de alcoolismo determinada por taxas de alcoolemia"},
  { cod: "Y91",	text: "Y91 - Evidência de alcoolismo determinada pelo nível da intoxicação"},
  { cod: "Y96",	text: "Y96 - Circunstância relativa às condições de trabalho"},
  { cod: "Y98",	text: "Y98 - Circunstâncias relativas a condições do modo de vida"},
  { cod: "Z00",	text: "Z00 - Exame geral e investigação de pessoas sem queixas ou diagnóstico relatado"},
  { cod: "Z01",	text: "Z01 - Outros exames e investigações especiais de pessoas sem queixa ou diagnóstico relatado"},
  { cod: "Z02",	text: "Z02 - Exame médico e consulta com finalidades administrativas"},
  { cod: "Z03",	text: "Z03 - Observação e avaliação médica por doenças e afecções suspeitas"},
  { cod: "Z04",	text: "Z04 - Exame e observação por outras razões"},
  { cod: "Z08",	text: "Z08 - Exame de seguimento após tratamento por neoplasia maligna"},
  { cod: "Z09",	text: "Z09 - Exame de seguimento após tratamento de outras afecções que não neoplasias malignas"},
  { cod: "Z10",	text: "Z10 - Exame geral de rotina ('check up') de uma subpopulação definida"},
  { cod: "Z12",	text: "Z12 - Exame especial de rastreamento ('screening') de neoplasias"},
  { cod: "Z13",	text: "Z13 - Exame especial de rastreamento ('screening') de outros transtornos e doenças"},
  { cod: "Z20",	text: "Z20 - Contato com e exposição a doenças transmissíveis"},
  { cod: "Z21",	text: "Z21 - Estado de infecção assintomática pelo vírus da imunodeficiência humana [HIV]"},
  { cod: "Z22",	text: "Z22 - Portador de doença infecciosa"},
  { cod: "Z29",	text: "Z29 - Necessidade de outras medidas profiláticas"},
  { cod: "Z30",	text: "Z30 - Anticoncepção"},
  { cod: "Z31",	text: "Z31 - Medidas de procriação"},
  { cod: "Z32",	text: "Z32 - Exame ou teste de gravidez"},
  { cod: "Z33",	text: "Z33 - Gravidez como achado casual"},
  { cod: "Z34",	text: "Z34 - Supervisão de gravidez normal"},
  { cod: "Z35",	text: "Z35 - Supervisão de gravidez de alto risco"},
  { cod: "Z36",	text: "Z36 - Rastreamento ('screening') pré-natal"},
  { cod: "Z37",	text: "Z37 - Resultado do parto"},
  { cod: "Z38",	text: "Z38 - Nascidos vivos [nado-vivos] segundo o local de nascimento"},
  { cod: "Z39",	text: "Z39 - Assistência e exame pós-natal"},
  { cod: "Z40",	text: "Z40 - Cirurgia profilática"},
  { cod: "Z41",	text: "Z41 - Procedimentos para outros propósitos exceto cuidados de saúde"},
  { cod: "Z42",	text: "Z42 - Seguimento envolvendo cirurgia plástica"},
  { cod: "Z43",	text: "Z43 - Atenção a orifícios artificiais"},
  { cod: "Z44",	text: "Z44 - Colocação e ajustamento de aparelhos de prótese externa"},
  { cod: "Z45",	text: "Z45 - Ajustamento e manuseio de dispositivo implantado"},
  { cod: "Z46",	text: "Z46 - Colocação e ajustamento de outros aparelhos"},
  { cod: "Z47",	text: "Z47 - Outros cuidados de seguimento ortopédico"},
  { cod: "Z48",	text: "Z48 - Outro seguimento cirúrgico"},
  { cod: "Z49",	text: "Z49 - Cuidados envolvendo diálise"},
  { cod: "Z50",	text: "Z50 - Cuidados envolvendo o uso de procedimentos de reabilitação"},
  { cod: "Z51",	text: "Z51 - Outros cuidados médicos"},
  { cod: "Z52",	text: "Z52 - Doadores de órgãos e tecidos"},
  { cod: "Z53",	text: "Z53 - Pessoas em contato com serviços de saúde para procedimentos específicos não realizados"},
  { cod: "Z54",	text: "Z54 - Convalescença"},
  { cod: "Z55",	text: "Z55 - Problemas relacionados com a educação e com a alfabetização"},
  { cod: "Z56",	text: "Z56 - Problemas relacionados com o emprego e com o desemprego"},
  { cod: "Z57",	text: "Z57 - Exposição ocupacional a fatores de risco"},
  { cod: "Z58",	text: "Z58 - Problemas relacionados com o ambiente físico"},
  { cod: "Z59",	text: "Z59 - Problemas relacionados com a habitação e com as condições econômicas"},
  { cod: "Z60",	text: "Z60 - Problemas relacionados com o meio social"},
  { cod: "Z61",	text: "Z61 - Problemas relacionados com eventos negativos de vida na infância"},
  { cod: "Z62",	text: "Z62 - Outros problemas relacionados com a educação da criança"},
  { cod: "Z63",	text: "Z63 - Outros problemas relacionados com o grupo primário de apoio, inclusive com a situação familiar"},
  { cod: "Z64",	text: "Z64 - Problemas relacionados com algumas outras circuntâncias psicossociais"},
  { cod: "Z65",	text: "Z65 - Problemas relacionados com outras circunstâncias psicossociais"},
  { cod: "Z72",	text: "Z72 - Problemas relacionados com o estilo de vida"},
  { cod: "Z73",	text: "Z73 - Problemas relacionados com a organização de seu modo de vida"},
  { cod: "Z74",	text: "Z74 - Problemas relacionados com a dependência de uma pessoa que oferece cuidados de saúde"},
  { cod: "Z75",	text: "Z75 - Problemas relacionados com as facilidades médicas e outros cuidados de saúde"},
  { cod: "Z76",	text: "Z76 - Pessoas em contato com os serviços de saúde em outras circunstâncias"},
  { cod: "Z80",	text: "Z80 - História familiar de neoplasia maligna"},
  { cod: "Z81",	text: "Z81 - História familiar de transtornos mentais e comportamentais"},
  { cod: "Z82",	text: "Z82 - História familiar de algumas incapacidades e doenças crônicas que conduzem a incapacitação"},
  { cod: "Z83",	text: "Z83 - História familiar de outros transtornos específicos"},
  { cod: "Z84",	text: "Z84 - História familiar de outras afecções"},
  { cod: "Z85",	text: "Z85 - História pessoal de neoplasia maligna"},
  { cod: "Z86",	text: "Z86 - História pessoal de algumas outras doenças"},
  { cod: "Z87",	text: "Z87 - História pessoal de outras doenças e afecções"},
  { cod: "Z88",	text: "Z88 - História pessoal de alergia a drogas, medicamentos e a substâncias biológicas"},
  { cod: "Z89",	text: "Z89 - Ausência adquirida de membros"},
  { cod: "Z90",	text: "Z90 - Ausência adquirida de órgãos não classificados em outra parte"},
  { cod: "Z91",	text: "Z91 - História pessoal de fatores de risco, não classificados em outra parte"},
  { cod: "Z92",	text: "Z92 - História pessoal de tratamento médico"},
  { cod: "Z93",	text: "Z93 - Orifícios artificiais"},
  { cod: "Z94",	text: "Z94 - Órgãos e tecidos transplantados"},
  { cod: "Z95",	text: "Z95 - Presença de implantes e enxertos cardíacos e vasculares"},
  { cod: "Z96",	text: "Z96 - Presença de outros implantes funcionais"},
  { cod: "Z97",	text: "Z97 - Presença de outros dispositivos protéticos"},
  { cod: "Z98",	text: "Z98 - Outros estados pós-cirúrgicos"},
  { cod: "Z99",	text: "Z99 - Dependência de máquinas e dispositivos capacitantes, não classificados em outra parte"},
]
import Breadcrumb from "../../../components/breadcrumb";
import BaseDocente from "../../docente/baseDocente";
import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import Spinner from '../../../components/spinner';
import { useEffect, useState } from "react";
import api from "../../../services";
import { store } from "../../../store";
import FichaAvaliacaoTable from './table';
import {listarLancamentosTurma} from '../../../services/ficha-avaliacao';
import Select, { AriaOnFocus } from 'react-select';
import { quadroHorarioServidorEscolaridade, quadroHorarioServidorTurno, quadroHorarioServidorTurma, quadroHorarioAulas, quadroHorarioServidorUnidades } from '../../../services/quadro-horario';
import { useMutation, useQuery } from 'react-query';
import { getDisciplinasByTurmaServidor } from '../../../services/disciplinas';
import urlsDocente from "../../docente/urlsDocente";
import urlsGestaoUnidades from "../../gestao-unidades/urlsGestaoUnidades";
import ImpressaoFichaAvaliacao from "../impressao";
import React, { useRef } from 'react';

const VisualizacaoFichaAvaliacaoPage = (gestao) => {
  // const unidadeID = localStorage.getItem('unidadeID');
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const currentUser = store.getState()['user']['user']
  const [unidade, setUnidade] = useState(localStorage.getItem('unidadeID'));
  const [escolaridade, setEscolaridade] = useState(0);
  const [turno, setTurno] = useState(0);
  const [turma, setTurma] = useState(0);
  const [disciplina, setDisciplina] = useState(0);
  const [disciplinas, setDisciplinas] = useState([]);
  const [turnos, setTurnos] = useState([]);
  const [turmas, setTurmas] = useState([]);
  const onMenuOpen = () => setIsOpen(true);
  const onMenuClose = () => setIsOpen(false);
  const [unidades, setUnidades] = useState([]);
  const [escolaridades, setEscolaridades] = useState([]);
  const printRef = useRef();
  // const unidadeID = await localStorage.getItem('unidadeID');


  let BaseComponent;
  let BasePath;
  let adicionarUrl;
  let servidorId;
  let relatorio;
  

  if (!gestao.gestao && !gestao.docente) {
    BaseComponent = BaseGestaoUnidades;
    BasePath = 'Gestão Unidades'
    adicionarUrl = urlsGestaoUnidades.fichaAvaliacaoLancamento;
    relatorio = urlsGestaoUnidades.fichaAvaliacaoImpressao;
    servidorId = 0;
  }

  // Segunda condição para substituir BaseComponent por BaseDocente, se docente.docente for verdadeiro
  if (gestao.docente) {
    BaseComponent = BaseDocente;
    BasePath = 'Docente'
    adicionarUrl = urlsDocente.fichaAvaliacaoLancamento;
    relatorio = urlsDocente.fichaAvaliacaoImpressao;
    servidorId = currentUser?.servidorId;
  }

  useEffect(() => {
    // Remove all items from localStorage
    localStorage.setItem('impressaoFichaAvaliacao', JSON.stringify([]));
  }, []);

  const { isLoading: loadingUnidades, refetch: refetchUnidades } = useQuery('getUnidades', () => quadroHorarioServidorUnidades(servidorId), {
    enabled: true,
    retry: true,
    onSuccess: (data) => {
      var dados = [{value: 0, label: ''}];
      data.forEach(item => {
        if (!dados.some(d => d.value === item.id)) {
          dados.push({ value: item.id, label: item.nome });
        }
      });
      

      setUnidades(dados);
    }
  })

  const { refetch: refetch } = useQuery('listarLancamentosTurma', () => (turma !== null && disciplina != null ? listarLancamentosTurma(turma, disciplina) : null),
        {
            retry: 3,
            enabled: turma != 0 && disciplina != 0,
            onSuccess: (data) => {
              if (data === undefined){
                setData([]);
                return;
              }
              setData(data.data);
              localStorage.setItem('impressaoFichaAvaliacao', JSON.stringify(data.data));
              
            },
            onError: (error) => {
              debugger
              console.error('An error occurred:', error);
              setData([]); // Set data to empty array in case of an error
            },
        }
    )

  const { refetch: refetchEscolaridades } = useQuery('getEscolaridades', () => (unidade !== null ? quadroHorarioServidorEscolaridade(servidorId, unidade) : null),
        {
            retry: 3,
            enabled: unidade !== null,
            onSuccess: (data) => {
              var dados = [{value: 0, label: ''}];
              data.forEach(item => {
                if (!dados.some(d => d.value === item.id)) {
                  dados.push({ value: item.id, label: item.descricao });
                }
              });
              
        
              setEscolaridades(dados);
            }
        }
    )

  const { isLoading: loadingDisciplinas, refetch: refetchDisciplina } = useQuery('getDisciplinasByTurmaServidor', () =>
  turma !== 0 ? getDisciplinasByTurmaServidor(turma, servidorId) : null,
  {
      retry: 3,
      enabled: turma !== 0,
      onSuccess: (data) => {
        var dados = [{value: 0, label: ''}];
        data.forEach(item => {
          if (!dados.some(d => d.value === item.id)) {
            dados.push({ value: item.id, label: item.descricao });
          }
        });
        
  
        setDisciplinas(dados);
      }
  }
)

  const { isLoading: loadingTurmas, refetch: refetchTurmas } = useQuery('getTurmasUnidadeEscolaridadeTurno', () =>
  unidade !== '' && escolaridade !== '' && turno !== '' ? quadroHorarioServidorTurma(servidorId, unidade, escolaridade, turno) : null,
  {
      retry: 3,
      enabled: unidade !== '' && escolaridade !== '' && turno !== '',
      onSuccess: (data) => {
        var dados = [{value: 0, label: ''}];
        data.forEach(item => {
          if (!dados.some(d => d.value === item.id)) {
            dados.push({ value: item.id, label: item.descricao });
          }
        });
        
  
        setTurmas(dados);
      }
  }
)

const { isLoading: loadingTurnos, refetch: refetchTurnos } = useQuery('getTurnos', () => quadroHorarioServidorTurno(servidorId, unidade, escolaridade),
  {
      enabled: unidade !== 0 && escolaridade !== 0,
      retry: 3,
      onSuccess: (data) => {
        var dados = [];
        data.forEach(item => {
          if (!dados.some(d => d.value === item.id)) {
            dados.push({ value: item.id, label: item.descricao });
          }
        });
        
  
        setTurnos(dados);
      }
  }
)

  
  const clickImprimirFichaAvaliacao = async () => {
    debugger
    if (printRef.current) {
        printRef.current.print();
    }
};

  return (
    <BaseComponent>
    <Breadcrumb
        title={'Ficha de Avaliação'}
        itens={[BasePath, 'Visualização']}
        imprimir={{ link: relatorio, text: "Imprimir" }}
        onClickImprimir={clickImprimirFichaAvaliacao}
        actions={{
          link: adicionarUrl,
          text: 'Lançar Notas/Faltas'
        }}
      />
     
      {
        isLoading ? (
          <Spinner />
        ) : (
          <>
          <div className='row'>
          {/* <button onClick={clickImprimirFichaAvaliacao}>Imprimir Ficha de Avaliação</button> */}
     
                                    {/* <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="unidadeId" className="form-label">
              Unidade
            </label>
            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={unidades}
              // isDisabled={gestao.gestao ? false : true}
              value={unidades.find(x => x.value == unidade)}
              placeholder=''             
              onChange={async e => {
                setUnidade(e.value);
                await setEscolaridade(0)
                await setTurno(0)
                await setTurma(0)
                // refetch()
                refetchEscolaridades()
              }
              }
            />
          </div>
        </div> */}

        <div className="col-12 col-md-4">
          <div className="form-group">
            <label htmlFor="escolaridade" className="form-label">
              Escolaridade
            </label>
            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={escolaridades}
              // isDisabled={gestao.gestao ? false : true}
              // value={unidades.find(x => x.value == unidade)}
              placeholder=''             
              onChange={async e => {
                await setEscolaridade(e.value)
                await setTurno(0)
                await setTurma(0);
                await setDisciplina(0);
                await refetchTurnos();
                await setData([]);
                await localStorage.setItem('impressaoFichaAvaliacaoEscolaridade', e.label);
                 // refetchBncc();
                 await setData([]);
              }
              }
            />
          </div>
        </div>

        <div className="col-12 col-md-4">
          <div className="form-group">
            <label htmlFor="turno" className="form-label">
              Turno
            </label>
            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={turnos}
              // isDisabled={gestao.gestao ? false : true}
              // value={turno}
              placeholder=''             
              onChange={async e => {
                await setTurno(e.value)
                                                        await setTurma(0);
                                                        refetchTurmas();
                                                        await setDisciplina(0);
                                                        await setData([]);
              }
              }
            />
          </div>
        </div>

        <div className="col-12 col-md-4">
          <div className="form-group">
            <label htmlFor="turma" className="form-label">
              Turmas
            </label>
            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={turmas}
              // isDisabled={gestao.gestao ? false : true}
              // value={unidades.find(x => x.value == unidade)}
              placeholder=''             
              onChange={async e => {
                await setTurma(e.value);
                await localStorage.setItem('impressaoFichaAvaliacaoTurma', e.label);
                refetchDisciplina();
                await setDisciplina(0);
                await setData([]);
                
              }
              }
            />
          </div>
        </div>

        <div className='row' style={{marginTop: 20, marginBottom: 30}}>
          
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="turma" className="form-label">
              Componente Curricular
            </label>
            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={disciplinas}
              // isDisabled={gestao.gestao ? false : true}
              // value={unidades.find(x => x.value == unidade)}
              placeholder=''             
              onChange={async e => {
                await setDisciplina(e.value);
                await localStorage.setItem('impressaoFichaAvaliacaoComponenteCurricular', e.label);
                refetch()
                                                       
              }
              }
            />
          </div>
        </div>
        </div>
        </div>
       
        <div className="row">
  <div className="col-12 col-md-12">
    {data !== undefined && (
      <div className="form-group">
         <ImpressaoFichaAvaliacao ref={printRef} />
      </div>
    )}
  </div>
</div>

          </>
        )}
    </BaseComponent>
  )
};

export default VisualizacaoFichaAvaliacaoPage;
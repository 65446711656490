import api from "..";

async function postNotas(data) {
  return await api
    .post('fichaAvaliacao', data)
    .then(async (res) => {
      const { data } = res;
      return res.data;
    });
}

async function postNotaAluno(data) {
  return await api
    .post('fichaAvaliacao/aluno', data)
    .then(async (res) => {
      const { data } = res;
      return res.data;
    });
}

async function postNotaLinha(data) {
  return await api
    .post('fichaAvaliacao/notas', data)
    .then(async (res) => {
      const { data } = res;
      return res.data;
    });
}

async function postNotaLinhaRiapFundamental(data) {
  return await api
    .post('fichaAvaliacao/notas-riap-fundamental', data)
    .then(async (res) => {
      const { data } = res;
      return res.data;
    });
}

async function postNotaLinhaRiap(data) {
  return await api
    .post('fichaAvaliacao/notas-riap', data)
    .then(async (res) => {
      const { data } = res;
      return res.data;
    });
}

async function postQuantidadeAula(data) {
  return await api
    .post('fichaAvaliacao/aulasDadas', data)
    .then(async (res) => {
      const { data } = res;
      return res.data;
    });
}

async function postQuantidadeAulaRiap(data) {
  return await api
    .post('fichaAvaliacao/notas-riap/aulas', data)
    .then(async (res) => {
      const { data } = res;
      return res.data;
    });
}

async function imprimirRelatorioRiap(alunoId) {

  let config = {};


    config = {
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'blob' }
    };

  return await api
    .get(`fichaAvaliacao/riap/relatorio/${alunoId}`, config)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function imprimirFichaIndividual(alunoId) {

  let config = {};


    config = {
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'blob' }
    };

  return await api
    .get(`fichaAvaliacao/ficha-individual/${alunoId}`, config)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function imprimirFichaIndividualResponsavel(alunoId) {

  let config = {};


    config = {
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'blob', 'X-Api-Key' : '564884cb-09fa-4280-9c3d-87b9ec5d16cc' }
    };

  return await api
    .get(`fichaAvaliacao/ficha-individual/${alunoId}`, config)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function imprimirRelatorioRiapInfantil(alunoId) {

  let config = {};


    config = {
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'blob' }
    };

  return await api
    .get(`fichaAvaliacao/riap/infantil/relatorio/${alunoId}`, config)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function imprimirRelatorioRiapFundamental(alunoId) {

  let config = {};


    config = {
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'blob' }
    };

  return await api
    .get(`fichaAvaliacao/riap/fundamental/relatorio/${alunoId}`, config)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function listarLancamentosTurma(turmaId, disciplinaId) {
  return await api
    .get(`fichaAvaliacao?turmaId=${turmaId}&disciplinaId=${disciplinaId}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function listarLancamentosTurmaRiap(turmaId, disciplinaId) {
  return await api
    .get(`fichaAvaliacao/riap-fundamental?turmaId=${turmaId}&disciplinaId=${disciplinaId}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function listarLancamentosTurmaInfantil(turmaId) {
  return await api
    .get(`fichaAvaliacao/infantil?turmaId=${turmaId}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function listarLancamentosTurmaPrimeiroAno(turmaId) {
  return await api
    .get(`fichaAvaliacao/primeiroano?turmaId=${turmaId}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function listarLancamentosRiapAlunoId(alunoId) {
  return await api
    .get(`fichaAvaliacao/riap/lancamentos/${alunoId}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function listarAulasRiapAlunoId(alunoId) {
  return await api
    .get(`fichaAvaliacao/riap/dados/${alunoId}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};



async function listarAlunos(turmaId, trimestreId, disciplinaId) {
  return await api
    .get(`fichaAvaliacao/alunos?turmaId=${turmaId}&trimestreId=${trimestreId}&disciplinaId=${disciplinaId}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function listarAlunosRiap(turmaId, trimestreId, disciplinaId) {
  return await api
    .get(`fichaAvaliacao/alunos-riap?turmaId=${turmaId}&trimestreId=${trimestreId}&disciplinaId=${disciplinaId}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function listarAulas(turmaId, trimestreId, disciplinaId) {
  return await api
    .get(`fichaAvaliacao/aulasDadas?turmaId=${turmaId}&trimestreId=${trimestreId}&disciplinaId=${disciplinaId}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

export {
    postNotas,
    listarLancamentosTurma,
    listarAlunos,
    postNotaAluno,
    postQuantidadeAula,
    listarAulas,
    listarLancamentosTurmaInfantil,
    listarLancamentosTurmaPrimeiroAno,
    postNotaLinha,
    postNotaLinhaRiap,
    postQuantidadeAulaRiap,
    imprimirRelatorioRiap,
    listarLancamentosRiapAlunoId,
    listarAulasRiapAlunoId,
    imprimirRelatorioRiapInfantil,
    postNotaLinhaRiapFundamental,
    listarAlunosRiap,
    imprimirFichaIndividual,
    imprimirRelatorioRiapFundamental,
    listarLancamentosTurmaRiap,
    imprimirFichaIndividualResponsavel
}
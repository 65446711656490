import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Estilos customizados para um layout mais moderno e minimalista
const useStyles = makeStyles(() => ({
  dialogTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    color: '#333',
    marginBottom: '1rem',
  },
  dialogContent: {
    textAlign: 'center',
    fontSize: '1.1rem',
    color: '#666',
    padding: '20px',
  },
  dialogActions: {
    justifyContent: 'center',
    padding: '16px',
  },
  closeButton: {
    backgroundColor: '#1976d2',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1565c0',
    },
    padding: '8px 24px',
    fontSize: '1rem',
    textTransform: 'none',
    borderRadius: '20px',
  },
}));

const DialogChamado = ({isOpen, setOpenModal, titulo, setTitulo, descricao, setDescricao, abrirChamado}) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} PaperProps={{ style: { borderRadius: '12px', padding: '10px' } }}>
      <DialogTitle className={classes.dialogTitle}>
          Abertura de chamado
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography variant="body1">
            Preencha os campos abaixo para abertura de chamado.
          </Typography>
          <div className='row'>
            <div className='col-12'>
              <input
              id='titulo'
                type="text"
                className="form-control"
                placeholder="Título"
                value={titulo}
                onChange={(e) =>  setTitulo(e.target.value)}
              />
            </div>
          </div>
          <div className='row' style={{marginTop:10}}>
            <div className='col-12'>
              <textarea id='titulo'
                type="text"
                className="form-control"
                placeholder="Descrição"
                value={descricao}
                onChange={(e) =>  setDescricao(e.target.value)}
                rows={4}>
              </textarea>
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleClose} className={classes.closeButton} >
            Fechar
          </Button>
          <Button onClick={abrirChamado} className={classes.closeButton}>
            Abrir chamado
          </Button>
        </DialogActions>
    </Dialog>
  );
};

export default DialogChamado;

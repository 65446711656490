import { useMemo, useEffect, useState } from 'react'
import Breadcrumb from "../../../components/breadcrumb";
import Table from "../../../components/table";
import BaseConfiguracoes from '../../configuracoes/baseConfiguracoes';
import urlsConfiguracoes from '../../configuracoes/urlsConfiguracoes';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from 'react-query';
import { getAlunosManuais } from '../../../services/alunos';
import { useNavigate } from "react-router-dom";
import Spinner from '../../../components/spinner';
import urlsGestaoUnidades from "../../gestao-unidades/urlsGestaoUnidades";
import BaseChamadaPublica from '../../processos/chamadaPublica/baseChamadaPublica';

const AlunosManuaisPage = (configuracao) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [unidadeId, setUnidadeId] = useState(
    JSON.parse(localStorage.getItem("unidadeID"))
  );
  const [skip, setSkip] = useState(sessionStorage.getItem('manuais') ? JSON.parse(sessionStorage.getItem('manuais')).skip : 0);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(sessionStorage.getItem('manuais') ? JSON.parse(sessionStorage.getItem('manuais')).limit : 20);
  const [ativo, setAtivo] = useState(true);
  const [descricao, setDescricao] = useState('');
  const [warningControl, setWarningControl] = useState(false);
  const [pesquisarAluno, setPesquisaAluno] = useState(sessionStorage.getItem('manuais') ? JSON.parse(sessionStorage.getItem('manuais')).aluno : "");
  const [pesquisaCPF, setpesquisaCPF] = useState(sessionStorage.getItem('manuais') ? JSON.parse(sessionStorage.getItem('manuais')).cpf : "");

  const BaseComponent = configuracao.configuracao ? BaseConfiguracoes : BaseChamadaPublica;
  const BaseTitle = configuracao.configuracao ? 'Configurações' : 'Chamada Pública';
  const { isLoading: loading, refetch } = useQuery('getAlunosManuais', () => getAlunosManuais(pesquisarAluno, pesquisaCPF, unidadeId, skip, limit, 0), {
    onSuccess: (data) => {
      setData(data?.data);
      setTotal(data?.total);
      setLimit(data?.limit);
      setSkip(data?.skip);
    },
    enabled: false,
    retry: false
  });


  useEffect(() => {
    refetch();
    recarregarFiltros();
  }, [limit, skip]);

  const recarregarFiltros = () => {
    if(sessionStorage.getItem('manuais')){
      setPesquisaAluno(JSON.parse(sessionStorage.getItem('manuais')).aluno);
      setpesquisaCPF(JSON.parse(sessionStorage.getItem('manuais')).cpf);
      setSkip(JSON.parse(sessionStorage.getItem('manuais')).skip);
      setLimit(JSON.parse(sessionStorage.getItem('manuais')).limit);
      sessionStorage.removeItem('manuais');
    }
  }

  function handleExportar() {
    getAlunosManuais(
      pesquisarAluno,
      pesquisaCPF,
      unidadeId,
      skip,
      limit,
      1
    ).then((response) => {
      if (response?.byteLength > 0) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'alunos-manuais.xlsx');
        document.body.appendChild(link);
        link.click();
      } else {
        toast.error('Erro ao exportar os dados');
      }
    });
  }

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Nome', accessor: 'nome' },
      { Header: 'Cpf', accessor: 'cpf' },
      {
        Header: 'Nascimento', accessor: 'carteiraNascimento',
        Cell: ({ row }) =>
          row.original.carteiraNascimento.split("T")[0].split("-").reverse().join("/"),
      },
      { Header: 'Unidade', accessor: 'unidade' },
      { Header: 'Escolaridade', accessor: 'escolaridade' },
      {Header: 'Servidor', accessor: 'servidorLogin'},
      {
        Header: "Data Evento",
        accessor: "dataEvento",
        Cell: ({ row }) =>
          row.original.dataEvento.split("T")[0].split("-").reverse().join("/"),
      },
      // {
      //   Header: '', accessor: 'actions',
      //   Cell: ({ row }) => (
      //     <>
      //       <button onClick={() => {
      //             sessionStorage.setItem('manuais',JSON.stringify(
      //               {
      //                 aluno: pesquisarAluno,
      //                 cpf: pesquisaCPF,
      //                 skip: skip,
      //                 limit: limit
      //               }
      //             ));
      //           navigate(urlsGestaoUnidades.alunosEditar + row.original.id, {
      //             state: {
      //               id: row.original.pessoaID,
      //               aluno: {
      //                 nome: row.original.nome,
      //                 cpf: row.original.cpf,
      //                 responsavel: row.original.responsavelNome,
      //                 status: row.original.status,
      //               },
      //             },
      //           });
      //       }} className='btn btn-sm btn-primary'>
      //         <i className="material-icons">edit_note</i> Editar
      //       </button>
      //       <button className='btn btn-sm btn-danger' onClick={() => {
      //         sessionStorage.setItem('manuais',JSON.stringify(
      //           {
      //             aluno: pesquisarAluno,
      //             cpf: pesquisaCPF,
      //             skip: skip,
      //             limit: limit
      //           }
      //         ));

      //           navigate(urlsGestaoUnidades.alunosEvadir, {
      //             state: {
      //               id: row.original.id,
      //               aluno: {
      //                 nome: row.original.nome,
      //                 responsavel: row.original.responsavelNome,
      //                 escolaridade: row.original.escolaridade,
      //                 escolaridadeId: row.original.escolaridadeId,
      //               },
      //             },
      //           });
      //       }}>
      //         <i className="material-icons">delete</i> excluir
      //       </button>
      //     </>
      //   )
      // },
    ],
    [navigate, data]
  )

  return (
    <BaseComponent>
      <Breadcrumb title={'Alunos Cadastrados Manualmente'} itens={[BaseTitle, 'Parâmetros', 'Alunos Cadastrados Manualmente', 'Lista Geral']} hasExcelButton={true} onClickDownload={() => handleExportar()} />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginTop: 10,
          marginBottom: 20,
        }}
      >
        <div
          className="form-group"
          style={{ width: "100%", maxWidth: 390, marginRight: 10 }}
        >
          <input
            type="text"
            className="form-control"
            placeholder="CPF"
            value={pesquisaCPF}
            onChange={(e) => setpesquisaCPF(e.target.value)}
          />
        </div>

        <div
          className="form-group"
          style={{ width: "100%", maxWidth: 395, marginRight: 10 }}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Nome do Aluno"
            value={pesquisarAluno}
            onChange={(e) => setPesquisaAluno(e.target.value)}
          />
        </div>
        <div
          className="form-group"
          style={{
            marginTop: 6,
            marginLeft: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <button
            className="btn btn-sm btn-primary"
            onClick={() => refetch()}
          >
            <i className="material-icons md-search"></i> Buscar
          </button>
        </div>
      </div>
      
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            {loading ? (
              <Spinner />
            ) : (
              <Table
                columns={columns}
                data={data === undefined ? [] : data}
                hasPagination
                limit={limit}
                setLimit={setLimit}
                skip={skip}
                setSkip={setSkip}
                totalItems={total}
                validatePage={true}
              />
            )}
          </div>
        </div>
      </div>
    </BaseComponent>
  )

};

export default AlunosManuaisPage;

import urlsFamiliasConectada from "./urlsFamiliaConectada";

const links = () => {
  return (
    {
      familiaConectada: {
        id: 'familia_conectada',
        text: 'FAMÍLIA CONECTADA',
        type: 'title',
        visible: true,
        itens: {
          avisos: {
            id: 'avisos',
            text: 'AVISOS',
            path: urlsFamiliasConectada.reponsavelAvisos,
            iconName:'',
            icon: 'group',
            visible: true,
          },
        }
      },   
    }
  )
};

export default links;
import { useMemo, useState } from 'react';
import Breadcrumb from '../../../components/breadcrumb';
import Table from '../../../components/table';
import BaseReconhecimentoFacial from '../baseReconhecimentoFacial';
import { useQuery } from 'react-query';
import { listarReconhecimentoFacial } from '../../../services/reconhecimento-facial';
import { useNavigate } from "react-router-dom";
import Spinner from '../../../components/spinner';

const ListagemReconhecimentoFacial = () => {
  const navigate = useNavigate();

  const unidadeId = JSON.parse(localStorage.getItem('unidadeID'));
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);

  const { data, isLoading: loading, refetch } = useQuery(
    ['listarReconhecimentoFacial', unidadeId, skip, limit],
    () => listarReconhecimentoFacial(unidadeId, skip, limit),
    {
      enabled: !!unidadeId
    }
  );

  console.log(data);

  const columns = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'nome',
        Cell: ({ row }) => (
          row.original.alunoId === 0 ? row.original.nomeServidor : row.original.nomeAluno
        )
      },
      {
        Header: 'Data Consulta',
        accessor: 'dataRegistro',
        Cell: ({ value }) => {
          const date = new Date(value);
          return (
            <div>
              <div>{date.toLocaleDateString()} às {date.toLocaleTimeString()}</div>
              <div></div>
            </div>
          );
        }
      },
      { Header: 'Unidade Consulta', accessor: 'nomeUnidade' },
      {
        Header: 'Reconhecimento',
        accessor: 'alunoId',
        Cell: ({ row }) => (
          <span className={`badge bg-dark`}>
            {row.original.alunoId === 0 ? 'SERVIDOR' : 'ALUNO'}
          </span>
        )
      },
    ],
    []
  );

  return (
    <BaseReconhecimentoFacial>
      <Breadcrumb title={'Reconhecimento Facial'} itens={['Listagem']} />
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            {loading ? (
              <Spinner />
            ) : (
              <Table
                columns={columns}
                data={data ? data : []}
                // hasPagination
                // limit={limit}
                // setLimit={setLimit}
                // skip={skip}
                // setSkip={setSkip}
                // totalItems={data?.total}
                // hiddenColluns={['preMatricula']}
                overflowUnset={true}
              />
            )}
          </div>
        </div>
      </div>
    </BaseReconhecimentoFacial>
  );
};

export default ListagemReconhecimentoFacial;

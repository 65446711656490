
import { getEscolaridade } from './../services/escolaridades';
import { getTurno } from './../services/turnos';

const createStorageReport = async ({ dados, nomeTurma, turnoId, escolaridadeId }) => {
    var escolaridade = await getEscolaridade(escolaridadeId);
    var turno = await getTurno(turnoId);


    localStorage.setItem('report', JSON.stringify(
        {
            logo: `/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`,
            dados: dados.sort(function (a, b) { a = a.nome; b = b.nome; return a - b; }),
            nomeUnidade: document.getElementById('titleUnidade').innerText.substring(0),
            nomeTurma: nomeTurma,
            nomeTurno: turno.descricao,
            nomeEscolaridade: escolaridade.descricao
        }
    ));
}

export default createStorageReport;
import React, { useState, useEffect } from "react";
import Breadcrumb from "../../components/breadcrumb";
import BaseGestaoUnidades from "../gestao-unidades/baseGestaoUnidades";
import BaseGestao from "../gestao/baseGestao";
import BaseCommon from "../shared/BaseCommon";
import { store } from "../../store";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { listarServidor as listarServidor } from "../../services/servidores";
import { editarServidorPerfil as editarServidorPerfil } from "../../services/servidores";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";

const Perfil = (gestao) => {
  let BaseComponent;
  let BasePath;
  let camposPreenchidosEmail = false;
  let camposPreenchidosSenha = false;
  let camposPreenchidosSenhaConfirm = false;
  let camposPreenchidosTelefone = false;

  const navigate = useNavigate();

  const handleLogout = () => {
    navigate("/");
  };

  const currentUser = store.getState()["user"]["user"];

  const { data: servidor, isLoading } = useQuery("listarServidor", () =>
    listarServidor(currentUser.servidorId)
  );
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");
  const [telefone, setTelefone] = useState("");

  useEffect(() => {
    if (servidor) {
      setEmail(servidor.email || "");
      setSenha(servidor.usuarioSenha || "");
      setConfirmarSenha(servidor.usuarioSenha || "");
      setTelefone(servidor.telefoneCelular || servidor.telefoneFixo || "");
    }
  }, [servidor]);

  const handleChangeEmail = (event) => setEmail(event.target.value);
  const handleChangeSenha = (event) => setSenha(event.target.value);
  const handleChangeConfirmarSenha = (event) =>
    setConfirmarSenha(event.target.value);
  const handleChangeTelefone = (event) => setTelefone(event.target.value);

  const rawPhoneNumber = telefone.replace(/\D/g, "");

  const startsWithNine = /^\d{2}9/.test(rawPhoneNumber);

  // formattedPhoneNumber = `(${rawPhoneNumber.slice(0, 2)}) ${rawPhoneNumber.slice(2, 7)}-${rawPhoneNumber.slice(7)}`;

  console.log(servidor);

  BaseComponent = BaseCommon;
  BasePath = "Perfil Servidor";

  const handleClickEnviar = () => {
    if (senha != confirmarSenha) {
      toast.error("As senhas não coincidem!");
    } else {
      if (email == null || email == "") {
        camposPreenchidosEmail = true;
        toast.error("O campo email é obrigatório!");
      }
      if (senha == null || senha == "") {
        camposPreenchidosSenha = true;
        toast.error("O campo senha é obrigatório!");
      }
      if (confirmarSenha == null || confirmarSenha == "") {
        camposPreenchidosSenhaConfirm = true;
        toast.error("O campo confirmar senha é obrigatório!");
      }
      if (telefone === null || telefone === "") {
        camposPreenchidosTelefone = true;
        toast.error("O campo telefone é obrigatório!");
      }

      if (
        camposPreenchidosTelefone != true &&
        camposPreenchidosEmail != true &&
        camposPreenchidosSenha != true &&
        camposPreenchidosSenhaConfirm != true
      ) {
        editarServidorPerfil(currentUser.servidorId, email, senha, telefone);
        if (servidor.usuarioSenha !== senha) {
          handleLogout();
        }
        toast.success("Atualizado com sucesso!");
      }
    }
  };

  return (
    <BaseComponent>
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <Breadcrumb
              title={"Alterar Dados Pessoais"}
              itens={[BasePath, "Perfil"]}
            />
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <ul
                    className="nav nav-tabs"
                    id="servidoresTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        data-bs-toggle="tab"
                        data-bs-target="#dados"
                        type="button"
                        role="tab"
                        aria-controls="dados"
                        aria-selected="true"
                      >
                        DADOS GERAIS
                      </button>
                    </li>
                  </ul>
                  {servidor && (
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label
                            htmlFor="nomeServidor"
                            style={{ marginTop: "24px" }}
                          >
                            Email:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="nomeServidor"
                            value={email}
                            onChange={handleChangeEmail}
                            required={true}
                            style={
                              email == "" ? { borderColor: "#FC2C00" } : {}
                            }
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label
                            htmlFor="nomeServidor"
                            style={{ marginTop: "24px" }}
                          >
                            Senha:
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="nomeServidor"
                            value={senha}
                            onChange={handleChangeSenha}
                            required={true}
                            style={
                              senha == "" ? { borderColor: "#FC2C00" } : {}
                            }
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label
                            htmlFor="nomeServidor"
                            style={{ marginTop: "24px" }}
                          >
                            Confirmar Senha:
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="nomeServidor"
                            value={confirmarSenha}
                            onChange={handleChangeConfirmarSenha}
                            required={true}
                            style={
                              confirmarSenha == ""
                                ? { borderColor: "#FC2C00" }
                                : {}
                            }
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label
                            htmlFor="nomeServidor"
                            style={{ marginTop: "24px" }}
                          >
                            Telefone:
                          </label>
                          <InputMask
                            type="text"
                            className="form-control"
                            id="nomeServidor"
                            value={telefone}
                            mask={
                              startsWithNine
                                ? "(99)99999-9999"
                                : "(99)9999-9999"
                            }
                            onChange={handleChangeTelefone}
                            style={
                              telefone == "" ? { borderColor: "#FC2C00" } : {}
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <button
                    type="submit"
                    onClick={handleClickEnviar}
                    className="btn btn-success"
                    style={{
                      marginTop: "20px",
                      position: "sticky",
                      left: "87%",
                    }}
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseComponent>
  );
};

export default Perfil;

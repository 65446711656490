import { useEffect, useMemo, useState } from 'react';
import Breadcrumb from '../../../components/breadcrumb';
import Table from '../../../components/table';
import BaseGestao from '../../gestao/baseGestao';
import BaseGestaoUnidades from '../../gestao-unidades/baseGestaoUnidades';
import BaseConfiguracoes from '../../configuracoes/baseConfiguracoes';
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom';
import { getServidores, deleteServidor, restaurarServidor } from '../../../services/servidores'
import Spinner from '../../../components/spinner';
import { getUnidades } from '../../../services/unidades';
import { toast } from 'react-toastify'
import urlsGestao from '../../gestao/urlsGestao';
import urlsGestaoUnidades from '../../gestao-unidades/urlsGestaoUnidades';
import Select from 'react-select';
import FormInput from '../../../components/formInput';
import { store } from '../../../store';

const ServidoresExcluidosPage = (gestao) => {

  const unidadeID = localStorage.getItem('unidadeID')
  const currentUser = store.getState()['user']['user']
  
  const navigate = useNavigate();
  const [newData, setNewData] = useState('');
  const [search, setSearch] = useState('');
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [warningControl, setWarningControl] = useState(false)
  const [cargos, setCargos] = useState([]);
  const [regimes, setRegimes] = useState([]);
  const [unidade, setUnidade] = useState(gestao.gestao ? 0 : unidadeID)
  const [unidades, setUnidades] = useState([]);

  const onMenuOpen = () => setIsOpen(true);
  const onMenuClose = () => setIsOpen(false);
  const [isOpen, setIsOpen] = useState(false);
  



  const { isLoading: loadingUnidades, refetch: refetchUnidades } = useQuery('getUnidades', () => getUnidades(), {
    enabled: true,
    retry: true,
    onSuccess: (data) => {
      var dados = [{value: 0, label: 'TODOS'}];
      data.forEach(item => {
        dados.push({ value: item.id, label: item.nome })
      });

      setUnidades(dados);
    }
  })

  const editarUrl = gestao.gestao ? urlsGestao.servidoresEditar : urlsGestaoUnidades.servidoresEditar;
  const adicionarUrl = gestao.gestao ? urlsGestao.servidoresAdicionar : urlsGestaoUnidades.servidoresAdicionar;
  const BaseComponent = BaseConfiguracoes;
  const BasePath = 'Configurações';

  useEffect(() => {
    if (process.env.REACT_APP_SECRETARIA_CIDADE === 'ARARUAMA') {
      import('../pickersAraruama').then((mod) => {
        setCargos(mod.servidoresCargos);
        // setRegimes(mod.regimesServidores);
        var dados = [{value: 0, label: 'TODOS'}];
        mod.regimesServidores.forEach(item => {
          dados.push({ value: item.id, label: item.description })
        });
  
        setRegimes(dados);
      });
    } else {
      import('../pickersSaoPedro').then((mod) => {
        setCargos(mod.servidoresCargos);
        var dados = [{value: 0, label: 'TODOS'}];
        mod.regimesServidores.forEach(item => {
          dados.push({ value: item.id, label: item.description })
        });
  
        setRegimes(dados);
      });
    }
  }, []);
  
  
  const [unidadeSelected, setUnidadeSelected] = useState(gestao.gestao ? 0 : unidadeID);
  const [regimeSelected, setRegimeSelected] = useState(0);

  const [cargosData, setCargosData] = useState([]);

    const { data, refetch, isLoading } = useQuery('getServidores', () => getServidores(unidadeSelected, regimeSelected, search, limit, skip, 1), {
    onSuccess: (data) => {
      setNewData(data);
    },
    enabled: true,
    retry: false
  })


  

  const findCargo = (id) => {
    if (id) {
      const cargoName = cargos.find((cargo) => cargo.id === id);
      return cargoName.description;
    }
  }


  const findRegime = (id) => {
    if (id) {
      const regimeName = regimes.find((regime) => regime.value === id);
      return regimeName.label;
    }
  }

  const handleRestaurarServidor = async (servidorId) => {

    let data = {
      servidorId: parseInt(servidorId),
      usuarioId: currentUser?.servidorId
    }
    await restaurarServidor(data)
    refetch()
  }

  useEffect(() => {
    refetch()
  }, [search, limit, skip, newData, unidadeSelected, regimeSelected ])

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Nome Servidor', accessor: 'nomeCompleto' },
      // { Header: 'CPF', accessor: 'cpf' },
      // { Header: 'Número Matrícula', accessor: 'numeroContrato' },
      {
        Header: 'Cargo', accessor: 'cargo',
        Cell: ({ row }) => (
          findCargo(row.original.cargo)
        )
      },
      // {
      //   Header: 'Regime', accessor: 'regimeID',
      //   Cell: ({ row }) => (
      //     findRegime(row.original.regime)
      //   )
      // },
      {
        Header: 'Gestão', accessor: 'moduloGestao',
        Cell: ({ row }) => (
          <span className={`badge bg-${row.original.moduloGestao ? 'success' : 'danger'}`}>
            {row.original.moduloGestao ? 'SIM' : 'NÃO'}
          </span>
        )
      },
      {
        Header: 'Gestão Unidades', accessor: 'moduloGestaoUnidades',
        Cell: ({ row }) => (
          <span className={`badge bg-${row.original.moduloGestaoUnidades ? 'success' : 'danger'}`}>
            {row.original.moduloGestaoUnidades ? 'SIM' : 'NÃO'}
          </span>
        )
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) => (
          <>
            
           

            {row.original.status !== 2 && (
            <button
              onClick={() =>
                handleRestaurarServidor(row.original.id)
              }
              className="btn btn-sm btn-success"
            >
              <i className="material-icons md-edit_note"></i> restaurar
            </button>
            )}
          </>
        )
      }
    ],
    [navigate, isLoading, newData]
  )

  const handleSearchButton = () => {
    if (search.length >= 3) {
      setSearch(search)
      refetch()
    }
  }



  return (
    <BaseComponent>
      <Breadcrumb title={'Servidores'} itens={[BasePath, 'Servidores', 'Lista Geral']} actions={{ link: adicionarUrl, text: "Novo Servidor" }} />
      
      <div style={{ marginTop: 10, marginBottom: 20 }}>
      <div className='row align-items-center'>
      <div className="col-12 col-md-5">
          <div className="form-group">
            <label htmlFor="unidadeId" className="form-label">
              Unidades
            </label>
            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={unidades}
              // isDisabled={gestao.gestao ? false : true}
              value={unidades.find(x => x.value == unidadeSelected)}
              placeholder=''             
              onChange={async e => {
                setUnidadeSelected(e.value);
              }
              }
            />
          </div>
        </div>

        <div className="col-12 col-md-2">
          <div className="form-group">
            <label htmlFor="regimeId" className="form-label">
              Regimes
            </label>
            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={regimes}
              // isDisabled={gestao.gestao ? false : true}
              value={regimes.find(x => x.value == regimeSelected)}
              placeholder=''             
              onChange={async e => {
                setRegimeSelected(e.value);
              }
              }
            />
          </div>
        </div>

        <div className="col-12 col-md-4">
        <div className='form-group'>
        <label htmlFor="regimeId" className="form-label">
              Pesquisar
            </label>
          <input
            type='text'
            className='form-control'
            // placeholder='Pesquisar'
            value={search}
            onChange={e => setSearch(e.target.value)}
            onMouseEnter={() => setWarningControl(true)}
            onMouseOut={() => setWarningControl(false)}
          />
        </div>
        </div>

        <div className="col-12 col-md-1 d-flex align-items-center justify-content-center">
        <div className='form-group' style={{marginTop: '20px'}}>
          <button className='btn btn-sm btn-primary' onClick={() => handleSearchButton()}>
            <i className="material-icons md-search"></i> Buscar
          </button>
        </div>
        </div>

                    </div>

                   
                    
      
        
        

      </div>

    

      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            {isLoading ? (
              <Spinner />
            ) : (
              <Table
                columns={columns}
                data={ newData?.data ? newData?.data : [] }
                hasPagination
                limit={limit}
                setLimit={setLimit}
                skip={skip}
                setSkip={setSkip}
                totalItems={newData?.total}
              />
            )}
          </div>
        </div>
      </div>
    </BaseComponent>
  )

};

export default ServidoresExcluidosPage;

import React, { useState, useEffect, useMemo } from 'react'
import Table from '../../../../components/table';
import { useQuery } from 'react-query'

export default function QuadroDeHorarioAbaDia(data) {
  
  const quadroData = data?.data?.find(item => item.diaSemana === data.diaSemana)?.dadosQuadroHorario ?? [];

  const columns = useMemo(
    () => [
      { Header: 'Hora Inicial', accessor: 'horaInicio' },
      { Header: 'Hora Final', accessor: 'horaFinal' },
      { Header: 'Disciplina', accessor: 'disciplina' },
      { Header: 'Servidor 1', accessor: 'servidor1Descricao' },
      { Header: 'Servidor 2', accessor: 'servidor2Descricao' },
    ],
    []
  )

  let diaSemanaLower = data.diaSemana.toLowerCase()

  return (
    <div
      className={diaSemanaLower == 'segunda' ? "tab-pane fade show active" : "tab-pane fade"}
      id={diaSemanaLower}
      role="tabpanel"
      aria-labelledby={diaSemanaLower + '-tab'}
    >
      <div className="card-body">
        <div className="row">
          <Table
            columns={columns}
            data={quadroData}
          />
        </div>

      </div>
    </div>
  )
}

import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Breadcrumb from "../../../components/breadcrumb";
import BaseGestao from '../../gestao/baseGestao';
import BaseGestaoUnidades from '../../gestao-unidades/baseGestaoUnidades';
import CComissao from './tabs/cComissao';
import HoraExtra from './tabs/horaExtra';
import FrequenicaBaseModel from './tabs/baseModel';

const LancamentosFrenquenciaServidores = (gestao) => {

  let BaseComponent;
  let BasePath;
  
  if (gestao.gestao) {
    BaseComponent = BaseGestao;
    BasePath = "Gestão";
  }
  if (!gestao.gestao && !gestao.docente) {
    BaseComponent = BaseGestaoUnidades;
    BasePath = "Gestão Unidades";
  }

  // State to keep track of the selected tab
  const [selectedTab, setSelectedTab] = useState(0);
  const regimeEfetivo = 3;
  const regimeContrato = 2;
  const cargosDoc2 = 1;
  const cargosGem = 2;
  const cargosApoio = 3;
  

  // Function to handle tab change
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <BaseComponent>
      <Breadcrumb title={'Formulário Lançamento Frequência Servidores'} itens={[BasePath, 'Servidores', 'Frequência']} />
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className="card-body">

              {/* Tabs Section */}
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Frequência Servidores Tabs">
                  <Tab label="DOC. II EFETIVO" />
                  <Tab label="DOC II CONTRATO" />
                  <Tab label="GEM EFETIVO" />
                  <Tab label="GEM CONTRATO" />
                  <Tab label="APOIO EFETIVO" />
                  <Tab label="APOIO CONTRATO" />
                  {/* <Tab label="C. COMISSÃO" /> */}
                  <Tab label="HORA EXTRA" />
                </Tabs>
              </Box>

              {/* Tab Panels */}
              {selectedTab === 0 && <FrequenicaBaseModel regimeId={regimeEfetivo} cargoId={cargosDoc2}  />} {/* Load the Doc2Efetivo content */}
              {selectedTab === 1 && <FrequenicaBaseModel regimeId={regimeContrato} cargoId={cargosDoc2}/>}
              {selectedTab === 2 && <FrequenicaBaseModel regimeId={regimeEfetivo} cargoId={cargosGem}/>}
              {selectedTab === 3 && <FrequenicaBaseModel regimeId={regimeContrato} cargoId={cargosGem}/>}
              {selectedTab === 4 && <FrequenicaBaseModel regimeId={regimeEfetivo} cargoId={cargosApoio}/>}
              {selectedTab === 5 && <FrequenicaBaseModel regimeId={regimeContrato} cargoId={cargosApoio}/>}
              {/* {selectedTab === 6 && <CComissao/>} */}
              {selectedTab === 6 && <HoraExtra/>}

            </div>
          </div>
        </div>
      </div>
    </BaseComponent>
  );
};


export default LancamentosFrenquenciaServidores;

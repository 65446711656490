import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from '../../pages/login';


const LoginRoutes = () => (
    <React.Fragment>
        <Route path="/" element={<Login />} /> 
    </React.Fragment>
);

export default LoginRoutes;

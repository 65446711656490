import React, { Component } from 'react';
import Erro500 from '../pages/shared/Erro500';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Atualiza o estado para exibir a interface de fallback após um erro ser lançado
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Você pode registrar o erro em um serviço de relatórios de erros
    console.error("ErrorBoundary captured an error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Renderiza a página de erro 500
      return <Erro500 />;
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;

import { useMemo, useState, useEffect } from 'react';
import Breadcrumb from '../../../../components/breadcrumb';
import Table from '../../../../components/table';
import BaseAdministrativo from '../../baseAdministrativo';
import urlsAdministrativo from '../../urlsAdministrativo';
import { useQuery, useMutation } from 'react-query';
import { deleteNutricaoProduto, getNutricaoProdutos } from '../../../../services/nutricao';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const NutricaoControleProdutos = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [skip, setSkip] = useState(0);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(20);

  // const { data, refetch: refetchNutricaoProdutos } = useQuery('getNutricaoProdutos', () => getNutricaoProdutos(), {
  //   enabled: true,
  //   onSuccess: data => {
  //     setNewData(data)
  //   }
  // })

  const { isLoading: loading, refetch } = useQuery('getNutricaoProdutos', () => getNutricaoProdutos(skip, limit), {
    onSuccess: (data) => {
      setData(data.data);
      setTotal(data.total);
      setLimit(data.limit);
      setSkip(data.skip);
    },
    enabled: false,
    retry: true
  })

  useEffect(() => {
    refetch();
  }, [data, limit, skip]);

  const { mutate } = useMutation(id => deleteNutricaoProduto(id), {
    onSuccess: data => {
      toast.success('Produto excluído com sucesso.');
      refetch();
    },
    onError: data => {
      toast.error('Erro ao excluir produto. Tente novamente mais tarde.')
    }
  })

  let BRLFormat = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const formatValue = (value) => {
    if (value) {
      const newValue = `${BRLFormat.format(value)}`;
      return newValue;
    }
  }

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Produto', accessor: 'nome' },
      { Header: 'Descrição', accessor: 'descricao' },
      { Header: 'Fabricante', accessor: 'fabricante' },
      {
        Header: 'Valor', accessor: 'valor',
        Cell: ({ row }) => (
          formatValue(row.original.valor)
        )
      },
      { Header: 'Quantidade', accessor: 'quantidade' },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <button onClick={() => navigate(urlsAdministrativo.administrativoNutricaoProdutoCadastrar + row.original.id, {
              state: {
                data: row.original,
              }
            })} className='btn btn-sm btn-primary'>
              <i className="material-icons md-edit_note"></i> visualizar
            </button>
            <button
              className='btn btn-sm btn-danger'
              style={{ marginLeft: '8px' }}
              onClick={() => {
                const confirm = window.confirm('Tem certeza que deseja excluir?')
                if (confirm) {
                  mutate(row.original.id);
                }
              }}
            >
              <i className="material-icons md-group_remove"></i> excluir
            </button>
          </>
        )
      },
    ],
    [data]
  )

  return (
    <BaseAdministrativo>
      <Breadcrumb title={'Nutrição - Controle de Produtos'} itens={['Administrativo', 'Nutrção', 'Controle de produtos']} actions={{ link: urlsAdministrativo.nutricaoProdutoCadastrar, text: "Novo Produto" }} />
      <div className='row'>
        <div className="mailbox-container">
          <div className="card">
            <div className="card-body">
              {/* <Table columns={columns} data={newData ? newData : []} /> */}
              <Table
                columns={columns}
                data={data}
                hasPagination
                limit={limit}
                setLimit={setLimit}
                skip={skip}
                setSkip={setSkip}
                totalItems={total}
              />
            </div>
          </div>
        </div>
      </div>
    </BaseAdministrativo >
  )
};
export default NutricaoControleProdutos;

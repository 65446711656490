import { useMemo, useState } from 'react';
import Breadcrumb from '../../../../components/breadcrumb';
import Table from '../../../../components/novaTabela';
import BaseAdministrativo from '../../baseAdministrativo';
import { useNavigate } from 'react-router-dom';
import urlsAdministrativo from '../../urlsAdministrativo';
import { useQuery } from 'react-query';
import { getGrupoProdutos, deleteGrupoProdutos } from '../../../../services/administrativo/patrimonio';
import { toast } from 'react-toastify';
import { Chip } from '@mui/material';
import CategoriaProdutosActionMenu from './components/CategoriaProdutosActionMenu';
import CategoriaProdutosModal from './components/CategoriaProdutosModal';
import MUIBreadcrumb from '../../../../components/breadcrumb/muiBreadcrumb';
import AddIcon from '@mui/icons-material/Add';
import UndoIcon from '@mui/icons-material/Undo';

const CategoriaProdutosTable = () => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  
  const { data: groupData } = useQuery('getGrupoProdutos', getGrupoProdutos, { retry: false, enabled: true });

  const { refetch: deleteGroup } = useQuery('deleteGrupoProdutos', deleteGrupoProdutos, {
    retry: false,
    enabled: false,
    onSuccess: () => toast.success('Categoria de produtos excluída com sucesso.'),
    onError: () => toast.error('Algo deu errado. Tente novamente mais tarde.'),
  });

  const handleView = (row) => {
    setSelectedRow(row.original);
    setModalOpen(true);
  };

  const handleDelete = (row) => {
    deleteGrupoProdutos(row.original.id);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedRow(null);
  };

  const handleAddCategory = () => {
    setSelectedRow(null);
    setModalOpen(true);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Descrição', accessor: 'descricao' },
      {
        Header: 'Status',
        accessor: 'status',
        style: { display: "flex", justifyContent: "center", height: "61px" },
        Cell: ({ row }) => (
          <Chip
            label={row.original.status === 1 ? 'Ativo' : 'Inativo'}
            color={row.original.status === 1 ? 'success' : 'error'}
          />
        ),
      },
      {
        Header: 'Ações',
        accessor: 'actions',
        Cell: ({ row }) => (
          <CategoriaProdutosActionMenu
            row={row}
            onView={() => handleView(row)}
            onDelete={() => handleDelete(row)}
          />
        ),
      },
    ],
    [groupData]
  );

  return (
    <BaseAdministrativo>
      <MUIBreadcrumb
  title="Categoria de Produtos"
  itens={[
    { label: 'Administrativo', url: '#' },
    { label: 'Patrimônio', url: '#' },
    { label: 'Categoria de produtos', url: urlsAdministrativo.patrimonioCategoriaProdutos }
  ]}
  actions={[
    { onClick: handleAddCategory, text: 'Nova Categoria', icon: <AddIcon />, color: 'primary.main' },
    { onClick: handleGoBack, text: 'Voltar', icon: <UndoIcon />, color: 'warning.main' },
  ]}
/>
      <div className="row">
        <div className="card-body">
          <Table columns={columns} data={groupData || []} />
        </div>
      </div>

      {/* Modal for viewing or adding a category */}
      <CategoriaProdutosModal
        open={isModalOpen}
        onClose={handleCloseModal}
        initialData={selectedRow} // Pass selected row data for edit or null for add mode
      />
    </BaseAdministrativo>
  );
};

export default CategoriaProdutosTable;

import React, { useEffect, useRef } from 'react';
import Logo from '../logo';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Types as PanelTypes } from '../../store/ducks/panel'

const RenderMenu = ({ menu }) => {
  const dispatch = useDispatch();

  return (
    <>
      <li key={menu.text} className="sidebar-title"> {menu.text} </li>
      {Object.keys(menu.itens).map((kk, ii) => menu.itens[kk].visible ? (
        <li key={menu.itens[kk].text} onClick={() => dispatch({ type: PanelTypes.OPEN_MENU, payload: { isMenuOpen: false } })
        }>
          <NavLink to={menu.itens[kk].path}>
          <i class={`material-icons md-${menu.itens[kk].icon}`}></i>
            {menu.itens[kk].text}
          </NavLink>
        </li>
      ) : null)}
    </>
  )
}

const Aside = ({ links, perfil }) => {
  const dispatch = useDispatch();
  const sidebarRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        dispatch({ type: PanelTypes.OPEN_MENU, payload: { isMenuOpen: false } });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="app-sidebar" ref={sidebarRef}>
      <Logo perfil={perfil}/>
      <div className="app-menu" >
        <ul className="accordion-menu">
          {Object.keys(links).map((k, i) => links[k].visible ? <RenderMenu menu={links[k]} key={i} /> : null)}
        </ul>
      </div>
    </div>
  )
};

export default Aside;

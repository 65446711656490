import React from 'react';
import { Routes, Route } from 'react-router-dom';
import urlsRemanejamento from '../../../pages/processos/remanejamento/private/urlsRemanejamento';
import HomeRemanejamento from '../../../pages/processos/remanejamento/private/homeRemanejamento';
import RemanejamentoInscricoes from '../../../pages/processos/remanejamento/private/inscricoes';
import RemanejamentoInscricaoOnlineForm from '../../../pages/processos/remanejamento/public/formulario/remanejamento'
import RemanejamentoOnline from '../../../pages/processos/remanejamento/public/formulario';
import RemanejamentoVisualizacaoFormulario from '../../../pages/processos/remanejamento/public/formulario/visualizacaoForm';
import RemanejamentoIndeferir from '../../../pages/processos/remanejamento/private/inscricoes/indeferir';

const enable = process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEDUC' || process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'R3MAIS';
const dataAberturaRemanejamento = new Date('2024-11-01T09:00:00');
const dataFechamentoRemanejamento = new Date('2024-11-22T17:00:00');

const RemanejamentoRoutes = () => (
    <React.Fragment>
    {enable &&  (
    <>
      <Route path={urlsRemanejamento.dashboard} element={<HomeRemanejamento />} />
      <Route path={urlsRemanejamento.inscricoes} element={<RemanejamentoInscricoes />} />
      <Route path={urlsRemanejamento.formulario} element={<RemanejamentoOnline dataAbertura={dataAberturaRemanejamento} dataFechamento={dataFechamentoRemanejamento} />} />
      <Route path={urlsRemanejamento.visualizarId} element={<RemanejamentoVisualizacaoFormulario />} />
      <Route path={urlsRemanejamento.indeferirId} element={<RemanejamentoIndeferir />} />
    </>
    )}

    {enable && new Date() >= dataAberturaRemanejamento && new Date() <= dataFechamentoRemanejamento && (
       <>

       <Route path={urlsRemanejamento.formularioDados} element={<RemanejamentoInscricaoOnlineForm dataAbertura={dataAberturaRemanejamento} dataFechamento={dataFechamentoRemanejamento}/>} /> 
     </>
    )}
  </React.Fragment>
);

export default RemanejamentoRoutes;

import { useMemo, useEffect, useState } from 'react'
import Breadcrumb from '../../components/breadcrumb';
import Table from '../../components/table';
import BaseConfiguracoes from '../configuracoes/baseConfiguracoes';
import urlsConfiguracoes from '../configuracoes/urlsConfiguracoes';
import { useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query'
import { getCargos } from '../../services/servidores'
import { getResponsaveis, deleteResponsavel } from '../../services/responsaveis'
import { toast } from 'react-toastify';

const ResponsaveisPage = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [total, setTotal] = useState()

  const { data: representantesData, refetch } = useQuery('getResponsaveis', () => getResponsaveis({ skip: skip, limit: limit }), {
    enabled: true,
    retry: true,
    onSuccess: (data) => {
      setData(data?.data);
      setLimit(data?.limit);
      setSkip(data?.skip)
      setTotal(data?.total)
    }
  })

  const { mutate: deleteMutate, isLoading: loadingDelete } = useMutation(deleteResponsavel, {
    onSuccess: () => {
      toast.success('Representante excluído com sucesso!');
      refetch();
    },
    onError: () => {
      toast.error('Algo deu errado. Tente novamente mais tarde.');
    }
  })

  const { data: cargosData, isLoading: cargosLoading } = useQuery('getCargos', () => getCargos(), {
    enabled: true,
    retry: true
  })

  const findCargo = (id) => {
    if (id) {
      const cargoName = cargosData?.find((cargo) => cargo.id === id);
      return cargoName?.descricao;
    }
  }


  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Nome Responsável', accessor: 'nome' },
      { Header: 'Telefone Celular', accessor: 'telefoneCelular' },
      { Header: 'E-mail', accessor: 'email' },
      {
        Header: 'Cargo', accessor: 'cargo',
        Cell: ({ row }) => (
          findCargo(row.original.cargo)
        )
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <button
              onClick={() =>
                navigate(urlsConfiguracoes.responsaveisEditar + row.original.id)
              }
              className="btn btn-sm btn-primary"
            >
              <i className="material-icons md-edit_note"></i> editar
            </button>
            <button
              style={{ marginLeft: '8px' }}
              onClick={() => {
                const confirm = window.confirm('Tem certeza que deseja excluir?')
                if (confirm) {
                  deleteMutate(row.original.id)
                }
              }}
              className="btn btn-sm btn-danger"
            >
              <i className="material-icons md-group_remove"></i> excluir
            </button>
          </>
        )
      }
    ],
    [navigate, data, cargosData]
  )

  return (
    <BaseConfiguracoes>
      <Breadcrumb
        title={'Responsáveis'}
        itens={['Gestão', 'Responsáveis', 'Lista Geral']}
        actions={{
          link: urlsConfiguracoes.responsaveisAdicionar,
          text: 'Novo Responsável'
        }}
      />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <Table
              columns={columns}
              data={data}
              hasPagination
              limit={limit}
              setLimit={setLimit}
              skip={skip}
              setSkip={setSkip}
              totalItems={total}
            />
          </div>
        </div>
      </div>
    </BaseConfiguracoes>
  )
}

export default ResponsaveisPage

import Header from "../../components/header";
import Aside from "../../components/aside";
import links from './linksGestaoUnidades'
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react'
import api from '../../services'
import { store } from '../../store/index'
import SuporteRodape from "../../utils/suporteRodape";


const BaseGestaoUnidades = ({ children }) => {
  const isMenuOpen = useSelector(state => state.panel.isMenuOpen);
  const [newData, setNewData] = useState('');

  const currentUser = store.getState()['user']['user']

  useEffect(() => {
    const unidadeID = localStorage.getItem('unidadeID');
    async function fetchData() {
      const response = await api.get(`${process.env.REACT_APP_API_URL}/unidade/${unidadeID}`);
      setNewData(response.data.data.nome)
    }
    fetchData()
  }, []);

  return (
    <div className={
      isMenuOpen ? 'app menu-off-canvas align-content-stretch d-flex flex-wrap menu-off-canvas-show' : 'app menu-off-canvas align-content-stretch d-flex flex-wrap'
    }>
          <SuporteRodape />
      <Aside links={
        links(currentUser?.moduloGestaoUnidadesGrupoGestaoEducacional, 
              currentUser?.moduloGestaoUnidadesGrupoAcademico, 
              currentUser?.moduloGestaoUnidadesGrupoDiario, 
              currentUser?.moduloGestaoUnidadesGrupoTransferencias,
              currentUser?.moduloGestaoUnidadesGrupoFamiliaConectada,
              currentUser?.moduloGestaoUnidadesFrequenciaServidores)} />
      <div className="app-container">
        <div className="search">
          <form>
            <input
              className="form-control"
              type="text"
              placeholder="Pesquisa..."
              aria-label="Search"
            />
          </form>
          <a href="/" className="toggle-search">
            <i className="material-icons md-close"></i>
          </a>
        </div>
        <Header title={newData} isGestao={currentUser?.moduloGestao === 1} docente={false} showPicker={true} />
        <div className="app-content">
          <div className="content-wrapper">
            <div className="container">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default BaseGestaoUnidades;

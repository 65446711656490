import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import BaseAdministrativo from '../../../baseAdministrativo'
import NutricaoProdutos from './tab/produto/index'

const NutricaoProdutosForm = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { state } = useLocation()
  const [canEdit, setCanEdit] = useState(id ? false : true)
  const [isHistoricTab, setIsHistoricTab] = useState(false)

  return (
    <BaseAdministrativo>
      <div className="row">
        <div className="mailbox-container">
          <div className="card">
            <div className="container-fluid">
              <div className="row">
                <div className="mailbox-open-content col-md-12 m-0 p-3">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="produtos-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#produtos"
                        type="button"
                        role="tab"
                        aria-controls="produtos"
                        aria-selected="true"
                        onClick={() => setIsHistoricTab(false)}
                      >
                        Produtos
                      </button>
                    </li>


                  </ul>

                  <div className="tab-content" id="myTabContent">
                    <NutricaoProdutos />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseAdministrativo>
  )

};

export default NutricaoProdutosForm;
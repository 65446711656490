import Breadcrumb from "../../components/breadcrumb";
import BaseGestaoUnidades from "./baseGestaoUnidades";
import Spinner from '../../components/spinner';
import { useEffect, useState } from "react";
import api from '../../services'

const HomeGestaoUnidades = () => {
  const unidadeID = localStorage.getItem('unidadeID');
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      const response = await api.get(`/estatistica/municipio/${unidadeID}`);
      setData(response.data.data)
      setIsLoading(false)
    }
    fetchData()
  }, [])

  return (
    <BaseGestaoUnidades>
      <Breadcrumb title={'Informações Gerais'} itens={['Gestão de Unidades', 'Dashboard']} />
      {
        isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="row">
              <div className="col-xl-4">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-gray">
                        <i className="material-icons-outline md-assignment_ind"></i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                      <span className="widget-stats-title">Alunos</span>
                        <span className="widget-stats-amount">{data?.alunos}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-success">
                        <i className="material-icons-outline md-assignment_ind"></i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Turmas</span>
                        <span className="widget-stats-amount">{data?.turmas}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-danger">
                        <i className="material-icons-outline md-groups"></i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Servidores</span>
                        <span className="widget-stats-amount">{data?.servidores}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
    </BaseGestaoUnidades>
  )
};

export default HomeGestaoUnidades;
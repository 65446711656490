import api from "..";

async function getResponsaveis({ skip = 0, limit = 20 }) {
  return await api
    .get(`gestao/responsavel?skip=${skip}&limit=${limit}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function getResponsavel(id) {
  return await api
    .get(`gestao/responsavel/${id}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function updateResponsavel({ id, data }) {
  return await api
    .put(`gestao/responsavel/${id}`, data)
    .then(async (res) => {
      return res;
    });
}

async function postResponsavel(data) {
  return await api
    .post('gestao/responsavel', data)
    .then(async (res) => {
      return res;
    });
}

async function deleteResponsavel(id) {
  return await api
    .delete(`gestao/responsavel/${id}`)
    .then(async (res) => {
      return res;
    });
};

export {
  getResponsaveis,
  getResponsavel,
  updateResponsavel,
  postResponsavel,
  deleteResponsavel
}
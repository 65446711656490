import styled from 'styled-components';

export const PaginationSection = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  width: 100%;
  background-color: transparent;
  color: #7b8c9d;
  font-weight: 500;
  padding: 0 16px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 2px solid #f4f7fa;
`;

export const TDCheck = styled.td`
  width: 50px;
  height: 48px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
`;

export const ContainerRadioButton = styled.label`
  position: relative;
  display: block;
  min-height: 24px;
  padding: 0 12px;
  font-size: 16px;
  color: #444;
  user-select: none;
  cursor: pointer;

  input {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
    cursor: pointer;
  }

  span.checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    border: 1px solid #767676;
    height: 22px;
    width: 22px;
    border-radius: 4px;
    background: #fff;
  }

  input:checked ~ .checkmark {
    border: 2px solid #ec0000;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 6px;
    top: 1px;
    width: 4px;
    height: 10px;
    border: 1px solid #ec0000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg;
  }
`;

export const SelectAmount = styled.section`
  display: flex;
  background-color: transparent;
  gap: 5px;
`;

export const SelectPage = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background-color: transparent;
`;

export const NumberButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7b8c9d;
  width: 24px;
  height: 24px;
  border: none;
  background-color: ${props => props.selected ? '#2269F5' : 'transparent'};
  color: ${props => props.selected ? 'white' : '#7b8c9d'};
  font-weight: bold;

  :disabled {
    display: none;
  }
`;

export const SelectComponent = styled.select`
  display: flex;
`;

/* Add CSS to ensure dropdown menu is shown above the table */
export const DropdownMenuShow = styled.div`
  .dropdown-menu.show {
    z-index: 1050; /* Ensure this is higher than the table's z-index */
    position: absolute;
    transform: translate3d(0, 0, 0); /* Optional: Adjusts for better positioning */
  }
`;

/* Make sure the table is in a relative container if necessary */
export const TableResponsive = styled.div`
  position: relative;
`;

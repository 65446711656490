import React, { useState, useEffect } from 'react';
import {
  Grid, Card, CardContent, TextField, Menu, Button, Autocomplete, Tooltip, Chip, Box
} from '@mui/material';
import { FilterAltOutlined, TaskAlt } from '@mui/icons-material';
import { getCidades, getEstados } from '../../../../services/endereco';
import { status } from '../data/status';
import { exportarInscricoesFiltroServidores } from '../../../../services/formulariosService';

const FormularioServidoresInscricoesFilter = ({ filtros, setFiltros, handleSubmit }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [estadoSelecionado, setEstadoSelecionado] = useState('');

  // Load filters from localStorage when component loads
  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem('servidoresFiltros'));
    if (savedFilters) {
      setFiltros(savedFilters);
    }
  }, [setFiltros]);

  // Save the filters in localStorage
  useEffect(() => {
    localStorage.setItem('servidoresFiltros', JSON.stringify(filtros));
  }, [filtros]);

  useEffect(() => {
    const fetchEstados = async () => {
      const response = await getEstados();
      setEstados(response.map((estado) => ({
        label: estado.descricao,
        value: estado.descricao,
        id: estado.id
      })));
    };
    fetchEstados();
  }, []);

  useEffect(() => {
    if (estadoSelecionado) {
      const fetchCidades = async () => {
        const response = await getCidades(estadoSelecionado.id);
        setCidades(response.map((cidade) => ({ label: cidade.nome })));
      };
      fetchCidades();
    }
  }, [estadoSelecionado]);



  const handleChange = (event, value, fieldName) => {
    const { name, value: fieldValue, checked, type } = event.target || {};
    setFiltros({
      ...filtros,
      [name || fieldName]: type === 'checkbox' ? checked : (value || fieldValue),
    });
  };

  const handleFilterSubmit = () => {
    handleSubmit();
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportar = async () => {
    await exportarInscricoesFiltroServidores(filtros);
  };

  // Map of internal field names to user-friendly labels
  const filterLabels = {
    nomeCompleto: 'Nome Completo',
    dataNascimento: 'Data de Nascimento',
    cpf: 'CPF',
    uf: 'Estado (UF)',
    cidade: 'Cidade',
    status: 'Status'
  };

  const filtrosAplicados = Object.keys(filtros).filter((key) => filtros[key] && key !== 'skip' && key !== 'limit' && key !== 'tipoFormulario' && key !== 'exportar');

  return (
    <>
      {/* Button Container */}
      <Box display="flex" gap={2} mb={2}>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          startIcon={<FilterAltOutlined />}
          variant="contained"
          size="large"
          color="info"
        >
          Filtro
        </Button>

        {/* Exportar Button */}
        <Button variant="contained" color="secondary" onClick={handleExportar} startIcon={<TaskAlt />} >
          Exportar
        </Button>
      </Box>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Card>
          <CardContent>
            <Grid container spacing={2}>
                 {/* Status */}
              <Grid item xs={12}>
                <Autocomplete
                  options={status}
                  value={filtros.status}
                  onChange={(event, value) => handleChange(event, value, 'status')}
                  renderInput={(params) => <TextField {...params} label="Status" fullWidth />}
                />
              </Grid>

            

             

              {/* Nome Completo */}
              <Grid item xs={6}>
                <TextField label="Nome Completo" name="nomeCompleto" value={filtros.nomeCompleto} onChange={handleChange} fullWidth />
              </Grid>

              {/* CPF */}
              <Grid item xs={6}>
                <TextField label="CPF" name="cpf" value={filtros.cpf} onChange={handleChange} fullWidth inputProps={{ maxLength: 14 }} />
              </Grid>

              {/* Estado */}
              <Grid item xs={6}>
                <Autocomplete
                  options={estados}
                  value={filtros.uf}
                  onChange={(event, newValue) => {
                    handleChange(event, newValue, 'uf');
                    setEstadoSelecionado(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} label="Estado" fullWidth />}
                />
              </Grid>

              {/* Cidade */}
              <Grid item xs={6}>
                <Autocomplete
                  options={cidades}
                  value={filtros.cidade}
                  onChange={(event, newValue) => handleChange(event, newValue, 'cidade')}
                  renderInput={(params) => <TextField {...params} label="Cidade" fullWidth />}
                />
              </Grid>

              {/* Botão de Aplicar Filtros */}
              <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={handleFilterSubmit} fullWidth>
                  Aplicar Filtros
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Menu>

      {/* Renderizar filtros aplicados como tooltips */}
      <Box mt={4}>
        <Grid container spacing={1}>
          {filtrosAplicados.map((filtroKey, index) => (
            <Grid item key={index}>
              <Tooltip title={`${filterLabels[filtroKey]}: ${filtros[filtroKey]}`} arrow>
                <Chip label={`${filterLabels[filtroKey]}: ${filtros[filtroKey]}`} sx={{ backgroundColor: '#1976d2', color: '#fff' }} />
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default FormularioServidoresInscricoesFilter;

import React from 'react';

class DataImpressao extends React.Component {
  render() {

    const { data } = this.props;

    let studentsMap = {};

    if (data !== undefined) {
    data.forEach(trimestre => {
      trimestre.linhas.forEach(student => {
        const { nomeAluno, numeroMatricula, ae, ad, recuperacao, media, faltas } = student;
        if (!studentsMap[numeroMatricula]) {
          studentsMap[numeroMatricula] = { nomeAluno, numeroMatricula, trimesters: [] };
        }
        studentsMap[numeroMatricula].trimesters.push({ ae, ad, recuperacao, media, faltas });
      });
    });
  }

    const students = Object.values(studentsMap);

    return (
      <table border="1" className="striped-table">
        <caption>AE - Avaliação Escrita | AD - Avaliação Diversificada | RP - Recuperação | N.T - Nota Trimestral
        </caption>
        <thead>
          <tr>
            <th rowSpan="2">NOME</th>
            <th rowSpan="2">MATRÍCULA</th>
            <th colSpan="5">1º TRIMESTRE</th>
            <th colSpan="5">2º TRIMESTRE</th>
            <th colSpan="5">3º TRIMESTRE</th>
            <th rowSpan="2">MÉDIA FINAL</th>
            <th rowSpan="2">FALTAS TOTAIS</th>
          </tr>
          <tr>
            <th>AE</th>
            <th>AD</th>
            <th>RP</th>
            <th>N.T</th>
            <th>FALTAS</th>
            
            <th>AE</th>
            <th>AD</th>
            <th>RP</th>
            <th>N.T</th>
            <th>FALTAS</th>
            
            <th>AE</th>
            <th>AD</th>
            <th>RP</th>
            <th>N.T</th>
            <th>FALTAS</th>
          </tr>
        </thead>
        <tbody>
          {students.map((student, index) => (
            <tr key={index}>
              <td>{student.nomeAluno}</td>
              <td>{student.numeroMatricula}</td>
              {student.trimesters.map((trimester, idx) => (
                <React.Fragment key={idx}>
                 <td>{trimester.ae == -1 ? '' : trimester.ae}</td>
                  <td>{trimester.ad == -1 ? '' : trimester.ad}</td>
                  <td>{trimester.recuperacao == -1 ? '' : trimester.recuperacao}</td>
                  <td>{trimester.media == -1 ? '' : trimester.media}</td>
                  <td>{trimester.faltas == -1 ? '' : trimester.faltas}</td>
                </React.Fragment>
              ))}
             <td>{(student.trimesters.reduce((acc, cur) => acc + (cur.media > 0 ? cur.media : 0), 0) / student.trimesters.length).toFixed(2)}</td>
              <td>{student.trimesters.reduce((acc, cur) => cur.faltas > 0 ? acc + cur.faltas : acc, 0)}</td>
            </tr>
          ))}
        </tbody>
      </table>     
    );
    
  }
}

export default DataImpressao;
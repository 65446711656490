import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { createDisciplina, updateDisciplina } from '../../../services/disciplinas';
import upperCaseSimple from '../../../utils/upperCaseSimple';
import removeEmpty from '../../../utils/removeEmpty';
import { TextField, Button, MenuItem, FormControl, InputLabel, Select, CircularProgress, Grid } from '@mui/material';

const DisciplinasForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();

  const { mutate, isLoading } = useMutation(id ? updateDisciplina : createDisciplina, {
    onSuccess: (message) => {
      toast.success(message);
      navigate(-1);
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar cadastrar uma disciplina, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  const { register, handleSubmit, setValue, formState: { isValid, errors }, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      descricao: '',
      sigla: '',
      boletimObrigatorio: true, // Initially set as empty
    },
  });

  const onSubmit = (data) => {
    const formData = {
      ...upperCaseSimple(removeEmpty(data)),
      boletimObrigatorio: data.boletimObrigatorio === 'true', // Convert string to boolean
    };

    if (id) {
      mutate({ id, data: formData });
    } else {
      mutate(formData);
    }
  };

  // Effect to populate form with data from API
  useEffect(() => {
    if (state) {
      const { disciplina } = state;
      if (id && disciplina) {
        const { descricao, sigla, boletimObrigatorio } = disciplina;
        setValue('descricao', descricao);
        setValue('sigla', sigla);
        setValue('boletimObrigatorio', boletimObrigatorio ? 'true' : 'false'); // Set string "true" or "false"
      }
    }
  }, [id, state, setValue]);

  // Watch value of boletimObrigatorio to control the Select value
  const boletimObrigatorioValue = watch('boletimObrigatorio');

  console.log(boletimObrigatorioValue)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {/* Descrição Field */}
        <Grid item xs={12} md={4}>
          <TextField
            label="Descrição"
            variant="outlined"
            fullWidth
            margin="normal"
            {...register('descricao', { required: true })}
            error={!!errors.descricao}
            helperText={errors.descricao ? 'Campo obrigatório!' : ''}
          />
        </Grid>

        {/* Sigla Field */}
        <Grid item xs={12} md={4}>
          <TextField
            label="Sigla"
            variant="outlined"
            fullWidth
            margin="normal"
            {...register('sigla', { required: true })}
            error={!!errors.sigla}
            helperText={errors.sigla ? 'Campo obrigatório!' : ''}
          />
        </Grid>

        {/* Boletim Obrigatório Select */}
        <Grid item xs={12} md={4}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="boletim-obrigatorio-label">Boletim Obrigatório</InputLabel>
            <Select
              labelId="boletim-obrigatorio-label"
              id="boletim-obrigatorio"
              label="Boletim Obrigatório"
              value={boletimObrigatorioValue} // Use the watched value from react-hook-form
              {...register('boletimObrigatorio', { required: true })}
              error={!!errors.boletimObrigatorio}
            >
              <MenuItem value="true">SIM</MenuItem>
              <MenuItem value="false">NÃO</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={!isValid || isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : id ? 'Atualizar' : 'Cadastrar'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default DisciplinasForm;

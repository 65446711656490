// src/components/UpdateModal.js
import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Select, MenuItem, Button } from '@mui/material';

const FormularioServidoresInscricoesAtualizar = ({ open, onClose, onSave, selectedRow, status, setStatus, observacao, setObservacao }) => {
  if (!selectedRow) return null;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Atualizar Inscrição</DialogTitle>
      <DialogContent>
        <TextField
          label="ID"
          value={selectedRow.id}
          fullWidth
          margin="dense"
          InputProps={{
            readOnly: true,
          }}
        />
        <Select
          label="Status"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          fullWidth
          margin="dense"
        >
          <MenuItem value="EM ANÁLISE">EM ANÁLISE</MenuItem>
          <MenuItem value="CANCELADO">CANCELADO</MenuItem>
          <MenuItem value="DEFERIDO">DEFERIDO</MenuItem>
          <MenuItem value="INDEFERIDO">INDEFERIDO</MenuItem>
        </Select>
        <TextField
          label="Observação"
          value={observacao}
          onChange={(e) => setObservacao(e.target.value)}
          fullWidth
          margin="dense"
          multiline
          rows={3}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={onSave} color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormularioServidoresInscricoesAtualizar;

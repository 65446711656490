import React, { useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import FormInput from '../../../../../../../components/formInput';

export default function Contrato({ register, errors, setValue, canEdit, watch }) {
  const { id } = useParams()
  const { state } = useLocation();


  return (
    <div
      className="tab-pane fade show active"
      id="contrato"
      role="tabpanel"
      aria-labelledby="contrato-tab"
    >
      <div className="card-body">
        <div className="row">
          {id && (
            <div className="col-md-1">
              <FormInput
                register={register}
                errors={errors}
                atribute="id"
                label="ID"
                readOnly={true}
                required={false}
              />
            </div>
          )}

          <div className={id ? "col-md-3" : "col-md-4"}>
            <FormInput
              register={register}
              errors={errors}
              atribute="identificador"
              label="Número identificador do contrato"
              required={false}
            />
          </div>
          <div className="col-md-4">
            <FormInput
              register={register}
              errors={errors}
              atribute="contratante"
              label="Contratante"
              required={false}
            />
          </div>
          <div className="col-md-4">
            <FormInput
              register={register}
              errors={errors}
              atribute="contratado"
              label="Contratado"
              required={false}
            />
          </div>


        </div>
      </div>
    </div>
  )
}

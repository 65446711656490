import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import FormInput from '../../../../../../../components/formInput'
import { getNutricaoProdutosById, postNutricaoProduto, putNutricaoProduto, getNutricaoGrupoProdutosCombo, getNutricaoUnidadeMedidaCombo } from '../../../../../../../services/nutricao'
import { useQuery, useMutation } from 'react-query'
import urlsAdministrativo from '../../../../../urlsAdministrativo'
import { toast } from 'react-toastify'
import upperCaseSimple from '../../../../../../../utils/upperCaseSimple'
import removeEmpty from '../../../../../../../utils/removeEmpty'

export default function NutricaoProdutos() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { state } = useLocation();
  const [data, setData] = useState(null)
  const [grupos, setGrupos] = useState([])

  const { data: produtoData } = useQuery('getNutricaoProdutosById', () => id && getNutricaoProdutosById(id), {
    enabled: id ? true : false,
    onSuccess: data => {
      setData(produtoData);
      setValue('id', id)
      setValue('nomeProduto', produtoData.nome);
      setValue('categoriaProdutoId', parseInt(produtoData.categoriaId));
      setValue('descricao', produtoData.descricao);
      setValue('fabricante', produtoData.fabricante);
      setValue('valor', produtoData.valor);
      setValue('quantidade', produtoData.quantidade);
      setValue('codigo', produtoData.codigo);
      setValue('dataValidade', produtoData.dataValidade.split('T')[0]);
      setValue('unidadeMedidaId', parseInt(produtoData.unidadeMedidaId));
      setValue('agua', produtoData.agua);
      setValue('carboidratos', produtoData.carboidratos);
      setValue('gorduras', produtoData.gorduras);
      setValue('proteinas', produtoData.proteinas);
    }
  })

  const { data: gruposData, isFetched: gruposFetched } = useQuery('getNutricaoGrupoProdutosCombo', () => getNutricaoGrupoProdutosCombo(), {
    enabled: true,
  })

  const { data: unidadeMedidaData, isFetched: unidadeMedidaFetched } = useQuery('getNutricaoUnidadeMedidaCombo', () => getNutricaoUnidadeMedidaCombo(), {
    enabled: true,
  })

  const { mutate, isLoading } = useMutation(id ? putNutricaoProduto : postNutricaoProduto, {
    onSuccess: data => {
      toast.success(id ? 'Produto atualizado com sucesso.' : 'Produto criado com sucesso.');
      navigate(urlsAdministrativo.nutricaoProduto);
    },
    onError: data => {
      toast.error(id ? 'Erro ao atualizar produto. Tente novamente mais tarde.' : 'Erro ao criar produto. Tente novamente mais tarde.');
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { isValid, errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      nomeProduto: '',
      categoriaProdutoId: 0,
      descricao: '',
      fabricante: '',
      // numeroSerie: '',
      valor: 0,
      quantidade: 0,
      unidadeMedidaId: 0,
      agua: '',
      carboidratos: '',
      gorduras: '',
      proteinas: ''
    }
  })

  useEffect(() => {
    if (state && gruposFetched && id && unidadeMedidaFetched && data) {
      setValue('id', id)
      setValue('nomeProduto', data?.nome);
      setValue('categoriaProdutoId', parseInt(data?.categoriaId));
      setValue('descricao', data?.descricao);
      setValue('fabricante', data?.fabricante);
      // setValue('numeroSerie', state?.data?.numeroSerie);
      setValue('valor', data?.valor);
      setValue('quantidade', data?.quantidade);
      setValue('codigo', data?.codigo);
      setValue('dataValidade', data?.dataValidade.split('T')[0]);
      setValue('unidadeMedidaId', parseInt(data?.unidadeMedidaId));
      setValue('agua', data?.agua);
      setValue('carboidratos', data?.carboidratos);
      setValue('gorduras', data?.gorduras);
      setValue('proteinas', data?.proteinas);
    }
  }, [state, gruposData, gruposFetched, unidadeMedidaData, unidadeMedidaFetched])

  const onSubmit = (data) => {
    const request = {
      nome: data.nomeProduto,
      categoriaId: parseInt(data.categoriaProdutoId),
      descricao: data.descricao,
      fabricante: data.fabricante,
      // numeroSerie: data.numeroSerie,
      valor: parseFloat(data.valor),
      quantidade: parseFloat(data.quantidade),
      codigo: data.codigo,
      dataValidade: data.dataValidade,
      unidadeMedidaId: parseInt(data.unidadeMedidaId),
      agua: data.agua,
      carboidratos: data.carboidratos,
      gorduras: data.gorduras,
      proteinas: data.proteinas,
    }

    if (id) {
      mutate({ id: id, data: upperCaseSimple(removeEmpty(request)) })
    } else {
      mutate(upperCaseSimple(removeEmpty(request)))
    }
  }

  return (
    <div
      className="tab-pane fade show active"
      id="produtos"
      role="tabpanel"
      aria-labelledby="produtos-tab"
    >
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="card-body">
          <div className="row">
            {id && (
              <div className="col-md-1">
                <FormInput
                  register={register}
                  errors={errors}
                  atribute="id"
                  label="ID"
                  readOnly={true}
                  required={false}
                />
              </div>
            )}

            <div className={id ? "col-md-8" : "col-md-9"}>
              <FormInput
                register={register}
                errors={errors}
                atribute="nomeProduto"
                label="Nome do Produto"
              />
            </div>

            <div className="col-md-3">
              <label htmlFor="categoriaProdutoId">Categoria do Produto <span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
              <select className="form-select" id="categoriaProdutoId" {...register("categoriaProdutoId", { required: true })}>
                <option>SELECIONE</option>
                {gruposData?.map(grupo => (
                  <option key={grupo.id} value={grupo.id}>
                    {grupo.descricao}
                  </option>
                ))}
              </select>
              {errors.categoriaProduto?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
            </div>
          </div>

          <div className="row" style={{ marginTop: 30 }}>

            <div className="col-md-12">
              <FormInput
                register={register}
                errors={errors}
                atribute="descricao"
                label="Descrição"
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: 30 }}>

            <div className="col-md-5">
              <FormInput
                register={register}
                errors={errors}
                atribute="fabricante"
                label="Fabricante"
              />
            </div>
            {/* <div className="col-md-3">
              <FormInput
                register={register}
                errors={errors}
                atribute="numeroSerie"
                label="Número de série"
              />
            </div> */}
            <div className="col-md-2">
              <FormInput
                register={register}
                errors={errors}
                atribute="valor"
                label="Valor"
              />
            </div>
            <div className="col-md-2">
              <FormInput
                register={register}
                errors={errors}
                type='number'
                atribute="quantidade"
                label="Quantidade"
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: 30 }}>
            <div className="col-md-4">
              <FormInput
                register={register}
                errors={errors}
                atribute="codigo"
                label="Código"
              />
            </div>
            <div className="col-md-4">
              <FormInput
                register={register}
                errors={errors}
                type='date'
                atribute="dataValidade"
                label="Data de validade"
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="unidadeMedidaId">Unidade de medida <span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
              <select className="form-select" id="unidadeMedidaId" {...register("unidadeMedidaId", { required: true })}>
                <option>SELECIONE</option>
                {unidadeMedidaData?.map(unidadeMedida => (
                  <option key={unidadeMedida.id} value={unidadeMedida.id}>
                    {unidadeMedida.descricao} - {unidadeMedida.sigla}
                  </option>
                ))}
              </select>
              {errors.unidadeMedida?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
            </div>

            <div className="row" style={{ marginTop: 30, marginBottom:20 }}>
              <div className="col-md-3">
                <FormInput
                  register={register}
                  errors={errors}
                  atribute="agua"
                  label="Agua"
                />
              </div>
              <div className="col-md-3">
                <FormInput
                  register={register}
                  errors={errors}
                  atribute="carboidratos"
                  label="Carboidratos"
                />
              </div>
              <div className="col-md-3">
                <FormInput
                  register={register}
                  errors={errors}
                  atribute="gorduras"
                  label="Gorduras"
                />
              </div>
              <div className="col-md-3">
                <FormInput
                  register={register}
                  errors={errors}
                  atribute="proteinas"
                  label="Proteínas"
                />
              </div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end'
            }}
          >
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => navigate(urlsAdministrativo.nutricaoProduto)}
              style={{ marginRight: '8px' }}
            >
              Voltar
            </button>

            <button
              type="submit"
              className="btn btn-success"
              disabled={!isValid}
            >
              {id ? 'Atualizar' : 'Cadastrar'}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

import api from "../..";

async function deleteConta(id) {
  return await api
    .delete(`financeiro/contaspagar/${id}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function getContas() {
  return await api
    .get('financeiro/contaspagar')
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}


export {
  deleteConta,
  getContas,
}
import api from "../..";

async function postProduto(data) {
  return await api
    .post('patrimonio/produto', data)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
};

async function getProdutos() {
  return await api
    .get('patrimonio/produto/')
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function getProduto(id) {
  return await api
    .get(`patrimonio/produto/${id}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function putProduto({ id, data }) {
  return await api
    .put(`patrimonio/produto/${id}`, data)
    .then(async (res) => {
      const data = res.data.data;
      return data;
    })
}

async function deleteProduto(id) {
  return await api
    .delete(`patrimonio/produto/${id}`)
    .then(async (res) => {
      const data = res.data.data;
      return data;
    })
}

async function getGrupoProdutos() {
  return await api
    .get('patrimonio/grupoprodutos')
    .then(async (res) => {
      return res.data.data
    })
}

async function getUnidadeMedida() {
  return await api
    .get('patrimonio/produtos/unidademedidas')
    .then(async (res) => {
      return res.data.data
    })
}

export {
  postProduto,
  getProdutos,
  getProduto,
  putProduto,
  deleteProduto,
  getGrupoProdutos,
  getUnidadeMedida
}
import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style.css';
import { LocationApi } from '../../../../services/external/cep';
import { getUnidades } from '../../../../services/unidades';
import { validate as isCPFValid } from 'cpf-check';

const escolaridades = [
  { value: 0, label: '' },
  { value: 1, label: '1º ANO' },
  { value: 2, label: '2º ANO' },
  { value: 3, label: '3º ANO' },
  { value: 4, label: '4º ANO' },
  { value: 5, label: '5º ANO' },
  { value: 6, label: '6º ANO' },
  { value: 7, label: '7º ANO' },
  { value: 8, label: '8º ANO' },
  { value: 9, label: '9º ANO' },
  { value: 10, label: 'CRECHE I' },
  { value: 11, label: 'CRECHE II' },
  { value: 12, label: 'CRECHE III' },
  { value: 13, label: 'CRECHE IV' },
  { value: 24, label: 'PRÉ I' },
  { value: 26, label: 'PRÉ II' }
];

const deficiencias = [
  { value: 0, label: '' },
  { value: 1, label: 'DEFICIENCIAS MÚLTIPLAS' },
  { value: 2, label: 'DEFICIÊNCIA INTELECTUAL' },
  { value: 3, label: 'DEFICIÊNCIA VISUAL' },
  { value: 4, label: 'DEFICIÊNCIA AUDITIVA' },
  { value: 5, label: 'DEFICIÊNCIA FÍSICA' },
  { value: 6, label: 'TRANSTORNO DO ESPECTRO AUTISTA (TEA)' },
  { value: 7, label: 'ALTAS HABILIDADES' }
];

const TransporteFormularioPage = () => {
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm({
    mode: 'onChange',
    defaultValues: {
      nome: '',
      filiacao1: '',
      filiacao2: '',
      dataNascimento: '',
      escola: '',
      serie: '',
      turno: '',
      necessidadeEspecial: '',
      descricaoNecessidade: '',
      rua: '',
      numero: '',
      apto: '',
      bairro: '',
      cep: '',
      telefone: '',
      email: '',
      autorizados: [{ nome: '', rua: '', bairro: '', cidade: '', uf: '', cep: '', telefone: '', parentesco: '' }],
      emergencia: { nome: '', telefone: '', parentesco: '' }
    }
  });

  const [unidades, setUnidades] = useState([]);
  const [exibeAvisoEndereco, setExibeAvisoEndereco] = useState(0);
  const { current: locationApi } = useRef(new LocationApi());

  useEffect(() => {
    const fetchUnidades = async () => {
      const response = await getUnidades();
      setUnidades(response.data.map(item => ({ value: item.id, label: item.nome })));
    };
    fetchUnidades();
  }, []);

  const handleAutoCompleteForm = useCallback(async (cep) => {
    if (cep.length < 8) return;
    try {
      const data = await locationApi.searchByCep(cep);
      const { cidade, uf, bairro, logradouro } = data.data.data;

      if (data.status === 400) {
        toast.warn(data.data.message);
      }

      if (cidade !== 'São Pedro da Aldeia') {
        setExibeAvisoEndereco(1);
      } else {
        setExibeAvisoEndereco(0);
      }

      setValue('bairro', bairro);
      setValue('cidade', cidade);
      setValue('estado', uf);
      setValue('logradouro', logradouro);
    } catch (error) {
      toast.error('Não foi possível carregar o endereço, cep não encontrado na base de dados do correio.');
    }
  }, [setValue]);

  useEffect(() => {
    const cep = watch('cep');
    if (cep) {
      handleAutoCompleteForm(cep.replace(/\D/g, ''));
    }
  }, [watch('cep'), handleAutoCompleteForm]);

  const onSubmit = data => {
    console.log(data);
    // Envie os dados do formulário aqui
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      padding: '24px 48px',
    }}>
      <img src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`} width="200" height="150" style={{ display: 'flex', alignSelf: 'center' }} />
      <h3 style={{ margin: '15px', alignSelf: 'center' }}>SECRETARIA DE EDUCAÇÃO - PREFEITURA MUNICIPAL DE SÃO PEDRO</h3>
      <h2 style={{ margin: '15px', alignSelf: 'center' }}>TRANSPORTE</h2>
  </div>
  );
};

export default TransporteFormularioPage;

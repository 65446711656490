import React, { useState, useEffect } from 'react';
import { Grid, Button, IconButton, Paper, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { getEscolaridades } from '../../../services/escolaridades';

import { getDisciplinasEscolaridade, postDisciplinaEscolaridade, deleteDisciplinaEscolaridade } from '../../../services/disciplinas';
import { useParams } from 'react-router-dom';
import TableComponent from '../../../components/novaTabela';
import { Autocomplete, TextField } from '@mui/material';

const DisciplinaEscolaridadeTab = () => {
  const { id } = useParams(); // Get the id from the route params
  const [escolaridades, setEscolaridades] = useState([]);
  const [selectedEscolaridade, setSelectedEscolaridade] = useState(null);
  const [addedEscolaridades, setAddedEscolaridades] = useState([]);

  useEffect(() => {
    // Fetch available escolaridades when the component mounts
    const fetchEscolaridades = async () => {
      try {
        const response = await getEscolaridades();
        setEscolaridades(response);
      } catch (error) {
        console.error('Error fetching escolaridades:', error);
      }
    };

    // Fetch already added escolaridades for the table
    const loadEscolaridadesDisciplinas = async () => {
      try {
        const response = await getDisciplinasEscolaridade(id);
        setAddedEscolaridades(response);
      } catch (error) {
        console.error('Error fetching escolaridades for disciplinas:', error);
      }
    };

    fetchEscolaridades();
    if (id) {
      loadEscolaridadesDisciplinas();
    }
  }, [id]);

  // Filter the available escolaridades that are not already added
  const availableEscolaridades = escolaridades.filter(
    (escolaridade) => !addedEscolaridades.some(
      (added) => parseInt(added.escolaridadeId) === escolaridade.id
    )
  );

  const handleAddEscolaridade = async () => {
    if (selectedEscolaridade) {
      try {
        const data = {
          disciplinaId: parseInt(id),
          escolaridadeId: selectedEscolaridade.id,
        };
        await postDisciplinaEscolaridade(data);
        // Add the selected escolaridade to the table
        const response = await getDisciplinasEscolaridade(id);
        setAddedEscolaridades(response);
      } catch (error) {
        console.error('Error adding escolaridade:', error);
      }
    }
  };

  const handleRemoveEscolaridade = async (escolaridadeId) => {
    await deleteDisciplinaEscolaridade(escolaridadeId);
    const response = await getDisciplinasEscolaridade(id);
    setAddedEscolaridades(response);
  };

  // Columns for the table component
  const columns = [
    {
        Header: '#',
        accessor: 'id',
    },
    {
      Header: 'Descrição',
      accessor: 'escolaridadeDescricao',
    },
    {
      Header: 'Ações',
      accessor: 'actions',
      Cell: ({ row }) => (
        <IconButton
          variant="contained"
          color="secondary"
          onClick={() => handleRemoveEscolaridade(row.original.id)}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <div>
      {/* Select and add button */}
      <Grid container spacing={2}>
        {/* Autocomplete select for escolaridades */}
        <Grid item xs={12}>
          <Autocomplete
            options={availableEscolaridades}
            getOptionLabel={(option) => `${option.descricao}`}
            value={selectedEscolaridade}
            onChange={(event, newValue) => setSelectedEscolaridade(newValue)}
            renderInput={(params) => <TextField {...params} label="Escolaridade" variant="outlined" fullWidth />}
          />
        </Grid>

        {/* Add button */}
        <Grid item xs={12} style={{marginBottom: '20px'}}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddEscolaridade}
            disabled={!selectedEscolaridade}
            startIcon={<SaveIcon />}
            fullWidth
          >
            Adicionar
          </Button>
        </Grid>
      </Grid>

      {/* Table displaying the added escolaridades */}
      <TableComponent
        columns={columns}
        data={addedEscolaridades}
        hasPagination={false} // Disable pagination if not needed
      />
    </div>
  );
};

export default DisciplinaEscolaridadeTab;

import api from '../index'

async function botaoPanico(data) {
  return await api
    .post('shared/notificacao/panico', data)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

export {
  botaoPanico
}
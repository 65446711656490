import React, { useState, useMemo, useEffect } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import Table from '../../../../../../../components/table'
import { useMutation, useQuery } from 'react-query'
import { getLancamentoImpressao } from '../../../../../../../services/administrativo/patrimonio'

export default function Historico({ register, errors, setValue, canEdit, watch }) {
  const { id } = useParams()
  const navigate = useNavigate()
  const { state } = useLocation();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [impressorasLancamentoData, setImpressorasLancamentoData] = useState()

  const { data: lancamentosUnidades } = useQuery('getLancamentoImpressao', () => getLancamentoImpressao(id, skip, limit), {
    retry: true,
    onSuccess: (data) => {
      setImpressorasLancamentoData(data)
    }
  })

  useEffect(() => {
    setImpressorasLancamentoData(lancamentosUnidades)
  }, [lancamentosUnidades])

  const formatDate = (date) => {
    const dataObj = new Date(date);
    const dia = dataObj.getDate().toString().padStart(2, '0');
    const mes = (dataObj.getMonth() + 1).toString().padStart(2, '0');
    const ano = dataObj.getFullYear();

    return `${dia}-${mes}-${ano}`;
  }

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Unidade', accessor: 'unidadeNome' },
      { Header: 'Mês', accessor: 'mesExtenso' },
      { Header: 'Data Lançamento', accessor: formatDate('dataLancamento') },
      { Header: 'Cópias', accessor: 'copias' },
    ],
    [navigate]
  );


  return (
    <div
      className="tab-pane fade"
      id="historico"
      role="tabpanel"
      aria-labelledby="historico-tab"
    >
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <Table
                columns={columns}
                data={impressorasLancamentoData?.data || []}
                hasPagination
                limit={limit}
                setLimit={setLimit}
                skip={skip}
                setSkip={setSkip}
                totalItems={0}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from "react";

class DataImpressao extends React.Component {
    render() {

    let studentsMap = {};

    const { data } = this.props;
     

    if (data !== undefined) {
        data.forEach(trimestre => {
            trimestre.linhas.forEach(student => {
              const { nomeAluno, numeroMatricula, linguaPortuguesa, matematica, ciencias, geografia, historia, artes, educacaoFisica, total } = student;
              if (!studentsMap[numeroMatricula]) {
                studentsMap[numeroMatricula] = { nomeAluno, numeroMatricula, trimesters: [] };
              }
              studentsMap[numeroMatricula].trimesters.push({  linguaPortuguesa, matematica, ciencias, geografia, historia, artes, educacaoFisica, total});
            });
          });
  }

  const students = Object.values(studentsMap);

    return (
        <table border="1" className="striped-table">
          {/* <caption>HA - Hora Aula | DL - Dias Letivos
          </caption> */}
          <thead>
          <tr>
          <th rowSpan="2">NOME</th>
            <th rowSpan="2">MATRÍCULA</th>
            <th colSpan="8">1º TRIMESTRE</th>
            <th colSpan="8">2º TRIMESTRE</th>
            <th colSpan="8">3º TRIMESTRE</th>
            <th colSpan={4}>TOTAL ANUAL</th>
          </tr>
        
          <tr>
          <th>LP</th>
            <th>MAT</th>
            <th>CIÊN</th>
            <th>HIST</th>
            <th>GEO</th>
            <th>ARTE</th>
            <th>ED FÍS</th>
            <th>Total</th>
            <th>LP</th>
            <th>MAT</th>
            <th>CIÊN</th>
            <th>HIST</th>
            <th>GEO</th>
            <th>ARTE</th>
            <th>ED FÍS</th>
            <th>Total</th>
            <th>LP</th>
            <th>MAT</th>
            <th>CIÊN</th>
            <th>HIST</th>
            <th>GEO</th>
            <th>ARTE</th>
            <th>ED FÍS</th>
            <th>Total</th>
            <th>TOTAL FALTAS/ HA</th>
            <th>TOTAL FALTAS / DIA</th>
            <th>%FR.:</th>
            <th>Decisão</th>
          </tr>
          </thead>
          <tbody>
          {students.map((student, index) => (
            <tr key={index}>
              <td>{student.nomeAluno}</td>
              <td>{student.numeroMatricula}</td>
              {student.trimesters.map((trimester, idx) => (
                <React.Fragment key={idx}>
                 <td>{trimester.linguaPortuguesa !== -1 ? trimester.linguaPortuguesa : ''}</td>
                <td>{trimester.matematica !== -1 ? trimester.matematica : ''}</td>
                <td>{trimester.ciencias !== -1 ? trimester.ciencias : ''}</td>
                <td>{trimester.historia !== -1 ? trimester.historia : ''}</td>
                <td>{trimester.geografia !== -1 ? trimester.geografia : ''}</td>
                <td>{trimester.artes !== -1 ? trimester.artes : ''}</td>
                <td>{trimester.educacaoFisica !== -1 ? trimester.educacaoFisica : ''}</td>
                <td>{trimester.total !== -1 ? trimester.total : ''}</td>
                </React.Fragment>
              ))}
               {/* <td>{calcularSomaTrimestres(data)}</td> */}
               <td>{student.trimesters.reduce((acc, cur) => acc + cur.total, 0)}</td>
               <td>{student.trimesters.reduce((acc, cur) => acc + cur.total, 0) / 5}</td>
               <td>{(200 - student.trimesters.reduce((acc, cur) => acc + cur.total, 0) / 5) * 100 / 200}</td>
               <td>
  <span className={`badge bg-${((200 - student.trimesters.reduce((acc, cur) => acc + cur.total, 0) / 5) * 100 / 200) >= 75 ? 'success' : 'danger'}`}>
    {((200 - student.trimesters.reduce((acc, cur) => acc + cur.total, 0) / 5) * 100 / 200) >= 75 ? 'APROVADO' : 'REPROVADO'}
  </span>
</td>
            </tr>
          ))}
        </tbody>
        </table>
    )
}
}

export default DataImpressao;

import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import BaseAdministrativo from '../../../baseAdministrativo'
import Cadastro from './tab/cadastro'
import Contato from './tab/contato'
import Contrato from './tab/contrato'


const ControleFornecedoresForm = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { state } = useLocation()
  const [canEdit, setCanEdit] = useState(id ? false : true)
  const [isHistoricTab, setIsHistoricTab] = useState(false)

  // const {
  //   register,
  //   handleSubmit,
  //   setValue,
  //   getValues,
  //   watch,
  //   formState: { isValid, errors }
  // } = useForm({
  //   mode: 'all',
  //   defaultValues: {
  //     id: 1,
  //     cadastro: {
  //       nomeFantasia: '',
  //       cnpj: ''
  //     },
  //     contato: {
  //       email: '',
  //       telefone: '',
  //       cep: '',
  //       numero: '',
  //       bairro: '',
  //       complemento: '',
  //       estado: '',
  //       cidade: ''
  //     },
  //     contrato: {
  //       areaAtuacao: '',
  //       produtosServicos: '',
  //       referenciasIndicacoes: '',
  //       clausulasEspeciais: ''
  //     }

  //   }
  // })

  // useMemo(() => {
  //   (async () => {
  //     if (state) {
  //       const { teste } = state
  //       if (id && teste) {
  //         const { cadastro, contato, contrato } =
  //           teste
  //         // Ifs responsáveis por atribuir os valores existentes nos campos do formulário
  //         if (cadastro) {
  //           setValue('id', id)
  //           setValue('cadastro.nomeFantasia', cadastro.nomeFantasia)
  //           setValue('cadastro.cnpj', cadastro.cnpj)

  //         }
  //         if (contato) {
  //           setValue('contato.email', contato.email)
  //           setValue('contato.telefone', contato.telefone)
  //           setValue('contato.cep', contato.cep)
  //           setValue('contato.logradouro', contato.logradouro)
  //           setValue('contato.numero', parseInt(contato.numero))
  //           setValue('contato.bairro', contato.bairro)
  //           setValue('contato.complemento', contato.complemento)
  //           setValue('contato.estado', contato.estado)
  //           setValue('contato.cidade', contato.cidade)

  //         }
  //         if (contrato) {
  //           setValue('contrato.areaAtuacao', contrato.areaAtuacao)
  //           setValue('contrato.produtosServicos', contrato.produtosServicos)
  //           setValue('contrato.referenciasIndicacoes', contrato.referenciasIndicacoes)
  //           setValue('contrato.clausulasEspeciais', contrato.clausulasEspeciais)
  //         }
  //       }
  //     }
  //   })()
  // }, [id, state, setValue])

  // // ao cancelar edição mantém os dados já recebidos no state;
  // const cancelEdit = () => {
  //   if (state) {
  //     const { teste } = state
  //     if (id && teste) {
  //       const { cadastro, contato, contrato } =
  //         teste
  //       // Ifs responsáveis por atribuir os valores existentes nos campos do formulário
  //       if (cadastro) {
  //         setValue('id', id)
  //         setValue('cadastro.nomeFantasia', cadastro.nomeFantasia)
  //         setValue('cadastro.cnpj', cadastro.cnpj)

  //       }
  //       if (contato) {
  //         setValue('contato.email', contato.email)
  //         setValue('contato.telefone', contato.telefone)
  //         setValue('contato.cep', contato.cep)
  //         setValue('contato.logradouro', contato.logradouro)
  //         setValue('contato.numero', parseInt(contato.numero))
  //         setValue('contato.bairro', contato.bairro)
  //         setValue('contato.complemento', contato.complemento)
  //         setValue('contato.estado', contato.estado)
  //         setValue('contato.cidade', contato.cidade)

  //       }
  //       if (contrato) {
  //         setValue('contrato.areaAtuacao', contrato.areaAtuacao)
  //         setValue('contrato.produtosServicos', contrato.produtosServicos)
  //         setValue('contrato.referenciasIndicacoes', contrato.referenciasIndicacoes)
  //         setValue('contrato.clausulasEspeciais', contrato.clausulasEspeciais)
  //       }
  //     }
  //   }
  //   setCanEdit(false)
  // }

  return (
    <BaseAdministrativo>
      <div className="row">
        <div className="mailbox-container">
          <div className="card">
            <div className="container-fluid">
              <div className="row">
                <div className="mailbox-open-content col-md-12 m-0 p-3">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="cadastro-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#cadastro"
                        type="button"
                        role="tab"
                        aria-controls="cadastro"
                        aria-selected="true"
                        onClick={() => setIsHistoricTab(false)}
                      >
                        Cadastro
                      </button>
                    </li>
                    {id && <>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="contato-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#contato"
                          type="button"
                          role="tab"
                          aria-controls="contato"
                          aria-selected="false"
                          onClick={() => setIsHistoricTab(false)}
                        >
                          Contato
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="contrato-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#contrato"
                          type="button"
                          role="tab"
                          aria-controls="contrato"
                          aria-selected="false"
                          onClick={() => setIsHistoricTab(false)}
                        >
                          Contrato
                        </button>
                      </li>
                    </>
                    }


                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <Cadastro />
                    <Contato />
                    <Contrato />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseAdministrativo>
  )

};

export default ControleFornecedoresForm;
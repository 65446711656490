import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFF;
  padding: 24px 48px;
  width: 85vw;
  justify-content: center;
  margin-top: 15px;
`;

export const FormHeader = styled.h5`
  color: #212529;
  font-size: 20px;
  align-self: center;
`;

export const LineDivider = styled.hr`
  width: 100%;
`

export const Row = styled.div`
  display: flex;
`;

export const PrintButton = styled.button`
  display: flex;
  justify-content: center;
  width: 100px;
  border: 0.5px solid white;
  background: brown;
  color: white;
  border-radius: 5px;
  padding: 5px;

  @media print {
    display: none;
  }
`;

export const TableButton = styled.button`
  @media print {
    display: none;
  }
`;
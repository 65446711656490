import UrlsProcessoSeletivoServidores from './UrlsProcessoSeletivoServidores';

// Import Material UI icons
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import FolderIcon from '@mui/icons-material/Folder';

const LinksProcessoSeletivoServidores = () => {
    return {
        serivdores: {
            id: 'servidores',
            text: 'SERVIDORES',
            type: 'title',
            visible: true,
            itens: {
                dashboard: {
                    id: 'dashboard',
                    text: 'DASHBOARD',
                    path: UrlsProcessoSeletivoServidores.dashboard,
                    icon: 'dashboard',
                    visible: true,
                },
                inscricoes: {
                    id: 'inscricoes',
                    text: 'INSCRIÇÕES',
                    path: UrlsProcessoSeletivoServidores.inscricoes,
                    icon: 'group',
                    visible: true,
                },
            }
        },
        formularios: {
            id: 'formularios',
            text: 'FORMULÁRIOS',
            type: 'title',
            visible: true,
            itens: {
                formulario:{
                    id: 'formulario',
                    text: 'FORMULÁRIO',
                    iconName: '',
                    icon: 'folder',  // Icon for Formulário
                    visible: true,
                    path: UrlsProcessoSeletivoServidores.formulario,
                },
            }
        }
    };
};

export default LinksProcessoSeletivoServidores;

import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Estilos customizados para um layout mais moderno e minimalista
const useStyles = makeStyles(() => ({
  dialogTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    color: '#333',
    marginBottom: '1rem',
  },
  dialogContent: {
    textAlign: 'center',
    fontSize: '1.1rem',
    color: '#666',
    padding: '20px',
  },
  dialogActions: {
    justifyContent: 'center',
    padding: '16px',
  },
  closeButton: {
    backgroundColor: '#1976d2',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1565c0',
    },
    padding: '8px 24px',
    fontSize: '1rem',
    textTransform: 'none',
    borderRadius: '20px',
  },
}));

const DialogAmbienteNaoProdutivo = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
      const isDialogDismissed = localStorage.getItem('ambienteNaoProdutivoDismissed');
      if (!isDialogDismissed) {
        setOpen(true);
      }
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem('ambienteNaoProdutivoDismissed', 'true');
  };

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ style: { borderRadius: '12px', padding: '10px' } }}>
      <DialogTitle className={classes.dialogTitle}>
        Atenção
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body1">
          Este ambiente não é produtivo, apenas de teste. Por favor, tenha isso em mente ao utilizar este sistema.
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleClose} className={classes.closeButton}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogAmbienteNaoProdutivo;

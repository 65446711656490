import BaseGestao from "../../gestao/baseGestao";
import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import Breadcrumb from "../../../components/breadcrumb";
import { getEscolaridades } from "../../../services/escolaridades";
import { getBnccByEscolaridadeAnoFaixa } from "../../../services/bncc-infantil";
import { Autocomplete, TextField, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { useEffect, useState, useMemo } from "react";

const BnccInfantilPage = ({ modulo }) => {
    let Base;
    let BasePath;
    const [escolaridadeOptions, setEscolaridadeOptions] = useState([]);
    const [selectedEscolaridade, setSelectedEscolaridade] = useState(null);
    const [selectedClassificacao, setSelectedClassificacao] = useState(null);
    const [selectedComponente, setSelectedComponente] = useState(null);
    const [tableData, setTableData] = useState([]);

    // Define componente options
    const componenteOptions = [
        "CORPO, GESTOS E MOVIMENTOS",
        "ESPAÇOS, TEMPOS, QUANTIDADES, RELAÇÕES E TRANSFORMAÇÕES",
        "O EU, O OUTRO E O NÓS",
        "TRAÇOS, SONS, CORES E FORMAS",
        "ESCUTA, FALA, PENSAMENTO E IMAGINAÇÃO"
    ];

    const classificacaoOptionsForCreche = ["BEBES", "CRIANÇAS BEM PEQUENAS"];
    const classificacaoOptionsForPreEscola = ["CRIANÇAS PEQUENAS"];

    if (modulo === "gestao") {
        Base = BaseGestao;
        BasePath = "Gestão";
    }
    if (modulo === "gestao-unidades") {
        Base = BaseGestaoUnidades;
        BasePath = "Gestão Unidades";
    }

    // Load escolaridade options
    useEffect(() => {
        getEscolaridades().then((response) => {
            const filteredEscolaridades = response
                .filter((item) => 
                    typeof item.descricao === "string" &&
                    (item.descricao.toLowerCase().includes("creche") || 
                     item.descricao.toLowerCase().includes("pre") || 
                     item.descricao.toLowerCase().includes("pré"))
                )
                .map((item) => item.descricao); // Extract only the descricao field for options
            setEscolaridadeOptions(filteredEscolaridades);
        }).catch((error) => {
            console.error("Failed to fetch escolaridades:", error);
        });
    }, []);

    // Determine the classificacao options based on selectedEscolaridade
    const classificacaoOptions = useMemo(() => {
        if (selectedEscolaridade && selectedEscolaridade.toLowerCase().includes("creche")) {
            return classificacaoOptionsForCreche;
        } else if (selectedEscolaridade) {
            return classificacaoOptionsForPreEscola;
        }
        return [];
    }, [selectedEscolaridade]);

    // Fetch BNCC data based on selected Componente, Escolaridade, and Classificação
    useEffect(() => {
        if (selectedComponente && selectedEscolaridade && selectedClassificacao) {
            const escolaridadeValue = selectedEscolaridade.toLowerCase().includes("creche") ? "CRECHE" : "PRÉ-ESCOLA";
    
            getBnccByEscolaridadeAnoFaixa(escolaridadeValue, selectedClassificacao, selectedComponente)
                .then((data) => {
                    setTableData(data);
                })
                .catch((error) => {
                    console.error("Failed to fetch BNCC data:", error);
                });
        }
    }, [selectedComponente, selectedEscolaridade, selectedClassificacao]);

    return (
        <Base>
            <div>
                <Breadcrumb title="BNCC Infantil" itens={[BasePath, "BNCC Infantil"]} />
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <Autocomplete
                            options={componenteOptions}
                            value={selectedComponente}
                            onChange={(event, newValue) => setSelectedComponente(newValue)}
                            renderInput={(params) => <TextField {...params} label="Componente" variant="outlined" />}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Autocomplete
                            options={escolaridadeOptions}
                            value={selectedEscolaridade}
                            onChange={(event, newValue) => {
                                setSelectedEscolaridade(newValue);
                                setSelectedClassificacao(null); // Reset Classificação when Escolaridade changes
                            }}
                            renderInput={(params) => <TextField {...params} label="Escolaridade" variant="outlined" />}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Autocomplete
                            options={classificacaoOptions}
                            value={selectedClassificacao}
                            onChange={(event, newValue) => setSelectedClassificacao(newValue)}
                            renderInput={(params) => <TextField {...params} label="Classificação" variant="outlined" />}
                            fullWidth
                            disabled={!selectedEscolaridade} // Disable until Escolaridade is selected
                        />
                    </Grid>
                </Grid>

                <TableContainer component={Paper} style={{ marginTop: 20 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Habilidade</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{row.habilidades}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Base>
    );
};

export default BnccInfantilPage;

import React, { useEffect, useMemo } from 'react'
import FormInput from '../../../../../components/formInput'
import { useMutation } from 'react-query'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import { postResponsavel, updateResponsavel } from '../../../../../services/responsaveis'
import urlsConfiguracoes from '../../../../configuracoes/urlsConfiguracoes'
import { toast } from 'react-toastify'
import { store } from '../../../../../store/index'

export default function DadosResponsavel({ data }) {
  const navigate = useNavigate()
  const { id } = useParams()

  const currentUser = store.getState()['user']['user']
  const [cargos, setCargos] = React.useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { isValid, errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      nome: '',
      telefoneCelular: '',
      email: '',
      cargo: 0,
    }
  });



  useEffect(() => {
    if (process.env.REACT_APP_SECRETARIA_CIDADE === 'ARARUAMA') {
      import('../../../../servidores/pickersAraruama').then((mod) => {
        setCargos(mod.servidoresCargos);
        // setRegimes(mod.regimesServidores);
        var dados = [{value: 0, label: 'TODOS'}];
        mod.regimesServidores.forEach(item => {
          dados.push({ value: item.id, label: item.description })
        });
      });
    } else {
      import('../../../../servidores/pickersSaoPedro').then((mod) => {
        setCargos(mod.servidoresCargos);
        var dados = [{value: 0, label: 'TODOS'}];
        mod.regimesServidores.forEach(item => {
          dados.push({ value: item.id, label: item.description })
        });
  
      });
    }
  }, []);

  const { mutate } = useMutation(id ? updateResponsavel : postResponsavel, {
    onSuccess: data => {
      if (id) {
        toast.success('Representante atualizado com sucesso!');
      } else {
        toast.success('Representante criado com sucesso!');
        navigate(urlsConfiguracoes.responsaveis);
      }
    },
    onError: data => {
      toast.error('Algo deu errado. Tente novamente mais tarde.');
    },
  })

  useEffect(() => {
    if (data && id) {
      setValue('id', data?.data?.id);
      setValue('nome', data?.data?.nome);
      setValue('telefoneCelular', (data?.data?.telefoneCelular).toString());
      setValue('email', data?.data?.email);
      setValue('cargo', data?.data?.cargo);
    }
  }, [data, id, navigate])

  const onSubmit = data => {

    const dataRequest = {
      nome: data.nome.toUpperCase(),
      telefoneCelular: data.telefoneCelular.replaceAll('(', '').replaceAll(')', '').replaceAll('-', ''),
      email: data.email.toUpperCase(),
      cargo: data.cargo
    }
    if (id) {
      mutate({ id: id, data: dataRequest })
    } else {
      mutate(dataRequest)
    }
  }

  return (
    <div
      className="tab-pane fade show active"
      id="dadosResponsavel"
      role="tabpanel"
      aria-labelledby="dadosResponsavel-tab"
    >
      <div className="card-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-6">
              <FormInput
                register={register}
                errors={errors}
                atribute="nome"
                label="Nome"
                required={false}
              />
            </div>
            <div className="col-md-6">
              <FormInput
                register={register}
                errors={errors}
                mask="(99)99999-9999"
                atribute="telefoneCelular"
                label="Celular"
                required={false}
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: 30 }}>
            <div className="col-md-6">
              <FormInput
                register={register}
                errors={errors}
                type="email"
                atribute="email"
                label="E-mail"
                required={false}
              />
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="cargo">Cargo</label>
                <select
                  className="form-select"
                  id="cargo"
                  {...register('cargo', { required: false, valueAsNumber: true })}
                >
                  <option>SELECIONE</option>
                  {
                    cargos.map(cargo => (
                      <option value={cargo.id}>{cargo.description}</option>
                    ))
                  }
                </select>
                {errors.cargo?.type === 'required' && (
                  <span className="text-danger">Campo obrigatório</span>
                )}
              </div>
            </div>

          </div>

          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              padding: '0 12px',
              marginTop: '30px',
            }}
          >
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => navigate(urlsConfiguracoes.responsaveis)}
            >
              Voltar
            </button>
            <button
              type="submit"
              className="btn btn-success"
              disabled={!isValid}
            >
              {id ? 'Atualizar' : 'Cadastrar'}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

import React, { useEffect, useRef, useState } from "react";
import { listarFichaIndividualFundamentalEja, salvarResultadoFicha } from "../../../../services/ficha-individual";
import BaseGestao from "../../../gestao/baseGestao";
import BaseGestaoUnidades from "../../../gestao-unidades/baseGestaoUnidades";
import Breadcrumb from "../../../../components/breadcrumb";
import { useForm } from "react-hook-form";
import { useReactToPrint } from "react-to-print";
import "./style.css";
import SalvarFichaForm from "../../salvarResultado/SalvarFichaForm";
import { useParams } from "react-router-dom";

const FichaIndividualEjaFundamental = (gestao) => {
  const [ficha, setFicha] = useState([]); // Estado para armazenar a ficha individual
  const [loading, setLoading] = useState(true); // Estado para controlar o loading
  const BaseComponent = gestao.gestao ? BaseGestao : BaseGestaoUnidades;
  const BasePath = gestao.gestao ? "Gestão" : "Gestão Unidades";
  const printRef = useRef(null);
  const { id } = useParams(); 

  const resultados = [
    { label: "Transferido", value: 1 },
    { label: "Aprovado", value: 2 },
    { label: "Aprovado pelo Conselho de Classe", value: 3 },
    { label: "Reprovado", value: 4 },
    { label: "Evadido", value: 5 },
    { label: "Matrícula Indeferida", value: 6 },
    { label: "Temporalidade flexível", value: 7 },
    { label: "Promovido", value: 8 },
    { label: "Retido", value: 9 },
  ];

  useEffect(() => {
    // Função que busca os dados da API
    const fetchFicha = async () => {
      try {
        const response = await listarFichaIndividualFundamentalEja(id);
        setFicha(response); // Armazena os dados no estado
        setLoading(false); // Desabilita o loading
        setValue("resultado", response?.resultadoFinal?.resultado ?? "");
        setValue("observacoes", response?.resultadoFinal?.observacao ?? "");
      } catch (error) {
        console.error("Erro ao buscar a ficha individual:", error);
        setLoading(false); // Desabilita o loading, mesmo em caso de erro
      }
    };

    fetchFicha(); // Chama a função assim que o componente é montado
  }, []);

  const {
    setValue,
    watch,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      resultado: "",
      observacoes: "",
    },
  });

  const resultado = watch("resultado");
  const observacoes = watch("observacoes");

  


  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "FICHA_INDIVIDUAL_ALUNO",
  });

  useEffect(() => {
    // Função que busca os dados da API
    const fetchFicha = async () => {
      try {
        const response = await listarFichaIndividualFundamentalEja(id);
        await setFicha(response); // Armazena os dados no estado
        setLoading(false); // Desabilita o loading
      } catch (error) {
        console.error("Erro ao buscar a ficha individual:", error);
        setLoading(false); // Desabilita o loading, mesmo em caso de erro
      }
    };

    fetchFicha(); // Chama a função assim que o componente é montado
  }, []);

  if (loading) {
    return <p>Carregando...</p>; // Exibe mensagem de carregamento
  }

  if (!ficha) {
    return <p>Erro ao carregar a ficha.</p>; // Exibe mensagem de erro, caso não tenha ficha
  }

  const segundoAno = ficha?.aluno?.escolaridade.toUpperCase().includes("FASE II");
  const observaoOpicional = segundoAno ? 'RD - Relatório Descritivo |' : '';
  const periodoDescricao = segundoAno ? 'T - Trimestre |' : 'B - Bimestre |';

  return (
    <BaseComponent>
      <Breadcrumb
        title={"Ficha Individual"}
        itens={[BasePath, "Alunos", "Documentos"]}
        onClickImprimir={handlePrint}
        imprimir={{ link: "fda", text: "Imprimir" }}
      />
      <style>
        {`
           @media print {
            .ficha-container {
              transform: scale(0.9);
              font-size: 0.85em;
            }
            .ficha-table td,
            .experiencia-table th,
            .experiencia-table td,
            .total-geral-table td,
            .resultado-final-table td,
            .assinaturas-table td {
              padding: 3px;
              margin: 0;
            }
            .signature-section {
              margin-top: 20px;
            }
            @page {
              size: landscape;
            }
          }
        `}
      </style>
      <SalvarFichaForm id={id} ficha={ficha} />
      <div className="ficha-container" ref={printRef}>
        <div className="d-flex justify-content-between pt-5">
          <div style={{ width: "250px" }}>
            <img
              src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`}
              width="100"
              height="100"
              alt=""
            ></img>
          </div>
          <div className="text-center">
            <h5>ESTADO DO RIO DE JANEIRO</h5>
            <h5>{process.env.REACT_APP_PREFEITURA}</h5>
            <h5>SECRETARIA MUNICIPAL DE EDUCAÇÃO</h5>
            <h5>{ficha?.aluno.unidade}</h5>
          </div>
          <div>
            <img
              src={`/assets/images/prefeituras/logo-pmspa-azul.png`}
              width="250"
              height="80"
              alt=""
            ></img>
          </div>
        </div>
        <h2 className="mt-3">FICHA INDIVIDUAL DO ENSINO FUNDAMENTAL {ficha?.aluno?.escolaridade} </h2>
        <table className="ficha-table">
          <tbody>
            <tr>
              <td>ALUNO: {ficha?.aluno?.nome}</td>
              <td>SEXO: {ficha?.aluno?.sexo}</td>
            </tr>
          </tbody>
        </table>
        <table className="ficha-table">
          <tbody>
            <tr>
              <td>DATA DE NASCIMENTO: {ficha?.aluno?.dataNascimento}</td>
              <td style={{textAlign: 'left'}}>CIDADE NASCIMENTO: {ficha.aluno.naturalidadeCidade}</td>
              <td style={{textAlign: 'left'}}>ESTADO NASCIMENTO: {ficha.aluno.naturalidadeEstado}</td>
            </tr>
          </tbody>
        </table>
        <table className="ficha-table mb-2">
          <tbody>
            <tr>
              <td>FILIAÇÃO 1: {ficha?.aluno.filiacao1}</td>
              <td>FILIAÇÃO 2: {ficha?.aluno.filiacao2}</td>
            </tr>
          </tbody>
        </table>

        <table className="experiencia-table">
          <thead>
            <tr>
              <th>ESCOLARIDADE: {ficha?.aluno.escolaridade}</th>
              <th>TURMA: {ficha?.aluno.turma}</th>
              <th>TURNO: {ficha?.aluno.turno}</th>
              <th>NÚMERO: {ficha?.aluno.numero}</th>
              <th>ANO LETIVO: {ficha?.aluno.anoLetivo}</th>
            </tr>
          </thead>
        </table>
        <table className="experiencia-table mt-2">
          <thead>
            <tr>
              <th rowSpan="2">ÁREA DE CONHECIMENTO</th>
              <th colSpan="3">NOTAS</th>
              <th colSpan="3">FALTAS</th>
              <th colSpan="3">HORAS-AULAS/AULAS</th>
            </tr>
            <tr>
              <th>1º B</th>
              <th>2º B</th>
              <th>M.F.</th>
              <th>1º B</th>
              <th>2º B</th>
              <th>T.F.</th>
              <th>1º B</th>
              <th>2º B</th>
              <th>TOTAL</th>
            </tr>
          </thead>
          <tbody>
            {ficha?.camposDeExperiencias.map((componente, index) => (
              <tr key={index}>
                <td>{componente.campo}</td>
                
                  <td>{componente.avaliacoes.primeiroTrimestreMediaIsNull ? '' : componente.avaliacoes.mediaPrimeiroTrimestre}</td>
                <td>{componente.avaliacoes.segundoTrimestreMediaIsNull ? '' : componente.avaliacoes.mediaSegundoTrimestre}</td>
               
                <td>{(componente.avaliacoes.primeiroTrimestreMediaIsNull && componente.avaliacoes.segundoTrimestreMediaIsNull && componente.avaliacoes.terceiroTrimestreMediaIsNull) ? '' : componente.avaliacoes.mediaFinal}</td>
                <td>{componente.faltas.primeiroTrimestre}</td>
                <td>{componente.faltas.segundoTrimestre}</td>

                <td>{componente.faltas.totalFaltas}</td>
                <td>{componente.horasAulas.primeiroTrimestre}</td>
                <td>{componente.horasAulas.segundoTrimestre}</td>
                <td>{componente.horasAulas.totalHoras}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <table className="ficha-table mt-2"></table>
        <table className="ficha-table mt-2">
          <thead>
            <tr>
              <td>TOTAL DE FALTAS: {ficha?.totalGeral.faltas}</td>
              <td>TOTAL DE HORAS: {ficha?.totalGeral.horasAulas}</td>
              <td>
                PERCENTUAL DE FREQUÊNCIA:{" "}
                {ficha?.totalGeral.percentualFrequencia}%
              </td>
              <td>
                CARGA HORÁRIA:{" "}
                {ficha.totalGeral.cargaHoraria}h
              </td>
            </tr>
          </thead>
        </table>

        <table className="resultado-final-table mt-2">
          <tbody>
            <tr>
              <td style={{ maxWidth: "120px" }}>
                RESULTADO:{" "}
                {ficha?.resultadoFinal?.resultado||
                  "N/A"}
              </td>
              <td>
  DATA: {ficha?.resultadoFinal?.dataLancamento ? new Date(ficha.resultadoFinal.dataLancamento).toLocaleDateString() : ''}
</td>

              <td style={{ maxWidth: "60px" }}>DIAS LETIVOS: {200}</td>
            </tr>
          </tbody>
        </table>

        <div className="d-flex mt-2" style={{ height: 80 }}>
          <div className="border-black w-25 d-flex justify-content-center align-items-center">
            OBSERVAÇÕES
          </div>
          <div className="d-flex align-items-center ps-2 border-black w-75">
            {observacoes}
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "end" }}>
          <span>
            {observaoOpicional} {periodoDescricao} | M.F - Média Final | T.F
            - Frequência | * - Componente curricular de matrícula facultativa
          </span>
        </div>

        <div className="signature-section">
          <div className="signature-field">
            <div className="signature-line"></div>
            <span>SECRETÁRIO(A) ESCOLAR</span>
          </div>
          <div className="signature-field">
            <div className="signature-line"></div>
            <span>DIREÇÃO</span>
          </div>
          <div className="signature-field">
            <div className="signature-line"></div>
            <span>INSPETOR(A) ESCOLAR</span>
          </div>
        </div>
      </div>
    </BaseComponent>
  );
};

export default FichaIndividualEjaFundamental;

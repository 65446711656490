import Breadcrumb from '../../../../../components/breadcrumb'
import BaseChamadaPublica from '../../baseChamadaPublica'
import FormInput from '../../../../../components/formInput'
import { useState } from 'react'
import { pesquisaNome, pesquisaPorFiltro } from '../../../../../services/processos/chamadaPublica'
import Table from '../../../../../components/table'
import { useMemo, useEffect } from 'react'
import { useQuery } from 'react-query';
import urlsChamadaPublica from '../../urlsChamadaPublica'


const ChamadaPublicaPesquisarPage = () => {

  const [pesquisa, setPesquisa] = useState('')
  const [data, setData] = useState([])
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [tipoFiltro, setTipoFiltro] = useState('id');




  const { isError, isLoading, refetch } = useQuery(
    'pesquisaNome',
    () => pesquisaPorFiltro(tipoFiltro, pesquisa, skip, limit),
    {
      retry: 0,
      enabled: true,
      onSuccess: data => {
        console.log(data)
        setData(data)
      }
    }
  )

  // async function handleSearch(){
  //     const response = await pesquisaNome(nome, skip, limit)
  //     setData(response)
  // }

  useEffect(() => {
    if (pesquisa.length > 3) {
      refetch()
    }
  }, [refetch, limit, skip])

  function abrirInscricao(id) {
    localStorage.setItem('ID', id)
    window.open(urlsChamadaPublica.visualizacao + id, '_blank')

  }

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Unidade', accessor: 'unidade' },
      { Header: 'Opção', accessor: 'opcao' },
      { Header: 'Estudante', accessor: 'nome' },
      { Header: 'CPF', accessor: 'cpf' },
      {
        Header: 'Status', accessor: 'status',
        Cell: ({ row }) => (
          <span className={`badge bg-dark`}>
            {row.original.status}
          </span>
        )
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) => (
          <>

            <button
              onClick={() => { abrirInscricao(row.original.id) }
                // navigate(urls.processosSeletivosVInscricao + row.original.id)
              }
              className="btn btn-sm btn-primary"
            >
              <i className="material-icons md-edit_note"></i>
            </button>
          </>
        )
      },
    ])





  return (
    <BaseChamadaPublica>
      <Breadcrumb title={'PESQUISAR'} itens={['CHAMADA PÚBLICA', 'INSCRIÇÕES', 'PESQUISAR']} />
      {/* <form id="form" onSubmit={onsubmit()}> */}
      <div className="card-body">
        {/* <div className='row'>
                    <div className='col-8'>
                      <label htmlFor="nome" className="form-label">Nome</label>
                      <input type="text" className="form-control" id="nome"
                      />
                      
                    </div>
                    <div className='col-4'>
                        <button></button>
                    </div>
                  </div> */}

        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginTop: 10,
            marginBottom: 20
          }}
        >

          <div className='col-5'>
            <label htmlFor="descricao" className="form-label">TIPO DE FILTRO</label>
            {
              <select className="form-select" id="tipoFiltro" value={tipoFiltro}
                onChange={async e => {
                  setTipoFiltro(e.target.value);
                }}>
                <option value="id">ID</option>
                <option value="nomeAluno" selected>Nome Aluno</option>
                <option value="cpfAluno">CPF Aluno</option>
                <option value="filiacao">Filiação</option>
                <option value="nomeResponsavel">Nome Responsável</option>
              </select>
            }
          </div>

          <div className="form-group col-md-6" style={{ marginLeft: '10px', marginRight: '10px' }}>
            <label htmlFor="descricao" className="form-label">PESQUISA</label>
            <input
              type="text"
              className="form-control"
              placeholder="Informe a pesquisa"
              value={pesquisa}
              onChange={e => setPesquisa(e.target.value)}
            />
          </div>


          <div className='col-1'>
            <button
              className="btn btn-sm btn-primary" style={{ marginTop: '37px' }}
              onClick={() => refetch()}
            >
              <i className="material-icons md-search"></i> Buscar
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <Table
                columns={columns}
                data={data?.data ? data?.data : []}
                hiddenColluns={['cpf']}
                hasPagination={true}
                limit={limit}
                setLimit={setLimit}
                skip={skip}
                setSkip={setSkip}
                totalItems={data?.total}
              />
            </div>
          </div>
        </div>


      </div>
      {/* </form> */}

    </BaseChamadaPublica>
  )
}

export default ChamadaPublicaPesquisarPage
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { putContrato, postContrato, getContrato } from '../../../../../services/administrativo/patrimonio-contratos'
import { useMutation, useQuery } from 'react-query'
import BaseAdministrativo from '../../../baseAdministrativo'
import Contrato from './tab/Contrato'
import Clausula from './tab/Clausula'
import Descricao from './tab/Descricao'
import urlsAdministrativo from '../../../urlsAdministrativo'
import { toast } from 'react-toastify'
import upperCaseSimple from '../../../../../utils/upperCaseSimple'
import removeEmpty from '../../../../../utils/removeEmpty'


const ContratosForm = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { state } = useLocation()

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { isValid, errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      identificador: '',
      contratante: '',
      contratado: '',
      descricao: '',
      valor: 0,
      inicial: '',
      final: '',
      informacoesPagamento: '',
      clausulasCondicoes: '',
    }
  })

  const { data: contratoData, isFetched: fetchedContrato, isLoading: loadingContrato } = useQuery('getContrato', () => id && getContrato(id), {
    enabled: id ? true : false,
  })

  const { mutate, isLoading } = useMutation(id ? putContrato : postContrato, {
    onSuccess: message => {
      if (id) {
        toast.success('Contrato atualizado com sucesso.')
      } else {
        toast.success('Contrato criado com sucesso.')
        navigate(urlsAdministrativo.patrimonioControleContratos)
      }
    },
    onError: error => {
      if (id) {
        toast.error('Erro ao atualizar contrato. Tente novamente mais tarde.')
      } else {
        toast.error('Erro ao criar contrato. Tente novamente mais tarde.')
      }
    }
  })

  useEffect(() => {
    if (id && fetchedContrato && contratoData) {
      setValue('id', id)
      setValue('identificador', contratoData?.data?.identificador);
      setValue('contratante', contratoData?.data?.contratante);
      setValue('contratado', contratoData?.data?.contratado);
      setValue('descricao', contratoData?.data?.descricao);
      setValue('valor', contratoData?.data?.valor);
      setValue('inicial', contratoData?.data?.inicial?.split('T')[0]);
      setValue('final', contratoData?.data?.final?.split('T')[0]);
      setValue('informacoesPagamento', contratoData?.data?.informacoesPagamento);
      setValue('clausulasCondicoes', contratoData?.data?.clausulasCondicoes);
    }
  }, [contratoData, fetchedContrato, loadingContrato])

  const onSubmit = (data) => {
    const request = {
      identificador: data.identificador,
      contratante: data.contratante,
      contratado: data.contratado,
      descricao: data.descricao,
      valor: parseInt(data.valor),
      inicial: data.inicial,
      final: data.final,
      informacoesPagamento: data.informacoesPagamento,
      clausulasCondicoes: data.clausulasCondicoes,
    }

    if (id) {
      mutate({ id, data: upperCaseSimple(removeEmpty(request))})
    } else {
      mutate(upperCaseSimple(removeEmpty(request)))
    }
  }


  return (
    <BaseAdministrativo>
      <div className="row">
        <div className="mailbox-container">
          <div className="card">
            <div className="container-fluid">
              <div className="row">
                <div className="mailbox-open-content col-md-12 m-0 p-3">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="contrato-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#contrato"
                        type="button"
                        role="tab"
                        aria-controls="contrato"
                        aria-selected="true"
                      >
                        Contrato
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="description-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#description"
                        type="button"
                        role="tab"
                        aria-controls="description"
                        aria-selected="false"
                      >
                        Descrição
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="clausula-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#clausula"
                        type="button"
                        role="tab"
                        aria-controls="clausula"
                        aria-selected="false"
                      >
                        Cláusulas e Condições
                      </button>
                    </li>
                  </ul>
                  <form id="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="tab-content" id="myTabContent">
                      <Contrato
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        watch={watch}
                      />
                      <Descricao
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        watch={watch}
                      />
                      <Clausula
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        watch={watch}
                        getValues={getValues}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => navigate(urlsAdministrativo.patrimonioControleContratos)}
                        style={{ marginRight: '8px' }}
                      >
                        Voltar
                      </button>

                      <button
                        type="submit"
                        className="btn btn-success"
                        disabled={!isValid}
                      >
                        {id ? 'Atualizar' : 'Cadastrar'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseAdministrativo>
  )

};

export default ContratosForm;
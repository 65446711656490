import { useParams, useNavigate, useLocation } from 'react-router-dom'
import Breadcrumb from '../../../components/breadcrumb'
import BaseConfiguracoes from '../../configuracoes/baseConfiguracoes'
import { useQuery } from 'react-query'
import { getResponsavel } from '../../../services/responsaveis'
import DadosResponsavel from './tab/dadosResponsavel'

const ResponsaveisCadatrarPage = () => {
  const navigate = useNavigate()
  const { id } = useParams()


  const { data } = useQuery('getResponsavel', () => id && getResponsavel(id), {
    retry: true,
    enabled: true,
  })


  return (
    <BaseConfiguracoes>
      <Breadcrumb
        title={'Formulário Responsáveis'}
        itens={['Gestão', 'Responsáveis', 'Formulário']}
      />
      <div className="row">
        <div className="mailbox-container">
          <div className="card">
            <div className="container-fluid">
              <div className="row">
                <div className="mailbox-open-content col-md-12 m-0 p-3">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="dadosResponsavel-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#dadosResponsavel"
                        type="button"
                        role="tab"
                        aria-controls="dadosResponsavel"
                        aria-selected="true"
                      >
                        Dados Responsável
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <DadosResponsavel data={data} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseConfiguracoes>
  )
}

export default ResponsaveisCadatrarPage

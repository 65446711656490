import React from 'react';
import { Breadcrumbs, Typography, Button, Link as MuiLink, Box, Divider } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const MUIBreadcrumb = ({
  itens,
  title,
  description,
  actions = [],
}) => {
  const renderButton = ({ onClick, text, icon, color = 'primary.main' }, index) => (
    <Button
      key={index}
      variant="contained"
      startIcon={icon}
      onClick={onClick}
      sx={{
        textTransform: 'none',
        fontWeight: 'bold',
        backgroundColor: color,
        '&:hover': { backgroundColor: color.replace('.main', '.dark') },
      }}
    >
      {text}
    </Button>
  );

  return (
    <Box sx={{ backgroundColor: '#f9fafb', padding: 2, borderRadius: 1, mb: 2 }}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
        <Box>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {itens.map((item, index) => (
              <MuiLink
                key={index}
                color="inherit"
                href={item.url}
                underline="hover"
              >
                {item.label}
              </MuiLink>
            ))}
          </Breadcrumbs>
          <Typography variant="h4" fontWeight="bold" mt={1}>
            {title}
          </Typography>
          {description && <Typography variant="body2" color="textSecondary">{description}</Typography>}
        </Box>
        <Box display="flex" gap={1}>
          {actions.map((action, index) => renderButton(action, index))}
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};

export default MUIBreadcrumb;

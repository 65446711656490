import { useMemo, useEffect, useState } from 'react'
import Breadcrumb from '../../../../components/breadcrumb';
import BaseAdministrativo from '../../baseAdministrativo';
import Spinner from '../../../../components/spinner';
import Table from '../../../../components/table';
import urlsAdministrativo from '../../urlsAdministrativo';
import { getVeiculos } from '../../../../services/administrativo/transporte';
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from 'react-query';

const TransporteVeiculos = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [listaUnidades, setListaUnidades] = useState([]);
  const [skip, setSkip] = useState(0);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(20);
  const [modelo, setModelo] = useState('');
  const [fabricante, setFabricante] = useState('');
  const [placa, setPlaca] = useState('');
  const [ano, setAno] = useState('');
  const [cor, setCor] = useState('');

  const { isLoading: loading, refetch } = useQuery('getVeiculos', () => getVeiculos(modelo, fabricante, placa, ano, cor, skip, limit), {
    onSuccess: (data) => {
      setData(data.data);
      setTotal(data.total);
      setLimit(data.limit);
      setSkip(data.skip);
    },
    enabled: false,
    retry: true
  });


  useEffect(() => {
    refetch();
  }, [data, skip, limit]);


  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Modelo', accessor: 'modelo' },
      { Header: 'Fabricante', accessor: 'fabricante' },
      { Header: 'Ano', accessor: 'ano' },
      { Header: 'Cor', accessor: 'cor' },
      { Header: 'Placa', accessor: 'placa' }, {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
          <button onClick={() => navigate(urlsAdministrativo.transporteVeiculosCadastrar + row.original.id, {
              state: {
                id: row.original.id,
                veiculo: {
                  modelo: row.original.modelo,
                  fabricante: row.original.fabricante,
                  ano: row.original.ano,
                  cor: row.original.cor,
                  placa: row.original.placa,
                }
              }
            })} className='btn btn-sm btn-warning'>
              <i className="material-icons md-edit_note"></i> editar
            </button>
            <button onClick={() => navigate(urlsAdministrativo.transporteVeiculosLancarGastos + row.original.id, {
              state: {
                id: row.original.id
              }
            })} className='btn btn-sm btn-success'>
              <i className="material-icons md-edit_note"></i> Lançar Gastos
            </button>
          </>
        )
      },
    ],
    [data]
  )

  return (
    <BaseAdministrativo>
      <Breadcrumb title={'Veículos'} itens={['Administrativo', 'Transportes', 'Veículos', 'Lista Geral']}
      actions={{ link: urlsAdministrativo.transporteVeiculosCadastrar, text: "Novo Veículo" }}  />

      <div className='row' style={{ marginBottom: '20px', marginRight: '0', marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
        <div className="col-md-2">
          <input type="text" className="form-control" value={modelo} onChange={(event) => setModelo(event.target.value)} placeholder="Modelo" />
        </div>
        <div className="col-md-2">
          <input type="text" className="form-control" value={fabricante} onChange={(event) => setFabricante(event.target.value)} placeholder="Fabricante" />
        </div>
        <div className="col-md-2">
          <input type="text" className="form-control" value={placa} onChange={(event) => setPlaca(event.target.value)} placeholder="Placa" />
        </div>
        <div className="col-md-2">
          <input type="text" className="form-control" value={ano} onChange={(event) => setAno(event.target.value)} placeholder="Ano" />
        </div>
        <div className="col-md-2">
          <input type="text" className="form-control" value={cor} onChange={(event) => setCor(event.target.value)} placeholder="Cor" />
        </div>
        <button className="btn btn-sm btn-primary" style={{ width: '100px', height: '30px', marginTop: '7px' }} onClick={() => refetch()}>
          <i className="material-icons md-search"></i> Buscar
        </button>
      </div>

      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            {loading ? (
              <Spinner />
            ) : (
              <Table
                columns={columns}
                data={data}
                hasPagination
                limit={limit}
                setLimit={setLimit}
                skip={skip}
                setSkip={setSkip}
                totalItems={total}
              />
            )}
          </div>
        </div>
      </div>
    </BaseAdministrativo>
  )
};
export default TransporteVeiculos;
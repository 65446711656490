import { useMemo, useEffect, useState } from 'react'
import Breadcrumb from '../../components/breadcrumb'
import Table from '../../components/table'
import BaseGestao from '../gestao/baseGestao'
import BaseGestaoUnidades from '../gestao-unidades/baseGestaoUnidades'
import urlsGestao from '../gestao/urlsGestao'
import urlsGestaoUnidades from '../gestao-unidades/urlsGestaoUnidades'
import { useNavigate } from 'react-router-dom'
import { getQuadros, updateQuadro } from '../../services/quadro-horario'
import { getTurmasUnidadeEscolaridadeTurno } from '../../services/turmas'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { getEscolaridades as todasEscolaridades, getEscolaridadesUnidade } from '../../services/escolaridades';

import { getTurnos as todosTurnos, getTurnosUnidadeEscolaridade } from '../../services/turnos';


import { useQuery, useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { set } from 'react-hook-form'


const QuadroHorarioPage = (gestao) => {
  const navigate = useNavigate()

  const [unidades, setUnidades] = useState([])
  const [openPicker, setOpenPicker] = useState(false);
  const [show, setShow] = useState(false);

  const unidade = localStorage.getItem('unidadeID')
  const [escolaridade, setEscolaridade] = useState(0)
  const [turno, setTurno] = useState(0)
  const [turma, setTurma] = useState(0)
  const [nomeTurma, setNomeTurma] = useState('')

  const [nomeTurmaCadastro, setNomeTurmaCadastro] = useState('')

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const anoLetivo = 2024

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [descricaoQuadroHorario, setDescricaoQuadroHorario] = useState('');
  const [idQuadroHorario, setIdQuadroHorario] = useState(0);
  const [turmaId, setTurmaId] = useState(0);

  const editarDisplinas = gestao.gestao ? urlsGestao.quadroHorarioEditarDisplina : urlsGestaoUnidades.quadroHorarioEditarDisplina;
  const cadastrarQuadro = gestao.gestao ? urlsGestao.quadroHorarioCadastrar : urlsGestaoUnidades.quadroHorarioCadastrar;
  const BaseComponent = gestao.gestao ? BaseGestao : BaseGestaoUnidades;
  const BasePath = gestao.gestao ? 'Gestão' : 'Gestão Unidades';


  const { data, isLoading: loading, refetch } = useQuery('getQuadros', () => getQuadros(unidade, escolaridade, turno, turma, skip, limit), {
  });
  



  const {
    data: escolaridades,
    isLoading: loadingEscolaridades,
    refetch: refetchEscolaridades
  } = useQuery(
    'getEscolaridades',
    () => ((unidade !== null && unidade > 0) ? getEscolaridadesUnidade(unidade) : todasEscolaridades()),
    {
      retry: 0,
      enabled: true
    }
  )

  const {
    data: turnos,
    isLoading: loadingTurnos,
    refetch: refetchTurnos
  } = useQuery(
    'getTurnos',
    () =>
      (unidade !== null && unidade != 0) && escolaridade !== 0
        ? getTurnosUnidadeEscolaridade(unidade, escolaridade)
        : todosTurnos(),
    {
      retry: 0,
      enabled: true
    }
  )

  const {
    data: turmas,
    isLoading: loadingTurmas,
    refetch: refetchTurmas
  } = useQuery(
    'getTurmas',
    () =>
      unidade !== null && escolaridade !== null && turno !== null
        ? getTurmasUnidadeEscolaridadeTurno(unidade, escolaridade, turno, anoLetivo)
        : null,
    {
      retry: 0,
      enabled: true
    }
  )

  async function atualizaDescricao(){
      const data = {
        descricao: descricaoQuadroHorario.toUpperCase(),
        turmaId: turmaId,
      }

      const response = await updateQuadro(idQuadroHorario, data)

      if (response.descricao === descricaoQuadroHorario) {
        toast.success('Descrição atualizada com sucesso!');
        handleClose();
        refetch();
      }
      else {
        toast.error('Erro ao atualizar descrição!');
      }


  }

  useEffect(() => {
    refetch()
  }, [refetch, limit, skip])

  const columns = useMemo(
    () => [
      { Header: 'Quadro', accessor: 'id' },
      { Header: 'Descrição', accessor: 'descricao' },
      { Header: 'Turma', accessor: 'turmaDescricao' },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <button
              onClick={() =>
                navigate(editarDisplinas + row.original.id, {
                  state: {
                    id: row.original.id,
                    descricao: row.original.descricao,
                  }
                }, 
                localStorage.setItem('nomeUnidade', row.original.unidadeNome),
                localStorage.setItem('nomeEscolaridade', row.original.escolaridadeDescricao),
                localStorage.setItem('nomeTurno', row.original.turnoDescricao),
                localStorage.setItem('nomeTurma', row.original.turmaDescricao),

                localStorage.setItem('quadro-horario-dados', JSON.stringify({
                  turma: row.original.turmaDescricao,
                  nomeUnidade: row.original.unidadeDescricao,
                  nomeEscolaridade: row.original.escolaridadeDescricao,
                  nomeTurno: row.original.turnoDescricao,
                  nomeTurma: row.original.turmaDescricao})))
              }
              className="btn btn-sm btn-primary"
            >
              <i className="material-icons md-edit_note"></i> visualizar
            </button>
            <>
            <button onClick={() => {
              handleShow();
              setDescricaoQuadroHorario(row.original.descricao);
              setIdQuadroHorario(row.original.id);
              setTurmaId(row.original.turmaId);
            }} className='btn btn-sm btn-secondary' style={{ width: '82px' }}>
              <i className="material-icons md-edit_note"></i> editar
            </button>
          </>
          </>
          
        )
      }
    ],
    [navigate]
  )

  useEffect(() => {
    refetchEscolaridades()
  }, [refetchEscolaridades])


  const cleanFilters = async name => {
    switch (name) {
      case 'escolaridade':
        await setTurno(0)
        await setTurma(0)
        break
      case 'turno':
        await setTurma(0)
        break
      default:
        break
    }
  }

  const newItemAvailable = () => {
    if (turma !== 0 && turma !== undefined) {
      navigate(cadastrarQuadro + turma, {
        state: {
          id: turma,
          nomeTurma, nomeTurmaCadastro
        }
      });
    } else {
      toast.error('Por favor, selecione a turma nos filtros antes de criar um novo quadro.')
    }
  }

  return (
    <BaseComponent>
      <Breadcrumb
        title={'Quadros de Horários'}
        itens={[BasePath, 'Academico', 'Quadro de Horários']}
        actions={{
          link: cadastrarQuadro,
          text: 'Novo Quadro'
        }}
        onClick={() => newItemAvailable()}
        checkBefore={false}
      />

<Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Editar Descrição</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
            <div className='row'>
              <div className="form-group" style={{ width: '100%' }}>
                <label htmlFor="observacao" className="form-label">OBSERVAÇÃO</label>
                {
                  <textarea onChange={(e) => { setDescricaoQuadroHorario(e.target.value) }} value={descricaoQuadroHorario} class="form-control" id="observacao" rows="3"></textarea>
                }
              </div>
            </div>
            </div>
           
            
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
            <Button variant="primary" onClick={atualizaDescricao}>
              Salvar
            </Button>
          </Modal.Footer>
        </Modal>

      <div className="row" style={{ marginBottom: 20 }}>
        <div className="col-12 col-md-4">
          <div className="form-group">
            <label htmlFor="escolaridadeID" className="form-label">
              Escolaridades
            </label>
            <select
              className="form-select"
              id="escolaridadeID"
              defaultValue={escolaridade}
              onChange={async e => {
                await setEscolaridade(e.target.value);
                await cleanFilters('escolaridade')
                await refetchTurnos()
                await refetch()
              }}
            >
              {loadingEscolaridades ? (
                <option value={0}></option>
              ) : (
                <>
                  <option value={0}></option>
                  {escolaridades &&
                    escolaridades?.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.descricao}
                      </option>
                    ))}
                </>
              )}
            </select>
          </div>
        </div>

        <div className="col-12 col-md-4">
          <div className="form-group">
            <label htmlFor="turnoID" className="form-label">
              Turnos
            </label>
            <select
              className="form-select"
              id="turnoID"
              defaultValue={turno}
              onChange={async e => {
                await setTurno(e.target.value);
                await refetch()
                await cleanFilters('turno')
                await refetchTurmas()
              }}
            >
              {loadingTurnos ? (
                <option value={0}></option>
              ) : (
                <>
                  <option value={0}></option>
                  {turnos &&
                    turnos?.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.descricao}
                      </option>
                    ))}
                </>
              )}
            </select>
          </div>
        </div>

        <div className="col-12 col-md-4">
          <div className="form-group">
            <label htmlFor="turmaID" className="form-label">
              Turmas
            </label>
            <select
              className="form-select"
              id="turmaID"
              defaultValue={turma}
              onChange={async (e) => {
                await setTurma(e.target.value)
                await setNomeTurmaCadastro(e.target.options[e.target.selectedIndex].text)
                await refetch()
              }}
            >
              {loadingTurmas ? (
                <option value={0}></option>
              ) : (
                <>
                  <option value={0}></option>
                  {turmas && turmas?.data?.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.descricao}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>
        </div>
        {/* <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}> // link opcional para tela de disciplinas
          <button className="btn btn-info" style={{ marginTop: '12px', justifySelf: 'flex-end' }} onClick={() => navigate(urls.gestaoAcademicoDisciplinas)} ><i
            className="material-icons">add</i>Disciplinas</button>
        </div> */}
      </div>


      <div className="row">
        <div className="col-12">
          <div className="card">
            <Table
              columns={columns}
              data={data ? data.data : []}
              hasPagination
              limit={limit}
              setLimit={setLimit}
              skip={skip}
              setSkip={setSkip}
              totalItems={data?.total}
              hiddenColluns={['id']}
            />
          </div>
        </div>
      </div>
    </BaseComponent>
  )
}

export default QuadroHorarioPage

import { useMemo, useRef, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from "../../../components/breadcrumb";
import BaseConfiguracoes from "../../configuracoes/baseConfiguracoes";
import urlsConfiguracoes from "../../configuracoes/urlsConfiguracoes";
import { useMutation } from 'react-query';
import { criarDistrito } from "../../../services/unidades";
import upperCaseSimple from "../../../utils/upperCaseSimple";
import removeEmpty from "../../../utils/removeEmpty";
import { LocationApi } from "../../../services/external/cep";
import FormInput from "../../../components/formInput";

const DistritoCadastrarPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();

  const { mutate, isLoading } = useMutation(criarDistrito, {
    onSuccess: (message) => {
      toast.success(message);
      navigate(urlsConfiguracoes.distritos);
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar cadastrar um distrito, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  const { register, handleSubmit, setValue,watch, formState: { isValid, errors } } = useForm({
    mode: "onChange", defaultValues: {
    descricao: '',
    cidade: '',
    estado: '',
    }
  });


  const onSubmit = (data) => {
    // if (id) {
    //   mutate({ id, data: upperCaseSimple(removeEmpty(data)) });
    // } else {
      mutate(upperCaseSimple(removeEmpty(data)));
    // }
  };

  useMemo(() => {
    (async () => {
      if (state) {
        const { distrito } = state;
        if (id && distrito) {
          const { descricao, cidade, estado } = distrito;
          setValue('descricao', descricao);
          setValue('cidade', cidade);
          setValue('estado', estado);
        }
      }
    })();
  }, [id, state, setValue]);

  return (
    <BaseConfiguracoes>
      <Breadcrumb title={'Formulário Distritos'} itens={['Gestao', 'Acadêmico', 'Distritos', 'Formulário']} />
      <form id='form' onSubmit={handleSubmit(onSubmit)}>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className="card-body">
                <>
                  <div className='row'>
                    
                    <div className="col-md-6">
                        <FormInput
                        register={register}
                        errors={errors}
                        atribute="descricao"
                        label="Descrição"
                        // mask="99999-999"
                        required={true}
                        readOnly={id ? true : false}
                        />
                    </div>

                    <div className="col-md-3">
                        <FormInput
                            register={register}
                            errors={errors}
                            atribute="cidade"
                            label="Cidade"
                            required={false}
                            readOnly={id ? true : false}
                        />
                    </div>

                    <div className="col-md-3">
                        <FormInput
                            register={register}
                            errors={errors}
                            atribute="estado"
                            label="Estado"
                            required={false}
                            readOnly={id ? true : false}
                        />
                    </div>
                    
                  </div>

                  <br />

                 

                
                </>
              </div>
              <div className='card-footer text-end'>
                <button type='submit' className='btn btn-success' disabled={id ? true : false}>
                  {isLoading ? 'Enviado...' : id ? 'Atualizar' : 'Cadastrar'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </BaseConfiguracoes>
  )

};

export default DistritoCadastrarPage;
import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Button, TextField, Alert, Box, Select, MenuItem, FormControl, IconButton
} from '@mui/material';
import { Save, Delete } from '@mui/icons-material'; // Icons for save and delete
import Autocomplete from '@mui/material/Autocomplete';
import dayjs from 'dayjs';
import { getServidores, listarServidoresFrequenciaHe, adicionarServidoresFrequenciaHe, deletarServidoresFrequenciaHe, enviarServidoresFrequenciaHe } from '../../../../services/servidores';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

const HoraExtra = () => {
  const unidadeId = JSON.parse(localStorage.getItem('unidadeID'));
  const [selectedServidor, setSelectedServidor] = useState(null);
  const [servidorList, setServidorList] = useState([]);
  const [registries, setRegistries] = useState({});
  const [servidores, setServidores] = useState([]);
  const [formularioFechado, setFormularioFechado] = useState(false);
  const [savedRegistry, setSavedRegistry] = useState({}); // New state to track saved registries
  
  const { register, watch } = useForm();

  // Fetch the servidores when the component mounts
  useEffect(() => {
    getServidores(unidadeId, 0, '', 2000, 0).then((response) => {
      setServidores(response.data);
    });
  }, [unidadeId]);

  // Fetch registries for the current month and year
  const loadData = () => {
    listarServidoresFrequenciaHe(unidadeId, watch('mes'), watch('ano')).then((response) => {
      const data = response.data;
  
      const servidorMap = {};
      const registryMap = {};
  
      // Check if all records have status === 2
      const allStatusAreClosed = data.every(record => record.status === 2);
      if (allStatusAreClosed) {
        setFormularioFechado(true);
      } else {
        setFormularioFechado(false);
      }
  
      data.forEach((record) => {
        if (!servidorMap[record.servidorId]) {
          servidorMap[record.servidorId] = {
            id: record.servidorId,
            nomeCompleto: record.nomeServidor,
          };
        }
  
        if (!registryMap[record.servidorId]) {
          registryMap[record.servidorId] = [];
        }
  
        registryMap[record.servidorId].push({
          id: record.id,
          date: dayjs(record.dataLancamento).format('YYYY-MM-DD'),
          initialTime: record.horaInicio,
          finalTime: record.horaFim,
        });
      });
  
      setServidorList(Object.values(servidorMap));
      setRegistries(registryMap);
      setSavedRegistry(registryMap); // Mark fetched registries as saved
    });
  };
  

  useEffect(() => {
    loadData();
  }, [unidadeId, watch('mes'), watch('ano')]);

  const handleAddServidor = () => {
    if (selectedServidor && !servidorList.some(s => s.id === selectedServidor.id)) {
      setServidorList([...servidorList, selectedServidor]);
      setRegistries({
        ...registries,
        [selectedServidor.id]: [],
      });
    }
  };

  const handleAddRegistry = (servidorId) => {
    const newRegistry = {
      date: '',
      initialTime: '',
      finalTime: '',
    };
    setRegistries({
      ...registries,
      [servidorId]: [...registries[servidorId], newRegistry],
    });
  };

  const handleRegistryChange = (servidorId, index, field, value) => {
    const updatedRegistry = registries[servidorId].map((registry, i) =>
      i === index
        ? { ...registry, [field]: value }
        : registry
    );
    setRegistries({ ...registries, [servidorId]: updatedRegistry });
  };

  const handleSave = async (servidorId, index) => {
    const registryToSave = registries[servidorId][index];
    const servidor = servidorList.find(s => s.id === servidorId);

    const body = {
      servidorId: servidorId,
      nome: servidor.nomeCompleto,
      unidadeId: unidadeId,
      dataLancamento: registryToSave.date,
      horaInicio: registryToSave.initialTime,
      horaFim: registryToSave.finalTime,
      mes: watch("mes"),
      ano: watch("ano").toString(),
    };

    const response = await adicionarServidoresFrequenciaHe(body);

    if (response.statusCode === 200) {
      toast.success('Registro salvo com sucesso');
      setSavedRegistry({
        ...savedRegistry,
        [servidorId]: [...(savedRegistry[servidorId] || []), registryToSave],
      });
    } else { 
      toast.error('Erro ao salvar registro');
    }
  };

  const handleDeleteRegistry = async (servidorId, index) => {
    const registryToDelete = registries[servidorId][index];
  
    if (registryToDelete.id) {
      try {
        const response = await deletarServidoresFrequenciaHe(registryToDelete.id);
        
        if (response.status === 200) {
          toast.success('Registro deletado com sucesso');
          const updatedRegistry = registries[servidorId].filter((_, i) => i !== index);
          setRegistries({ ...registries, [servidorId]: updatedRegistry });
        } else {
          toast.error('Erro ao deletar o registro');
        }
      } catch (error) {
        toast.error('Erro ao deletar o registro');
      }
    } else {
      const updatedRegistry = registries[servidorId].filter((_, i) => i !== index);
      setRegistries({ ...registries, [servidorId]: updatedRegistry });
    }
  };

  // Handle "Enviar" button
  const handleEnviar = async () => {
    try {
      const data = {
        mes: watch("mes"),
        ano: watch("ano"),
        unidadeId: unidadeId,
      }
      const response = await enviarServidoresFrequenciaHe(data);

      if (response.status === 200) {
        toast.success('Formulário enviado com sucesso!');
        loadData(); // Reload the data after submission
      } else {
        toast.error('Erro ao enviar o formulário');
      }
    } catch (error) {
      toast.error('Erro ao enviar o formulário');
    }
  };

  return (
    <div>
      {/* Month and Year Selectors (always visible) */}
      <Box sx={{ display: 'flex', gap: 2, marginTop: '20px' }}>
        <Select
          {...register("mes")}
          defaultValue={dayjs().format("MMMM").toUpperCase()}
          fullWidth
          sx={{ flex: 1 }}
          displayEmpty
          renderValue={(selected) => (selected ? selected : <em>Escolha o mês de referência</em>)}
        >
          {["JANEIRO", "FEVEREIRO", "MARÇO", "ABRIL", "MAIO", "JUNHO", "JULHO", "AGOSTO", "SETEMBRO", "OUTUBRO", "NOVEMBRO", "DEZEMBRO"].map((mes) => (
            <MenuItem key={mes} value={mes}>
              {mes}
            </MenuItem>
          ))}
        </Select>
        <Select
          {...register("ano")}
          defaultValue={dayjs().year()}
          fullWidth
          sx={{ flex: 1 }}
          displayEmpty
          renderValue={(selected) => (selected ? selected : <em>Escolha o ano de referência</em>)}
        >
          {[2024, 2025].map((ano) => (
            <MenuItem key={ano} value={ano}>
              {ano}
            </MenuItem>
          ))}
        </Select>
      </Box>

      {/* Alerts */}
      {formularioFechado && (
        <Alert sx={{ display: 'flex', gap: 2, marginTop: '20px' }} severity="warning">
          O formulário encontra-se fechado, não é mais possível realizar alterações.
        </Alert>
      )}
      {!formularioFechado && (
        <Alert sx={{ display: 'flex', gap: 2, marginTop: '20px' }} severity="info">
          O formulário encontra-se em rascunho, após salvar todos os dados e ocorrências, clique no botão <b>ENVIAR</b> para envia-lo para SEMED.
        </Alert>
      )}

      {/* Conditionally render the Servidor select and Adicionar Servidor button */}
      {!formularioFechado && (
        <>
          {/* Autocomplete for filtering Servidores */}
          <FormControl fullWidth sx={{ marginTop: '20px' }}>
            <Autocomplete
              options={servidores}
              getOptionLabel={(option) => option.nomeCompleto}
              value={selectedServidor}
              onChange={(event, newValue) => setSelectedServidor(newValue)}
              renderInput={(params) => <TextField {...params} label="Filtrar Servidor" />}
            />
            <Button onClick={handleAddServidor} variant="contained" sx={{ marginTop: '10px' }}>
              Adicionar Servidor
            </Button>
          </FormControl>
        </>
      )}

      <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Servidor</TableCell>
              <TableCell>Apontamentos</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {servidorList.map((servidor) => (
              <TableRow key={servidor.id}>
                <TableCell>{servidor.nomeCompleto}</TableCell>
                <TableCell>
                  {!formularioFechado && (
                    <Button onClick={() => handleAddRegistry(servidor.id)} variant="contained">
                      Adicionar Registro
                    </Button>
                  )}
                  {registries[servidor.id]?.map((registry, index) => (
                    <Table key={index} sx={{ marginTop: '10px' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Data</TableCell>
                          <TableCell>Hora Inicial</TableCell>
                          <TableCell>Hora Final</TableCell>
                          <TableCell>Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <TextField
                              type="date"
                              value={registry.date}
                              onChange={(e) => handleRegistryChange(servidor.id, index, 'date', e.target.value)}
                              disabled={formularioFechado}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              type="time"
                              value={registry.initialTime}
                              onChange={(e) => handleRegistryChange(servidor.id, index, 'initialTime', e.target.value)}
                              disabled={formularioFechado}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              type="time"
                              value={registry.finalTime}
                              onChange={(e) => handleRegistryChange(servidor.id, index, 'finalTime', e.target.value)}
                              disabled={formularioFechado}
                            />
                          </TableCell>
                          <TableCell>
                            {!savedRegistry[servidor.id]?.[index] && !formularioFechado && (
                              <IconButton
                                color="primary"
                                onClick={() => handleSave(servidor.id, index)}
                              >
                                <Save />
                              </IconButton>
                            )}
                            {!formularioFechado && (
                              <IconButton
                                color="secondary"
                                onClick={() => handleDeleteRegistry(servidor.id, index)}
                              >
                                <Delete />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Enviar button */}
      {!formularioFechado && (
        <Button onClick={handleEnviar} variant="contained" color="primary" sx={{ marginTop: '20px' }}>
          Enviar
        </Button>
      )}
    </div>
  );
};

export default HoraExtra;

import React, { useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import FormInput from '../../../../../../../components/formInput';

export default function Description({ register, errors, setValue, canEdit, watch }) {
  const { id } = useParams()
  const { state } = useLocation();


  return (
    <div
      className="tab-pane fade"
      id="description"
      role="tabpanel"
      aria-labelledby="description-tab"
    >
      <div className="card-body">
        <div className="row">
          <div className="col-md-12">
            <FormInput
              register={register}
              errors={errors}
              atribute="descricao"
              label="Descrição do objeto/serviço"
              required={false}
            />
          </div>


        </div>

        <div className="row" style={{ marginTop: '30px' }}>

          <div className="col-md-4">
            <FormInput
              register={register}
              errors={errors}
              atribute="valor"
              label="Valor total do contrato"
              required={false}
            />
          </div>

          <div className="col-md-4">
            <FormInput
              register={register}
              errors={errors}
              type="date"
              atribute="inicial"
              label="Data inicial"
              required={false}
            />
          </div>

          <div className="col-md-4">
            <FormInput
              register={register}
              errors={errors}
              type="date"
              atribute="final"
              label="Data final"
              required={false}
            />
          </div>
        </div>
        <div className="row" style={{ marginTop: '30px' }}>
          <div className="col-md-12">
            <label htmlFor="informacoesPagamento">
              Informações do pagamento 
              {/* <span style={{ color: 'red', marginLeft: '4px' }}>*</span> */}
            </label>
            <textarea id="informacoesPagamento" style={{ height: '300px', width: '100%', border: '1px solid #2269f5', borderRadius: '5px' }}
              {...register('informacoesPagamento', { required: true })}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

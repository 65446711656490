import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { criarNutricaoGrupoProdutos, atualizaNutricaoGrupoProdutos } from '../../../../../services/nutricao'
import { useMutation } from 'react-query'
import BaseAdministrativo from '../../../baseAdministrativo'
import FormInput from '../../../../../components/formInput'
import urlsAdministrativo from '../../../urlsAdministrativo'
import { toast } from 'react-toastify'


const NutricaoCategoriaProdutosForm = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const { state } = useLocation()

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { isValid, errors }
    } = useForm({
        mode: 'all'
    })

    const { mutate, isLoading } = useMutation(id ? atualizaNutricaoGrupoProdutos : criarNutricaoGrupoProdutos, {
        onSuccess: message => {
            toast.success(message);
            navigate(urlsAdministrativo.nutricaoCategoriaProduto)
        },
        onError: error => {
            toast.error('Algo deu errado. Tente novamente mais tarde');
        }
    })

    useMemo(() => {
        (async () => {
            if (state) {
                if (id) {
                    const { descricao, status } = state
                    setValue('id', id)
                    setValue('descricao', descricao)
                    setValue('status', status.toString())
                }
            }
        })()
    }, [id, state, setValue])

    const onSubmit = (data) => {
        if (data.descricao !== '') {
            if (id) {
                mutate({ id, descricao: data.descricao.toUpperCase(), status: data?.status === "true" ? true : false })
            } else {
                mutate({ descricao: data.descricao.toUpperCase(), status: data?.status === "true" ? true : false })
            }
        } else {
            toast.error('Confira os campos obrigatórios e tente novamente.')
        }
    }


    return (
        <BaseAdministrativo>
            <div className="row">
                <div className="mailbox-container">
                    <div className="card">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="mailbox-open-content col-md-12 m-0 p-3">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className="nav-link active"
                                                id="categoria-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#categoria"
                                                type="button"
                                                role="tab"
                                                aria-controls="categoria"
                                                aria-selected="true"
                                            >
                                                NUTRIÇÃO - Categoria produto
                                            </button>
                                        </li>
                                    </ul>
                                    <form id="form" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="tab-content" id="myTabContent">
                                            <div
                                                className="tab-pane fade show active"
                                                id="categoria"
                                                role="tabpanel"
                                                aria-labelledby="categoria-tab"
                                            >
                                                <div className="card-body">
                                                    <div className="row">
                                                        {id && (
                                                            <div className="col-md-1">
                                                                <FormInput
                                                                    register={register}
                                                                    errors={errors}
                                                                    atribute="id"
                                                                    label="ID"
                                                                    readOnly={true}
                                                                />
                                                            </div>
                                                        )}

                                                        <div className={id ? "col-md-6" : "col-md-7"}>
                                                            <FormInput
                                                                register={register}
                                                                errors={errors}
                                                                atribute="descricao"
                                                                label="Descrição"
                                                            />
                                                        </div>

                                                        <div className="col-md-5">
                                                            <label htmlFor="status">Status <span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                                                            <select className="form-select" id="status" {...register("status", { required: true })}>
                                                                <option value={null}>SELECIONE</option>
                                                                <option key="1" value={true}>ATIVO</option>
                                                                <option key="2" value={false}>INATIVO</option>
                                                            </select>
                                                            {errors.status?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: '100%',
                                                justifyContent: 'flex-end'
                                            }}
                                        >
                                            <button
                                                type="button"
                                                className="btn btn-warning"
                                                onClick={() => navigate(urlsAdministrativo.nutricaoCategoriaProduto)}
                                                style={{ marginRight: '8px' }}
                                            >
                                                Voltar
                                            </button>

                                            <button
                                                type="submit"
                                                className="btn btn-success"
                                                disabled={!isValid}
                                            >
                                                {id ? 'Atualizar' : 'Cadastrar'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BaseAdministrativo>
    )

};

export default NutricaoCategoriaProdutosForm;
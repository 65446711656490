
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import BaseAdministrativo from '../../../baseAdministrativo'
import Movimentacao from './tab/movimentacao'


const ControleEstoqueForm = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { state } = useLocation()


  return (
    <BaseAdministrativo>
      <div className="row">
        <div className="mailbox-container">
          <div className="card">
            <div className="container-fluid">
              <div className="row">
                <div className="mailbox-open-content col-md-12 m-0 p-3">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="movimentacao-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#movimentacao"
                        type="button"
                        role="tab"
                        aria-controls="movimentacao"
                        aria-selected="true"
                      >
                        Movimentação
                      </button>
                    </li>
                  </ul>
                    <div className="tab-content" id="myTabContent">
                      <Movimentacao />
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseAdministrativo>
  )

};

export default ControleEstoqueForm;
import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";
import BaseBilingue from "../BaseBilingue";
import { listarEstatisticasBilingue } from "../../../../services/formulariosService";
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import Spinner from "../../../../components/spinner";
import { Pie, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';

// Importando ícones para os status
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'; // Em Análise
import CancelIcon from '@mui/icons-material/Cancel'; // Cancelado
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Deferido
import HighlightOffIcon from '@mui/icons-material/HighlightOff'; // Indeferido
import AssessmentIcon from '@mui/icons-material/Assessment';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';



ChartJS.register(ArcElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const BilingueDashboardPage = () => {
  const [estatisticas, setEstatisticas] = useState(null);

  useEffect(() => {
    listarEstatisticasBilingue().then((response) => {
      setEstatisticas(response.data);
    });
  }, []);

  if (!estatisticas) {
    return <Spinner />;
  }

  const totalInscritos = estatisticas.quantidadeInscritosMilitar +
                         estatisticas.quantidadeInscritosGastronomiaHotelaria +
                         estatisticas.quantidadeInscritosAgronegocioTurismoRural;

  const pieData = {
    labels: ['Militar', 'Gastronomia', 'Agronegócio'],
    datasets: [
      {
        label: 'Inscrições',
        data: [
          estatisticas.quantidadeInscritosMilitar,
          estatisticas.quantidadeInscritosGastronomiaHotelaria,
          estatisticas.quantidadeInscritosAgronegocioTurismoRural
        ],
        backgroundColor: ['#3f51b5', '#ff5722', '#4caf50'], // Correspondendo as cores
        hoverBackgroundColor: ['#3949ab', '#e64a19', '#388e3c']
      },
    ],
  };

  const pieOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `Inscritos: ${tooltipItem.raw}`;
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  const barData = {
    labels: ['Total de Inscrições (Válidas)', 'Total de Inscrições (Duplicadas)'],
    datasets: [
      {
        label: 'Porcentagem',
        data: [
          (estatisticas.totalInscricoesPorCpf / totalInscritos) * 100,
          (estatisticas.totalInscricoes / totalInscritos) * 100 - (estatisticas.totalInscricoesPorCpf / totalInscritos) * 100
        ],
        backgroundColor: ['#ff9800', '#03a9f4'],
        hoverBackgroundColor: ['#f57c00', '#0288d1'],
      },
    ],
  };

  const barOptions = {
    indexAxis: 'y',
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.raw.toFixed(2)}%`;
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 100,
        ticks: {
          callback: function (value) {
            return `${value}%`;
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <BaseBilingue>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Card sx={{ backgroundColor: '#f5f5f5', borderLeft: '5px solid #3f51b5' }}>
              <CardContent>
                <Box display="flex" alignItems="center">
                  <MilitaryTechIcon style={{ fontSize: 50, color: '#3f51b5' }} />
                  <Box ml={2}>
                    <Typography variant="h6" component="div">
                      Inscritos Militar
                    </Typography>
                    <Typography variant="h5">
                      {estatisticas.quantidadeInscritosMilitar}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card sx={{ backgroundColor: '#f5f5f5', borderLeft: '5px solid #ff5722' }}>
              <CardContent>
                <Box display="flex" alignItems="center">
                  <RestaurantIcon style={{ fontSize: 50, color: '#ff5722' }} />
                  <Box ml={2}>
                    <Typography variant="h6" component="div">
                      Inscritos Gastronomia
                    </Typography>
                    <Typography variant="h5">
                      {estatisticas.quantidadeInscritosGastronomiaHotelaria}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card sx={{ backgroundColor: '#f5f5f5', borderLeft: '5px solid #4caf50' }}>
              <CardContent>
                <Box display="flex" alignItems="center">
                  <AgricultureIcon style={{ fontSize: 50, color: '#4caf50' }} />
                  <Box ml={2}>
                    <Typography variant="h6" component="div">
                      Inscritos Agronegócio
                    </Typography>
                    <Typography variant="h5">
                      {estatisticas.quantidadeInscritosAgronegocioTurismoRural}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Box mt={4}>
          {/* Status - Militar */}
          <Typography variant="h5" gutterBottom>
            Orientação Militar
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Card sx={{ backgroundColor: '#e3f2fd' }}>
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <HourglassEmptyIcon style={{ color: '#0288d1', fontSize: 30 }} />
                    <Box ml={1}>
                      <Typography>Em Análise</Typography>
                      <Typography variant="h6">{estatisticas.militarEmAnalise}</Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={3}>
              <Card sx={{ backgroundColor: '#e3f2fd' }}>
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <CancelIcon style={{ color: '#d32f2f', fontSize: 30 }} />
                    <Box ml={1}>
                      <Typography>Cancelado</Typography>
                      <Typography variant="h6">{estatisticas.militarCancelado}</Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={3}>
              <Card sx={{ backgroundColor: '#e3f2fd' }}>
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <CheckCircleIcon style={{ color: '#388e3c', fontSize: 30 }} />
                    <Box ml={1}>
                      <Typography>Deferido</Typography>
                      <Typography variant="h6">{estatisticas.militarDeferido}</Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={3}>
              <Card sx={{ backgroundColor: '#e3f2fd' }}>
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <HighlightOffIcon style={{ color: '#f57c00', fontSize: 30 }} />
                    <Box ml={1}>
                      <Typography>Indeferido</Typography>
                      <Typography variant="h6">{estatisticas.militarIndeferido}</Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Status - Gastronomia */}
          <Box mt={4}>
            <Typography variant="h5" gutterBottom>
              Orientação em Gastronomia e Hotelaria
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Card sx={{ backgroundColor: '#ffe0b2' }}>
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <HourglassEmptyIcon style={{ color: '#ff9800', fontSize: 30 }} />
                      <Box ml={1}>
                        <Typography>Em Análise</Typography>
                        <Typography variant="h6">{estatisticas.gastronomiaEmAnalise}</Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={3}>
                <Card sx={{ backgroundColor: '#ffe0b2' }}>
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <CancelIcon style={{ color: '#d32f2f', fontSize: 30 }} />
                      <Box ml={1}>
                        <Typography>Cancelado</Typography>
                        <Typography variant="h6">{estatisticas.gastronomiaCancelado}</Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={3}>
                <Card sx={{ backgroundColor: '#ffe0b2' }}>
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <CheckCircleIcon style={{ color: '#388e3c', fontSize: 30 }} />
                      <Box ml={1}>
                        <Typography>Deferido</Typography>
                        <Typography variant="h6">{estatisticas.gastronomiaDeferido}</Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={3}>
                <Card sx={{ backgroundColor: '#ffe0b2' }}>
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <HighlightOffIcon style={{ color: '#f57c00', fontSize: 30 }} />
                      <Box ml={1}>
                        <Typography>Indeferido</Typography>
                        <Typography variant="h6">{estatisticas.gastronomiaIndeferido}</Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>

          {/* Status - Agronegócio */}
          <Box mt={4}>
            <Typography variant="h5" gutterBottom>
              Orientação em Agronegócio e Turismo Rural
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Card sx={{ backgroundColor: '#e8f5e9' }}>
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <HourglassEmptyIcon style={{ color: '#66bb6a', fontSize: 30 }} />
                      <Box ml={1}>
                        <Typography>Em Análise</Typography>
                        <Typography variant="h6">{estatisticas.agronegocioEmAnalise}</Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={3}>
                <Card sx={{ backgroundColor: '#e8f5e9' }}>
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <CancelIcon style={{ color: '#d32f2f', fontSize: 30 }} />
                      <Box ml={1}>
                        <Typography>Cancelado</Typography>
                        <Typography variant="h6">{estatisticas.agronegocioCancelado}</Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={3}>
                <Card sx={{ backgroundColor: '#e8f5e9' }}>
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <CheckCircleIcon style={{ color: '#388e3c', fontSize: 30 }} />
                      <Box ml={1}>
                        <Typography>Deferido</Typography>
                        <Typography variant="h6">{estatisticas.agronegocioDeferido}</Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={3}>
                <Card sx={{ backgroundColor: '#e8f5e9' }}>
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <HighlightOffIcon style={{ color: '#f57c00', fontSize: 30 }} />
                      <Box ml={1}>
                        <Typography>Indeferido</Typography>
                        <Typography variant="h6">{estatisticas.agronegocioIndeferido}</Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>

          <Box mt={4}>
            {/* Totais */}
            <Typography variant="h5" gutterBottom>
              Totais
            </Typography>
            <Grid container spacing={2}>
  {/* Totais */}
  <Grid item xs={6}>
    <Card sx={{ backgroundColor: '#fafafa' }}>
      <CardContent>
        <Box display="flex" alignItems="center">
          <AssessmentIcon style={{ fontSize: 40, color: '#3f51b5' }} />
          <Box ml={2}>
            <Typography>Total de Inscrições</Typography>
            <Typography variant="h6">{estatisticas.totalInscricoes}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  </Grid>

  <Grid item xs={6}>
    <Card sx={{ backgroundColor: '#fafafa' }}>
      <CardContent>
        <Box display="flex" alignItems="center">
          <AccountCircleIcon style={{ fontSize: 40, color: '#4caf50' }} />
          <Box ml={2}>
            <Typography>Total de Inscrições por CPF</Typography>
            <Typography variant="h6">{estatisticas.totalInscricoesPorCpf}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  </Grid>
</Grid>


            {/* Gráficos: Pizza e Barra Horizontal */}
            <Box mt={6}>
              <Grid container spacing={2} mt={4}>
                <Grid item xs={6}>
                  <Card>
                    <CardContent>
                      <Typography variant="h5" gutterBottom>
                        Distribuição de Inscrições por Tipo
                      </Typography>
                      <Box height={300}>
                        <Pie data={pieData} options={pieOptions} />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={6}>
                  <Card>
                    <CardContent>
                      <Typography variant="h5" gutterBottom>
                        Porcentagem de Total de Inscrições e CPF
                      </Typography>
                      <Box height={300}>
                        <Bar data={barData} options={barOptions} />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </div>
    </BaseBilingue>
  );
};

export default BilingueDashboardPage;

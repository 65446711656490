import { combineReducers } from 'redux';

import user from './user'
import panel from './panel'

const appReducer = combineReducers({
  user,
  panel
});

const rootReducer = (state, action) => {
    if (action.type === 'login/LOGOUT_REQUEST') {
      state = undefined;
    } 
  return appReducer(state, action);
};

export default rootReducer;

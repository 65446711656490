import api from "..";

async function listarAvisos(unidadeId, turmaId, alunoId, skip = 0, limit = 20) {
  var url =  `familiaconectada/avisos?unidadeId=${unidadeId}&turmaId=${turmaId}&alunoId=${alunoId}&skip=${skip}&limit=${limit}`
  return await api
    .get(url)
    .then(async (res) => {
      return res;
    });
};

async function adicionarAvisos(data) {
  return await api
    .post(`familiaconectada/avisos`, data)
    .then(async (res) => {
      return res.data
    });
}

async function loginAluno(data) {
  return await api
    .post(`familiaconectada/aluno/login`, data)
    .then(async (res) => {
      return res
    });
}

async function loginResponsavel(data) {
  return await api
    .post(`familiaconectada/responsavel/login`, data)
    .then(async (res) => {
      return res
    });
}

export {
    adicionarAvisos,
    listarAvisos,
    loginResponsavel,
    loginAluno

}
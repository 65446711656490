import Breadcrumb from '../../../../../../components/breadcrumb'
import BaseChamadaPublica from '../../../baseChamadaPublica';
import Alert from '@mui/material/Alert';
import Table from '../../../../../../components/table';
import { useMemo, useState,useEffect } from 'react';
import { useQuery } from 'react-query';
import { getEscolaridades } from '../../../../../../services/escolaridades';
import { getInscricoesRelatorio } from '../../../../../../services/processos/chamadaPublica';
import handleDownloadExcel from '../../../../../../utils/handleDownloadExcel';
import { toast } from 'react-toastify';


const RelatorioMatriculadosPorEscolaridadePage = () => {

  const [escolaridade, setEscolaridade] = useState(0)
  const [skip, setSkip] = useState(0);
  const [status, setStatus] = useState(3);
  const [exportar, setExportar] = useState(0);
  const [limit, setLimit] = useState(20);
  const [data, setData] = useState([]);
  const nomeArquivo = 'relatotio-chamada-publica-matriculados-por-escolaridade-quantitativo.xlsx'


  const escolaridades = [
    { "id": 1, "nome": "1º ANO" },
    { "id": 2, "nome": "2º ANO" },
    { "id": 3, "nome": "3º ANO" },
    { "id": 4, "nome": "4º ANO" },
    { "id": 5, "nome": "5º ANO" },
    { "id": 6, "nome": "6º ANO" },
    { "id": 7, "nome": "7º ANO" },
    { "id": 8, "nome": "8º ANO" },
    { "id": 9, "nome": "9º ANO" },
    { "id": 10, "nome": "CRECHE I" },
    { "id": 11, "nome": "CRECHE II" },
    { "id": 12, "nome": "CRECHE III" },
    { "id": 13, "nome": "CRECHE IV" },
    { "id": 24, "nome": "PRÉ I" },
    { "id": 26, "nome": "PRÉ II" }
];


async function fetchInscricoes(value){

  setEscolaridade(value)

  if(value.length > 0){
    var response = await getInscricoesRelatorio(0, value, 0, 0, 0, '', skip, limit, exportar, status)
    setData(response || [])
  }
}

const exportarDados = async () => {
    
  if(data.length === 0 || data?.total === 0){
    return toast.warning('Não há dados para exportar.')
  }

  try {

  const relatorioData = await getInscricoesRelatorio(0, escolaridade, 0, 0, 0, '', 0, data?.total, 1, status)

  if (relatorioData) {
    return handleDownloadExcel(relatorioData, nomeArquivo);
  } else {
      return toast.error('Falha ao obter o relatório');
      // Tratar falha na obtenção do relatório
  }
  } catch (error) {
    return toast.error('Erro ao buscar relatório');
    // Tratar erro na chamada da função
  }
 
  // return await handleDownloadExcel(relatorioData, titulo);
}

useEffect(() => {
  fetchInscricoes(escolaridade)
}, [limit, skip])


  const columns = useMemo(
      () => [
      { Header: 'Unidade', accessor: 'unidadeDescricao' },
      { Header: 'Ano Escolaridade', accessor: 'escolaridadeDescricao' },
      { Header: 'Turno', accessor: 'turnoDescricao' },
      { Header: 'Quantidade', accessor: 'quantidade' },
      ],
      []
  )
    


return (
    <BaseChamadaPublica>
      <Breadcrumb
        title={'POR ESCOLARIDADE - QUANTITATIVO'}
        itens={['CHAMADA PUBLICA', 'RELATÓRIOS', 'MATRICULADOS', 'POR ESCOLARIDADE - QUANTITATIVO']}
        hasExcelButton 
        onClickDownload={() => exportarDados()} 
      />

<Alert variant="filled" severity="info" style={{marginBottom: 20}}>Resultado com base nas inscrições como primeira opção.</Alert>
<div className="row" style={{ marginBottom: 20 }}>
        <div className="col-12 col-md-12">
          <div className="form-group">
            <label htmlFor="escolaridade" className="form-label">
            Escolaridade
            </label>
            <select
              className="form-select"
              id="escolaridade"
              value={escolaridade}
              onChange={async e => {
                await fetchInscricoes(e.target.value)
              }}
            >
                <option value={''}></option>
              {escolaridades?.map(item => (
                              <option key={item.id} value={item.id}>
                                {item.nome}
                              </option>
                            ))}
              
            </select>
          </div>
        </div>
        </div>

        <div className="row">
        <div className="col-12">
          <div className="card">
        <Table
              columns={columns}
              data={data.data ? data.data : []}
              hasPagination
              limit={limit}
              setLimit={setLimit}
              skip={skip}
              setSkip={setSkip}
              totalItems={data?.total}
            />
            </div>
            </div>
            </div>

      </BaseChamadaPublica>

      
)
}

export default RelatorioMatriculadosPorEscolaridadePage
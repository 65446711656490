// Action Types

export const Types = {
  PROFILE: 'user/PROFILE',
};

// Reducer

const initialState = {
  user: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.PROFILE:
      return { ...state, user: action.payload.user };
    default:
      return state;
  }
}

// Action Creators
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Modulos from '../../pages/modulos';
import urls from '../../utils/urls';


const ModulosRoutes = () => (
    <React.Fragment>
        <Route path={urls.modulos} element={<Modulos />} /> 
    </React.Fragment>
);

export default ModulosRoutes;

import React from 'react';

// Example JSON data
const data = {
  escolaridade: "2º Ano",
  turma: "Z 1 Ano (DEMO)",
  turno: "1º",
  matricula: "02015607",
  filiacao: "TESTE | TESET",
  nascimento: "01/08/2024",
  ano: "2024",
  componentes_curriculares: [
    {
      nome: "LÍNGUA PORTUGUESA",
      notas: { "1T": "R.D.", "2T": 0, "3T": 0, "MF": 0 },
      faltas: { "1T": 0, "2T": 0, "3T": 0, "TF": 0 },
      horas_aulas: { "1T": 0, "2T": 0, "3T": 0, "total": 0 }
    },
    {
      nome: "MATEMÁTICA",
      notas: { "1T": "R.D.", "2T": 0, "3T": 0, "MF": 0 },
      faltas: { "1T": 0, "2T": 0, "3T": 0, "TF": 0 },
      horas_aulas: { "1T": 0, "2T": 0, "3T": 0, "total": 0 }
    },
    // Add the remaining subjects similarly...
  ],
  faltas_totais: 0,
  percentual_frequencia: "0%",
  resultado_final: "25/09/2024",
  carga_horaria: 0,
  dias_letivos: 200,
  observacoes: ""
};

const ReportCard = () => {
  return (
    <div>
      <h2>Escolaridade: {data.escolaridade}</h2>
      <h3>Turma: {data.turma} | Turno: {data.turno}</h3>
      <p>Matrícula: {data.matricula} | Filiação: {data.filiacao} | Nascimento: {data.nascimento} | Ano: {data.ano}</p>
      
      <table border="1" cellPadding="10">
        <thead>
          <tr>
            <th>Componentes Curriculares</th>
            <th>1ºT</th>
            <th>2ºT</th>
            <th>3ºT</th>
            <th>M.F.</th>
            <th>Faltas 1ºT</th>
            <th>Faltas 2ºT</th>
            <th>Faltas 3ºT</th>
            <th>T.F.</th>
            <th>Horas 1ºT</th>
            <th>Horas 2ºT</th>
            <th>Horas 3ºT</th>
            <th>Total Horas</th>
          </tr>
        </thead>
        <tbody>
          {data.componentes_curriculares.map((componente, index) => (
            <tr key={index}>
              <td>{componente.nome}</td>
              <td>{componente.notas["1T"]}</td>
              <td>{componente.notas["2T"]}</td>
              <td>{componente.notas["3T"]}</td>
              <td>{componente.notas["MF"]}</td>
              <td>{componente.faltas["1T"]}</td>
              <td>{componente.faltas["2T"]}</td>
              <td>{componente.faltas["3T"]}</td>
              <td>{componente.faltas["TF"]}</td>
              <td>{componente.horas_aulas["1T"]}</td>
              <td>{componente.horas_aulas["2T"]}</td>
              <td>{componente.horas_aulas["3T"]}</td>
              <td>{componente.horas_aulas["total"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
      
      <p>Faltas Totais: {data.faltas_totais}</p>
      <p>% Frequência: {data.percentual_frequencia}</p>
      <p>Resultado Final: {data.resultado_final}</p>
      <p>Carga Horária: {data.carga_horaria}</p>
      <p>Dias Letivos: {data.dias_letivos}</p>
      <p>Observações: {data.observacoes}</p>
    </div>
  );
};

export default ReportCard;

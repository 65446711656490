import React from 'react';
import { Routes, Route } from 'react-router-dom';
import urlsBilingue from '../../../pages/processos/bilingue/private/urlsBilingue';
import HomeBilingue from '../../../pages/processos/bilingue/private/homeBilingue';
import BilingueInscricoes from '../../../pages/processos/bilingue/private/inscricoes';
import BilingueIndeferir from '../../../pages/processos/bilingue/private/inscricoes/indeferir';
import BilingueVisualizarFormulario from '../../../pages/processos/bilingue/public/visualizacaoForm';
import ProcessoSeletivoBilingue from '../../../pages/processos/bilingue/public';
import ProcessoSeletivoCivico from '../../../pages/processos/bilingue/public/civico';
import ProcessoSeletivoGastronomia from '../../../pages/processos/bilingue/public/gastronomia';
import ProcessoSeletivo2024Form from '../../../pages/processos/bilingue/public/2024';
import FormularioBilingue2024Militar from '../../../pages/processos/bilingue2024/forms/militar';
import FormularioBilingue2024Visualizacao from '../../../pages/processos/bilingue2024/visualizacao';
import BilingueAtualizarIncricaoPage from '../../../pages/formularios/bilingue/pages/BilingueAtualizarIncricaoPage';
import BilingueDashboardPage from '../../../pages/formularios/bilingue/pages/BilingueDashboardPage';
import BilingueInscricoesPage from '../../../pages/formularios/bilingue/pages/BilingueInscricoesPage';


const enable = process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEDUC' || process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'R3MAIS';

const BilingueRoutes = () => (
    <React.Fragment>
    {enable && (
    <>
      <Route path={urlsBilingue.dashboard} element={<HomeBilingue />} />
      <Route path={urlsBilingue.inscricoes} element={<BilingueInscricoes />} />
      {/* <Route path={urlsBilingue.formularioCivico} element={<ProcessoSeletivoCivico />} />
        <Route path={urlsBilingue.formularioGastronomia} element={<ProcessoSeletivoGastronomia />} /> */}
        <Route path={urlsBilingue.formulario2024} element={<ProcessoSeletivo2024Form />} />
        <Route path={urlsBilingue.formulario} element={<ProcessoSeletivoBilingue />} />
      <Route path={urlsBilingue.visualizarId} element={<BilingueVisualizarFormulario />} />
      <Route path={urlsBilingue.indeferirId} element={<BilingueIndeferir />} />
      <Route path={urlsBilingue.formulario2024Militar} element={<FormularioBilingue2024Militar />} />
      <Route path={urlsBilingue.formulario2024VisualizacaoId} element={<FormularioBilingue2024Visualizacao />} />
      <Route path={urlsBilingue.inscricoesAtualizar2024} element={<BilingueAtualizarIncricaoPage />} />
      <Route path={urlsBilingue.dashboard2024} element={<BilingueDashboardPage />} />
      <Route path={urlsBilingue.inscricoes2024} element={<BilingueInscricoesPage />} />
    </>
    )}
  </React.Fragment>
);

export default BilingueRoutes;

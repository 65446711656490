import React, { useState, useEffect } from "react";
import BaseGestao from "../../gestao/baseGestao";
import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import { useNavigate, useLocation } from "react-router-dom";
import Breadcrumb from "../../../components/breadcrumb";
import Frente from "./frente";
import Verso from "./verso";
import { getAluno } from "../../../services/alunos";
import { useQuery } from "react-query";
import urlsGestao from "../../gestao/urlsGestao";
import urlsGestaoUnidades from "../../gestao-unidades/urlsGestaoUnidades";

const AlunosCarteirinhaPage = (gestao) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const BaseComponent = gestao.gestao ? BaseGestao : BaseGestaoUnidades;
  const BasePath = gestao.gestao ? "Gestão" : "Gestão Unidades";
  const impressaoPage = gestao.gestao
    ? urlsGestao.alunosCarteirinhaImpressao
    : urlsGestaoUnidades.alunosCarteirinhaImpressao;

  const [activeTab, setActiveTab] = useState("front");
  const [aluno, setAluno] = useState({});

  const { data, loadingAluno } = useQuery(
    "getAluno",
    () => state.id !== undefined && getAluno(state.id),
    {
      retry: false,
      enabled: true,
      onSuccess: (response) => {
        setAluno({
          ...response,
          unidade: state.aluno.unidade,
          escolaridade: state.aluno.escolaridade,
          turno: state.aluno.turno,
          turma: state.aluno.turma,
        });
      },
    }
  );

  useEffect(() => {
    if (state.id !== undefined && data === undefined) {
      getAluno(state.id);
    }
  }, []);

  console.log(aluno);

  function print() {
    navigate(impressaoPage, { state: { aluno: aluno } });
  }

  return (
    <BaseComponent>
      <Breadcrumb
        title={"Carteirinha"}
        itens={[BasePath, "Alunos", "Documentos"]}
      />
      <div style={{ marginBottom: "20px" }}>
        <button
          onClick={() => setActiveTab("front")}
          style={{
            padding: "10px",
            marginRight: "5px",
            border: "none",
            cursor: "pointer",
            backgroundColor: activeTab === "front" ? "#FFCC00" : "#003366",
            color: activeTab === "front" ? "#003366" : "#FFCC00",
            borderRadius: "4px",
          }}
        >
          Frente
        </button>
        <button
          onClick={() => setActiveTab("back")}
          style={{
            padding: "10px",
            marginRight: "5px",
            border: "none",
            cursor: "pointer",
            backgroundColor: activeTab === "back" ? "#FFCC00" : "#003366",
            color: activeTab === "back" ? "#003366" : "#FFCC00",
            borderRadius: "4px",
          }}
        >
          Verso
        </button>
        <button
          onClick={() => print()}
          style={{
            padding: "10px",
            marginRight: "5px",
            border: "none",
            cursor: "pointer",
            backgroundColor: "green",
            color: "white",
            borderRadius: "4px",
          }}
        >
          Imprimir
        </button>
      </div>
      <div className="card-content">
        {activeTab === "front" ? (
          <div style={{ width: "440px" }}>
            <Frente data={aluno} />
          </div>
        ) : (
          <div style={{ width: "440px" }}>
            <Verso data={aluno} />
          </div>
        )}
      </div>
    </BaseComponent>
  );
};

export default AlunosCarteirinhaPage;

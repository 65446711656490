import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ChatPage from '../pages/chat';


const GlobalRoutes = () => (
    <React.Fragment>
        <Route path="/chat" element={<ChatPage />} /> 
    </React.Fragment>
);

export default GlobalRoutes;

import React, { useState, useEffect } from 'react';
import BaseGestaoUnidades from '../../gestao-unidades/baseGestaoUnidades';
import BaseDocente from '../../docente/baseDocente';
import { store } from '../../../store';
import Breadcrumb from '../../../components/breadcrumb';
import EscolaridadeSelect from '../../../utils/escolaridadeSelect';
import { listarAlunos, listarAulas } from '../../../services/ficha-avaliacao';
import { useQuery } from 'react-query';
import {postQuantidadeAula} from '../../../services/ficha-avaliacao';
import { toast } from 'react-toastify';
import TabelaFundamental from '../segundo-ano/table';
import urlsDocente from '../../docente/urlsDocente';
import urlsGestaoUnidades from '../../gestao-unidades/urlsGestaoUnidades';
import { quadroHorarioServidorTurno, quadroHorarioServidorTurma, quadroHorarioServidorEscolaridade } from '../../../services/quadro-horario';
import { getDisciplinasByTurmaServidor } from '../../../services/disciplinas';


const FichaAvaliacaoVisualizacaoTerceiroAoNonoAno = (gestao) => {
    const [isOpen, setIsOpen] = useState(false);
    const [unidade, setUnidade] = useState(localStorage.getItem('unidadeID'));
    const [escolaridade, setEscolaridade] = useState(0);
    const [turno, setTurno] = useState(0);
    const [turma, setTurma] = useState(0);
    const [disciplina, setDisciplina] = useState(0);
    const [ciclo, setCiclo] = useState(0);
    const onMenuOpen = () => setIsOpen(true);
    const onMenuClose = () => setIsOpen(false);

    const ciclos = [
        { value: 1, label: '1º TRIMESTRE' },
        { value: 2, label: '2º TRIMESTRE' },
        { value: 3, label: '3º TRIMESTRE' },
      ];

    const currentUser = store.getState()['user']['user'];

    const { data: alunos, isLoading: loadingAlunos, refetch: refetchAlunos } = useQuery('getAlunos', () =>
        turma !== 0 ? listarAlunos(turma, ciclo, disciplina) : null,
        {
            retry: 3,
            enabled: turma !== 0 && ciclo !== 0 && disciplina !== 0,
        }
    )

    const [totalAulasDadas, setTotalAulasDadas] = useState('');

    const { data: aulas, isLoading: loadingAulas, refetch: refetchAulas } = useQuery(
      ['getAulas', disciplina],
      () => (disciplina > 0 ? listarAulas(turma, ciclo, disciplina) : null),
      {
        enabled: disciplina > 0,
        onSuccess: data => {
          console.log(data?.totalAulas);
          if (data !== null) {
            setTotalAulasDadas(data.totalAulas);
          } else {
            setTotalAulasDadas('');
          }
        }
      }
    );

    const [turnos, setTurnos] = useState([]);
  const { refetch: refetchTurnos, loadingTurnos } = useQuery(
    'getTurnos',
    () => (unidade !== null ? quadroHorarioServidorTurno(servidorId, unidade, escolaridade) : null),
    {
      retry: 3,
      enabled: unidade !== null && escolaridade !== null,
      onSuccess: (data) => {
        if(data === null){
          return
        }
        var dados = [];
        data.forEach((item) => {
          if (!dados.some((d) => d.value === item.id)) {
            dados.push({ value: item.id, label: item.descricao });
          }
        });

        setTurnos(dados);
      },
    }
  );

  const [disciplinas, setDisciplinas] = useState([]);

  const { refetch: refetchDisciplinas, loadingDisciplinas } = useQuery(
    'getDisciplinas',
    () => (turma !== null && turma != 0 ? getDisciplinasByTurmaServidor(turma, servidorId) : null),
    {
      retry: 3,
      enabled: turma !== null && turma != 0,
      onSuccess: (data) => {
        if (data != null){
            var dados = [];
            data.forEach((item) => { {
                if (!dados.some((d) => d.value === item.id)) {
                  dados.push({ value: item.id, label: item.descricao });
                }
              }
              setDisciplinas(dados);
            });
        }
        

        
      },
    }
  );

  useEffect(() => {
    if (unidade !== null && escolaridade !== null) {
        refetchTurnos();
    }
  }, [escolaridade, refetchTurnos]);

  const handleChangeEscolaridade = async (e) => {
    await setEscolaridade(e.target.value);
    await setTurno(0);
    await setCiclo(0)
    await setTurma(0)
    await setDisciplina(0)
    await setCiclo(0)
    await localStorage.removeItem('turmaIdImpressaoTerceiroAno');
    await localStorage.removeItem('disciplinaIdImpressaoTerceiroAno');
  };

  const handleChangeTurno = async (e) => {
    await setTurno(e.target.value);
    await setCiclo(0)
    await setTurma(0)
    await setDisciplina(0)
    await setCiclo(0)
    await localStorage.removeItem('turmaIdImpressaoTerceiroAno');
    await localStorage.removeItem('disciplinaIdImpressaoTerceiroAno');
  };

  const handleChangeTrimestre = async (e) => {
    await setCiclo(e.target.value);
    refetchDisciplinas()
    await setDisciplina(0)
  };

  const handleChangeDisciplina = async (e) => {
    await setDisciplina(e.target.value);
    await refetchAlunos();
    await localStorage.setItem('disciplinaIdImpressaoTerceiroAno', e.target.value);
  };

  const [turmas, setTurmas] = useState([]);
  const { refetch: refetchTurmas, loadingTurmas } = useQuery(
    'getTurmas',
    () => (unidade !== null ? quadroHorarioServidorTurma(servidorId, unidade, escolaridade, turno) : null),
    {
      retry: 3,
      enabled: unidade !== null && escolaridade !== null && turno !== null,
      onSuccess: (data) => {
        if(data === null){
          return
        }
        var dados = [];
        data.forEach((item) => {
          if (!dados.some((d) => d.value === item.id)) {
            dados.push({ value: item.id, label: item.descricao });
          }
        });

        setTurmas(dados);
      },
    }
  );

  useEffect(() => {
    if (unidade !== null && escolaridade !== null && turno !== null) {
        refetchTurmas();
    }
  }, [turno, refetchTurmas]);

  const handleChangeTurma = async (e) => {
    await setTurma(e.target.value);
    await setCiclo(0)
    await setDisciplina(0)
    await localStorage.setItem('turmaIdImpressaoTerceiroAno', e.target.value);
  };

    let BaseComponent;
    let BasePath;
    let servidorId;
    let pageTitle = 'Ficha Avaliação | 3º ao 9º Ano';
    let urlImprir = ''

    if (!gestao.gestao && !gestao.docente) {
        BaseComponent = BaseGestaoUnidades;
        BasePath = 'Gestão Unidades';
        servidorId = 0;
        urlImprir = urlsGestaoUnidades.fichaAvaliacaoTerceiroNonoAnoImpressao;

    } else if (gestao.docente) {
        BaseComponent = BaseDocente;
        BasePath = 'Docente';
        servidorId = currentUser?.servidorId;
        urlImprir = urlsDocente.fichaAvaliacaoTerceiroNonoAnoImpressao;
    }

    useEffect(() => {
        setTurno(0)
    }, [escolaridade]);

    useEffect(() => {
    }, [turno]);

    useEffect(() => {
      console.log(disciplina)
      console.log(turma)
      console.log(ciclo)
        refetchAulas(); 
    }, [disciplina, refetchAulas]);

    const [escolaridades, setEscolaridades] = useState([]);
    let tipo = 4
    const tipo1 = [12,13,14,15,24,26]
    const tipo2 = [1]
    const tipo3 = [2]
    const tipo4 = [3,4,5,6,7,8,9]

  const { refetch: refetchEscolaridades, loadingEscolariades } = useQuery(
    'getEscolaridades',
    () => (unidade !== null ? quadroHorarioServidorEscolaridade(servidorId, unidade) : null),
    {
      retry: 3,
      enabled: unidade !== null,
      onSuccess: (data) => {
        if(data === null){
          return
        }
        var dados = [];
        data.forEach((item) => {
          if (
            (tipo === 1 && tipo1.includes(item.id)) ||
            (tipo === 2 && tipo2.includes(item.id)) ||
            (tipo === 3 && tipo3.includes(item.id)) ||
            (tipo === 4 && tipo4.includes(item.id))
          ) {
            if (!dados.some((d) => d.value === item.id)) {
              dados.push({ value: item.id, label: item.descricao });
            }
          }
        });

        setEscolaridades(dados);
      },
    }
  );

    async function handleBlurAulasDadas(e) {
        let data = {
            disciplinaId: disciplina,
            trimestreId: ciclo,
            turmaId: turma,
            quantidadeAulasDadas: e.target.value
        }

        const response = await postQuantidadeAula(data);

        if (response?.statusCode === undefined){
            return
        }
        if (response.statusCode === 200) {
            toast.success('Aulas lançadas com sucesso!');
        }
        else{
            toast.error('Erro ao salvar aulas!');
        }
    }

    function clickImprimir(){
        if (ciclo == 0){
            toast.warn('Selecione um trimestre para imprimir!')
            return
        }
        window.open(urlImprir, '_blank')
    }

    return (
        <BaseComponent>
            <Breadcrumb 
            title={pageTitle} 
            itens={[BasePath]}  
            imprimir={{ link: urlImprir, text: "Imprimir" }}
          onClickImprimir={clickImprimir}
          />
            <form id='form'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className="card-body">
                                <>
                                    <div className='row'>
                                        <div className="col-12 col-md-4">
                                        <div className="form-group">
            <label htmlFor="escolaridadeId" className="form-label">
              Escolaridades
            </label>
            <select
              className="form-select"
              id="escolaridadeId"
              value={escolaridade}
              onChange={async e => {
                await handleChangeEscolaridade(e);
              }}
            >
              {loadingEscolariades ? (
                <option value={0}></option>
              ) : (
                <>
                  <option value={0}></option>
                  {escolaridades &&
                    escolaridades.map(item => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                </>
              )}
            </select>
          </div>
                                     

                                        </div>
                                        <div className="col-12 col-md-4">
                                        <div className="form-group">
            <label htmlFor="turnoID" className="form-label">
              Turnos
            </label>
            <select
              className="form-select"
              id="turnoID"
              value={turno}
              onChange={async e => {
                await handleChangeTurno(e);
              }}
            >
              {loadingTurnos ? (
                <option value={0}></option>
              ) : (
                <>
                  <option value={0}></option>
                  {turnos &&
                    turnos.map(item => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                </>
              )}
            </select>
          </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                        <div className="form-group">
            <label htmlFor="turmaID" className="form-label">
              Turmas
            </label>
            <select
              className="form-select"
              id="turmaID"
              value={turma}
              onChange={async e => {
                await handleChangeTurma(e)
              }}
            >
              {loadingTurmas ? (
                <option value={0}></option>
              ) : (
                <>
                  <option value={0}></option>
                  {turmas &&
                    turmas.map(item => (
                      
                     
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                </>
              )}
            </select>
          </div>
                                        </div>
                                        {/* Add more form elements here */}
                                    </div>

                                    <div className='row' style={{marginTop: 20}}>
                                    <div className="col-12 col-md-4">
                                            <div className="form-group">
            <label htmlFor="trimestreId" className="form-label">
              Trimestres
            </label>
            <select
              className="form-select"
              id="trimestreId"
              value={ciclo}
              onChange={async e => {
                await handleChangeTrimestre(e);
              }}
            >
              
                <>
                  <option value={0}></option>
                  {ciclos &&
                    ciclos.map(item => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                </>
             
            </select>
          </div>
          </div>

          <div className="col-12 col-md-4">
                                            <div className="form-group">
            <label htmlFor="disciplinaId" className="form-label">
              Disciplinas
            </label>
            <select
              className="form-select"
              id="disciplinaId"
              value={disciplina}
              onChange={async e => {
                await handleChangeDisciplina(e);
              }}
            >
              {loadingDisciplinas ? (
                <option value={0}></option>
              ) : (
                <>
                  <option value={0}></option>
                  {disciplinas &&
                    disciplinas.map(item => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                </>
              )}
            </select>
          </div>
          </div>

          {/* <div className="col-12 col-md-4">
                                            <label style={{marginBottom: 10}} htmlFor="aulasDadas" id="aria-label">Horas / Aulas</label>
<input
    className="form-control"
    type="number"
    aria-labelledby="aria-label"
    id="aulasDadas"
    name="aulasDadas"
    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
    defaultValue={aulas?.registro === true ? aulas?.totalAulas : null}
    // placeholder="Digite aqui a quantidade de aulas dadas nesse trimestre para essa disciplina."
    onBlur={(e) => { handleBlurAulasDadas(e) }}
/>

                                                </div> */}

                                            <div className='row' style={{marginTop: 30}}>
                                            <h5>Lançamento</h5>
                                            <div className="col-12 col-md-2">
                    <label style={{ marginBottom: 10 }} htmlFor="aulasDadas" id="aria-label">Horas / Aulas</label>
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="number"
                        aria-labelledby="aria-label"
                        id="aulasDadas"
                        name="aulasDadas"
                        value={totalAulasDadas} // Bind input to state
                        onChange={(e) => setTotalAulasDadas(e.target.value)} // Handle input change
                      />
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={(e) => { handleBlurAulasDadas({ target: { value: totalAulasDadas } }) }}
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                                            
                                                </div>
                                            
                                            </div>
                                
                                            <div className='row' style={{ marginTop: 20 }}>
                                            {disciplina > 0 && (
    <TabelaFundamental key={Math.floor(Math.random() * 100) + 1} alunos={alunos} disciplina={disciplina} ciclo={ciclo} turma={turma} />
)}
</div>

                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </BaseComponent>
    );
};

export default FichaAvaliacaoVisualizacaoTerceiroAoNonoAno;

import React from 'react'
import FormInput from '../../../../../../../components/formInput';

export default function DadosConta({ register, errors, setValue, canEdit, watch, servicoData, readOnly }) {
  const formatedNumber = (number) => {
    const numberFormatted = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number);
    setValue('valor', numberFormatted)
  }

  return (
    <div
      className="tab-pane fade"
      id="dadosConta"
      role="tabpanel"
      aria-labelledby="dadosConta-tab"
    >
      <div className="card-body">
        <div className="row">
          <FormInput
            register={register}
            errors={errors}
            atribute="tipoDeServico"
            label="Tipo de serviço"
            readOnly={servicoData ? !readOnly : false}
          />
        </div>

        <div className="row" style={{ marginTop: '25px' }}>
          <div className="col-md-6">
            <FormInput
              register={register}
              errors={errors}
              onChange={e => formatedNumber(e)}
              onKeyDownCapture={e => e === 'Backspace' && setValue('valor', '')}
              atribute="valor"
              label="Valor"
              readOnly={servicoData ? !readOnly : false}
            />
          </div>
          <div className="col-md-6">
            <FormInput
              register={register}
              errors={errors}
              type="date"
              atribute="dataPagamento"
              label="Data"
              readOnly={servicoData ? !readOnly : false}
            />
          </div>
        </div>

        <div className="row" style={{ marginTop: '25px' }}>
          <div className="col-md-6">
            <FormInput
              register={register}
              errors={errors}
              type="file"
              atribute="anexo"
              label="Anexo"
              readOnly={servicoData ? !readOnly : false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

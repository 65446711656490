import api from "..";
import { mock } from "./mock";

async function listarFichaIndividualInfantil(alunoId) {
  return await api
    .get(`fichaIndividual/infantil?alunoId=${alunoId}`)
    .then(async (res) => {
      const { data } = res;
      return data?.data;
    });
}

async function listarFichaIndividualFundamental(alunoId) {
   return await api
  .get(`fichaIndividual/fundamental?alunoId=${alunoId}`)
  .then(async (res) => {
    const { data } = res;
    return data?.data;
  });
}

async function listarFichaIndividualFundamentalEja(alunoId) {
  return await api
 .get(`fichaIndividual/ejaFundamental?alunoId=${alunoId}`)
 .then(async (res) => {
   const { data } = res;
   return data?.data;
 });
}

async function salvarResultadoFicha(dados){
  return await api.post(`fichaIndividual/resultado`, dados)
  .then(async (res) => {
    const { data } = res;
    return data?.data;
  });
}

async function listarAtaResultadoFinalInfantil(turmaId) {
  return await api
    .get(`fichaIndividual/infantil/ataResultadoFinal?turmaId=${turmaId}`)
    .then(async (res) => {
      const { data } = res;
      return data?.data;
    });
}

async function listarAtaResultadoFinalAnosIniciais(turmaId) {
  return await api
    .get(`fichaIndividual/anosIniciais/ataResultadoFinal?turmaId=${turmaId}`)
    .then(async (res) => {
      const { data } = res;
      return data?.data;
    });
}

async function listarAtaResultadoFinalAnosFinais(turmaId) {
  return await api
    .get(`fichaIndividual/anosFinais/ataResultadoFinal?turmaId=${turmaId}`)
    .then(async (res) => {
      const { data } = res;
      return data?.data;
    });
}


export { listarFichaIndividualInfantil, listarFichaIndividualFundamental, salvarResultadoFicha, listarFichaIndividualFundamentalEja, listarAtaResultadoFinalInfantil, listarAtaResultadoFinalAnosIniciais, listarAtaResultadoFinalAnosFinais };

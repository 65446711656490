import { useEffect, useMemo, useState } from 'react';
import Breadcrumb from "../../../components/breadcrumb";
import Table from "../../../components/table";
import BaseGestaoUnidades from '../../gestao-unidades/baseGestaoUnidades';
import urlsGestaoUnidades from '../../gestao-unidades/urlsGestaoUnidades';
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query';

import { getAlunosTranferenciaEnviadas } from '../../../services/alunos';


const TransferenciasEnviadasPage = (gestao) => {

  const navigate = useNavigate()

  const unidadeId = localStorage.getItem('unidadeID');
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [data, setData] = useState([]);



const { isError, isLoading, refetch } = useQuery(
  'getAlunosTranferenciaEnviadas', () => getAlunosTranferenciaEnviadas(unidadeId, skip, limit),
  {
    retry: 0,
    enabled: true,
    onSuccess: (data) => {
      setData(data);
    }
  });

  useEffect(() => {
    refetch()
  }, [refetch, limit, skip])

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'alunoId' },
      { Header: 'Nome', accessor: 'nome' },
      {
        Header: 'PcD', accessor: 'pcd',
        Cell: ({ row }) => (
          <span className={`badge bg-${row.original.unidadeLocacao ? 'success' : 'danger'}`}>
            {row.original.pcd ? 'SIM' : 'NÃO'}
          </span>
        )
      },
      { Header: 'Unidade Envio', accessor: 'unidadeProximaDescricao' },
      //{ Header: 'Unidade Anterior ID', accessor: 'unidadeAnterior' },
      { Header: 'Escolaridade', accessor: 'escolaridadeProximaDescricao' },
      //{ Header: 'Escolaridade 2024', accessor: 'escolaridadeProxima' },
      {
        Header: 'Criação', accessor: 'criacao',
        Cell: ({ row }) => (
          row.original.criacao.split('T')[0].split('-').reverse().join('/')
        )
      },
    
    ],
    []
  )

  return (
    <BaseGestaoUnidades>
      <Breadcrumb title={'Transferências Enviadas'} itens={['Gestão Unidades', 'Transferências Enviadas', 'Lista Geral']} />
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
          <Table
                columns={columns}
                data={data?.data ? data?.data : []}
                hasPagination
                limit={data?.limit}
                setLimit={setLimit}
                skip={data?.skip}
                setSkip={setSkip}
                totalItems={data?.total}
                hiddenColluns={['unidadeAnterior', 'escolaridadeProxima']}
              />
          </div>
        </div>
      </div>
    </BaseGestaoUnidades>
  )

};

export default TransferenciasEnviadasPage;

import React, { forwardRef, useEffect, useState } from "react";
import { useTable } from "react-table";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

// Styled Pagination Section
const PaginationSection = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0.75rem 1.5rem",
  backgroundColor: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.divider}`,
}));

// Styled Select Controls
const SelectControls = styled("div")({
  display: "flex",
  alignItems: "center",
});

// Pagination Button
const PageButton = styled(IconButton)(({ theme }) => ({
  padding: "4px",
  color: theme.palette.primary.main,
  "&:disabled": {
    color: theme.palette.action.disabled,
  },
}));

// Number Button
const NumberButton = styled(MenuItem)(({ theme, active }) => ({
  minWidth: "40px",
  textAlign: "center",
  color: active ? theme.palette.primary.main : theme.palette.text.secondary,
  fontWeight: active ? "bold" : "normal",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// Styled Table
const ModernTable = styled(MuiTable)(({ theme }) => ({
  "& thead th": {
    fontWeight: "bold",
    color: theme.palette.primary.dark,
    textAlign: "center", // Center table headers
    padding: "12px",
    backgroundColor: theme.palette.background.default,
  },
  "& tbody td": {
    textAlign: "center", // Center table cells
    padding: "12px",
  },
  "& tbody tr:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const TableComponent = forwardRef(
  (
    {
      columns,
      data,
      hasPagination,
      limit = 20,
      setLimit,
      skip = 0,
      setSkip,
      totalItems,
      isBoardTable = false,
      hiddenColluns = [],
      overflowUnset = false,
    },
    ref
  ) => {
    const [itemsPerPage, setItemsPerPage] = useState(limit);
    const [currentPage, setCurrentPage] = useState(Math.ceil(skip / limit) + 1);
    const [buttonsAmount, setButtonsAmount] = useState([]);
    const [newData, setNewData] = useState(data);

    useEffect(() => {
      setCurrentPage(Math.ceil(skip / limit) + 1);
      setNewData(data);
    }, [data, skip, limit]);

    useEffect(() => {
      const pageCount = Math.ceil(totalItems / itemsPerPage);
      const buttons = Array.from({ length: pageCount }, (_, i) => i + 1);
      if (buttons.length === 0) {
        buttons.push(1);
        setCurrentPage(1);
      }
      setButtonsAmount(buttons);
    }, [itemsPerPage, totalItems]);

    const handlePageNumber = (page) => {
      setCurrentPage(page);
      setSkip((page - 1) * itemsPerPage);
    };

    const handleAmountView = (amount) => {
      setItemsPerPage(amount);
      setLimit(amount);
      setSkip(0);
    };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data: newData,
        initialState: { hiddenColumns: hiddenColluns },
      });

    return (
      <TableContainer
        component={Paper}
        elevation={3}
        sx={{
          overflow: overflowUnset ? "unset" : "auto",
          borderRadius: "12px",
        }}
      >
        <ModernTable ref={ref} {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
            {rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {isBoardTable
                    ? "Utilize os filtros para visualizar os quadros disponíveis"
                    : "Nenhum registro encontrado"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </ModernTable>
        {hasPagination && totalItems > 0 && (
          <PaginationSection>
            <SelectControls>
              <Typography variant="body2">Itens por página:</Typography>
              <Select
                onChange={(e) => handleAmountView(parseInt(e.target.value))}
                value={itemsPerPage}
                sx={{ mx: 1 }}
              >
                {[10, 20, 50].map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="body2">de {totalItems}</Typography>
            </SelectControls>
            <SelectControls>
              <PageButton
                onClick={() => handlePageNumber(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <NavigateBeforeIcon />
              </PageButton>
              <Select
                onChange={(e) => handlePageNumber(parseInt(e.target.value))}
                value={currentPage}
                sx={{ mx: 1, minWidth: 60 }}
              >
                {buttonsAmount.map((key) => (
                  <NumberButton
                    key={key}
                    value={key}
                    active={currentPage === key ? 1 : 0}
                  >
                    {key}
                  </NumberButton>
                ))}
              </Select>
              <PageButton
                onClick={() => handlePageNumber(currentPage + 1)}
                disabled={currentPage === buttonsAmount.length}
              >
                <NavigateNextIcon />
              </PageButton>
            </SelectControls>
          </PaginationSection>
        )}
      </TableContainer>
    );
  }
);

TableComponent.displayName = "TableComponent";

export default TableComponent;

import api from "..";

async function getRelatorioAlunos(unidadeId = 0, escolaridadeId = 0, turnoId = 0, turmaId = 0, matriculados = 1, renovacao = 0, nome = "null", cpf = "", responsavel = "", skip = 0, limit = 20, anoLetivo = 0, transporte = 0) {
  console.log(transporte)
    return await api
      .get(`relatorio/alunos?unidadeId=${unidadeId}&escolaridadeId=${escolaridadeId}&turnoId=${turnoId}&turmaId=${turmaId}&matriculados=${matriculados}&renovacao=${renovacao}&nome=${nome}&cpf=${cpf}&responsavel=${responsavel}&skip=${skip}&limit=${limit}&anoLetivo=${anoLetivo}&utilizaTransporteEscolar=${transporte}`,
      { responseType: 'arraybuffer' }, 
      { headers: { 'Content-Type': 'blob' } })
      .then((response) => {
        let fileName = `alunos.xlsx`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([response.data], {
              type: 'application/pdf',
              encoding: 'UTF-8'
            }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              encoding: 'UTF-8'
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      });
  };

export {
    getRelatorioAlunos
}
import { useEffect, useState, useMemo } from 'react';
import * as S from './style';
import FormFieldRed from '../../../../../components/formInputRed';
import FormField from '../../../../../components/formField';
import { useParams } from 'react-router-dom'
import api from '../../../../../services';
import { useNavigate } from 'react-router-dom'
import urlsServidores from '../urlsServidores';

const ServidoresVisualizarFormulario = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const { id } = useParams()
  const [exibeAnexosDocumento, setExibeAnexoDocumentos] = useState(0)
  const [exibeCnh, setExibeCnh] = useState(0)
  const [exibeComprovaExperiencia, setExibeComprovaExperiencia] = useState(0)
  const [cargoChecked, setCargoChecked] = useState(0)

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`/processoSeletivo/inscricao/servidor/${id}`);
      if (cargoChecked == 0){
        await checkCargo(response.data.data?.cargo)
        setCargoChecked(1)
      }
     
      setData(response.data.data)
      
    }
    fetchData()
  }, [])

  async function checkCargo(cargo) {

    if (cargo == 1 || cargo == 2 || cargo == 3 || cargo == 4 || cargo == 5 || cargo == 6 || cargo == 7 || cargo == 12 || cargo == 13) {
      setExibeComprovaExperiencia(1)
    }

    if (cargo == 6 || cargo == 8 || cargo == 9 || cargo == 10 || cargo == 11) {
      setExibeAnexoDocumentos(1)
    }

    if (cargo == 5) {
      setExibeCnh(1)
    }
  }

  const renderStatus = (status) => {
    switch (status) {
      case 1:
        return 'AGUARDANDO';
      case 2:
        return 'HOMOLOGADO';
      case 3:
        return 'CONVOCADO';
      case 4:
        return 'NÃO HOMOLOGADO';
      case 5:
        return 'NÃO COMPARECEU';
      case 6:
        return 'RECURSO ACEITO';
      case 7:
        return 'RECURSO NEGADO';
      case 8:
        return 'CANCELADO';
      default:
        return '';
    }
  };

  

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      // {
      //   Header: 'Data e Hora', accessor: 'criacao',
      //   Cell: ({ row }) => (
      //     row.original.criacao.split('T')[0].split('-').reverse().join('/') + ' às ' + row.original.criacao.split('T')[1].split('.')[0]
      //   )
      // },
      // { Header: 'Status', accessor: 'status' },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <S.TableButton 
            onClick={() =>
              {  window.open(urlsServidores.visualizar + row.original.id, '_blank')}
              // navigate(urls.processosSeletivosVInscricao + row.original.id)
            }
            className='btn btn-sm btn-primary'>
              <i className="material-icons md-edit_note"></i> visualizar
            </S.TableButton>
          </>
        )
      },
    ],
    []
  )

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '24px 48px',
      backgroundImage: 'url("https://pre-matricula.educacaoararuama.rj.gov.br/_form/images/fundo.jpg")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      height: '100vh'
    }}>
      <img
        src='https://pre-matricula.educacaoararuama.rj.gov.br/assets/araruama/araruama-logo-principal.png'
        alt="Logo" width="200" height="150"
      />
      <h6 style={{ marginTop: '10px', alignSelf: 'center' }}>PREFEITURA MUNICIPAL DE ARARUAMA</h6>
      <h4 style={{ alignSelf: 'center' }}>SECRETARIA DE EDUCAÇÃO</h4>
      <S.Container>
        <S.FormHeader>FORMULÁRIO DE CONFIRMAÇÃO DE CADASTRO</S.FormHeader>

        <S.LineDivider />
        
        <S.Row>
          <FormField label="ID" value={data?.id} fw={40} />
          <FormField label="Data e Hora" value={data?.criacao.split('T')[0].split('-').reverse().join('/') + ' às ' + data?.criacao.split('T')[1].split('.')[0]} fw={20} />
          <FormField label="Nome" value={data?.nome} fw={40} />
          <FormField label="Nacionalidades" value={data?.nacionalidade} fw={20} />
        </S.Row>

        <S.LineDivider />
        <S.Row>
          <FormField label="Endereço" value={data?.endereco} fw={50} />
          {/* <FormField label="data nascimento" value={data?.dataNascimento.split('T')[0].split('-').reverse().join('/')} fw={33.3} /> */}
          <FormField label="Número" value={data?.numero} fw={25} />
          <FormField label="Complemento" value={data?.complemento} fw={25} />
          {/* <FormField label="deficiente" value={data?.deficiente ? 'SIM' : 'NÃO'} fw={34} /> */}
        </S.Row>
        



        <S.Row>
          <FormField label="Bairro" value={data?.bairro} fw={25} />
          {/* <FormField label="data nascimento" value={data?.dataNascimento.split('T')[0].split('-').reverse().join('/')} fw={33.3} /> */}
          <FormField label="Cidade" value={data?.cidade} fw={25} />
          <FormField label="Estado" value={data?.estado} fw={25} />
          <FormField label="CEP" value={data?.cep} fw={25} />
          {/* <FormField label="deficiente" value={data?.deficiente ? 'SIM' : 'NÃO'} fw={34} /> */}
        </S.Row>
        


        <S.Row>
          <FormField label="Tel Residencial" value={data?.telefoneResidencial} fw={25} />
          {/* <FormField label="data nascimento" value={data?.dataNascimento.split('T')[0].split('-').reverse().join('/')} fw={33.3} /> */}
          <FormField label="Tel Recado/Nome" value={data?.telefoneRecado} fw={25} />
          <FormField label="Celular" value={data?.telefoneCelular} fw={25} />
          <FormField label="Email" value={data?.email} fw={25} />
          {/* <FormField label="deficiente" value={data?.deficiente ? 'SIM' : 'NÃO'} fw={34} /> */}
        </S.Row>
        


        {/* <S.LineDivider /> */}
        <S.Row>
          <FormField label="Data Nascimento" value={data?.dataNascimento.split('T')[0].split('-').reverse().join('/')} fw={50} />
          {/* <FormField label="data nascimento" value={data?.dataNascimento.split('T')[0].split('-').reverse().join('/')} fw={33.3} /> */}
          <FormField label="Sexo" value={data?.sexo == 2 ? "MASCULINO" : "FEMININO"} fw={25} />
          <FormField label="Estado Civil" value={data?.estadoCivil} fw={25} />
          {/* <FormField label="deficiente" value={data?.deficiente ? 'SIM' : 'NÃO'} fw={34} /> */}
        </S.Row>

        <S.Row>
          <FormField label="RG Nº" value={data?.numeroRg} fw={20} />
          {/* <FormField label="data nascimento" value={data?.dataNascimento.split('T')[0].split('-').reverse().join('/')} fw={33.3} /> */}
          <FormField label="Órg. Exp" value={data?.orgaoExpedicaoRg} fw={20} />
          <FormField label="Estado" value={data?.estadoExpedicaoRg} fw={20} />
          <FormField label="Expedição" value={data?.dataExpedicaoRg.split('T')[0].split('-').reverse().join('/')} fw={20} />
          <FormField label="CPF" value={data?.cpf} fw={20} />
          {/* <FormField label="deficiente" value={data?.deficiente ? 'SIM' : 'NÃO'} fw={34} /> */}
        </S.Row>
        

        {/* <S.LineDivider /> */}

        <S.Row>
          <FormField label="Nome do Pai" value={data?.nomeDoPai} fw={50} />
          {/* <FormField label="Data Nascimento Responsável" value={data?.responsavelNascimento.split('T')[0].split('-').reverse().join('/')} fw={25} /> */}
          <FormField label="Nome da Mãe" value={data?.nomeDaMae} fw={50} />


          
        </S.Row>

        <S.Row>
          <FormField label="Nível Escolaridade" value={data?.nivelDeEscolaridade === 1
    ? 'FUNDAMENTAL INCOMPLETO'
    : data?.nivelDeEscolaridade === 2
    ? 'FUNDAMENTAL COMPLETO'
    : data?.nivelDeEscolaridade === 3
    ? 'MÉDIO INCOMPLETO'
    : data?.nivelDeEscolaridade === 4
    ? 'MÉDIO COMPLETO'
    : data?.nivelDeEscolaridade === 5
    ? 'SUPERIOR INCOMPLETO'
    : data?.nivelDeEscolaridade === 6
    ? 'SUPERIOR COMPLETO'
    : data?.nivelDeEscolaridade === 7
    ? 'PÓS-GRADUAÇÃO'
    : data?.nivelDeEscolaridade === 8
    ? 'MESTRADO'
    : data?.nivelDeEscolaridade === 9
    ? 'DOUTORADO'
    : ''} fw={50} />
          {/* <FormField label="Data Nascimento Responsável" value={data?.responsavelNascimento.split('T')[0].split('-').reverse().join('/')} fw={25} /> */}
          <FormField label="Cargo" value={ 
      data?.cargo === 1
    ? 'AGENTE DE SERVIÇOS GERAIS'
    :  data?.cargo === 2
    ? 'AUXILIAR DE DISCIPLINA'
    :  data?.cargo === 3
    ? 'MERENDEIRA'
    :  data?.cargo === 4
    ? 'MONITOR DE TRANSPORTE ESCOLAR'
    :  data?.cargo === 5
    ? 'MOTORISTA D'
    :  data?.cargo === 6
    ? 'NUTRICIONISTA'
    :  data?.cargo === 7
    ? 'OFICIAL ADMINISTRATIVO'
    :  data?.cargo === 8
    ? 'ORIENTADOR EDUCACIONAL'
    :  data?.cargo === 9
    ? 'ORIENTADOR PEDAGÓGICO'
    :  data?.cargo === 10
    ? 'PROFESSOR I'
    :  data?.cargo === 11
    ? 'PROFESSOR II'
    :  data?.cargo === 12
    ? 'SECRETÁRIO ESCOLAR'
    :  data?.cargo === 13
    ? 'VIGIA' : ""} fw={50} />         
        </S.Row>
       

        <S.Row>
          <FormField label="Tempo de Experiência (Meses)" value={data?.quantidadeMesesExperiencia == ""? 0 : data?.quantidadeMesesExperiencia} fw={100} />

          
        </S.Row>

        <S.Row>
          <FormFieldRed label="Status" value={renderStatus(data?.status)} fw={50} />
          <FormFieldRed label="Motivo" value={data?.motivo} fw={50} />
          
        </S.Row>

        <S.LineDivider />

       

        {/* <Table
          columns={columns}
          data={data?.inscricoesAntigas ? data?.inscricoesAntigas : []}
        /> */}





        

        <S.FormHeader>ANEXOS</S.FormHeader>
        <S.Row>
          <FormField label="COMPROVANTE ESCOLARIDADE" value={data?.anexoComprovanteEscolaridade.length == 36 ? 'ENVIADO' : 'ENVIADO'} fw={50} nome={data?.nome} anexoId={data?.anexoComprovanteEscolaridade} />
          {/* <FormField label="Data Nascimento Responsável" value={data?.responsavelNascimento.split('T')[0].split('-').reverse().join('/')} fw={25} /> */}
          <FormField label="RG" value={data?.anexoRg.length != '' ? 'ENVIADO' : 'ENVIADO'} fw={25} nome={data?.nome} anexoId={data?.anexoComprovanteEscolaridade} />
          <FormField label="CPF" value={data?.anexoCpf.length  != '' ? 'ENVIADO' : 'ENVIADO'} fw={25} nome={data?.nome} anexoId={data?.anexoComprovanteEscolaridade} />

        </S.Row>

        {
            exibeAnexosDocumento == 1 && (
              <>
              <S.LineDivider />

<S.Row>

{
            data?.anexoDoutorado1.length  != '' && (
              <>
               <FormField label="DIPLOMA DOUTORADO 01" value={data?.anexoDoutorado1.length  != '' ? 'ENVIADO' : 'NÃO ENVIADO'} fw={100} nome={data?.nome} anexoId={data?.anexoDoutorado1} />
              </>
            )
}

</S.Row>

<S.Row>

{
            data?.anexoMestrado1.length  != '' && (
              <>
               <FormField label="DIPLOMA MESTRADO 01" value={data?.anexoMestrado1.length  != '' ? 'ENVIADO' : 'NÃO ENVIADO'} fw={50} nome={data?.nome} anexoId={data?.anexoMestrado1} />
              </>
            )
}

{
            data?.anexoMestrado2.length  != '' && (
              <>
              <FormField label="DIPLOMA MESTRADO 02" value={data?.anexoMestrado2.length  != '' ? 'ENVIADO' : 'NÃO ENVIADO'} fw={50} nome={data?.nome} anexoId={data?.anexoComprovanteEscolaridade} />
              </>
            )
}

</S.Row>

<S.Row>

{
            data?.anexoPosGraduacao1.length  != '' && (
              <>
              <FormField label="DIPLOMA PÓS GRADUAÇÃO 01" value={data?.anexoPosGraduacao1.length  != '' ? 'ENVIADO' : 'NÃO ENVIADO'} fw={50} nome={data?.nome} anexoId={data?.anexoComprovanteEscolaridade} />
              </>
            )
}

{
            data?.anexoPosGraduacao2.length  != '' && (
              <>
              <FormField label="DIPLOMA PÓS GRADUAÇÃO 02" value={data?.anexoPosGraduacao2.length  != '' ? 'ENVIADO' : 'NÃO ENVIADO'} fw={50} nome={data?.nome} anexoId={data?.anexoComprovanteEscolaridade} />
              </>
            )
}

{
            data?.anexoPosGraduacao3.length  != '' && (
              <>
              <FormField label="DIPLOMA PÓS GRADUAÇÃO 03" value={data?.anexoPosGraduacao3.length  != '' ? 'ENVIADO' : 'NÃO ENVIADO'} fw={50} nome={data?.nome} anexoId={data?.anexoComprovanteEscolaridade} />
              </>
            )
}

</S.Row>

<S.Row>
{
            data?.anexoCertificados1.length  != '' && (
              <>
              <FormField label="CERTIFICADOS 01" value={data?.anexoCertificados1.length  != '' ? 'ENVIADO' : 'NÃO ENVIADO'} fw={20} nome={data?.nome} anexoId={data?.anexoCertificados1} />
              </>
            )
}

{
            data?.anexoCertificados2.length  != '' && (
              <>
              <FormField label="CERTIFICADOS 01" value={data?.anexoCertificados2.length  != '' ? 'ENVIADO' : 'NÃO ENVIADO'} fw={20} nome={data?.nome} anexoId={data?.anexoCertificados1} />
              </>
            )
}

{
            data?.anexoCertificados3.length  != '' && (
              <>
              <FormField label="CERTIFICADOS 03" value={data?.anexoCertificados3.length  != '' ? 'ENVIADO' : 'NÃO ENVIADO'} fw={20} nome={data?.nome} anexoId={data?.anexoCertificados1} />
              </>
            )
}

{
            data?.anexoCertificados4.length  != '' && (
              <>
              <FormField label="CERTIFICADOS 04" value={data?.anexoCertificados4.length  != '' ? 'ENVIADO' : 'NÃO ENVIADO'} fw={20} nome={data?.nome} anexoId={data?.anexoCertificados1} />
              </>
            )
}

{
            data?.anexoCertificados5.length  != '' && (
              <>
              <FormField label="CERTIFICADOS 05" value={data?.anexoCertificados5.length  != '' ? 'ENVIADO' : 'NÃO ENVIADO'} fw={20} nome={data?.nome} anexoId={data?.anexoCertificados1} />
              </>
            )
}

</S.Row>

                
              </>
            )
          }

       

        {
            exibeComprovaExperiencia == 1 && (
              <>
               <S.LineDivider />

<S.Row>
  <FormField label="COMPROVANTE EXPERIÊNCIA" value={data?.anexoComprovanteExperiencia.length == 36 ? 'ENVIADO' : 'ENVIADO'} fw={100} nome={data?.nome} anexoId={data?.anexoComprovanteEscolaridade} />
</S.Row>
                
              </>
            )
          }

       

        

        {
            exibeCnh == 1 && (
              <>
              <S.LineDivider />
                <S.Row>
                  <FormField label="CNH" value={data?.anexoCnh.length == 36 ? 'ENVIADO' : 'ENVIADO'} fw={100} nome={data?.nome} anexoId={data?.anexoComprovanteEscolaridade} />
                </S.Row>
                
              </>
            )
          }

{
            data?.recurso != null && (
              <>
                <S.LineDivider />
              <S.FormHeader>RECURSO</S.FormHeader>  
              <S.Row>
                  <FormField label="DATA RECURSO" value={data?.dataRecurso.split('T')[0].split('-').reverse().join('/') + ' às ' + data?.dataRecurso.split('T')[1].split('.')[0]} fw={100} />
                </S.Row>
                <S.Row>
                  <FormField label="RAZÕES RECURSO" value={data?.recurso} fw={100}/>
                </S.Row>
                
              </>
              
            )
          }



        


        {/* <S.LineDivider /> */}

        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
          <S.PrintButton type="button" onClick={() => {
            // window.print()
            navigate('/processo-seletivo/servidor')
          }}>Voltar</S.PrintButton>
          <S.PrintButton type="button" onClick={() => {
            window.print()
            navigate('/processo-seletivo/servidor')
          }}>Imprimir</S.PrintButton>
          {/* <S.PrintButton type="button" onClick={() => {
            // window.print()
            navigate('/processo-seletivo/servidor/recurso/' + data?.id)
          }}>Recurso</S.PrintButton> */}
           
        </div>


      </S.Container>
    </div>
  )
}

export default ServidoresVisualizarFormulario

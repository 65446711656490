import Breadcrumb from '../../../../../../components/breadcrumb'
import BaseChamadaPublica from '../../../baseChamadaPublica';
import Alert from '@mui/material/Alert';
import Table from '../../../../../../components/table';
import { useMemo, useState,useEffect } from 'react';
import { getInscricoesRelatorio } from '../../../../../../services/processos/chamadaPublica';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import handleDownloadExcel from '../../../../../../utils/handleDownloadExcel';


const RelatorioInscritosPorBairroPage= () => {


    const [bairro, setBairro] = useState('')
    const [skip, setSkip] = useState(0);
    const [status, setStatus] = useState(1);
    const [exportar, setExportar] = useState(0);
    const [limit, setLimit] = useState(20);
    const [data, setData] = useState([]);
    const nomeArquivo = 'relatotio-pre-matricula-2024-inscritos-por-bairro-quantitativo.xlsx'


const bairros = [
    'ALECRIM',
    'BAIXO GRANDE',
    'BALEIA',
    'BALNEÁRIO DAS CONCHAS',
    'BALNEÁRIO SÃO PEDRO',
    'BASE AERO NAVAL',
    'BELA VISTA',
    'BOA VISTA',
    'BOQUEIRÃO',
    'BOTAFOGO',
    'CAMPO REDONDO',
    'CENTRO',
    'COLINA',
    'CRUZ',
    'ESTAÇÃO',
    'FLEXEIRA',
    'FLUMINENSE',
    'ITAÍ',
    'JARDIM ARCO ÍRIS',
    'JARDIM DAS ACÁCIAS',
    'JARDIM MORADA DA ALDEIA',
    'JARDIM PRIMAVERA',
    'JARDIM SOLEDADE',
    'MORRO DO MILAGRE',
    'NOVA SÃO PEDRO',
    'PARQUE ARRUDA',
    'PARQUE DOIS MENINOS',
    'PARQUE ESTORIL',
    'PAU RACHADO',
    'PINHEIROS',
    'POÇO FUNDO',
    'PONTA DO AMBRÓSIO',
    'PORTO DA ALDEIA',
    'PORTO DO CARRO',
    'PRAIA DO SUDOESTE',
    'PRAIA LINDA',
    'RECANTO DAS ORQUÍDEAS',
    'RECANTO DO SOL',
    'RETIRO',
    'RUA DO FOGO',
    'SANTO ANTÔNIO',
    'SÃO JOÃO',
    'SÃO JOSÉ',
    'SÃO MATEUS',
    'SAPEATIBA MIRIM',
    'SERGEIRA',
    'TRÊS VENDAS',
    'VINHATEIRO'
];

  async function fetchInscricoes(value){

    setBairro(value)
    if(value.length > 0){
      var response = await getInscricoesRelatorio(0, 0, 0, 0, 0, value, skip, limit, exportar, status)
      setData(response || [])
    }
  }

  const exportarDados = async () => {
    
    if(data.length === 0 || data?.total === 0){
      return toast.warning('Não há dados para exportar.')
    }

    try {

    const relatorioData = await getInscricoesRelatorio(0, 0, 0, 0, 0, bairro, 0, data?.total, 1, status)

    if (relatorioData) {
      return handleDownloadExcel(relatorioData, nomeArquivo);
    } else {
        return toast.error('Falha ao obter o relatório');
        // Tratar falha na obtenção do relatório
    }
    } catch (error) {
      return toast.error('Erro ao buscar relatório');
      // Tratar erro na chamada da função
    }
   
    // return await handleDownloadExcel(relatorioData, titulo);
  }

  useEffect(() => {
    fetchInscricoes(bairro)
  }, [limit, skip])


    const columns = useMemo(
        () => [
        { Header: 'Unidade', accessor: 'unidadeDescricao' },
        { Header: 'Ano Escolaridade', accessor: 'escolaridadeDescricao' },
        // { Header: 'Turno', accessor: 'turnoDescricao' },
        { Header: 'Quantidade', accessor: 'quantidade' },
        ],
        []
    )
      





return (
    <BaseChamadaPublica>
      <Breadcrumb
        title={'POR BAIRRO - QUANTITATIVO'}
        itens={['PRÉ MATRÍCULA 2024', 'RELATÓRIOS', 'INSCRITOS', 'POR BAIRRO - QUANTITATIVO']}
        hasExcelButton 
        onClickDownload={() => exportarDados()} 
      />

        <Alert variant="filled" severity="info" style={{marginBottom: 20}}>Resultado com base nas inscrições como primeira opção.</Alert>

        <div className="row" style={{ marginBottom: 20 }}>
        <div className="col-12 col-md-12">
          <div className="form-group">
            <label htmlFor="bairro" className="form-label">
            Bairro
            </label>
            <select
              className="form-select"
              id="bairro"
              value={bairro}
              onChange={async e => {
                await fetchInscricoes(e.target.value)
              }}
            >
                <option value={''}></option>
                {bairros.map(bairro => (
                    <option key={bairro} value={bairro}>{bairro}</option>
                ))}
              
            </select>
          </div>
        </div>
        </div>
      
        <div className="row">
        <div className="col-12">
          <div className="card">
        <Table
              columns={columns}
              data={data.data ? data.data : []}
              hasPagination
              limit={limit}
              setLimit={setLimit}
              skip={skip}
              setSkip={setSkip}
              totalItems={data?.total}
            />
            </div>
            </div>
            </div>
        

      </BaseChamadaPublica>
      
      
)
}

export default RelatorioInscritosPorBairroPage
import React from 'react';
import { useParams } from 'react-router-dom';
import FichaIndividualFundamental from './fundamental';
import FichaIndividualInfantil from './infantil';
import FichaIndividualEjaFundamental from './eja/fundamental';
import FichaIndividualPrimeiroAno from './primeiroAno';

export const FichaIndividualPage = () => {
  const { alunoId } = useParams(); // Get alunoId from route parameters

  // Helper function to remove accents and compare case-insensitively
  const normalizeString = (str) => {
    return str ? str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() : '';
  };

  const fichaAvaliacaoEscolaridade = localStorage.getItem('fichaAvaliacaoEscolaridade') || '';

  // Check if description contains "creche" or "pré"
  const isInfantil = () => {
    const normalizedDescricao = normalizeString(fichaAvaliacaoEscolaridade);
    return normalizedDescricao.includes('creche') || normalizedDescricao.includes('pre');
  };

  // Check if description contains "fase II" to "fase IX" for EJA Fundamental
  const isEjaFundamental = () => {
    const normalizedDescricao = normalizeString(fichaAvaliacaoEscolaridade);
    return [
      'fase iii', 'fase iv', 'fase v', 
      'fase vi', 'fase vii', 'fase viii', 'fase ix'
    ].some(fase => normalizedDescricao.includes(fase));
  };

  const isPrimeiroAno = () => {
    const normalizedDescricao = normalizeString(fichaAvaliacaoEscolaridade);
    return normalizedDescricao.includes('1º ano') || normalizedDescricao.includes('primeiro ano') || normalizedDescricao === 'fase i';
  };


  return (
    <div>
      {isInfantil() ? (
        <FichaIndividualInfantil alunoId={alunoId} />
      ) : isEjaFundamental() ? (
        <FichaIndividualEjaFundamental alunoId={alunoId} />
      ) : isPrimeiroAno() ? (
        <FichaIndividualPrimeiroAno alunoId={alunoId} />
      ) : (
        <FichaIndividualFundamental alunoId={alunoId} />
      )}
    </div>
  );
};

export default FichaIndividualPage;

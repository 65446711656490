import React from 'react'
import * as S from './styles';
import { botaoPanico } from '../../services/panico';
import { toast } from 'react-toastify'
import { useMutation } from 'react-query'

export const PanicModal = ({
  open = false,
  onClose,
}) => {
  const unidadeId = JSON.parse(localStorage.getItem('unidadeID'));

  const { mutate } = useMutation(botaoPanico, {
    onSuccess: data => {
      toast.success('Alerta enviado com sucesso!');
      onClose()
    },
    onError: data => {
      toast.error('Algo deu errado. Tente novamente.');
    },
  })

  const handleAlert = () => {
    mutate({ unidadeId: unidadeId })
  }

  return (
    <S.Container open={open}>
      <S.Header>
        <S.Title>
          ALERTA DE PÂNICO
        </S.Title>
      </S.Header>
      <S.Section open={open}>
        <div className="card-body" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <div className="row" style={{ marginBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center', alignSelf: 'center' }} >
            <h5>Tem certeza que deseja acionar o alerta de pânico?</h5>
          </div>

          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
          >
            <button type="button" className="btn btn-warning" onClick={() => onClose()}>Cancelar</button>
            <button type="button" className="btn btn-danger" onClick={() => handleAlert()}>ALERTAR</button>
          </div>
        </div>
      </S.Section>
    </S.Container>
  )
}

export default PanicModal;

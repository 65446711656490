import api from "../..";

async function criarGrupoProdutos({ descricao, status }) {
  return await api
    .post('patrimonio/grupoprodutos', { descricao, status })
    .then(async (res) => {
      const data = res.data;
      return data;
    });
};

async function atualizaGrupoProdutos({ id, descricao, status }) {
  return await api
    .put(`patrimonio/grupoprodutos/${id}`, { descricao, status })
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function deleteGrupoProdutos(id) {
  return await api
    .delete(`patrimonio/grupoprodutos/${id}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function getGrupoProdutos() {
  return await api
    .get('patrimonio/grupoprodutos')
    .then(async (res) => {
      const data = res.data.data;
      return data;
    })
}

async function cadastrarImpressora({ marca, modelo, numeroSerie, unidadeId }) {
  return await api
    .post('patrimonio/impressora', { marca, modelo, numeroSerie, unidadeId })
    .then(async (res) => {
      const data = res.data;
      return data;
    });
};

async function editarImpressora({ id, marca, modelo, numeroSerie, unidadeId, status }) {
  return await api
    .put(`patrimonio/impressora/${id}`, { marca, modelo, numeroSerie, unidadeId })
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function listarImpressoras({ skip = 0, limit = 20 }) {
  return await api
    .get(`patrimonio/impressora/skip=${skip}&limit=${limit}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
};

async function listarImpressorasUnidades(unidadeId, skip, limit) {
  console.log('service')
  unidadeId = unidadeId == "" ? 0 : unidadeId;
  console.log(unidadeId)
  return await api
    .get(`patrimonio/impressora/unidade/${unidadeId}/skip=${skip}&limit=${limit}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
};

async function deletarImpressora(id) {
  return await api
    .delete(`patrimonio/impressora/${id}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function getLancamentoImpressaoUnidade(unidadeId, impressoraId, skip = 0, limit = 20) {
  return await api
    .get(`patrimonio/impressora/lancamento/${impressoraId}/unidade/${unidadeId}/skip=${skip}&limit=${limit}`)
    .then(async (res) => {
      const data = res;
      return data;
    })
}

async function getLancamentoImpressao(impressoraId, skip = 0, limit = 20) {
  return await api
    .get(`patrimonio/impressora/lancamento/${impressoraId}/skip=${skip}&limit=${limit}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function postLancamentoImpressao({ impressoraId, unidadeId, ano, mesId, copias, anexoId }) {
  return await api
    .post('patrimonio/impressora/lancamento', { impressoraId, unidadeId, ano, mesId, copias, anexoId })
    .then(async (res) => {
      const data = res.data;
      return data;
    });
};


async function impressoraChamado({ unidadeId, servidorId, impressoraId, titulo, status, descricao }) {
  return await api
    .post('patrimonio/impressorachamado', { unidadeId, servidorId, impressoraId, titulo, status, descricao })
    .then(async (res) => {
      const data = res.data;
      return data;
    });
};


async function impressoraChamadoMensagem({ chamadoId, servidorId, mensagem, }) {
  return await api
    .post('patrimonio/impressorachamadomensagem', { chamadoId, servidorId, mensagem })
    .then(async (res) => {
      const data = res.data;
      return data;
    });
};

async function impressoraChamadoStatus({ chamadoId, id = 0, servidorId, mensagem, status}) {
  return await api
    .post('patrimonio/listaimpressorachamadomensagemstatus', {status, id, chamadoId, servidorId, mensagem })
    .then(async (res) => {
      const data = res.data;
      return data;
    });
};


async function getChamadosImpressoras(unidadeId, skip = 0, limit = 20) {
  return await api
    .get(`patrimonio/listachamadosimpressora/${unidadeId}/skip=${skip}&limit=${limit}`)
    .then(async (res) => {
      const data = res;
      return data.data;
    })
}
async function getChamadosImpressorasMensagens(chamadoId) {
  return await api
    .get(`patrimonio/listaimpressorachamadomensagem/${chamadoId}`)
    .then(async (res) => {
      const data = res;
      return data.data;
    })
}

export {
  criarGrupoProdutos,
  atualizaGrupoProdutos,
  deleteGrupoProdutos,
  getGrupoProdutos,
  cadastrarImpressora,
  editarImpressora,
  listarImpressoras,
  deletarImpressora,
  getLancamentoImpressao,
  postLancamentoImpressao,
  getLancamentoImpressaoUnidade,
  listarImpressorasUnidades,
  impressoraChamado,
  getChamadosImpressoras,
  impressoraChamadoMensagem,
  getChamadosImpressorasMensagens,
  impressoraChamadoStatus
}
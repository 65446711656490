import React, { useState, useEffect } from 'react'
import FormInput from '../../../../../../../components/formInput'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { getCidades, getEstados, getLocation} from '../../../../../../../services/endereco'
import { putFornecedorContato, getFornecedor } from '../../../../../../../services/administrativo/patrimonio-fornecedores'
import { toast } from 'react-toastify'
import InputMask from 'react-input-mask'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import urlsAdministrativo from '../../../../../urlsAdministrativo'
import upperCaseSimple from '../../../../../../../utils/upperCaseSimple'
import removeEmpty from '../../../../../../../utils/removeEmpty'

export default function Contato() {
  const [stateSelected, setStateSelected] = useState();
  const [data, setData] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { isValid, errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
      telefone: '',
      cep: '',
      numero: '',
      bairro: '',
      complemento: '',
      estado: '',
      cidade: ''
    }
  })

  const { data: dataFornecedor, isFetched } = useQuery('getFornecedor', (id) => getFornecedor(id), {
    enabled: id ? true : false,
    onSuccess: data => {
      setData(data);
    }
  })

  useEffect(() => {
    if (data !== null) {
      setValue('email', data?.contato?.email);
      setValue('telefone', data?.contato?.telefone);
      setValue('cep', data?.contato?.codigoPostal);
      setValue('logradouro', data?.contato?.logradouro);
      setValue('numero', data?.contato?.numero);
      setValue('bairro', data?.contato?.bairro);
      setValue('complemento', data?.contato?.complemento);
      setValue('estado', data?.contato?.estado);
      setValue('cidade', data?.contato?.cidade);
    }
  }, [data, isFetched])

  const { mutate: contatoMutate } = useMutation(putFornecedorContato, {
    onSuccess: message => {
      toast.success('Fornecedor atualizado com sucesso.');
    },
    onError: error => {
      toast.error('Algo deu errado. Tente novamente mais tarde');
    }
  })


  const { data: estadosData } = useQuery('getEstados', () => getEstados(), {
    enabled: true,
    retry: true
  })

  const { data: cidadesData, refetch: cidadesRefetch } = useQuery('getCidades', () => getCidades(stateSelected), {
    enabled: false,
    retry: true
  })

  useEffect(() => {
    if (estadosData?.length > 0) {
      var estadoSelecionado = estadosData?.find((estado) => estado.sigla === getValues('estado'))
      setStateSelected(estadoSelecionado?.id)
    }
  }, [watch('estado')])

  useEffect(() => {
    if (stateSelected !== null && stateSelected !== undefined && stateSelected !== 'SELECIONE') {
      cidadesRefetch()
    }
  }, [stateSelected])

  const [cepData, setCepData] = useState('')

  const { mutate, isLoading } = useMutation(cep => getLocation(cep), {
    onSuccess: data => {
      const address = data.data;
      setValue('logradouro', address.logradouro)
      setValue('bairro', address.bairro)
      setValue('estado', address.estadoSigla)
      setTimeout(() => {
        setValue('cidade', address.cidade)
      }, 1000)

    },
    onError: error => {
      setValue('logradouro', '')
      setValue('bairro', '')
      setValue('estado', '')
      setValue('cidade', '')

      if (error.response) {
        const { message } = error.response.data
        toast.error(message)
      } else if (error.request) {
        toast.error('Código postal não localizado na base de dados. Preencha manualmentes')
      } else {
        toast.error(error.message)
      }
    }
  })

  useEffect(() => {
    if (cepData !== '_____-___' && cepData.length == 9) {
      const newCep = cepData.replace('-', '').replaceAll('_', '')
      if (newCep.length == 8) {
        mutate(newCep)
      }
    }
  }, [cepData])

  const onSubmit = (data) => {
    contatoMutate({ id: id, data: { ...upperCaseSimple(removeEmpty(data)), codigoPostal: data.cep } })
  }


  return (
    <div
      className="tab-pane fade"
      id="contato"
      role="tabpanel"
      aria-labelledby="contato-tab"
    >
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="card-body">
          <div className="row">
            <div className="col-9">
              <FormInput
                register={register}
                errors={errors}
                type='email'
                atribute="email"
                label="E-mail"
              />
            </div>

            <div className="col-3">
              <FormInput
                register={register}
                errors={errors}
                mask="(99)9999-9999"
                atribute="telefone"
                label="Telefone"
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: 30 }}>
            <div className="col-2">
              <div className="form-group">
                <label htmlFor="cep">CEP <span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                <InputMask
                  type="tel"
                  inputMode="numeric"
                  className="form-control"
                  name="cep"
                  id="cep"
                  onChange={e => setCepData(e.target.value)}
                  mask="99999-999"
                  {...register('cep', { required: true })}
                />
              </div>
            </div>

            <div className="col-8">
              <FormInput
                register={register}
                errors={errors}
                atribute="logradouro"
                label="Logradouro"
              />
            </div>

            <div className="col-2">
              <FormInput
                register={register}
                errors={errors}
                atribute="numero"
                label="Número"
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: 30 }}>
            <div className="col-6">
              <FormInput
                register={register}
                errors={errors}
                atribute="bairro"
                label="Bairro"
              />
            </div>
            <div className="col-6">
              <FormInput
                register={register}
                errors={errors}
                atribute="complemento"
                label="Complemento"
              />
            </div>

            <div className="row" style={{ marginTop: 30 }}>
              <div className="col-3">

                <div className="form-group">
                  <label htmlFor="estado">UF <span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                  <select
                    className="form-select"
                    id="estado"
                    {...register('estado', { required: true })}
                  >
                    <option>SELECIONE</option>
                    {estadosData?.map(estados => (
                      <option key={estados.id} value={estados.sigla}>
                        {estados.sigla}
                      </option>
                    ))}
                  </select>
                  {errors?.estado?.type === 'required' && (
                    <span className="text-danger">Campo obrigatório</span>
                  )}
                </div>
              </div>

              <div className="col-9">

                <div className="form-group">
                  <label htmlFor="cidade">Cidade <span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                  <select
                    className="form-select"
                    id="cidade"
                    style={{ maxHeight: '100px', overflow: 'auto' }}
                    {...register('cidade', { required: true })}
                  >
                    <option>SELECIONE</option>
                    {cidadesData?.map(cidades => (
                      <option key={cidades.id} value={cidades.nome}>
                        {cidades.nome}
                      </option>
                    ))}
                  </select>
                  {errors?.cidade?.type === 'required' && (
                    <span className="text-danger">Campo obrigatório</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end'
          }}
        >
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => navigate(urlsAdministrativo.patrimonioControleFornecedores)}
            style={{ marginRight: '8px' }}
          >
            Voltar
          </button>

          <button
            type="submit"
            className="btn btn-success"
            disabled={!isValid}
          >
            {id ? 'Atualizar' : 'Cadastrar'}
          </button>
        </div>
      </form>
    </div >
  )
}

import HeaderNotLogged from "../../components/header/noLoggedHeader";
import Aside from "../../components/aside";
import links from './linksFamiliaConectada'
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react'
// import SuporteRodape from "../../utils/suporteRodape";


const BaseFamiliaConectada = ({ children }) => {
  const isMenuOpen = useSelector(state => state.panel.isMenuOpen);
  const [newData, setNewData] = useState('');

  return (
    <div className={
      isMenuOpen ? 'app menu-off-canvas align-content-stretch d-flex flex-wrap menu-off-canvas-show' : 'app menu-off-canvas align-content-stretch d-flex flex-wrap'
    }>
          {/* <SuporteRodape /> */}
          <Aside links={links()} perfil={false} />
      <div className="app-container">
        <div className="search">
          <form>
            <input
              className="form-control"
              type="text"
              placeholder="Pesquisa..."
              aria-label="Search"
            />
          </form>
          <a href="/" className="toggle-search">
            <i className="material-icons md-close"></i>
          </a>
        </div>
        <HeaderNotLogged />
        <div className="app-content">
          <div className="content-wrapper">
            <div className="container">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default BaseFamiliaConectada;

import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from "../../../components/breadcrumb";
import BaseConfiguracoes from "../../configuracoes/baseConfiguracoes";
import urlsConfiguracoes from "../../configuracoes/urlsConfiguracoes";
import { useMutation } from 'react-query';
import { alocarDistrito, getUnidadesDistritos, deleteDistritoUnidade } from "../../../services/unidades";
import upperCaseSimple from "../../../utils/upperCaseSimple";
import removeEmpty from "../../../utils/removeEmpty";
import { getUnidades } from "../../../services/unidades";
import Select from 'react-select';
import { useQuery } from "react-query";
import Table from "../../../components/table";
import { date } from "faker/lib/locales/az";

const DistritoAlocarPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const [distrito, setDistrito] = useState('');
  const [unidade, setUnidade] = useState(0);
  const [unidades, setUnidades] = useState([]);
  // const [data, setData] = useState([]);
  const [skip, setSkip] = useState(0);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(20);

  const onMenuOpen = () => setIsOpen(true);
  const onMenuClose = () => setIsOpen(false);
  const [isOpen, setIsOpen] = useState(false);

  const { mutate, isLoading } = useMutation(alocarDistrito, {
    onSuccess: (data) => {
      toast.success('Unidade alocada com sucesso');
      // navigate(urlsConfiguracoes.distritos);
    },
    onError: (error) => {
      if (error.response) {
        toast.error('Erro ao tentar alocar uma unidade, tente novamente mais tarde.');
      } else if (error.request) {
        toast.error('Erro ao tentar alocar uma unidade, tente novamente mais tarde.');
      } else {
        toast.error('Erro ao tentar alocar uma unidade, tente novamente mais tarde.');
      }
    }
  });

  const { data, isLoading: loading, refetch } = useQuery('getUnidadesFiltro', () => getUnidadesDistritos(id, skip, limit), {});

  const { isLoading: loadingUnidades, refetch: refetchUnidades } = useQuery('getUnidades', () => getUnidades(), {
    enabled: true,
    retry: true,
    onSuccess: (data) => {
      var dados = [];
      data.forEach(item => {
        dados.push({ value: item.id, label: item.nome })
      });

      setUnidades(dados);
    }
  })

  const onSubmit = (data) => {
    data = {  distritoId: parseInt(id), unidadeId: unidade }
    mutate(upperCaseSimple(removeEmpty(data)));
    // navigate(urlsConfiguracoes.distritos);
  };

  const deleteDistritoFunction = async (unidadeId) => {
    try {
      await deleteDistritoUnidade(id, unidadeId);
      toast.success('Unidade removida do distrito com sucesso.');
      refetch()
    } catch (error) {
      toast.error('Ocorreu um erro ao remover a unidade do distrito.');
      console.error('Error deleting unidade from distrito:', error);
      // Handle the error if needed
    }
  };

  useMemo(() => {
    (async () => {
      if (state) {
        const { distrito } = state;
        if (id && distrito) {
          const { descricao } = distrito;
          setDistrito(descricao);
        }
      }
    })();
  }, [id, state]);

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Unidade', accessor: 'nome' },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {` `}
            <button
              className='btn btn-sm btn-danger'
              type="button"
              onClick={() => {
                const confirm = window.confirm('Tem certeza que deseja excluir essa alocação?');
                if (confirm) {
                  deleteDistritoFunction(row.original.id)

                }
              }}
            >
              <i className="material-icons md-group_remove"></i> excluir
            </button>
          </div>
        )
      },
    ],
    [navigate, data]
  )

  return (
    <BaseConfiguracoes>
      <Breadcrumb title={'Cadastar Unidade Distrito'} itens={['Configurações', 'Distrito', 'Unidades', 'Alocar']} />
      <form id='form' onSubmit={(onSubmit)}>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className="card-body">
              <div className="row" style={{ marginBottom: 20 }}>
      <div className="col-12 col-md-12">
          <div className="form-group">
            <label htmlFor="unidadeId" className="form-label">
              Unidades
            </label>
            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              // name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={unidades}
              isDisabled={false}
              value={unidades.find(x => x.value == unidade)}
              placeholder=''             
              onChange={async e => {
                setUnidade(e.value);
              }
              }
            />
          </div>
        </div>
        </div>
        <div className="row">
        <Table
                columns={columns}
                data={data?.data ? data?.data : []}
                hasPagination
                limit={limit}
                setLimit={setLimit}
                skip={skip}
                setSkip={setSkip}
                totalItems={data?.total}
                // hiddenColluns={['id', 'cidade', 'estado', 'numero']}
              />
            </div>
              </div>
              <div className='card-footer text-end'>
                <button type='submit' className='btn btn-success'>
                  {isLoading ? 'Enviado...' : 'Alocar'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </BaseConfiguracoes>
  )

};

export default DistritoAlocarPage;
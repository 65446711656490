import api from "..";

async function getNacionalidades(skip, limit, descricao) {
  var url = descricao != "" ?
     `gestao/nacionalidade?descricao=${descricao}&skip=${skip}&limit=${limit}` : 
     `gestao/nacionalidade?skip=${skip}&limit=${limit}`
  return await api
    .get(url)
    .then(async (res) => {
      const { data } = res.data;
      return res.data;
    });
};

async function createNacionalidade(data) {
  return await api
    .post('gestao/nacionalidade', data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function updateNacionalidade({ id, data }) {
  return await api
    .put(`gestao/nacionalidade/${id}`, data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function relatorioNacionalidades() {     
  return await api
    .get(`relatorio/nacionalidades`, { responseType: 'arraybuffer' },  { headers: { 'Content-Type': 'blob' }})
    .then(async (res) => {
      return res.data;
    });
};

export {
  getNacionalidades,
  createNacionalidade,
  updateNacionalidade,
  relatorioNacionalidades
  // updateEscolaridade,
  // getEscolaridade
}
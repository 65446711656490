import {
  Autocomplete,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import {
  doisCursos,
  experienceOptionsOutros,
  experienceOptionsPrefeitura,
  quatroCursos,
  tresCursos,
} from "../data/options";

export function DocenteIForm({ control, errors, isPrint = false, values }) {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Typography marginBottom={2}>
          Experiência profissional no cargo pretendido na Prefeitura de São
          Pedro da Aldeia?
        </Typography>
        <Controller
          name="experiencePrefeitura"
          control={control}
          rules={{ required: "Experiência na Prefeitura é obrigatória." }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  experienceOptionsPrefeitura?.find(
                    (c) => c.id == values?.experiencePrefeitura
                  )?.description || ""
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={experienceOptionsPrefeitura}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.experiencePrefeitura}
                    helperText={
                      errors.experiencePrefeitura
                        ? errors.experiencePrefeitura.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography marginBottom={2}>
          Experiência profissional no cargo pretendido em outros municípios ou
          instituições privadas?
        </Typography>
        <Controller
          name="experienceOutros"
          control={control}
          rules={{
            required: "Experiência em outros municípios é obrigatória.",
          }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  experienceOptionsOutros?.find(
                    (c) => c.id == values?.experienceOutros
                  )?.description || ""
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={experienceOptionsOutros}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.experienceOutros}
                    helperText={
                      errors.experienceOutros
                        ? errors.experienceOutros.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography>
          Cursos na área de Educação, com carga horária mínima de 40h (quarenta
          horas) – por certificado
        </Typography>
        <Controller
          name="cursoEducacao"
          control={control}
          rules={{ required: "Curso na área de Educação é obrigatório." }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  tresCursos?.find((c) => c.id == values?.cursoEducacao)
                    ?.description || ""
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={tresCursos}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.cursoEducacao}
                    helperText={
                      errors.cursoEducacao ? errors.cursoEducacao.message : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography>
          Cursos de Formação Continuada oferecidos pelo Centro de Formação
          Continuada Professora Ismênia Trindade dos Santos - CEFOR, com
          certificado de conclusão, com frequência mínima de 75% da carga
          horária total do curso – por certificado
        </Typography>
        <Controller
          name="cursoFormacaoContinuada"
          control={control}
          rules={{
            required: "Curso de Formação Continuada é obrigatório.",
          }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  doisCursos?.find(
                    (c) => c.id == values?.cursoFormacaoContinuada
                  )?.description || ""
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={doisCursos}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.cursoFormacaoContinuada}
                    helperText={
                      errors.cursoFormacaoContinuada
                        ? errors.cursoFormacaoContinuada.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography>
          Capacitações oferecidas pelo Centro de Formação Continuada Professora
          Ismênia Trindade dos Santos – CEFOR, com declaração de no mínimo 4h
          (quatro horas) – sendo de conteúdo diferente do certificado
          apresentado para pontuação do item anterior
        </Typography>
        <Controller
          name="capacitacaoFormacaoContinuada"
          control={control}
          rules={{
            required: "Capacitação de Formação Continuada é obrigatória.",
          }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  tresCursos?.find(
                    (c) => c.id == values?.capacitacaoFormacaoContinuada
                  )?.description || ""
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={tresCursos}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.capacitacaoFormacaoContinuada}
                    helperText={
                      errors.capacitacaoFormacaoContinuada
                        ? errors.capacitacaoFormacaoContinuada.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography>
          Cursos <strong>correspondentes ao componente curricular</strong> de
          atuação com documento comprobatório de conclusão, com carga horária
          mínima de 40h (quarenta horas) - por certificado
        </Typography>
        <Controller
          name="cursoCargoPretendido"
          control={control}
          rules={{
            required: "Curso correspondente ao cargo pretendido é obrigatório.",
          }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  quatroCursos?.find(
                    (c) => c.id == values?.cursoCargoPretendido
                  )?.description || ""
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={quatroCursos}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.cursoCargoPretendido}
                    helperText={
                      errors.cursoCargoPretendido
                        ? errors.cursoCargoPretendido.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography marginBottom={2}>
          Cursos na área de Educação Especial com documento comprobatório de
          conclusão, com carga horária mínima de 40h (quarenta horas) - por
          certificado
        </Typography>
        <Controller
          name="cursoEducacaoEspecial"
          control={control}
          rules={{
            required: "Curso na área de Educação Especial é obrigatório.",
          }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  tresCursos?.find((c) => c.id == values?.cursoEducacaoEspecial)
                    ?.description || ""
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={tresCursos}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.cursoEducacaoEspecial}
                    helperText={
                      errors.cursoEducacaoEspecial
                        ? errors.cursoEducacaoEspecial.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>
    </>
  );
}

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import urlsConfiguracoes from '../../pages/configuracoes/urlsConfiguracoes';
import HomeConfiguracoes from '../../pages/configuracoes/homeConfiguracoes';
import UnidadesPage from '../../pages/unidades';
import EscolaridadesPage from '../../pages/escolaridades';
import TurnosPage from '../../pages/turnos';
import ResponsaveisPage from '../../pages/responsaveis';
import CargosPage from '../../pages/cargos';
import NacionalidadesPage from '../../pages/nacionalidades';
import UnidadesCadastrarPage from '../../pages/unidades/cadastrar';
import CargosCadastrarPage from '../../pages/cargos/cadastrar';
import EscolaridadesCadatrarPage from '../../pages/escolaridades/cadastrar';
import TurnosCadastrarPage from '../../pages/turnos/cadastrar';
import ResponsaveisCadastrarPage from '../../pages/responsaveis/cadastrar';
import NacionalidadesCadastrarPage from '../../pages/nacionalidades/cadastrar';
import DistritosPage from '../../pages/distritos';
import DistritoCadastrarPage from '../../pages/distritos/adicionar';
import DistritoAlocarPage from '../../pages/distritos/alocar';
import BanccFundamentalPage from '../../pages/bncc/fundamental';
import AlunosExcluidosPage from '../../pages/alunos/excluidos';
import AlunoDuplicadoPage from '../../pages/alunos/duplicados';
import ServidoresExcluidosPage from '../../pages/servidores/excluidos';
import WebCamPage from '../../pages/reconhecimento-facil/webcam';
import MapaPage from '../../pages/mapas';
import ChatInterativo from '../../pages/ia';
import DisciplinasCadastrarPage from '../../pages/disciplinas/DisciplinaCadastrarPage';
import DisciplinasPage from '../../pages/disciplinas/DisciplinasPage';
import AlunosManuaisPage from '../../pages/alunos/manuais';
import AlunosPreMatriculaPage from '../../pages/alunos/preMatricula';
import AlunosRemanejamentoPage from '../../pages/alunos/remanejamento';

const defaultPermisson = false;

const ConfiguracoesRoutes = () => (
    <React.Fragment>
        <Route exact path={urlsConfiguracoes.dashboard} element={ <HomeConfiguracoes />}/>
        <Route exact path={urlsConfiguracoes.unidades} element={ <UnidadesPage />}/>
        <Route exact path={urlsConfiguracoes.escolaridades} element={ <EscolaridadesPage />}/>
        <Route exact path={urlsConfiguracoes.turnos} element={ <TurnosPage />}/>
        <Route exact path={urlsConfiguracoes.responsaveis} element={ <ResponsaveisPage />}/>
        <Route exact path={urlsConfiguracoes.disciplinas} element={ <DisciplinasPage />}/>
        <Route exact path={urlsConfiguracoes.cargos} element={ <CargosPage />}/>
        <Route exact path={urlsConfiguracoes.nacionalidades} element={ <NacionalidadesPage />}/>
        <Route exact path={urlsConfiguracoes.webcam} element={ <WebCamPage configuracao={true}  />}/>

        <Route exact path={urlsConfiguracoes.unidadeAdicionar} element={ <UnidadesCadastrarPage />}/>
        <Route exact path={urlsConfiguracoes.unidadeEditarId} element={ <UnidadesCadastrarPage />}/>
        <Route exact path={urlsConfiguracoes.cargoAdicionar} element={ <CargosCadastrarPage />}/>
        <Route exact path={urlsConfiguracoes.cargoEditarId} element={ <CargosCadastrarPage />}/>
        <Route exact path={urlsConfiguracoes.disciplinasAdicionar} element={ <DisciplinasCadastrarPage />}/>
        <Route exact path={urlsConfiguracoes.disciplinasEditarId} element={ <DisciplinasCadastrarPage />}/>
        <Route exact path={urlsConfiguracoes.escolaridadeAdicionar} element={ <EscolaridadesCadatrarPage />}/>
        <Route exact path={urlsConfiguracoes.escolaridadeEditarId} element={ <EscolaridadesCadatrarPage />}/>
        <Route exact path={urlsConfiguracoes.turnoAdicionar} element={ <TurnosCadastrarPage />}/>
        <Route exact path={urlsConfiguracoes.turnoEditarId} element={ <TurnosCadastrarPage />}/>
        <Route exact path={urlsConfiguracoes.responsaveisAdicionar} element={ <ResponsaveisCadastrarPage />}/>
        <Route exact path={urlsConfiguracoes.responsaveisEditarId} element={ <ResponsaveisCadastrarPage />}/>
        <Route exact path={urlsConfiguracoes.nacionalidadeAdicionar} element={ <NacionalidadesCadastrarPage />}/>
        <Route exact path={urlsConfiguracoes.nacionalidadeEditarId} element={ <NacionalidadesCadastrarPage />}/>
        <Route exact path={urlsConfiguracoes.distritos} element={ <DistritosPage />}/>
        <Route exact path={urlsConfiguracoes.distritosEditarId} element={ <DistritoCadastrarPage />}/>
        <Route exact path={urlsConfiguracoes.distritosAdicionar} element={ <DistritoCadastrarPage />}/>
        <Route exact path={urlsConfiguracoes.distritoAlocar} element={ <DistritoAlocarPage />}/>
        <Route exact path={urlsConfiguracoes.distritoAlocarId} element={ <DistritoAlocarPage />}/>
        <Route exact path={urlsConfiguracoes.bnncFundamental} element={ <BanccFundamentalPage configuracao={true} />}/>
        <Route exact path={urlsConfiguracoes.alunosExcluidos} element={ <AlunosExcluidosPage configuracao={true} />}/>
        <Route exact path={urlsConfiguracoes.alunosDuplicado} element={ <AlunoDuplicadoPage configuracao={true} />}/>
        <Route exact path={urlsConfiguracoes.servidoresExcluidos} element={ <ServidoresExcluidosPage configuracao={true} />}/>
        <Route exact path={urlsConfiguracoes.mapas} element={ <MapaPage configuracao={true} />}/>
        <Route exact path={urlsConfiguracoes.chat} element={ <ChatInterativo configuracao={true} />}/>
        <Route exact path={urlsConfiguracoes.alunosManuais} element={ <AlunosManuaisPage configuracao={true} />}/>
        <Route exact path={urlsConfiguracoes.alunosPreMatricula} element={ <AlunosPreMatriculaPage configuracao={true} />}/>
        <Route exact path={urlsConfiguracoes.alunosRemanejamento} element={ <AlunosRemanejamentoPage configuracao={true} />}/>
    </React.Fragment>
);

export default ConfiguracoesRoutes;

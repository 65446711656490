import React, { useState, useEffect, useMemo } from 'react';
import Breadcrumb from '../../../components/breadcrumb';
import Table from '../../../components/table';
import Select from 'react-select';
import { useQuery } from 'react-query';
import BaseConfiguracoes from '../../configuracoes/baseConfiguracoes';
import BaseGestao from '../../gestao/baseGestao';
import BaseDocente from '../../docente/baseDocente';
import BaseGestaoUnidades from '../../gestao-unidades/baseGestaoUnidades';
import { getEscolaridades } from '../../../services/escolaridades';
import { getBnccByDisciplinaAnoFaixa } from '../../../services/bncc-fundamental';
import { getDisciplinas } from '../../../services/disciplinas';

const BanccFundamentalPage = (gestao) => {
  const [escolaridade, setEscolaridade] = useState('');
  const [disciplina, setDisciplina] = useState('');
  const [data, setData] = useState([]);

  const { data: escolaridadesData } = useQuery('escolaridades', getEscolaridades);
  const { data: disciplinasData } = useQuery('disciplinas', getDisciplinas);
  const { refetch: fetchBnccFundamental } = useQuery(
    ['bnccFundamental', escolaridade, disciplina],
    () => getBnccByDisciplinaAnoFaixa(disciplina, escolaridade),
    {
      enabled: false,
      onSuccess: (data) => setData(data)
    }
  );

  let BaseComponent;
  let BasePath;

  if (gestao.gestao) {
    BaseComponent = BaseGestao;
    BasePath = 'Gestão'
  } 
  if (!gestao.gestao && !gestao.docente) {
    BaseComponent = BaseGestaoUnidades;
    BasePath = 'Gestão Unidades'
  }

  // Segunda condição para substituir BaseComponent por BaseDocente, se docente.docente for verdadeiro
  if (gestao.docente) {
    BaseComponent = BaseDocente;
    BasePath = 'Docente'
  }

  if (gestao.configuracao) {
    BaseComponent = BaseConfiguracoes;
    BasePath = 'Configurações'
  }

  useEffect(() => {
    fetchBnccFundamental();
  }, [escolaridade, disciplina, fetchBnccFundamental]);

  const columns = useMemo(() => [
    { Header: '#', accessor: 'id' },
    { Header: 'Habilidades', accessor: 'habilidades' },
    // Add more columns as needed
  ], []);

  const handleSearch = () => {
    fetchBnccFundamental();
  };

  return (
    <BaseComponent>
    <div>
        
      <Breadcrumb title="BNCC Fundamental" itens={[BasePath, 'BNCC Fundamental']} />
      <div className="row">
  <div className="col-md-6">
    <Select
      options={escolaridadesData?.map(e => ({ value: e.id, label: e.descricao }))}
      onChange={e => setEscolaridade(e.label.substring(0, 2))}
      placeholder="Escolaridade"
    />
  </div>
  <div className="col-md-6">
    <Select
      options={disciplinasData?.map(e => ({ value: e.id, label: e.descricao }))}
      onChange={e => setDisciplina(e.label)}  // Make sure this should be `setDisciplina` or similar
      placeholder="Disciplina"  // Changed placeholder to match the data
    />
  </div>
  <div className="col-12">
    <button onClick={handleSearch} className="btn btn-primary mt-3">Buscar</button>
  </div>
</div>

<div className="row" style={{marginTop: 20}}>
        <div className="col-12">
          <div className="card">
      <Table columns={columns}  data={data?.data ? data?.data : []}/>
      </div>
      </div>
      </div>
    </div>
    </BaseComponent>
  );
};

export default BanccFundamentalPage;

export default async function handleDownloadExcel(response, fileName) {
    try {
        const blobType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

        // Processamento para o download do arquivo continua aqui...
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // Variante para IE
            window.navigator.msSaveOrOpenBlob(new Blob([response.data], { type: blobType }), fileName);
        } else {
            // Para outros navegadores
            const url = window.URL.createObjectURL(new Blob([response], { type: blobType }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url); // Limpa a URL após o download
            link.remove();
        }
    } catch (error) {
        console.error('Erro ao baixar arquivo.');
        // Tratar erros, como mostrar uma mensagem ao usuário
    }
}

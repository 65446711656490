import { getEscolaridade } from './../services/escolaridades';
import { getUnidade, getUnidadesById } from './../services/unidades';

const createStorageReportTurmas = async ({ dados, escolaridade, unidade, todasUnidades }) => {
    
    var unidadeDescricao = null;
    var escolaridadeDescricao = null;

    if(unidade != 0){
        unidadeDescricao = await getUnidade(unidade);
    }



    if(escolaridade != 0){
        escolaridadeDescricao = await getEscolaridade(escolaridade);
    }

    localStorage.setItem('reportTurmas', JSON.stringify(
        {
            logo: `/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`,
            dados: dados.sort((a, b) => {
                // First, sort by 'unidadeId'
                if (a.unidadeId !== b.unidadeId) {
                    return a.unidadeId - b.unidadeId;
                }
                // If 'unidadeId' is the same, sort by 'escolaridadeId'
                if (a.escolaridadeId !== b.escolaridadeId) {
                    return a.escolaridadeId - b.escolaridadeId;
                }
                // If both 'unidadeId' and 'escolaridadeId' are the same, sort by 'turnoId'
                return a.turnoId - b.turnoId;
            }),
            nomeTurmas: dados.nomeTurma,
            vagasTotais: dados.vagasTotais,
            vagasRegulares: dados.vagasRegulares,
            vagasPcd: dados.vagasPcd,
            alunosRegulares: dados.alunosRegulares,
            alunosPcd: dados.alunosPcd,
            saldo: dados.saldo,
            escolaridade: escolaridadeDescricao != null ? escolaridadeDescricao.descricao : "",
            nomeUnidade: unidadeDescricao != null ? unidadeDescricao.nome : "",
            todasUnidades: todasUnidades
        }
    ));
}

export default createStorageReportTurmas;
import api from '../index'

async function getModules(token) {
    return await api
      .get('modulo')
      .then(async (res) => {
        const { data } = res.data;
        return data;
      });
  };
  
  export {
    getModules
  }
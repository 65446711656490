import { useEffect, useMemo, useState } from 'react';
import { Breadcrumbs, Card, CardContent, Grid, Typography, FormControl, Autocomplete, TextField, IconButton, makeStyles } from '@mui/material';
import Table from '../../../../components/table';
import BaseAdministrativo from '../../baseAdministrativo';
import BaseGestaoUnidades from '../../../gestao-unidades/baseGestaoUnidades';
import { useNavigate } from 'react-router-dom';
import urlsAdministrativo from '../../urlsAdministrativo';
import urlsGestaoUnidades from '../../../gestao-unidades/urlsGestaoUnidades';
import { useQuery, useMutation } from 'react-query';
import { listarImpressoras, deletarImpressora, listarImpressorasUnidades, impressoraChamado } from '../../../../services/administrativo/patrimonio';
import { toast } from 'react-toastify';
import { getUnidades } from '../../../../services/unidades';
import Breadcrumb from '../../../../components/breadcrumb';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import { SupportAgent } from '@mui/icons-material';
import DialogChamado from './components/modal';
import { store } from '../../../../store';

const ImpressorasPage = (gestao, administrativo) => {
  const unidadeId = JSON.parse(localStorage.getItem('unidadeID'));
  const navigate = useNavigate();
  const [newData, setNewData] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [titulo, setTitulo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [impressoraId, setImpressoraId] = useState('');
  const [unidadeSelect, setUnidadeSelect] = useState(gestao.administrativo ? 0 : unidadeId);
  const [openModal, setOpenModal] = useState(false);
  const currentUser = store.getState()["user"]["user"];


  useQuery('unidades', getUnidades, {
    retry: true,
    onSuccess: (data) => {
        var items = [
            { 
                id: 0, 
                nome: "TODAS UNIDADES", 
                sigla: "TDS", 
                status: 1, 
                unidadeLotacao: 0, 
                unidadeLocacao: 0, 
                nomeDiretor: "", 
                inep: "", 
                endereco: 
                    { 
                        cep: "28941-414", 
                        logradouro: "JUSCELINO KUBSTHECK DE OLIVEIRA", 
                        numero: 547, 
                        complemento: "TESTE", 
                        bairro: "SOLEDADE", 
                        cidade: "SÃO PEDRO DA ALDEIA", 
                        estado: "RJ" 
                    }, 
                contato: 
                    { 
                        telefone: "998077975", 
                        email: "IP" 
                    }, 
                criacao: "2024-10-21T21:58:31.6747326-03:00", 
                ultimaAtualizacao: "2024-10-21T21:58:31.6747333-03:00"
             }
        ];
        items.push(...data);
        setUnidades(items);
    },
}
);

  const { refetch: refetchData, isLoading } = useQuery(
    'listarImpressoras',
    () => listarImpressorasUnidades(unidadeSelect, skip, limit),
    {
      enabled: true,
      retry: true,
      onSuccess: (data) => {
        setNewData(data);
      },
    }
  );


  const handleClose = () => {
    setOpenModal(false);
    setTitulo('');
    setDescricao('');
  };

  const abrirChamado = async () => {
    var response = await impressoraChamado(
      {
        id: 0,
        unidadeId: unidadeId,
        servidorId: currentUser?.servidorId,
        impressoraId: impressoraId,
        titulo: titulo,
        status: 1,
        descricao: descricao
      }
    );
    
    handleClose();
    if (response) {
      toast.success("Chamado aberto com sucesso!");
    } else{
      toast.error('Houve um problema ao tentar abrir o chamado!');
    }

  }

  useEffect(() => {
    refetchData();
  }, [unidadeSelect]);

  const { mutate, isLoading: loadingDelete } = useMutation(
    (id) => deletarImpressora(id),
    {
      onSuccess: (data) => {
        toast.success('Impressora excluída com sucesso.');
        refetchData();
      },
      onError: (data) => {
        toast.error('Erro ao excluir impressora. Tente novamente mais tarde.');
      },
    }
  );

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Marca', accessor: 'marca' },
      { Header: 'Modelo', accessor: 'modelo' },
      { Header: 'Número Série', accessor: 'numeroSerie' },
      { Header: 'Unidade', accessor: 'unidadeNome' },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) => (
          <>
            {/* <IconButton
              color="primary"
              onClick={() =>
                navigate(urlsAdministrativo.patrimonioControleImpressorasCadastrar + row.original.id, {
                  state: {
                    id: row.original.id,
                    marca: row.original.marca,
                    modelo: row.original.modelo,
                    numeroSerie: row.original.numeroSerie,
                    unidadeId: row.original.unidadeId,
                  },
                })
              }
            >
              <EditIcon />
            </IconButton> */}
            <IconButton
              color="error"
              onClick={() => {
                const confirm = window.confirm('Tem certeza que deseja excluir?');
                if (confirm) {
                  deletarImpressora(row.original.id);
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
            {!gestao.administrativo && (
              <IconButton
                color="default"
                onClick={() => navigate(urlsGestaoUnidades.controleImpressorasLancamento + row.original.id, {
                  state: {
                    id: row.original.id,
                    marca: row.original.marca,
                    modelo: row.original.modelo,
                    numeroSerie: row.original.numeroSerie,
                  },
                },
                )}

              >
                <PrintIcon />
              </IconButton>

            )}
            {!gestao.administrativo && (
              <IconButton
                color="primary"
                onClick={() => {
                  setImpressoraId(row.original.id);
                  setOpenModal(true);
                }}

              >
                <SupportAgent />
              </IconButton>

            )}

          </>
        ),
      },
    ],
    [navigate, isLoading, newData, loadingDelete]
  );

  let Base = gestao.administrativo ? BaseAdministrativo : BaseGestaoUnidades;
  let title = gestao.administrativo ? 'Administrativo' : 'Gestão de Unidades';
  let linkCadastrar = gestao.administrativo ? urlsAdministrativo.patrimonioControleImpressorasCadastrar : '';

  return (
    <Base>
      <DialogChamado
        isOpen={openModal}
        setOpenModal={setOpenModal}
        titulo={titulo}
        setTitulo={setTitulo}
        descricao={descricao}
        setDescricao={setDescricao}
        abrirChamado={abrirChamado}
      ></DialogChamado>
      <Breadcrumb title={'Impressoras'} itens={[title, 'Patrimônio', 'Impressoras']}
        actions={{ link: linkCadastrar, text: "Nova Impressora" }} />
      <Grid container spacing={2} style={{ marginBottom: 20 }}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              id="unidadeId-autocomplete"
              options={unidades || []}
              getOptionLabel={(option) => option.nome}
              value={unidades?.find((u) => u.id === unidadeSelect) || null}
              onChange={(e, newValue) => setUnidadeSelect(newValue ? newValue.id : '')}
              disabled={!gestao.administrativo}
              renderInput={(params) => <TextField {...params} label="Unidade" />}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Card>
        <CardContent style={{ backgroundColor: 'transparent' }}>
          <Table
            columns={columns}
            data={newData.data ? newData.data : []}
            hasPagination
            limit={limit}
            setLimit={setLimit}
            skip={skip}
            setSkip={setSkip}
            totalItems={newData.total}
          />
        </CardContent>
      </Card>
    </Base>
  );
};

export default ImpressorasPage;

import React, { useEffect, useState } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query'
import { getUnidadeMedida } from '../../../../../../../services/administrativo/patrimonio-produto'
import FormInput from '../../../../../../../components/formInput'
import InputWithSelect from '../../../../../../../components/inputWithSelect'
import { useForm } from 'react-hook-form'
import api from '../../../../../../../services'
import { postMovimentacao } from '../../../../../../../services/administrativo/patrimonio-estoque'
import { toast } from 'react-toastify'
import { getUnidades } from '../../../../../../../services/unidades'
import upperCaseSimple from '../../../../../../../utils/upperCaseSimple'
import removeEmpty from '../../../../../../../utils/removeEmpty'


export default function Produto({ fornecedorData, produtoData }) {
  const { id } = useParams()
  const { state } = useLocation();
  const [options, setOptions] = useState();
  const [selected, setSelected] = useState();
  const [controlVariable, setControlVariable] = useState(true)
  const navigate = useNavigate();

  const [produtoOptions, setProdutoOptions] = useState();
  const [selectedProduto, setSelectedProduto] = useState();
  const [controlVariableProduto, setControlVariableProduto] = useState(true)

  const { data: unidadeMedidaData, isFetched: unidadeMedidaFetched } = useQuery('getUnidadeMedida', () => getUnidadeMedida(), {
    enabled: true,
  })

  const { data: unidades } = useQuery('unidades', () => getUnidades(), {
    retry: true
  })

  // const getSelectedUnidade = (item) => {
  //     return item.id === state.unidades
  // }


  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid, errors }
  } = useForm({
    mode: 'onChange'
  })
  // Lógica fornecedores
  let fornecedor = watch('nome');

  useEffect(() => {
    if (fornecedor) {
      if (fornecedor === '') {
        setOptions()
        return
      }
      // if (fornecedor.length <= 2) {
      //   setOptions()
      //   return
      // }
      if (fornecedor.length >= 0) {
        getListaFornecedoresFiltrada(fornecedor)
      }
    }
  }, [fornecedor])

  useEffect(() => {
    if (fornecedorData && options === undefined) {
      getListaFornecedoresFiltrada(fornecedorData);
    }
    if (fornecedorData && options && (selected !== undefined)) {
      setSelected(options[0])
    }
    if (fornecedorData && options && controlVariable) {
      setSelected(options[0])
      setControlVariable(false)
    }
  }, [fornecedorData, options])

  const getListaFornecedoresFiltrada = async (fornecedor) => {
    const response = await api.get(`fornecedor/all?nome=${fornecedor}`);
    console.log({ response })
    setOptions(response.data.data)
  }

  useEffect(() => {
    if (selected) {
      const { nomeFantasia, cnpj } = selected.cadastro;
      setValue('nome', nomeFantasia)
      setValue('CNPJ', cnpj)
      // setValue('unidadeId', state.unidadeId)
    
      // setOptions()
    }
  }, [selected, setValue])
  // Fim lógica fornecedores


    // Lógica produtos
    let produto = watch('nomeProduto');

    useEffect(() => {
      if (produto) {
        if (produto === '') {
          setProdutoOptions()
          return
        }
        // if (produto.length <= 2) {
        //   setOptions()
        //   return
        // }
        if (produto.length >= 3) {
          getListaProdutoFiltrada(produto)
        }
      }
    }, [produto])
  
    useEffect(() => {
      if (produtoData && produtoOptions === undefined) {
        getListaProdutoFiltrada(produtoData);
      }
      if (produtoData && produtoOptions && (selectedProduto !== undefined)) {
        setSelectedProduto(produtoOptions[0])
      }
      if (produtoData && produtoOptions && controlVariableProduto) {
        setSelectedProduto(produtoOptions[0])
        setControlVariableProduto(false)
      }
    }, [produtoData, produtoOptions])
  
    const getListaProdutoFiltrada = async (produto) => {
      const response = await api.get(`patrimonio/produtos/filtro=${produto}`);
      setProdutoOptions(response.data.data)
    }
  
    useEffect(() => {
      if (selectedProduto) {
        const { nome, quantidade, valor, total, codigo, unidadeMedida, dataValidade } = selectedProduto;
        setValue('nomeProduto', nome)
        setValue('quantidadeEstoque', quantidade)
        setValue('custo', valor)
        setValue('valorEstoque', total)
        setValue('codigoProduto', codigo)
        setValue('dataValidade', dataValidade.split('T')[0])

        console.log('unidade medida data', unidadeMedidaData)

        const unidade = unidadeMedidaData?.find((unidade) => unidade.id === parseInt(unidadeMedida))
        console.log("unidade selec:" , unidade)
        const unidadeFormatted = unidade.descricao + " - " + unidade.sigla

        setValue('unidadeMedida', unidadeFormatted)
      
      }
    }, [selectedProduto, setValue])
    // Fim lógica produtos


  useEffect(() => {
    if (id) {
      setValue('id', id)
      setValue('descricao', state.descricao)
      setValue('status', state.status)
    }
  }, [state, setValue])

  const { mutate, isLoading } = useMutation(postMovimentacao, {
    onSuccess: data => {
      toast.success(data.message);
      navigate(-1)
    },
    onError: data => {
      toast.error(data.message);
    }
  })

  const onSubmit = (data) => {
    let params = {
      produtoId: parseInt(selectedProduto?.id),
      tipoMovimentacao: parseInt(data?.status),
      dataMovimentacao: data?.data,
      quantidade: parseInt(data?.quantidade),
      fornecedorId: parseInt(selected?.cadastro?.id),
      motivoMovimentacao: data?.motivoMovimentacao,
      unidadeId: parseInt(data?.unidadeId)
    } 
    mutate(upperCaseSimple(removeEmpty(params)))
  }

  return (
    <div
      className="tab-pane fade show active"
      id="movimentacao"
      role="tabpanel"
      aria-labelledby="movimentacao-tab"
    >
      <div className="card-body">
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          {id && (
            <div className="col-md-1">
              <FormInput
                register={register}
                errors={errors}
                atribute="id"
                label="ID"
                readOnly={true}
              />
            </div>
          )}

          <div className={id ? "col-md-6" : "col-md-7"}>
                   <InputWithSelect
                   register={register}
                   errors={errors}
                   atribute="nomeProduto"
                   label="Nome do produto"
                   options={produtoOptions}
                   name={produto}
                   selected={setSelectedProduto}
                   isSelected={selectedProduto ? true : false}
                   attribute1='nome'
                   attribute2='null'
                 />
          </div>

          <div className="col-md-3">
            <FormInput
              register={register}
              errors={errors}
              atribute="codigoProduto"
              label="Código"
              readOnly
            />
          </div>

          <div className="col-md-2">
              <FormInput
                register={register}
                errors={errors}
                atribute="quantidadeEstoque"
                label="Qtd. em estoque"
                readOnly
              />
            </div>

        </div>

        <div className="row" style={{ marginTop: 30 }}>
        <div className="col-md-3">
        <FormInput
              register={register}
              errors={errors}
              atribute="unidadeMedida"
              label="Unidade de medida"
              readOnly
            />
          </div>

          <div className="col-md-3">
            <FormInput
              register={register}
              errors={errors}
              atribute="custo"
              label="Custo do produto"
              readOnly
            />
          </div>

          <div className="col-md-3">
            <FormInput
              register={register}
              errors={errors}
              atribute="valorEstoque"
              label="Valor do Estoque"
              readOnly
            />
          </div>

          <div className="col-md-3">
              <FormInput
                register={register}
                errors={errors}
                type='date'
                atribute="dataValidade"
                label="Data de validade"
                readOnly
              />
            </div>
        </div>

        <div className="row" style={{ marginTop: 30 }}>
        <div className="col-md-7">
            <label htmlFor="status">Tipo de movimentação<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
            <select className="form-select" id="tipoMovimentacao" {...register("status", { required: false })}>
              <option>SELECIONE</option>
              <option value={1}>ENTRADA</option>
              <option value={2}>SAÍDA</option>
            
            </select>
            {errors.status?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
          </div>

          <div className="col-md-3">
            <FormInput
              register={register}
              errors={errors}
              type='date'
              atribute="data"
              label="Data da movimentação"
            />
          </div>
          <div className="col-md-2">
            <FormInput
              register={register}
              errors={errors}
              atribute="quantidade"
              label="Quantidade"
            />
          </div>
        </div>

        <div className="row" style={{ marginTop: 30 }}>
        <div className="col-md-12">
            <FormInput
              register={register}
              errors={errors}
              atribute="motivoMovimentacao"
              label="Motivo da movimentação"
            />
          </div>
        </div>

        <div className="row" style={{ marginTop: 30 }}>
        <div className="col-md-12">
              <label htmlFor="unidades" >
                Unidade
              </label>
              <select
                className="form-select"
                id="unidadeId"
                {...register('unidadeId', { required: true })}
              >
                <option value={null}>SELECIONE</option>
                {unidades?.map(unidade => (
                  <option key={unidade.id} value={unidade.id} 
                  // selected={getSelectedUnidade(unidade)}
                  >
                    {unidade.nome}
                  </option>
                ))}
              </select>
              {errors.unidadeLotacao && (
                <div role="alert" className="invalid-feedback d-block">
                  Campo obrigatório!
                </div>
              )}

          </div>
        </div>
        
        <div className="row" style={{ marginTop: 30 }}>
          <div className="col-md-8">
          <InputWithSelect
              register={register}
              errors={errors}
              atribute="nome"
              label="Nome do fornecedor"
              options={options}
              name={fornecedor}
              selected={setSelected}
              isSelected={selected ? true : false}
            />
          </div>

          <div className="col-md-4">
              <FormInput
                register={register}
                errors={errors}
                atribute="CNPJ"
                label="CNPJ"
                readOnly
              />
            </div>

        </div>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-end',
                        marginTop: '30px'
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => navigate(-1)}
                        style={{ marginRight: '8px' }}
                      >
                        Voltar
                      </button>

                      <button
                        type="submit"
                        className="btn btn-success"
                        disabled={!isValid}
                      >
                        {id ? 'Atualizar' : 'Cadastrar'}
                      </button>
                    </div>
                    </form>
      </div>
    </div>
  )
}

import Table from '../../../../../components/table'
import { useMemo, useState, useEffect } from 'react'
import { listaEspera, listarFiltroUnidades, listarFiltroEscolaridades } from '../../../../../services/processos/chamadaPublica'
import { useQuery } from 'react-query'
import './style.css'
import { Alert } from '@mui/material'


const ListaDeEsperaPage = () => {


const [unidade, setUnidade] = useState(0)
const [escolaridade, setEscolaridade] = useState(0)
const [unidadesData, setUnidadesData] = useState([])
const [escolaridadesData, setEscolaridadesData] = useState([])
const [listaEsperaData, setListaEsperaData] = useState([])
const [skip, setSkip] = useState(0);
const [limit, setLimit] = useState(50);

const {
    data: unidades,
    isLoading: loadingUnidades,
    refetch: refetchUnidades
  } = useQuery(
    'listarFiltroUnidades',
    () => listarFiltroUnidades(0),
    {
      retry: 0,
      enabled: true,
      onSuccess: data => {
        console.log(data)
        setUnidadesData(data)
      }
    }
  )

  const {
    data: escolaridades,
    isLoading: loadingEscolaridades,
    refetch: refetchEscolaridades
  } = useQuery(
    'listarFiltroEscolaridades',
    () => listarFiltroEscolaridades(0, unidade),
    {
      retry: 0,
      enabled: unidade != 0,
      onSuccess: data => {
        console.log(data)
        setEscolaridadesData(data)
      }
    }
  )

  const {
    data: inscricoes,
    isLoading: loadingListaEspera,
    refetch: refetch
  } = useQuery(
    'listaEspera',
    () => listaEspera(unidade, escolaridade, skip, limit),
    {
      retry: 0,
      enabled: unidade !=0 && escolaridade != 0,
      onSuccess: data => {
        console.log(data)
        setListaEsperaData(data)
      }
    }
  )

const columns = useMemo(
    () => [
        { Header: 'Posição', accessor: 'posicao' },
        { Header: 'Nome', accessor: 'nome' },
      { Header: 'ID', accessor: 'id' },
      { Header: 'Status', accessor: 'status' },
      {
        Header: 'PcD', accessor: 'deficiente',
        Cell: ({ row }) => (
          <span className={`badge bg-${row.original.deficiente == 1 ? 'success' : 'danger'}`}>
            {row.original.deficiente == 1 ? 'SIM' : 'NÃO'}
          </span>
        )
      },
      {
        Header: 'Estudante da Rede', accessor: 'estudanteDaRede',
        Cell: ({ row }) => (
          <span className={`badge bg-${row.original.estudanteDaRede == 1 ? 'success' : 'danger'}`}>
            {row.original.estudanteDaRede == 1 ? 'SIM' : 'NÃO'}
          </span>
        )
      },
    //   { Header: 'Pontuação', accessor: 'pontuacao' },
    //   { Header: 'Data Criação', accessor: 'registro' }
    ],
  )

  useEffect(() => {
    refetch()
  }, [refetch, limit, skip])


  return (
    <div>
      <div className='content-body'>
        <img src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`} width="200" height="150" style={{ display: 'flex', alignSelf: 'center' }} />
        <h3 style={{ margin: '15px', alignSelf: 'center' }}>SECRETARIA DE EDUCAÇÃO - PREFEITURA MUNICIPAL DE SÃO PEDRO</h3>
        <h2 style={{ margin: '15px', alignSelf: 'center' }}>LISTA ESPERA</h2>

        <Alert variant="filled" severity="info" style={{marginBottom: 20, marginTop: 20}}>Senhores(as) responsáveis! <br></br>

        A cada nova convocação, será mantida a priorização do chamamento dos alunos PcDs(Pessoa com Deficiência), respeitando a capacidade máxima por turma. Não havendo aluno PcD na lista de espera, as vagas serão destinadas aos alunos regulares.</Alert>
   

      <div className="row" style={{ marginBottom: 20 }}>
      <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="unidade" className="form-label">
            Unidade
            </label>
            <select
              className="form-select"
              id="unidade"
              value={unidade}
              onChange={async e => {
                await setUnidade(e.target.value)
                await setEscolaridade(0)
                await refetchEscolaridades()
                await setListaEsperaData([])
              }}
            >
                <option value={0}></option>
              {unidadesData?.map(unidade => (
                              <option key={unidade.id} value={unidade.id}>
                                {unidade.nome}
                              </option>
                            ))}
              
            </select>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="escolaridade" className="form-label">
            Escolaridades
            </label>
            <select
              className="form-select"
              id="escolaridade"
              value={escolaridade}
              onChange={async e => {
                await setEscolaridade(e.target.value)
                await setLimit(50)
                await setSkip(0)
                refetch()
              }}
            >

            <option value={0}></option>
              {escolaridadesData?.map(item => (
                              <option key={item.id} value={item.id}>
                                {item.nome}
                              </option>
                            ))}
               
            </select>
          </div>
        </div>

        
        <div className="col-12" style={{marginTop: 30}}>
          <div className="card">
            <Table
              columns={columns}
              data={listaEsperaData?.data ? listaEsperaData?.data : []}
              hasPagination
              limit={limit}
              setLimit={setLimit}
              skip={skip}
              setSkip={setSkip}
              totalItems={listaEsperaData?.total}
              customPagination={[50]}
            />
          </div>
        </div>
      </div>

      
    </div>
    </div>
  );

}

export default ListaDeEsperaPage
import React, { useEffect } from 'react';

const SuporteRodape = () => {
  useEffect(() => {
    // Create the script element
    const ttChatLoaderS = document.createElement('script');
    ttChatLoaderS.type = 'text/javascript';
    document.tomticketChatLoaderScriptVersion = 2;
    ttChatLoaderS.src = `https://r3mais.tomticket.com/scripts-chat/chat.min.js?id=EP62621&account=4007744P11122023102433&autoOpen=0&hideWhenOffline=0&d=r3mais&ts=${new Date().getTime()}&ref=${encodeURIComponent(document.URL)}`;
    
    // Append the script to the body

    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      document.body.appendChild(ttChatLoaderS);
    }

    // Remove the script from the body when the component unmounts
    return () => {
      if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      document.body.removeChild(ttChatLoaderS);
      }
    };
  }, []); // Empty array ensures this effect runs once on mount and cleanup on unmount

  return null; // This component does not render anything
};

export default SuporteRodape;
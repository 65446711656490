const Breadcrumb = ({
  itens,
  title,
  description,
  imprimir = { link: false, text: "-" },
  actions = { link: false, text: "-" },
  voltar = { link: false, text: "-" },
  onClick,
  onClickDownload,
  onClickImprimir,
  checkBefore = true,
  hasExcelButton = false,
  hasModal = false,
  onClickModal,
  modalTitulo,

}) => {

  return (
    <div className="col">
      <div className="page-description d-flex align-items-center">
        <div className="page-description-content flex-grow-1">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              {itens.map((iten, index) => (
                <li className="breadcrumb-item active" key={index}>{iten}</li>
              ))}
            </ol>
          </nav>
          <h4>{title}</h4>
          {description ? <span>
            {description}
          </span> : ''}
        </div>
        {hasExcelButton && (
          <div className="page-description-actions">
            <button className="btn btn-success" onClick={onClickDownload} style={{ marginRight: '20px' }} >
              <i className="material-icons md-download"></i>Exportar
            </button>
          </div>
        )}
        {actions.link && (
          <div className="page-description-actions" style={{ marginRight: '20px' }}>
            <a href={checkBefore ? actions.link : null} className="btn btn-info" onClick={onClick} >
              <i className="material-icons md-add"></i>{actions.text}
            </a>
          </div>
        )}
        {voltar.link && (
         <div className="page-description-voltar">
           <a href={checkBefore ? voltar.link : null} className="btn btn-warning" onClick={onClick} >
             <i className="material-icons md-undo"></i>{voltar.text}
           </a>
         </div>
       )}
       {hasModal && (
        <div className="page-description-imprimir" style={{marginLeft: '5px'}}>
          <a href={null} className="btn btn-primary" onClick={onClickModal} >
             <i className="material-icons md-add"></i>{modalTitulo}
           </a>
        </div>
      )}
       {imprimir.link && (
        <div className="page-description-imprimir" style={{marginLeft: '5px'}}>
          <a href={null} className="btn btn-success" onClick={onClickImprimir} >
             <i className="material-icons md-print"></i>{imprimir.text}
           </a>
        </div>
      )}
      </div>
    </div>
  )
}

export default Breadcrumb;

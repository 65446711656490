import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/`,
});

function successHandler(response) {
  return response;
}

async function errorHandler(error) {
  return Promise.reject(error);
}

api.interceptors.request.use(async (config) => {
  config.headers['Tenant-Id'] = `${process.env.REACT_APP_TENANT_ID}`
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

api.interceptors.response.use(response => {
  return response;
}, error => {
  if (error?.response?.status === 401) window.location.href = "/";
  return error;
});

export default api;
import React, { useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import FormInput from '../../../../../../../components/formInput';

export default function Clausula({ register, errors, setValue, canEdit, watch, getValues }) {
  const { id } = useParams()
  const { state } = useLocation();

  return (
    <div
      className="tab-pane fade show"
      id="clausula"
      role="tabpanel"
      aria-labelledby="clausula-tab"
    >
      <div className="card-body">
        <div className="row">

          <div className="col-md-12">
            <label htmlFor="clausulasCondicoes">
              Cláusulas e condições 
              {/* <span style={{ color: 'red', marginLeft: '4px' }}>*</span> */}
            </label>
            <textarea id="clausulasCondicoes" style={{ height: '300px', width: '100%', border: '1px solid #2269f5', borderRadius: '5px' }}
              {...register('clausulasCondicoes', { required: false })}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

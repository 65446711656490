import { useEffect, useState } from "react";
import styles from "./home.module.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

const cards = [
  {
    title: "Personalização do Aprendizado",
    image: "/assets/images/eduAmigo/0.png",
  },
  {
    title: "Linguagem Apropriada",
    image: "/assets/images/eduAmigo/1.png",
  },
  {
    title: "Preparação para o Futuro",
    image: "/assets/images/eduAmigo/2.png",
  },
  {
    title: "Conteúdo Seguro",
    image: "/assets/images/eduAmigo/3.png",
  },
  {
    title: "Monitoramento do Progresso",
    image: "/assets/images/eduAmigo/4.png",
  },
];

function EduAmigoHome() {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (window.innerWidth >= 768) {
      AOS.init({ duration: 1000 });
    } else {
      const elements = document.querySelectorAll("[data-aos]");
      elements.forEach((el) => {
        el.removeAttribute("data-aos");
      });
    }
  }, []);

  return (
    <>
      <nav className={styles.nav}>
        <div className={styles.logo}>
          <a href="#first">eduamigo</a>
        </div>
        <ul className={styles.ul}>
          <li>
            <a href="#first">Início</a>
          </li>
          <li>
            <a href="#security">Segurança</a>
          </li>
          <li>
            <a href="#learn">Aprenda</a>
          </li>
          <li>
            <a href="#talk">Converse</a>
          </li>
          <li>
            <a href="#contact">Contato</a>
          </li>
        </ul>
        <button
          onClick={() => navigate("/desenvolvimento/eduAmigo/fale")}
          className={`${styles.button} ${styles.buttonNav}`}
        >
          Fale com Eduamigo
        </button>
        <MenuIcon
          sx={{ fontSize: 40 }}
          className={styles.menuMobileIcon}
          onClick={() => setMenuOpen(!menuOpen)}
        />
      </nav>

      {/* Menu mobile */}
      <div className={`${styles.mobileMenu} ${menuOpen ? styles.open : ""}`}>
        <ul>
          <li>
            <a href="#first" onClick={() => setMenuOpen(false)}>
              Início
            </a>
          </li>
          <li>
            <a href="#security" onClick={() => setMenuOpen(false)}>
              Segurança
            </a>
          </li>
          <li>
            <a href="#learn" onClick={() => setMenuOpen(false)}>
              Aprenda
            </a>
          </li>
          <li>
            <a href="#talk" onClick={() => setMenuOpen(false)}>
              Converse
            </a>
          </li>
          <li>
            <a href="#contact" onClick={() => setMenuOpen(false)}>
              Contato
            </a>
          </li>
        </ul>
        <button
          onClick={() => {
            navigate("/desenvolvimento/eduAmigo/fale");
            setMenuOpen(false);
          }}
          className={styles.buttonMobile}
        >
          Fale com Eduamigo
        </button>
      </div>

      <main className={styles.main}>
        <section id="first" className={styles.firstSection}>
          <div className={styles.backgroundImage}></div>
          <div className={styles.containerFirstSection}>
            <div className={styles.speakContainer}>
              <h1 className={styles.font48}>
                O seu melhor amigo na hora de aprender e se divertir
              </h1>
              <p className={styles.font20}>
                Com o Eduamigo, você aprende se divertindo! Conte com ele para
                as melhores experiências em educação.
              </p>
              <button
                onClick={() => navigate("/desenvolvimento/eduAmigo/fale")}
                className={`${styles.buttonWhite} ${styles.button}`}
              >
                Fale com Eduamigo
              </button>
            </div>
            <div className={styles.width50}>
              <img src="/assets/images/eduAmigo/hero.png" alt="" height={600} />
            </div>
          </div>
        </section>

        <section className={styles.secondSection}>
          {cards.map((card) => (
            <div
              data-aos="flip-left"
              className={styles.cardContainer}
              key={card.title}
            >
              <h2 className={styles.font24}>{card.title}</h2>
              <div>
                <img src={card.image} alt="" />
              </div>
            </div>
          ))}
        </section>

        <section id="security" className={styles.thirdSection}>
          <div className={styles.container}>
            <h2>Aprenda em total segurança</h2>
            <div className={styles.cards}>
              <div
                data-aos="zoom-out"
                className={`${styles.card} ${styles.card1}`}
              >
                <img
                  src="/assets/images/eduAmigo/hero2left.png"
                  alt="Imagem de uma criança com um tablet"
                />
                <p>
                  O Eduamigo é uma inteligência artificial desenvolvida para
                  interagir com as crianças, ensinando de uma forma divertida e
                  totalmente segura.
                </p>
              </div>
              <div
                data-aos="zoom-out"
                className={`${styles.card} ${styles.card2}`}
              >
                <img
                  src="/assets/images/eduAmigo/hero2right.png"
                  alt="Imagem de uma criança com mochila"
                />
                <p>
                  Todo o desenvolvimento foi pensado em garantir a segurança das
                  crianças, desde o conteúdo indicado até as escolhas das
                  palavras. Somos uma inteligência artificial certificada e
                  comprometida com a segurança dos nossos pequenos.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="learn" className={styles.fourthSection}>
          <div data-aos="zoom-out-right">
            <img
              src="/assets/images/eduAmigo/hero3center.png"
              alt=""
              width={425}
            />
          </div>
          <div data-aos="zoom-in-left" className={styles.textContainerFourth}>
            <h2>
              Utilize o Eduamigo para aprender coisas novas todos os dias!!
            </h2>
            <p>
              Conte com um aprendizado em todas as áreas, com uma linguagem
              didática e lúdica. Utilizando de histórias e curiosidades para
              despertar o interesse e criatividade das crianças.
            </p>
            <h2>Aprenda “como”</h2>
            <p>
              Com o Eduamigo, entenda “como” chegar na resposta e os motivos que
              fazem dela a resposta certa.
            </p>
          </div>
        </section>

        <section id="talk" className={styles.fifthSection}>
          <div className={styles.containerFirstSection}>
            <div className={styles.textContainerFifth}>
              <h2>Converse com o Eduamigo</h2>
              <p>
                Converse com o Eduamigo e deixe ele te mostrar como você pode
                aprender algo novo hoje! Falar com o Eduamigo
              </p>
              <button
                onClick={() => navigate("/desenvolvimento/eduAmigo/fale")}
                className={`${styles.buttonPurple} ${styles.button}`}
              >
                Fale com Eduamigo
              </button>
            </div>
            <div data-aos="fade-left">
              <img
                src="/assets/images/eduAmigo/hero3topright.png"
                alt=""
                width={443}
              />
            </div>
          </div>
        </section>

        <section id="contact" className={styles.sixthSection}>
          <div>
            <img src="/assets/images/eduAmigo/hero4.webp" alt="" width={440} />
          </div>
          <div data-aos="fade-left" className={styles.textContainerFourth}>
            <h2>Transforme a aprendizagem escolar das crianças!</h2>
            <p>
              Com conteúdos personalizados e interativos, ela torna o estudo
              mais envolvente e eficaz. Suas atividades envolventes mantêm as
              crianças motivadas e curiosas, promovendo um aprendizado contínuo
              e agradável.
            </p>
            <button className={`${styles.buttonPurple} ${styles.button}`}>
              Fale com nossa equipe
            </button>
          </div>
        </section>

        <footer className={styles.footer}>
          <div className={styles.footerContainer}>
            <div className={styles.logo}>
              <a href="#first">eduamigo</a>
            </div>
            <div className={styles.font20}>
              © 2024 Eduamigo. All Rights Reserved.
            </div>
          </div>
        </footer>
      </main>
    </>
  );
}

export default EduAmigoHome;

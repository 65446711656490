import { useMemo, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import Breadcrumb from '../../../../../components/breadcrumb'
import BaseGestaoUnidades from '../../../../gestao-unidades/baseGestaoUnidades'
import urlsGestaoUnidades from '../../../../gestao-unidades/urlsGestaoUnidades'
import Table from '../../../../../components/table'
import { useMutation, useQuery } from 'react-query'
import { getLancamentoImpressaoUnidade, postLancamentoImpressao } from '../../../../../services/administrativo/patrimonio'
import removeEmpty from '../../../../../utils/removeEmpty'
import FormInput from '../../../../../components/formInput'
import api from '../../../../../services'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button } from "@mui/material";
import { listaAnexo } from '../../../../../services/processos/remanejamento'

const ControleImpressorasLancamento = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { state } = useLocation()
  const unidade = localStorage.getItem('unidadeID');
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [impressorasLancamentoData, setImpressorasLancamentoData] = useState()
  
  const { data: lancamentosUnidades } = useQuery('getLancamentoImpressaoUnidade', () => getLancamentoImpressaoUnidade(unidade, id, skip, limit), {
    retry: true,
    // onSuccess: (data) => {
    //   setImpressorasLancamentoData(data.data)
    // }
  })

  useEffect(() => {
    
    setImpressorasLancamentoData(lancamentosUnidades)
  }, [lancamentosUnidades])

  const meses = [
    {
        id: 1,
        descricao: 'JANEIRO'
    },
    {
        id: 2,
        descricao: 'FEVEREIRO'
    },
    {
        id: 3,
        descricao: 'MARÇO'
    },
    {
        id: 4,
        descricao: 'ABRIL'
    },
    {
      id: 5,
      descricao: 'MAIO'
    },
    {
      id: 6,
      descricao: 'JUNHO'
    },
    {
      id: 7,
      descricao: 'JULHO'
    },
    {
      id: 8,
      descricao: 'AGOSTO'
    },
    {
      id: 9,
      descricao: 'SETEMBRO'
    },
    {
      id: 10,
      descricao: 'OUTUBRO'
    },
    {
      id: 11,
      descricao: 'NOVEMBRO'
    },
    {
      id: 12,
      descricao: 'DEZEMBRO'
    },

  ]

  const { mutate, isLoading } = useMutation(
    postLancamentoImpressao,
    {
      onSuccess: () => {
        toast.success('Lançamento efetuado com sucesso!')
        navigate(-1)
      },
      onError: error => {
        if (error.response) {
          const { message } = error.response.data
          toast.error(message)
          toast.error(
            'Erro ao processar a solicitação, tente novamente mais tarde.'
          )
        } else if (error.request) {
          toast.error(
            'Erro ao processar a solicitação, tente novamente mais tarde.'
          )
        } else {
          toast.error(
            'Erro ao processar a solicitação, tente novamente mais tarde.'
          )
        }
      }
    }
  )

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid, errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      impressoraId: null,
      copias: 0,
      ano: new Date().getFullYear()
    }
  })

  const onSubmit = async () => {
    debugger
    if (id) {

      debugger

     

      const payload = {
        impressoraId: parseInt(id),
        mesId: parseInt(watch('mesId')),
        ano: parseInt(watch('ano')),
        copias: parseInt(watch('copias')),
      }
      mutate(removeEmpty(payload))
    }
  }

  useMemo(() => {
    (async () => {
      if (state) {
        setValue('id', state.id)
          setValue('impressoraId', state.id)
          setValue('marca', state.marca)
          setValue('modelo', state.modelo)
          setValue('numeroSerie', state.numeroSerie)
      }
    })()
  }, [state])

  const handleDownload = async (arquivoId) => {
    try {
      
  
      // Recebe o arquivo da função listaAnexo (agora como Blob)
      const arquivoBlob = await listaAnexo(arquivoId);

      const extensao = arquivoBlob.type;
  
      // Gera uma URL para o Blob
      const blobUrl = URL.createObjectURL(arquivoBlob);
  
      // Verificar a extensão e abrir o arquivo em uma nova aba
      if (extensao === "application/pdf") {
        const pdfWindow = window.open(blobUrl, "_blank");
        if (!pdfWindow) {
          alert("Permita pop-ups para visualizar o PDF.");
        }
      } else if (extensao === "image/png") {
        const imgWindow = window.open(blobUrl, "_blank");
        if (!imgWindow) {
          alert("Permita pop-ups para visualizar a imagem.");
        }
      } else {
        // Para outros tipos de arquivos, abre uma nova aba
        const newWindow = window.open(blobUrl, "_blank");
        if (!newWindow) {
          alert("Permita pop-ups para visualizar o arquivo.");
        }
      }
  
      // Revogar a URL após abrir o arquivo para liberar memória
      URL.revokeObjectURL(blobUrl);
  
    } catch (error) {
      console.error('Erro ao baixar o arquivo:', error);
    }
  };

  
  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Unidade', accessor: 'unidadeNome' },
      { Header: 'Mês', accessor: 'mesExtenso' },
      {
        Header: 'Data Lançamento', accessor: 'dataLancamento',
        Cell: ({ row }) => (
          row.original.dataLancamento.split('T')[0].split('-').reverse().join('/')
        )
      },
      { Header: 'Cópias', accessor: 'copias' },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) => (
          <>
            {row.original.anexoId && row.original.anexoId !== '00000000-0000-0000-0000-000000000000' && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<VisibilityIcon />}
          onClick={() =>
            handleDownload(row.original.anexoId)
          }
        >
          Visualizar
        </Button>
      )}
            
            {/* <Button
              variant="contained"
              color="error"
              size="small"
              startIcon={<DeleteIcon />}
              onClick={() => handleDelete(row.original.arquivoId)}
              style={{ marginLeft: '10px' }} // Add space between buttons
            >
              Deletar
            </Button> */}
          </>
        )
      }
    ],
    [navigate]
  );

  const handleUpdate = async () => {

    if (id) {

      let anexo = watch('anexo')
      let anexoId = ''

      if (anexo.length > 0) {
        const formData = new FormData();
        formData.append('arquivo', anexo[0]);
        const response = await api.post('/arquivo/processo-seletivo', formData);
  
        if (response?.data?.statusCode !== 201) {
          return toast.error('Erro ao enviar arquivo.');
        }
        anexoId = response.data.data.id;
      }
  
      if (anexo.length > 0 && !anexoId) {
        return toast.error('Erro ao enviar arquivo..');
      }

      debugger
      
      const payload = {
        impressoraId: parseInt(id),
        mesId: parseInt(watch('mesId') == "" ? '0' : watch('mesId')),
        ano: parseInt(watch('ano')),
        copias: parseInt(watch('copias')),
        unidadeId: parseInt(unidade),
        anexoId: anexoId
      }
      mutate(removeEmpty(payload));
    }
  };

  return (
    <BaseGestaoUnidades>
      <Breadcrumb
        title={'Formulário Impressora'}
        itens={['Gestão Unidades', 'Impressora', 'Formulário']}
      />
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <>
                  <div className="row">
                  <div className="col-2">
                      <FormInput
                        register={register}
                        errors={errors}
                        atribute="id"
                        label="ID"
                        readOnly={true}
                      />
                    </div>
                    <div className="col-4">
                      <FormInput
                        register={register}
                        errors={errors}
                        atribute="marca"
                        label="Marca"
                        readOnly={true}
                      />
                    </div>

                    <div className="col-6">
                      <FormInput
                        register={register}
                        errors={errors}
                        atribute="modelo"
                        label="Modelo"
                        readOnly={true}
                      />
                    </div>
                  </div>

                  <br />
                  

                  <div className="row">
                  <div className="col-6">
                      <FormInput
                        register={register}
                        errors={errors}
                        atribute="numeroSerie"
                        label="Numéro de Série"
                        readOnly={true}
                      />
                    </div>
                

                  <div className={"col-md-2"}>
              <label htmlFor="mesId" >
                Mês
              </label>
              <select
                className="form-select"
                id="mesId"
                
                {...register('mesId', { required: true })}
              >
                <option value={null}></option>
                {meses?.map(mes => (
                  <option key={mes.id} value={mes.id} 
                  // selected={getSelectedUnidade(unidade)}
                  >
                    {mes.descricao}
                  </option>
                ))}
              </select>

            </div>
            

          

          <div className={"col-md-2"}>
            <FormInput
              register={register}
              errors={errors}
              atribute="ano"
              label="Ano"
            />
          </div>

          <div className={"col-md-2"}>
            <FormInput
              register={register}
              type='number'
              errors={errors}
              atribute="copias"
              label="Cópias"
            />
          </div>
          </div>
                   

                  <br />

                  <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="anexo">Anexo (Imagem ou PDF)</label>
                    <input type="file" id="anexo" {...register('anexo')} accept="image/*,application/pdf" className="form-control" />
                  </div>
                </div>
    



                  
                </>
              </div>
              <div className="card-footer text-end">
                <button
                  type='button'
                  style={{ marginRight: '8px' }}
                  className="btn btn-warning"
                  onClick={() => navigate(-1)}
                >
                  Voltar
                </button>
                <button
                  type={id ? 'button' : 'submit'}
                  className="btn btn-success"
                  onClick={id ? () => handleUpdate() : null}
                >
                  Cadastrar
                  
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
          {console.log(impressorasLancamentoData)}
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <Table
                columns={columns}
                // data={[]}
                data={impressorasLancamentoData ? impressorasLancamentoData?.data?.data : []}
                hasPagination
                limit={limit}
                setLimit={setLimit}
                skip={skip}
                setSkip={setSkip}
                totalItems={0}

              />
            </div>
          </div>
        </div>
      </div>
    </BaseGestaoUnidades>
  )
}

export default ControleImpressorasLancamento

import api from '..';
import axios from 'axios';

const api2 = axios.create({
  baseURL: 'https://api2.r3mais.com.br',
  headers: {
    'Content-Type': 'application/json',
  },
});

async function storeFace(data) {
  return await api
    .post('/imagem', data)
    .then(async (res) => {
        console.log(res)
      return res;
    })
    .catch((error) => {
      console.error('Error storing face:', error);
      throw error;
    });
}

async function storeFacePython(data) {
  return await api2
    .post('/face/upload', data)
    .then(async (res) => {
        console.log(res)
      return res.data;
    })
    .catch((error) => {
      console.error('Error storing face:', error);
      throw error;
    });
}

async function compareFaceServidor(data) {
    return await api
      .post('/imagem/comparar/servidor', data)
      .then(async (res) => {
          console.log(res)
        return res;
      })
      .catch((error) => {
        throw error;
      });
  }

  async function compareFaceAluno(data) {
    return await api
      .post('/imagem/comparar/aluno', data)
      .then(async (res) => {
          console.log(res)
        return res;
      })
      .catch((error) => {
        throw error;
      });
  }

  async function listarReconhecimentoFacial(unidade, skip, limit) {
    return await api
      .get(`imagem?unidadeId=${unidade}&skip=${skip}&limit=${limit}`)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  }

export {
  storeFace,
  compareFaceServidor,
  compareFaceAluno,
  storeFacePython,
  listarReconhecimentoFacial
};

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px 0px;
  border: #a1a1a1 1px solid;
  width: ${props => props.fieldWidth && `${props.fieldWidth}%`};
`;

export const SpanLabel = styled.label`
  color: #999;
  font-size: 10px;
  line-height: 10px;
`;

export const FieldValue = styled.b`
  padding-left: 5px;
  font-size: 14px;
`;
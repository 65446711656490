import React from 'react';
import { Routes, Route } from 'react-router-dom';
import urlsGestaoUnidades from '../../pages/gestao-unidades/urlsGestaoUnidades';
import HomeGestaoUnidades from '../../pages/gestao-unidades/homeGestaoUnidades';
import AlunosAdicionarPage from '../../pages/alunos/adicionar';
import AlunosAprovacaoPage from '../../pages/alunos/aprovacao';
import AlunosPage from '../../pages/alunos';
import AlunosEvadirPage from '../../pages/alunos/evadir';
import TurmasPage from '../../pages/turmas';
import TurmaAlunosPage from '../../pages/turmas/alunos';
import ServidoresPage from '../../pages/servidores';
import ServidoresAdicionarPage from '../../pages/servidores/adicionar';
import TransferenciasRecebidasPage from '../../pages/transferencias/recebidas';
import TransferenciasEnviadasPage from '../../pages/transferencias/enviadas';
import TransferenciasAprovacaoPage from '../../pages/transferencias/aprovacao';
import TransferenciasCancelamentoPage from '../../pages/transferencias/cancelamento';
import TurmasAdicionarPage from '../../pages/turmas/adicionar';
import QuadroHorarioPage from '../../pages/quadro-horarios';
import QuadroDeHorarioDisciplinas from '../../pages/quadro-horarios/disciplinas';
import QuadroDeHorarioAlocaDisciplinas from '../../pages/quadro-horarios/alocacaoDisciplinas';
import QuadroHorarioImpressao from '../../pages/quadro-horarios/imprimir';
import NovoQuadroHorarioPage from '../../pages/quadro-horarios/cadastrar';
import DiarioClassePage from '../../pages/diario-classe';
import DiarioClasseAdicionarPage from '../../pages/diario-classe/cadastrar';
import DiarioClasseAvaliacoesPage from '../../pages/diario-classe/avaliacoes';
import DiarioClasseAvaliacoesAlunosPage from '../../pages/diario-classe/avaliacoes/alunos';
import BanccFundamentalPage from '../../pages/bncc/fundamental';
import FaltasPage from '../../pages/faltas';
import FaltasAdicionarPage from '../../pages/faltas/cadastrar';
import Calendario from '../../pages/calendarios';
import VisualizacaoFichaAvaliacaoPage from '../../pages/ficha-avaliacao/visualizacao';
import HomeFichaAvaliacaoPage from '../../pages/ficha-avaliacao/homeFichaAvaliacao';
import ImpressaoFichaAvaliacao from '../../pages/ficha-avaliacao/impressao';
import FichaAvaliacaoVisualizacaoInfantil from '../../pages/ficha-avaliacao/infantil';
import FichaAvaliacaoVisualizacaoPrimeiroAno from '../../pages/ficha-avaliacao/primeiro-ano';
import FichaAvaliacaoVisualizacaoSegundoAno from '../../pages/ficha-avaliacao/segundo-ano';
import FichaAvaliacaoVisualizacaoTerceiroNonoAno from '../../pages/ficha-avaliacao/terceiro-nono-ano';
import FichaAvaliacaoImpressaoInfantil from '../../pages/ficha-avaliacao/infantil/impressao';
import FichaAvaliacaoImpressao1Ano from '../../pages/ficha-avaliacao/primeiro-ano/impressao';
import FichaAvaliacaoImpressaoSegundoAno from '../../pages/ficha-avaliacao/segundo-ano/impressao';
import FichaAvaliacaoImpressaoTerceiroAoNonoAno from '../../pages/ficha-avaliacao/terceiro-nono-ano/impressao';
import FamiliaConectadaAtendimento from '../../pages/familia-conectada/atendimento';
import FamiliaConectadaAtendimentoAdicionar from '../../pages/familia-conectada/atendimento/adicionar';
import FamiliaConectadaAvisosAdicionar from '../../pages/familia-conectada/avisos/adicionar';
import FamiliaConectadaAvisos from '../../pages/familia-conectada/avisos';
import RiapCreche from '../../pages/riap/creche';
import RiapInfantil from '../../pages/riap/infantil';
import RiapFundamental from '../../pages/riap/3ao9';
import RiapEja from '../../pages/riap/eja';
import AlunosAprovacaoEJAPage from '../../pages/alunos/aprovacaoEJA';
import FichaAvaliacaoImpressaoRiapTerceiroAoNonoAno from '../../pages/riap/3ao9/impressao';
import FichaAvaliacaoImpressaoRiapEja from '../../pages/riap/eja/impressao';
import WebCamPage from '../../pages/reconhecimento-facil/webcam';
import AlunosCarteirinhaPage from '../../pages/alunos/carteirinha';
import FichaAvaliacaoImpressaoPrimeiroAnoEja from '../../pages/ficha-avaliacao/eja-fase-1/impressao';
import FichaAvaliacaoImpressaoSegundoAnoEja from '../../pages/ficha-avaliacao/eja-fase-2/impressao';
import FichaAvaliacaoVisualizacaoSegundoAnoEja from '../../pages/ficha-avaliacao/eja-fase-2';
import FichaAvaliacaoVisualizacaoPrimeiroAnoEja from '../../pages/ficha-avaliacao/eja-fase-1';
import FichaAvaliacaoVisualizacaoTerceiroAnoEja from '../../pages/ficha-avaliacao/eja-fase-3-a-9';
import FichaAvaliacaoImpressaoTerceiroEja from '../../pages/ficha-avaliacao/eja-fase-3-a-9/impressao';
import AlunosCarteirinhaImpressaoPage from '../../pages/alunos/carteirinha/impressao';
import AdicionarFotoAluno from '../../pages/alunos/foto';
import AdicionarFotoServidor from '../../pages/servidores/foto';
import RelatorioAlunosTransporte from '../../pages/relatorios/alunos/alunosTransporte';
import LancamentosFrenquenciaServidores from '../../pages/frequenciaServidores/adicionar';
import FichaIndividualPage from '../../pages/ficha-individual/FichaIndividualPage';
import ControleImpressorasLancamento from '../../pages/administrativo/patrimonios/controleImpressoras/lancamento';
import ImpressorasPage from '../../pages/administrativo/patrimonios/controleImpressoras';
import ImpressorasChamadosPage from '../../pages/administrativo/patrimonios/chamadosImpressoras';
import AcompanhamentoChamadosPage from '../../pages/administrativo/patrimonios/chamadosImpressoras/acompanhamento';
import VisualizacaoFormularioRenovacao from '../../pages/alunos/renovacao';
import AtaResultadoFinalPage from '../../pages/ata-resultado-final/AtaResultadoFinalPage';
import BnccInfantilPage from '../../pages/bncc/infantil';
import ChatPage from '../../pages/chat';

const defaultPermisson = false;
const enable = process.env.REACT_APP_SECRETARIA_MUNICIPAL !== 'SEDUC' || process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'R3MAIS';
const enableAraruama = process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEDUC' || process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'R3MAIS';

const GestaoUnidadesRoutes = () => (
    <React.Fragment>
        {enable && (
            <>
            <Route path={urlsGestaoUnidades.fichaAvaliacao} element={<VisualizacaoFichaAvaliacaoPage />} />
            <Route path={urlsGestaoUnidades.fichaAvaliacaoLancamento} element={<HomeFichaAvaliacaoPage />} />
            </>
            )}


{enableAraruama && (
        <>
         <Route exact path={urlsGestaoUnidades.riapCreche} element={ <RiapCreche gestao={false} docente={false} />}/>
         <Route exact path={urlsGestaoUnidades.riapPre} element={ <RiapCreche gestao={false} docente={false} />}/>
            <Route exact path={urlsGestaoUnidades.riapInfantil} element={ <RiapInfantil gestao={false} docente={false} />}/>
            <Route exact path={urlsGestaoUnidades.riapFundamental} element={ <RiapFundamental gestao={false} docente={false} />}/>
            <Route exact path={urlsGestaoUnidades.riapEja} element={ <RiapEja gestao={false} docente={false} />}/>
            <Route exact path={urlsGestaoUnidades.alunosAprovacaoEja} element={ <AlunosAprovacaoEJAPage gestao={defaultPermisson} />}/>
            <Route exact path={urlsGestaoUnidades.riapFundamentalImpressao} element={ <FichaAvaliacaoImpressaoRiapTerceiroAoNonoAno gestao={defaultPermisson} />}/>
            <Route exact path={urlsGestaoUnidades.riapEjaImpressao} element={ <FichaAvaliacaoImpressaoRiapEja gestao={defaultPermisson} />}/>

          </>
        )}
        <Route exact path={urlsGestaoUnidades.dashboard} element={ <HomeGestaoUnidades />}/>
        <Route exact path={urlsGestaoUnidades.alunos} element={ <AlunosPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestaoUnidades.alunosAdicionar} element={ <AlunosAdicionarPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestaoUnidades.alunosEditarId} element={ <AlunosAdicionarPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestaoUnidades.alunosEvadir} element={ <AlunosEvadirPage gestao={defaultPermisson} />}/>

        
        <Route exact path={urlsGestaoUnidades.diarioClasse} element={ <DiarioClassePage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestaoUnidades.diarioClasseAdicionar} element={ <DiarioClasseAdicionarPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestaoUnidades.diarioClasseAdicionarId} element={ <DiarioClasseAdicionarPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestaoUnidades.diarioClasseAvaliacoes} element={ <DiarioClasseAvaliacoesPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestaoUnidades.diarioClasseAvaliacoesId} element={ <DiarioClasseAvaliacoesPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestaoUnidades.diarioClasseAvaliacoesAlunos} element={ <DiarioClasseAvaliacoesAlunosPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestaoUnidades.diarioClasseAvaliacoesAlunosId} element={ <DiarioClasseAvaliacoesAlunosPage gestao={defaultPermisson} />}/>

        <Route exact path={urlsGestaoUnidades.alunosAprovacao} element={ <AlunosAprovacaoPage gestao={defaultPermisson} />}/>



        <Route exact path={urlsGestaoUnidades.turmas} element={ <TurmasPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestaoUnidades.turmasAlunosId} element={ <TurmaAlunosPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestaoUnidades.turmasAdicionar} element={ <TurmasAdicionarPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestaoUnidades.turmasEditarId} element={ <TurmasAdicionarPage gestao={defaultPermisson} />}/>

        <Route exact path={urlsGestaoUnidades.servidores} element={ <ServidoresPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestaoUnidades.servidoresAdicionar} element={ <ServidoresAdicionarPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestaoUnidades.servidoresEditarId} element={ <ServidoresAdicionarPage gestao={defaultPermisson} />}/>

        <Route exact path={urlsGestaoUnidades.transferenciasEnviadas} element={ <TransferenciasEnviadasPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestaoUnidades.transferenciasRecebidas} element={ <TransferenciasRecebidasPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestaoUnidades.transferenciasRecebidasAprovacao} element={ <TransferenciasAprovacaoPage gestao={defaultPermisson} />}/>   
        <Route exact path={urlsGestaoUnidades.transferenciasRecebidasCancelamento} element={ <TransferenciasCancelamentoPage gestao={defaultPermisson} />}/>



        <Route exact path={urlsGestaoUnidades.quadroHorario} element={ <QuadroHorarioPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestaoUnidades.quadroHorarioEditarDisplinaId} element={ <QuadroDeHorarioDisciplinas gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestaoUnidades.quadroHorarioAlocarDisciplinaId} element={ <QuadroDeHorarioAlocaDisciplinas gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestaoUnidades.quadroHorarioImpressao} element={ <QuadroHorarioImpressao gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestaoUnidades.quadroHorarioCadastrarId} element={ <NovoQuadroHorarioPage gestao={defaultPermisson} />}/>
        <Route path={urlsGestaoUnidades.bnccFundamental} element={<BanccFundamentalPage gestao={defaultPermisson} docente={false} configuracao={false} />} /> 
        <Route path={urlsGestaoUnidades.bnccInfantil} element={<BnccInfantilPage modulo={"gestao-unidades"} />} /> 
        <Route path={urlsGestaoUnidades.calendario} element={<Calendario gestao={defaultPermisson} docente={false} configuracao={false} />} /> 

        <Route path={urlsGestaoUnidades.faltas} element={<FaltasPage gestao={false} docente={false} />} /> 
        <Route path={urlsGestaoUnidades.faltasAdicionar} element={<FaltasAdicionarPage gestao={false} docente={false} />} />

        <Route path={urlsGestaoUnidades.alunosFichaTransporteImpressao} element={<RelatorioAlunosTransporte gestao={false} docente={false} />} />


        <Route exact path={urlsGestaoUnidades.fichaAvaliacaoInfantil} element={ <FichaAvaliacaoVisualizacaoInfantil gestao={false} docente={false} />}/>
        <Route exact path={urlsGestaoUnidades.fichaAvaliacaoPrimeiroAno} element={ <FichaAvaliacaoVisualizacaoPrimeiroAno gestao={false} docente={false} />}/>
        <Route exact path={urlsGestaoUnidades.fichaAvaliacaoSegundoAno} element={ <FichaAvaliacaoVisualizacaoSegundoAno gestao={false} docente={false} />}/>
        <Route exact path={urlsGestaoUnidades.fichaAvaliacaoTerceiroNonoAno} element={ <FichaAvaliacaoVisualizacaoTerceiroNonoAno gestao={false} docente={false} />}/>
        <Route exact path={urlsGestaoUnidades.fichaAvaliacaoInfantilImpressao} element={ <FichaAvaliacaoImpressaoInfantil gestao={false} docente={false} />}/>
        <Route exact path={urlsGestaoUnidades.fichaAvaliacaoPrimeiroAnoImpressao} element={ <FichaAvaliacaoImpressao1Ano gestao={false} docente={false} />}/>
        <Route exact path={urlsGestaoUnidades.fichaAvaliacaoSegundoAnoImpressao} element={ <FichaAvaliacaoImpressaoSegundoAno gestao={false} docente={false} />}/>
        <Route exact path={urlsGestaoUnidades.fichaAvaliacaoTerceiroNonoAnoImpressao} element={ <FichaAvaliacaoImpressaoTerceiroAoNonoAno gestao={false} docente={false} />}/>
        <Route exact path={urlsGestaoUnidades.fichaAvaliacaoImpressao} element={ <ImpressaoFichaAvaliacao gestao={false} docente={false} />}/>
        <Route path={urlsGestaoUnidades.familiaConectadaAvisos} element={<FamiliaConectadaAvisos gestao={false} docente={false} />} />
        <Route path={urlsGestaoUnidades.familiaConectadaAvisosAdicionar} element={<FamiliaConectadaAvisosAdicionar gestao={false} docente={false} />} />
        <Route path={urlsGestaoUnidades.familiaConectadaAtendimento} element={<FamiliaConectadaAtendimento gestao={false} docente={false} />} />
        <Route path={urlsGestaoUnidades.familiaConectadaAtendimentoAdicionar} element={<FamiliaConectadaAtendimentoAdicionar gestao={false} docente={false} />} />
        <Route path={urlsGestaoUnidades.alunoCadastrarFoto} element={<AdicionarFotoAluno gestao={false} docente={false} />} />
        <Route path={urlsGestaoUnidades.servidorCadastrarFoto} element={<AdicionarFotoServidor gestao={false} docente={false} />} />
        <Route path={urlsGestaoUnidades.alunosCarteirinha} element={<AlunosCarteirinhaPage gestao={false} />} />
        <Route path={urlsGestaoUnidades.alunosCarteirinhaImpressao} element={<AlunosCarteirinhaImpressaoPage gestao={false} />} />
        <Route exact path={urlsGestaoUnidades.fichaAvaliacaoPrimeiroAnoEja} element={ <FichaAvaliacaoVisualizacaoPrimeiroAnoEja gestao={false}  />}/>
            <Route exact path={urlsGestaoUnidades.fichaAvaliacaoSegundoAnoEja} element={ <FichaAvaliacaoVisualizacaoSegundoAnoEja gestao={false}  />}/>
            <Route exact path={urlsGestaoUnidades.fichaAvaliacaoTerceiroAnoEja} element={ <FichaAvaliacaoVisualizacaoTerceiroAnoEja gestao={false}  />}/>
            <Route exact path={urlsGestaoUnidades.fichaAvaliacaoPrimeiroAnoEjaImpressao} element={ <FichaAvaliacaoImpressaoPrimeiroAnoEja gestao={false}  />}/>
            <Route exact path={urlsGestaoUnidades.fichaAvaliacaoSegundoAnoEjaImpressao} element={ <FichaAvaliacaoImpressaoSegundoAnoEja gestao={false}  />}/>
            <Route exact path={urlsGestaoUnidades.fichaAvaliacaoTerceiroAnoEjaImpressao} element={ <FichaAvaliacaoImpressaoTerceiroEja gestao={false}  />}/>
            <Route path={urlsGestaoUnidades.servidorFrenquenciaLancamento} element={<LancamentosFrenquenciaServidores gestao={false} />} />
            <Route path={urlsGestaoUnidades.alunoFichaIndividualId} element={<FichaIndividualPage gestao={true} docente={false} />} />
            <Route path={urlsGestaoUnidades.controleImpressorasLancamentoId} element={<ControleImpressorasLancamento gestao={false} />} />
            <Route path={urlsGestaoUnidades.controleImpressoras} element={<ImpressorasPage gestao={false} />} />
            <Route path={urlsGestaoUnidades.chamadosImpressoras} element={<ImpressorasChamadosPage gestao={false} />} />
            <Route path={urlsGestaoUnidades.chamadosAcompanhamentoImpressoras} element={<AcompanhamentoChamadosPage gestao={false} />} />
            <Route path={urlsGestaoUnidades.alunoRenovacaoVisualizacaoId} element={<VisualizacaoFormularioRenovacao />} />
            <Route path={urlsGestaoUnidades.turmasAtaResultadoFinalId} element={<AtaResultadoFinalPage gestao={true} docente={false} />} />
            <Route path={urlsGestaoUnidades.chat} element={<ChatPage gestao={true} docente={false} />} />
            
    </React.Fragment>
);

export default GestaoUnidadesRoutes;

import { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from "../../components/breadcrumb";
// import BaseGestaoUnidades from "../gestao-unidades/baseGestaoUnidades";
import BaseFichaAvaliacao from "./baseFichaAvaliacao";
import { useMutation, useQuery } from 'react-query';
import { quadroHorarioServidorEscolaridade, quadroHorarioServidorTurno, quadroHorarioServidorTurma, quadroHorarioAulas, quadroHorarioServidorUnidades } from '../../services/quadro-horario';
import { getAlunosFiltro } from "../../services/alunos";
import { getDisciplina, getFaltas } from '../../services/faltas';
import { postNotas } from "../../services/ficha-avaliacao";
import { getDisciplinasByTurmaServidor } from '../../services/disciplinas';
import { getBnccByDisciplinaAnoFaixa } from '../../services/bncc-fundamental';
// import urlsGestaoUnidades from "../../gestao-unidades/urlsGestaoUnidades";
import urlsFichaAvaliacao from "./urlsFichaAvaliacao";
import ErrorIcon from '@mui/icons-material/Error';
import { store } from '../../store/index';
import Select, { AriaOnFocus } from 'react-select';
import BaseGestaoUnidades from "../gestao-unidades/baseGestaoUnidades";
import { listarLancamentosTurma } from "../../services/ficha-avaliacao";
import BaseDocente from "../docente/baseDocente";

const HomeFichaAvaliacao = (gestao) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { state } = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [unidade, setUnidade] = useState(localStorage.getItem('unidadeID'));
    const [escolaridade, setEscolaridade] = useState(0);
    const [turno, setTurno] = useState(0);
    const [turma, setTurma] = useState(0);
    const [ciclo, setCiclo] = useState(0);
    const [disciplina, setDisciplina] = useState("");
    const [dataChamada, setDataChamada] = useState("");
    const [horarioAula, setHorarioAula] = useState("");
    const [aulasDadas, setAulasDadas] = useState("");
    const [bncc, setBncc] = useState("");
    const currentUser = store.getState()['user']['user'];
    const [unidades, setUnidades] = useState([]);
    const [escolaridades, setEscolaridades] = useState([]);
    const ciclos = [
      { value: 1, label: '1º TRIMESTRE' },
      { value: 2, label: '2 TRIMESTRE' },
      { value: 3, label: '3 TRIMESTRE' },
    ];
    const [disciplinas, setDisciplinas] = useState([]);
    const [turnos, setTurnos] = useState([]);
    const [turmas, setTurmas] = useState([]);
    const onMenuOpen = () => setIsOpen(true);
    const onMenuClose = () => setIsOpen(false);
    const [lancamentos, setLancamentos] = useState([])

    const notas = [];
    for (let i = 0.1; i <= 7; i += 0.1) {
        notas.push({ value: i.toFixed(1), label: i.toFixed(1) });
    }

//     const { refetch: refetch } = useQuery('listarLancamentosTurma', () => (turma !== null && disciplina != null ? listarLancamentosTurma(turma, disciplina) : null),
//     {
//         retry: 3,
//         enabled: turma != 0 && disciplina != 0,
//         onSuccess: (data) => {
//           if (data === undefined){
//             setLancamentos([]);
//             return;
//           }
//           setLancamentos(data.data[ciclo].linhas);
//           setAulasDadas(data.data[ciclo].aulasDadas)
//           console.log(data.data[ciclo].linhas)
          
//         },
//         onError: (error) => {
//           debugger
//           console.error('An error occurred:', error);
//           setLancamentos([]); // Set data to empty array in case of an error
//         },
//     }
// )
    

    let BaseComponent;
    let BasePath;
    let servidorId;

        if (!gestao.gestao && !gestao.docente) {
        BaseComponent = BaseGestaoUnidades;
        BasePath = 'Gestão Unidades'
        servidorId = 0

    }

    // Segunda condição para substituir BaseComponent por BaseDocente, se docente.docente for verdadeiro
    if (gestao.docente) {
        BaseComponent = BaseDocente;
        BasePath = 'Docente'
        servidorId = currentUser?.servidorId
    }

  //   if (gestao.fichaAvaliacao) {
  //     BaseComponent = BaseFichaAvaliacao;
  //     BasePath = 'Ficha Avaliação'
  // }

    const { mutate, isLoading } = useMutation(postNotas, {
        onSuccess: (message) => {
            toast.success(message);
            navigate(urlsFichaAvaliacao.faltas);
        },
        onError: (error) => {
            if (error.response) {
                const { message } = error.response.data;
                toast.error(message);
            } else if (error.request) {
                toast.error('Erro ao tentar cadastrar uma nacionalidade, tente novamente mais tarde.');
            } else {
                toast.error(error.message);
            }
        }
    });

    // const { isLoading: loadingUnidades, refetch: refetchUnidades } = useQuery('getUnidades', () => quadroHorarioServidorUnidades(servidorId), {
    //   enabled: true,
    //   retry: true,
    //   onSuccess: (data) => {
    //     var dados = [];
    //     data.forEach(item => {
    //       if (!dados.some(d => d.value === item.id)) {
    //         dados.push({ value: item.id, label: item.nome });
    //       }
    //     });
        
  
    //     setUnidades(dados);
    //   }
    // })
  

    const { refetch: refetchEscolaridades } = useQuery('getEscolaridades', () => (unidade !== null ? quadroHorarioServidorEscolaridade(servidorId, unidade) : null),
        {
            retry: 3,
            enabled: unidade !== null,
            onSuccess: (data) => {
              var dados = [];
              data.forEach(item => {
                if (!dados.some(d => d.value === item.id)) {
                  dados.push({ value: item.id, label: item.descricao });
                }
              });
              
        
              setEscolaridades(dados);
            }
        }
    )

    const { isLoading: loadingTurmas, refetch: refetchTurmas } = useQuery('getTurmasUnidadeEscolaridadeTurno', () =>
        unidade !== '' && escolaridade !== '' && turno !== '' ? quadroHorarioServidorTurma(servidorId, unidade, escolaridade, turno) : null,
        {
            retry: 3,
            enabled: unidade !== '' && escolaridade !== '' && turno !== '',
            onSuccess: (data) => {
              var dados = [];
              data.forEach(item => {
                if (!dados.some(d => d.value === item.id)) {
                  dados.push({ value: item.id, label: item.descricao });
                }
              });
              
        
              setTurmas(dados);
            }
        }
    )

    const { isLoading: loadingTurnos, refetch: refetchTurnos } = useQuery('getTurnos', () => quadroHorarioServidorTurno(servidorId, unidade, escolaridade),
        {
            enabled: unidade !== 0 && escolaridade !== 0,
            retry: 3,
            onSuccess: (data) => {
              var dados = [{value: 0, label: ''}];
              data.forEach(item => {
                if (!dados.some(d => d.value === item.id)) {
                  dados.push({ value: item.id, label: item.descricao });
                }
              });
              
        
              setTurnos(dados);
            }
        }
    )

    const { data: alunos, isLoading: loadingAlunos, refetch: refetchAlunos } = useQuery('getAlunosByUnidadeEscolaridadeTurnoTurma', () =>
        unidade !== 0 && escolaridade !== 0 && turno !== 0 && turma !== 0 ? getAlunosFiltro(unidade, escolaridade, turno, turma, 1, 0, '', '', '', 0, 100, 2024) : null,
        {
            retry: 3,
            enabled: unidade !== 0 && escolaridade !== 0 && turno !== 0 && turma !== 0 && ciclo !== 0
        }
    )

    const { isLoading: loadingDisciplinas, refetch: refetchDisciplina } = useQuery('getDisciplinasByTurmaServidor', () =>
        turma !== 0 ? getDisciplinasByTurmaServidor(turma, servidorId) : null,
        {
            retry: 3,
            enabled: turma !== 0,
            onSuccess: (data) => {
              var dados = [];
              data.forEach(item => {
                if (!dados.some(d => d.value === item.id)) {
                  dados.push({ value: item.id, label: item.descricao });
                }
              });
              
        
              setDisciplinas(dados);
            }
        }
    )

    // const { data: horarioAulas, isLoading: loadingHorarioAulas, refetch: refetchHorarioAulas } = useQuery('quadroHorarioAulas', () =>
    //     turma !== 0 && disciplina !== "" ? quadroHorarioAulas(turma, servidorId, disciplina) : null,
    //     {
    //         retry: 3
    //     }
    // )

    // const { data: bnccs, isLoading: loadingBncc, refetch: refetchBncc } = useQuery('getBnccByDisciplinaAnoFaixa', () =>
    //     turno !== 0 && disciplina !== "" ?
    //         getBnccByDisciplinaAnoFaixa(document.getElementById('disciplina').options[document.getElementById('disciplina').selectedIndex].text,
    //             document.getElementById('idEscolaridade').options[document.getElementById('idEscolaridade').selectedIndex].text.substring(0, 1)) : null,
    //     {
    //         onSuccess: (data) => {
    //             if (data != null) {
    //                 var dados = [];
    //                 data.data.forEach(item => {
    //                     dados.push({ value: item.id, label: item.habilidades })
    //                 });
    //                 setListaBncc(dados);
    //             }
    //         },
    //         retry: 3
    //     }
    // )

    // const { data: faltas, isLoading: loading, refetch } = useQuery('getFaltas', () => getFaltas(turno, escolaridade, turma, unidade, 0, disciplina, 0, 9999),
    //     {
    //         retry: 2
    //     }
    // )


    const salvaLancamentoNota = async () => {
      
    }

    const SalvarLancamentoFaltas = async () => {

        if (disciplina == "") {
            toast.error('SELECIONE A DISCIPLINA');
            return;
        }
       
        if (aulasDadas == "") {
            toast.error('INFORME A QUANTIDADE DE AULAS DADAS');
            return;
        }
       
        var alunosPost = [];
        for (let i = 0; i < alunos.data.length; i++) {
            const aluno = alunos.data[i];
            const aeValue =  // Retrieve the value from the Select component with the ID starting with 'ae'
            alunosPost.push({
                alunoId: aluno.id,
                ae: parseFloat(document.getElementsByName("ae" + aluno.id)[0].value) || 0,
                ad: parseFloat(document.getElementsByName("ad" + aluno.id)[0].value) || 0,
                recuperacao: parseFloat(document.getElementsByName("recuperacao" + aluno.id)[0].value) || 0,
                faltas: parseInt(document.getElementById("faltas" + aluno.id).value) || 0,
            });
        }

        var model = {
            turmaId: turma,
            disciplinaId: disciplina,
            trimestreId: ciclo,
            quantidadeAulasDadas: parseInt(aulasDadas),
            alunos: alunosPost
        }

        var response = await postNotas(model);
        if (response.statusCode == 200) {
            toast.success("Lançamento de falta realizado com sucesso!");
            // navigate(-1);
        }
        else
            toast.error("Houve um problema ao realizar o Lancamento de falta!");
    }

    // useEffect(() => {
    //     faltas?.data?.map((row) => {
    //         document.getElementById("falta" + row.idAluno).value = row.qtdFaltas;
    //     });
    // }, [disciplina]);

    // useEffect(() => {
    //     alunos?.data.map((row) => {
    //         // document.getElementById("chk" + row.id).checked = true;
    //     });
    // }, [alunos]);

    return (
        <BaseComponent>
            <Breadcrumb title={'Formulário Faltas'} itens={[BasePath, 'Faltas', 'Formulário']} />
            <form id='form'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className="card-body">
                                <>
                                    <div className='row'>
                                   

                                    <div className="col-12 col-md-4">
  <div className="form-group">
    <label htmlFor="escolaridade" className="form-label">
      Escolaridade
    </label>
    <Select
      aria-labelledby="aria-label"
      inputId="aria-example-input"
      name="aria-live-color"
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      options={escolaridades}
      // isDisabled={gestao.gestao ? false : true}
      // value={unidades.find(x => x.value == unidade)}
      placeholder=''
      onChange={async e => {
        await setEscolaridade(e.value)
        await setTurno(0) // Reset the turno value
        await setTurma(0) // Reset the turma value
        await refetchTurnos(); // Refetch the list of turnos based on the new escolaridade
        // refetchBncc();
      }}
    />
  </div>
</div>

<div className="col-12 col-md-4">
  <div className="form-group">
    <label htmlFor="turno" className="form-label">
      Turno
    </label>
    <Select
      aria-labelledby="aria-label"
      inputId="aria-example-input"
      name="aria-live-color"
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      options={turnos}
      // isDisabled={gestao.gestao ? false : true}
      // value={turno}
      placeholder=''
      onChange={async e => {
        await setTurno(e.value)
        await setTurma(0)
        refetchTurmas(); // Refetch the list of turmas based on the new turno
      }}
    />
  </div>
</div>


        <div className="col-12 col-md-4">
          <div className="form-group">
            <label htmlFor="turma" className="form-label">
              Turmas
            </label>
            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={turmas}
              // isDisabled={gestao.gestao ? false : true}
              // value={unidades.find(x => x.value == unidade)}
              placeholder=''             
              onChange={async e => {
                await setTurma(e.value);
                refetchAlunos();
                refetchDisciplina();
              }
              }
            />
          </div>
        </div>

        <div className='row' style={{marginTop: 20}}>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label htmlFor="trimestre" className="form-label">
                Trimestre
              </label>
              <Select
                aria-labelledby="aria-label"
                inputId="aria-example-input"
                name="aria-live-color"
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                options={escolaridade === 2 ? ciclos.filter(ciclo => ciclo.id !== 1) :  ciclos}
                // isDisabled={gestao.gestao ? false : true}
                // value={unidades.find(x => x.value == unidade)}
                placeholder=''             
                onChange={async e => {
                  await setCiclo(e.value);
                }
                }
              />
            </div>
          </div>
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="turma" className="form-label">
              Componente Curricular
            </label>
            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={disciplinas}
              // isDisabled={gestao.gestao ? false : true}
              // value={unidades.find(x => x.value == unidade)}
              placeholder=''             
              onChange={async e => {
                await setDisciplina(e.value);
                                                       
              }
              }
            />
          </div>
        </div>
        </div>

                                        

                                       

                                        
                                        
                                    </div>

                                    {disciplina > 0 && (
                                    <div className='row' style={{ marginTop: 20 }}>
                                        <div className='col-12'>
                                            {alunos != null && alunos?.data.length > 0 ?
                                                <>
                                                    <div className='row' style={{ marginBottom: '20px' }}>
                                                        
                                                        
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-hover table-responsive">
                                                            <thead>
                                                                <tr className="text-center">
                                                                    <th style={{ display: 'none' }} className="text-center">ID</th>
                                                                    <th className="text-center">MATRÍCULA</th>
                                                                    <th className="text-center">NOME</th>
                                                                   {[1, 10, 11, 12, 13].includes(escolaridade) ? null : (
  <>
    <th className="text-center">AE</th>
    <th className="text-center">AD</th>
    <th className="text-center">RECUPERAÇÃO</th>
    </>
)}
                                                                    <th className="text-center">FALTAS</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    alunos?.data.map((row) => {
                                                                      const student = lancamentos.find((lanc) => lanc.numeroMatricula === '02013452');
                                                                      
                                                                        return (<tr className="text-center">
                                                                            <td style={{ display: 'none' }}>{row.id}</td>
                                                                            <td>{row.numeroMatricula}</td>
                                                                            <td>{row.nome}</td>
                                                                            {[1, 10, 11, 12, 13].includes(escolaridade) ? null : (
  <>
    <td>
    <Select
              aria-labelledby="aria-label"
              // inputId={'ae' + row.id}
              name={'ae' + row.id}
              // name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={notas}
              value={notas.find(
                (option) =>
                  option.value ===
                  (lancamentos.find((lanc) => lanc.numeroMatricula === row.numeroMatricula)?.ae || '')
              )}
              placeholder=''
            />
    </td>
    <td>
    <Select
              aria-labelledby="aria-label"
              // inputId={'ae' + row.id}
              name={'ad' + row.id}
              // name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={notas.filter((option) => parseFloat(option.value) <= 3)}
              value={notas.find((option) => option.value === row.id)}     
              placeholder=''
            />
    </td>
    <td>
    <Select
              aria-labelledby="aria-label"
              // inputId={'ae' + row.id}
              name={'recuperacao' + row.id}
              // name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={notas}
              value={notas.find((option) => option.value === row.id)}
              placeholder=''    
            />
    </td>
  </>
)}

                                                                            <td>
                                                                                <input className="form-control" type='number' id={'faltas' + row.id}/>
                                                                            </td>
                                                                        </tr>
                                                                        )
                                                                    })
                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                                : alunos != null && alunos?.data.length == 0 ?
                                                    <p className="text-center">Nenhum registro encontrado</p> : <p className="text-center">Selecione os filtros</p>}

                                        </div>
                                    </div>
                                    )}
                                </>
                                {
                                    alunos != null && alunos?.data.length && disciplina > 0 ?
                                        <div className='row' style={{ marginTop: 30 }}>
                                           

                                           <div className='col-md-12' style={{ marginBottom: 10, marginTop: 10 }}>
  <div className="form-group">
    <label htmlFor="aulasDadas">QUANTIDADE AULAS DADAS</label>
    <input
      type="number"
      className="form-control"
      id="aulasDadas"
      value={aulasDadas}
      onChange={(e) => setAulasDadas(e.target.value)}
      min={0}
      max={7}
    />
  </div>
</div>

                                        </div>
                                        :
                                        <>
                                        </>
                                }


                            </div>
                            {disciplina > 0 && (
  <div className='card-footer text-end'>
    <button
      style={{ marginRight: 20 }}
      type="button"
      className="btn btn-warning"
      onClick={() => navigate(-1)}
    >
      VOLTAR
    </button>
    <button
      disabled={alunos?.data.length > 0 ? false : true}
      style={{ marginRight: 20 }}
      type="button"
      className="btn btn-primary"
      onClick={async () => await SalvarLancamentoFaltas()}
    >
      SALVAR
    </button>
  </div>
)}

                        </div>
                    </div>
                </div>
            </form>
        </BaseComponent>
    )

};

export default HomeFichaAvaliacao;
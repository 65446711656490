import React from "react";
import BaseFormularioServidores from "../BaseFormularioServidores";
import { Chart as ChartJS, ArcElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';

const ServidoresDashboardPage = () => {
  return (
    <BaseFormularioServidores>
    <h3>Dashboard</h3>
      <></>
    </BaseFormularioServidores>
  );
};


export default ServidoresDashboardPage;

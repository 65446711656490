import { useMemo, useState } from 'react';
import Breadcrumb from '../../../../components/breadcrumb';
import Table from '../../../../components/table';
import BaseAdministrativo from '../../baseAdministrativo';
import urlsAdministrativo from '../../urlsAdministrativo';
import { useQuery, useMutation } from 'react-query';
import { getProdutos, deleteProduto } from '../../../../services/administrativo/patrimonio-produto'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


const ControleProdutosTable = () => {
  const navigate = useNavigate();
  const [newData, setNewData] = useState()

  const { data, refetch: refetchProdutos } = useQuery('getProdutos', () => getProdutos(), {
    enabled: true,
    onSuccess: data => {
      setNewData(data.data)
    }
  })

  const { mutate } = useMutation(id => deleteProduto(id), {
    onSuccess: data => {
      toast.success('Produto excluído com sucesso.');
      refetchProdutos();
    },
    onError: data => {
      toast.error('Erro ao excluir produto. Tente novamente mais tarde.')
    }
  })

  let BRLFormat = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const formatValue = (value) => {
    if (value) {
      const newValue = `${BRLFormat.format(value)}`;
      return newValue;
    }
  }

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Produto', accessor: 'nome' },
      { Header: 'Descrição', accessor: 'descricao' },
      { Header: 'Fabricante', accessor: 'fabricante' },
      {
        Header: 'Valor', accessor: 'valor',
        Cell: ({ row }) => (
          formatValue(row.original.valor)
        )
      },
      { Header: 'Quantidade', accessor: 'quantidade' },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <button onClick={() => navigate(urlsAdministrativo.patrimonioControleProdutosCadastrar + row.original.id, {
              state: {
                data: row.original,
              }
            })} className='btn btn-sm btn-primary'>
              <i className="material-icons md-edit_note"></i> visualizar
            </button>
            <button
              className='btn btn-sm btn-danger'
              style={{ marginLeft: '8px' }}
              onClick={() => {
                const confirm = window.confirm('Tem certeza que deseja excluir?')
                if (confirm) {
                  mutate(row.original.id);
                }
              }}
            >
              <i className="material-icons md-group_remove"></i> excluir
            </button>
          </>
        )
      },
    ],
    [newData]
  )

  return (
    <BaseAdministrativo>
      <Breadcrumb title={'Controle de Produtos'} itens={['Administrativo', 'Patrimônio', 'Controle de produtos']} actions={{ link: urlsAdministrativo.patrimonioControleProdutosCadastrar, text: "Novo Produto" }} />
      <div className='row'>
        <div className="mailbox-container">
          <div className="card">
            <div className="card-body">
              <Table columns={columns} data={newData ? newData : []} />
            </div>
          </div>
        </div>
      </div>
    </BaseAdministrativo >
  )
};
export default ControleProdutosTable;

import React from "react";

class HeaderImpressao extends React.Component {
  render() {
    const { data } = this.props;

    let aulasDadas = [];
    let totalAulas = 0;
    

if (data !== undefined) {
  data.forEach(trimestre => {
    let { aulasDadasR1, aulasDadasR2, aulasDadasTracos, aulasDadasCorpo } = trimestre;
    aulasDadas.push({ aulasDadasR1, aulasDadasR2, aulasDadasTracos, aulasDadasCorpo });
    totalAulas += aulasDadasR1 + aulasDadasR2 + aulasDadasTracos + aulasDadasCorpo;  
  });
}

let totalDiaLetivos = totalAulas % 1 === 0 ? (totalAulas / 5).toFixed(0) : (totalAulas / 5).toFixed(2);


    return (
      <table border="1" className="striped-table">
        <thead>
          <tr>
            <th colSpan="5">1º TRIMESTRE</th>
            <th colSpan="5">2º TRIMESTRE</th>
            <th colSpan="5">3º TRIMESTRE</th>
            <th rowSpan="1" colSpan={2}>TOTAL ANUAL</th>
          </tr>
          {/* <tr>
            <th colSpan="5">HORAS-AULAS</th>
            <th colSpan="5">HORAS-AULAS</th>
            <th colSpan="5">HORAS-AULAS</th>
          </tr> */}
          <tr>
            <th>R1</th>
            <th>R2</th>
            <th>Traços</th>
            <th>Corpo</th>
            <th>Total</th>
            <th>R1</th>
            <th>R2</th>
            <th>Traços</th>
            <th>Corpo</th>
            <th>Total</th>
            <th>R1</th>
            <th>R2</th>
            <th>Traços</th>
            <th>Corpo</th>
            <th>Total</th>
            <th>HA</th>
            <th>DL</th>
          </tr>
        </thead>
        <tbody>
  <tr>
    {/* Render columns for 1st trimester */}
    <td>{aulasDadas[0].aulasDadasR1 !== -1 ? aulasDadas[0].aulasDadasR1 : ''}</td>
    <td>{aulasDadas[0].aulasDadasR2 !== -1 ? aulasDadas[0].aulasDadasR2 : ''}</td>
    <td>{aulasDadas[0].aulasDadasTracos !== -1 ? aulasDadas[0].aulasDadasTracos : ''}</td>
    <td>{aulasDadas[0].aulasDadasCorpo !== -1 ? aulasDadas[0].aulasDadasCorpo : ''}</td>
    <td>{aulasDadas[0].aulasDadasR1 !== -1 && aulasDadas[0].aulasDadasR2 !== -1 && aulasDadas[0].aulasDadasTracos !== -1 && aulasDadas[0].aulasDadasCorpo !== -1 ? aulasDadas[0].aulasDadasR1 + aulasDadas[0].aulasDadasR2 + aulasDadas[0].aulasDadasTracos + aulasDadas[0].aulasDadasCorpo : ''}</td>

    {/* Render columns for 2nd trimester */}
    <td>{aulasDadas[1].aulasDadasR1 !== -1 ? aulasDadas[1].aulasDadasR1 : ''}</td>
    <td>{aulasDadas[1].aulasDadasR2 !== -1 ? aulasDadas[1].aulasDadasR2 : ''}</td>
    <td>{aulasDadas[1].aulasDadasTracos !== -1 ? aulasDadas[1].aulasDadasTracos : ''}</td>
    <td>{aulasDadas[1].aulasDadasCorpo !== -1 ? aulasDadas[1].aulasDadasCorpo : ''}</td>
    <td>{aulasDadas[1].aulasDadasR1 !== -1 && aulasDadas[1].aulasDadasR2 !== -1 && aulasDadas[1].aulasDadasTracos !== -1 && aulasDadas[1].aulasDadasCorpo !== -1 ? aulasDadas[1].aulasDadasR1 + aulasDadas[1].aulasDadasR2 + aulasDadas[1].aulasDadasTracos + aulasDadas[1].aulasDadasCorpo : ''}</td>

    {/* Render columns for 3rd trimester */}
    <td>{aulasDadas[2].aulasDadasR1 !== -1 ? aulasDadas[2].aulasDadasR1 : ''}</td>
    <td>{aulasDadas[2].aulasDadasR2 !== -1 ? aulasDadas[2].aulasDadasR2 : ''}</td>
    <td>{aulasDadas[2].aulasDadasTracos !== -1 ? aulasDadas[2].aulasDadasTracos : ''}</td>
    <td>{aulasDadas[2].aulasDadasCorpo !== -1 ? aulasDadas[2].aulasDadasCorpo : ''}</td>
    <td>{aulasDadas[2].aulasDadasR1 !== -1 && aulasDadas[2].aulasDadasR2 !== -1 && aulasDadas[2].aulasDadasTracos !== -1 && aulasDadas[2].aulasDadasCorpo !== -1 ? aulasDadas[2].aulasDadasR1 + aulasDadas[2].aulasDadasR2 + aulasDadas[2].aulasDadasTracos + aulasDadas[2].aulasDadasCorpo : ''}</td>
      <td>{totalAulas}</td>
      <td>{totalDiaLetivos}</td>
    </tr>
</tbody>


      </table>
    );
  }
}

export default HeaderImpressao;

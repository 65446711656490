import api from "..";

async function getNutricaoGrupoProdutos() {
  return await api
    .get('administrativo/nutricao/grupoproduto')
    .then(async (res) => {
      const data = res.data.data;
      return data;
    })
}

async function deleteNutricaoGrupoProdutos(id) {
  return await api
    .delete(`administrativo/nutricao/grupoproduto/${id}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function criarNutricaoGrupoProdutos(data) {
  return await api
    .post('administrativo/nutricao/grupoproduto', data)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
};

async function atualizaNutricaoGrupoProdutos(data) {
  return await api
    .put(`administrativo/nutricao/grupoproduto/${data.id}`, data)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function getNutricaoProdutos(skip = 0, limit = 20) {
  return await api
    .get(`administrativo/nutricao/produto?skip=${skip}&limit=${limit}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function deleteNutricaoProduto(id) {
  return await api
    .delete(`administrativo/nutricao/produto/${id}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function getNutricaoGrupoProdutosCombo() {
  return await api
    .get('administrativo/nutricao/grupoprodutocombo')
    .then(async (res) => {
      const data = res.data.data;
      return data;
    })
}

async function getNutricaoProduto(skip = 0, limit = 20) {
  return await api
    .get(`administrativo/nutricao/produto?skip=${skip}&limit=${limit}`)
    .then(async (res) => {
      const data = res.data.data;
      return data;
    })
}

async function getNutricaoUnidadeMedidaCombo() {
  return await api
    .get('administrativo/nutricao/unidademedidacombo')
    .then(async (res) => {
      const data = res.data.data;
      return data;
    })
}

async function putNutricaoProduto(data) {
  return await api
    .put(`administrativo/nutricao/produto/${data.id}`, data.data)
    .then(async (res) => {
      const data = res.data.data;
      return data;
    })
}

async function postNutricaoProduto(data) {
  return await api
    .post('administrativo/nutricao/produto', data)
    .then(async (res) => {
      const data = res.data.data;
      return data;
    })
}
async function getNutricaoProdutosById(id) {
  return await api
    .get('administrativo/nutricao/produtobyid/' + id)
    .then(async (res) => {
      const data = res.data.data;
      return data;
    })
}

async function getMovimentacoes(skip = 0, limit = 20) {
  return await api
    .get(`administrativo/nutricao/movimentacoes?skip=${skip}&limit=${limit}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
};

async function postMovimentacao(data) {
  return await api
    .post(`administrativo/nutricao/movimentacoes`, data)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
};

async function getCardapios(skip = 0, limit = 20) {
  return await api
    .get(`administrativo/nutricao/cardapio?skip=${skip}&limit=${limit}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
};

async function addCardapio(data) {
  return await api
    .post(`administrativo/nutricao/cardapio`, data)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
};

async function getCardapioImprimir(id = 0) {
  return await api
    .get(`administrativo/nutricao/cardapioimprimir/${id}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
};




export {
  getNutricaoGrupoProdutos,
  deleteNutricaoGrupoProdutos,
  criarNutricaoGrupoProdutos,
  atualizaNutricaoGrupoProdutos,
  getNutricaoProdutos,
  deleteNutricaoProduto,
  getNutricaoGrupoProdutosCombo,
  getNutricaoProduto,
  getNutricaoUnidadeMedidaCombo,
  putNutricaoProduto,
  postNutricaoProduto,
  getNutricaoProdutosById,
  getMovimentacoes,
  postMovimentacao,
  getCardapios,
  addCardapio,
  getCardapioImprimir
}
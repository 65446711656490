import Breadcrumb from '../../components/breadcrumb';
import BaseReconhecimentoFacial from './baseReconhecimentoFacial';
import Spinner from '../../components/spinner';
import { useEffect, useState } from "react";
import api from '../../services'

const HomeReconhecimentoFacial = () => {
  const unidadeID = localStorage.getItem('unidadeID');
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      const response = await api.get(`/estatistica/reconhecimentoFacial/${unidadeID}`);
      setData(response.data.data)
      setIsLoading(false)
    }
    fetchData()
  }, [])

  return (
    <BaseReconhecimentoFacial>
      <Breadcrumb title={'Informações Gerais'} itens={['Reconhecimento Facial', 'Dashboard']} />
      {
        isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="row">
              <div className="col-xl-3">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-gray">
                        <i className="material-icons-outline md-groups"></i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                      <span className="widget-stats-title">Alunos</span>
                        <span className="widget-stats-amount">{data?.totalAlunos}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-gray">
                        <i className="material-icons-outline md-groups"></i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                      <span className="widget-stats-title">Servidores</span>
                        <span className="widget-stats-amount">{data?.totalServidores}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-gray">
                        <i className="material-icons-outline md-groups"></i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                      <span className="widget-stats-title">Aluno Com Foto</span>
                        <span className="widget-stats-amount">{data?.totalAlunosComImagem}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-gray">
                        <i className="material-icons-outline md-groups"></i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                      <span className="widget-stats-title">Servidores Com Foto</span>
                        <span className="widget-stats-amount">{data?.totalServidoresComImagem}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </>
        )}
    </BaseReconhecimentoFacial>
  )
};

export default HomeReconhecimentoFacial;
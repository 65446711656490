import React, { useState } from 'react';
import { Box, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import HomeIcon from '@mui/icons-material/Home';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';

const Erro500 = () => {
  const navigate = useNavigate();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [errorDescription, setErrorDescription] = useState('');

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSendError = () => {
    // Aqui, enviaríamos o erro e a descrição do usuário para um endpoint de suporte
    console.log("Descrição do erro enviada:", errorDescription);
    handleCloseDialog();
    setErrorDescription(''); // Limpar o campo de descrição após o envio
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      textAlign="center"
      sx={{
        bgcolor: '#f9fafb',
        padding: 2,
      }}
    >
      <ReportProblemIcon sx={{ fontSize: 80, color: 'error.main', mb: 2 }} />
      <Typography variant="h3" fontWeight="bold" gutterBottom>
        500 - Erro Interno do Servidor
      </Typography>
      <Typography variant="body1" color="textSecondary" mb={3}>
        Oops! Algo deu errado. Por favor, tente novamente mais tarde.
      </Typography>
      <Box display="flex" gap={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<HomeIcon />}
          onClick={() => navigate('/')}
          sx={{ textTransform: 'none', fontWeight: 'bold' }}
        >
          Ir para a Página Inicial
        </Button>
        <Button
          variant="outlined"
          color="error"
          startIcon={<ErrorOutlineIcon />}
          onClick={handleOpenDialog}
          sx={{ textTransform: 'none', fontWeight: 'bold' }}
        >
          Reportar Erro
        </Button>
      </Box>

      {/* Diálogo para descrição do erro */}
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Reportar Problema</DialogTitle>
        <DialogContent>
          <Typography variant="body2" mb={2}>
            Descreva o que estava fazendo quando o erro aconteceu para nos ajudar a resolver o problema.
          </Typography>
          <TextField
            label="Descrição do problema"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={errorDescription}
            onChange={(e) => setErrorDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleSendError} color="primary" variant="contained" disabled={!errorDescription}>
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Erro500;

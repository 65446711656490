import React, { useRef, useState } from "react";
import "./alunosTransporte.css";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { getAluno } from "../../../services/alunos";
import BaseGestao from "../../gestao/baseGestao";
import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import Breadcrumb from "../../../components/breadcrumb";
import { useReactToPrint } from "react-to-print";
import { store } from "../../../store";
import moment from "moment";

const RelatorioAlunosTransporte = (gestao) => {
  const [aluno, setAluno] = useState({});
  const printRef = useRef(null);
  const { state } = useLocation();
  const BaseComponent = gestao.gestao ? BaseGestao : BaseGestaoUnidades;
  const BasePath = gestao.gestao ? "Gestão" : "Gestão Unidades";
  const currentUser = store.getState()['user']['user']

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "FICHA_TRANSPORTE_ALUNO",
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const { data: alunoData, loadingAluno } = useQuery(
    "getAluno",
    () => state.id !== undefined && getAluno(state.id),
    {
      retry: false,
      enabled: true,
      onSuccess: (response) => {
        setAluno({
          ...response,
          nome: state.aluno.nome,
          numeroCarteiraNascimento: state.aluno.numeroCarteiraNascimento,
          cpf: state.aluno.cpf,
          rg: state.aluno.rg,
          endereco: state.aluno.endereco,
          telefone: state.aluno.telefone,
          unidade: state.aluno.unidade,
          escolaridade: state.aluno.escolaridade,
          turno: state.aluno.turno,
          turma: state.aluno.turma,
          fotoPerfil: state.aluno.fotoPerfil,
          transporte: {
            desembarqueNome: state.aluno.transporte?.desembarqueNome,
            desembarqueParentesco:
              state.aluno.transporte?.desembarqueParentesco,
            desembarqueNome2: state.aluno.transporte?.desembarqueNome2,
            desembarqueParentesco2:
              state.aluno.transporte?.desembarqueParentesco2,
            emergenciaNome: state.aluno.transporte?.emergenciaNome,
            emergenciaTelefone: state.aluno.transporte?.emergenciaTelefone,
            emergenciaParentesco: state.aluno.transporte?.emergenciaParentesco,
            possuiAlergiaMedicamentos:
              state.aluno.transporte?.possuiAlergiaMedicamentos,
            medicamentos: state.aluno.transporte?.medicamentos,
            tipoSanguineo: state.aluno.transporte?.tipoSanguineo,
          },
        });
      },
    }
  );

  return (
    <BaseComponent>
      <Breadcrumb
        title={"Ficha Transporte"}
        itens={[BasePath, "Alunos", "Documentos"]}
        // handlePrint={handlePrint}
        onClickImprimir={handlePrint}
        imprimir={{ link: 'fda', text: "Imprimir" }}
      />
    <div className="report-container" id="ficha-transporte-aluno" ref={printRef}>
      <div className="d-flex justify-content-center">
        <div className="logo-prefeitura">
          <img
            src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`}
            width="80"
            height="80"
            alt=""
          ></img>
        </div>
        <div className="text-center">
          <h5>ESTADO DO RIO DE JANEIRO</h5>
          <h5>{process.env.REACT_APP_PREFEITURA}</h5>
          <h5>SECRETARIA MUNICIPAL DE EDUCAÇÃO</h5>
          <h5>{aluno?.unidade}</h5>
        </div>
      </div>
      <table className="info-table">
        <tbody>
          <tr>
            <td rowSpan="4" className="photo-cell">
              <div className="photo-placeholder">
                {alunoData?.fotoPerfil ? (
                  <img
                    src={alunoData?.fotoPerfil}
                    alt="Foto do estudante"
                    style={{
                      width: "120px",
                      height: "100px", // Fixed height to ensure the image is square
                      borderRadius: "12px",
                      objectFit: "cover", // Ensures the image covers the entire element while maintaining aspect ratio
                      objectPosition: "center", // Centers the image within the element
                    }}
                  />
                ) : (
                  <img
                    src="/assets/images/avatars/avatar-male.svg"
                    alt="Foto do estudante"
                    style={{
                      width: "100px",
                      height: "auto",
                      borderRadius: "8px",
                    }}
                  />
                )}
              </div>
            </td>
            <td colSpan="6" className="fw-bold fs-4">
              {alunoData?.nome}
            </td>
          </tr>
          <tr>
            <td>
              <strong>ESCOLARIDADE:</strong> {aluno?.escolaridade}
            </td>
            <td>
              <strong>TURMA:</strong> {aluno?.turma}
            </td>
            <td>
              <strong>TURNO:</strong> {aluno?.turno}
            </td>
            <td>
              <strong>MATRÍCULA:</strong> {aluno?.numeroMatricula}
            </td>
            <td>
              <strong>NASCIMENTO:</strong>{" "}
              {formatDate(alunoData?.numeroCarteiraNascimento)}
            </td>
            <td>
              <strong>ANO LETIVO:</strong> 2024
            </td>
          </tr>
          <tr>
            <td className="text-start" colSpan="3">
              <strong>FILIAÇÃO 01: </strong>
              {alunoData?.filiacao1}
            </td>
            <td className="text-start" colSpan="3">
              <strong>FILIAÇÃO 02: </strong>
              {alunoData?.filiacao2}
            </td>
          </tr>
          <tr>
            <td className="text-start" colSpan="3">
              <strong>ROTA:</strong>
            </td>
            <td className="text-start" colSpan="3">
              <strong>PONTO DE REFERÊNCIA:</strong>
            </td>
          </tr>
        </tbody>
      </table>

      <table className="mt-2 info-table">
        <colgroup>
          <col width="8%" />
          <col width="10%" />
          <col width="20%" />
        </colgroup>
        <tbody>
          <tr>
            <td colSpan={10} className="fw-bold fs-5">
              PESSOA A SER ACIONADA EM CASO DE EMERGÊNCIA:
            </td>
          </tr>
          <tr>
            <td className="text-start" colSpan={6}>
              <strong>NOME: </strong>
              {alunoData?.transporte?.emergenciaNome}
            </td>
            <td className="text-start" colSpan={2}>
              <strong>GRAU DE PARENTESCO: </strong>
              {alunoData?.transporte?.emergenciaParentesco}
            </td>
            <td className="text-start" colSpan={2}>
              <strong>TELEFONE: </strong>
              {alunoData?.transporte?.emergenciaTelefone}
            </td>
          </tr>
        </tbody>
      </table>

      <table className="info-table mt-2">
        <colgroup>
          <col width="30%" />
        </colgroup>
        <tbody>
          <tr>
            <td colSpan={10} className="fw-bold fs-5">
              1ª PESSOAS AUTORIZADAS A RECEBER O ALUNO NO DESEMBARQUE:
            </td>
          </tr>
          <tr>
            <td className="text-start" colSpan={6}>
              <strong>NOME: </strong>
              {alunoData?.transporte?.desembarqueNome}
            </td>
            <td className="text-start" colSpan={2}>
              <strong>GRAU DE PARENTESCO: </strong>
              {alunoData?.transporte?.desembarqueParentesco}
            </td>
            <td className="text-start" colSpan={2}>
              <strong>TELEFONE: </strong>
              {alunoData?.transporte?.desembarqueTelefone}
            </td>
          </tr>
          <tr>
            <td className="text-start" colSpan={4}>
              <strong>CEP: </strong>
              {alunoData?.transporte?.desembarqueCep}
            </td>
            <td className="text-start" colSpan={4}>
              <strong>LOGRADOURO: </strong>
              {alunoData?.transporte?.desembarqueLogradouro}
            </td>
            <td className="text-start" colSpan={1}>
              <strong>COMPLEMENTO: </strong>
              {alunoData?.transporte?.desembarqueComplemento}
            </td>
            <td className="text-start" colSpan={1}>
              <strong>Nº: </strong>
              {alunoData?.transporte?.desembarqueNumero}
            </td>
          </tr>
          <tr>
            <td className="text-start" colSpan={6}>
              <strong>BAIRRO: </strong>
              {alunoData?.transporte?.desembarqueBairro}
            </td>
            <td className="text-start" colSpan={2}>
              <strong>CIDADE: </strong>
              {alunoData?.transporte?.desembarqueCidade}
            </td>
            <td className="text-start" colSpan={2}>
              <strong>ESTADO: </strong>
              {alunoData?.transporte?.desembarqueEstado}
            </td>
          </tr>
        </tbody>
      </table>
      <table className="info-table mt-2">
        <colgroup>
          <col width="30%" />
        </colgroup>
        <tbody>
          <tr>
            <td colSpan={10} className="fw-bold fs-5">
              2ª PESSOAS AUTORIZADAS A RECEBER O ALUNO NO DESEMBARQUE:
            </td>
          </tr>
          <tr>
            <td className="text-start" colSpan={6}>
              <strong>NOME: </strong>
              {alunoData?.transporte?.desembarqueNome2}
            </td>
            <td className="text-start" colSpan={2}>
              <strong>GRAU DE PARENTESCO: </strong>
              {alunoData?.transporte?.desembarqueParentesco2}
            </td>
            <td className="text-start" colSpan={2}>
              <strong>TELEFONE: </strong>
              {alunoData?.transporte?.desembarqueTelefone2}
            </td>
          </tr>
          <tr>
            <td className="text-start" colSpan={4}>
              <strong>CEP: </strong>
              {alunoData?.transporte?.desembarqueCep2}
            </td>
            <td className="text-start" colSpan={4}>
              <strong>LOGRADOURO: </strong>
              {alunoData?.transporte?.desembarqueLogradouro2}
            </td>
            <td className="text-start" colSpan={1}>
              <strong>COMPLEMENTO: </strong>
              {alunoData?.transporte?.desembarqueComplemento2}
            </td>
            <td className="text-start" colSpan={1}>
              <strong>Nº: </strong>
              {alunoData?.transporte?.desembarqueNumero2}
            </td>
          </tr>
          <tr>
            <td className="text-start" colSpan={6}>
              <strong>BAIRRO: </strong>
              {alunoData?.transporte?.desembarqueBairro2}
            </td>
            <td className="text-start" colSpan={2}>
              <strong>CIDADE: </strong>
              {alunoData?.transporte?.desembarqueCidade2}
            </td>
            <td className="text-start" colSpan={2}>
              <strong>ESTADO: </strong>
              {alunoData?.transporte?.desembarqueEstado2}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="mt-4 fs-5">
        <p>
          É obrigatória a presença do responsável (ou pessoa autorizada), no
          ponto de embarque ou desembarque para autorizar a entrada no ônibus ou
          receber o aluno. Caso o responsável não esteja no local de
          desembarque, o aluno será encaminhado para Secretaria Municipal de
          Educação, e ficará aguardando o responsável buscá-lo.{" "}
        </p>
        <p>
          O Departamento de Transporte emitirá uma notificação ao Conselho
          Tutelar dando ciência do ocorrido.
        </p>
      </div>
      <div className="signature-section">
        <div className="signature-field">
          <div className="signature-line"></div>
          <span>Servidor Responsável</span>
        </div>
        <div className="signature-field">
          <div className="signature-line"></div>
          <span>Direção</span>
        </div>
        <div className="signature-field">
          <div className="signature-line"></div>
          <span>Responsável pelo Aluno</span>
        </div>
      </div>
      <div className="mt-5">
        <p className="text-center fs-5 mt-5">
          {`Emitido por ${currentUser?.usuarioLogin} em ${moment(new Date()).format("DD/MM/YYYY")} às ${moment(new Date()).format("HH:mm")}`}
        </p>
      </div>
    </div>
    </BaseComponent>
  );
};

export default RelatorioAlunosTransporte;

import api from '../'

async function getServidoresCargos(ativo, skip, limit, descricao) {
    var url = descricao != "" ?
       `servidorcargo/listarservidorcargos?ativo=${ativo}&skip=${skip}&limit=${limit}&descricao=${descricao}` : 
       `servidorcargo/listarservidorcargos?ativo=${ativo}&skip=${skip}&limit=${limit}`
    return await api
      .get(url)
      .then(async (res) => {
        const { data } = res.data;
        return res.data;
      });
  };

async function createCargo(data) {
  return await api
    .post('servidorcargo', data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function updateCargo({ id, data }) {
  return await api
    .put(`servidorcargo/${id}`, data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function relatorioCargos() {     
    return await api
    .get(`relatorioparametros/cargos`, { responseType: 'arraybuffer' },  { headers: { 'Content-Type': 'blob' }})
      .then(async (res) => {
        return res.data;
      });
  };

export {
  getServidoresCargos,
  createCargo,
  updateCargo,
  relatorioCargos
}
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import FormInput from '../../../../components/formInput'
import { toast } from 'react-toastify'
import { useMutation } from 'react-query'
import { postListaEspera } from '../../../../services/processos/chamadaPublica'
import { cpf } from 'cpf-cnpj-validator'
import api from '../../../../services'
import './style.css';


const ChamadaPublicaHome = () => {

  const navigate = useNavigate();
  const [exibeFormulario, setExibeFormulario] = useState(0);
  const [contagemRegressivaEncerramento, setContagemRegressivaEncerramento] = useState('');
  const [contagemRegressivaAbertura, setContagemRegressivaAbertura] = useState('');

  setInterval(() => {

    var dataAtual = new Date();
    var time = Date.parse(new Date(2024, 0, 17, 23, 59, 59)) - Date.parse(dataAtual);
    var seconds = Math.floor((time / 1000) % 60);
    var minutes = Math.floor((time / 1000 / 60) % 60);
    var hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    var days = Math.floor(time / (1000 * 60 * 60 * 24));
    var dias = days == 0 ? "" : `${days.toString()} Dias -`;
    setContagemRegressivaEncerramento(`${dias} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);

    var time = Date.parse(new Date(2025, 0, 0, 0, 0, 0)) - Date.parse(dataAtual);
    var seconds = Math.floor((time / 1000) % 60);
    var minutes = Math.floor((time / 1000 / 60) % 60);
    var hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    var days = Math.floor(time / (1000 * 60 * 60 * 24));
    var dias = days == 0 ? "" : `${days.toString()} Dias -`;
    setContagemRegressivaAbertura(`${dias} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);

  }, 1000);

  const validaExibeFormulario = () => {
    var dataAbertura = new Date(2024, 0, 10, 9, 0, 0);
    var dataFechamento = new Date(2025, 0, 0, 0, 0, 0);
    var dataAtual = new Date();
    if (dataAtual >= dataAbertura && dataAtual < dataFechamento)
      setExibeFormulario(1);
    if (dataAtual > dataFechamento)
      setExibeFormulario(2);
  }

  const handleSearch = async (cpf) => {
    const response = await api.get(`/chamadapublica/filtrocpf?cpf=${cpf}`);
    const id = response?.data?.id;

    if (id) {
      navigate('/chamada-publica/visualizacao/' + id)
    } else {
      toast.error('Inscrição não encontrada na base de dados.')
    }
  }


  const { mutate } = useMutation(postListaEspera, {
    onSuccess: data => {
      toast.success('Formulário enviado com sucesso.');
    },
    onError: data => {
      toast.error('Erro ao enviar formulário. Tente novamente mais tarde.')
    }
  })

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { isValid, errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      bairro: '',
      celularResponsavel1: '',
      celularResponsavel2: '',
      celularResponsavel3: '',
      cep: '',
      cidade: '',
      complemento: '',
      cpfAluno: '',
      cpfResponsavelLegal1: '',
      cpfResponsavelLegal2: '',
      cpfResponsavelLegal3: '',
      dataNascimento: '',
      emailResponsavel1: '',
      emailResponsavel2: '',
      emailResponsavel3: '',
      estado: '',
      logradouro: '',
      necessidadesEducacionaisEspeciais: '',
      nomeAluno: '',
      numero: 0,
      numeroCartaoSus: '',
      possuiBolsaFamilia: false,
      possuiLaudo: false,
      guardaOficial: false,
      termoDeclaracao: false,
      responsavelLegal1: '',
      responsavelLegal2: '',
      responsavelLegal3: '',
      telefoneResponsavel1: '',
      telefoneResponsavel2: '',
      telefoneResponsavel3: '',
    }
  })

  function removeEmpty(obj) {
    return Object.entries(obj).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {})
  }

  const onSubmit = data => {

    if (data) {
      const request = {
        ...data,
        possuiBolsaFamilia: Boolean(data.possuiBolsaFamilia),
        possuiLaudo: Boolean(data.possuiLaudo),
        guardaOficial: Boolean(data.guardaOficial),
        termoDeclaracao: Boolean(data.termoDeclaracao),
        necessidadesEducacionaisEspeciais: Boolean(data.necessidadesEducacionaisEspeciais),
        numero: parseInt(data.numero),
      }
      mutate(request)
    }
  }

  useEffect(() => {
    validaExibeFormulario();
  })

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '24px 48px',
      backgroundImage: 'url("fundo.jpg")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      height: '100vh'
    }}>
      <img src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`} width="200" height="150"></img>
      <h3 style={{ margin: '15px', alignSelf: 'center' }}>SECRETARIA DE EDUCAÇÃO - PREFEITURA MUNICIPAL DE SÃO PEDRO</h3>
      <div className="row">
        <div className="mailbox-container">
          <div className="card">
            <div className="container-fluid" style={{ background: '#fff' }}>
              <div className="row">
                <div className="mailbox-open-content col-md-12 m-0 p-3">
                  <div className="card-body" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>


                    {exibeFormulario == 0 && (
                      <>
                        <div>
                          <center>
                            <div style={{ color: '#d2d208', fontSize: '25px', fontWeight: 'normal' }} id="dday">
                              O formulário será disponibilizado em {contagemRegressivaAbertura}
                            </div>
                          </center>
                        </div>
                        <div style={{ border: '1px solid #dee2e6', borderRadius: '5px', display: 'flex', flexDirection: 'column', width: '80%', height: '100%', alignItems: 'center', justifyContent: 'center', padding: '12px', margin: '12px 0' }}>
                          <h2 style={{ textAlign: 'center', color: 'red' }}>INSCRIÇÕES ABERTAS A PARTIR DO DIA<br></br> 10/01/2023 ÀS 9H</h2>
                        </div>
                      </>
                    )}

                  

                        <div className='box-search'>
                          <h3 style={{ color: '#3377c0', fontSize: '35px', alignSelf: 'center' }} >PESQUISAR</h3>
                          <div style={{ display: 'flex', flexDirection: 'row', gap: '12px', width: '100%' }}>
                            <div style={{ width: '60%' }} >
                              <FormInput
                                register={register}
                                errors={errors}
                                mask="999.999.999-99"
                                atribute="cpf"
                                style={{ width: '100%' }}
                                required={false}
                                placeholder="DIGITE SEU CPF"
                              />
                            </div>
                            <button
                              onClick={() => handleSearch(watch('cpf'))}
                              style={{ backgroundColor: '#3377c0', color: '#FFF', fontSize: '20px', border: '1px solid #FFF', borderRadius: '5px', width: '30%', height: '43px', alignSelf: 'flex-end' }} >
                              <span className='txt-pesquisa'>PESQUISAR</span>
                              <span className='icone-pesquisa'>
                                <i className="material-icons md-search"></i></span>
                            </button>
                          </div>
                        </div>
                        {exibeFormulario == 1 && (
                      <>

                        <div style={{ display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center', justifyContent: 'center' }}>
                          <div className='box-search' >
                            <h3 style={{ color: '#3377c0', fontSize: '24px', textAlign: 'center' }} >INSCRIÇÕES PARA CADASTRO CHAMADA PÚBLICA 2024</h3>
                            <button
                              type="button"
                              onClick={() => navigate('/chamada-publica/formulario')}
                              style={{ backgroundColor: '#3377c0', color: '#FFF', fontSize: '20px', border: '1px solid #FFF', borderRadius: '5px' }}
                            >CADASTRO</button>
                          </div>
                          {/* <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #dee2e6', borderRadius: '5px', padding: '12px', gap: '8px' }} >
                          <h3 style={{ color: '#3377c0', fontSize: '24px', textAlign: 'center' }} >INSCRIÇÕES PARA PRÉ I AO 9 ANO</h3>
                          <button onClick={() => navigate('/chamada-publica/formulario')} style={{ backgroundColor: '#3377c0', color: '#FFF', fontSize: '20px', border: '1px solid #FFF', borderRadius: '5px' }} >CADASTRO</button>
                        </div> */}
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center', justifyContent: 'center' }}>
                          <div className='box-search' >
                            <h3 style={{ color: '#3377c0', fontSize: '24px', textAlign: 'center' }} >ACESSE AQUI A LISTA DE ESPERA E CONSULTE SUA POSIÇÃO</h3>
                            <button
                              type="button"
                              onClick={() => navigate('/lista-espera')}
                              style={{ backgroundColor: '#3377c0', color: '#FFF', fontSize: '20px', border: '1px solid #FFF', borderRadius: '5px' }}
                            >LISTA DE ESPERA</button>
                          </div>
                          {/* <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #dee2e6', borderRadius: '5px', padding: '12px', gap: '8px' }} >
                          <h3 style={{ color: '#3377c0', fontSize: '24px', textAlign: 'center' }} >INSCRIÇÕES PARA PRÉ I AO 9 ANO</h3>
                          <button onClick={() => navigate('/chamada-publica/formulario')} style={{ backgroundColor: '#3377c0', color: '#FFF', fontSize: '20px', border: '1px solid #FFF', borderRadius: '5px' }} >CADASTRO</button>
                        </div> */}
                        </div>
                      </>
                    )}
                    {exibeFormulario == 2
                      && (

                        <>
                          <div style={{ border: '1px solid #dee2e6', borderRadius: '5px', display: 'flex', flexDirection: 'column', width: '80%', height: '100%', alignItems: 'center', justifyContent: 'center', padding: '12px', margin: '12px 0' }}>
                            <h2 style={{ textAlign: 'center', color: 'red' }}>INSCRIÇÕES ENCERRADAS</h2>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChamadaPublicaHome

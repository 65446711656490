import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CircularProgress } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SchoolIcon from '@mui/icons-material/School';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import Breadcrumb from "../../../../components/breadcrumb";
import BaseRemanejamento from "./baseRemanejamento";
import { getDashboardRemanejamento } from "../../../../services/dashboard";
import { useQuery } from 'react-query';

// Register necessary components for Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const HomeRemanejamento = () => {
  const { data, isLoading } = useQuery('getDashboardRemanejamento', getDashboardRemanejamento, { retry: 3 });

  const renderCard = (title, amount, icon, iconColor, backgroundColor) => (
    <Card sx={{ boxShadow: 3, borderRadius: 2, height: '100%', backgroundColor, position: 'relative' }}>
      <CardContent>
        <Box display="flex" alignItems="center">
          <Box
            sx={{
              backgroundColor: iconColor,
              color: '#fff',
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 64,
              height: 64,
              mr: 2,
            }}
          >
            {icon}
          </Box>
          <Box>
            <Typography variant="subtitle1" color="textSecondary">{title}</Typography>
            <Typography variant="h4" fontWeight="bold" color="textPrimary">{amount}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  // Prepare chart data
  const labels = Array.from({ length: 22 }, (_, i) => {
    const date = new Date(2024, 10, i + 1); // November (month 10 in JS) + day offset
    return date.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' });
  });

  // Map dailyInscriptions data to align with labels and ensure all days are included
  const dailyTotals = labels.map(label => {
    const found = data.dailyInscriptions.find(d => new Date(d.date).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' }) === label);
    return found ? found.total : 0; // Default to 0 if no data for that day
  });

  // Chart data configuration
  const chartData = {
    labels,
    datasets: [
      {
        label: 'Incrições Por Dia',
        data: dailyTotals,
        borderColor: '#FFFFFF', // White line color
        backgroundColor: 'rgba(255, 99, 132, 0.2)', // Light red fill
        pointBackgroundColor: '#FFFFFF',
        borderWidth: 2,
        pointRadius: 5,
        fill: true,
        tension: 0.4, // Smooth curve
      },
    ],
  };

  // Chart options configuration
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false, // Allows custom height
    plugins: {
      legend: { display: false },
    },
    scales: {
      x: {
        ticks: { color: '#FFFFFF' }, // White ticks on x-axis
        grid: { display: false },
      },
      y: {
        beginAtZero: true,
        ticks: { color: '#FFFFFF' }, // White ticks on y-axis
        grid: { color: 'rgba(255, 255, 255, 0.2)' }, // Light white grid lines
      },
    },
  };

  return (
    <BaseRemanejamento>
      <Breadcrumb title="Informações Gerais" itens={['Remanejamento', 'Dashboard']} />

      <Typography variant="h4" gutterBottom mt={3}>Dados Gerais Inscrições</Typography>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={6}>
          {renderCard(
            "Total Inscrições",
            data.totalInscricoesEmAnalise + data.totalInscricoesDeferidas + data.totalInscricoesIndeferidas + data.totalInscricoesCanceladas,
            <GroupsIcon fontSize="large" />,
            "#FFA726",
            "#FFF3E0"
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {renderCard(
            "Total Inscrições Válidas",
            data.totalInscricoesEmAnalise + data.totalInscricoesDeferidas + data.totalInscricoesIndeferidas,
            <CheckCircleIcon fontSize="large" />,
            "#66BB6A",
            "#E8F5E9"
          )}
        </Grid>
      </Grid>

      <Typography variant="h4" gutterBottom>Inscrições por Status</Typography>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} sm={6} md={3}>
          {renderCard(
            "Total Em Análise",
            data.totalInscricoesEmAnalise,
            <ErrorOutlineIcon fontSize="large" />,
            "#29B6F6",
            "#E1F5FE"
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {renderCard(
            "Total Cancelado",
            data.totalInscricoesCanceladas,
            <CancelIcon fontSize="large" />,
            "#EF5350",
            "#FFEBEE"
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {renderCard(
            "Total Deferido",
            data.totalInscricoesDeferidas,
            <CheckCircleIcon fontSize="large" />,
            "#66BB6A",
            "#E8F5E9"
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {renderCard(
            "Total Indeferido",
            data.totalInscricoesIndeferidas,
            <CancelIcon fontSize="large" />,
            "#EF5350",
            "#FFEBEE"
          )}
        </Grid>
      </Grid>

      <Typography variant="h4" gutterBottom>Total Inscrições por Escolaridade</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={2}>
          {renderCard("Creche II", data.totalInscricoesTipo1, <SchoolIcon fontSize="large" />, "#FFB300", "#FFF8E1")}
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          {renderCard("Creche III", data.totalInscricoesTipo2, <SchoolIcon fontSize="large" />, "#FFB300", "#FFF8E1")}
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          {renderCard("Creche IV", data.totalInscricoesTipo3, <SchoolIcon fontSize="large" />, "#FFB300", "#FFF8E1")}
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          {renderCard("Pré II", data.totalInscricoesTipo4, <SchoolIcon fontSize="large" />, "#66BB6A", "#E8F5E9")}
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          {renderCard("3º Ano", data.totalInscricoesTipo5, <SchoolIcon fontSize="large" />, "#FFCA28", "#FFFDE7")}
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          {renderCard("5º Ano", data.totalInscricoesTipo6, <SchoolIcon fontSize="large" />, "#FFCA28", "#FFFDE7")}
        </Grid>
      </Grid>

      <Typography variant="h4" gutterBottom mt={3}>Inscrições Diárias</Typography>
      <Card sx={{ boxShadow: 3, borderRadius: 2, mt: 2, backgroundColor: '#FF5252' }}>
        <CardContent sx={{ height: 200 }}> {/* Set height to control the chart size */}
          <Line data={chartData} options={chartOptions} />
        </CardContent>
      </Card>
    </BaseRemanejamento>
  );
};

export default HomeRemanejamento;

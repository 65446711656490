import React from 'react';
import { Routes, Route } from 'react-router-dom';
import urlsGestao from '../../pages/gestao/urlsGestao';
import HomeGestao from '../../pages/gestao/homeGestao';
import AlunosPage from '../../pages/alunos';
import AlunosAdicionarPage from '../../pages/alunos/adicionar';
import AlunosEvadirPage from '../../pages/alunos/evadir';
import AlunosAprovacaoPage from '../../pages/alunos/aprovacao';
import TurmasPage from '../../pages/turmas';
import TurmaAlunosPage from '../../pages/turmas/alunos';
import ServidoresPage from '../../pages/servidores';
import ServidoresAdicionarPage from '../../pages/servidores/adicionar';
import TurmasAdicionarPage from '../../pages/turmas/adicionar';
import QuadroHorarioPage from '../../pages/quadro-horarios';
import QuadroDeHorarioDisciplinas from '../../pages/quadro-horarios/disciplinas';
import QuadroDeHorarioAlocaDisciplinas from '../../pages/quadro-horarios/alocacaoDisciplinas';
import QuadroHorarioImpressao from '../../pages/quadro-horarios/imprimir';
import NovoQuadroHorarioPage from '../../pages/quadro-horarios/cadastrar';
import DiarioClassePage from '../../pages/diario-classe';
import FaltasPage from '../../pages/faltas';
import FaltasAdicionarPage from '../../pages/faltas/cadastrar';
import BanccFundamentalPage from '../../pages/bncc/fundamental';
import Calendario from '../../pages/calendarios';
import Perfil from '../../pages/perfil'
import BibliotecaPage from '../../pages/biblioteca';
import AlunosAprovacaoEJAPage from '../../pages/alunos/aprovacaoEJA';
import WebCamPage from '../../pages/reconhecimento-facil/webcam';
import AlunosCarteirinhaPage from '../../pages/alunos/carteirinha';
import AlunosCarteirinhaImpressaoPage from '../../pages/alunos/carteirinha/impressao';
import AdicionarFotoAluno from '../../pages/alunos/foto';
import AdicionarFotoServidor from '../../pages/servidores/foto';
import RelatorioAlunosTransporte from '../../pages/relatorios/alunos/alunosTransporte';
import LancamentosFrenquenciaServidores from '../../pages/frequenciaServidores/adicionar';
import FichaIndividualPage from '../../pages/ficha-individual/FichaIndividualPage';
import VisualizacaoFormularioRenovacao from '../../pages/alunos/renovacao';
import AtaResultadoFinalPage from '../../pages/ata-resultado-final/AtaResultadoFinalPage';
import ChatPage from '../../pages/chat';

const defaultPermisson = true;

const GestaoRoutes = () => (
    <React.Fragment>
        <Route exact path={urlsGestao.dashboard} element={ <HomeGestao />}/>
        <Route exact path={urlsGestao.alunos} element={ <AlunosPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestao.alunosAdicionar} element={ <AlunosAdicionarPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestao.alunosEditarId} element={ <AlunosAdicionarPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestao.alunosEvadir} element={ <AlunosEvadirPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestao.alunosAprovacao} element={ <AlunosAprovacaoPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestao.alunosAprovacao} element={ <AlunosAprovacaoPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestao.alunosAprovacaoEja} element={ <AlunosAprovacaoEJAPage gestao={defaultPermisson} />}/>

        <Route exact path={urlsGestao.turmas} element={ <TurmasPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestao.turmasAlunosId} element={ <TurmaAlunosPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestao.turmasAdicionar} element={ <TurmasAdicionarPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestao.turmasEditarId} element={ <TurmasAdicionarPage gestao={defaultPermisson} />}/>

        <Route exact path={urlsGestao.faltas} element={ <FaltasPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestao.faltasAdicionar} element={ <FaltasAdicionarPage gestao={defaultPermisson} docente={false} />}/>
        <Route exact path={urlsGestao.faltasAdicionarId} element={ <FaltasAdicionarPage gestao={defaultPermisson} docente={false} />}/>

        <Route exact path={urlsGestao.servidores} element={ <ServidoresPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestao.servidoresAdicionar} element={ <ServidoresAdicionarPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestao.servidoresEditarId} element={ <ServidoresAdicionarPage gestao={defaultPermisson} />}/>


        <Route exact path={urlsGestao.quadroHorario} element={ <QuadroHorarioPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestao.quadroHorarioEditarDisplinaId} element={ <QuadroDeHorarioDisciplinas gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestao.quadroHorarioAlocarDisciplinaId} element={ <QuadroDeHorarioAlocaDisciplinas gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestao.quadroHorarioCadastrarId} element={ <NovoQuadroHorarioPage gestao={defaultPermisson} />}/>
        <Route exact path={urlsGestao.quadroHorarioImpressao} element={ <QuadroHorarioImpressao gestao={defaultPermisson} />}/>
        <Route path={urlsGestao.bnccFundamental} element={<BanccFundamentalPage gestao={defaultPermisson} docente={false} configuracao={false} />} /> 
        <Route path={urlsGestao.calendario} element={<Calendario gestao={defaultPermisson} docente={false} configuracao={false} />} /> 
        <Route path={urlsGestao.perfil} element={<Perfil gestao={false} docente={true} />} /> 

        <Route path={urlsGestao.biblioteca} element={<BibliotecaPage gestao={defaultPermisson} />} />
        <Route path={urlsGestao.servidorCadastrarFoto} element={<AdicionarFotoServidor gestao={true} docente={false} />} />
        <Route path={urlsGestao.alunoCadastrarFoto} element={<AdicionarFotoAluno gestao={true} docente={false} />} />
        <Route path={urlsGestao.alunosCarteirinha} element={<AlunosCarteirinhaPage gestao={true} docente={false} />} />
        <Route path={urlsGestao.alunosCarteirinhaImpressao} element={<AlunosCarteirinhaImpressaoPage gestao={true} docente={false} />} />

        <Route path={urlsGestao.alunosFichaTransporteImpressao} element={<RelatorioAlunosTransporte gestao={true} docente={false} />} />
        <Route path={urlsGestao.servidorFrenquenciaLancamento} element={<LancamentosFrenquenciaServidores gestao={true} docente={false} />} />
        <Route path={urlsGestao.alunoFichaIndividualId} element={<FichaIndividualPage gestao={true} docente={false} />} />
        <Route path={urlsGestao.alunoRenovacaoVisualizacaoId} element={<VisualizacaoFormularioRenovacao />} />
        <Route path={urlsGestao.turmasAtaResultadoFinalId} element={<AtaResultadoFinalPage gestao={true} docente={false} />} />
        <Route path={urlsGestao.chat} element={<ChatPage gestao={true} docente={false} />} />

    </React.Fragment>
);

export default GestaoRoutes;

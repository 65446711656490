import { useEffect, useMemo, useState } from 'react';
import Breadcrumb from "../../../components/breadcrumb";
import Table from "../../../components/table";
import BaseGestaoUnidades from '../../gestao-unidades/baseGestaoUnidades';
import urlsGestaoUnidades from '../../gestao-unidades/urlsGestaoUnidades';
import { useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query';

import { getAlunosTranferenciaRecebidas } from '../../../services/alunos';

const TransferenciasRecebidasPage = (gestao) => {

  const navigate = useNavigate()

  const unidadeId = localStorage.getItem('unidadeID');
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [data, setData] = useState([]);



const { isError, isLoading, refetch } = useQuery(
  'getAlunosTranferenciaRecebidas', () => getAlunosTranferenciaRecebidas(unidadeId, skip, limit),
  {
    retry: 0,
    enabled: true,
    onSuccess: (data) => {
      setData(data);
    }
  });

  useEffect(() => {
    refetch()
  }, [refetch, limit, skip])

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'alunoId' },
      { Header: 'Nome', accessor: 'nome' },
      {
        Header: 'PcD', accessor: 'pcd',
        Cell: ({ row }) => (
          <span className={`badge bg-${row.original.unidadeLocacao ? 'success' : 'danger'}`}>
            {row.original.pcd ? 'SIM' : 'NÃO'}
          </span>
        )
      },
      { Header: 'Unidade Anterior', accessor: 'unidadeAnteriorDescricao' },
      { Header: 'Unidade Anterior ID', accessor: 'unidadeAnterior' },
      { Header: 'Escolaridade 2024', accessor: 'escolaridadeProximaDescricao' },
      { Header: 'Escolaridade 2024', accessor: 'escolaridadeProxima' },
      {
        Header: 'Criação', accessor: 'criacao',
        Cell: ({ row }) => (
          row.original.criacao.split('T')[0].split('-').reverse().join('/')
        )
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) => (
          <>
            {row.original.turmaID !== 0 && (
              <button onClick={() => navigate(urlsGestaoUnidades.transferenciasRecebidasAprovacao, {
                state: {
                  id: row.original.alunoId,
                  nome: row.original.nome,
                  unidadeAnterior: row.original.unidadeAnterior,
                  unidadeAnteriorDescricao: row.original.unidadeAnteriorDescricao,
                  escolaridadeProxima: row.original.escolaridadeProxima,
                  escolaridadeProximaDescricao: row.original.escolaridadeProximaDescricao,
                }
              })} className='btn btn-sm btn-success' style={{ marginRight: '5px' }}>
                <i className="material-icons md-edit_note"></i> aprovar
              </button>
            )}
            <button
              onClick={() =>
                navigate(urlsGestaoUnidades.transferenciasRecebidasCancelamento, {
                    state: {
                        id: row.original.alunoId,
                        nome: row.original.nome,
                        unidadeAnterior: row.original.unidadeAnterior,
                        unidadeAnteriorDescricao: row.original.unidadeAnteriorDescricao,
                        escolaridadeProxima: row.original.escolaridadeProxima,
                        escolaridadeProximaDescricao: row.original.escolaridadeProximaDescricao,
                    }
                })
              }
              className="btn btn-sm btn-danger"
            >
              <i className="material-icons md-remove"></i> recusar
            </button>
            
          </>
        )
      }
    ],
    []
  )

  return (
    <BaseGestaoUnidades>
      <Breadcrumb title={'Transferências Recebidas'} itens={['Gestão Unidades', 'Transferências Recebidas', 'Lista Geral']} />
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
          <Table
                columns={columns}
                data={data?.data ? data?.data : []}
                hasPagination
                limit={data?.limit}
                setLimit={setLimit}
                skip={data?.skip}
                setSkip={setSkip}
                totalItems={data?.total}
                hiddenColluns={['unidadeAnterior', 'escolaridadeProxima']}
              />
          </div>
        </div>
      </div>
    </BaseGestaoUnidades>
  )

};

export default TransferenciasRecebidasPage;

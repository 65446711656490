const baseUrl = '/ficha-avaliacao';

const urlsFichaAvaliacao = {
    dashboard: baseUrl,
    calendario: `${baseUrl}/calendario`,
    alunos: `${baseUrl}/alunos`,
    faltas: `${baseUrl}/faltas`,
    noticias: `${baseUrl}/noticias`,
    faltasAdicionar: `${baseUrl}/faltas/adicionar/`,
    faltasAdicionarId: `${baseUrl}/faltas/adicionar/:id`,
    notas: `${baseUrl}/notas`,
    notasAdicionar: `${baseUrl}/notas/adicionar/`,
    notasAdicionarId: `${baseUrl}/notas/adicionar/:id`,
    notasAvaliacoes: `${baseUrl}/notas/avaliacoes/`,
    notasAvaliacoesId: `${baseUrl}/notas/avaliacoes/:id`,
    notasAvaliacoesAlunos: `${baseUrl}/notas/avaliacoes/alunos/`,
    notasAvaliacoesAlunosId: `${baseUrl}/notas/avaliacoes/alunos/:id`,
    notasVisualizacao: `${baseUrl}/notas/visualizacao`,
    // diarioClasse: `${baseUrl}/diario-classe`,
    turmas: `${baseUrl}/turmas`,
    turmasAlunos: `${baseUrl}/turmas/alunos/`,
    turmasAlunosId: `${baseUrl}/turmas/alunos/:id`,
    quadroHorario: `${baseUrl}/quadro-horario`,
    quadroHorarioImpressao: `${baseUrl}/quadro-horario/imprimir`,
    bnncFundamental: `${baseUrl}/bncc-fundamental`,
}

export default urlsFichaAvaliacao;
import { useEffect, useMemo, useState } from 'react';
import { Breadcrumbs, Card, CardContent, Grid, Typography, FormControl, Autocomplete, TextField, IconButton, makeStyles } from '@mui/material';
import Table from '../../../../components/table';
import BaseAdministrativo from '../../baseAdministrativo';
import BaseGestaoUnidades from '../../../gestao-unidades/baseGestaoUnidades';
import { useNavigate } from 'react-router-dom';
import urlsAdministrativo from '../../urlsAdministrativo';
import urlsGestaoUnidades from '../../../gestao-unidades/urlsGestaoUnidades';
import { useQuery, useMutation } from 'react-query';
import { listarImpressoras, deletarImpressora, listarImpressorasUnidades, impressoraChamado, getChamadosImpressoras } from '../../../../services/administrativo/patrimonio';
import { toast } from 'react-toastify';
import { getUnidades } from '../../../../services/unidades';
import Breadcrumb from '../../../../components/breadcrumb';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import { PlayCircleOutline, SupportAgent, Visibility } from '@mui/icons-material';
import { store } from '../../../../store';

const ImpressorasChamadosPage = (gestao, administrativo) => {
    const unidadeId = JSON.parse(localStorage.getItem('unidadeID'));
    const navigate = useNavigate();
    const [newData, setNewData] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(20);
    const [titulo, setTitulo] = useState('');
    const [descricao, setDescricao] = useState('');
    const [impressoraId, setImpressoraId] = useState('');
    const [unidadeSelect, setUnidadeSelect] = useState(gestao.administrativo ? 0 : unidadeId);
    const [openModal, setOpenModal] = useState(false);
    const currentUser = store.getState()["user"]["user"];


    useQuery('unidades', getUnidades, {
        retry: true,
        onSuccess: (data) => {
            var items = [
                { 
                    id: 0, 
                    nome: "TODAS UNIDADES", 
                    sigla: "TDS", 
                    status: 1, 
                    unidadeLotacao: 0, 
                    unidadeLocacao: 0, 
                    nomeDiretor: "", 
                    inep: "", 
                    endereco: 
                        { 
                            cep: "28941-414", 
                            logradouro: "JUSCELINO KUBSTHECK DE OLIVEIRA", 
                            numero: 547, 
                            complemento: "TESTE", 
                            bairro: "SOLEDADE", 
                            cidade: "SÃO PEDRO DA ALDEIA", 
                            estado: "RJ" 
                        }, 
                    contato: 
                        { 
                            telefone: "998077975", 
                            email: "IP" 
                        }, 
                    criacao: "2024-10-21T21:58:31.6747326-03:00", 
                    ultimaAtualizacao: "2024-10-21T21:58:31.6747333-03:00"
                 }
            ];
            items.push(...data);
            setUnidades(items);
        },
    }
    );

    const { refetch: refetchData, isLoading } = useQuery(
        'getChamadosImpressoras',
        () => getChamadosImpressoras(unidadeSelect, 0, 20),
        {
            enabled: true,
            retry: true,
            onSuccess: (data) => {
                setNewData(data);
            },
        }
    );

    useEffect(() => {
        refetchData();
    }, [unidadeSelect]);

    const columns = useMemo(
        () => [
            { Header: '#', accessor: 'id' },
            { Header: 'Undiade', accessor: 'unidade' },
            { Header: 'Servidor', accessor: 'servidor' },
            { Header: 'Impressora', accessor: 'impressora' },
            { Header: 'Título', accessor: 'titulo' },
            { Header: 'Descrição', accessor: 'descricao' },
            {
                Header: 'Data Abertura',
                Cell: ({ row }) =>
                    row.original.dataEvento.split("T")[0].split("-").reverse().join("/"),
            },
            {
                Header: "STATUS",
                accessor: "status",
                Cell: ({ row }) => (
                    <span
                        className={`badge bg-${row.original.status == 1 ? "primary" : row.original.status == 2 ? "primary" : row.original.status == 3 ? "warning" : row.original.status == 4 ? "success" : "danger"}`}
                    >
                        {row.original.status == 1 ? "Em Aberto" : row.original.status == 2 ? "Em Andamento" : row.original.status == 3 ? "Aguardando Resposta do Solicitante" : row.original.status == 4 ? "Concluído" : "Cancelado"}
                    </span>
                ),
            },
            {
                Header: '',
                accessor: 'actions',
                Cell: ({ row }) => (
                    <>
                        {!gestao.administrativo && (
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    navigate(urlsGestaoUnidades.chamadosAcompanhamentoImpressoras,
                                        {
                                            state: {
                                                id: row.original.id,
                                                marca: row.original.marca,
                                                modelo: row.original.modelo,
                                                numeroSerie: row.original.numeroSerie,
                                                nomeServidor: row.original.servidor,
                                                servidorId: row.original.servidorId,
                                                dataEvento: row.original.dataEvento.split("T")[0].split("-").reverse().join("/"),
                                                titulo: row.original.titulo,
                                                descricao: row.original.descricao
                                            },
                                        }
                                    )
                                }}
                            >
                                <Visibility />
                            </IconButton>

                        )}
                        {gestao.administrativo && (
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    navigate(urlsAdministrativo.patrimonioControleImpressorasChamadoAcompanhamento,
                                        {
                                            state: {
                                                id: row.original.id,
                                                marca: row.original.marca,
                                                modelo: row.original.modelo,
                                                numeroSerie: row.original.numeroSerie,
                                                nomeServidor: row.original.servidor,
                                                servidorId: row.original.servidorId,
                                                dataEvento: row.original.dataEvento.split("T")[0].split("-").reverse().join("/"),
                                                titulo: row.original.titulo,
                                                descricao: row.original.descricao,
                                                status: row.original.status
                                            },
                                        }
                                    )
                                }}
                            >
                                <PlayCircleOutline />
                            </IconButton>

                        )}

                    </>
                ),
            },
        ],
        [navigate, isLoading, newData]
    );

    let Base = gestao.administrativo ? BaseAdministrativo : BaseGestaoUnidades;
    let title = gestao.administrativo ? 'Administrativo' : 'Gestão de Unidades';
    let linkCadastrar = gestao.administrativo ? urlsAdministrativo.patrimonioControleImpressorasCadastrar : '';

    return (
        <Base>
            <Breadcrumb title={'Chamados Impressoras'} itens={[title, 'Patrimônio', 'Impressoras', 'Chamados']} />
            <Grid container spacing={2} style={{ marginBottom: 20 }}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Autocomplete
                            id="unidadeId-autocomplete"
                            options={unidades || []}
                            getOptionLabel={(option) => option.nome}
                            value={null}
                            onChange={(e, newValue) => setUnidadeSelect(newValue ? newValue.id : '')}
                            disabled={!gestao.administrativo}
                            renderInput={(params) => <TextField {...params} label="Unidade" />}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Card>
                <CardContent style={{ backgroundColor: 'transparent' }}>
                    <Table
                        columns={columns}
                        data={newData.data ? newData.data : []}
                        hasPagination
                        limit={limit}
                        setLimit={setLimit}
                        skip={skip}
                        setSkip={setSkip}
                        totalItems={newData.total}
                    />
                </CardContent>
            </Card>
        </Base>
    );
};

export default ImpressorasChamadosPage;

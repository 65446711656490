import { useEffect, useState } from 'react';
import './estilo.css'

const QuadroHorarioImpressaoDocente = (dados) => {
    const [quadroHorario, setQuadrohorario] = useState([]);
    const [materias, setMaterias] = useState([]);
    const [unidade, setUnidade] = useState('');
    const [escolaridade, setEscolaridade] = useState('');
    const [turno, setTurno] = useState('');
    const [turma, setTurma] = useState('');


    useEffect(() => {
        setUnidade(localStorage.getItem('nomeUnidade'));
        setEscolaridade(localStorage.getItem('nomeEscolaridade'));
        setTurno(localStorage.getItem('nomeTurno'));
        setTurma(localStorage.getItem('nomeTurma'));
        var dados = JSON.parse(localStorage.getItem('quadro-horario')).data;
        var lista = [];
        var quadro = [];

        for (let i = 0; i < dados.length; i++) {
            quadro.push({ dia: diaSemana(dados[i]['diaSemana']), ...dados[i] })
        }
        setQuadrohorario(quadro.sort(function (a, b) {
            return a.dia < b.dia ? -1 : a.dia > b.dia ? 1 : 0;
        }));

        for (let i = 0; i < dados.length; i++) {
            for (let j = 0; j < dados[i]['dadosQuadroHorario'].length; j++) {
                const element = dados[i]['dadosQuadroHorario'][j];
                lista.push({ linha: j, diaSemana: dados[i]['diaSemana'], dia: diaSemana(dados[i]['diaSemana']), ...element })
            }
        }

        var resultadoFinal = []
        var line = 0;
        var qtdAulasDiasSenamas = [
            lista.filter(x => x.diaSemana == 'Segunda').length,
            lista.filter(x => x.diaSemana == 'Terca').length,
            lista.filter(x => x.diaSemana == 'Quarta').length,
            lista.filter(x => x.diaSemana == 'Quinta').length,
            lista.filter(x => x.diaSemana == 'Sexta').length
        ];

        while (true) {
            var result = lista.filter(x => x.linha == line);
            if (result.length == 0) {
                break;
            }
            
            if (result.length < 5) {
                if (lista.filter(x => x.diaSemana == 'Segunda').length < qtdAulasDiasSenamas.sort().reverse()[0] && result.find(x => x.diaSemana == 'Segunda') == undefined) {
                    result.push({
                        dia: 2,
                        diaSemana: 'Segunda',
                        disciplina: "",
                        horaFinal: "",
                        horaInicio: "",
                        linha: line,
                        servidor1Descricao: "",
                        servidor2Descricao: ""
                    });
                }
                if (lista.filter(x => x.diaSemana == 'Terca').length < qtdAulasDiasSenamas.sort().reverse()[0] && result.find(x => x.diaSemana == 'Terca') == undefined) {
                    result.push({
                        dia: 3,
                        diaSemana: 'Terca',
                        disciplina: "",
                        horaFinal: "",
                        horaInicio: "",
                        linha: line,
                        servidor1Descricao: "",
                        servidor2Descricao: ""
                    });
                }
                if (lista.filter(x => x.diaSemana == 'Quarta').length < qtdAulasDiasSenamas.sort().reverse()[0] && result.find(x => x.diaSemana == 'Quarta') == undefined) {
                    result.push({
                        dia: 4,
                        diaSemana: 'Quarta',
                        disciplina: "",
                        horaFinal: "",
                        horaInicio: "",
                        linha: line,
                        servidor1Descricao: "",
                        servidor2Descricao: ""
                    });
                }
                if (lista.filter(x => x.diaSemana == 'Quinta').length < qtdAulasDiasSenamas.sort().reverse()[0] && result.find(x => x.diaSemana == 'Quinta') == undefined) {
                    result.push({
                        dia: 5,
                        diaSemana: 'Quinta',
                        disciplina: "",
                        horaFinal: "",
                        horaInicio: "",
                        linha: line,
                        servidor1Descricao: "",
                        servidor2Descricao: ""
                    });
                }
                if (lista.filter(x => x.diaSemana == 'Sexta').length < qtdAulasDiasSenamas.sort().reverse()[0] && result.find(x => x.diaSemana == 'Sexta') == undefined) {
                    result.push({
                        dia: 6,
                        diaSemana: 'Sexta',
                        disciplina: "",
                        horaFinal: "",
                        horaInicio: "",
                        linha: line,
                        servidor1Descricao: "",
                        servidor2Descricao: ""
                    });
                }
            }
            resultadoFinal.push(result);

            line++;
        }
        
        setMaterias(resultadoFinal.sort(function (a, b) {
            return a.dia < b.dia ? -1 : a.dia > b.dia ? 1 : 0;
        }));


        setTimeout(() => {
            window.print();
            // localStorage.removeItem('quadro-horario');
            // localStorage.removeItem('nomeEscolaridade');
            // localStorage.removeItem('nomeTurno');
            // localStorage.removeItem('nomeTurma');
        }, 2000);
    }, []);

    const diaSemana = (dia) => {
        switch (dia) {
            case 'Segunda':
                return 2
                break;
            case 'Terca':
                return 3
                break;
            case 'Quarta':
                return 4
                break;
            case 'Quinta':
                return 5
                break;
            case 'Sexta':
                return 6
                break;

            default:
                break;
        }
    }

    return (
        <div className='class-print' style={{ marginLeft: '10px 0 ', marginRight: '13px', width: 720 }}>
            <div className='row'>
                <div className='col-md-12'>
                    <img src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`} height={90} className='center' style={{ marginBottom: '20px', marginTop: '20px' }} />
                </div>
            </div>
            <div className='row box-info'>
                <div className='col-md-12'>
                    <div className='row' style={{ height: '25px' }}>
                        <p className='text-center'>UNIDADE: {unidade}</p>
                    </div>
                    {/* <div className='row'>
                        <div className='col-md-6'>
                            <p>ESCOLARIDADE: {escolaridade}</p>
                        </div>
                        <div className='col-md-3'>
                            <p>TURNO: {turno}</p>
                        </div>
                        <div className='col-md-3'>
                            <p>TURMA: {turma}</p>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <table className='tb-quadro-horario'>
                        <thead>
                            <th>SEGUNDA-FEIRA</th>
                            <th>TERÇA-FEIRA</th>
                            <th>QUARTA-FEIRA</th>
                            <th>QUINTA-FEIRA</th>
                            <th>SEXTA-FEIRA</th>
                        </thead>
                        <tbody>
                            {
                                materias.map(materia => {
                                    {
                                        return <>
                                            <tr>
                                                {
                                                    materia.sort(function (a, b) {
                                                        return a.dia < b.dia ? -1 : a.dia > b.dia ? 1 : 0;
                                                    }).map((line) => {

                                                        return line.horaInicio != '' ? <> <td><b>{line.horaInicio}/{line.horaFinal} - {line.disciplina} </b><br />
                                                        <br /><b>{line.unidadadeDescricao}</b><br /><br />
                                                        ESCOLARIDADE - <b>{line.escolaridadeDescricao}</b><br />
                                                        TURNO - <b>{line.turnoDescricao}</b><br />
                                                        TURMA - <b>{line.turmaDescricao}</b><br />
                                                        </td></> : <><td></td></>
                                                    })
                                                }
                                            </tr>
                                        </>
                                    }

                                })
                            }
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    );
};

export default QuadroHorarioImpressaoDocente;

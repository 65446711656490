import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { getDisciplinas, deleteDisciplina } from '../../services/disciplinas';
import Breadcrumb from "../../components/breadcrumb";
import TableComponent from '../../components/novaTabela';
import BaseConfiguracoes from '../configuracoes/baseConfiguracoes';
import urlsConfiguracoes from '../configuracoes/urlsConfiguracoes';
import { CircularProgress, Button, IconButton, Typography, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const DisciplinasPage = () => {
  const navigate = useNavigate();

  const { data, isLoading: loading, refetch } = useQuery('getDisciplinas', getDisciplinas, {
    retry: 3,
  });

  const { mutate } = useMutation(deleteDisciplina, {
    onSuccess: (message) => {
      toast.success(message);
      refetch();
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar excluir, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    },
  });

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Descrição', accessor: 'descricao' },
      { Header: 'Sigla', accessor: 'sigla' },
      {Header: 'Boletim Obrigatório', accessor: 'boletimObrigatorio', Cell: ({ value }) => (value ? 'SIM' : 'NÃO')},
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <IconButton
              onClick={() =>
                navigate(urlsConfiguracoes.disciplinasEditar + row.original.id, {
                  state: {
                    id: row.original.id,
                    disciplina: {
                      descricao: row.original.descricao,
                      sigla: row.original.sigla,
                      boletimObrigatorio: row.original.boletimObrigatorio,
                    },
                  },
                })
              }
              color="primary"
              size="small"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                if (window.confirm('Deseja realmente excluir?')) {
                  mutate(row.original.id);
                }
              }}
              color="secondary"
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          </>
        ),
      },
    ],
    [mutate, navigate]
  );

  return (
    <BaseConfiguracoes>
      <Breadcrumb
        title="Disciplinas"
        itens={['Gestão', 'Secretaria', 'Disciplinas', 'Lista Geral']}
        actions={{
          link: urlsConfiguracoes.disciplinasAdicionar,
          text: 'Nova Disciplina',
        }}
      />

        {loading ? (
          <CircularProgress />
        ) : (
          <TableComponent columns={columns} data={data ? data : []} hasPagination pageSize={data?.limit || 10} />
        )}

    </BaseConfiguracoes>
  );
};

export default DisciplinasPage;

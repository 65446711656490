import React from 'react';
import BaseGestaoUnidades from '../../../gestao-unidades/baseGestaoUnidades';
import BaseGestao from '../../../gestao/baseGestao';
import urlsGestao from '../../../gestao/urlsGestao';
import urlsGestaoUnidades from '../../../gestao-unidades/urlsGestaoUnidades';
import Breadcrumb from '../../../../components/breadcrumb';

const FamiliaConectadaAtendimentoAdicionar = (gestao) => {

    let BaseComponent;
    let BasePath;
    let adicionarUrl;
    let modulo = 'Família Conectada';
    let componente = 'Adicionar Atendimento';
    const unidadeId = JSON.parse(localStorage.getItem('unidadeID'));

    if (gestao.gestao) {
        BaseComponent = BaseGestao;
        BasePath = 'Gestão'
        adicionarUrl = urlsGestao.alunosAdicionar;
      } 
      if (!gestao.gestao && !gestao.docente) {
        BaseComponent = BaseGestaoUnidades;
        BasePath = 'Gestão Unidades'
        adicionarUrl = urlsGestaoUnidades.alunosAdicionar;
      }

    return (
        <BaseComponent>
        <Breadcrumb
          title={componente}
          itens={[BasePath, modulo, componente]}
           
          
        />
        </BaseComponent>
    );
};

export default FamiliaConectadaAtendimentoAdicionar;
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from "../../../../../../components/breadcrumb";
import BaseGestaoUnidades from "../../../../../gestao-unidades/baseGestaoUnidades";
import urlsGestaoUnidades from "../../../../../gestao-unidades/urlsGestaoUnidades";
import { postIndeferirInscricao } from "../../../../../../services/processos/chamadaPublica";
import { useMutation } from 'react-query';
import FormInput from "../../../../../../components/formInput";
import { store } from "../../../../../../store";

const MatricularIndeferir = () => {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const [responsavel, setResponsavel] = useState(null);

  const unidadeId = JSON.parse(localStorage.getItem('unidadeID'));

  const currentUser = store.getState()['user']['user']

  const [shouldApperUnidadePicker, setShouldApperUnidadePicker] = useState(false);
  const [isFirstRenderPick, setIsFirstRenderPick] = useState(true);

  const [motivo, setMotivo] = useState('')
  const [status, setStatus] = useState(0)




  const { mutate, isLoading } = useMutation(postIndeferirInscricao, {
    onSuccess: (data) => {
      toast.success('Matrícula Indeferida com sucesso!');
      navigate(urlsGestaoUnidades.matriculas);
    },
    onError: (error) => {
      if (error.response.status == 400) {
        toast.error("Informe todos os campos obrigatórios.");
      } else if (error.request) {
        toast.error('Erro ao tentar indeferir matrícula, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  const { register, handleSubmit, setValue, formState: { isValid, errors }, watch } = useForm({ mode: "onSubmit" });

  // PARA TESTAR A INTEGRAÇÃO, SÓ DESCOMENTAR A LINHA "MUTATE" ABAIXO
    const onSubmit = (data) => {

        console.log(watch('status'))
        const values = {
          alunoId: state.id,  
          usuarioId: parseInt(currentUser?.servidorId),
          status: parseInt(watch('status')),
          motivo: watch('motivo'),
          unidadeId: unidadeId
        }

        if(values.status == 0){
            return toast.warn("Selecione um status para dar continuidade ao processo.")
          }
    
        else{
          mutate(values);
        }
    
        
      };

  useEffect(() => {
    setValue('id', state.id)
    setValue('nome', state.aluno.nome)
  }, [state, pathname, setValue])

  useEffect(() => {
    setShouldApperUnidadePicker(watch('motivo') === '5')
  }, [watch('motivo')])




  // PARA HABILITAR O PICKER DE UNIDADE NO FORM BASTA COMENTAR AS LINHAS 135 ~ 147 E DESCOMENTAR AS LINHAS 148~165
  return (
    <BaseGestaoUnidades>
      <Breadcrumb title={'INDEFERIRMENTO MATRÍCULAS'} itens={['Gestão de Unidades', 'Matriculas', 'Indeferir']} />
      <form id='form' onSubmit={handleSubmit(onSubmit)}>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className="card-body">
                <>
                  <div className='row'>
                    <div className='col-1'>
                      <FormInput
                        register={register}
                        errors={errors}
                        group=""
                        atribute="id"
                        label="ID"
                        readOnly
                      />
                    </div>
                    <div className='col-11'>
                      <FormInput
                        register={register}
                        errors={errors}
                        group=""
                        atribute="nome"
                        label="Nome"
                        readOnly
                      />
                    </div>
                  </div>

                  <br />

                  <div className='row'>
                    <div className={shouldApperUnidadePicker ? "col-md-6" : "col-md-12"}>
                      <div className="form-group">
                        <label htmlFor="status">Status<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                        <select
                          className="form-select"
                          id="status"
                        //   onClick={() => setIsFirstRenderPick(false)}
                          {...register('status', { required: true, valueAsNumber: true })}
                        >
                          {isFirstRenderPick && <option></option>}
                          {state.remanejamento !== true && <option value={5}>INDEFERIDO</option>}
                          <option value={7}>DESISTÊNCIA</option>

                       
                        </select>
                       
                      </div>
                    </div>
                    {/* {
                      shouldApperUnidadePicker && (
                        <div className="col-md-6" >
                          <FormInput
                            register={register}
                            errors={errors}
                            group=""
                            atribute="unidadeID"
                            label="Unidade"
                            readOnly
                          />
                        </div>
                      )
                    } */}
                    
                  </div>

  

                  

                  <br />

                  <div className='row'>
                    <div className='col-12'>
                      <FormInput
                        register={register}
                        errors={errors}
                        group=""
                        atribute="motivo"
                        label="Motivo"
                        required={false}
                      />
                    </div>
                  </div>

                </>
              </div>
              <div 
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginRight: 30,
                marginBottom: 20
              }}>
              <button 
                style={{ marginRight: 20}}
                type='button' 
                className='btn btn-warning'
                onClick={() => navigate(-1)}>
                  Voltar
                </button>
                <button 
                type='submit' 
                className='btn btn-success' 
                onClick={() => onSubmit()}
                // disabled={!isValid}
                >
                  Enviar
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </BaseGestaoUnidades>
  )

};

export default MatricularIndeferir;
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { cadastrarImpressora, editarImpressora } from '../../../../../services/administrativo/patrimonio'
import { useMutation } from 'react-query'
import BaseAdministrativo from '../../../baseAdministrativo'
import Categoria from './tab/categoria'
import urlsAdministrativo from '../../../urlsAdministrativo'
import { toast } from 'react-toastify'
import Historico from './tab/historico'


const ImpressoraCadastrarForm = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { state } = useLocation()

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid, errors }
  } = useForm({
    mode: 'all'
  })

  const { mutate, isLoading } = useMutation(id ? editarImpressora : cadastrarImpressora, {
    onSuccess: message => {
      toast.success(message);
      navigate(urlsAdministrativo.patrimonioControleImpressoras)
    },
    onError: error => {
      toast.error('Algo deu errado. Tente novamente mais tarde');
    }
  })

  useMemo(() => {
    (async () => {
      if (state) {
        const { teste } = state
        if (id && teste) {
          const { marca, modelo, numeroSerie, unidadeId } = teste
          setValue('id', id)
          setValue('marca', marca)
          setValue('modelo', modelo)
          setValue('numeroSerie', numeroSerie)
          setValue('unidadeId', unidadeId)
        }
      }
    })()
  }, [id, state, setValue])

  const onSubmit = (data) => {
    if (data.marca !== '') {
      if (id) {
        mutate({ id, marca: data.marca.toUpperCase(), modelo: data.modelo.toUpperCase(), numeroSerie: data.numeroSerie.toUpperCase(), unidadeId: data.unidadeId})
      } else {
        mutate({ marca: data.marca.toUpperCase(), modelo: data.modelo.toUpperCase(), numeroSerie: data.numeroSerie.toUpperCase(), unidadeId: data.unidadeId})
      }
    } else {
      toast.error('Confira os campos obrigatórios e tente novamente.')
    }
  }


  return (
    <BaseAdministrativo>
      <div className="row">
        <div className="mailbox-container">
          <div className="card">
            <div className="container-fluid">
              <div className="row">
                <div className="mailbox-open-content col-md-12 m-0 p-3">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="categoria-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#categoria"
                        type="button"
                        role="tab"
                        aria-controls="categoria"
                        aria-selected="true"
                      >
                        Informações
                      </button>
                    </li>
                    {
                      id && (
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="historico-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#historico"
                            type="button"
                            role="tab"
                            aria-controls="historico"
                            aria-selected="false"
                          // onClick={() => setIsHistoricTab(false)}
                          >
                            Histórico
                          </button>
                        </li>
                      )
                    }

                  </ul>
                  <form id="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="tab-content" id="myTabContent">
                      <Categoria
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        watch={watch}
                      />

                      {
                        id && (
                          <Historico
                            register={register}
                            errors={errors}
                            setValue={setValue}
                          // watch={watch}
                          />
                        )
                      }

                    </div>

                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => navigate(urlsAdministrativo.patrimonioControleImpressoras)}
                        style={{ marginRight: '8px' }}
                      >
                        Voltar
                      </button>

                      <button
                        type="submit"
                        className="btn btn-success"
                        disabled={!isValid}
                      >
                        {id ? 'Atualizar' : 'Cadastrar'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseAdministrativo>
  )

};

export default ImpressoraCadastrarForm;
import api from "..";

async function getBnccByEscolaridadeAnoFaixa(escolaridade, anoFaixa, componente) {
  try {
    const response = await api.get(`/bnccInfantil/filtro`, {
      params: { escolaridade, anoFaixa, componente }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching BNCC Infantil data", error);
    throw error;
  }
}

export {
  getBnccByEscolaridadeAnoFaixa,
};

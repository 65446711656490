import { useMemo, useEffect, useState } from "react";
import Breadcrumb from "../../components/breadcrumb";
import Table from "../../components/table";
import BaseGestaoUnidades from "../gestao-unidades/baseGestaoUnidades";
import BaseGestao from "../gestao/baseGestao";
import BaseDocente from "../docente/baseDocente";
import urlsGestao from "../gestao/urlsGestao";
import urlsGestaoUnidades from "../gestao-unidades/urlsGestaoUnidades";
import { useNavigate } from "react-router-dom";
import { getRelatorioAlunos } from "../../services/relatorios";
import { getAlunosFiltro } from "../../services/alunos";
import Select from "react-select";
import api from "../../services/index";
import { Modal, Button, Dropdown } from "react-bootstrap";

import { getTurmasUnidadeEscolaridadeTurno } from "../../services/turmas";
import {
  getEscolaridades as todasEscolaridades,
  getEscolaridadesUnidade,
} from "../../services/escolaridades";
import {
  getTurnos as todosTurnos,
  getTurnosUnidadeEscolaridade,
} from "../../services/turnos";
import { getUnidades } from "../../services/unidades";
import { useQuery } from "react-query";
import { store } from "../../store";
import { adicionarAvisos } from "../../services/familia-conectada";
import { toast } from "react-toastify";
import { imprimirFichaIndividual } from "../../services/ficha-avaliacao";
import handleDownloadPDF from "../../utils/handleDownloadPDF";
import BaseReconhecimentoFacial from "../reconhecimento-facil/baseReconhecimentoFacial";
import urlsReconhecimentoFacil from "../reconhecimento-facil/urlsReconhecimentoFacil";
import BaseAdministrativo from "../administrativo/baseAdministrativo";
import {
  Card,
  CardContent,
  Menu,
  MenuItem,
  Button as ButtonComponet,
  Chip,
} from "@mui/material";
import { FilterAlt, FilterAltOutlined } from "@mui/icons-material";
import { set } from "react-hook-form";

const AlunosPage = (gestao) => {
  const currentUser = store.getState()["user"]["user"];
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showEja, setShowEja] = useState(false);
  const handleShowEja = () => setShowEja(true);
  const [titulo, setTitulo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [alunoId, setAlunoId] = useState(0);
  const [totalAlunos, setTotalAlunos] = useState(0);

  const modulo = gestao.gestao ? "gestao" : "unidades";

  let BaseComponent;
  let BasePath;
  let editarUrl;
  let adicionarUrl;
  let evadirUrl;
  let aprovacaoUrl;
  let aprovacaoEjaUrl;
  let cadastrarFotoUrl;
  let habilitaEditar = false;
  let habilitarExcluir = false;
  let habilitaAdicionaFoto = false;
  let habilitaNotificar = false;
  let habilitaFichaIndividual = false;
  let habilitaFichaIndividual2 = false;
  let habilitaFichaTransporte = false;
  let habilitaAprovacaoEJA = false;
  let habilitaAdicionarAluno = false;
  let habilitaExportarAluno = false;
  let disableSeletorUnidades = true;
  let habilitaImprimirCarterinha = false;
  let carteirinhaUrl;
  let fichaTransporteUrl;
  let fichaIndividualUrl;
  let renovacaoUrl;

  async function adicionarAviso() {
    const data = {
      titulo,
      descricao,
      servidorId: currentUser?.servidorId,
      alunoId: alunoId,
      unidadeId: unidadeId,
    };

    try {
      let response = await adicionarAvisos(data);
      if (response?.statusCode === 200) {
        toast.success("Aviso adicionado com sucesso");
        handleClose();
      } else {
        toast.error("Erro ao adicionar aviso");
      }
    } catch (error) {
      console.error("Erro ao adicionar aviso:", error);
      toast.error("Erro ao adicionar aviso");
    }
  }

  function handleOpenModal(alunoId) {
    setAlunoId(alunoId);
    setShow(true);
  }

  if (gestao.gestao) {
    BaseComponent = BaseGestao;
    BasePath = "Gestão";
    editarUrl = urlsGestao.alunosEditar;
    aprovacaoUrl = urlsGestao.alunosAprovacao;
    aprovacaoEjaUrl = urlsGestao.alunosAprovacaoEja;
    adicionarUrl = urlsGestao.alunosAdicionar;
    evadirUrl = urlsGestao.alunosEvadir;
    renovacaoUrl = urlsGestao.renovacaoVisualizacao;
    cadastrarFotoUrl = urlsGestao.alunoCadastrarFoto;
    habilitaAdicionaFoto = true;
    habilitarExcluir = true;
    habilitaNotificar = true;
    habilitaEditar = true;
    habilitaAprovacaoEJA = true;
    habilitaFichaIndividual = true;
    habilitaFichaIndividual2 = true;
    habilitaFichaTransporte = true;
    habilitaAdicionarAluno = true;
    habilitaExportarAluno = true;
    disableSeletorUnidades = false;
    habilitaImprimirCarterinha = true;
    carteirinhaUrl = urlsGestao.alunosCarteirinha;
    fichaTransporteUrl = urlsGestao.alunosFichaTransporteImpressao;
    fichaTransporteUrl = urlsGestao.alunosFichaTransporteImpressao;
    fichaIndividualUrl = urlsGestao.alunoFichaIndividual;
  }
  if (!gestao.gestao && !gestao.docente) {
    BaseComponent = BaseGestaoUnidades;
    BasePath = "Gestão Unidades";
    editarUrl = urlsGestaoUnidades.alunosEditar;
    aprovacaoUrl = urlsGestaoUnidades.alunosAprovacao;
    aprovacaoEjaUrl = urlsGestaoUnidades.alunosAprovacaoEja;
    adicionarUrl = urlsGestaoUnidades.alunosAdicionar;
    evadirUrl = urlsGestaoUnidades.alunosEvadir;
    cadastrarFotoUrl = urlsGestaoUnidades.alunoCadastrarFoto;
    habilitaAdicionaFoto = true;
    habilitarExcluir = true;
    habilitaNotificar = true;
    habilitaEditar = true;
    habilitaAprovacaoEJA = true;
    habilitaFichaIndividual = true;
    habilitaFichaIndividual2 = true;
    habilitaFichaTransporte = true;
    habilitaAdicionarAluno = true;
    habilitaExportarAluno = true;
    habilitaImprimirCarterinha = true;
    carteirinhaUrl = urlsGestaoUnidades.alunosCarteirinha;
    fichaTransporteUrl = urlsGestaoUnidades.alunosFichaTransporteImpressao;
    fichaIndividualUrl = urlsGestaoUnidades.alunoFichaIndividual;
    renovacaoUrl = urlsGestaoUnidades.alunoRenovacaoVisualizacao;
  }
  if (gestao.reconhecimentoFacial) {
    BaseComponent = BaseReconhecimentoFacial;
    BasePath = "Reconhecimento Facial";
    habilitaAdicionaFoto = true;
    habilitarExcluir = false;
    habilitaNotificar = false;
    habilitaEditar = false;
    adicionarUrl = "";
    habilitaExportarAluno = false;
    cadastrarFotoUrl = urlsReconhecimentoFacil.alunoCadastrarFoto;
  }
  if (gestao.docente) {
    BaseComponent = BaseDocente;
    BasePath = "Docente";
    habilitaAdicionaFoto = false;
    habilitarExcluir = false;
    habilitaNotificar = false;
    habilitaEditar = false;
    adicionarUrl = "";
    habilitaExportarAluno = false;
  }

  if (gestao.administrativo) {
    BaseComponent = BaseAdministrativo;
    BasePath = "Administrativo";
    habilitaAdicionaFoto = false;
    habilitarExcluir = false;
    habilitaNotificar = false;
    habilitaFichaIndividual = false;
    habilitaFichaIndividual2 = false;
    habilitaFichaTransporte = true;
    habilitaEditar = true;
    adicionarUrl = "";
    habilitaExportarAluno = false;
    disableSeletorUnidades = false;
  }

  const [search, setSearch] = useState("");
  const [pesquisarResponsavel, setPesquisarResponsavel] = useState("");
  const [pesquisarAluno, setPesquisaAluno] = useState("");
  const [pesquisaCPF, setpesquisaCPF] = useState("");
  const [data, setData] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [openPicker, setOpenPicker] = useState(false);

  const unidadeId = JSON.parse(localStorage.getItem("unidadeID"));
  const anoLetivoAtual =
    process.env.REACT_APP_SECRETARIA_MUNICIPAL !== "SEMED" ? 0 : 2024;
  const filtroAlunosAnoLetivo =
    localStorage.getItem("filtroAlunosAnoLetivo") || anoLetivoAtual;
  const filtroAlunosEscolaridade =
    localStorage.getItem("filtroAlunosEscolaridade") || 0;
  const filtroAlunosTurno = localStorage.getItem("filtroAlunosTurno") || 0;
  const filtroAlunosTurma = localStorage.getItem("filtroAlunosTurma") || 0;
  const filtroAlunosRenovacao =
    localStorage.getItem("filtroAlunosRenovacao") || 0;
  const filtroAlunosMatriculados =
    localStorage.getItem("filtroAlunosMatriculados") || 0;
  const filtroAlunosTransporte =
    localStorage.getItem("filtroAlunosTransporte") || 0;
  const filtroAlunosUnidade = 
    localStorage.getItem("filtroAlunosUnidade") ? localStorage.getItem("filtroAlunosUnidade") : gestao.gestao ? 0 : unidadeId;

  const filtroAlunosLimit = localStorage.getItem("modulo") !== modulo ? 20 : localStorage.getItem("filtroAlunosLimit") || 20;
  const filtroAlunosSkip = localStorage.getItem("modulo") !== modulo ? 0 : localStorage.getItem("filtroAlunosSkip") || 0;

  const [escolaridade, setEscolaridade] = useState(filtroAlunosEscolaridade);
  const [unidade, setUnidade] = useState(filtroAlunosUnidade);
  const [turno, setTurno] = useState(filtroAlunosTurno);
  const [turma, setTurma] = useState(filtroAlunosTurma);
  const [matriculado, setMatriculado] = useState(filtroAlunosMatriculados);
  const [transporte, setTransporte] = useState(filtroAlunosTransporte);
  const [skip, setSkip] = useState(filtroAlunosSkip);
  const [limit, setLimit] = useState(filtroAlunosLimit);
  const [renovacao, setRenovacao] = useState(filtroAlunosRenovacao);
  const [anoLetivo, setAnoLetivo] = useState(filtroAlunosAnoLetivo);

  const onMenuOpen = () => setIsOpen(true);
  const onMenuClose = () => setIsOpen(false);
  const [isOpen, setIsOpen] = useState(false);

  function salvarAlunosFiltro() {
    // Objeto com os valores a serem salvos
    const alunosFiltro = {
      unidade: unidade,
      escolaridade: escolaridade,
      turno: turno,
      turma: turma,
      matriculado: matriculado,
      renovacao: renovacao,
      pesquisarAluno: pesquisarAluno,
      pesquisaCPF: pesquisaCPF,
      pesquisarResponsavel: pesquisarResponsavel,
      skip: skip,
      limit: limit,
      anoLetivo: anoLetivo,
      transporte: transporte,
    };
  
    // Salvar no localStorage como uma string JSON
    localStorage.setItem('alunosfiltro', JSON.stringify(alunosFiltro));
  }

  const { isError, isLoading, refetch } = useQuery(
    "alunos",
    () =>
      getAlunosFiltro(
        unidade,
        escolaridade,
        turno,
        turma,
        matriculado,
        renovacao,
        pesquisarAluno,
        pesquisaCPF,
        pesquisarResponsavel,
        skip,
        limit,
        anoLetivo,
        transporte
      ),
    {
      retry: 0,
      enabled: false,
      onSuccess: (data) => {
        // salvarAlunosFiltro();
        setData(data);
        setTotalAlunos(data.total);
      },
    }
  );

  useEffect(() => {
    refetchUnidades();
  }, [unidade]);

  const { isLoading: loadingUnidades, refetch: refetchUnidades } = useQuery(
    "getUnidades",
    () => getUnidades(),
    {
      enabled: true,
      retry: true,
      onSuccess: (data) => {
        var dados = [{ value: 0, label: "" }];
        data.forEach((item) => {
          dados.push({ value: item.id, label: item.nome });
        });

        setUnidades(dados);
      },
    }
  );

  async function clickImprimir(alunoId, nomeAluno) {
    if (alunoId == 0) {
      toast.warn("Selecione um trimestre e um aluno para impressão!");
      return;
    }
    try {
      const pdf = await imprimirFichaIndividual(alunoId);
      if (pdf === undefined) {
        toast.error("Não foi possível gerar o relatório.");
        return;
      }
      let nomeArquivo = `FICHA_INDIVIDUAL_${nomeAluno}.pdf`;
      handleDownloadPDF(pdf, nomeArquivo);
    } catch (error) {
      toast.error("Não foi possível gerar o relatório.");
      console.error("Erro ao gerar o relatório:", error);
    }
  }

  const {
    data: escolaridades,
    isLoading: loadingEscolaridades,
    refetch: refetchEscolaridades,
  } = useQuery(
    "getEscolaridades",
    () =>
      unidade !== null && unidade > 0
        ? getEscolaridadesUnidade(unidade, anoLetivo)
        : todasEscolaridades(),
    {
      retry: 0,
      enabled: false,
    }
  );

  const {
    data: turnos,
    isLoading: loadingTurnos,
    refetch: refetchTurnos,
  } = useQuery(
    "getTurnos",
    () =>
      unidade !== null && unidade != 0 && escolaridade !== 0
        ? getTurnosUnidadeEscolaridade(unidade, escolaridade, anoLetivo)
        : todosTurnos(),
    {
      retry: 0,
      enabled: false,
    }
  );

  const {
    data: turmas,
    isLoading: loadingTurmas,
    refetch: refetchTurmas,
  } = useQuery(
    "getTurmas",
    () =>
      unidade !== null && escolaridade !== null && turno !== null
        ? getTurmasUnidadeEscolaridadeTurno(
            unidade,
            escolaridade,
            turno,
            anoLetivo
          )
        : null,
    {
      retry: 0,
      enabled: false,
    }
  );

  const handleFichaAvaliacao = (id, escolaridade) => {
    debugger
    localStorage.setItem("fichaAvaliacaoEscolaridade", escolaridade);
    navigate(fichaIndividualUrl + id);
  };

  const ActionsMenu = ({ row }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          Ações
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ marginTop: 0 }}>
          {row.original.turmaID !== 0 && habilitarExcluir && (
            <Dropdown.Item
              className="dropdown-item-danger d-flex"
              onClick={() =>
                navigate(evadirUrl, {
                  state: {
                    id: row.original.id,
                    aluno: {
                      nome: row.original.nome,
                      responsavel: row.original.responsavel.nome,
                      escolaridade: row.original.escolaridade,
                      escolaridadeId: row.original.escolaridadeId,
                    },
                  },
                })
              }
            >
              <i className="material-icons md-delete pe-2"></i> Excluir
            </Dropdown.Item>
          )}
          {habilitaEditar && (
            <Dropdown.Item
              className="dropdown-item-secondary d-flex"
              onClick={() =>
                navigate(editarUrl + row.original.id, {
                  state: {
                    id: row.original.pessoaID,
                    aluno: {
                      nome: row.original.nome,
                      cpf: row.original.cpf,
                      responsavel: row.original.responsavel.nome,
                      status: row.original.status,
                    },
                  },
                })
              }
            >
              <i className="material-icons md-edit_note pe-2"></i> Editar
            </Dropdown.Item>
          )}
          {habilitaNotificar && (
            <Dropdown.Item
              className="dropdown-item-warning d-flex"
              onClick={() => handleOpenModal(row.original.id)}
            >
              <i className="material-icons md-priority_high pe-2"></i>{" "}
              Notificar
            </Dropdown.Item>
          )}
          {/* {process.env.REACT_APP_SECRETARIA_MUNICIPAL !== "SEDUC" &&
            habilitaFichaIndividual && (
              <Dropdown.Item
                className="dropdown-item-success d-flex"
                onClick={() =>
                  clickImprimir(row.original.id, row.original.nome)
                }
              >
                <i className="material-icons pe-2">person</i> Ficha Individual
              </Dropdown.Item>
            )} */}
          {process.env.REACT_APP_SECRETARIA_MUNICIPAL !== "SEDUC" &&
            habilitaFichaIndividual && (
              <Dropdown.Item
                className="dropdown-item-success d-flex"
                onClick={() => handleFichaAvaliacao(row.original.id, row.original.escolaridade)}
              >
                <i className="material-icons md-person pe-2"></i> Ficha Individual
              </Dropdown.Item>
            )}
          {process.env.REACT_APP_SECRETARIA_MUNICIPAL === "SEDUC" &&
            row.original.escolaridade.includes("FASE") &&
            row.original.renovacao === false &&
            habilitaAprovacaoEJA && (
              <Dropdown.Item
                className="dropdown-item-primary d-flex"
                onClick={() =>
                  navigate(aprovacaoEjaUrl, {
                    state: {
                      id: row.original.id,
                      nome: row.original.nome,
                      escolaridade: row.original.escolaridade,
                      unidade: row.original.unidade,
                      unidadeId: row.original.unidadeId,
                    },
                  })
                }
              >
                <i className="material-icons md-add pe-2"></i> Aprovação
                EJA
              </Dropdown.Item>
            )}
          {habilitaAdicionaFoto && (
            <Dropdown.Item
              className="dropdown-item d-flex"
              style={{ color: "#9c00ab" }}
              onClick={() =>
                navigate(cadastrarFotoUrl, {
                  state: {
                    alunoId: row.original.id,
                  },
                })
              }
            >
              <i className="material-icons md-camera_alt pe-2"></i> Foto
            </Dropdown.Item>
          )}

          {habilitaImprimirCarterinha && (
            <Dropdown.Item
              className="dropdown-item-primary d-flex"
              onClick={() =>
                navigate(carteirinhaUrl, {
                  state: {
                    id: row.original.id,
                    aluno: {
                      escolaridade: row.original.escolaridade,
                      unidade: row.original.unidade,
                      turma: row.original.turma,
                      turno: row.original.turno,
                    },
                  },
                })
              }
            >
              <i className="material-icons md-badge pe-2"></i> Carteirinha
            </Dropdown.Item>
          )}
          {habilitaFichaTransporte && (
            <Dropdown.Item
              className="dropdown-item d-flex"
              onClick={() =>
                navigate(fichaTransporteUrl, {
                  state: {
                    id: row.original.id,
                    aluno: {
                      escolaridade: row.original.escolaridade,
                      unidade: row.original.unidade,
                      turma: row.original.turma,
                      turno: row.original.turno,
                      responsavel: row.original.responsavel.nome,
                      responsavelId: row.original.responsavel.id,
                      responsavelCPF: row.original.responsavel.cpf,
                      responsavelTelefone: row.original.responsavel.telefone,
                      responsavelEmail: row.original.responsavel.email,
                      responsavelParentesco:
                        row.original.responsavel.parentesco,
                      responsavelEndereco: row.original.responsavel.endereco,
                      responsavelBairro: row.original.responsavel.bairro,
                      responsavelCidade: row.original.responsavel.cidade,
                      responsavelUF: row.original.responsavel.uf,
                    },
                  },
                })
              }
            >
              <i className="material-icons md-airport_shuttle pe-2"></i> Ficha de
              Transporte
            </Dropdown.Item>
          )}
          {row.original.renovacao && (
            <Dropdown.Item
              className="dropdown-item-danger d-flex"
              onClick={() =>
                navigate(renovacaoUrl + row.original.id)
              }
            >
             <i className="material-icons md-refresh pe-2"></i> Protocolo Renovação
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const columns = useMemo(
    () => [
      { Header: "#", accessor: "id" },
      { Header: "Nome", accessor: "nome" },
      { Header: "Status", accessor: "status" },
      {
        Header: "Nascimento",
        accessor: "nascimento",
        Cell: ({ row }) =>
          row.original.nascimento.split("T")[0].split("-").reverse().join("/"),
      },
      { Header: "CPF", accessor: "cpf" },
      { Header: "Matrícula", accessor: "numeroMatricula" },
      { Header: "Unidade", accessor: "unidade" },
      { Header: "Escolaridade", accessor: "escolaridade" },
      {
        Header: "PcD",
        accessor: "pcd",
        Cell: ({ row }) => (
          <span
            className={`badge bg-${row.original.pne ? "success" : "danger"}`}
          >
            {row.original.pne ? "SIM" : "NÃO"}
          </span>
        ),
      },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => <ActionsMenu row={row} />,
      },
    ],
    [navigate]
  );

  useEffect(() => {
    const modulo = gestao.gestao ? "gestao" : "unidades";
    
    if (localStorage.getItem('modulo')  === null){
        localStorage.setItem("modulo", modulo);
    }
    // localStorage.setItem("modulo", modulo);
    if(localStorage.getItem('modulo') !== modulo && localStorage.getItem('modulo') !== null){
      // setSkip(0);
      // setLimit(20);
      localStorage.setItem("filtroAlunosLimit", 20);
      localStorage.setItem("filtroAlunosSkip", 0);
      localStorage.setItem('currentPage', 1)
      localStorage.setItem("modulo", modulo);
    }
    else{
      localStorage.setItem("filtroAlunosLimit", limit);
      localStorage.setItem("filtroAlunosSkip", skip);
    }
    refetch();
    refetchEscolaridades();
    refetchTurnos();
    refetchTurmas();

    
  }, [refetch, limit, skip]);

  const handleSearch = () => {
    refetch();
  };

  const handleDownloadExcel = async () => {
    await getRelatorioAlunos(
      unidade,
      escolaridade,
      turno,
      turma,
      matriculado,
      renovacao,
      pesquisarAluno,
      pesquisaCPF,
      pesquisarResponsavel,
      skip,
      50000,
      0,
      transporte
    );
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseB = () => {
    setAnchorEl(null);
  };

  const unidadeSelecionada = unidades?.find((x) => x.value === unidade)?.label;
  const escolaridadeSelecionada = escolaridades?.find(
    (x) => x.id == escolaridade
  )?.descricao;
  const turnoSelecionado = turnos?.find((x) => x.id == turno)?.descricao;
  const turmaSelecionada = turmas?.data?.find((x) => x.id == turma)?.descricao;
  const matriculadoSelecionado =
    matriculado == 1 ? "SIM" : matriculado == 2 ? "NÃO" : "TODOS";
  const transporteSelecionado =
    transporte == 1 ? "SIM" : transporte == 2 ? "NÃO" : "TODOS";

  return (
    <BaseComponent>
      <Breadcrumb
        title={"Alunos"}
        itens={[BasePath, "Alunos", "Lista Geral"]}
        hasExcelButton={habilitaExportarAluno}
        onClickDownload={() => handleDownloadExcel()}
        actions={{
          link: adicionarUrl,
          text: "Novo Aluno",
        }}
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar Aviso | Aluno</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="form-group" style={{ width: "100%" }}>
              <label htmlFor="titulo" className="form-label">
                TÍTULO
              </label>
              <textarea
                onChange={(e) => {
                  setTitulo(e.target.value);
                }}
                value={titulo}
                className="form-control"
                id="titulo"
                rows="1"
                placeholder="REUNIÃO"
              ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="form-group" style={{ width: "100%" }}>
              <label htmlFor="descricao" className="form-label">
                DESCRIÇÃO
              </label>
              <textarea
                onChange={(e) => {
                  setDescricao(e.target.value);
                }}
                value={descricao}
                className="form-control"
                id="descricao"
                rows="3"
                placeholder="REUNIÃO DE PAIS DIA ..."
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={adicionarAviso}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginTop: 10,
          marginBottom: 20,
        }}
      >
        <div
          className="form-group"
          style={{ width: "100%", maxWidth: 390, marginRight: 10 }}
        >
          <input
            type="text"
            className="form-control"
            placeholder="CPF"
            value={pesquisaCPF}
            onChange={(e) => setpesquisaCPF(e.target.value)}
          />
        </div>

        <div
          className="form-group"
          style={{ width: "100%", maxWidth: 395, marginRight: 10 }}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Nome do Aluno"
            value={pesquisarAluno}
            onChange={(e) => setPesquisaAluno(e.target.value)}
          />
        </div>

        <div className="form-group" style={{ width: "100%", maxWidth: 395 }}>
          <input
            type="text"
            className="form-control"
            placeholder="Nome Reponsável"
            value={pesquisarResponsavel}
            onChange={(e) => setPesquisarResponsavel(e.target.value)}
          />
        </div>
        <div
          className="form-group"
          style={{
            marginTop: 6,
            marginLeft: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <button
            className="btn btn-sm btn-primary"
            onClick={() => handleSearch()}
          >
            <i className="material-icons md-search"></i> Buscar
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div
              style={{
                margin: "10px",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <ButtonComponet
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                startIcon={<FilterAltOutlined />}
                variant="contained"
                size="large"
                color="info"
              >
                Filtro
              </ButtonComponet>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseB}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <div style={{ padding: "20px", width: "400px" }}>
                  <div className="form-group">
                    <label htmlFor="unidadeId" className="form-label">
                      Unidades
                    </label>
                    <Select
                      aria-labelledby="aria-label"
                      inputId="aria-example-input"
                      name="aria-live-color"
                      onMenuOpen={onMenuOpen}
                      onMenuClose={onMenuClose}
                      options={unidades}
                      isDisabled={disableSeletorUnidades}
                      value={unidades.find((x) => x.value == unidade)}
                      placeholder=""
                      onChange={async (e) => {
                        setUnidade(e.value);
                        await setEscolaridade(0);
                        await setTurno(0);
                        await setTurma(0);
                        localStorage.setItem("filtroAlunosUnidade", e.value);
                        localStorage.setItem("filtroAlunosEscolaridade", 0);
                        localStorage.setItem("filtroAlunosTurno", 0);
                        localStorage.setItem("filtroAlunosTurma", 0);
                        await refetchEscolaridades();
                        await refetchTurnos();
                        await refetchTurmas();
                        await refetch();
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="anoLetivo" className="form-label">
                      Ano Letivo
                    </label>
                    <select
                      className="form-select"
                      id="anoLetivo"
                      value={anoLetivo}
                      onChange={async (e) => {
                        await setAnoLetivo(e.target.value);
                        localStorage.setItem(
                          "filtroAlunosAnoLetivo",
                          e.target.value
                        );
                        refetch();
                      }}
                    >
                      <option value={0}></option>
                      {!gestao.docente &&
                        process.env.REACT_APP_SECRETARIA_MUNICIPAL !==
                          "SEMED" && <option value={2023}>2023</option>}
                      <option value={2024}>2024</option>
                      {/* <option value={2025}>2025</option> */}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="escolaridadeID" className="form-label">
                      Escolaridades
                    </label>
                    <select
                      className="form-select"
                      id="escolaridadeID"
                      value={escolaridade}
                      onChange={async (e) => {
                        await setEscolaridade(e.target.value);
                        await setTurno(0);
                        await setTurma(0);
                        await refetchTurnos(0);
                        await refetchTurmas(0);
                        await localStorage.setItem(
                          "filtroAlunosEscolaridade",
                          e.target.value
                        );
                        await localStorage.setItem("filtroAlunosTurno", 0);
                        await localStorage.setItem("filtroAlunosTurma", 0);
                        await refetch();
                      }}
                    >
                      {loadingEscolaridades ? (
                        <option value={0}></option>
                      ) : (
                        <>
                          <option value={0}></option>
                          {escolaridades &&
                            escolaridades.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.descricao}
                              </option>
                            ))}
                        </>
                      )}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="turnoID" className="form-label">
                      Turnos
                    </label>
                    <select
                      className="form-select"
                      id="turnoID"
                      value={turno}
                      onChange={async (e) => {
                        await setTurno(e.target.value);
                        localStorage.setItem(
                          "filtroAlunosTurno",
                          e.target.value
                        );
                        setTurma(0);
                        localStorage.setItem(
                          "filtroAlunosTurma",
                          e.target.value
                        );
                        refetchTurmas();
                        refetch();
                      }}
                    >
                      {loadingTurnos ? (
                        <option value={0}></option>
                      ) : (
                        <>
                          <option value={0}></option>
                          {turnos &&
                            turnos.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.descricao}
                              </option>
                            ))}
                        </>
                      )}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="turmaID" className="form-label">
                      Turmas
                    </label>
                    <select
                      className="form-select"
                      id="turmaID"
                      value={turma}
                      onChange={async (e) => {
                        await setTurma(e.target.value);
                        localStorage.setItem(
                          "filtroAlunosTurma",
                          e.target.value
                        );
                        refetch();
                      }}
                    >
                      {loadingTurmas ? (
                        <option value=""></option>
                      ) : (
                        <>
                          <option value={0}></option>
                          {turmas?.data &&
                            turmas?.data.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.descricao}{" "}
                                {unidade == 0
                                  ? " - " +
                                      unidades.find(
                                        (unidade) =>
                                          unidade.value === item.unidadeId
                                      )?.label || ""
                                  : ""}
                              </option>
                            ))}
                        </>
                      )}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="matriculados" className="form-label">
                      Matriculados?
                    </label>
                    <select
                      className="form-select"
                      id="matriculados"
                      value={matriculado}
                      onChange={async (e) => {
                        await setMatriculado(e.target.value);
                        localStorage.setItem(
                          "filtroAlunosMatriculados",
                          e.target.value
                        );
                        refetch();
                      }}
                    >
                      <>
                        <option value={0}></option>
                        <option value={1}>SIM</option>
                        <option value={2}>NÃO</option>
                      </>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="transporte" className="form-label">
                      Transporte?
                    </label>
                    <select
                      className="form-select"
                      id="transporte"
                      value={transporte}
                      onChange={async (e) => {
                        await setTransporte(e.target.value);
                        localStorage.setItem(
                          "filtroAlunosTransporte",
                          e.target.value
                        );
                        refetch();
                      }}
                    >
                      <>
                        <option value={0}></option>
                        <option value={1}>SIM</option>
                        <option value={2}>NÃO</option>
                      </>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="renovacao" className="form-label">
                      Renovação?
                    </label>
                    <select
                      className="form-select"
                      id="renovacao"
                      value={renovacao}
                      onChange={async (e) => {
                        await setRenovacao(e.target.value);
                        localStorage.setItem(
                          "filtroAlunosRenovacao",
                          e.target.value
                        );
                        refetch();
                      }}
                    >
                      <>
                        <option value={0}></option>
                        <option value={1}>SIM</option>
                        <option value={2}>NÃO</option>
                      </>
                    </select>
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Chip
                      sx={{ marginTop: 2 }}
                      color="error"
                      label="Limpar filtros"
                      onClick={async () => {
                        if (!disableSeletorUnidades) {
                          await setUnidade(0);
                          await localStorage.removeItem("filtroAlunosUnidade");
                        }
                        await setAnoLetivo(0);
                        await localStorage.removeItem("filtroAlunosAnoLetivo");
                        await setEscolaridade(0);
                        await localStorage.removeItem(
                          "filtroAlunosEscolaridade"
                        );
                        await setTurno(0);
                        await localStorage.removeItem("filtroAlunosTurno");
                        await setTurma(0);
                        await localStorage.removeItem("filtroAlunosTurma");
                        await setMatriculado(0);
                        await localStorage.removeItem(
                          "filtroAlunosMatriculados"
                        );
                        await setTransporte(0);
                        await localStorage.removeItem("filtroAlunosTransporte");
                        await refetch();
                      }}
                    />
                  </div>
                </div>
              </Menu>
              {unidade > 0 ? (
                <Chip
                  color="info"
                  label={`UNIDADE: ${unidadeSelecionada}`}
                  variant="outlined"
                  {...(!disableSeletorUnidades && {
                    onDelete: async () => {
                      await setUnidade(0);
                      await setEscolaridade(0);
                      await setTurno(0);
                      await setTurma(0);
                      localStorage.setItem("filtroAlunosUnidade", 0);
                      localStorage.removeItem("filtroAlunosEscolaridade");
                      localStorage.removeItem("filtroAlunosTurno");
                      localStorage.removeItem("filtroAlunosTurma");
                      refetch();
                      refetchEscolaridades();
                    },
                  })}
                />
              ) : null}
              {anoLetivo > 0 ? (
                <Chip
                  color="info"
                  label={`ANO LETIVO: ${anoLetivo}`}
                  variant="outlined"
                  onDelete={async () => {
                    await setAnoLetivo(0);
                    await localStorage.removeItem("filtroAlunosAnoLetivo");
                    await refetch();
                  }}
                />
              ) : null}
              {escolaridade > 0 ? (
                <Chip
                  color="info"
                  label={`ESCOLARIDADE: ${escolaridadeSelecionada}`}
                  variant="outlined"
                  onDelete={async () => {
                    await setEscolaridade(0);
                    await setTurno(0);
                    await setTurma(0);
                    await refetchTurnos(0);
                    await refetchTurmas(0);
                    await localStorage.removeItem("filtroAlunosEscolaridade");
                    await localStorage.removeItem("filtroAlunosTurno");
                    await localStorage.removeItem("filtroAlunosTurma");
                    await refetch();
                  }}
                />
              ) : null}
              {turno > 0 ? (
                <Chip
                  color="info"
                  label={`TURNO: ${turnoSelecionado}`}
                  variant="outlined"
                  onDelete={async () => {
                    await setTurno(0);
                    await localStorage.removeItem("filtroAlunosTurno");
                    await setTurma(0);
                    await localStorage.removeItem("filtroAlunosTurma");
                    await refetchTurmas();
                    await refetch();
                  }}
                />
              ) : null}
              {turma > 0 ? (
                <Chip
                  color="info"
                  label={`TURMA: ${turmaSelecionada}`}
                  variant="outlined"
                  onDelete={async () => {
                    await setTurma(0);
                    localStorage.removeItem("filtroAlunosTurma");
                    await refetch();
                  }}
                />
              ) : null}
              {matriculado > 0 ? (
                <Chip
                  color="info"
                  label={`MATRICULADO: ${matriculadoSelecionado}`}
                  variant="outlined"
                  onDelete={async () => {
                    await setMatriculado(0);
                    localStorage.removeItem("filtroAlunosMatriculados");
                    await refetch();
                  }}
                />
              ) : null}
              {transporte > 0 ? (
                <Chip
                  color="info"
                  label={`TRANSPORTE: ${transporteSelecionado}`}
                  variant="outlined"
                  onDelete={async () => {
                    await setTransporte(0);
                    localStorage.removeItem("filtroAlunosTransporte");
                    await refetch();
                  }}
                />
              ) : null}
              {renovacao > 0 ? (
                <Chip
                  color="info"
                  label={`RENOVAÇÃO: ${renovacao == 1 ? "SIM" : "NÃO"}`}
                  variant="outlined"
                  onDelete={async () => {
                    await setRenovacao(0);
                    localStorage.removeItem("filtroAlunosRenovacao");
                    await refetch();
                  }}
                />
              ) : null}
            </div>
            <Table
              columns={columns}
              data={data?.data ? data?.data : []}
              hasPagination
              limit={limit}
              setLimit={setLimit}
              skip={skip}
              setSkip={setSkip}
              totalItems={totalAlunos}
              hiddenColluns={[
                "escolaridade",
                "status",
                gestao.docente ? "actions" : "",
              ]}
              overflowUnset={true}
              validatePage={true}
            />
          </div>
        </div>
      </div>
    </BaseComponent>
  );
};

export default AlunosPage;

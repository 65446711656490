import api from "..";

async function getDiarioClasse(unidadeId = 0, servidorId = 0, skip = 0, limit = 20) {
  return await api
    .get(`gestao-unidades/diarioclasse?unidadeId=${unidadeId}&servidorId=${servidorId}&skip=${skip}&limit=${limit}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function createDiarioClasse(data) {
  return await api
    .post('gestao-unidades/diarioclasse', data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function updateDiarioClasse({ id, data }) {
  return await api
    .put(`gestao-unidades/diarioclasse/${id}`, data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function deleteDiarioClasse(id) {
  return await api
    .delete(`gestao-unidades/diarioclasse/${id}`)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function createDiarioClasseAvaliacao(data) {
  return await api
    .post('gestao-unidades/DiarioClasse/avaliacoes', data)
    .then(async (res) => {
      return res.data;
    });
}

async function deleteDiarioClasseAvaliacao(id) {
  return await api
    .delete('gestao-unidades/DiarioClasse/avaliacoes/' + id)
    .then(async (res) => {
      return res.data;
    });
}

async function listaDiarioClasseAvaliacao(id, ciclo) {
  return await api
    .get(`gestao-unidades/DiarioClasse/avaliacoes/${id}/${ciclo}`)
    .then(async (res) => {
      return res.data;
    });
}

async function getAvaliacoes(unidade, escolaridade, turno, turma, periodo, ciclo) {
  return await api
    .get(`gestao-unidades/DiarioClasse/avaliacoesAlunos/unidade/${unidade}/escolaridade/${escolaridade}/turno/${turno}/turma/${turma}/periodo/${periodo}/ciclo/${ciclo}`)
    .then(async (res) => {
      console.log(res.data.data);
      return res.data.data;
    });
};

async function getAlunosParaAvaliacoes(unidade, escolaridade, turno, turma, avaliacao, disciplina) {
  return await api
    .get(`gestao-unidades/DiarioClasse/alunosParaAvaliacao/unidade/${unidade}/escolaridade/${escolaridade}/turno/${turno}/turma/${turma}/avaliacao/${avaliacao}/disciplina/${disciplina}`)
    .then(async (res) => {
      return res.data;
    });
};

async function getDisciplina() {
  return await api
    .get(`gestao-unidades/DiarioClasse/disciplinas`)
    .then(async (res) => {
      return res.data;
    });
};

async function postLanctos(data) {
  return await api
    .post('gestao-unidades/DiarioClasse/addLancto', data)
    .then(async (res) => {
      return res.data;
    });
}

async function getQtdAvaliacoes(idDiarioClasse, ciclo) {
  return await api
    .get(`gestao-unidades/DiarioClasse/avaliacoes/qtdavaliacoes/idDiarioClasse/${idDiarioClasse}/ciclo/${ciclo}`)
    .then(async (res) => {
      return res.data;
    });
};

async function getVisualizacao(unidade, escolaridade, turno, turma, periodo, disciplina, ciclo) {
  return await api
    .get(`gestao-unidades/DiarioClasse/visualizacao/unidade/${unidade}/escolaridade/${escolaridade}/turno/${turno}/turma/${turma}/periodo/${periodo}/disciplina/${disciplina}/ciclo/${ciclo}`)
    .then(async (res) => {
      return res.data;
    });
};



export {
    getDiarioClasse,
    createDiarioClasse,
    updateDiarioClasse,
    deleteDiarioClasse,
    createDiarioClasseAvaliacao,
    deleteDiarioClasseAvaliacao,
    listaDiarioClasseAvaliacao,
    getAvaliacoes,
    getAlunosParaAvaliacoes,
    getDisciplina,
    postLanctos,
    getQtdAvaliacoes,
    getVisualizacao
};
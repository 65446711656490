import React, { useState, useEffect } from "react";
import moment from "moment";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Box,
  Tabs,
  Tab,
  Button,
  IconButton,
  MenuItem,
  Typography,
  Divider,
  Pagination,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import DraftsIcon from "@mui/icons-material/Drafts";
import { store } from "../../store/index";
import api from "../../services/index";

export default function Notifications({ notifications, isChat = false }) {
  const currentUser = store.getState()["user"]["user"];
  const [activeTab, setActiveTab] = useState(isChat ? "chat" : "notificacao");
  const [filter, setFilter] = useState("nao-lidas");
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState(null);
  const [dataTab, setDataTab] = useState([]);  

  const navigate = useNavigate();

  useEffect(() => {
    setData(notifications?.data?.data);
    setDataTab(notifications?.data?.data?.notificacoes || []);
  }, [notifications]);



  useEffect(() => {
    if (data) {
      let filteredData = [];
      if (activeTab === "notificacao") {
        filteredData =
          filter === "lidas"
            ? data.notificacoes.filter((e) => e.lida)
            : data.notificacoes.filter((e) => !e.lida);
      } else if (activeTab === "aviso") {
        filteredData =
          filter === "lidas"
            ? data.avisos.filter((e) => e.lida)
            : data.avisos.filter((e) => !e.lida);
      } else if (activeTab === "chat") {
        filteredData =
          filter === "lidas"
            ? data.notificacoesChat.filter((e) => e.lida)
            : data.notificacoesChat.filter((e) => !e.lida);
      }

      setDataTab(filteredData.slice(0, filteredData.length));
    }
  }, [filter, activeTab, data]);

  const updateNotificationRead = async (id, isRead) => {
    if (isChat) {
      navigate("/chat");
      return;
    }
    if (!isRead) {
      await api.put(`/shared/notificacao/${id}/ConfirmarLeitura`);
      const result = await api.get(`/shared/notificacao/perfil`);
      setDataTab(result?.data?.notificacoes || []);
      window.location.reload();
    }
  };

  const handleTabChange = (event, newValue) => setActiveTab(newValue);

  return (
    <Box
      sx={{
        p: 1,
        width: 400,
        height: 480,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isChat ? (
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Chat" value="chat" />
        </Tabs>
      ) : (
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Notificação" value="notificacao" />
          <Tab label="Aviso" value="aviso" />
        </Tabs>
      )}

      <Box sx={{ display: "flex", justifyContent: "center", my: 1 }}>
        <Button
          variant={filter === "nao-lidas" ? "contained" : "outlined"}
          onClick={() => setFilter("nao-lidas")}
          sx={{ mx: 0.5 }}
        >
          Não lidas
        </Button>
        <Button
          variant={filter === "lidas" ? "contained" : "outlined"}
          onClick={() => setFilter("lidas")}
          sx={{ mx: 0.5 }}
        >
          Lidas
        </Button>
      </Box>

      <Divider sx={{ my: 1 }} />

      {dataTab == 0 ? (
        <Typography variant="body1" textAlign="center">
          Nenhuma notificação
        </Typography>
      ) : (
        <>
          {dataTab
            .slice((currentPage - 1) * 5, (currentPage - 1) * 5 + 5)
            .map((e) => (
              <MenuItem
                key={e.id}
                onClick={() => updateNotificationRead(e.id, e.lida)}
                sx={{
                  bgcolor: e.lida ? "grey.100" : ".light",
                  "&:hover": { bgcolor: e.lida ? "grey.200" : "primary.main" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      maxWidth: "250px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {e.titulo}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {moment(e.criacao, "YYYY/MM/DD").format("DD-MM-YYYY")}
                  </Typography>
                </Box>
                <IconButton size="small">
                  {e.lida ? <DraftsIcon /> : <EmailIcon />}
                </IconButton>
              </MenuItem>
            ))}
        </>
      )}

      <Divider sx={{ my: 1 }} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mt: "auto",
          gap: 1,
        }}
      >
        {isChat ? (
          <NavLink to={`/chat`} style={{ textDecoration: "none" }}>
            <Button variant="contained">Abrir Chat</Button>
          </NavLink>
        ) : (
          <NavLink
            to={`/perfil/${currentUser}?notificacoes`}
            style={{ textDecoration: "none" }}
          >
            <Button variant="contained">Ver todas notificações</Button>
          </NavLink>
        )}
        <Pagination
          count={Math.ceil(dataTab.length / 5)}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
          color="primary"
        />
      </Box>
    </Box>
  );
}

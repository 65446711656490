import api from "../..";

async function postProcesso(data) {
  return await api
    .post('patrimonio/processos', data)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
};

async function postAnexo(data) {
  return await api
    .post('arquivo/contratos', data)
    .then(async (res) => {
      const data = res.data.data;
      return data;
    });
};

async function getProcessos() {
  return await api
    .get('patrimonio/processos')
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function getProcesso(id) {
  return await api
    .get(`patrimonio/processos/${id}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function putProcesso({ id, data }) {
  return await api
    .put(`patrimonio/processos/${id}`, data)
    .then(async (res) => {
      const data = res.data.data;
      return data;
    })
}

async function deleteProcesso(id) {
  return await api
    .delete(`patrimonio/processos/${id}`)
    .then(async (res) => {
      const data = res.data.data;
      return data;
    })
}

export {
  postProcesso,
  postAnexo,
  getProcessos,
  getProcesso,
  putProcesso,
  deleteProcesso
}
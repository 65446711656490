import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from "../../../components/breadcrumb";
import BaseGestao from "../../gestao/baseGestao";
import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import urlsGestao from "../../gestao/urlsGestao";
import urlsGestaoUnidades from "../../gestao-unidades/urlsGestaoUnidades";
import { createQuadro } from "../../../services/quadro-horario";
import { useQuery } from "react-query";

const NovoQuadroHorarioPage = (gestao) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();

  const homeQuadro = gestao.gestao ? urlsGestao.quadroHorario : urlsGestaoUnidades.quadroHorario;
  const BaseComponent = gestao.gestao ? BaseGestao : BaseGestaoUnidades;
  const BasePath = gestao.gestao ? 'Gestão' : 'Gestão Unidades';

  const { register, handleSubmit, setValue, getValues, formState: { isValid, errors } } = useForm({
    mode: "onChange", defaultValues: {
      turmaId: id,
      description: '',
    }
  });


  useEffect(() => {
    setValue('turmaId', id);
    setValue('turmaDescricao', state.nomeTurmaCadastro);
  }, []);

  const { error, isLoading, refetch } = useQuery('createQuadro',
    () => createQuadro({ turmaId: parseInt(id), descricao: getValues('description').toUpperCase() }), {
    retry: 0,
    enabled: false,
    onSuccess: () => {
      toast.success('Quadro de horários criado com sucesso!');
      navigate(homeQuadro);
    },
    onError: () => {
      toast.error('Algo deu errado, tente novamente.');
    }
  }
  )

  const onSubmit = () => {
    // mutate({ id, data });
    refetch();
  };

  const handleCancel = () => {
    navigate(homeQuadro);
  }

  return (
    <BaseComponent>
      <Breadcrumb title={'Novo Quadro de Horários'} itens={[BasePath, 'Acadêmico', 'Quadro de Horários']} />
      <form id='form' onSubmit={handleSubmit(onSubmit)}>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className="card-body">
                <>
                  <div className='row'>
                    {/* <div className='col-2'>
                      <label htmlFor="turmaId" className="form-label">Turma</label>
                      <input type="text" className="form-control" id="turmaId" readOnly
                        {...register("turmaId", { required: true })} />
                      {errors.turmaId?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div> */}

                    <div className='col-6'>
                      <label htmlFor="turmaDescricao" className="form-label">Turma</label>
                      <input type="text" className="form-control" id="turmaDescricao" readOnly
                        {...register("turmaDescricao", { required: true })} />
                      {errors.turmaId?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>


                    <div className='col-6'>
                      <label htmlFor="description" className="form-label">Descrição</label>
                      <input type="text" className="form-control" id="description"
                        {...register("description", { required: true })} />
                      {errors.description?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>
                  </div>

                </>
              </div>
              <div className='card-footer text-end'>
                <button type='button' className='btn btn-warning' onClick={() => handleCancel()} style={{ marginRight: '8px' }}>
                  Voltar
                </button>
                <button type='submit' className='btn btn-success' disabled={!isValid}>
                  Cadastrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </BaseComponent>
  )

};

export default NovoQuadroHorarioPage;
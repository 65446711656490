import React from "react";
import { useQuery } from "react-query";
import { getServidores } from "../../../services/servidores";

const SalvarFichaFormResultadoFinal = ({ setDados }) => {
  const { data: servidoresData } = useQuery({
    queryKey: ["servidores"],
    queryFn: async () => {
      const unidadeId = localStorage.getItem("unidadeID");
      const response = await getServidores(unidadeId, 0, "", 1000);
      return response;
    },
  });

  const handleChange = (field, value) => {
    setDados((prevDados) => ({
      ...prevDados,
      [field]: value,
    }));
  };

  return (
    <div className="row">
      <div className="d-flex justify-content-center">
        <div className="d-flex flex-column align-items-center">
          <h5>Campos cusotmizáveis</h5>
          <div className="d-flex gap-2">
            <div className="col-md-2">
              <div className="form-group">
                <label>
                  Carga Horária
                  <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                </label>
                <input
                  onChange={(e) => handleChange("cargaHoraria", e.target.value)}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label>
                  Dias Letivos
                  <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                </label>
                <input
                  onChange={(e) => handleChange("diasLetivos", e.target.value)}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label>
                  Dias do mês
                  <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                </label>
                <input
                  onChange={(e) => handleChange("diasDoMes", e.target.value)}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  Servidor
                  <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                </label>
                <select
                  onChange={(e) => handleChange("servidor", e.target.value)}
                  className="form-select"
                >
                  {servidoresData?.data?.map((servidor) => (
                    <option key={servidor.id} value={servidor.nomeCompleto}>
                      {servidor.nomeCompleto}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalvarFichaFormResultadoFinal;

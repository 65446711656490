import { useMemo, useState } from 'react';
import Breadcrumb from '../../../../components/breadcrumb';
import Table from '../../../../components/table';
import BaseAdministrativo from '../../baseAdministrativo';
import { useQuery, useMutation } from 'react-query';
import { getContratos, deleteContrato } from '../../../../services/administrativo/patrimonio-contratos'
import urlsAdministrativo from '../../urlsAdministrativo';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const ControleContratos = () => {
  const [newData, setNewData] = useState();
  const navigate = useNavigate()

  const { data, refetch: refetchContratos } = useQuery('getContratos', () => getContratos(), {
    onSuccess: data => {
      setNewData(data.data);
    }
  })

  const { mutate, isLoading: loadingDelete } = useMutation(deleteContrato, {
    onSuccess: data => {
      toast.success('Contrato excluído com sucesso.')
      refetchContratos()
    },
    onError: data => {
      toast.error('Erro ao excluir contrato. Tente novamente mais tarde.')
    }
  })

  const formatData = (data) => {
    const newData = data?.split('T')[0].split('-').reverse().join('/');
    return newData;
  }


  let BRLFormat = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const formatValue = (value) => {
    if (value) {
      const newValue = `${BRLFormat.format(value)}`;
      return newValue;
    }
  }

  const columns2 = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Nº Identificador', accessor: 'identificador' },
      { Header: 'Descrição', accessor: 'descricao' },
      { Header: 'Valor', accessor: 'valor',
      Cell: ({ row }) => (
        formatValue(row.original.valor)
      )
    
    },
      {
        Header: 'Data Inicial', accessor: 'inicial',
        Cell: ({ row }) => (
          formatData(row.original.inicial)
        )
      },
      {
        Header: 'Data Final', accessor: 'final',
        Cell: ({ row }) => (
          formatData(row.original.final)
        )
      },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <button onClick={() => navigate(urlsAdministrativo.patrimonioControleContratosCadastrar + row.original.id)} className='btn btn-sm btn-primary'>
              <i className="material-icons md-edit_note"></i> visualizar
            </button>
            <button
              className='btn btn-sm btn-danger'
              style={{ marginLeft: '8px' }}
              onClick={() => {
                const confirm = window.confirm('Tem certeza que deseja excluir?')
                if (confirm) {
                  mutate(row.original.id);
                }
              }}
            >
              <i className="material-icons md-group_remove"></i> excluir
            </button>
          </>
        )
      },
    ],
    [newData, loadingDelete]
  )

  return (
    <BaseAdministrativo>
      <Breadcrumb title={'Controle de Contratos'} itens={['Administrativo', 'Patrimônio', 'Controle de Contratos']} actions={{ link: urlsAdministrativo.patrimonioControleContratosCadastrar, text: "Novo Contrato" }} />
      <div className='row'>
        <div className="mailbox-container">
          <div className="card">
            <div className="card-body">

              <Table columns={columns2} data={newData ? newData : []} />
            </div>
          </div>
        </div>
      </div>
    </BaseAdministrativo >
  )
};
export default ControleContratos;

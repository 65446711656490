import mock from "./mock";
import api from "..";

// Fetch a paginated list of books
async function getLivros(skip, limit) {
  return await api
    .get(`biblioteca?skip=${skip}&limit=${limit}`)
    .then(async (res) => {
      return res.data;
    });
}

// Fetch a book by its ID (mock implementation)
async function getLivro(id) {
  const findLivroById = mock.find((element) => element.id === id);
  return await findLivroById;
}

// Fetch a book by its ID from the API
async function getLivroById(id) {
  return await api
    .get(`biblioteca/${id}`)
    .then(async (res) => {
      return res.data;
    });
}

// Add a new book
async function postLivro(data) {
  return await api
    .post('biblioteca', data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

// Allocate a book
async function alocarLivro(data) {
  return await api
    .post('biblioteca/alocar', data)
    .then(async (res) => {
      return res;
    });
}

// Update a book by its ID (mock implementation)
async function putLivro(id, data) {
  const findLivroById = mock.find((element) => element.id === id);
  const index = mock.indexOf(findLivroById);
  mock[index] = { ...findLivroById, ...data };
  return await mock[index];
}

// Update a book by its ID in the API
async function updateLivro(id, data) {
  return await api
    .put(`biblioteca/${id}`, data)
    .then(async (res) => {
      return res.data;
    });
}

// Delete a book by its ID (mock implementation)
async function deleteLivro(id) {
  const findLivroById = mock.find((element) => element.id === id);
  const index = mock.indexOf(findLivroById);
  mock.splice(index, 1);
  return await findLivroById;
}

export {
  getLivros,
  getLivro,
  getLivroById,
  postLivro,
  putLivro,
  updateLivro,
  deleteLivro,
  alocarLivro
};

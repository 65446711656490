import React, { useRef, useEffect, useState } from "react";
import BaseGestao from "../../gestao/baseGestao";
import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import Breadcrumb from "../../../components/breadcrumb";
import { useForm } from "react-hook-form";
import { useReactToPrint } from "react-to-print";
import "./style.css";
import { Typography } from "@mui/material";
import { listarAtaResultadoFinalAnosIniciais } from "../../../services/ficha-individual";
import SalvarFichaFormResultadoFinal from "../salvarResultado/SalvarFichaFormResultadoFinal";




const AtaResultadoFinalEJAFase3A5 = ({gestao, turmaId, escolaridadeDescricao, turnoDescricao, turmaDescricao, unidadeDescricao}) => {
  const BaseComponent = gestao ? BaseGestao : BaseGestaoUnidades;
  const BasePath = gestao ? "Gestão" : "Gestão Unidades";
  const printRef = useRef(null);

  const [data, setData] = useState([]);
  const [dados, setDados] = useState({});

  useEffect(() => {
    listarAtaResultadoFinalAnosIniciais(turmaId).then((response) => {
      setData(response);
    });
  }, [turmaId]);

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    getValues,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      resultado: "",
      observacoes: "",
      diasLetivos: null,
    },
  });

  const onSubmit = () => {
    // Adicione aqui o código para salvar as alterações na ficha individual
    console.log("Salvar alterações na ficha individual");
    console.log(getValues()); // Mostra os valores do formulário
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "ATA_RESULTADOS_FINAIS_EJA_FASE_3_A_5",
  });

  return (
    <BaseComponent>
      <Breadcrumb
        title={"Ata Resultado de Avaliação EJA"}
        itens={[BasePath, "Turmas", "Atas de Resultado de Avaliação"]}
        onClickImprimir={handlePrint}
        imprimir={{ link: "fda", text: "Imprimir" }}
      />
       <div className="d-flex justify-content-center ">
        <SalvarFichaFormResultadoFinal ficha={dados} setDados={setDados} />
      </div>
      <style>
        {`
          @media print {
            @page {
              size: landscape;
            }
          }
        `}
      </style>
      <div className="ficha-container" ref={printRef}>
        <div className="d-flex justify-content-between pt-5">
          <div style={{ width: "250px" }}>
            <img
              src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`}
              width="100"
              height="100"
              alt=""
            ></img>
          </div>
          <div className="text-center">
            <h5>ESTADO DO RIO DE JANEIRO</h5>
            <h5>{process.env.REACT_APP_PREFEITURA}</h5>
            <h5>SECRETARIA MUNICIPAL DE EDUCAÇÃO</h5>
          </div>
          <div>
            <img
              src={`/assets/images/prefeituras/logo-pmspa-azul.png`}
              width="250"
              height="80"
              alt=""
            ></img>
          </div>
        </div>
        <div className="text-center">
          <h2 className="mt-3">{unidadeDescricao}, Ano Letivo: 2024</h2>
          <h3 className="mt-3">ATA RESULTADOS FINAIS - I FASE</h3>
          <h5>Total de Carga Horária: {dados?.cargaHoraria}h | Dias Letivos: {dados?.diasLetivos}</h5>
        </div>
        <div style={{ border: "2px solid black", padding: 10 }} className="my-3">
          <Typography>
            Aos {dados?.diasDoMes} dias do mês de dezembro de dois mil e vinte e quatro
            conclui-se o processo de avaliação de rendimento escolar dos alunos
            matriculados na: {escolaridadeDescricao} - 2º semestre, turma: {turmaDescricao}, turno: {turnoDescricao}, curso 
            Educação de Jovens e Adultos, deste Estabelecimento de Ensino.
          </Typography>
        </div>

        <table className="styled-table vertical-text-row">
          <thead>
            <tr>
              <th rowSpan="2">Nº</th>
              <th rowSpan="2">NOME DO ALUNO</th>
              <th rowSpan="2">
                <span>Sexo</span>
              </th>
              <th rowSpan="2">
                <span>Data de <br /> Nascimento</span>
              </th>
              <th colSpan="5">COMPONENTES CURRICULARES</th>
              <th rowSpan="2">
                <span>% <br /> Frequência</span>
              </th>
              <th rowSpan="2">
                <span>Decisão Final<br />Final</span>
              </th>
              <th rowSpan="2">
                <span>Obs.</span>
              </th>
            </tr>
            <tr>
              <th>
                <span>Língua Portuguesa</span>
              </th>
              <th>
                <span>Matemática</span>
              </th>
              <th>
                <span>Ciências</span>
              </th>
              <th>
                <span>História</span>
              </th>
              <th>
                <span>Geografia</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((aluno) => (
               <tr key={aluno.id}>
               <td>{aluno.id}</td>
               <td>{aluno.nome}</td>
               <td>{aluno.sexo}</td>
               <td>{new Date(aluno.dataNascimento).toLocaleDateString('pt-BR')}</td>
               <td>{aluno.linguaPortuguesa}</td>
               <td>{aluno.matematica}</td>
               <td>{aluno.ciencias}</td>
               <td>{aluno.historia}</td>
               <td>{aluno.geografia}</td>
               <td>{aluno.percentualFrequencia}</td>
               <td>{aluno.resultadoFinal}</td>
               <td>{aluno.observacao}</td>
             </tr>
            ))}
          </tbody>
        </table>
        <div className="mt-3">
          <Typography>
            OBS.: *Alunos avaliados por Relatório.
          </Typography>
          <Typography textAlign="end" marginTop={3}>
            São Pedro da Aldeia, {dados?.diasDoMes} de dezembro de 2024.
          </Typography>
        </div>

        <div className="signature-section">
          <div className="signature-field">
            <div className="signature-line"></div>
            <span>DIRETOR(A)</span>
          </div>
          <div className="signature-field">
            <div className="signature-line"></div>
            <span>INSPETOR(A) ESCOLAR</span>
          </div>
        </div>
      </div>
    </BaseComponent>
  );
};

export default AtaResultadoFinalEJAFase3A5;

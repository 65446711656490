import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./fale.module.css";
import {
  iaTextoResponse,
  iaAudioResponse,
  iaCardReponse,
} from "../../services/iaService";
import Quiz from "./quiz";

function EduAmigoFale() {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [chat, setChat] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cards, setCards] = useState([]);
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const [isLoadingCards, setIsLoadingCards] = useState(false);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(null);
  const [loadingAudio, setLoadingAudio] = useState(false);
  const [currentAudioIndex, setCurrentAudioIndex] = useState(null);

  const handleOpenModal = async (messageIndex) => {
    setCurrentMessageIndex(messageIndex);
    setCards([]);

    const selectedMessage = chat[messageIndex];
    if (selectedMessage && selectedMessage.text) {
      setIsLoadingCards(true);

      let cardResponse = await iaCardReponse({ message: selectedMessage.text });
      if (cardResponse?.data) {
        setCards(cardResponse?.data);
      }

      setIsLoadingCards(false);
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSendMessage = () => {
    if (message.trim()) {
      const newMessage = { user: true, text: message };
      setChat([...chat, newMessage]);
      setMessage("");
      setIsLoadingResponse(true);

      setTimeout(async () => {
        let iaResponse = await iaTextoResponse({ message: message });

        let chatResponse = {
          user: false,
          text: iaResponse?.data?.resposta,
          audioSource: "",
          isPlaying: false,
          audioInstance: null,
          isLoading: false,
        };

        setChat((prevChat) => [...prevChat, chatResponse]);
        setIsLoadingResponse(false);
        setCards([]);
        setCurrentMessageIndex(null);
      }, 1000);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  const handlePlayPauseAudio = async (index) => {
    const newChat = [...chat];
    const currentMessage = newChat[index];

    if (currentAudioIndex !== null && currentAudioIndex !== index) {
      // Pause the currently playing audio
      const previousMessage = newChat[currentAudioIndex];
      previousMessage.audioInstance?.pause();
      previousMessage.isPlaying = false;
      setCurrentAudioIndex(null);
    }

    if (!currentMessage.audioSource && !currentMessage.isLoading) {
      setLoadingAudio(true);
      currentMessage.isLoading = true;
      setChat(newChat);

      try {
        let audioResponse = await iaAudioResponse({
          message: currentMessage.text,
        });
        const audio = new Audio(
          `data:audio/mp3;base64,${audioResponse?.data?.audio}`
        );

        currentMessage.audioSource = audioResponse?.data?.audio;
        currentMessage.audioInstance = audio;
        currentMessage.isPlaying = true;
        currentMessage.isLoading = false;
        setCurrentAudioIndex(index);
        setChat([...newChat]);
        audio.play();

        setLoadingAudio(false);

        audio.onended = () => {
          currentMessage.isPlaying = false;
          setCurrentAudioIndex(null);
          setChat([...newChat]);
        };
      } catch (error) {
        console.error("Audio generation failed", error);
        currentMessage.isLoading = false;
        setLoadingAudio(false);
      }

      setChat(newChat);
    } else if (currentMessage.isPlaying) {
      currentMessage.audioInstance.pause();
      currentMessage.isPlaying = false;
      setCurrentAudioIndex(null);
      setChat(newChat);
    } else {
      currentMessage.audioInstance.play();
      currentMessage.isPlaying = true;
      setCurrentAudioIndex(index);
      setChat(newChat);
    }
  };

  console.log(loadingAudio);

  return (
    <>
      <main className={styles.main}>
        <nav className={styles.nav}>
          <div className={styles.logo}>
            <a href="/desenvolvimento/eduAmigo">eduamigo</a>
          </div>
          <div className={styles.buttonContainer}>
            <button className={`${styles.button} ${styles.buttonPurple}`}>
              Fale com Eduamigo
            </button>
          </div>
        </nav>

        {chat.length === 0 && (
          <div className={styles.hint}>
            <div className={styles.titleMessage}>
              <h2>Olá, amigo vamos começar a conversar?</h2>
              <h2>Para começar, digite sua dúvida que eu vou te responder</h2>
            </div>
            <img
              className={styles.image}
              src="/assets/images/eduAmigo/hero4.webp"
              alt=""
            />
          </div>
        )}

        {chat.length > 0 && (
          <div className={styles.chatBox}>
            {chat.map((msg, index) => (
              <div
                key={index}
                className={`${styles.message} ${
                  msg.user ? styles.userMessage : styles.iaMessage
                }`}
              >
                {!msg.user ? (
                  <img
                    className={styles.iaAvatar}
                    src="/assets/images/eduAmigo/avatar-male.webp"
                    alt=""
                    width={85}
                  />
                ) : null}
                {msg.user ? (
                  msg.text
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: msg.text }} />
                )}
                {!msg.user && (
                  <div className={styles.audioControls}>
                    {loadingAudio ? (
                      <div className="d-flex gap-2">
                        <span className={styles.playPauseButton}>
                          ⏳ Gerando áudio...
                        </span>
                        <button
                          onClick={() => handleOpenModal(index)}
                          className={styles.playPauseButton}
                        >
                          Eduamigo Explica
                        </button>
                      </div>
                    ) : (
                      <div className="d-flex gap-2">
                        <button
                          className={styles.playPauseButton}
                          onClick={() => handlePlayPauseAudio(index)}
                          disabled={msg.isLoading}
                        >
                          {msg.isPlaying ? "⏸️ Pausar" : "🔊 Reproduzir"}
                        </button>
                        <button
                          onClick={() => handleOpenModal(index)}
                          className={styles.playPauseButton}
                        >
                          Eduamigo Explica
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
            {isLoadingResponse && (
              <div className={styles.loadingSpinner}>
                <div className={styles.spinner}></div>
                <span>Estou pensando...</span>
              </div>
            )}
          </div>
        )}

        <div className={styles.inputSection}>
          <div className={styles.inputContainer}>
            <input
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              onKeyDown={handleKeyDown}
              placeholder="Oie, digite sua dúvida aqui"
              className={styles.inputField}
            />
            <button onClick={handleSendMessage} className={styles.sendButton}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                width="24px"
                height="24px"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.59 5.58L20 12l-8-8-8 8z" />
              </svg>
            </button>
          </div>
        </div>
      </main>
      {isLoadingCards ? (
        <div className={styles.loadingCards}>
          <div className={styles.loadingSpinnerQuiz}></div>
          <span>Carregando o quiz...</span>
        </div>
      ) : (
        <Quiz isOpen={isModalOpen} onClose={handleCloseModal} cards={cards} />
      )}
    </>
  );
}

export default EduAmigoFale;

import api from '../'

async function getContatosComConversas(usuarioId) {
  return await api
    .get(`Chat/participants?usuarioId=${usuarioId}`)
    .then(async (res) => {
      const { data } = res.data;
      return res.data;
    });
};


async function getMensagens(conversaId, usuarioId) {
  return await api
    .get(`Chat/messages/${conversaId}?usuarioId=${usuarioId}`)
    .then(async (res) => {
      const { data } = res.data;
      return res.data;
    });
};

async function pessoasGrupo(conversaId) {
  return await api
    .get(`Chat/pessoasgrupo?conversaId=${conversaId}`)
    .then(async (res) => {
      return res.data;
    });
};

async function salvaMensagem(data) {
  return await api
    .post(`Chat/salvarmensagem`, data)
    .then(async (res) => {
      const { data } = res.data;
      return res.data;
    });
}; 

async function criaGrupo(data) {
  return await api
    .post(`Chat/criagrupo`, data)
    .then(async (res) => {
      const { data } = res.data;
      return res.data;
    });
}; 

async function statusMensagem(data) {
  return await api
    .post(`Chat/statusmensagem`, data)
    .then(async (res) => {
      const { data } = res.data;
      return res.data;
    });
}; 

async function salvarImagem(data) {
  return await api
    .post(`Chat/uploadimages`, data)
    .then(async (res) => {
      const { data } = res.data;
      return res.data;
    });
}; 

export {
  getContatosComConversas,
  getMensagens,
  salvaMensagem,
  criaGrupo,
  statusMensagem,
  salvarImagem,
  pessoasGrupo
}
import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import Table from "../../../../components/table";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Select,
  MenuItem,
  Alert,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AttachFile, CloudDownload } from "@mui/icons-material";
import { ocorrenciasOptions } from "../../ocorrenciasOptions";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import {
  listarServidoresFrequencia,
  adicionarServidoresFrequencia,
  adicionarDocumento,
  adicionarServidoresOcorrencia,
  enviarServidoresFrequencia,
  deletarServidoresOcorrencia
} from "../../../../services/servidores";
import { toast } from "react-toastify";
import { listaAnexo } from "../../../../services/processos/remanejamento";
import "dayjs/locale/pt-br";

dayjs.locale("pt-br");

const FrequenicaBaseModel = ( {gestao, regimeId, cargoId}) => {
  const unidadeID = localStorage.getItem("unidadeID");

  const [servidores, setServidores] = useState([]);

  const [openModalOcorrencia, setOpenModalOcorrencia] = useState(false);
  const [openModalVerOcorrencias, setOpenModalVerOcorrencias] = useState(false);
  const [selectedServidor, setSelectedServidor] = useState(null);
  const [cargaHorariaTemp, setCargaHorariaTemp] = useState({});
  const [formularioFechado, setFormularioFechado] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tipoOcorrencia: "INTEGRAL",
      servidorPermutado: "",
      dataInicio: null,
      dataFim: null,
      diasAusente: null,
      file: "",
    },
  });

  const dataInicio = watch("dataInicio");
  const dataFim = watch("dataFim");
  const ocorrencia = watch("tipoOcorrencia");

  const handleDownload = async (arquivoId, extensao) => {
    try {

      // Recebe o arquivo da função listaAnexo (agora como Blob)
      const arquivoBlob = await listaAnexo(arquivoId);

      // Gera uma URL para o Blob
      const blobUrl = URL.createObjectURL(arquivoBlob);

      // Verificar a extensão e abrir o arquivo em uma nova aba
      if (extensao === "application/pdf") {
        const pdfWindow = window.open(blobUrl, "_blank");
        if (!pdfWindow) {
          alert("Permita pop-ups para visualizar o PDF.");
        }
      } else if (extensao === "image/png") {
        const imgWindow = window.open(blobUrl, "_blank");
        if (!imgWindow) {
          alert("Permita pop-ups para visualizar a imagem.");
        }
      } else {
        // Para outros tipos de arquivos, abre uma nova aba
        const newWindow = window.open(blobUrl, "_blank");
        if (!newWindow) {
          alert("Permita pop-ups para visualizar o arquivo.");
        }
      }

      // Revogar a URL após abrir o arquivo para liberar memória
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Erro ao baixar o arquivo:", error);
    }
  };

  // Função para calcular os dias ausentes
  const calculateDiasAusente = (inicio, fim) => {
    if (inicio && fim) {
      const diasAusente = dayjs(fim).diff(dayjs(inicio), "day") + 1; // Incluindo o dia de início
      setValue("diasAusente", diasAusente);
    }
  };

  // Recalcula os dias ausentes quando as datas são alteradas
  React.useEffect(() => {
    if (dataInicio && dataFim) {
      calculateDiasAusente(dataInicio, dataFim);
    }
  }, [dataInicio, dataFim]);

  // Função para alternar o estado da Checkbox (chcfc)
  const handleCheckboxChange = useCallback(
    async (servidorId) => {
      setServidores((prevData) => {
        const newData = prevData.map((row) =>
          row.servidorId === servidorId ? { ...row, chcfc: !row.chcfc } : row
        );
        return newData;
      });
    },
    []
  );

  // Função para atualizar o campo ch (Carga Horária)
  const handleChChange = useCallback(
    async (servidorId, newCh) => {
      setServidores((prevData) => {
        const newData = prevData.map((row) =>
          row.servidorId === servidorId ? { ...row, cargaHoraria: newCh } : row
        );
        return newData;
      });
    },
    []
  );

  // Função para deletar uma ocorrência e atualizar o backend
  async function handleDeleteOcorrencia(row){
    const response = await deletarServidoresOcorrencia(row.id);
    if(response.status === 200){
      toast.success("Ocorrência excluída com sucesso.");
      setOpenModalOcorrencia(false);
    } else {
      toast.error("Erro ao excluir a ocorrência.");
    }
    
  }
  const onSubmitOcorrencia = async (formData) => {

    debugger
    // Verificar se o tipo de ocorrência é "ATESTADO MÉDICO" e se o arquivo foi anexado
    if (
      formData.tipoOcorrencia === "ATESTADO MÉDICO" &&
      (!formData.file || formData.file.length === 0)
    ) {
      // Exibir mensagem de erro
      setError("file", {
        type: "manual",
        message:
          "Atestado médico é obrigatório o envio do anexo.",
      });
      return; // Parar o envio da ocorrência até que o arquivo seja anexado
    }

    if (formData.file.length > 0) {
      console.log(formData);
      if (formData.file != null) {
        var formDataToSend = new FormData();
        formDataToSend.append("arquivo", formData.file[0]);
        formDataToSend.append("servidorId", selectedServidor.servidorId);
        formDataToSend.append("tipo", 'FREQUÊNCIA');
        var response = await adicionarDocumento(formDataToSend);
        formData.anexoId = response.data.id.toString().toLowerCase();
      }
    }

    formData.file = null;


    try {
      await adicionarServidoresOcorrencia({
        ocorrencia: {
          ...formData,
        },
        unidadeId: unidadeID,
        servidorId: selectedServidor.servidorId,
        ano: watch("ano"),
        mes: watch("mes"),
      });
      toast.success(`Ocorrência salva com sucesso.`);
    } catch (error) {
      toast.error(`Erro ao salvar ocorrência.`);
    }

    setOpenModalOcorrencia(false);
    reset();
  };

  const handleModalAddOcorrencia = (servidor) => {
    setSelectedServidor(servidor);
    setOpenModalOcorrencia(true);
  };

  // Função para visualizar as ocorrências de um servidor
  const handleModalVerOcorrencia = (servidor) => {
    setSelectedServidor(servidor);
    setOpenModalVerOcorrencias(true);
  };

  const columns = useMemo(
    () => [
      { Header: "#", accessor: "servidorId" },
      { Header: "Nome", accessor: "nome" },
      { Header: "Matrícula", accessor: "matricula" },
      { Header: "Cargo", accessor: "cargo" },
      {
        Header: "Carga Horária",
        accessor: "cargaHoraria",
        Cell: ({ row }) => (
          <div
            style={{
              display: "flex",
              maxWidth: "110px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              className="form-control"
              type="number"
              aria-labelledby="aria-label"
              defaultValue={row.original.cargaHoraria}
              disabled={formularioFechado}
              onChange={(e) => handleChChange(row.original.servidorId, e.target.value)}
            />
            /HORAS
          </div>
        ),
      },
      {
        Header: "CHCFC",
        accessor: "chcfc",
        Cell: ({ row }) => (
          <Checkbox
            disabled={formularioFechado}
            checked={row.original.chcfc}
            onChange={() => handleCheckboxChange(row.original.servidorId)}
          />
        ),
      },
      {
        Header: "Ocorrências",
        accessor: "ocorrencias",
        Cell: ({ row }) => (
          row.original.ocorrencias.map(oc => oc.tipoOcorrencia).join(", ")
        ),
      },
      {
        Header: "Ações",
        accessor: "actions",
        Cell: ({ row }) => (
          <>
            {!formularioFechado && (
              <Tooltip title="Adicionar Ocorrência">
                <IconButton
                  onClick={() => handleModalAddOcorrencia(row.original)}
                >
                  <AddCircleIcon color="success" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Ver Ocorrências">
              <IconButton
                onClick={() => handleModalVerOcorrencia(row.original)}
              >
                <VisibilityIcon color="primary" />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    ],
    [formularioFechado, watch("mes"), watch("ano"), handleChChange, handleCheckboxChange]
  );

  const handleEnviar = useCallback(async () => {
    try {
      var response = await enviarServidoresFrequencia({
        mes: watch("mes"),
        ano: watch("ano"),
        unidadeId: unidadeID,
        regimeId: regimeId,
        cargoId: cargoId,
      });

      debugger

      if (response.status === 200) {
        toast.success("Formulário enviado com sucesso!");
        setFormularioFechado(true);
      } else {
        toast.error("Erro ao enviar o formulário.");
      }
    } catch (error) {
      toast.error("Erro ao enviar o formulário.");
    }
  });

  const handleSalvar = useCallback(async () => {
    let successCount = 0;
    for (let i = 0; i < servidores.length; i++) {
      const servidor = servidores[i];
      
      try {
        await adicionarServidoresFrequencia({
          ...servidor,
          mes: watch("mes"),
          ano: watch("ano"),
          unidadeId: unidadeID,
        });
        // successCount++;
        // toast.info(`${i + 1} de ${servidores.length}`);
      } catch (error) {
        // toast.error(`Erro ao salvar o registro ${i + 1}.`);
      }
    }
    setCargaHorariaTemp({});
    toast.success(
      `Registro enviados com sucesso!`
    );
  });

  useEffect(() => {
    
      listarServidoresFrequencia(unidadeID, regimeId, cargoId, watch('mes'), watch('ano')).then((data) => {
        setServidores(data?.data || []);
        const isFormularioFechado = data?.data.some((item) => item.status !== 1);
        setFormularioFechado(isFormularioFechado);
      });
  }, [unidadeID, watch('mes'), watch('ano')]);

  return (
    <>
    {formularioFechado && <Alert sx={{ display: 'flex', gap: 2, marginTop: '20px' }} severity="warning">O formulário encontra-se fechado, não é mais possível realizar alterações.</Alert>}
    {!formularioFechado &&  <Alert sx={{ display: 'flex', gap: 2, marginTop: '20px' }} severity="info">O formulário encontra-se em rascunho, após salvar todos os dados e ocorrências, clique no botão <b>ENVIAR</b> para envia-lo para SEMED.</Alert>}
      <Box sx={{ display: 'flex', gap: 2, marginTop: '20px' }}>
        <Select
          {...register("mes")}
          defaultValue={dayjs().format("MMMM").toUpperCase()}
          fullWidth
          sx={{ flex: 1 }}
          displayEmpty
          renderValue={(selected) => {
            if (!selected) {
              return <em>Escolha o mês de referência</em>;
            }
            return selected;
            }}
          >
            {["JANEIRO", "FEVEREIRO", "MARÇO", "ABRIL", "MAIO", "JUNHO", "JULHO", "AGOSTO", "SETEMBRO", "OUTUBRO", "NOVEMBRO", "DEZEMBRO"].map((mes) => (
            <MenuItem key={mes} value={mes}>
              {mes}
            </MenuItem>
            ))}
          </Select>
          <Select
            {...register("ano")}
            defaultValue={dayjs().year()}
            fullWidth
            sx={{ flex: 1 }}
            displayEmpty
            renderValue={(selected) => {
            if (!selected) {
              return <em>Escolha o ano de referência</em>;
            }
            return selected;
            }}
          >
            {[2024, 2025].map((ano) => (
            <MenuItem key={ano} value={ano}>
              {ano}
            </MenuItem>
            ))}
          </Select>
          </Box>
          <div style={{ marginTop: 20 }}>
          <Table
            columns={columns}
            data={servidores}
            hasPagination
            overflowUnset={true}
            hiddenColluns={["servidorId"]}
            totalItems={servidores.length}
          />
          {!formularioFechado && (
            <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              marginTop: 2,
            }}
            >
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={() => handleSalvar()}
            >
              Salvar
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<SendIcon />}
              onClick={() => handleEnviar()}
            >
              Enviar
            </Button>
            </Box>
          )}
          {/* Modal para Adicionar Ocorrência */}
        <Dialog
          open={openModalOcorrencia}
          onClose={() => setOpenModalOcorrencia(false)}
          fullWidth
        >
          <DialogTitle textAlign="center">
            Adicionar Ocorrência - {selectedServidor?.nome}
          </DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit(onSubmitOcorrencia)}>
              <Box sx={{ marginTop: "20px" }}>
                <Select
                  {...register("tipoOcorrencia")}
                  defaultValue="INTEGRAL"
                  fullWidth
                >
                  {ocorrenciasOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              {ocorrencia === "PERMUTA" ? (
                <Box sx={{ marginTop: "20px" }}>
                  <TextField
                    label="Servidor Permutado"
                    {...register("servidorPermutado")}
                    fullWidth
                  />
                </Box>
              ) : null}
              {ocorrencia.includes("LICENÇA") ||
              ocorrencia === "ATESTADO MÉDICO" ? (
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                    <DatePicker
                      label="Data Início"
                      required={true}
                      value={dataInicio || null}
                      onChange={(value) => setValue("dataInicio", value)}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth required={true} />
                      )}
                    />
                     <TextField
                    label="Dias Ausente"
                    {...register("diasAusente")}
                    // fullWidth
                    type="string"
                    required={true}
                    InputProps={{
                      readOnly: false,
                    }}
                    />
                    <DatePicker
                      label="Data Fim"
                      value={dataInicio && watch("diasAusente") ? dayjs(dataInicio).add(watch("diasAusente"), 'day') : null}
                      onChange={(value) => setValue("dataFim", value)}
                      disabled={true}
                      InputProps={{
                      readOnly: true,
                      disabled: true
                      }}
                      renderInput={(params) => (
                      <TextField {...params} fullWidth />
                      )}
                    />
                    </LocalizationProvider>
                   
                  </Box>
                  ) : null}
                  <Box sx={{ marginTop: "20px" }}>
                  <Button
                    sx={{ marginTop: "20px" }}
                    variant="contained"
                    component="label"
                    fullWidth
                  >
                    <AttachFile />
                    Anexar Documento
                    <input
                    type="file"
                    hidden
                    accept="application/pdf, image/png"
                    {...register("file")}
                    />
                  </Button>
                  {/* Exibir a mensagem de erro se existir */}
                {errors.file && (
                  <p style={{ color: "red", marginTop: "10px" }}>
                    {errors.file.message}
                  </p>
                )}
              </Box>
              <DialogActions>
                <Button onClick={() => setOpenModalOcorrencia(false)}>
                  Cancelar
                </Button>
                <Button type="submit" variant="contained" color="success">
                  Adicionar
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>

        {/* Modal para Visualizar Ocorrências */}
        <Dialog
          open={openModalVerOcorrencias}
          onClose={() => setOpenModalVerOcorrencias(false)}
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle textAlign="center">
            Ocorrências Registradas - {selectedServidor?.nome}
          </DialogTitle>
          <DialogContent>
            <Table
              columns={[
                { Header: "#", accessor: "id" },
                { Header: "Ocorrência", accessor: "tipoOcorrencia" },
                {
                  Header: "Data Início",
                  accessor: "dataInicio",
                  Cell: ({ value }) =>
                    value === "0001-01-01T00:00:00" ? "" : dayjs(value).format("DD/MM/YYYY"),
                },
                {
                  Header: "Data Fim",
                  accessor: "dataFim",
                  Cell: ({ value }) =>
                    value === "0001-01-01T00:00:00" ? "" : dayjs(value).format("DD/MM/YYYY"),
                },
                { Header: "Dias Ausente", accessor: "diasAusente" },
                { Header: "Servidor Permutado", accessor: "servidorPermutado" },
                {
                  Header: "Anexo",
                  accessor: "anexoId",
                  Cell: ({ value }) =>
                    value ? (
                      <Tooltip title="Baixar Documento">
                        <IconButton
                          component="a"
                          // href={URL.createObjectURL(value)}
                          download={value.name}
                          onClick={() => handleDownload(value, value.extensao)}
                        >
                          <CloudDownload />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      ""
                    )
                },
                {
                  Header: "",
                  accessor: "actions",
                  Cell: ({ row }) => (
                    <Tooltip title="Excluir Ocorrência">
                      <IconButton
                        disabled={formularioFechado}
                        onClick={() => handleDeleteOcorrencia(row.original)}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  ),
                },
              ]}
              data={selectedServidor?.ocorrencias || []}
              hiddenColluns={[formularioFechado ? "actions" : ""]}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenModalVerOcorrencias(false)}>
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default FrequenicaBaseModel;

import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
`;

export const DropdownContainer = styled.div`
  position: relative;
  margin-top: 1px;
  width: 100%;
  max-height: 157px;
  padding: 8px 18px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: overlay;
`;

export const DropdownOption = styled.div`
  display:flex;
  align-items: center;
  cursor: pointer;
  height: 30px;
  
  &:hover {
    background: #eee;
  }
`;
import { useEffect, useState, useMemo } from 'react';
import * as S from './style';
import Table from '../../../../../components/table';
import { useParams } from 'react-router-dom'
import api from '../../../../../services';
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import './style.css';

const VisualizacaoFormularioChamadaPublicaPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const { id } = useParams()

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`/chamadapublica/${id}`);
      setData(response.data);
    }
    fetchData()
  }, [])

  const escolaridades = [
    { value: 0, text: '' },
    { value: 1, text: '1º ANO' },
    { value: 2, text: '2º ANO' },
    { value: 3, text: '3º ANO' },
    { value: 4, text: '4º ANO' },
    { value: 5, text: '5º ANO' },
    { value: 6, text: '6º ANO' },
    { value: 7, text: '7º ANO' },
    { value: 8, text: '8º ANO' },
    { value: 9, text: '9º ANO' },
    { value: 10, text: 'CRECHE I' },
    { value: 11, text: 'CRECHE II' },
    { value: 12, text: 'CRECHE III' },
    { value: 13, text: 'CRECHE IV' },
    // { value: 14, text: 'EJA' },
    // { value: 15, text: 'FASE I' },
    // { value: 16, text: 'FASE II' },
    // { value: 17, text: 'FASE III' },
    // { value: 18, text: 'FASE IV' },
    // { value: 19, text: 'FASE IX' },
    // { value: 20, text: 'FASE V' },
    // { value: 21, text: 'FASE VI' },
    // { value: 22, text: 'FASE VII' },
    // { value: 23, text: 'FASE VIII' },
    { value: 24, text: 'PRÉ I' },
    { value: 26, text: 'PRÉ II' },
    // { value: 27, text: 'MULTISSERIADA' },
    // { value: 28, text: 'ENSINO MÉDIO' },
  ]

  function handleEscolaridade(escolaridade) {
    const objeto = escolaridades.find(item => item.value === escolaridade);

    if (objeto == undefined) {
      return ''
    }

    return objeto.text
  }

  async function handleVisualizar(nome) {
    const response = await api.get(`/chamadapublica/filtroNome?nome=${nome}`);
    const id = response?.data?.id;

    if (id) {
      window.open('/chamada-publica/visualizacao/' + id, '_blank')
    } else {
      toast.error('Inscrição não encontrada na base de dados por nome.')
    }
  }

  function handleDeficiencia(search) {
    const objeto = deficiencias.find(item => item.value === search);

    if (objeto == undefined) {
      return ''
    }

    return objeto.text
  }

  const deficiencias = [
    { value: 0, text: '' },
    { value: 1, text: 'DEFICIENCIAS MÚLTIPLAS' },
    { value: 2, text: 'DEFICIÊNCIA INTELECTUAL' },
    { value: 3, text: 'DEFICIÊNCIA VISUAL' },
    { value: 4, text: 'DEFICIÊNCIA AUDITIVA' },
    { value: 5, text: 'DEFICIÊNCIA FÍSICA' },
    { value: 6, text: 'TRANSTORNO DO ESPECTRO AUTISTA (TEA)' },
    { value: 7, text: 'ALTAS HABILIDADES' },
  ];


  const tempoTrabalho = [
    { value: 0, text: '' },
    { value: 1, text: 'SIM' },
    { value: 2, text: 'NÃO' }
  ]

  const statusOptions = [
    { value: 1, text: 'LISTA DE ESPERA' },
    { value: 2, text: 'CONVOCADO' },
    { value: 3, text: 'MATRICULADO' },
    { value: 4, text: 'NÃO COMPARECIDO' },
    { value: 5, text: 'INDEFERIDO' },
    { value: 6, text: 'CANCELADO' },
    { value: 7, text: 'DESISTÊNCIA' },
    { value: 8, text: 'MATRICULADO POR OUTRA OPÇÃO' },
    { value: 9, text: 'CONVOCADO POR OUTRA OPÇÃO' },
    { value: 10, text: 'MATRICULADO EM OUTRA OPÇÃO' }
  ];

  const schoolOptions = [
    { value: 0, text: '' },
    { value: 2, text: 'COORDENADORIA REGIONAL BAIXADA LITORÂNEA' },
    { value: 5, text: 'CRECHE MUNICIPAL COMUNITÁRIA DONA CHICA' },
    { value: 6, text: 'CRECHE MUNICIPAL COMUNITÁRIA PROFESSORA TIA FÁTIMA' },
    { value: 7, text: 'CRECHE MUNICIPAL TIA MÁRCIA' },
    { value: 52, text: 'ESCOLA DE ARTES MUNICIPAL DIRETORA LUÍZA MARIA LEAL MENDES' },
    { value: 8, text: 'ESCOLA MUNICIPAL ANTÔNIO RODRIGUES DOS SANTOS' },
    { value: 9, text: 'ESCOLA MUNICIPAL ANTÔNIO VAZ DA SILVA' },
    { value: 10, text: 'ESCOLA MUNICIPAL AURELINO MARTINS DOS SANTOS' },
    { value: 11, text: 'ESCOLA MUNICIPAL BARNABÉ MARIANO DE SOUZA' },
    { value: 12, text: 'ESCOLA MUNICIPAL CARLOTA ROCHA DA SILVA' },
    { value: 13, text: 'ESCOLA MUNICIPAL DE EDUCAÇÃO ESPECIAL PEDRO PAULO LOBO DE ANDRADE' },
    { value: 26, text: 'ESCOLA MUNICIPAL DE EDUCAÇÃO INFANTIL GRACINÉA RODRIGUES DE SOUZA' },
    { value: 27, text: 'ESCOLA MUNICIPAL DR. PLÍNIO DE ASSIS TAVARES' },
    { value: 28, text: 'ESCOLA MUNICIPAL DULCE JOTTA DE SOUZA' },
    { value: 29, text: 'ESCOLA MUNICIPAL ELÍZIO HENRIQUE DE PAIVA' },
    { value: 30, text: 'ESCOLA MUNICIPAL ELÍZIO IGNÁCIO RANGEL' },
    { value: 31, text: 'ESCOLA MUNICIPAL FLONETE ALEXANDRINO DA SILVA' },
    { value: 32, text: 'ESCOLA MUNICIPAL FRANCISCO PAES DE CARVALHO FILHO' },
    { value: 14, text: 'ESCOLA MUNICIPALIZADA ADALGIZA DA SILVA LOBO' },
    { value: 15, text: 'ESCOLA MUNICIPALIZADA CAPITÃO COSTA' },
    { value: 16, text: 'ESCOLA MUNICIPALIZADA ELÍZIO DA COSTA MOREIRA' },
    { value: 17, text: 'ESCOLA MUNICIPALIZADA JOSÉ GUIMARÃES' },
    { value: 18, text: 'ESCOLA MUNICIPALIZADA LUCINDA FRANCISCONE DE MEDEIROS' },
    { value: 19, text: 'ESCOLA MUNICIPALIZADA MANOEL MARTINS TEIXEIRA' },
    { value: 20, text: 'ESCOLA MUNICIPALIZADA MANOEL MORAES DA SILVA' },
    { value: 21, text: 'ESCOLA MUNICIPALIZADA PAINEIRA' },
    { value: 22, text: 'ESCOLA MUNICIPALIZADA PAULO ROBERTO MARINHO' },
    { value: 23, text: 'ESCOLA MUNICIPALIZADA PEQUIÁ' },
    { value: 24, text: 'ESCOLA MUNICIPALIZADA RETIRO' },
    { value: 25, text: 'ESCOLA MUNICIPALIZADA VITAL BRASIL' },
    { value: 33, text: 'ESCOLA MUNICIPAL JAMILA MOTA DA SILVA' },
    { value: 34, text: 'ESCOLA MUNICIPAL JARDIM PRIMAVERA' },
    { value: 35, text: 'ESCOLA MUNICIPAL JOSÉ TEIXEIRA PAULO' },
    { value: 36, text: 'ESCOLA MUNICIPAL LUÍZA TERRA DE ANDRADE' },
    { value: 37, text: 'ESCOLA MUNICIPAL PROFESSORA CAROLINA NAZARETH TEIXEIRA PINHEIRO' },
    { value: 38, text: 'ESCOLA MUNICIPAL PROFESSORA DULCINDA JOTTA MENDES' },
    { value: 39, text: 'ESCOLA MUNICIPAL PROFESSORA MARIA CELESTE DE CAMPOS' },
    { value: 40, text: 'ESCOLA MUNICIPAL PROFESSORA MARIA DA GLÓRIA DOS SANTOS MOTTA' },
    { value: 41, text: 'ESCOLA MUNICIPAL PROFESSORA MIRIAN ALVES DE MACEDO GUIMARÃES - CÍVICO MILITAR' },
    { value: 42, text: 'ESCOLA MUNICIPAL QUILOMBOLA DONA ROSA GERALDA DA SILVEIRA' },
    { value: 43, text: 'ESCOLA MUNICIPAL RUBEM ARRUDA' },
    { value: 44, text: 'ESCOLA MUNICIPAL SÃO FRANCISCO DE ASSIS' },
    { value: 45, text: 'ESCOLA MUNICIPAL VIDAL DE NEGREIROS' },
    { value: 46, text: 'ESCOLA MUNICIPAL VINHATEIRO' },
    { value: 3, text: 'PREFEITURA MUNICIPAL DE SÃO PEDRO DA ALDEIA' },
    { value: 1, text: 'SECRETARIA ESTADUAL DE EDUCACAO DO RIO DE JANEIRO' },
    // { value: 4, text: 'SECRETARIA MUNICIPAL DE EDUCAÇÃO' },
    // { value: 51, text: 'SEM UNIDADE' },
    // { value: 53, text: 'TRANSFERÊNCIA EXTERNA' },
  ];

  function handleUnidades(search) {
    const objeto = schoolOptions.find(item => item.value === search);

    if (objeto == undefined) {
      return ''
    }

    return objeto.text
  }



  function handleStatus(search) {
    const objeto = statusOptions.find(item => item.value === search);

    if (objeto == undefined) {
      return ''
    }

    return objeto.text
  }

  const programaSocialOptions = [
    { value: 1, text: 'BOLSA FAMÍLIA' },
    { value: 2, text: 'CADÚNICO' },
    { value: 3, text: 'NÃO SOU BENEFICIÁRIO DE PROGRAMAS SOCIAIS' },
  ];

  function handleBeneficios(search) {
    const objeto = programaSocialOptions.find(item => item.value === search);

    if (objeto == undefined) {
      return ''
    }

    return objeto.text
  }



  const columns = useMemo(
    () => [
      { Header: 'Nome', accessor: 'nome' },
      {
        Header: 'Visualizar', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <S.TableButton
              onClick={() => { handleVisualizar(row.original.nome) }
                // navigate(urls.processosSeletivosVInscricao + row.original.id)
              }
              className='btn btn-sm btn-primary'>
              <i className="material-icons md-edit_note"></i> visualizar
            </S.TableButton>
          </>
        )
      },
    ],
    []
  )

  return (
    <div className='body-content'>
      <img
        src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`}
        alt="Logo" width="200" height="150"
      />
      <h6 style={{ marginTop: '10px', alignSelf: 'center' }}>PREFEITURA MUNICIPAL DE SÃO PEDRO</h6>
      <h4 style={{ alignSelf: 'center' }}>SECRETARIA DE EDUCAÇÃO</h4>
      <S.Container>
        <S.FormHeader>FORMULÁRIO DE CONFIRMAÇÃO DE CHAMADA PÚBLICA 2024</S.FormHeader>

        <p><strong>Sr(a). responsável,</strong></p>

        <p>O resultado poderá ser consultado por meio do CPF do responsável que realizou a pré-matrícula no endereço <a href="https://app.semedspa.rj.gov.br/chamada-publica" target="_blank">https://app.semedspa.rj.gov.br/chamada-publica</a>.</p>

        <p>A efetivação da matrícula deverá ocorrer em até <strong>3 dias após a data da convocação</strong> na Unidade Escolar que o aluno tiver sido alocado, dentre as opções informadas pelo responsável.</p>


        <S.LineDivider />

        <div>
          <div className="table-responsive">
            <table className="table table-striped table-hover table-responsive">
              <tbody>
                <tr className="small text-center">
                  <td>ID</td>
                  <td>{data?.id}</td>
                </tr>
                <tr className="small text-center">
                  <td>Data e Hora</td>
                  <td>{data?.criacao.split('T')[0].split('-').reverse().join('/') + ' às ' + data?.criacao.split('T')[1].split('.')[0]}</td>
                </tr>
                <tr className="small text-center">
                  <td>Escolaridade Pretendida</td>
                  <td>{handleEscolaridade(data?.escolaridade)}</td>
                </tr>
                <tr className="small text-center">
                  <td>Nome</td>
                  <td>{data?.nome}</td>
                </tr>
                <tr className="small text-center">
                  <td>Data nascimento</td>
                  <td>{data?.dataNascimento.split('T')[0].split('-').reverse().join('/')}</td>
                </tr>
                <tr className="small text-center">
                  <td>CPF</td>
                  <td>{data?.cpf}</td>
                </tr>
                <tr className="small text-center">
                  <td>filiação 1</td>
                  <td>{data?.filiacao1}</td>
                </tr>
                <tr className="small text-center">
                  <td>filiação 2</td>
                  <td>{data?.filiacao2}</td>
                </tr>
                <tr className="small text-center">
                  <td>JA É estudante da rede</td>
                  <td>{data?.estudanteDaRede == 1 ? "SIM" : "NÃO"}</td>
                </tr>
                <tr className="small text-center">
                  <td>CEP</td>
                  <td>{data?.cep}</td>
                </tr>
                <tr className="small text-center">
                  <td>Logradouro</td>
                  <td>{data?.logradouro}</td>
                </tr>

                <tr className="small text-center">
                  <td>numero</td>
                  <td>{data?.numero}</td>
                </tr>
                <tr className="small text-center">
                  <td>complemento</td>
                  <td>{data?.complemento}</td>
                </tr>
                <tr className="small text-center">
                  <td>Bairro</td>
                  <td>{data?.bairro}</td>
                </tr>

                <tr className="small text-center">
                  <td>cidade</td>
                  <td>{data?.cidade}</td>
                </tr>
                <tr className="small text-center">
                  <td>UF</td>
                  <td>{data?.estado}</td>
                </tr>
                <tr className="small text-center">
                  <td>deficiente</td>
                  <td>{data?.deficiente == 1 ? 'SIM' : 'NÃO'}</td>
                </tr>
                {
                  data?.deficiente == 1 && (
                    <>
                      <tr className="small text-center">
                        <td>deficiencia</td>
                        <td>{handleDeficiencia(data?.deficiencia)}</td>
                      </tr>
                    </>
                  )
                }
                <tr className="small text-center">
                  <td>nome responsável</td>
                  <td>{data?.responsavelNomeCompleto}</td>
                </tr>
                <tr className="small text-center">
                  <td>rg responsável</td>
                  <td>{data?.responsavelRg}</td>
                </tr>
                <tr className="small text-center">
                  <td>cpf responsável</td>
                  <td>{data?.responsavelCpf}</td>
                </tr>
                <tr className="small text-center">
                  <td>Data Nascimento Responsável</td>
                  <td>{data?.responsavelDataNascimento.split('T')[0].split('-').reverse().join('/')}</td>
                </tr>
                <tr className="small text-center">
                  <td>Telefone Responsável</td>
                  <td>{data?.responsavelTelefone}</td>
                </tr>
                <tr className="small text-center">
                  <td>E-mail Responsável</td>
                  <td>{data?.responsavelEmail}</td>
                </tr>
                <tr className="small text-center">
                  <td>Tem irmão na primeira opção</td>
                  <td>{data?.possuiIrmao == 1 ? 'SIM' : 'NÃO'}</td>
                </tr>
                <tr className="small text-center">
                  <td>escolaridade irmão</td>
                  <td>{handleEscolaridade(data?.escolaridadeIrmao)}</td>
                </tr>
                <tr className="small text-center">
                  <td>nome irmão</td>
                  <td>{data?.nomeIrmao}</td>
                </tr>
                <tr className="small text-center">
                  <td>Beneficiário Programa Social?</td>
                  <td>{handleBeneficios(data?.beneficiarioProgramaSocial)}</td>
                </tr>
                <tr className="small text-center">
                  <td>Responsável Legal Está Empregado?</td>
                  <td>{data?.tempoTrabalho == 1 ? "SIM" : "NÃO"}</td>
                </tr>
                <tr className="small text-center">
                  <td>Possui Filiação monoparental?</td>
                  <td>{data?.monoparental == 1 ? "SIM" : "NÃO"}</td>
                </tr>
                <tr className="small text-center">
                  <td>Realizei a leitura e concordo com os termos da resolução?</td>
                  <td>SIM</td>
                </tr>
                <tr className="small text-center">
                  <td>primeira opção</td>
                  <td>{handleUnidades(data?.opcao1)}</td>
                </tr>
                {data?.statusOpcao1 == 1 && (
                  <tr className="small text-center">
                    <td>Posição</td>
                    <td>{data?.posicao1}</td>
                  </tr>)}

                <tr className="small text-center">
                  <td>status</td>
                  <td>{handleStatus(data?.statusOpcao1)}</td>
                </tr>
                {
                  data?.opcao2 > 0 &&
                  (
                    <>
                      <tr className="small text-center">
                        <td>segunda opção</td>
                        <td>{handleUnidades(data?.opcao2)}</td>
                      </tr>
                      {data?.statusOpcao2 == 1 && (
                        <tr className="small text-center">
                          <td>Posição</td>
                          <td>{data?.posicao2 }</td>
                        </tr>)}
                      <tr className="small text-center">
                        <td>status</td>
                        <td>{handleStatus(data?.statusOpcao2)}</td>
                      </tr>
                    </>
                  )
                }
                {
                  data?.opcao3 > 0 &&
                  (
                    <>
                      <tr className="small text-center">
                        <td>terceira opção</td>
                        <td>{data?.opcao3 == 0 ? '' : handleUnidades(data?.opcao3)}</td>
                      </tr>
                      {data?.statusOpcao3 == 1 && (
                        <tr className="small text-center">
                          <td>Posição</td>
                          <td>{data?.posicao3}</td>
                        </tr>)}
                      <tr className="small text-center">
                        <td>status</td>
                        <td>{data?.opcao3 == 0 ? '' : handleStatus(data?.statusOpcao3)}</td>
                      </tr>
                    </>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>

        <S.LineDivider />

        <Table
          columns={columns}
          data={data?.outrasInscricoes ? data?.outrasInscricoes : []}
        />

        <S.LineDivider />



        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
          <S.PrintButton type="button" onClick={() => {
            // window.print()
            navigate(-1)
          }}>Voltar</S.PrintButton>
          <S.PrintButton type="button" onClick={() => {
            window.print()
            navigate(-1)
          }}>Imprimir</S.PrintButton>

        </div>


      </S.Container>
    </div>
  )
}

export default VisualizacaoFormularioChamadaPublicaPage

import React, { useEffect, useState } from 'react';
import BaseFormularioServidores from '../BaseFormularioServidores';
import { listarInscricoesFiltroServidores, atualizarInscricaoServidores } from '../../../../services/formulariosService';
import FormularioServidoresInscricoesAtualizar from '../components/FormularioServidoresInscricoesAtualizar';
import FormularioServidoresInscricoesFilter from '../components/FormularioServidoresInscricoesFilter';
import FormularioServidoresInscricoesTable from '../components/FormularioServidoresInscricoesTable';
import UrlsProcessoSeletivoServidores from '../UrlsProcessoSeletivoServidores';
import { toast } from 'react-toastify';

const ServidoresInscricoesPage = () => {

    const [limit, setLimit] = useState(10);
    const [skip, setSkip] = useState(0);

  const [filtros, setFiltros] = useState({
    tipoFormulario: '',
    redeEnsino: '',
    escola: '',
    polo: '',
    nomeCompleto: '',
    dataNascimento: null,
    cpf: '',
    uf: '',
    cidade: '',
    nomeResponsavel: '',
    rgResponsavel: '',
    cpfResponsavel: '',
    telefoneResponsavel: '',
    emailResponsavel: '',
    tipoDeficiencia: '',
    atendimentoEspecializado: '',
    possuiAnexo: false,
    status: '',
    skip: skip, // Pagination: starts at 0
    limit: limit, // Items per page
  });

  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [status, setStatus] = useState('');
  const [observacao, setObservacao] = useState('');
  const [totalItems, setTotalItems] = useState(0);

  // Fetch data function to be reused
  const fetchData = async () => {
    const formattedDate = filtros.dataNascimento
      ? filtros.dataNascimento.toISOString().split('T')[0]
      : null;

    const payload = { ...filtros, dataNascimento: formattedDate };

    const response = await listarInscricoesFiltroServidores(payload);
    setData(response?.data || []);
    setTotalItems(response?.total || 0); // Total items for pagination
  };

  // Fetch data whenever 'filtros' or pagination (skip, limit) changes
  useEffect(() => {
    console.log('Fetching data with filters:', filtros);
    console.log('Skip:', skip);
    console.log('Limit:', limit);
    fetchData();
  }, [filtros, skip, limit]);

  // Handle updating the filters and resetting pagination
  const handleSubmit = () => {
    setFiltros((prevFiltros) => ({
      ...prevFiltros,
      skip: 0, // Reset to first page on new filter submit
    }));
  };

  

  // Handle limit change
  const handleLimitChange = (newLimit) => {
        // setLimit(newLimit);
        setFiltros((prevFiltros) => ({
            ...prevFiltros,
            limit: newLimit,
        }));
  };

    // Handle skip change
    const handleSkipChange = (newSkip) => {
        // setSkip(newSkip);
        setFiltros((prevFiltros) => ({
            ...prevFiltros,
            skip: newSkip,
        }));
    }

  const handleView = (row) => {
    window.open(`${UrlsProcessoSeletivoServidores.formularioVisualizacao}${row.original.id}`, '_blank');
  };

  const handleUpdate = (row) => {
    setSelectedRow(row.original);
    setStatus(row.original.status || ''); // Set default status
    setObservacao(row.original.observacao || ''); // Set default observação
    setOpenModal(true); // Open the modal
  };



  const handleSave = async () => {
    var response = await atualizarInscricaoServidores(selectedRow.id, status, observacao);
    if (response.statusCode === 200) {
      toast.success('Inscrição atualizada com sucesso!');
      setOpenModal(false);
      fetchData(); // Refresh the data after saving
    } else {
      toast.error('Erro ao atualizar inscrição');
    }
  };

  const handleClose = () => {
    setOpenModal(false); // Close the modal without saving
  };

  return (
    <BaseFormularioServidores>
      <FormularioServidoresInscricoesFilter filtros={filtros} setFiltros={setFiltros} handleSubmit={handleSubmit} />
      <div style={{ marginBottom: '20px' }}></div>
      <FormularioServidoresInscricoesTable 
        data={data} 
        handleView={handleView} 
        handleUpdate={handleUpdate} 
        totalItems={totalItems}
        limit={filtros.limit}
        setLimit={(newLimit) => handleLimitChange(newLimit)} // Handle limit change properly
        skip={filtros.skip}
        setSkip={(newSkip) => handleSkipChange(newSkip)}
      />

      {/* Modal component */}
      <FormularioServidoresInscricoesAtualizar
        open={openModal}
        onClose={handleClose}
        onSave={handleSave}
        selectedRow={selectedRow}
        status={status}
        setStatus={setStatus}
        observacao={observacao}
        setObservacao={setObservacao}
      />
    </BaseFormularioServidores>
  );
};

export default ServidoresInscricoesPage;

import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';

// URL da imagem de fundo relacionada à educação
// const backgroundImageUrl = 'https://example.com/imagem-educacao.jpg';

const Erro404 = () => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      textAlign="center"
      sx={{
        position: 'relative',
        color: '#fff',
        overflow: 'hidden',
        // backgroundImage: `url(${backgroundImageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        padding: 2,
      }}
    >
      {/* Overlay para aumentar a legibilidade do texto */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          bgcolor: 'rgba(0, 0, 0, 0.6)', // Overlay semi-transparente
          zIndex: 1,
        }}
      />
      <Box sx={{ position: 'relative', zIndex: 2, maxWidth: 600, padding: 3 }}>
        <ErrorOutlineIcon sx={{ fontSize: 80, color: 'error.main', mb: 2 }} />
        <Typography variant="h3" fontWeight="bold" gutterBottom>
          404 - Página Não Encontrada
        </Typography>
        <Typography variant="body1" color="inherit" mb={3}>
          Desculpe, a página que você está procurando não existe. <br/>Por favor, verifique o endereço ou vá para a página inicial.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/')}
          startIcon={<HomeIcon />} // Ícone da home
          sx={{
            mt: 2,
            textTransform: 'none',
            fontWeight: 'bold',
            paddingX: 3,
            paddingY: 1.5,
          }}
        >
          Ir para a Página Inicial
        </Button>
      </Box>
    </Box>
  );
};

export default Erro404;

const baseUrl = '/reconhecimento-facial';

const urlsReconhecimentoFacil = {
    dashboard: baseUrl,
    alunos: `${baseUrl}/alunos`,
    alunosEditar: `${baseUrl}/alunos/editar/`,
    alunosEditarId: `${baseUrl}/alunos/editar/:id`,
    servidores: `${baseUrl}/servidores`,
    servidoresEditar: `${baseUrl}/servidores/editar/`,
    servidoresEditarId: `${baseUrl}/servidores/editar/:id`,
    webcam: `${baseUrl}/webcam`,
    alunoCadastrarFoto: `${baseUrl}/alunos/cadastrar/foto`,
    servidorCadastrarFoto: `${baseUrl}/servidor/cadastrar/foto`,
    listagem: `${baseUrl}/listagem`,
   


}

export default urlsReconhecimentoFacil;
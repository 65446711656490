import { useEffect, useState, useMemo } from 'react';
import * as S from './style';
import FormField from './components/formField'
import Table from '../../../components/table';
import { useParams } from 'react-router-dom'
import api from '../../../services';
import { useNavigate } from 'react-router-dom'
import urlsGestaoUnidades from '../../gestao-unidades/urlsGestaoUnidades';

const VisualizacaoFormularioRenovacao = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const { id } = useParams()

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`/aluno/renovacao/${id}`);
      setData(response.data.data)
    }
    fetchData()
  }, [])

  

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      {
        Header: 'Data e Hora', accessor: 'criacao',
        Cell: ({ row }) => (
          row.original.criacao.split('T')[0].split('-').reverse().join('/') + ' às ' + row.original.criacao.split('T')[1].split('.')[0]
        )
      },
      { Header: 'Status', accessor: 'status' },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <S.TableButton 
            onClick={() =>
              {  window.open(urlsGestaoUnidades.processosSeletivosVInscricao + row.original.id, '_blank')}
              // navigate(urls.processosSeletivosVInscricao + row.original.id)
            }
            className='btn btn-sm btn-primary'>
              <i className="material-icons-two-tone">edit_note</i> visualizar
            </S.TableButton>
          </>
        )
      },
    ],
    []
  )

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '24px 48px',
      backgroundImage: 'url("https://pre-matricula.educacaoararuama.rj.gov.br/_form/images/fundo.jpg")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      height: '100vh'
    }}>
      <img
        src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`}
        alt="Logo" width="200" height="150"
      />
      <h2 style={{ marginTop: '10px', alignSelf: 'center' }}>{process.env.REACT_APP_PREFEITURA}</h2>
      <h3 style={{ alignSelf: 'center' }}>SECRETARIA DE EDUCAÇÃO</h3>
      <h4 style={{ marginTop: '20px', alignSelf: 'center' }}>Protocolo de Confirmação – Renovação de Matrícula</h4>
      <h5 style={{fontWeight: 'bold'}}>{data?.id}</h5>
      <h5 style={{ marginTop: '20px', alignSelf: 'center' }}>{data?.aluno}</h5>

      <h5 style={{ marginTop: '20px', alignSelf: 'center' }}>Protocolo gerado em: {data?.criacao.split('T')[0].split('-').reverse().join('/')} às {data?.criacao.split('T')[1]}</h5>

      <h5 style={{ marginTop: '20px', alignSelf: 'center' }}>Servidor: {data?.login}</h5>
    </div>
  )
}

export default VisualizacaoFormularioRenovacao

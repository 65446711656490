import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, DirectionsRenderer, Marker, InfoWindow } from '@react-google-maps/api';
import Breadcrumb from "../../components/breadcrumb";
import BaseConfiguracoes from '../configuracoes/baseConfiguracoes';
import Spinner from '../../components/spinner';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';

const locations = [
  { lat: -23.1997222, lng: -45.8839638, name: "Center Vale Shopping" },
  { lat: -23.213836, lng: -45.90139, name: "Vale Sul Shopping" },
  { lat: -23.2037965, lng: -45.9113173, name: "Colinas Shopping" },
  // { lat: -23.2272, lng: -45.8857, name: "Rua Buenos Aires, 84, Vista Verde" }
];

const mapContainerStyle = {
  height: "600px",
  width: "100%"
};

const center = {
  lat: -23.2237,
  lng: -45.9009
};

const googleMapsApiKey = "AIzaSyC5DtcUeGgNrK7-ZVt9bv9cJAhgr1b4gyo"; // Replace with your actual API key

const MapaPage = () => {
  const [response, setResponse] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  useEffect(() => {
    if (window.google && window.google.maps) {
      const directionsService = new window.google.maps.DirectionsService();

      directionsService.route(
        {
          origin: locations[0],
          destination: locations[locations.length - 1],
          waypoints: locations.slice(1, -1).map(location => ({ location: { lat: location.lat, lng: location.lng } })),
          travelMode: window.google.maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setResponse(result);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  }, []);

  return (
    <BaseConfiguracoes>
      <Breadcrumb title={'Mapa'} itens={['Gestão', 'Parâmetros', 'Mapa']} />
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <LoadScript googleMapsApiKey={googleMapsApiKey}>
              <GoogleMap
                id="direction-example"
                mapContainerStyle={mapContainerStyle}
                zoom={12}
                center={center}
              >
                {locations.map((location, index) => (
                  <Marker
                    key={index}
                    position={{ lat: location.lat, lng: location.lng }}
                    title={location.name}
                    onClick={() => setSelectedMarker(location)}
                  />
                ))}
                {selectedMarker && (
                  <InfoWindow
                    position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
                    onCloseClick={() => setSelectedMarker(null)}
                  >
                    <div>{selectedMarker.name}</div>
                  </InfoWindow>
                )}
                {response && (
                  <DirectionsRenderer
                    options={{
                      directions: response
                    }}
                  />
                )}
              </GoogleMap>
            </LoadScript>
          </div>
        </div>
      </div>
    </BaseConfiguracoes>
  );
};

export default MapaPage;

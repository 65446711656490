import urlsConfiguracoes from "./urlsConfiguracoes";

const linksConfiguracoes = (administrador) => {
  return (
    {
      alunos: {
        id: 'alunos',
        text: 'ALUNOS',
        type: 'title',
        visible: administrador == 1,
        itens: {
          alunosDuplicado: {
            id: 'alunosDuplicado',
            text: 'DUPLICADOS',
            path: urlsConfiguracoes.alunosDuplicado,
            iconName:'',
            icon: 'groups',
            visible: administrador == 1,
          },
          alunosManuais: {
            id: 'alunosManuais',
            text: 'CADASTRO MANUAL',
            path: urlsConfiguracoes.alunosManuais,
            iconName:'',
            icon: 'edit',
            visible: administrador == 1,
          },
          alunosPreMatricula: {
            id: 'alunosManuais',
            text: 'PRÉ-MATRÍCULA',
            path: urlsConfiguracoes.alunosPreMatricula,
            iconName: '',
            icon: 'how_to_reg',
            visible: administrador == 1,
        },        
        alunosRemanejamento: {
          id: 'alunosRemanejamento',
          text: 'REMANEJAMENTO',
          path: urlsConfiguracoes.alunosRemanejamento,
          iconName: '',
          icon: 'swap_horiz',
          visible: administrador == 1 && process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEMED',
      },
          excluidos: {
            id: 'alunos',
            text: 'EXCLUÍDOS',
            path: urlsConfiguracoes.alunosExcluidos,
            iconName:'',
            icon: 'delete',
            visible: administrador == 1,
          },
         
        },
      },
      servidores: {
        id: 'servidores',
        text: 'SERVIDORES',
        type: 'title',
        visible: administrador == 1,
        itens: {
          cargos: {
            id: 'vatgos',
            text: 'CARGOS - FUNÇÕES',
            path: urlsConfiguracoes.cargos,
            iconName:'',
            icon: 'event_note',
            visible: administrador == 1,
          },
          servidores: {
            id: 'servidores',
            text: 'SERVIDORES',
            path: urlsConfiguracoes.servidoresExcluidos,
            iconName:'',
            icon: 'delete',
            visible: administrador == 1,
          },
        },
      },
      parametros: {
        id: 'parametros',
        text: 'PARÂMETROS',
        type: 'title',
        visible: administrador == 1,
        itens: {
          unidades: {
            id: 'unidades',
            text: 'UNIDADES',
            path: urlsConfiguracoes.unidades,
            iconName:'',
            icon: 'domain',
            visible: administrador == 1,
          },
          distritos: {
            id: 'distritos',
            text: 'DISTRITOS',
            path: urlsConfiguracoes.distritos,
            iconName:'',
            icon: 'domain',
            visible: administrador == 1,
          },
          escolaridade: {
            id: 'escolaridade',
            text: 'ESCOLARIDADES',
            path: urlsConfiguracoes.escolaridades,
            iconName:'',
            icon: 'school',
            visible: administrador == 1,
          },
          turnos: {
            id: 'turnos',
            text: 'TURNOS',
            path: urlsConfiguracoes.turnos,
            iconName:'',
            icon: '12mp',
            visible: administrador == 1,
          },
          disciplina: {
            id: 'disciplina',
            text: 'DISCIPLINAS',
            path: urlsConfiguracoes.disciplinas,
            iconName:'',
            icon: 'collections_bookmark',
            visible: administrador == 1,
          },
          responsavel: {
            id: 'responsavel',
            text: 'RESPONSÁVEIS',
            path: urlsConfiguracoes.responsaveis,
            iconName:'',
            icon: 'warning',
            visible: administrador == 1,
          },
          
          nacionalidades: {
            id: 'vatgos',
            text: 'NACIONALIDADES',
            path: urlsConfiguracoes.nacionalidades,
            iconName:'',
            icon: 'event_note',
            visible: administrador == 1,
        },
        bnccFundamental: {
          id: 'bncc',
          text: 'BNCC FUNDAMENTAL',
          path: urlsConfiguracoes.bnncFundamental,
          iconName:'',
          icon: 'collections_bookmark',
          visible: administrador == 1,
      },
      webCam: {
        id: 'webCam',
        text: 'WEBCAM',
        path: urlsConfiguracoes.webcam,
        iconName:'',
        icon: 'collections_bookmark',
        visible: administrador == 1,
    },
  //   chatIterativo: {
  //     id: 'chatIterativo',
  //       text: 'Chat',
  //       path: urlsConfiguracoes.chat,
  //       icon: 'chat',
  //       visible: administrador === 1,
  //   },
  //   mapa: {
  //     id: 'mapa',
  //     text: 'MAPA',
  //     path: urlsConfiguracoes.mapas,
  //     icon: 'collections_bookmark',
  //     visible: administrador == 1,
  // },
          
    }
},
   
    }
  )
};

export default linksConfiguracoes;
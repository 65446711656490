import { useEffect, useMemo, useRef, useState } from "react";
import Breadcrumb from "../../components/breadcrumb";
import Table from "../../components/table";
import BaseBiblioteca from "./baseBiblioteca";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { deleteLivro, getLivros, alocarLivro } from "../../services/biblioteca";
import Spinner from "../../components/spinner";
import { getUnidades } from "../../services/unidades";
import { toast } from "react-toastify";
import urlsBiblioteca from "./urlsBiblioteca";
import Select from "react-select";
import { Button, Modal, Dropdown } from "react-bootstrap";

const BibliotecaPage = (gestao) => {
  const unidadeID = localStorage.getItem("unidadeID");

  const navigate = useNavigate();
  const [newData, setNewData] = useState([]);
  const [search, setSearch] = useState("");
  const [warningControl, setWarningControl] = useState(false);
  const [unidades, setUnidades] = useState([]);
  const [unidadesModal, setUnidadesModal] = useState([]);
  const [show, setShow] = useState(false);
  const [livro, setLivro] = useState("");
  const [quantidade, setQuantidade] = useState("");
  const [error, setError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [unidadeSelected, setUnidadeSelected] = useState(
    gestao.gestao ? 0 : unidadeID
  );
  const [unidadeModalSelected, setUnidadeModalSelected] = useState(
    gestao.gestao ? 0 : unidadeID
  );

  const onMenuOpen = () => setIsOpen(true);
  const onMenuClose = () => {
    // setUnidadeModalSelected(0);
    setIsOpen(false);
  };

  // table ref
  const tableRef = useRef(null);

  // table download
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "livros",
    sheet: "Livros",
  });

  const { isLoading: loadingUnidades, refetch: refetchUnidades } = useQuery(
    "getUnidades",
    () => getUnidades(),
    {
      enabled: true,
      retry: true,
      onSuccess: (data) => {
        var dados = [{ value: 0, label: "TODOS" }];
        let dadosModal = [{ value: 0, label: "SELECIONE UMA UNIDADE" }];
        data.forEach((item) => {
          dados.push({ value: item.id, label: item.nome });
          dadosModal.push({ value: item.id, label: item.nome });
        });

        setUnidades(dados);
        setUnidadesModal(dadosModal);
      },
    }
  );

  const editarUrl = urlsBiblioteca.editar;
  const adicionarUrl = urlsBiblioteca.adicionar;
  const BaseComponent = BaseBiblioteca;
  const BasePath = "Biblioteca";
  const situacaoLivros = [
    { value: 0, label: "TODOS" },
    { value: 1, label: "RUIM" },
    { value: 2, label: "REGULAR" },
    { value: 3, label: "BOM" },
    { value: 4, label: "ÓTIMO" }
  ]

  const { refetch, isLoading } = useQuery(
    ["getLivros", skip, limit],
    () => getLivros(skip, limit),
    {
      onSuccess: (data) => {
        setNewData(data);
      },
      enabled: true,
      retry: false,
    }
  );

  const deletarLivro = async (id) => {
    try {
      await deleteLivro(id);
      toast.success("Livro deletado com sucesso.");
      refetch();
    } catch (err) {
      toast.error("Falha na exclusão do livro. Tente novamente mais tarde!");
    }
  };

  useEffect(() => {
    refetch();
  }, [skip, limit]);

  const ActionsMenu = ({ row }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          Ações
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              setShow(!show);
              setLivro(row.original);
            }}
          >
            <i className="material-icons-outline md-assignment"></i> Alocar
          </Dropdown.Item>
          <Dropdown.Item onClick={() => navigate(editarUrl + row.original.id)}>
            <i className="material-icons md-edit_note"></i> Editar
          </Dropdown.Item>
          {gestao.gestao && (
            <Dropdown.Item onClick={() => deletarLivro(row.original.id)}>
              <i className="material-icons md-group_remove"></i> Excluir
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const columns = useMemo(
    () => [
      { Header: "#", accessor: "id" },
      { Header: "Título", accessor: "titulo" },
      { Header: "Ano", accessor: "ano" },
      { Header: "Quantidade", accessor: "quantidade" },
      // {
      //   Header: "Estado do Livro",
      //   accessor: "estado_do_livro",
      //   Cell: ({ row }) => {
      //     const estadoDoLivro = row.original.estado_do_livro;
      //     const getBadgeClass = (estadoDoLivro) => {
      //       switch (estadoDoLivro) {
      //         case 1:
      //           return "danger";
      //         case 2:
      //           return "primary";
      //         case 3:
      //           return "primary";
      //         case 4:
      //           return "success";
      //         default:
      //           return "primary";
      //       }
      //     };
      //     const getBadgeText = (estadoDoLivro) => {
      //       switch (estadoDoLivro) {
      //         case 1:
      //           return "RUIM";
      //         case 2:
      //           return "REGULAR";
      //         case 3:
      //           return "BOM";
      //         case 4:
      //           return "ÓTIMO";
      //         default:
      //           return "DESCONHECIDO";
      //       }
      //     };
      //     return (
      //       <span className={`badge bg-${getBadgeClass(parseInt(estadoDoLivro))}`}>
      //         {getBadgeText(parseInt(estadoDoLivro))}
      //       </span>
      //     );
      //   },
      // },
      {
        Header: "Unidade",
        accessor: "unidade",
        Cell: ({ row }) => {
          return row.original.unidadeDescricao;
        },
      },
      {
        Header: "Ações",
        accessor: "actions",
        Cell: ({ row }) => <ActionsMenu row={row} />,
      },
    ],
    [navigate, editarUrl, show, gestao.gestao]
  );

  const handleSearchButton = () => {
    if (search.length >= 3) {
      refetch();
    }
  };

  const handleAlocar = async (e) => {
    debugger
    const data = {
      livroId: livro.id,
      unidadeId: unidadeModalSelected,
    }
    const response = await alocarLivro(data)  

    if (response.status === 200 || response.status === 201) {
      toast.success("Livro alocado com sucesso.");
      setShow(false);
      refetch();
    }
    else{
      toast.error("Falha na alocação do livro. Tente novamente mais tarde!");
    }
  }

  // useEffect(() => {
  //   debugger
  //   if (unidadeModalSelected === 0) {
  //     setError("Você precisa selecionar uma unidade");
  //   } else {
  //     setError("");
  //   }
  // }, [unidadeModalSelected]);

  const handleChange = (selectedOption) => {
    setUnidadeModalSelected(selectedOption.value);
  };

  return (
    <BaseComponent>
      <Breadcrumb
        title={"Biblioteca"}
        itens={[BasePath, "Biblioteca", "Lista Livros"]}
        actions={{ link: adicionarUrl, text: "Cadastrar Livro" }}
        hasExcelButton
        onClickDownload={onDownload}
      />

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Alocar Livro - {livro.titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <form id="form">
              <div className="form-group">
                <label htmlFor="unidadeId" className="form-label">
                  UNIDADE
                </label>
                <Select
                  aria-labelledby="aria-label"
                  inputId="aria-example-input"
                  name="aria-live-color"
                  onMenuOpen={onMenuOpen}
                  onMenuClose={onMenuClose}
                  options={unidadesModal}
                  // value={unidadesModal.find(
                  //   (x) => x.value === unidadeModalSelected
                  // )}
                  placeholder=""
                  onChange={handleChange}
                />
                {error && <div style={{ color: "red" }}>{error}</div>}
              </div>
            </form>
          </div>
          <div className="row">
            <div className="form-group" style={{ width: "100%" }}>
              <label htmlFor="quantidade" className="form-label">
                QUANTIDADE
              </label>
              <input
                className="form-control"
                type="number"
                id="quantidade"
                value={livro.quantidade}
                readOnly
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary"  onClick={(e) => setShow(false)}>
  Fechar
</Button>
          <Button
            type="submit"
            variant="primary"
            disabled={error ? true : false}
            onClick={(e) => handleAlocar()}
          >
            Alocar
          </Button>
        </Modal.Footer>
      </Modal>

      <div style={{ marginTop: 10, marginBottom: 20 }}>
        <div className="row align-items-center">
          {/* <div className="col-12 col-md-5">
            <div className="form-group">
              <label htmlFor="unidadeId" className="form-label">
                Unidades
              </label>
              <Select
                aria-labelledby="aria-label"
                inputId="aria-example-input"
                name="aria-live-color"
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                options={unidades}
                defaultValue={0}
                value={unidades.find((x) => x.value === unidadeSelected)}
                placeholder=""
                onChange={async (e) => {
                  setUnidadeSelected(e.value);
                }}
              />
            </div>
          </div> */}

          {/* <div className="col-12 col-md-2">
            <div className="form-group">
              <label htmlFor="regimeId" className="form-label">
                Situação
              </label>
              <Select
                aria-labelledby="aria-label"
                inputId="aria-example-input"
                name="aria-live-color"
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                options={situacaoLivros}
                placeholder=""
              />
            </div>
          </div> */}

          {/* <div className="col-12 col-md-4">
            <div className="form-group">
              <label htmlFor="regimeId" className="form-label">
                Pesquisar
              </label>
              <input
                type="text"
                className="form-control"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onMouseEnter={() => setWarningControl(true)}
                onMouseOut={() => setWarningControl(false)}
              />
            </div>
          </div> */}

          {/* <div className="col-12 col-md-1 d-flex align-items-center justify-content-center">
            <div className="form-group" style={{ marginTop: "20px" }}>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => handleSearchButton()}
              >
                <i className="material-icons">search</i> Buscar
              </button>
            </div>
          </div> */}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            {isLoading ? (
              <Spinner />
            ) : (
              <Table
                columns={columns}
                data={newData ? newData : []}
                hasPagination
                limit={limit}
                setLimit={setLimit}
                skip={skip}
                setSkip={setSkip}
                totalItems={newData?.total}
                ref={tableRef}
              />
            )}
          </div>
        </div>
      </div>
    </BaseComponent>
  );
};

export default BibliotecaPage;

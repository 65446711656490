import Breadcrumb from "../../../components/breadcrumb";
import BaseGestaoUnidades from '../../gestao-unidades/baseGestaoUnidades';
import FormInput from '../../../components/formInput'
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import urlsGestaoUnidades from '../../gestao-unidades/urlsGestaoUnidades';
import { useMutation } from 'react-query';
import { store } from '../../../store/index'

import { recusarTransferencia } from '../../../services/alunos';



const TransferenciasCancelamentoPage = (gestao) => {

  const currentUser = store.getState()['user']['user']

   const { register, handleSubmit, setValue, formState: { isValid, errors }, watch } = useForm({ mode: "onSubmit" });

   const { state, pathname } = useLocation();

   const unidadeId = localStorage.getItem('unidadeID');

   const navigate = useNavigate();

   const { mutate } = useMutation(recusarTransferencia, {
    onSuccess: (message) => {
      toast.success(message);
      navigate(urlsGestaoUnidades.transferenciasRecebidas);
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar enturmar, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });



   const onSubmit = data => {

    const requestData = {
      alunoId: state.id,
      unidadeOrigem: parseInt(unidadeId),
      motivo: data.motivo,
      usuarioId: parseInt(currentUser?.servidorId)
    }
    console.log(requestData)
    mutate(requestData);
  }

 




   useEffect(() => {
    setValue('id', state.id)
    setValue('nome', state.nome)
    setValue('unidadeAnteriorId', state.unidadeAnterior)
    setValue('unidadeAnteriorDescricao', state.unidadeAnteriorDescricao)
    setValue('escolaridadeProximaId', state.escolaridadeProxima)
    setValue('escolaridadeProximaDescricao', state.escolaridadeProximaDescricao)
  }, [state, pathname, setValue])


  return (
    <BaseGestaoUnidades>
      <Breadcrumb title={'Transferências Internas'} itens={['Gestão Unidades', 'Transferências Internas', 'Aprovação']} />
      <form id='form' onSubmit={handleSubmit(onSubmit)}>
      <div className='row'>
          <div className='col-12'>
        <div className='card'>
            <div className="card-body">
            <div className='row'>
                    <div className='col-2'>
                      <FormInput
                        register={register}
                        errors={errors}
                        group=""
                        atribute="id"
                        label="ID"
                        readOnly
                      />
                    </div>
                    <div className='col-4'>
                      <FormInput
                        register={register}
                        errors={errors}
                        group=""
                        atribute="nome"
                        label="Nome"
                        readOnly
                      />
                    </div>
                    <div className='col-3'>
                      <FormInput
                        register={register}
                        errors={errors}
                        group=""
                        atribute="unidadeAnteriorDescricao"
                        label="Unidade Anterior"
                        readOnly                      />
                    </div>

                    <div className='col-3'>
                      <FormInput
                        register={register}
                        errors={errors}
                        group=""
                        atribute="escolaridadeProximaDescricao"
                        label="Escolaridade Pretendida"
                        readOnly                      />
                    </div>
                    
                  </div>


                  <div className="row" style={{paddingTop: '20px'}}>
                  <div className='col-12'>
                      <FormInput
                        register={register}
                        errors={errors}
                        group=""
                        atribute="motivo"
                        label="Motivo"
                                              />
                    </div>
   
          </div>

          <div className='row'>
          <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginRight: 30,
                marginTop: 20
              }}>
              <button 
                    style={{marginRight: 20}} 
                    type='button' 
                    className='btn btn-warning' 
                    onClick={() => navigate(-1)}>
                        Voltar
                      </button>
                <button 
                type='submit' 
                className='btn btn-success' 
                // disabled={!isValid}
                >
                  Enviar
                </button>
              </div>
          </div>
    </div>

        </div>
        </div>
    </div>
    </form>
    </BaseGestaoUnidades>
  )

};

export default TransferenciasCancelamentoPage;

import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import "./estilo.css";
// import {getUnidades} from '../../services/unidades'
import {
  getEscolaridades as todasEscolaridades,
} from "../../services/escolaridades";
import {
  getTurnos as todosTurnos,
} from "../../services/turnos";

const RelatorioPage = () => {
  const [storage, setStorage] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("report") != null) {
      setStorage(JSON.parse(localStorage.getItem("report")));
      setTimeout(() => {
        window.print();
        localStorage.removeItem("report");
      }, 2000);
    } else {
      refetchEscolaridades();
      refetchTurnos();
      setStorage(JSON.parse(localStorage.getItem("reportTurmas")));
      setTimeout(() => {
        window.print();
        localStorage.removeItem("reportTurmas");
      }, 2000);
    }
  }, []);

  const { data: escolaridades, refetch: refetchEscolaridades } = useQuery(
    "getEscolaridades",
    () => todasEscolaridades(),
    {
      enabled: true,
    }
  );

  const { data: turnos, refetch: refetchTurnos } = useQuery(
    "getTurnos",
    () => todosTurnos(),
    {
      enabled: true,
    }
  );

  if (localStorage.getItem("report") != null) {
    return (
      <>
        <div
          className="class-print"
          style={{ padding: "10px 30px", width: "100%" }}
        >
          <div className="row">
            <div className="col-md-12">
              <img
                src={storage?.logo}
                height={90}
                className="center"
                style={{ marginBottom: "20px", marginTop: "0" }}
              />
            </div>
          </div>
          <div className="row box-info" style={{ marginBottom: "0" }}>
            <div className="col-md-12 d-flex align-items-center justify-content-center">
              <p className="title">
                RELATÓRIO DE ALUNOS DA TURMA {storage?.nomeTurma}
              </p>
            </div>
          </div>
          <div
            className="row box-info"
            style={{ height: "140px", marginBottom: "0" }}
          >
            <div className="col-md-12">
              <div
                className="row"
                style={{
                  height: "25px",
                  marginLeft: "0",
                  marginTop: "8px",
                  justifyItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className="title">UNIDADE: {storage?.nomeUnidade}</p>
              </div>
              <div
                className="row"
                style={{
                  height: "25px",
                  marginLeft: "0",
                  marginTop: "32px",
                  justifyItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className="title" style={{ float: "left", width: "auto" }}>
                  TURMA: {storage?.nomeTurma}
                </p>
                <p className="title" style={{ float: "left", width: "auto" }}>
                  TURNO: {storage?.nomeTurno}
                </p>
              </div>
              <div
                className="row"
                style={{
                  height: "25px",
                  marginLeft: "0",
                  marginTop: "8px",
                  justifyItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className="title" style={{ float: "left", width: "auto" }}>
                  ESCOLARIDADE: {storage?.nomeEscolaridade}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="row" style={{ marginTop: 10 }}>
                <div className="col-12">
                  <table className="tbmVisao">
                    <thead>
                      <tr style={{ paddinfLeft: 50 }}>
                        <th>Matrícula</th>
                        <th>Nome</th>
                        <th>PcD</th>
                        <th>Nascimento</th>
                      </tr>
                    </thead>
                    <tbody>
                      {storage?.dados.map((aluno) => {
                        // linha = linha + 1
                        return (
                          <tr>
                            <td>{aluno.matricula}</td>
                            <td>{aluno.nome}</td>
                            <td>{aluno.pne}</td>
                            <td>{aluno.nascimento}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row footer-report"
          style={{ marginTop: "10px", position: "fixed" }}
        >
          <p style={{ float: "left", width: "200px" }}>
            {new Date().toLocaleDateString() +
              " " +
              new Date().toLocaleTimeString()}
          </p>
          <p style={{ textAlign: "right", width: "520px" }}>
            {
              JSON.parse(JSON.parse(localStorage.getItem("persist:root"))?.user)
                ?.user?.usuarioLogin
            }
          </p>
        </div>
      </>
    );
  } else {
    return (
      <>
        <style>
          {`
          @media print {
            @page {
              size: landscape;
            }
          }
        `}
        </style>
        <div
          className="class-print"
          style={{ padding: "10px 30px", width: "100%" }}
        >
          <div className="row">
            <div className="col-md-12">
              <img
                src={storage?.logo}
                height={90}
                className="center"
                style={{ marginBottom: "20px", marginTop: "0" }}
              />
            </div>
          </div>
          <div className="row box-info" style={{ marginBottom: "0" }}>
            <div className="col-md-12 d-flex align-items-center justify-content-center">
              <p className="title">
                RELATÓRIO DE TURMAS{" "}
                {storage?.nomeUnidade
                  ? `UNIDADE: ${storage?.nomeUnidade}`
                  : null}
              </p>
            </div>
          </div>
          {storage?.escolaridade || storage?.nomeUnidade ? (
            <div
              className="row box-info"
              style={{ height: "140px", marginBottom: "0" }}
            >
              <div className="col-md-12">
                <div
                  className="row"
                  style={{
                    height: "25px",
                    marginLeft: "0",
                    marginTop: "32px",
                    justifyItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {storage?.nomeUnidade && (
                    <p
                      className="title"
                      style={{ float: "left", width: "auto" }}
                    >
                      UNIDADE: {storage?.nomeUnidade}
                    </p>
                  )}
                  {storage?.escolaridade && (
                    <p
                      className="title"
                      style={{ float: "left", width: "auto" }}
                    >
                      ESCOLARIDADE: {storage?.escolaridade}
                    </p>
                  )}
                </div>
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="col-md-12">
              <div className="row" style={{ marginTop: 10 }}>
                <div className="col-12">
                  <table className="tbmVisao">
                    <thead>
                      <tr style={{ paddinfLeft: 50 }}>
                        {storage?.nomeUnidade == "" && <th>Unidade</th>}
                        {storage?.escolaridade == "" && <th>Escolaridade</th>}
                        <th>Turno</th>
                        <th>Turma</th>
                        <th>Vagas Totais</th>
                        <th>Vagas Regulares</th>
                        <th>Vagas PcD</th>
                        <th>Alunos Regulares</th>
                        <th>Alunos PcD</th>
                        <th>Saldo</th>
                      </tr>
                    </thead>
                    <tbody>
                      {storage?.dados
                        .sort((a, b) => {
                          if (a.unidadeId !== b.unidadeId) {
                            return a.unidadeId - b.unidadeId;
                          } else if (a.escolaridadeId !== b.escolaridadeId) {
                            return a.escolaridadeId - b.escolaridadeId;
                          } else {
                            return a.turnoId - b.turnoId;
                          }
                        })
                        .map((turma) => {
                          // linha = linha + 1
                          return (
                            <tr>
                              {storage?.nomeUnidade == "" && (
                                <td>
                                  {
                                    storage?.todasUnidades.find(
                                      (x) => x.value == turma.unidadeId
                                    )?.label
                                  }
                                </td>
                              )}
                              {storage?.escolaridade == "" && (
                                <td>
                                  {
                                    escolaridades?.find(
                                      (x) => x.id == turma.escolaridadeId
                                    )?.descricao
                                  }
                                </td>
                              )}
                              <td>
                                {
                                  turnos?.find((x) => x.id == turma.turnoId)
                                    ?.descricao
                                }
                              </td>
                              <td>{turma?.nomeTurma}</td>
                              <td>{turma?.vagasTotais}</td>
                              <td>{turma?.vagasRegulares}</td>
                              <td>{turma?.vagasPcd}</td>
                              <td>{turma?.alunosRegulares}</td>
                              <td>{turma?.alunosPcd}</td>
                              <td
                                style={{
                                  color: turma.saldo < 0 ? "red" : "inherit",
                                  fontWeight:
                                    turma.saldo < 0 ? "bold" : "normal",
                                }}
                              >
                                {turma?.saldo}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row footer-report"
          style={{ marginTop: "10px", position: "fixed" }}
        >
          <p style={{ float: "left", width: "200px" }}>
            {new Date().toLocaleDateString() +
              " " +
              new Date().toLocaleTimeString()}
          </p>
          <p style={{ textAlign: "right", width: "520px" }}>
            {
              JSON.parse(JSON.parse(localStorage.getItem("persist:root"))?.user)
                ?.user?.usuarioLogin
            }
          </p>
        </div>
      </>
    );
  }
};

export default RelatorioPage;

import LoginRoutes from "./routes/login";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import AppRoutes from "./routes";
import ErrorBoundary from "./utils/errorBoundary";
// import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    return (
        <Router>
            <div className="App">
                {/* Wrap AppRoutes in ErrorBoundary */}
                <ErrorBoundary>
                    <AppRoutes />
                </ErrorBoundary>
            </div>

            <ToastContainer position='bottom-right' theme='colored' containerId='form' />
        </Router>
    )
}

export default App;

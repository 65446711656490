import React, { useEffect, useState } from 'react'
import FormInput from '../../../../../../../components/formInput';
import InputWithSelect from '../../../../../../../components/inputWithSelect';
import api from '../../../../../../../services';

export default function DadosPessoais({ register, errors, setValue, watch, fornecedorData, readOnly }) {
  const [options, setOptions] = useState();
  const [selected, setSelected] = useState();
  const [controlVariable, setControlVariable] = useState(true)

  let fornecedor = watch('nome');

  useEffect(() => {
    if (fornecedor) {
      if (fornecedor === '') {
        setOptions()
        return
      }
      if (fornecedor.length <= 2) {
        setOptions()
        return
      }
      if (fornecedor.length >= 3) {
        getListaFornecedoresFiltrada(fornecedor)
      }
    }
  }, [fornecedor])

  useEffect(() => {
    if (fornecedorData && options === undefined) {
      getListaFornecedoresFiltrada(fornecedorData);
    }
    if (fornecedorData && options && (selected !== undefined)) {
      setSelected(options[0])
    }
    if (fornecedorData && options && controlVariable) {
      setSelected(options[0])
      setControlVariable(false)
    }
  }, [fornecedorData, options])

  const getListaFornecedoresFiltrada = async (fornecedor) => {
    const response = await api.get(`fornecedor/all?nome=${fornecedor}`);
    setOptions(response.data.data)
  }

  useEffect(() => {
    if (selected) {
      const { id, nomeFantasia } = selected.cadastro;
      const { bairro, cep, cidade, complemento, email, estado, logradouro, numero, telefone } = selected.contato;
      console.log(numero)
      setValue('id', id)
      setValue('nome', nomeFantasia)
      setValue('email', email)
      setValue('telefone', telefone)
      setValue('cep', cep)
      setValue('estado', estado)
      setValue('cidade', cidade)
      setValue('bairro', bairro)
      setValue('logradouro', logradouro)
      setValue('numero', numero === 0 ? '' : parseInt(numero))
      setValue('complemento', complemento)
      // setOptions()
    }
  }, [selected, setValue])

  return (
    <div
      className="tab-pane fade show active"
      id="dadosPessoais"
      role="tabpanel"
      aria-labelledby="dadosPessoais-tab"
    >
      <div className="card-body">
        <div className="row">
          <div className="col-md-1">
            {
              selected && (
                <FormInput
                  register={register}
                  errors={errors}
                  atribute="id"
                  label="ID"
                  readOnly={true}
                  required={false}
                />
              )
            }

          </div>
          <div className={selected ? "col-md-11" : "col-md-12"}>
            <InputWithSelect
              register={register}
              errors={errors}
              atribute="nome"
              label="Nome do fornecedor"
              options={options}
              name={fornecedor}
              selected={setSelected}
              isSelected={selected ? true : false}
              readOnly={fornecedorData ? !readOnly : false}
            />
          </div>
        </div>
        {
          selected && (
            <>
              <div className="row" style={{ marginTop: '25px' }}>
                <div className="col-md-6">
                  <FormInput
                    register={register}
                    errors={errors}
                    atribute="email"
                    label="Email"
                    readOnly
                    required={false}
                  />
                </div>
                <div className="col-md-6">
                  <FormInput
                    register={register}
                    errors={errors}
                    atribute="telefone"
                    label="Telefone"
                    readOnly
                    required={false}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: '25px' }}>
                <div className="col-md-3">
                  <FormInput
                    register={register}
                    errors={errors}
                    atribute="cep"
                    label="CEP"
                    readOnly
                    required={false}
                  />
                </div>
                <div className="col-md-3">
                  <FormInput
                    register={register}
                    errors={errors}
                    atribute="estado"
                    label="Estado"
                    readOnly
                    required={false}
                  />
                </div>
                <div className="col-md-3">
                  <FormInput
                    register={register}
                    errors={errors}
                    atribute="cidade"
                    label="Cidade"
                    readOnly
                    required={false}
                  />
                </div>
                <div className="col-md-3">
                  <FormInput
                    register={register}
                    errors={errors}
                    atribute="bairro"
                    label="Bairro"
                    readOnly
                    required={false}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: '25px' }}>
                <div className="col-md-4">
                  <FormInput
                    register={register}
                    errors={errors}
                    atribute="logradouro"
                    label="Logradouro"
                    readOnly
                    required={false}
                  />
                </div>
                <div className="col-md-4">
                  <FormInput
                    register={register}
                    errors={errors}
                    atribute="numero"
                    label="Número"
                    readOnly
                    required={false}
                  />
                </div>
                <div className="col-md-4">
                  <FormInput
                    register={register}
                    errors={errors}
                    atribute="complemento"
                    label="Complemento"
                    readOnly
                    required={false}
                  />
                </div>
              </div>
            </>

          )
        }
      </div>
    </div >
  )
}

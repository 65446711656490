import React, { useState } from 'react';
import { Tab, Tabs, Box, Typography, Paper } from '@mui/material';
import DisciplinasForm from './components/DisciplinasForm';
import DisciplinaEscolaridadeTab from './components/DisciplinaEscolaridadeTab';
import BaseConfiguracoes from '../configuracoes/baseConfiguracoes';
import Breadcrumb from '../../components/breadcrumb';
import { useParams } from 'react-router-dom'; // Import useParams to get id from route

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const DisciplinasCadastrarPage = () => {
  const { id } = useParams(); // Get id from route params
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <BaseConfiguracoes>
      <Breadcrumb title="Formulário Disciplinas" itens={['Gestão', 'Secretaria', 'Disciplinas', 'Formulário']} />

      {/* Wrapping everything inside a Paper component */}
      <Paper elevation={3} sx={{ backgroundColor: 'white', padding: 2, borderRadius: '8px' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="DADOS" />
          {/* Conditionally render the "ESCOLARIDADES" tab if id exists */}
          {id && <Tab label="ESCOLARIDADES" />}
        </Tabs>

        <TabPanel value={value} index={0}>
          <DisciplinasForm />
        </TabPanel>

        {/* Conditionally render the TabPanel for Escolaridades if id exists */}
        {id && (
          <TabPanel value={value} index={1}>
            <DisciplinaEscolaridadeTab id={id} />
          </TabPanel>
        )}
      </Paper>
    </BaseConfiguracoes>
  );
};

export default DisciplinasCadastrarPage;

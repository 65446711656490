import React, { useRef, useCallback, useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import { compareFaceAluno, compareFaceServidor, storeFace } from '../services/reconhecimento-facial';
import { toast } from 'react-toastify';
import { listarServidor } from '../services/servidores';
import { getAluno } from '../services/alunos';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';

const WebcamComponent = ({ showCaptureButton, showCompareButton, alunoId, servidorId, usuarioId, unidadeId }) => {
  const webcamRef = useRef(null);
  const [isComparing, setIsComparing] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const storedIsComparing = localStorage.getItem('isComparing');
    if (storedIsComparing !== null) {
      setIsComparing(JSON.parse(storedIsComparing));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('isComparing', JSON.stringify(isComparing));
  }, [isComparing]);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const data = {
      imagem: imageSrc,
      alunoId: alunoId,
      servidorId: servidorId,
      usuarioId: usuarioId,
      imagemId: imageSrc
    };

    storeFace(data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          toast.success('Imagem salva com sucesso');
        } else {
          toast.error('Erro ao salvar imagem');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('Error storing face');
      });
  }, [webcamRef, alunoId, servidorId, usuarioId]);

  const compare = useCallback(async (servidor, aluno) => {
    const imageSrc = webcamRef.current.getScreenshot();
    const data = {
      imagem: imageSrc,
      unidadeId: parseInt(unidadeId)
    };

    try {
      let res;
      if (aluno === true) {
        res = await compareFaceAluno(data);
        if (res.status === 200) {
          const aluno = await getAluno(res.data.alunoId);
          toast.success('CORRESPONDÊNCIA ENCONTRADA: ' + aluno.id + ' ' + aluno.nome);
        } else {
          toast.error('CORRESPONDÊNCIA NÃO ENCONTRADA');
        }
      }
      if (servidor === true) {
        res = await compareFaceServidor(data);
        if (res.status === 200) {
          const servidor = await listarServidor(res.data.servidorId);
          toast.success('CORRESPONDÊNCIA ENCONTRADA: ' + servidor.id + ' ' + servidor.nomeCompleto);
        } else {
          toast.error('CORRESPONDÊNCIA NÃO ENCONTRADA');
        }
      }
      
    } catch (error) {
      console.error('Error:', error);
      toast.error('Erro ao comparar faces');
    } finally {
      setIsComparing(false);
    }
  }, [webcamRef]);

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: 'user',
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', height: '100vh' }}>
      <Alert variant="filled" severity="info" style={{ marginBottom: 20 }}>
        Para melhores resultados, favor não utilizar óculos, boné, chapéu entre outros objetos na hora da foto.
      </Alert>
      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '10px', marginBottom: '20px' }}>
        {showCaptureButton && (
          <Button variant="contained" color="primary" onClick={() => capture()} style={{ margin: '10px' }}>
            Salvar Foto
          </Button>
        )}
        {showCompareButton && (
          <Button variant="contained" color="secondary" onClick={() => compare(false, true)} style={{ margin: '10px' }}>
            Localizar Aluno
          </Button>
        )}
        {showCompareButton && (
          <Button variant="contained" color="secondary" onClick={() => compare(true, false)} style={{ margin: '10px' }}>
            Localizar Servidor
          </Button>
        )}
        <Button variant="contained" color="warning" onClick={() => navigate(-1)} style={{ margin: '10px' }}>
          Voltar
        </Button>
      </div>
      <div style={{ marginTop: 10, textAlign: 'center', alignItems: 'center', width: '100%' }}>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          style={{ width: '60%', height: '60%' }}
          videoConstraints={videoConstraints}
        />
      </div>
    </div>
  );
};

export default WebcamComponent;

import api from "..";

async function getTurnos() {
  return await api
    .get('turno')
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
};

async function deleteTurno(id) {
  return await api
    .delete(`turno/${id}`)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function createTurno(data) {
  return await api
    .post('turno', data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function updateTurno({ id, data }) {
  return await api
    .put(`turno/${id}`, data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function getTurno(id) {
  return await api
    .get(`turno/${id}`)
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
}

async function getTurnosUnidadeEscolaridade(unidade, escolaridade, anoLetivo) {
    return await api
      .get(`turno/unidade/${unidade}/escolaridade/${escolaridade}?anoLetivo=${anoLetivo}`)
      .then(async (res) => {
        const { data } = res.data;
        return data;
      });
  };

export {
  getTurnos,
  deleteTurno,
  createTurno,
  updateTurno,
  getTurno,
  getTurnosUnidadeEscolaridade
}
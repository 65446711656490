import BaseChamadaPublica from "../../baseChamadaPublica"
import Breadcrumb from "../../../../../components/breadcrumb"
import { store } from "../../../../../store"
import { useMemo, useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getUnidadesEscolaridade2024, getUnidades } from "../../../../../services/unidades";
import { useQuery } from 'react-query'
import Select from 'react-select';
import FormInput from "../../../../../components/formInput";
import Table from "../../../../../components/table";
import {getTurmasUnidadeEscolaridadeTurno} from "../../../../../services/turmas";
import { postConvocar } from '../../../../../services/processos/chamadaPublica'

const ChamadaPublicaInscricoesRemanejamentoPage = () => {

  const { id } = useParams();
  const navigate = useNavigate()
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const { state } = useLocation();
  const currentUser = store.getState()['user']['user']
  const [unidades, setUnidades] = useState([])
  const [todasUnidades, setTodasUnidades] = useState([])
  const onMenuOpen = () => setIsOpen(true);
  const onMenuClose = () => setIsOpen(false);
  const [isOpen, setIsOpen] = useState(false);
  const [turmasData, setTurmasData] = useState([])
  const [unidade, setUnidade] = useState(null)


  
  const { } = useQuery('getUnidades', () => getUnidades(), {
    enabled: true,
    retry: true,
    onSuccess: (data) => {
      var dados = [];
      data.forEach(item => {
        dados.push({ value: item.id, label: item.nome })
      });

      setTodasUnidades(dados);
    }
  })

  async function refetchTurmas(unidadeId){
      var turmasResponse = await getTurmasUnidadeEscolaridadeTurno(unidadeId, state.escolaridadeId, 0, 2024, 0, 20)
      setTurmasData(turmasResponse)
    }

    async function convocarAluno(inscricaoId) {
      // Confirm before proceeding
      const isConfirmed = window.confirm("Deseja realmente remanejar este aluno?");
      if (!isConfirmed) {
        // User clicked 'Cancel', do nothing
        return;
      }
      const data = {
        usuarioId: parseInt(currentUser?.servidorId),
        unidadeId: parseInt(unidade),
        inscricaoId: state.id,
        remanejamento: true,
      };
  
      if(parseInt(unidade) == 0 || unidade == null){
        toast.warning('Selecione uma unidade e uma escolaridade para remanejar o aluno.');
        return
      }
    
      const response = await postConvocar(data);
  
      if (response.statusCode !== 200) {
        toast.error('Erro ao remanejar aluno.');
        return;
      }
  
      else{
        toast.success('Aluno remanejar com sucesso.');
        navigate(-1);
      }
  
    
    }


  const {  } = useQuery('getUnidadesEscolaridade2024', () => getUnidadesEscolaridade2024(state.escolaridadeId), {
    enabled: true,
    retry: true,
    onSuccess: (data) => {
      var dados = [];
      data.forEach(item => {
        // Define an array of ids that you want to exclude
      const excludedIds = [state.opcao1, state.opcao2, state.opcao3, 4, 53, 52];

      if (!excludedIds.includes(item.id)) {
          dados.push({ value: item.id, label: item.nome });
      }

      });

      setUnidades(dados);
    }
  })

 


  const { register, handleSubmit, setValue, formState: { isValid, errors }, watch } = useForm({ mode: "onSubmit" });

  // PARA TESTAR A INTEGRAÇÃO, SÓ DESCOMENTAR A LINHA "MUTATE" ABAIXO
  const onSubmit = data => {
    convocarAluno(id);
  }

  const turmasColluns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Nome Turma', accessor: 'descricao' },
      { Header: 'PreMatricula', accessor: 'preMatricula' },
      { Header: 'Vagas Totais' , accessor: 'vagasTotais' },
      // { Header: 'Alunos', accessor: 'quantidadeAlunos' },
      // { Header: 'Sala (m²)', accessor: 'metragemSala' },
      // { Header: 'Ano Letivo', accessor: 'anoLetivo' },
      { Header: 'Vagas Regulares', accessor: 'vagas' },
      { Header: 'Vagas PcD', accessor: 'vagasPcd' },
      { Header: 'Alunos Regulares', accessor: 'quantidadeAlunosRegulares' },
      { Header: 'Alunos PcD', accessor: 'quantidadeAlunosPcdPne' },
      {
        Header: 'Saldo',
        accessor: 'saldo',
        Cell: ({ row }) => {

          let saldo = 0;

          if (process.env.REACT_APP_SECRETARIA_CIDADE === 'ARARUAMA') {
          const vagas = row.original.vagasTotais;
          const alunosRegulares = row.original.quantidadeAlunosRegulares;
          const alunosPcdPne = row.original.quantidadeAlunosPcdPne;
  
          saldo = vagas - (alunosRegulares + alunosPcdPne * 2);
          }

          if (process.env.REACT_APP_SECRETARIA_CIDADE !== 'SEDUC') {
            const vagas = row.original.vagasTotais;
            const alunosRegulares = row.original.quantidadeAlunosRegulares;
            const alunosPcdPne = row.original.quantidadeAlunosPcdPne;
    
            saldo = vagas - (alunosRegulares + alunosPcdPne * 3);
            }
  
          return (
            <span style={{
              color: saldo < 0 ? 'red' : 'inherit',
              fontWeight: saldo < 0 ? 'bold' : 'normal',
            }}>
              {saldo}
            </span>
          );
        },
      },
    ],
    []
  )

  useEffect(() => {
    setValue('id', id)
    setValue('escolaridadeId', state.escolaridadeId)
    setValue('escolaridadeDescricao', state.escolaridadeDescricao)  
    setValue('opcao1', state.opcao1)
    setValue('opcao2', state.opcao2)
    setValue('opcao3', state.opcao3)
    setValue('nome', state.nome)
    setValue('usuarioId', currentUser?.servidorId)
    setValue('motivo', 'REMANEJAMENTO')
    setValue('usuarioLogin', currentUser?.usuarioLogin)
  }, [setValue])


return (
    <BaseChamadaPublica>
      <Breadcrumb
        title={'Inscrições'}
        itens={['Chamada Pública', 'Atualizar', 'Remanejamento', 'Inscrições']} 
      />

      <form id='form' onSubmit={handleSubmit(onSubmit)}>

      <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className="card-body">

              <div className='row'>
              <div className='col-4'>
                <FormInput
                  register={register}
                  errors={errors}
                  group=""
                  atribute="id"
                  label="ID"
                  readOnly
                />
              </div>
              <div className='col-4'>
                <FormInput
                  register={register}
                  errors={errors}
                  group=""
                  atribute="nome"
                  label="Nome"
                  readOnly
                />
              </div>
              <div className='col-4'>
                <FormInput
                  register={register}
                  errors={errors}
                  group=""
                  atribute="escolaridadeDescricao"
                  label="Escolaridade"
                  readOnly
                />
              </div>
              </div>
              <div className='row' style={{marginTop: 20}}>
              <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="unidadeId" className="form-label">
              Primeira Opção
            </label>
            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={todasUnidades}
              placeholder='' 
              value={todasUnidades.find(x => x.value == state.opcao1)}
              isDisabled={true}            
              onChange={async e => {

              }
              }
            />
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="unidadeId" className="form-label">
              Segunda Opção
            </label>
            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={todasUnidades}
              isDisabled={true}
              value={todasUnidades.find(x => x.value == state.opcao2)}
              placeholder=''             
              onChange={async e => {

              }
              }
            />
          </div>
        </div>
        </div>
        <div className='row' style={{marginTop: 20}}>

        <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="unidadeId" className="form-label">
              Terceira Opção
            </label>
            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={todasUnidades}
              placeholder='' 
              value={todasUnidades.find(x => x.value == state.opcao3)}
              isDisabled={true}           
              onChange={async e => {

              }
              }
            />
          </div>
        </div>
              <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="unidadeId" className="form-label">
              Unidade Remanejamento
            </label>
            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={unidades}
              placeholder=''             
              onChange={async e => {
                setUnidade(e.value)
                refetchTurmas(e.value)
              }
              }
            />
          </div>
        </div>

        <div className='row' style={{marginTop: 20}}>
        <div className='col-6'>
                <FormInput
                  register={register}
                  errors={errors}
                  group=""
                  atribute="motivo"
                  label="Motivo"
                  readOnly
                />
              </div>
              <div className='col-6'>
                <FormInput
                  register={register}
                  errors={errors}
                  group=""
                  atribute="usuarioLogin"
                  label="Usuário"
                  readOnly
                />
              </div>
          </div>

          <div 
                    style={{ 
                      display: 'flex', 
                      justifyContent: 'flex-end',
                      marginRight:30,
                      marginTop: 20,
                     }}>
                    <button 
                    style={{marginRight: 20}} 
                    type='button' 
                    className='btn btn-warning' 
                    onClick={() => navigate(-1)}>
                        Voltar
                      </button>
                      <button type='submit' className='btn btn-success' disabled={!isValid}>
                        Remanejar
                      </button>
                    </div>
          
          {
  turmasData && turmasData.data && turmasData.data.length > 0 && (
    <div className="row" style={{marginTop: 20}}>
      <div className="col-12">
        <h4>Dados Turmas</h4>
        <div className="card">
          <Table
            columns={turmasColluns}
            data={turmasData.data}
            hasPagination
            limit={limit}
            setLimit={setLimit}
            skip={skip}
            setSkip={setSkip}
            totalItems={turmasData.total}
            hiddenColluns={['preMatricula']}
          />
        </div>
      </div>
    </div>
  )
}
                </div>

              </div>
            </div>
          </div>
        </div>
      </form>
      </BaseChamadaPublica>
)
}

export default ChamadaPublicaInscricoesRemanejamentoPage
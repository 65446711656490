import Breadcrumb from '../../../../../../components/breadcrumb'
import BaseChamadaPublica from '../../../baseChamadaPublica';
import Alert from '@mui/material/Alert';
import Table from '../../../../../../components/table';
import { useMemo, useState,useEffect } from 'react';
import { getInscricoesRelatorio } from '../../../../../../services/processos/chamadaPublica';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import handleDownloadExcel from '../../../../../../utils/handleDownloadExcel';


const RelatorioInscritosPorTransInterna = () => {

  const [skip, setSkip] = useState(0);
  const [exportar, setExportar] = useState(0);
  const [limit, setLimit] = useState(20);
  const [status, setStatus] = useState(1);
  const [data, setData] = useState([]);
  const nomeArquivo =  'relatotio-chamada-publica-inscritos-transferencia-interna-quantitativo.xlsx'

  const columns = useMemo(
    () => [
    { Header: 'Unidade', accessor: 'unidadeDescricao' },
    { Header: 'Ano Escolaridade', accessor: 'escolaridadeDescricao' },
    // { Header: 'Turno', accessor: 'turnoDescricao' },
    { Header: 'Quantidade', accessor: 'quantidade' },
    ],
    []
)

async function fetchInscricoes(){
  var response = await getInscricoesRelatorio(0, 0, 0, 1, 0, '', skip, limit, exportar, status)
  setData(response || [])
}

const exportarDados = async () => {
    
  if(data.length === 0 || data?.total === 0){
    return toast.warning('Não há dados para exportar.')
  }

  try {

  const relatorioData = await getInscricoesRelatorio(0, 0, 0, 1, 0, '', skip, limit, exportar, status)

  if (relatorioData) {
    return handleDownloadExcel(relatorioData, nomeArquivo);
  } else {
      return toast.error('Falha ao obter o relatório');
      // Tratar falha na obtenção do relatório
  }
  } catch (error) {
    return toast.error('Erro ao buscar relatório');
    // Tratar erro na chamada da função
  }
 
  // return await handleDownloadExcel(relatorioData, titulo);
}

useEffect(() => {
  fetchInscricoes()
}, [limit, skip])



return (
    <BaseChamadaPublica>
      <Breadcrumb
        title={'POR TRANS. INTERNA - QUANTITATIVO'}
        itens={['CHAMADA PUBLICA', 'RELATÓRIOS', 'INSCRITOS', 'POR TRANS. INTERNA - QUANTITATIVO']}
        hasExcelButton
        onClickDownload={() => exportarDados()} 
        />

<Alert variant="filled" severity="info" style={{marginBottom: 20}}>Resultado com base nas inscrições como primeira opção.</Alert>

<div className="row">
        <div className="col-12">
          <div className="card">
        <Table
              columns={columns}
              data={data.data ? data.data : []}
              hasPagination
              limit={limit}
              setLimit={setLimit}
              skip={skip}
              setSkip={setSkip}
              totalItems={data?.total}
            />
            </div>
            </div>
            </div>

      </BaseChamadaPublica>
)
}

export default RelatorioInscritosPorTransInterna
import api from "../..";

async function postMemorando(data) {
  return await api
    .post('patrimonio/memorando', data)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
};

async function postAnexo(data) {
  return await api
    .post('arquivo/memorandos', data)
    .then(async (res) => {
      const data = res.data.data;
      return data;
    });
};

async function getMemorandos() {
  return await api
    .get('patrimonio/memorando')
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function getMemorando(id) {
  return await api
    .get(`patrimonio/memorando/${id}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    })
}

async function putMemorando({ id, data }) {
  return await api
    .put(`patrimonio/memorando/${id}`, data)
    .then(async (res) => {
      const data = res.data.data;
      return data;
    })
}

async function deleteMemorando(id) {
  return await api
    .delete(`patrimonio/memorando/${id}`)
    .then(async (res) => {
      const data = res.data.data;
      return data;
    })
}

export {
  postMemorando,
  postAnexo,
  getMemorandos,
  getMemorando,
  putMemorando,
  deleteMemorando
}
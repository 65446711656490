import React from 'react';
import { Routes, Route } from 'react-router-dom';
import FamiliaConectadaPais from '../../pages/familia-conectada/pais';


const FamiliaConectadaRoutes = () => (
    <React.Fragment>
        <Route path={'familia-conectada/responsavel'} element={<FamiliaConectadaPais />} /> 
    </React.Fragment>
);

export default FamiliaConectadaRoutes;

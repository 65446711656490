import urlsBiblioteca from "./urlsBiblioteca";

const linksBilioteca = () => {
  return (
    {
      biblioteca: {
        id: 'biblioteca',
        text: 'BIBLIOTECA',
        type: 'title',
        visible: true,
        itens: {
          dashboard: {
            id: 'dashboard',
            text: 'DASHBOARD',
            path: urlsBiblioteca.dashboard,
            iconName:'',
            icon: 'dashboard',
            visible: true,
          },
          livros: {
            id: 'livros',
            text: 'LIVROS',
            path: urlsBiblioteca.livros,
            iconName:'',
            icon: 'books',
            visible: true,
          },
          movimentacoes: {
            id: 'emprestimos',
            text: 'EMPRESTIMOS',
            path: urlsBiblioteca.emprestimo,
            iconName:'',
            icon: 'request_quote',
            visible: true,
          },
         
         
        }
      },   
    }
  )
};

export default linksBilioteca;
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import RelatorioPage from '../../pages/relatorio';
import urls from './../../utils/urls' 

const defaultPermisson = true;

const RelatorioRoutes = () => (
    <React.Fragment>
        <Route path={urls.relatorio} element={<RelatorioPage />} />
    </React.Fragment>
);

export default RelatorioRoutes;

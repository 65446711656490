import React, { useEffect, useRef, useState } from "react";
import BaseGestao from "../../gestao/baseGestao";
import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import Breadcrumb from "../../../components/breadcrumb";
import { useForm } from "react-hook-form";
import { useReactToPrint } from "react-to-print";
import "./style.css";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { listarAtaResultadoFinalInfantil } from "../../../services/ficha-individual";
import SalvarFichaFormResultadoFinal from "../salvarResultado/SalvarFichaFormResultadoFinal";




const AtaResultadoFinalInfantil = ({gestao, turmaId, escolaridadeDescricao, turnoDescricao, turmaDescricao, unidadeDescricao}) => {
  const BaseComponent = gestao ? BaseGestao : BaseGestaoUnidades;
  const BasePath = gestao ? "Gestão" : "Gestão Unidades";
  const printRef = useRef(null);

  const [data, setData] = useState([]);
  const [dados, setDados] = useState({});

  useEffect(() => {
    listarAtaResultadoFinalInfantil(turmaId).then((response) => {
      setData(response);
    });
  }, [turmaId]);


  const {
    register,
    setValue,
    watch,
    handleSubmit,
    getValues,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      resultado: "",
      observacoes: "",
      diasLetivos: null,
    },
  });

  const onSubmit = () => {
    // Adicione aqui o código para salvar as alterações na ficha individual
    console.log("Salvar alterações na ficha individual");
    console.log(getValues()); // Mostra os valores do formulário
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "ATA_RESULTADOS_FINAIS_INFANTIL_CRECHE_INTEGRAL",
  });

  return (
    <BaseComponent>
      <Breadcrumb
        title={"Ata Resultado de Avaliação Infantil"}
        itens={[BasePath, "Turmas", "Atas de Resultado de Avaliação"]}
        onClickImprimir={handlePrint}
        imprimir={{ link: "fda", text: "Imprimir" }}
      />
       <div className="d-flex justify-content-center ">
        <SalvarFichaFormResultadoFinal ficha={dados} setDados={setDados} />
      </div>
      <style>
        {`
          @media print {
            @page {
              size: landscape;
            }
          }
        `}
      </style>
      <div className="ficha-container" ref={printRef}>
        <div className="d-flex justify-content-between pt-5">
          <div style={{ width: "250px" }}>
            <img
              src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`}
              width="100"
              height="100"
              alt=""
            ></img>
          </div>
          <div className="text-center">
            <h5>ESTADO DO RIO DE JANEIRO</h5>
            <h5>{process.env.REACT_APP_PREFEITURA}</h5>
            <h5>SECRETARIA MUNICIPAL DE EDUCAÇÃO</h5>
          </div>
          <div>
            <img
              src={`/assets/images/prefeituras/logo-pmspa-azul.png`}
              width="250"
              height="80"
              alt=""
            ></img>
          </div>
        </div>
        <div className="text-center">
          <h2 className="mt-3">{unidadeDescricao}, Ano Letivo: 2024</h2>
          <h3 className="mt-3">
            ATA RESULTADOS FINAIS - EDUCAÇÃO INFANTIL - {escolaridadeDescricao}
          </h3>
          <h4>Total de Carga Horária: {dados?.cargaHoraria}h | Dias Letivos: {dados?.diasLetivos}</h4>
        </div>
        <div style={{ border: "2px solid black", padding: 10 }} className="my-3">
          <Typography>
            Aos {dados?.diasDoMes} dias do mês de dezembro de dois mil e vinte e quatro,
            conclui-se o processo de acompanhamento dos alunos matriculados na
            {escolaridadeDescricao}, no turno {turnoDescricao}, na turma {turmaDescricao}, na Educação Infantil deste
            Estabelecimento de Ensino.
          </Typography>
        </div>

        <table
          border="1"
          cellPadding="8"
          style={{ width: "100%", borderCollapse: "collapse" }}
        >
          <thead>
            <tr>
              <th>Nº</th>
              <th>Nome</th>
              <th>Sexo</th>
              <th>Data de Nascimento</th>
              <th>% Frequência</th>
              <th>Resultado Final</th>
              <th>Observação</th>
            </tr>
          </thead>
          <tbody>
            {data.map((aluno) => (
              <tr key={aluno.id}>
                <td>{aluno.id}</td>
                <td>{aluno.nome}</td>
                <td>{aluno.sexo}</td>
                <td>{new Date(aluno.dataNascimento).toLocaleDateString('pt-BR')}</td>
                <td>{aluno.percentualFrequencia}</td>
                <td>{aluno.resultadoFinal}</td>
                <td>{aluno.observacao}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="mt-3">
          <Typography>
            E para constar, eu,
            {dados?.servidor}, secretária deste
            Estabelecimento de Ensino, lavrei a presente Ata, que vai por mim
            assinada, pela Direção e pela Inspeção Escolar.
          </Typography>
          <Typography textAlign="end" marginTop={3}>
            São Pedro da Aldeia, {dados?.diasDoMes} de dezembro de 2024.
          </Typography>
        </div>

        <div className="signature-section">
          <div className="signature-field">
            <div className="signature-line"></div>
            <span>SECRETÁRIO(A) ESCOLAR</span>
          </div>
          <div className="signature-field">
            <div className="signature-line"></div>
            <span>DIREÇÃO</span>
          </div>
          <div className="signature-field">
            <div className="signature-line"></div>
            <span>INSPETOR(A) ESCOLAR</span>
          </div>
        </div>
      </div>
    </BaseComponent>
  );
};

export default AtaResultadoFinalInfantil;
